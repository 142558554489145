import React from 'react';
import useColumns from './columns';
import { useFormatter } from '../../../utils/formatter';
import SimpleResourceLandingLayout from '../../../components/layouts/SimpleResourceLandingLayout';

const Contributors = () => {
  const { columns, headers } = useColumns();
  const { formattedData } = useFormatter();

  return (
    <SimpleResourceLandingLayout
      columns={columns}
      headers={headers}
      resourceName="contributors"
      resourceModelName="Contributors"
      withProjectionFields
      formatter={formattedData}
      withSubRoutes
      templateType="contributors_import_template"
    />
  );
};

export default Contributors;
