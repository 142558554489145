import React, { useCallback, useEffect, useState } from 'react';
import { Button, Input, InputNumber, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../../contexts/AuthContext';
import {
  listItemAfter,
  listItemBefore,
  listTrainingDiploma
} from '../../../utils/constants/trainingActionTypes';
import ModalTrainingFields from '../../Sessions/ModalTrainingFields';
import ErrorStatusCode from '../../../utils/ErrorStatusCode';

const { Option } = Select;
const { TextArea } = Input;

const useFields = (trainingFieldValue, setTrainingFieldValue) => {
  const { notification } = ErrorStatusCode();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [enums, setEnums] = useState({});
  const [trainingPlaces, setTrainingPlaces] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [diplomSelect, setDiplomSelect] = useState(false);
  const [diploma, setDiploma] = useState('');

  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleChange = (value) => {
    setDiplomSelect(true);
    setDiploma(value);
  };

  const filterOption = (input, option) => {
    return (
      option.children
        ?.toString()
        .toLowerCase()
        .indexOf(input?.toLowerCase()) > 0 ||
      option.key?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
    );
  };
  const fields = [
    {
      name: ['name'],
      rules: [{ required: true }]
    },
    {
      name: ['subtitle']
    }
  ];

  const modalityFields = [
    {
      label: 'training_type',
      name: ['modality', 'training_type'],
      input: (
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          loading={isFieldsLoading}
        >
          {(enums.types || []).map((title) => (
            <Option key={title} value={title}>
              {t(`programs.form.${title}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      label: 'accessibility',
      name: ['modality', 'accessibility'],
      input: <TextArea />
    },
    {
      label: 'staff_limit',
      name: ['modality', 'staff_limit'],
      input: <InputNumber />
    },
    {
      label: 'training_places',
      name: ['modality', 'training_place'],
      input: (
        <Select loading={isFieldsLoading}>
          {trainingPlaces.map((item) => (
            <Option key={item._id} value={item._id}>
              {item.name}
            </Option>
          ))}
        </Select>
      )
    }
  ];

  const trainingActionTypeFields = [
    {
      label: 'training_action_type',
      name: ['training_action_type', 'training_action_type'],
      input: (
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={filterOption}
        >
          <Option key={-1} style={{ fontWeight: 'bold' }} disabled>
            {t('programs.form.fields.since_2019_revision')}
          </Option>
          {listItemAfter.map((item) => (
            <Option style={{ marginLeft: 30 }} key={item} value={item}>
              {item}
            </Option>
          ))}
          <Option style={{ fontWeight: 'bold' }} disabled>
            {t('programs.form.fields.before_2019')}
          </Option>
          {listItemBefore.map((item) => (
            <Option style={{ marginLeft: 30 }} key={item} value={item}>
              {item}
            </Option>
          ))}
        </Select>
      )
    },
    {
      label: 'training_domain',
      name: ['training_action_type', 'training_domain'],
      input: (
        <>
          <Button type="link" block onClick={showModal}>
            {trainingFieldValue ? (
              <span>{trainingFieldValue}</span>
            ) : (
              t('programs.form.fields.select_a_field')
            )}
          </Button>
          <ModalTrainingFields
            setTrainingFieldValue={setTrainingFieldValue}
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
          />
        </>
      )
    },
    {
      label: 'diploma',
      name: ['training_action_type', 'diploma'],
      input: (
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          onChange={handleChange}
        >
          {listTrainingDiploma.map((item) => (
            <Option key={item} value={item}>
              {item}
            </Option>
          ))}
        </Select>
      )
    }
  ];

  if (diplomSelect === true && diploma !== 'Aucun') {
    fields.push({
      name: ['diploma_name']
    });
  }

  const certificationsModalityFields = [
    {
      label: 'expected_results',
      name: ['certifications_modality', 'expected_results'],
      input: <TextArea />
    },
    {
      label: 'obtaining_modality',
      name: ['certifications_modality', 'obtaining_modality'],
      input: <TextArea />
    },
    {
      label: 'certification_details',
      name: ['certifications_modality', 'certification_details'],
      input: <TextArea />
    },
    {
      label: 'validity_duration',
      name: ['certifications_modality', 'validity_duration'],
      input: <Input type="number" suffix="mois" />
    }
  ];

  const accountingConfigurationFields = [
    {
      label: 'code',
      name: ['accounting_configuration', 'code']
    },
    {
      label: 'code_funders',
      name: ['accounting_configuration', 'code_funders']
    },
    {
      label: 'analytic_code',
      name: ['accounting_configuration', 'analytic_code']
    }
  ];

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/programs/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  const getTrainingPlaces = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/training-places' });
      setTrainingPlaces(data);
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getEnums();
    await getTrainingPlaces();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return {
    fields,
    modalityFields,
    trainingActionTypeFields,
    certificationsModalityFields,
    accountingConfigurationFields,
    isFieldsLoading
  };
};

export default useFields;
