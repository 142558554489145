import React, { useEffect, useState } from 'react';
import { Calendar, Col, Modal, Row, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import Modale from '../Sessions/SessionPlanning/modale';
import { useAgendaContext } from '../../contexts/AgendaContext';
import DateCellRender from './utils/DateCellRender';
import HeaderRender from './utils/HeaderRender';

const GlobalPlanning = () => {
  const [showModale, setShowModale] = useState(false);
  const { t } = useTranslation();
  const [resetAllSelector, setResetAllSelector] = useState(false);

  const {
    kanbanCols,
    updateSession,
    reloadCalendar,
    setReloadCalendar,
    sessionsWithoutModule,
    start,
    setStart,
    end,
    setEnd,
    isLoading,
    editMode,
    setEditMode
  } = useAgendaContext();

  useEffect(() => {
    if (end !== null && editMode === true) {
      setShowModale(true);
    }
  }, [editMode, end]);

  return (
    <>
      <Modale
        idKanbanCol={kanbanCols.find((el) => el.label === 'A VENIR')?._id}
        updateSession={updateSession}
        showModale={showModale}
        setShowModale={setShowModale}
        idModule={module?._id}
        module={module}
        setEditMode={setEditMode}
        editMode={editMode}
        setReloadCalendar={setReloadCalendar}
        reloadCalendar={reloadCalendar}
        dateStart={start}
        dateEnd={end}
        setStartDate={setStart}
        setEndDate={setEnd}
        sessions={sessionsWithoutModule}
        resetAllSelector={resetAllSelector}
        setResetAllSelector={setResetAllSelector}
      />
      {isLoading ? (
        <Modal
          visible
          centered
          footer={false}
          closable={false}
          bodyStyle={{ textAlign: 'center' }}
        >
          <Row justify="center" align="middle" style={{ height: '100%' }}>
            <Col>
              {t('agenda.loading')}
              <br />
              <Spin size="large" />
            </Col>
          </Row>
        </Modal>
      ) : (
        <Calendar
          headerRender={({ value, onChange }) => (
            <HeaderRender
              value={value}
              onChange={onChange}
              setEditMode={setEditMode}
              editMode={editMode}
            />
          )}
          dateCellRender={(value) => (
            <DateCellRender
              value={value}
              editMode={editMode}
              setStart={setStart}
              setEnd={setEnd}
            />
          )}
        />
      )}
    </>
  );
};

export default GlobalPlanning;
