import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  Divider,
  PageHeader,
  Table,
  Tag,
  DatePicker,
  Input,
  Row,
  Col,
  Spin
} from 'antd';
import moment from 'moment';
import useAuthContext from '../../contexts/AuthContext';
import { SimplePieChart } from './SimplePieChart';
import useErrorMessage from '../../utils/ErrorMessage';

const StyledTable = styled.div`
  .rowStyle {
    cursor: pointer;
  }
`;

const { RangePicker } = DatePicker;
const { Search } = Input;

const DevisTable = ({ indicator, tab }) => {
  const history = useHistory();
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const [total, setTotal] = useState(0);
  const [totalTtc, setTotalTtc] = useState(0);
  const [data, setData] = useState([]);
  const [donutData, setDonutData] = useState([]);
  const { dispatchAPI, user } = useAuthContext();
  const [dates, setDates] = useState(null);
  const [obj, setObj] = useState({});
  const [searchValue, setSearchValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [resources, setResources] = useState([]);
  const [totalPaid, setTotalPaid] = useState(0);
  const [status, setStatus] = useState(null);
  const [pagination, setPagination] = useState({
    hideOnSinglePage: true,
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: true
  });

  const startDate = dates ? moment(dates[0]) : null;
  const endDate = dates ? moment(dates[1]) : null;

  const fetchData = async (page = pagination) => {
    setIsLoading(true);
    try {
      const { data: invoices, headers } = await dispatchAPI('GET', {
        url: `/invoices?organization=${user.organization._id}&isTable=true`
      });

      let res = invoices;
      if (indicator) {
        res = res.sort((a, b) => (moment(a.date).isAfter(b.date) ? 1 : -1));
        res = res.filter((el) => el.status !== 'PAID');
      }
      setResources(res);
      setPagination({
        ...page,
        total: parseInt(headers['x-total-count'], 10)
      });
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsLoading(false);
  };

  const fetchDonutDatas = async () => {
    try {
      const response = await dispatchAPI('GET', {
        url: `/invoices/simple_pie_data/${user.organization._id}?startDate=${startDate}&endDate=${endDate}&statusFilter=${status}`
      });
      setDonutData(response.data);
    } catch (error) {
      if (error.response) message(error.response.status);
    }
  };

  const filterData = () => {
    setData(
      resources.filter(
        (r) =>
          (status ? status.includes(r.status) : true) &&
          (dates
            ? moment(r.deadline_date).isAfter(
                moment(dates[0]).startOf('day')
              ) &&
              moment(r.deadline_date).isBefore(moment(dates[1]).endOf('day'))
            : true) &&
          r.customer.toUpperCase().includes(searchValue.toUpperCase())
      )
    );
  };

  useEffect(() => {
    if (tab !== 'Factures') return;
    (async () => {
      filterData();
      await fetchDonutDatas(dates);
    })();
  }, [resources, dates, status, searchValue, tab]);

  const handlePageChange = async (page, filters) => {
    setStatus(filters.status);
    await fetchData(page);
  };

  useEffect(() => {
    (async () => {
      await fetchData();
    })();
  }, []);

  useEffect(() => {
    setTotal(
      data.length > 0 &&
        data.filter((el) => el.total_ht).map((el) => el.total_ht).length > 0
        ? data
            .filter((el) => el.total_ht)
            .map((el) => el.total_ht)
            .reduce((a, b) => a + b)
            .toFixed(2)
        : 0
    );
    setTotalTtc(
      data.length > 0 &&
        data.filter((el) => el.total_ttc).map((el) => el.total_ttc).length > 0
        ? data
            .filter((el) => el.total_ttc)
            .map((el) => el.total_ttc)
            .reduce((a, b) => a + b)
            .toFixed(2)
        : 0
    );
    setTotalPaid(
      data.length > 0 &&
        data.filter((el) => el.total_paid).map((el) => el.total_paid).length > 0
        ? data
            .filter((el) => el.total_paid)
            .map((el) => el.total_paid)
            .reduce((a, b) => a + b)
            .toFixed(2)
        : 0
    );
    const newObj = {};
    data.forEach((el) => {
      if (newObj[el.status]) {
        newObj[el.status].value += 1;
        newObj[el.status].amount += el.total_ht || 0;
      } else {
        newObj[el.status] = { value: 1, amount: el.total_ht || 0 };
      }
    });
    setObj(newObj);
  }, [data]);

  const column = [
    {
      title: 'Statut',
      dataIndex: 'status',
      sorter: true,
      filters: !indicator
        ? [
            { text: 'EN ATTENTE', value: 'WAITING' },
            { text: 'PARTIELLEMENT PAYÉ', value: 'PARTIALLY' },
            { text: 'PAYÉE', value: 'PAID' }
          ]
        : null,
      onFilter: (value, record) => record.status === value,
      render: (s) => {
        switch (s) {
          case 'PAID':
            return <Tag color="success">{s && t(`indicators.tags.${s}`)}</Tag>;
          case 'WAITING':
            return <Tag color="gold">{s && t(`indicators.tags.${s}`)}</Tag>;
          default:
            return <Tag color="default">{s && t(`indicators.tags.${s}`)}</Tag>;
        }
      }
    },
    {
      title: 'Client',
      dataIndex: 'customer'
    },
    {
      title: 'Échéance',
      dataIndex: 'deadline_date',
      render: (deadline_date) => moment(deadline_date).format('DD-MM-YYYY')
    },
    {
      title: 'Montant HT',
      dataIndex: 'total_ht',
      render: (total_ht) => `${parseFloat(total_ht).toFixed(2) || 0.0} €`
    },
    {
      title: 'Montant TTC',
      dataIndex: 'total_ttc',
      render: (total_ttc) => `${parseFloat(total_ttc).toFixed(2) || 0.0} €`
    },
    {
      title: 'Montant payé',
      dataIndex: 'total_paid',
      render: (total_paid) =>
        total_paid ? `${parseFloat(total_paid).toFixed(2) || 0.0} €` : '-'
    }
  ];
  const extra = [
    <>
      <Row gutter={8}>
        <Col span={10}>
          <Search
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder={t('dashboards.invoices.search_client')}
          />
        </Col>
        <Col span={14}>
          <RangePicker
            onChange={(values) => setDates(values)}
            style={{ float: 'right' }}
          />
        </Col>
      </Row>
    </>
  ];

  if (indicator) {
    return (
      <StyledTable
        as={Table}
        rowClassName="rowStyle"
        dataSource={
          indicator ? resources.filter((_, index) => index < 5) : data
        }
        onRow={(record) => ({
          onDoubleClick: () => {
            history.push({
              pathname: `/sessions/show/${record.session._id}`,
              state: { key: '11' }
            });
            localStorage.setItem('tabIndex', '11');
          }
        })}
        loading={isLoading}
        onChange={handlePageChange}
        pagination={indicator ? false : pagination}
        columns={column}
      />
    );
  }
  return (
    <>
      <Row>
        <Col xl={17} sm={24} xs={24}>
          <PageHeader title={t('dashboards.invoices.title')} extra={extra} />
          <StyledTable
            as={Table}
            rowClassName="rowStyle"
            onRow={(record) => ({
              onDoubleClick: () => {
                history.push({
                  pathname: `/sessions/show/${record.session._id}`,
                  state: { key: '11' }
                });
                localStorage.setItem('tabIndex', '11');
              }
            })}
            dataSource={
              indicator ? resources.filter((_, index) => index < 5) : data
            }
            loading={isLoading}
            onChange={handlePageChange}
            pagination={indicator ? false : pagination}
            columns={column}
            style={{ margin: '0 8px 0 20px' }}
          />
        </Col>
        <Col xl={7} sm={24} xs={24} className="dashboard-table-right-column">
          <h1>{` ${data.length} factures`}</h1>
          <Divider orientation="left">
            {t('dashboards.invoices.ht_amount')}
          </Divider>
          {isLoading ? (
            <Spin spinning={isLoading} />
          ) : (
            <SimplePieChart data={donutData} />
          )}
          <Divider orientation="left">
            {t('dashboards.invoices.totals')}
          </Divider>
          <div className="dashboard-total-prices-wrapper">
            <p>
              <span>{t('dashboards.invoices.total_ht')}</span>
              <span>{` ${parseFloat(total).toFixed(2)} €`}</span>
            </p>
            <p>
              <span>{t('dashboards.invoices.total_ttc')}</span>
              <span>{` ${parseFloat(totalTtc).toFixed(2)} €`}</span>
            </p>
            <p>
              <span>{t('dashboards.invoices.total_pay')}</span>
              <span>{` ${parseFloat(totalPaid).toFixed(2)} €`}</span>
            </p>
            <p>
              <span>{t('dashboards.invoices.total_due')}</span>
              <span>{` ${(totalTtc - totalPaid).toFixed(2)} €`}</span>
            </p>
          </div>

          <Divider orientation="left">
            {' '}
            {t('dashboards.invoices.filters')}
          </Divider>
          {Object.keys(obj).map((el) => {
            return (
              <p>
                {el === 'undefined'
                  ? `Autres: ${obj[el].value}`
                  : `${t(`indicators.tags.${el.toUpperCase()}`)} : ${
                      obj[el].value
                    }`}
              </p>
            );
          })}
        </Col>
      </Row>
    </>
  );
};

DevisTable.propTypes = {
  indicator: PropTypes.bool.isRequired,
  tab: PropTypes.string
};

DevisTable.defaultProps = {
  tab: ''
};

export default DevisTable;
