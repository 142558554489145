import React from 'react';
import PropTypes from 'prop-types';
import { DeleteOutlined, WarningOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import moment from 'moment';
import { Button, Col, Drawer, List, Popconfirm, Popover, Row, Tag } from 'antd';
import DetailsSession from '../DetailsSession';
import { routes } from '../../../utils/constants/routes';
import { useAgendaContext } from '../../../contexts/AgendaContext';

const SideDrawer = ({
  visible,
  value,
  onClose,
  finalReturn,
  handleVisibleChange
}) => {
  const {
    sessions,
    modules,
    editSlot,
    setEditSlot,
    selectTime,
    updateSlot,
    deleteSubSlot
  } = useAgendaContext();
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Drawer
      closable
      width="30%"
      mask={false}
      visible={visible[moment(value).format('YYYY-MM-DD')]}
      onClose={onClose}
      title={`${moment(value).format('dddd')} ${moment(value).format('LL')}`}
    >
      <List
        dataSource={finalReturn}
        renderItem={(module) => (
          <Popover
            placement="leftTop"
            onVisibleChange={handleVisibleChange}
            title={
              <Row>
                <Col span={12}>
                  <h3>{module.session}</h3>
                </Col>
                <Col span={12}>
                  <Popconfirm
                    title={t('datatable.column.action.delete.title')}
                    okText={t('datatable.column.action.delete.ok')}
                    okButtonProps={{ danger: true }}
                    cancelText={t('datatable.column.action.delete.cancel')}
                    icon={<WarningOutlined />}
                    onConfirm={() => {
                      deleteSubSlot(module.id, module.idSlot, module.idSubSlot);
                    }}
                  >
                    <Button type="link" danger>
                      {`${t('buttons.delete')} `}
                      <DeleteOutlined />
                    </Button>
                  </Popconfirm>
                </Col>
              </Row>
            }
            content={() => {
              return (
                <>
                  {DetailsSession(
                    sessions,
                    modules,
                    editSlot,
                    setEditSlot,
                    selectTime,
                    updateSlot,
                    module.idSession,
                    module.id,
                    module.idSlot,
                    module.idSubSlot,
                    module.type,
                    module.date,
                    module.start_time,
                    module.end_time
                  )}
                </>
              );
            }}
            trigger="click"
          >
            <List.Item
              style={{ width: '100%' }}
              onDoubleClick={() =>
                history.push({
                  pathname: `${routes.SESSIONS}/show/${module.idSession}`
                })
              }
            >
              <Row style={{ width: '100%' }}>
                <Col
                  span={1}
                  style={{ borderLeft: `4px solid ${module.color}` }}
                />
                <Col span={23}>
                  <Tag>{module.module}</Tag>
                  <Tag>{module.start_time}</Tag>
                  <h3>{module.session}</h3>
                  {module.customers.map(({ customer }) => (
                    <span>
                      {`${customer.name ||
                        `${customer.last_name} ${customer.first_name}`}, `}
                    </span>
                  ))}
                </Col>
              </Row>
            </List.Item>
          </Popover>
        )}
      />
    </Drawer>
  );
};

SideDrawer.propTypes = {
  visible: PropTypes.element.isRequired,
  value: PropTypes.element.isRequired,
  onClose: PropTypes.func.isRequired,
  finalReturn: PropTypes.element.isRequired,
  handleVisibleChange: PropTypes.func.isRequired
};

export default SideDrawer;
