import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  Button,
  Card,
  Col,
  Collapse,
  Divider,
  Dropdown,
  List,
  Menu,
  message,
  Row,
  Spin,
  Tag
} from 'antd';
import { useTranslation } from 'react-i18next';
import {
  CheckOutlined,
  DownloadOutlined,
  DownOutlined,
  LoadingOutlined,
  SendOutlined
} from '@ant-design/icons';
import moment from 'moment';
import useAuthContext from '../../../contexts/AuthContext';
import ModalUploadFiles from '../../Catalog/Program/modalUploadFiles';
import ListSessionFiles from '../conventions/ListSessionFiles';
import ModalSendMails from '../Convocations/modalSendMails';
import ModalGeneratingFile from '../Convocations/modalGeneratingFile';
import AttendanceCertificate from './AttendanceCertificate';
import ErrorStatusCode from '../../../utils/ErrorStatusCode';

const { Panel } = Collapse;

const Certifications = ({
  session,
  emails,
  trainees,
  fetchData,
  updateSession,
  forceRefresh,
  setForceRefresh
}) => {
  const { t } = useTranslation();
  const { dispatchAPI, user } = useAuthContext();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [users, setUsers] = useState([]);
  const [exist, setExist] = useState({});
  const [isModalMailVisible, setIsModalMailVisible] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [generatedFile, setGeneratedFile] = useState({});
  const [file, setFile] = useState([]);
  const [loading, setLoading] = useState({});
  const [customerId, setCustomerId] = useState(null);
  const [templatesCertificate, setTemplatesCertificate] = useState([]);
  const [isModalGenerateFileVisible, setIsModalGenerateFileVisible] = useState(
    false
  );
  const [templatesAttendance, setTemplatesAttendance] = useState([]);
  const [sendType, setSendType] = useState('one');
  const [generateStatus, setGenerateStatus] = useState(false);
  const [emailPurpose, setEmailPurpose] = useState('');
  const { notification } = ErrorStatusCode();

  const showModal = (purpose, id, file, fileType) => {
    if (purpose === 'all') {
      const list = [];
      setUsers(
        trainees.map((el) => ({
          ...el,
          ref: 'Trainees'
        }))
      );

      trainees.forEach((trainee) => {
        const listFiles = session.files.filter(
          (item) =>
            item.user && item.user._id === trainee._id && item.type === fileType
        );
        list.push({
          ...listFiles[listFiles.length - 1],
          user: trainee._id
        });
      });
      setFile(
        list
          .filter((el) => el._id !== undefined)
          .map((el) => ({
            id: el._id,
            fileName: el.filename,
            user: el.user
          }))
      );
    } else {
      setUsers(
        trainees
          .filter((el) => el._id.toString() === id.toString())
          .map((el) => ({
            ...el,
            ref: 'Trainees'
          }))
      );
      setFile([file]);
    }
    setEmailPurpose(fileType);
    setIsModalMailVisible(!isModalMailVisible);
  };

  const getFilesCertificates = () => {
    try {
      const list = user.organization.templates.filter(
        (el) => el.type === 'training_action_completion'
      );
      setTemplatesCertificate(list);
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  const getFilesAttendance = () => {
    try {
      const list = user.organization.templates.filter(
        (el) => el.type === 'attendance_certificate'
      );
      setTemplatesAttendance(list);
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  useEffect(() => {
    (async () => {
      await getFilesAttendance();
      await getFilesCertificates();
    })();
  }, []);

  const generateFile = async (id, name, customId, type, fileType) => {
    try {
      message.info(t('programs.success_messages.generating_document'), 5);
      setGenerateStatus(true);
      await dispatchAPI('GET', {
        url: `/files/generate/${type}/${session._id}/${customId}/${id}?fileType=${fileType}`,
        responseType: 'blob'
      });
      setIsModalGenerateFileVisible(!isModalGenerateFileVisible);
      await fetchData();
      setLoading(false);
      setGenerateStatus(false);
    } catch (e) {
      if (e.response) notification(e.response);
    }
    setIsDownloading({ ...isDownloading, [id]: false });
  };

  const getFile = async (el, customId, fileType) => {
    try {
      setLoading({ [`${customId}-${el.type}`]: true });
      await generateFile(el._id, el.name, customId, el.type, fileType);
    } catch (e) {
      if (e.response) notification(e.response);
    }
    setLoading({ [`${customId}-${el.type}`]: false });
  };

  const deleteFile = async (id) => {
    try {
      await dispatchAPI('DELETE', {
        url: `/files/${id}`
      });
    } catch (e) {
      message.error(t('settings.errors.category_delete'));
    }
  };

  const generateMultiFiles = async (templateId, typeFile, type) => {
    try {
      message.info(
        'Veuillez patienter, le document est en cours de génération',
        5
      );
      setLoading({ [type]: true });
      setGenerateStatus(true);
      await dispatchAPI('GET', {
        url: `/multi-generate/${
          session._id
        }/${templateId}?trainees=${JSON.stringify(
          trainees.map((el) => el._id)
        )}&fileType=${typeFile}`
      });
      setIsModalGenerateFileVisible(!isModalGenerateFileVisible);
      await fetchData();
      setLoading({ [type]: false });
      setGenerateStatus(false);
    } catch (e) {
      message.error(t('settings.errors.category_delete'));
    }
  };

  const menuDropDown = (type, id, isMulti) => {
    const menu = (
      <Menu>
        {type === 'attendance_certificate'
          ? templatesAttendance.map((el, index) => (
              <>
                <Menu.Item key={`${el._id}-${index + 1}`}>
                  {generateStatus ? (
                    <Button type="link" disabled>
                      {`${el.name.split('.')[0]} docx`}
                    </Button>
                  ) : (
                    <Button
                      type="link"
                      style={{ width: '100%', textAlign: 'left' }}
                      onClick={() =>
                        isMulti
                          ? generateMultiFiles(
                              el._id,
                              'docx',
                              'attendance_certificate'
                            )
                          : getFile(el, id, 'docx')
                      }
                    >
                      {`${el.name.split('.')[0]} docx`}
                    </Button>
                  )}
                </Menu.Item>
                <Menu.Item key={`${el._id}`}>
                  {generateStatus ? (
                    <Button type="link" disabled>
                      {`${el.name.split('.')[0]} pdf`}
                    </Button>
                  ) : (
                    <Button
                      type="link"
                      style={{ width: '100%', textAlign: 'left' }}
                      onClick={() =>
                        isMulti
                          ? generateMultiFiles(
                              el._id,
                              'pdf',
                              'attendance_certificate'
                            )
                          : getFile(el, id, 'pdf')
                      }
                    >
                      {`${el.name.split('.')[0]} pdf`}
                    </Button>
                  )}
                </Menu.Item>
              </>
            ))
          : templatesCertificate.map((el, index) => (
              <>
                <Menu.Item key={`${el._id}-${index + 1}`}>
                  {generateStatus ? (
                    <Button type="link" disabled>
                      {`${el.name.split('.')[0]} docx`}
                    </Button>
                  ) : (
                    <Button
                      type="link"
                      style={{ width: '100%', textAlign: 'left' }}
                      onClick={() =>
                        isMulti
                          ? generateMultiFiles(
                              el._id,
                              'docx',
                              'training_action_completion'
                            )
                          : getFile(el, id, 'docx')
                      }
                    >
                      {`${el.name.split('.')[0]} docx`}
                    </Button>
                  )}
                </Menu.Item>
                <Menu.Item key={`${el._id}`}>
                  {generateStatus ? (
                    <Button type="link" disabled>
                      {`${el.name.split('.')[0]} pdf`}
                    </Button>
                  ) : (
                    <Button
                      type="link"
                      style={{ width: '100%', textAlign: 'left' }}
                      onClick={() =>
                        isMulti
                          ? generateMultiFiles(
                              el._id,
                              'pdf',
                              'training_action_completion'
                            )
                          : getFile(el, id, 'pdf')
                      }
                    >
                      {`${el.name.split('.')[0]} pdf`}
                    </Button>
                  )}
                </Menu.Item>
              </>
            ))}
      </Menu>
    );
    return menu;
  };

  const downloadFile = async (id, name) => {
    try {
      const response = await dispatchAPI('GET', {
        url: `/files/${id}`,
        responseType: 'blob'
      });
      const blob = new Blob([response.data], {
        type: response.data.type
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = name;
      a.target = '_blank';
      a.click();
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
    setIsDownloading({ ...isDownloading, [id]: false });
  };

  const deleteFileFromSession = async (id) => {
    const filesList = session.files.filter((el) => el._id !== id);
    await updateSession({ files: filesList });
    await deleteFile(id);
    await fetchData();
  };

  useEffect(() => {
    const exists = {};
    const filesList = {};
    trainees.forEach((trainee) => {
      const file = session.files
        .filter(
          (file) =>
            file.user &&
            file.user._id.toString() === trainee._id.toString() &&
            file.type === 'training_action_completion'
        )
        .sort((a, b) => new Date(b.date) - new Date(a.date))[0];
      if (file) {
        exists[trainee._id] = true;
        filesList[trainee._id] = {
          fileName: file.filename,
          id: file._id
        };
      }
    });
    setExist(exists);
    setGeneratedFile(filesList);
  }, [session.files, trainees]);

  const listActions = (type, id) => {
    let actions = [];
    session.files.forEach((file) => {
      if (
        file.user &&
        file.user._id.toString() === id.toString() &&
        file.type === type
      ) {
        actions = [
          <>
            <Tag style={{ fontSize: 15 }} color="orange">
              <span>
                <CheckOutlined
                  style={{ margin: '4px 2px 0 0', fontSize: 14 }}
                />
                {`${t('sessions.conventions.button.generate_the')} ${moment(
                  file.date
                ).format('DD-MM-YYYY')} `}
              </span>
              <span>
                {`${t('sessions.conventions.button.format')} ${
                  file.filename.split('.')[1]
                }`}
              </span>
            </Tag>
          </>
        ];
      }
    });

    const email = emails.find(
      (el) => el.user && el.user._id === id && el.purpose === type
    );
    if (email) {
      actions.push(
        <Tag color="green">
          <span style={{ fontSize: 15 }}>
            <CheckOutlined style={{ margin: '4px 2px 0 0', fontSize: 14 }} />
            Envoyé
          </span>
        </Tag>
      );
    }

    return actions;
  };

  return (
    <>
      <Row style={{ padding: '0 20px' }}>
        <Col span={24}>
          <Alert
            showIcon
            type="info"
            message="Afin de passer cette étape, vous devez : générer les documents pour tous les clients et les envoyer."
          />
        </Col>
      </Row>
      <Divider orientation="left">
        <span>{t("Certificat de réalisation de l'action de formation")}</span>
      </Divider>
      <ModalGeneratingFile
        isVisible={isModalGenerateFileVisible}
        setVisible={setIsModalGenerateFileVisible}
      />
      <ModalSendMails
        emailModel="certification_model"
        files={file}
        file={file}
        sendType={sendType}
        users={users}
        isModalVisible={isModalMailVisible}
        setIsModalVisible={setIsModalMailVisible}
        emailPurpose={emailPurpose}
        refresh={forceRefresh}
        setRefresh={setForceRefresh}
      />
      <Card>
        <Row justify="space-between">
          <Col>
            <Button
              disabled={Object.keys(generatedFile).length === 0}
              type="primary"
              style={{ marginBottom: 10 }}
              onClick={() => {
                setSendType('multi');
                showModal('all', '', '', 'training_action_completion');
              }}
            >
              {t('sessions.training_action.send_button')}
            </Button>
            <Spin
              style={{ marginLeft: 10 }}
              spinning={loading.training_action_completion || false}
            />
            <Dropdown
              overlay={menuDropDown('training_action_completion', '', true)}
            >
              <Button type="link" style={{ marginBottom: 10, marginLeft: 10 }}>
                {t('sessions.training_action.generate_button')}
                <DownOutlined />
              </Button>
            </Dropdown>
          </Col>
        </Row>

        <List
          dataSource={trainees}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta title={`${item.first_name} ${item.last_name}`} />
              <span style={{ paddingRight: 50 }}>
                {listActions('training_action_completion', item._id)}
              </span>
              <span style={{ float: 'right' }}>
                {loading[`${item._id}-training_action_completion`] && (
                  <LoadingOutlined />
                )}
                <Dropdown
                  overlay={() =>
                    menuDropDown('training_action_completion', item._id)
                  }
                  trigger={['click']}
                >
                  <Button type="link">
                    Certificat
                    <DownOutlined />
                  </Button>
                </Dropdown>
                {exist[item._id] && (
                  <>
                    <Divider type="vertical" />
                    <Button
                      type="link"
                      icon={<DownloadOutlined />}
                      onClick={() =>
                        downloadFile(
                          generatedFile[item._id].id,
                          generatedFile[item._id].fileName
                        )
                      }
                    />
                    <Divider type="vertical" />
                    <Button
                      type="link"
                      icon={<SendOutlined />}
                      onClick={() => {
                        setSendType('one');
                        showModal(
                          item.ref,
                          item._id,
                          generatedFile[item._id],
                          'training_action_completion'
                        );
                      }}
                    />
                  </>
                )}
              </span>
            </List.Item>
          )}
        />
      </Card>
      <AttendanceCertificate
        trainees={trainees}
        loading={loading}
        menuDropDown={menuDropDown}
        customerId={customerId}
        setSendType={setSendType}
        showModal={showModal}
        session={session}
        listActions={listActions}
        downloadFile={downloadFile}
      />
      <Divider orientation="left" style={{ marginTop: 48 }}>
        <span>{t('Archives')}</span>
      </Divider>
      <Card>
        <Collapse ghost accordion>
          <Panel key="1" header={t('Archivage des attestations signées')}>
            <Button
              type="link"
              onClick={() => {
                setIsModalVisible(!isModalVisible);
              }}
            >
              {t('sessions.archives.button.download')}
            </Button>
            {session &&
              session.files &&
              session.files.filter((el) => el.type === 'attendance_certificate')
                .length !== 0 && (
                <ListSessionFiles
                  files={session.files.filter(
                    (el) => el.type === 'attendance_certificate'
                  )}
                  deleteFileFromSession={deleteFileFromSession}
                  downloadFile={downloadFile}
                />
              )}
            <ModalUploadFiles
              uploadType="sessions"
              fetchData={fetchData}
              setIsModalVisible={setIsModalVisible}
              isModalVisible={isModalVisible}
              id={session._id}
              fileType="attendance_certificate"
            />
          </Panel>
        </Collapse>
      </Card>
    </>
  );
};

Certifications.propTypes = {
  session: PropTypes.shape({
    _id: PropTypes.string,
    files: PropTypes.array
  }).isRequired,
  emails: PropTypes.arrayOf({}).isRequired,
  trainees: PropTypes.arrayOf({}).isRequired,
  fetchData: PropTypes.func.isRequired,
  updateSession: PropTypes.func.isRequired,
  forceRefresh: PropTypes.bool,
  setForceRefresh: PropTypes.bool.isRequired
};

Certifications.defaultProps = {
  forceRefresh: false
};

export default Certifications;
