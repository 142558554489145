import React, { useEffect, useState } from 'react';
import {
  Button,
  Divider,
  message,
  Popconfirm,
  Space,
  Table,
  Menu,
  Dropdown,
  Row,
  Col,
  Card,
  Input,
  Form
} from 'antd';
import { Link } from 'react-router-dom';
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  WarningOutlined,
  DownOutlined,
  LoadingOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { any } from 'prop-types';
import { routes, subRoutes } from '../../../utils/constants/routes';
import Columns from './customersColumns';
import useAuthContext from '../../../contexts/AuthContext';
import ModalSendMails from '../Convocations/modalSendMails';
import ListSessionFiles from '../conventions/ListSessionFiles';
import ModalGeneratingFile from '../Convocations/modalGeneratingFile';
import ListFiles from '../Emails/ListFiles';
import AssociatedProgramToSession from '../sessionProgram/AssociatedProgramToSession';
import calculateRateModule from '../sessionModule/utils/calculateRateModule';
import ErrorStatusCode from '../../../utils/ErrorStatusCode';
import NewTraineeDrawer from '../../Donnees/Trainees/NewTraineeDrawer';
import { getOpcoNumber } from './utils/getOpcoNumber';

const SessionCustomersTable = ({
  session,
  updateSession,
  fetchData,
  customers,
  allCustomers,
  purpose,
  contacts,
  modules,
  type,
  refresh,
  setRefresh
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const customersColumns = Columns();
  const { notification } = ErrorStatusCode();
  const { dispatchAPI, user } = useAuthContext();
  const [isDownloading, setIsDownloading] = useState(false);
  const [template, setTemplate] = useState([]);
  const [file, setFile] = useState([]);
  const [users, setUsers] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [expandedKey, setExpandedKey] = useState('');
  const [finalColumns, setFinalColumns] = useState([]);
  const [acceptedCheckbox, setAcceptedCheckbox] = useState([]);
  const [loading, setLoading] = useState(false);
  const [customerId, setCustomerId] = useState(null);
  const [isModalGenerateFileVisible, setIsModalGenerateFileVisible] = useState(
    false
  );
  const [quotationsTemplates, setQuotationsTemplates] = useState([]);
  const [visibilityTraineeDrawer, setVisibilityTraineeDrawer] = useState(false);
  const [emailModel, setEmailModel] = useState('');
  const [emailPurpose, setEmailPurpose] = useState('');

  const generateFile = async (temp, fileType) => {
    try {
      setLoading(true);
      message.info(
        'Veuillez patienter, le document est en cours de génération.',
        5
      );
      await dispatchAPI('GET', {
        url: `/files/generate/quotation/${session._id}/${expandedKey}/${temp._id}?fileType=${fileType}`,
        responseType: 'blob'
      });
      setIsModalGenerateFileVisible(!isModalGenerateFileVisible);
      await fetchData();
    } catch (e) {
      if (e.response) notification(e.response);
    }
    setIsDownloading({ ...isDownloading, [template._id]: false });
    setLoading(false);
  };

  const drawerToParent = async (data) => {
    const newData = data;
    for (const i of session.customers) {
      if (i.customer._id === newData.company) {
        i.nb_trainees += 1;
        i.trainees_list.push(newData);
      }
    }
    const newSession = session;
    await updateSession({ ...newSession });
    setRefresh(!refresh);
  };

  const getQuotationsTemplates = () => {
    try {
      const list = user.organization.templates.filter(
        (el) => el.type === 'quotations'
      );
      setQuotationsTemplates(list);
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  const menuDropDown = (idCustomer, type, idFile, fileName, customerRef) => {
    setCustomerId(idCustomer);
    let menu = any;
    switch (type) {
      case 'generate':
        menu = (
          <Menu>
            {quotationsTemplates.map((temp) => (
              <>
                <Menu.Item key={`${temp._id}_docx`}>
                  {loading ? (
                    <Button disabled type="link">
                      {`${temp.name.split('.')[0]} docx`}
                    </Button>
                  ) : (
                    <Button
                      type="link"
                      style={{ width: '100%', textAlign: 'left' }}
                      onClick={() => generateFile(temp, 'docx')}
                    >
                      {`${temp.name.split('.')[0]} docx`}
                    </Button>
                  )}
                </Menu.Item>
                <Menu.Item key={`${temp._id}_pdf`}>
                  {loading ? (
                    <Button disabled type="link">
                      {`${temp.name.split('.')[0]} pdf`}
                    </Button>
                  ) : (
                    <Button
                      type="link"
                      style={{ width: '100%', textAlign: 'left' }}
                      onClick={() => generateFile(temp, 'pdf')}
                    >
                      {`${temp.name.split('.')[0]} pdf`}
                    </Button>
                  )}
                </Menu.Item>
              </>
            ))}
          </Menu>
        );
        break;
      case 'download':
        menu = (
          <Menu>
            <Menu.Item key="1">
              <Button
                type="link"
                onClick={() => downloadFile(idFile, fileName)}
              >
                {t('buttons.download')}
              </Button>
            </Menu.Item>
            <Menu.Item key="2">
              <Button
                type="link"
                onClick={() =>
                  showModal(customerRef, idCustomer, idFile, fileName)
                }
              >
                {t('button.sendVersion')}
              </Button>
            </Menu.Item>
          </Menu>
        );
    }
    return menu;
  };

  const setNewTraineeDrawerVisibility = () => {
    setVisibilityTraineeDrawer(false);
  };

  const showModal = (ref, id, sendFile, type) => {
    switch (type) {
      case 'quotation':
        setEmailModel('quotation_model');
        setEmailPurpose('quotation');
        break;
      case 'convention':
        setEmailModel('convention_model');
        setEmailPurpose('convention');
        break;
      default:
        return null;
    }
    if (ref === 'Company') {
      setUsers(
        contacts
          .filter(
            (el) =>
              el.my_company._id.toString() === id.toString() &&
              el.company_representative
          )
          .map((el) => ({
            id: el._id,
            ...el,
            ref: 'Contact'
          }))
      );
    } else {
      setUsers(
        customers
          .filter((el) => {
            return el.key.toString() === id.toString();
          })
          .map(({ customer }) => ({
            ...customer
          }))
      );
    }
    setFile([
      {
        id: sendFile._id,
        fileName: sendFile.filename
      }
    ]);
    setIsModalVisible(!isModalVisible);
  };
  const deleteTraineeFromCustomer = async (idTrainee, idCustomer) => {
    try {
      let indexCustomer = 0;
      customers.forEach((customer, index) => {
        if (customer.customer._id === idCustomer) {
          indexCustomer = index;
        }
      });
      customers[indexCustomer].trainees_list = customers[
        indexCustomer
      ].trainees_list.filter((el) => el._id !== idTrainee);
      customers[indexCustomer].nb_trainees = customers[
        indexCustomer
      ].trainees_list.filter((el) => el._id !== idTrainee).length;

      await updateSession({ customers });
      await fetchData();
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
  };

  const getFile = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/files?type=quotations`
      });
      setTemplate(data[0]);
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  const downloadFile = async (id, name) => {
    try {
      const response = await dispatchAPI('GET', {
        url: `/files/${id}`,
        responseType: 'blob'
      });
      const blob = new Blob([response.data], {
        type: response.data.type
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = name;
      a.target = '_blank';
      a.click();
    } catch (e) {
      if (e.response) notification(e.response);
    }
    setIsDownloading({ ...isDownloading, [id]: false });
  };

  const deleteCustomerFromSession = async (idCustomer) => {
    const oldCustomersList = session.customers;
    const customersList = oldCustomersList.filter(
      (el) => el.customer._id !== idCustomer
    );
    const body = customersList.map((c) => ({
      ...c,
      customer: c.customer._id
    }));
    await updateSession({ customers: body });
    await fetchData();
  };

  useEffect(() => {
    getQuotationsTemplates();
  }, []);

  const deleteFile = async (id) => {
    try {
      await dispatchAPI('DELETE', {
        url: `/files/${id}`
      });
    } catch (e) {
      message.error(t('settings.errors.category_delete'));
    }
  };
  const deleteFileFromSession = async (id) => {
    const filesList = session.files.filter((el) => el._id !== id);
    await updateSession({ files: filesList });
    await deleteFile(id);
    await fetchData();
  };

  const onAccept = async (idUser, value, item) => {
    const customer = allCustomers.find((el) => el.customer._id === idUser);
    const newCustomers = allCustomers.filter(
      (el) => el.customer._id !== idUser
    );
    const opcoNumber = await form.validateFields();

    let files = session.files;
    if (value) {
      newCustomers.push({
        ...customer,
        customer: customer.customer,
        status: 'ACCEPTED',
        opco_number: opcoNumber[idUser].opco_number
      });
      if (item !== undefined) {
        files = session.files.map((el) => {
          if (el._id === item._id) {
            return {
              ...el,
              status: 'accepted'
            };
          }
          return el;
        });
      }
    } else {
      if (item !== undefined) {
        files = session.files.map((el) => {
          if (el._id === item._id) {
            return {
              ...el,
              status: 'waiting'
            };
          }
          return el;
        });
      }
      newCustomers.push({
        ...customer,
        customer: customer.customer,
        status: 'NEW',
        opco_number: opcoNumber[idUser].opco_number
      });
    }
    newCustomers.map((el) => ({
      ...el,
      customer: el.customer._id
    }));
    setAcceptedCheckbox(value);
    await updateSession({ customers: newCustomers, files });
    await fetchData();
  };

  useEffect(() => {
    const list = [];
    customers.forEach((customer) => {
      if (customer.status === 'ACCEPTED') {
        list[customer.customer._id] = true;
      } else {
        list[customer.customer._id] = false;
      }
    });
    setAcceptedCheckbox(list);
  }, [customers]);

  useEffect(() => {
    getOpcoNumber({ session, form });
  }, [session]);

  const expandedRowRender = (customer, trainees, customerRef) => {
    let type = '';
    if (customerRef === 'Company') {
      type = 'company_convention';
    } else {
      type = 'private_convention';
    }

    const customersExpendedColumns = [
      {
        title: t('trainees.form.last_name'),
        dataIndex: ['last_name'],
        sorter: true
      },
      {
        title: t('trainees.form.first_name'),
        dataIndex: ['first_name'],
        sorter: true
      },
      {
        key: 'action',
        render: ({ key }) => (
          <div>
            <span style={{ float: 'right' }}>
              <Link
                to={{
                  pathname: `${routes.DATAS}${subRoutes.DATAS.TRAINEES}/edit/${key}`
                }}
              >
                <EditOutlined />
              </Link>
              <Divider type="vertical" />
              <Popconfirm
                title={t('datatable.column.action.delete.title')}
                okText={t('datatable.column.action.delete.ok')}
                okButtonProps={{ type: 'danger' }}
                cancelText={t('datatable.column.action.delete.cancel')}
                onConfirm={async () => {
                  await deleteTraineeFromCustomer(key, customer._id);
                }}
                icon={<WarningOutlined />}
              >
                <DeleteOutlined style={{ color: 'red' }} type="delete" />
              </Popconfirm>
            </span>
          </div>
        )
      }
    ];

    return (
      <>
        {customerRef !== 'Trainees' && (
          <>
            <Table
              columns={customersExpendedColumns}
              dataSource={trainees}
              pagination={false}
            />
            {purpose && purpose === 'trainees' && (
              <Button
                onClick={() => setVisibilityTraineeDrawer(true)}
                type="primary"
                style={{ margin: '16px 0', float: 'right' }}
              >
                {t('trainees.button.create')}
              </Button>
            )}
            <NewTraineeDrawer
              company={customer}
              isVisible={visibilityTraineeDrawer}
              setVisibilityTraineesDrawer={setNewTraineeDrawerVisibility}
              refresh={refresh}
              setRefresh={setRefresh}
              sessionState={drawerToParent}
            />
          </>
        )}
        {purpose && purpose === 'customers' && (
          <>
            <Row
              gutter={[16, 16]}
              style={{ marginTop: 16 }}
              justify="space-between"
            >
              <Col span={12}>
                <Card style={{ height: '100%' }}>
                  <h4 style={{ textAlign: 'center' }}>{t('Devis')}</h4>
                  {loading && customer?._id === customerId ? (
                    <LoadingOutlined />
                  ) : (
                    <></>
                  )}
                  <Dropdown
                    overlay={() => menuDropDown(customer?._id, 'generate')}
                    trigger={['click']}
                  >
                    <Button type="link">
                      {t('quotations.form.button.generateQuotation')}
                      <DownOutlined />
                    </Button>
                  </Dropdown>
                  {session.files.filter(
                    (el) =>
                      el.user?._id.toString() === customer?._id.toString() &&
                      el.type === 'quotations'
                  ).length !== 0 ? (
                    <ListFiles
                      showModal={showModal}
                      onAccept={onAccept}
                      menuDropDown={menuDropDown}
                      acceptedCheckbox={acceptedCheckbox}
                      idUser={customer?._id}
                      customer={{
                        ...customer,
                        status: acceptedCheckbox[customer?._id]
                          ? 'ACCEPTED'
                          : 'WAITING'
                      }}
                      purpose="customers"
                      files={session.files.filter(
                        (el) =>
                          el.user?._id.toString() ===
                            customer?._id.toString() && el.type === 'quotations'
                      )}
                      downloadFile={downloadFile}
                      reference={customerRef}
                      type="quotation"
                      deleteFileFromSession={deleteFileFromSession}
                    />
                  ) : (
                    <div style={{ marginTop: 20 }}>
                      <span>{t('quotations.error')}</span>
                    </div>
                  )}
                </Card>
              </Col>
              <Col span={12}>
                <Card style={{ height: '100%' }}>
                  <h4 style={{ textAlign: 'center' }}>{t('Convention')}</h4>
                  {session.files
                    .filter((el) => el.user?._id)
                    .filter(
                      (el) =>
                        el.user?._id.toString() === customer?._id.toString() &&
                        el.type === type
                    ).length !== 0 ? (
                    <ListFiles
                      showModal={showModal}
                      marginTop={40}
                      onAccept={onAccept}
                      menuDropDown={menuDropDown}
                      acceptedCheckbox={acceptedCheckbox}
                      idUser={customer?._id}
                      customer={{
                        ...customer,
                        status: acceptedCheckbox[customer?._id]
                          ? 'ACCEPTED'
                          : 'WAITING'
                      }}
                      files={session.files.filter(
                        (el) =>
                          el.user?._id.toString() ===
                            customer?._id.toString() && el.type === type
                      )}
                      downloadFile={downloadFile}
                      reference={customerRef}
                      purpose="customers"
                      type="convention"
                      deleteFileFromSession={deleteFileFromSession}
                    />
                  ) : (
                    <span style={{ marginTop: 40 }}>
                      {t('sessions.conventions.button.error')}
                    </span>
                  )}
                </Card>
              </Col>
              <Col span={24}>
                <Form form={form}>
                  <Form.Item
                    style={{ width: '50%' }}
                    name={[customer._id, 'opco_number']}
                    label={t('sessions.form.opco_number')}
                  >
                    <Input
                      disabled={
                        session?.customers.find(
                          (el) => el.customer._id === customer._id
                        ).status === 'ACCEPTED'
                      }
                    />
                  </Form.Item>
                </Form>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <h4
                  style={{
                    textAlign: 'center',
                    fontSize: 15,
                    fontWeight: 700,
                    marginTop: 24
                  }}
                >
                  {t('programs.program')}
                </h4>
                {session.program ? (
                  <AssociatedProgramToSession
                    updateSession={updateSession}
                    fetchData={fetchData}
                    session={session}
                    purpose="customers"
                    onAccept={onAccept}
                    idUser={customer?._id}
                    customer={{
                      ...customer,
                      status: acceptedCheckbox[customer?._id]
                        ? 'ACCEPTED'
                        : 'WAITING'
                    }}
                  />
                ) : (
                  <span style={{ marginTop: 40 }}>{t('programs.error')}</span>
                )}
              </Col>
            </Row>
          </>
        )}
      </>
    );
  };

  const expandable = () => {
    if (purpose && (purpose === 'signings' || purpose === 'trainees')) {
      return {
        expandedRowRender: (record) => {
          return expandedRowRender(
            record.customer,
            record.trainees_list.map((el) => ({ ...el, key: el._id }))
          );
        },
        rowExpandable: (record) => record.ref === 'Company'
      };
    }

    return {
      expandedRowRender: (record) => {
        return expandedRowRender(
          record.customer,
          record.trainees_list.map((el) => ({ ...el, key: el._id })),
          record.ref
        );
      }
    };
  };

  useEffect(() => {
    if (purpose === 'trainees') {
      const customersActionColumns = [
        {
          title: t('orders.show.table.total'),
          render: (record) => {
            let total = 0;
            modules.forEach((module) => {
              const amount = calculateRateModule(module, [record]);
              total += amount;
            });
            return <span> {parseFloat(total).toFixed(2)} € TTC</span>;
          }
        },
        {
          key: 'action',
          render: ({ key, ref }) => (
            <div>
              <span style={{ float: 'right' }}>
                <Link
                  to={{
                    pathname: `${routes.SESSIONS}/show/${
                      session._id
                    }/customers/${
                      ref === 'Company' ? 'company' : 'private'
                    }/edit/${key}`
                  }}
                >
                  <EditOutlined />
                </Link>
                <Divider type="vertical" />
                <Popconfirm
                  title={t('datatable.column.action.delete.title')}
                  okText={t('datatable.column.action.delete.ok')}
                  okButtonProps={{ type: 'danger' }}
                  cancelText={t('datatable.column.action.delete.cancel')}
                  onConfirm={async () => {
                    await deleteCustomerFromSession(key);
                  }}
                  icon={<WarningOutlined />}
                >
                  <DeleteOutlined style={{ color: 'red' }} type="delete" />
                </Popconfirm>
              </span>
            </div>
          )
        }
      ];
      setFinalColumns([...customersColumns, ...customersActionColumns]);
    } else {
      setFinalColumns(customersColumns);
    }
  }, [purpose, type, modules]);
  return (
    <>
      <ModalGeneratingFile
        isVisible={isModalGenerateFileVisible}
        setVisible={setIsModalGenerateFileVisible}
      />
      <ModalSendMails
        emailModel={emailModel}
        file={file}
        refresh={refresh}
        setRefresh={setRefresh}
        users={users}
        emailPurpose={emailPurpose}
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        session={session}
      />
      {customers && customers.length !== 0 && (
        <div className="site-card_border-less-wrapper">
          <Table
            className="components-table-demo-nested"
            columns={finalColumns}
            onExpand={(isExpanded, record) => {
              setExpandedKey(isExpanded ? record.key : null);
            }}
            expandable={expandable()}
            dataSource={customers}
            pagination={false}
          />
        </div>
      )}
      {purpose && type && purpose === 'trainees' && type === 'new' && (
        <Space style={{ float: 'right', margin: '20px 20px 10px 10px' }}>
          <Link
            to={{
              pathname: `${routes.SESSIONS}/show/${session._id}/customers/company/create`
            }}
          >
            <Button type="primary">{t('sessions.form.button.company')}</Button>
          </Link>
          <Link
            to={{
              pathname: `${routes.SESSIONS}/show/${session._id}/customers/private/create`
            }}
          >
            <Button type="primary">{t('sessions.form.button.customer')}</Button>
          </Link>
        </Space>
      )}
    </>
  );
};

export default SessionCustomersTable;
