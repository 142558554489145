import { message } from 'antd';

const getRedirectionFlow = async (
  dispatchAPI,
  user,
  organization,
  amount,
  subscriptionLabel,
  t
) => {
  const response = await dispatchAPI('POST', {
    url: '/payment-system/gocardless',
    body: {
      user: user._id,
      organization: organization._id,
      amount,
      subscriptionLabel
    }
  });

  if (response.data) return response.data.redirect_url;

  return message.error(t('payment.gc.errors.getdirectionflow'));
};

const getPrescriberRedirectionFlow = async ({ dispatchAPI, user, setUser }) => {
  try {
    const { data } = await dispatchAPI('POST', {
      url: '/payment-system/gocardless/prescriber',
      body: user
    });

    if (data.mandateLink) {
      setUser(data);
    }
  } catch (e) {
    if (e.response) message.error(e.response);
  }
};

const completeRedirectionFlow = async (
  dispatchAPI,
  user,
  t,
  redirect_flow_id,
  setUser
) => {
  const response = await dispatchAPI('PATCH', {
    url: '/payment-system/gocardless',
    body: {
      user,
      organization: user.organization,
      redirect_flow_id
    }
  });
  if (!response.data.errors) {
    const newOrganization = response.data.organization;

    if (newOrganization !== undefined) {
      const newUser = { ...user, organization: newOrganization };

      setUser(newUser);

      await dispatchAPI('PATCH', {
        url: `organizations/${newOrganization._id}`,
        body: { newOrganization }
      });

      return newOrganization;
    }
    return message.error(t('payment.gc.errors.userUndefined'));
  }
  return message.error(t('payment.gc.errors.confirmdirectionflow'));
};

const completePrescriberRedirectionFlow = async ({
  dispatchAPI,
  user,
  redirectFlow,
  setRefresh,
  refresh,
  t,
  setUser
}) => {
  try {
    const { data } = await dispatchAPI('PATCH', {
      url: '/payment-system/gocardless/prescriber',
      body: {
        user,
        organization: user.organization,
        redirect_flow_id: redirectFlow
      }
    });

    if (data.gcMandate) {
      setUser(data);
      setRefresh(!refresh);
      message.success(t('payment.gc.success.bank_details_success'));
    }
  } catch (e) {
    if (e.response) message.error(e.response);
  }
};

const launchPayment = async (dispatchAPI, organization, amount, t, ref) => {
  const response = await dispatchAPI('POST', {
    url: '/payment-system/gocardless/payment',
    body: { organization, amount: amount * 100, reference: ref }
  });
  if (response.data) {
    message.success(t('payment.gc.success.paymentSuccess'));
    return response.data;
  }
  message.error(t('payment.gc.errors.paymentFailed'));
  return null;
};

const launchSubscription = async (
  dispatchAPI,
  organization,
  amount,
  t,
  ref,
  subscriptionLabel,
  setUser,
  user
) => {
  const response = await dispatchAPI('POST', {
    url: '/payment-system/gocardless/subscription',
    body: {
      organization,
      amount: amount * 100,
      reference: ref,
      subscriptionLabel,
      user
    }
  });

  if (response.data && response.data.organization !== undefined) {
    const newUser = { ...user, organization: response.data.organization };
    setUser(newUser);
    message.success(t('payment.gc.success.subscriptionSuccess'));
    return response.data;
  }

  return message.error(t('payment.gc.errors.subscriptionFailed'));
};

const pay = (
  type,
  dispatchAPI,
  organization,
  amount,
  t,
  ref,
  subscriptionLabel,
  setUser,
  user
) => {
  if (type === 'payment')
    launchPayment(dispatchAPI, organization, amount, t, ref);
  else if (type === 'subscription') {
    launchSubscription(
      dispatchAPI,
      organization,
      amount,
      t,
      ref,
      subscriptionLabel,
      setUser,
      user
    );
  } else message.error(t('payment.gc.errors.gcTypeMissing'));
};

export {
  getRedirectionFlow,
  completeRedirectionFlow,
  pay,
  getPrescriberRedirectionFlow,
  completePrescriberRedirectionFlow
};
