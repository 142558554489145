import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import useFields from './fields';
import CreateUpdateContainer from '../../../components/CreateUpdateContainer/CreateUpdateContainer';

const CreateUpdateQualityIncidents = ({ purpose }) => {
  const { fields, isFieldsLoading } = useFields();

  const config = {
    onGetResource: {
      setFields: data => ({
        ...data,
        incident_date:
          data.incident_date && moment(data.incident_date).utc(false),
        incident_resolution_date:
          data.incident_resolution_date &&
          moment(data.incident_resolution_date).utc(false),
      }),
    },
  };

  return (
    <CreateUpdateContainer
      resource="quality_incidents"
      loadingFields={isFieldsLoading}
      config={config}
      fields={fields}
      purpose={purpose}
      baseUrl="quality_incidents"
      withSubRoutes
    />
  );
};

CreateUpdateQualityIncidents.propTypes = {
  purpose: PropTypes.string.isRequired,
};

export default CreateUpdateQualityIncidents;
