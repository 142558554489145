import React from 'react';
import { Alert, Modal } from 'antd';
import { CheckOutlined } from '@ant-design/icons';

const ModalGeneratingFile = ({ isVisible, setVisible }) => {
  return (
    <>
      <Modal
        visible={isVisible}
        footer={false}
        onCancel={() => setVisible(!isVisible)}
        className="session-confirmation-modal">
        <Alert
          message={
            <>
              <CheckOutlined
                style={{
                  color: 'var(--addColor)',
                  marginRight: 8,
                  fontSize: 18,
                }}
              />
              Génération de fichier
            </>
          }
          description="Le document a bien été généré, vous pouvez le télécharger ou l'envoyer par mail."
          type="info"
        />
      </Modal>
    </>
  );
};

export default ModalGeneratingFile;
