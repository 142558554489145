import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Chart, Axis, Tooltip, Geom, Legend } from 'bizcharts';
import moment from 'moment';
import numeral from 'numeral';

const CommissionsAmountIndicator = ({ commissions }) => {
  const [data, setData] = useState([]);
  const months = moment.months();

  const setCommissionsData = () => {
    const temp = [];
    months.forEach((month) => {
      let value = 0;
      const commissionsMonth = commissions.filter(
        (commission) =>
          moment(commission.date).format('MMMM') === month &&
          moment(commission.date).format('YYYY') === moment().format('YYYY') &&
          commission.status === 'PAID'
      );
      if (commissionsMonth) {
        commissionsMonth.forEach((comm) => {
          value += comm.amount;
        });
      }
      temp.push({
        month: `${month}-${moment().format('YYYY')}`,
        value
      });
    });
    setData(temp);
  };

  useEffect(() => {
    setCommissionsData();
  }, [commissions]);

  const cols = {
    value: { min: 0 }
  };
  return (
    <>
      <Chart height={400} data={data} scale={cols} padding={50} forceFit>
        <Axis name="month" />
        <Axis name="value" />
        <Legend />
        <Tooltip crosshairs={{ type: 'cross' }} />
        <Geom
          type="line"
          position="month*value"
          size={2}
          color="#FF6D00"
          tooltip={[
            'month*value',
            (month, value) => ({
              title: month,
              name: 'Montant',
              value: `${numeral(value).format('0[.]00 $')}`
            })
          ]}
        />
        <Geom
          type="point"
          position="month*value"
          size={4}
          shape="hollowCircle"
          style={{ stroke: '#fff', lineWidth: 1 }}
          color="#FF6D00"
          tooltip={[
            'month*value',
            (month, value) => ({
              title: month,
              name: 'Montant',
              value: `${numeral(value).format('0[.]00 $')}`
            })
          ]}
        />
      </Chart>
    </>
  );
};

CommissionsAmountIndicator.propTypes = {
  commissions: PropTypes.arrayOf({}).isRequired
};
export default CommissionsAmountIndicator;
