export const routes = {
  DASHBORD: '/dashboard',
  AGENDA: '/agenda',
  USERS: '/users',
  COMMERCIAL: '/commercial',
  SESSIONS: '/sessions',
  CATALOG: '/catalog',
  DATAS: '/datas',
  ACCOUNTING: '/accounting',
  DOCUMENTS: '/documents',
  SUBSCRIPTIONS: '/subscriptions',
  SPONSORSHIP: '/sponsorship',
  ADMIN_SPONSORSHIP: 'admin-sponsorship',
};

export const subRoutes = {
  DASHBORD: {
    INDICATOR: '/indicator',
    PEDAGOGIC_FINANCIAL_REPORT: '/pedagogic_financial_report',
    QUALITY_MONITOR: '/quality_monitor',
    QUALITY_INCIDENTS: '/quality_incidents',
  },
  CATALOG: {
    PROGRAMS: '/programs',
    QUIZZES_MODELS: '/quizzes_models',
  },
  DATAS: {
    TRAINEES: '/trainees',
    COMPANIES: '/companies',
    CONTACTS: '/contacts',
    CONTRIBUTORS: '/contributors',
    'EXTERNAL-FUNDERS': '/external-funders',
    TRAINING_PLACES: '/training_places',
  },
  ACCOUNTING: {
    INVOICES: '/invoices',
  },
  DOCUMENTS: {
    GED: '/ged',
    TEMPLATE_EMAILS: '/template_emails',
  },
};

export const outOfNavRoutes = {
  LOGIN: '/login',
  CONFIRMATION: '/email-confirmation',
  PROFILE: '/profile',
  SPONSOR: '/sponsor'
};
