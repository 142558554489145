import React, { useState } from 'react';
import { Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import KanbanBoard from './KanbanBoard';
import ListDeals from './ListDeals';
import PageHeaderCustom from '../../../components/PageHeader/PageHeader';
import ContentCustom from '../../../components/ContentCustom/ContentCustom';
import { DealsContextProvider } from './DealsContext';

const Deals = () => {
  const { t } = useTranslation();
  const [kanbanView, setKanbanView] = useState(true);

  return (
    <>
      <PageHeaderCustom
        title={t('deals.show.title')}
        withSubRoutes
        extra={
          <Switch
            onChange={() => setKanbanView(!kanbanView)}
            checked={kanbanView}
            className="kanban-switch"
            checkedChildren="Kanban"
            unCheckedChildren="Liste"
          />
        }
      />
      <ContentCustom>
        <DealsContextProvider>
          {kanbanView ? <KanbanBoard /> : <ListDeals />}
        </DealsContextProvider>
      </ContentCustom>
    </>
  );
};

export default Deals;
