import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import {
  Button,
  Card,
  Col,
  Divider,
  List,
  message,
  PageHeader,
  Popconfirm,
  Row,
  Skeleton
} from 'antd';
import { useTranslation } from 'react-i18next';
import {
  DeleteOutlined,
  EditOutlined,
  FileOutlined,
  RightOutlined,
  WarningOutlined
} from '@ant-design/icons';
import useAuthContext from '../../../contexts/AuthContext';
import ContentCustom from '../../../components/ContentCustom/ContentCustom';
import listContent from './listContent';
import DescriptionList from '../../../components/DescriptionList/DescriptionList';
import { routes, subRoutes } from '../../../utils/constants/routes';
import PageHeaderCustom from '../../../components/PageHeader/PageHeader';
import ModalUploadFiles from './modalUploadFiles';
import ErrorStatusCode from '../../../utils/ErrorStatusCode';

const DetailProgram = () => {
  const { id } = useParams();
  const history = useHistory();
  const { t } = useTranslation();
  const [program, setProgram] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const { notification } = ErrorStatusCode();

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/programs/${id}?populate=skills`
      });
      setProgram({
        ...data,
        educational_goals: data.educational_goals,
        trainees_profile: {
          customers: data.trainees_profile.customers,
          prerequisite: data.trainees_profile.prerequisite
        },
        execution_track: {
          ...data.execution_track,
          results_track: data.execution_track.results_track,
          educational_resources: data.execution_track.educational_resources
        }
      });
    } catch (e) {
      if (e.response) notification(e.response);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    (async () => {
      await fetchData();
    })();
  }, []);

  const deleteProgram = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/programs/${id}` });
      history.goBack();
    } catch (e) {
      if (e.response) message.error(e.response);
    }
  };

  const updateProgram = async (body) => {
    try {
      await dispatchAPI('PATCH', { url: `/programs/${id}`, body });
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
  };

  const downloadFile = async (itemId, name) => {
    try {
      const response = await dispatchAPI('GET', {
        url: `/files/${itemId}`,
        responseType: 'blob'
      });
      const blob = new Blob([response.data], {
        type: response.data.type
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = name;
      a.target = '_blank';
      a.click();
    } catch (e) {
      if (e.response) notification(e.response);
    }
    setIsDownloading({ ...isDownloading, [itemId]: false });
  };

  const deleteFile = async (itemId) => {
    try {
      await dispatchAPI('DELETE', {
        url: `/files/${itemId}`
      });
    } catch (e) {
      message.error(t('settings.errors.category_delete'));
    }
  };

  const deleteFileFromProgram = async (itemId) => {
    const files = program.files.filter((el) => el._id !== itemId);
    await updateProgram({ files });
    await deleteFile(itemId);
    await fetchData();
  };

  return (
    <>
      <PageHeaderCustom
        title={t('programs.show.name', {
          name: program.name
        })}
        withSubRoutes
        extra={
          <>
            <Link
              to={{
                pathname: `${routes.CATALOG}${subRoutes.CATALOG.PROGRAMS}/edit/${id}`
              }}
            >
              <Button type="primary">
                {`${t('buttons.edit')} `}
                <EditOutlined />
              </Button>
            </Link>
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ danger: true }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={deleteProgram}
              icon={<WarningOutlined />}
            >
              <Button type="link" danger>
                {`${t('buttons.delete')} `}
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </>
        }
      />
      <ContentCustom>
        <Skeleton loading={isLoading} active>
          <DescriptionList data={listContent(program)} translate />
        </Skeleton>
      </ContentCustom>
      <Divider orientation="left">
        <span>{t('programs.form.educational_goals')}</span>
      </Divider>
      <ContentCustom>
        <List
          dataSource={program.educational_goals || []}
          split={false}
          renderItem={(goal) => goal && <List.Item>{goal}</List.Item>}
        />
      </ContentCustom>

      <Divider orientation="left">
        <span>{t('programs.form.trainees_profile')}</span>
      </Divider>
      <Card bordered={false} className="card-no-border">
        <Row>
          <Col span={12}>
            <List
              dataSource={program.trainees_profile?.customers || []}
              split={false}
              header={<h3>{`${t('programs.form.for_trainees')} :`}</h3>}
              renderItem={(item) =>
                item && (
                  <List.Item>
                    <RightOutlined />
                    {item}
                  </List.Item>
                )
              }
            />
          </Col>
          <Col span={12}>
            <List
              dataSource={program.trainees_profile?.prerequisite}
              split={false}
              header={<h3>{`${t('programs.form.prerequisite')} :`}</h3>}
              renderItem={(item) =>
                item && (
                  <List.Item>
                    <RightOutlined />
                    {item}
                  </List.Item>
                )
              }
            />
          </Col>
        </Row>
      </Card>

      <Divider orientation="left">
        <span>{t('programs.form.training_content')}</span>
      </Divider>
      <Card bordered={false} className="card-no-border">
        {program.training_content && (
          <List
            dataSource={program?.training_content}
            split={false}
            renderItem={(item) =>
              item && (
                <>
                  <List.Item>
                    <RightOutlined />
                    {item.title}
                  </List.Item>
                  {item.list_items &&
                    item.list_items.map((el) => (
                      <>
                        <span style={{ marginLeft: 30 }}>{el.title}</span>
                        <br />
                      </>
                    ))}
                </>
              )
            }
          />
        )}
      </Card>
      <Divider />
      <Card bordered={false} className="card-no-border">
        <Row>
          <Col span={8}>
            <PageHeader title={t('programs.form.team_description')} />
            <p style={{ padding: '0 24px' }}>
              {program.execution_track?.team_description || ''}
            </p>
          </Col>
          <Col span={8}>
            <PageHeader title={t('programs.form.results_track')} />
            <List
              dataSource={program.execution_track?.results_track || []}
              split={false}
              style={{ padding: '0 24px' }}
              renderItem={(item) =>
                item && (
                  <List.Item>
                    <RightOutlined />
                    {item}
                  </List.Item>
                )
              }
            />
          </Col>
          <Col span={8}>
            <PageHeader title={t('programs.form.educational_resources')} />
            <List
              dataSource={program.execution_track?.educational_resources || []}
              split={false}
              style={{ padding: '0 24px' }}
              renderItem={(item) =>
                item && (
                  <List.Item>
                    <RightOutlined />
                    {item}
                  </List.Item>
                )
              }
            />
          </Col>
        </Row>
      </Card>

      <Divider orientation="left">
        <span>{t('programs.form.quality')}</span>
      </Divider>
      <Card bordered={false} className="card-no-border">
        {program.quality}
      </Card>
      <Divider orientation="left">
        <span>{t('programs.form.certification_modality')}</span>
      </Divider>
      <Card bordered={false} className="card-no-border">
        <Row>
          <Col span={8}>
            <PageHeader title={t('programs.form.certification_details')} />
            <p style={{ padding: '0 24px' }}>
              {program.certifications_modality?.certification_details || ''}
            </p>
          </Col>
          <Col span={8}>
            <PageHeader title={t('programs.form.expected_results')} />
            <p style={{ padding: '0 24px' }}>
              {program.certifications_modality?.expected_results || ''}
            </p>
          </Col>
          <Col span={8}>
            <PageHeader title={t('programs.form.obtaining_modality')} />
            <p style={{ padding: '0 24px' }}>
              {program.certifications_modality?.obtaining_modality || ''}
            </p>
          </Col>
          <Col span={8}>
            <PageHeader title={t('programs.form.validity_duration')} />
            <p style={{ padding: '0 24px' }}>
              {program.certifications_modality?.validity_duration || ''}
            </p>
          </Col>
        </Row>
      </Card>
      <Row>
        <Divider orientation="left">
          <span>{t('programs.form.skills_domain')}</span>
        </Divider>
        <Col span={8}>
          <List
            dataSource={program.skills}
            split={false}
            style={{ padding: '0 24px' }}
            renderItem={(item) =>
              item && (
                <List.Item>
                  <RightOutlined />
                  {item.name}
                </List.Item>
              )
            }
          />
        </Col>
      </Row>
      <Divider orientation="left">
        <span>{t('programs.form.externFiles')}</span>
      </Divider>
      <Button
        type="link"
        onClick={() => {
          setIsModalVisible(!isModalVisible);
        }}
        style={{ marginLeft: 10 }}
      >
        {t('programs.form.title.addFiles')}
      </Button>
      <ModalUploadFiles
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        id={program._id}
        fetchData={fetchData}
        uploadType="programs"
      />
      <Card style={{ margin: 20 }}>
        <List
          className="program-files-list"
          dataSource={program.files}
          split={false}
          renderItem={(item) =>
            item && (
              <List.Item>
                <List.Item.Meta
                  onClick={() => {
                    downloadFile(item._id, item.filename);
                  }}
                  avatar={<FileOutlined />}
                  title={item.filename}
                />
                <span style={{ float: 'right' }}>
                  <Popconfirm
                    title={t('datatable.column.action.delete.title')}
                    okText={t('datatable.column.action.delete.ok')}
                    okButtonProps={{ type: 'danger' }}
                    cancelText={t('datatable.column.action.delete.cancel')}
                    onConfirm={async () => {
                      await deleteFileFromProgram(item._id);
                    }}
                    icon={<WarningOutlined />}
                  >
                    <DeleteOutlined style={{ color: 'red' }} type="delete" />
                  </Popconfirm>
                </span>
              </List.Item>
            )
          }
        />
      </Card>
    </>
  );
};

export default DetailProgram;
