import { useState, useEffect } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';

const InvoicesColumns = () => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const [companies, setCompanies] = useState([]);

  const getCompanies = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/companies?fields=name,first_name,last_name'
      });
      setCompanies(data);
    } catch (error) {
      if (error.response) message(error.response.status);
    }
  };

  useEffect(() => {
    getCompanies();
  }, []);

  return [
    {
      title: t('invoices.list.column.title.number'),
      dataIndex: 'number',
      key: 'number',
      sorter: true
    },
    {
      title: t('invoices.list.column.title.customer'),
      dataIndex: 'customer',
      toRequest: 'ref,customer.name,customer.first_name,customer.last_name',
      key: 'customer',
      render: (customer) =>
        (customer && customer.name) ||
        `${customer?.last_name} ${customer?.first_name}`,
      sorter: true,
      filters: companies.map((company) => ({
        text: company?.name || `${company?.first_name} ${company?.last_name}`,
        value: company?._id
      }))
    },
    {
      title: t('invoices.list.column.title.session'),
      dataIndex: 'session',
      toRequest: 'session.name',
      key: 'session',
      render: (session) => session && session.name
    },
    {
      title: t('invoices.list.column.title.emission_date'),
      dataIndex: 'emission_date',
      key: 'emission_date',
      render: (date) => date && moment(date).format('DD/MM/YYYY'),
      sorter: true
    },
    {
      title: t('invoices.list.column.title.payment_date'),
      dataIndex: 'deadline_date',
      key: 'deadline_date',
      render: (date) => date && moment(date).format('DD/MM/YYYY'),
      sorter: true
    },
    {
      title: t('invoices.list.column.title.total_ht'),
      dataIndex: 'total_ht',
      key: 'total_ht',
      render: (total) => `${parseFloat(total).toFixed(2)}€`,
      sorter: true
    },
    {
      title: t('invoices.list.column.title.total'),
      dataIndex: 'total_ttc',
      key: 'total_ttc',
      render: (total) => `${parseFloat(total).toFixed(2)}€`,
      sorter: true
    },
    {
      title: t('invoices.list.column.title.total_paid'),
      dataIndex: 'total_paid',
      key: 'total_paid',
      render: (total) => (total ? `${parseFloat(total).toFixed(2)}€` : '-'),
      sorter: true
    }
  ];
};

export default InvoicesColumns;
