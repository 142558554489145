import React from 'react';
import { Progress, Skeleton, Typography } from 'antd';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import numeral from 'numeral';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  border: 1px solid lightgrey;
  padding: 10px;
  minheight: 150px;
`;
const { Title } = Typography;

const RemainingToPay = ({ isLoading, invoice }) => {
  const { t } = useTranslation();
  const remaining =
    invoice.total_ttc - (invoice.total_paid ? invoice.total_paid : 0);

  return (
    <Container>
      <Skeleton loading={isLoading} paragraph={{ rows: 1, width: '50%' }}>
        <Title level={5} style={{ marginBottom: '0', color: 'lightgrey' }}>
          {t('invoices.showInvoice.payments')}
        </Title>
        <Title level={4} style={{ marginTop: '0' }}>
          {t('invoices.show.remaining', {
            value: `${numeral(remaining).format('0,0.00')}€`,
          })}
        </Title>
        <Progress
          style={{ float: 'right', marginTop: -60 }}
          type="circle"
          percent={parseInt(
            ((invoice.total_ttc - remaining) * 100) / invoice.total_ttc
          )}
          width={65}
        />
        <ul>
          {invoice.payments &&
            invoice.payments.map(payment => (
              <li key={payment._id}>
                {new Date(payment.date).toLocaleDateString()} -{' '}
                {numeral(payment.amount_paid).format('0,0.00')}€
              </li>
            ))}
        </ul>
      </Skeleton>
    </Container>
  );
};

RemainingToPay.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  invoice: PropTypes.object.isRequired,
};

export default RemainingToPay;
