import React from 'react';
import PropTypes from 'prop-types';
import { Steps } from 'antd';

const { Step } = Steps;

const SelectDealStatus = ({ editStatus, dealStatusIndex, kanbanCols }) => {
  const onSelectChange = (value) => {
    editStatus(value);
  };

  return (
    <div>
      <Steps current={dealStatusIndex} progressDot onChange={onSelectChange}>
        {kanbanCols &&
          kanbanCols.map((item) => <Step title={item.label} key={item._id} />)}
      </Steps>
    </div>
  );
};

SelectDealStatus.propTypes = {
  editStatus: PropTypes.func.isRequired,
  dealStatusIndex: PropTypes.number.isRequired,
  kanbanCols: PropTypes.element.isRequired
};

export default SelectDealStatus;
