import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory, useParams } from 'react-router-dom';
import {
  Button,
  Col,
  Popconfirm,
  Row,
  Skeleton,
  Steps,
  Table,
  Typography
} from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import numeral from 'numeral';
import {
  WarningOutlined,
  CheckOutlined,
  UndoOutlined,
  LoadingOutlined,
  DownloadOutlined
} from '@ant-design/icons/lib';
import { routes } from '../../../utils/constants/routes';
import ErrorStatusCode from '../../../utils/ErrorStatusCode';
import useAuthContext from '../../../contexts/AuthContext';
import NextPrevButtons from './NextPrevButtons';
import ContentCustom from '../../../components/ContentCustom/ContentCustom';
import PageHeaderCustom from '../../../components/PageHeader/PageHeader';
import useErrorMessage from '../../../utils/ErrorMessage';
import RemainingToPay from './Components/RemainingToPay';
import useAccountingContext from '../AccountingContext';
import Organization from './Components/Organization';
import Customer from './Components/Customer';
import Contact from './Components/Contact';
import Datatable from '../../../components/DataTable/Datatable';
import CreditNoteColumn from './creditNoteColumn';
import { getUpdatedModuleData } from '../CreditNotes/utils/getUpdatedModuleData';

const { Title } = Typography;
const { Column } = Table;
const { Step } = Steps;
const borderBottom = '1px solid var(--borderColor)';

const ShowInvoice = ({ resource }) => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { notification } = ErrorStatusCode();
  const { id } = useParams();
  const history = useHistory();
  const { updateDocument } = useAccountingContext();
  const [session, setSession] = useState();
  const [customer, setCustomers] = useState();
  const [generatedFile, setGeneratedFile] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [isDownloading, setDownloading] = useState(false);
  const [displayedResource, setResource] = useState({});
  const [selectFileType, setSelectFileType] = useState();
  const [moduleData, setModuleData] = useState([]);

  const getSession = async (sessionId) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/sessions/${sessionId}?customer=${JSON.stringify([
          { path: 'program' },
          { path: 'contributors', populate: { path: 'contributor' } },
          { path: 'funders', populate: { path: 'funder' } },
          { path: 'files', populate: { path: 'user' } },
          { path: 'organization' },
          {
            path: 'customers',
            populate: [{ path: 'customer' }, { path: 'trainees_list' }]
          }
        ])}`
      });
      setSession(data);
    } catch (e) {
      if (e.response) {
        switch (e.response.status) {
          case 400:
            return message(t('errors_code.notification.status_400'));
          case 401:
            return message(t('errors_code.notification.status_401'));
          case 403:
            return message(t('errors_code.notification.status_403'));
          case 404:
            return message(t('errors_code.notification.status_404'));
          case 405:
            return message(t('errors_code.notification.status_405'));
          case 409:
            return message(t('errors_code.notification.status_409'));
          case 498:
            return message(t('errors_code.notification.status_498'));
          default:
            return message(e.response.status);
        }
      }
    }
    return null;
  };

  const getCreditNotes = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/credit-notes`
      });
      return data;
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
    return null;
  };

  const getResource = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/${resource}/${id}?populate=customer,contact,order`
      });
      setResource(data);
      setCustomers(data.customer._id);
      await getSession(data.session);
      await getUpdatedModuleData(data, getCreditNotes, setModuleData, message);
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  const fetchResource = useCallback(async () => {
    setLoading(true);
    await getResource();
    setLoading(false);
  }, [id]);

  useEffect(() => {
    fetchResource();
  }, [fetchResource, id]);

  const createOrder = async (body) => {
    try {
      const { data } = await dispatchAPI('POST', { url: '/orders', body });
      history.push({ pathname: `../../orders/show/${data._id}` });
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const deleteOrder = async (orderId) => {
    try {
      await dispatchAPI('DELETE', { url: `/orders/${orderId}` });
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const unvalidateQuotation = async () => {
    if (displayedResource.articles) {
      displayedResource.articles = displayedResource.articles.filter(
        (q) => q.name !== 'Remise globale'
      );
    }

    await updateDocument(
      displayedResource._id,
      { articles: displayedResource.articles, order: null },
      () => {}
    );
    await deleteOrder(displayedResource.order._id);
    fetchResource();
  };

  const getCurrentStep = (record) => {
    let step = 0;

    if (record.order) {
      step = 1;

      if (record.order.invoices.length > 0) {
        step = 2;
      }
    }

    return step;
  };

  useEffect(() => {
    if (session === undefined) return;
    const files = session.files
      .filter((file) => file.user?._id)
      .filter((file) => file.user._id === customer && file.type === 'invoices')
      .sort((a, b) => new Date(b.date) - new Date(a.date))[0];
    if (files) {
      const filelist = {
        fileName: files.filename,
        id: displayedResource._id
      };
      setGeneratedFile(filelist);

      if (filelist.fileName.includes('.pdf')) {
        setSelectFileType('pdf');
      } else {
        setSelectFileType('docx');
      }
    }
  }, [session]);

  const DlFile = async ({ fileId, name, fileType, type }) => {
    setDownloading(true);

    let tempType;
    switch (type) {
      case 'invoices':
        tempType = 'invoices';
        break;
      case 'credit_notes':
        tempType = 'credit-notes';
        break;
      default:
    }

    const templates = session.organization.templates.filter(
      (el) => el.type === type
    )[0]._id;

    try {
      const response = await dispatchAPI('GET', {
        url: `/files/generate/${tempType}/${fileId}/${templates}?fileType=${fileType}`,
        responseType: 'blob'
      });
      const blob = new Blob([response.data], {
        contentType: 'application/pdf'
      });

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = name;
      a.click();
    } catch (e) {
      if (e.response) notification(e.response);
    }
    setDownloading(false);
  };

  const onClick = async () => {
    if (displayedResource.global_discount) {
      displayedResource.articles.push({
        quantity: 1,
        VAT: 0,
        name: 'Remise globale',
        unit_price:
          displayedResource.global_discount.type === 'CURRENCY'
            ? -displayedResource.global_discount.value
            : -(
                (displayedResource.subTotal *
                  displayedResource.global_discount.value) /
                100
              ),
        subtotal:
          displayedResource.global_discount.type === 'CURRENCY'
            ? -displayedResource.global_discount.value
            : -(
                (displayedResource.subTotal *
                  displayedResource.global_discount.value) /
                100
              ),
        comment: displayedResource.global_discount.comment
      });
    }

    await updateDocument(
      displayedResource._id,
      { articles: displayedResource.articles },
      () => {}
    );
    await createOrder({
      ...displayedResource,
      quotation: displayedResource._id,
      emission_date: moment()
    });
  };

  return (
    <>
      <PageHeaderCustom
        title={t(`${resource}.show.number`, {
          number: displayedResource.number
        })}
        extra={
          <>
            {moduleData.length ? (
              <Button
                type="link"
                onClick={() =>
                  history.push(
                    `${routes.ACCOUNTING}/invoices/show/${id}/credit-note/${displayedResource.session}/${customer}/${displayedResource.ref}`
                  )
                }
                style={{ paddingLeft: 0 }}
              >
                {t('sessions.invoices.add_credit_note')}
              </Button>
            ) : null}
            <Button
              type="primary"
              icon={isDownloading ? <LoadingOutlined /> : <DownloadOutlined />}
              onClick={() =>
                DlFile({
                  fileId: generatedFile.id,
                  name: generatedFile.fileName,
                  fileType: selectFileType,
                  type: 'invoices'
                })
              }
            >
              {t('buttons.download')}
            </Button>
            {(!displayedResource.order || resource === 'invoices') &&
              !isLoading && (
                <>
                  {resource === 'quotations' && (
                    <Button
                      type="add"
                      icon={<CheckOutlined />}
                      onClick={onClick}
                    >
                      {t('buttons.validate')}
                    </Button>
                  )}
                </>
              )}
            {displayedResource.order && resource === 'quotations' && (
              <Popconfirm
                title={t('datatable.column.action.unvalidate.title')}
                okText={t('datatable.column.action.unvalidate.ok')}
                okButtonProps={{ type: 'danger' }}
                cancelText={t('datatable.column.action.unvalidate.cancel')}
                onConfirm={unvalidateQuotation}
                icon={<WarningOutlined />}
              >
                <Button
                  type="danger"
                  icon={<UndoOutlined />}
                  style={{ float: 'right' }}
                >
                  {t('buttons.unvalidate')}
                </Button>
              </Popconfirm>
            )}
          </>
        }
      />
      <ContentCustom>
        <NextPrevButtons
          resource={resource}
          emissionDate={displayedResource.emission_date}
        />

        {resource === 'quotations' && (
          <Row>
            <Col span={12} offset={6}>
              <Steps current={getCurrentStep(displayedResource)}>
                <Step
                  title={t('accounting.invoices.created_on')}
                  subTitle={moment(displayedResource.created_at).format(
                    'DD/MM/YYYY'
                  )}
                />
                <Step
                  title={
                    displayedResource.order
                      ? t('accounting.invoices.validate_on')
                      : t('accounting.invoices.waiting_for_validation')
                  }
                  subTitle={
                    displayedResource.order &&
                    moment(displayedResource.updated_at).format('DD/MM/YYYY')
                  }
                />
                <Step
                  title={
                    displayedResource.order &&
                    displayedResource.order.invoices.length > 0
                      ? t('accounting.invoices.invoiced_on')
                      : t('accounting.invoices.waiting_for_invoicing')
                  }
                  subTitle={
                    displayedResource.order &&
                    displayedResource.order.invoices.length > 0 &&
                    moment(displayedResource.updated_at).format('DD/MM/YYYY')
                  }
                />
              </Steps>
            </Col>
          </Row>
        )}

        <Row style={{ marginTop: '50px', marginBottom: '50px' }}>
          <Col span={6} style={{ paddingBottom: '10px' }}>
            <Organization
              display={displayedResource.organization}
              isLoading={isLoading}
            />
          </Col>
          <Col span={6} offset={12} style={{ paddingBottom: '10px' }}>
            <Customer
              customer={displayedResource.customer}
              isLoading={isLoading}
            />
          </Col>
          {resource === 'invoices' && (
            <Col span={8}>
              <RemainingToPay
                isLoading={isLoading}
                invoice={displayedResource}
              />
            </Col>
          )}
          <Col span={6} offset={resource === 'invoices' ? 12 : 18}>
            <Contact
              isLoading={isLoading}
              contact={displayedResource.contact}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Title level={3}>
              {t(`${resource}.show.title`)}
              &nbsp;
              {displayedResource.subject}
            </Title>
            {displayedResource.comments && (
              <i>
                {t(`${resource}.show.comment`)}
                &nbsp;
                {displayedResource.comments}
              </i>
            )}
            <p>
              {t(`${resource}.show.emission`, {
                date: moment(displayedResource.emission_date).format('LL')
              })}
              <br />
              {displayedResource.deadline_date &&
                t(`${resource}.show.payment`, {
                  date: moment(displayedResource.deadline_date).format('LL')
                })}
              <br />
              {displayedResource.total_paid &&
                displayedResource.total_paid !== displayedResource.total &&
                t(`${resource}.show.remaining`, {
                  value: numeral(
                    displayedResource.total_ttc - displayedResource.total_paid
                  ).format('0,0.00')
                })}
              {displayedResource.order && resource === 'quotations' && (
                <>
                  {displayedResource.updated_at &&
                    t(`${resource}.show.validated`, {
                      date: moment(displayedResource.updated_at).format('LL')
                    })}
                  &nbsp;-&nbsp;
                  <Link to={`/orders/show/${displayedResource.order._id}`}>
                    {displayedResource.order.number}
                  </Link>
                </>
              )}
            </p>
          </Col>
        </Row>

        <Skeleton
          active
          title={false}
          loading={isLoading}
          paragraph={{
            rows: 4,
            width: ['100%', '100%', '100%', '100%']
          }}
        >
          <Table
            dataSource={(
              displayedResource.items || []
            ).map(({ _id, ...item }) => ({ ...item, key: _id }))}
            pagination={false}
          >
            <Column
              title={t('invoices.showInvoice.articlesTable.name')}
              dataIndex="name"
            />
            <Column
              title={t('invoices.showInvoice.articlesTable.quantity')}
              dataIndex="quantity"
              align="right"
            />
            <Column
              title={t('invoices.showInvoice.articlesTable.unit_price')}
              dataIndex="rate"
              align="right"
              render={(price) => `${numeral(price).format('0,0.00')}€`}
            />
            <Column
              title={t('invoices.showInvoice.articlesTable.discount')}
              dataIndex="discount"
              align="right"
              render={(discount) =>
                discount
                  ? `${numeral(discount.value).format('0,0.00')}${
                      discount.type === 'PERCENT' ? '%' : '€'
                    }`
                  : 'N/A'
              }
            />
            <Column
              title={t('invoices.showInvoice.articlesTable.VAT')}
              dataIndex="tva"
              align="right"
              render={(vat) => `${vat}%`}
            />
            <Column
              title={t('invoices.showInvoice.articlesTable.subTotal')}
              dataIndex="total_ht"
              align="right"
              render={(total) => `${total}€`}
            />
          </Table>
        </Skeleton>
        <Row style={{ marginTop: 50 }}>
          <Col
            style={{
              background: 'var(--bodyBackground)',
              border: '1px solid var(--borderColor)',
              padding: 8
            }}
            xs={{ offset: 8, span: 16 }}
            md={{ offset: 14, span: 10 }}
          >
            <Skeleton active loading={isLoading} paragraph={0}>
              <Row style={{ borderBottom }}>
                <Col span={8} style={{ textAlign: 'left' }}>
                  <Typography.Text style={{ fontSize: '16px' }}>
                    {`${t('invoices.showInvoice.subTotal')} `}
                  </Typography.Text>
                </Col>
                <Col span={16} style={{ textAlign: 'right' }}>
                  <Typography.Text style={{ fontSize: '16px' }}>
                    {`${numeral(displayedResource.total_ht || 0).format(
                      '0,0.00'
                    )}€`}
                  </Typography.Text>
                </Col>
              </Row>
              <Row style={{ borderBottom }}>
                <Col span={8} style={{ textAlign: 'left' }}>
                  <Typography.Text style={{ fontSize: '16px' }}>
                    {t('invoices.showInvoice.vat')}
                  </Typography.Text>
                </Col>
                <Col span={16} style={{ textAlign: 'right' }}>
                  <Typography.Text style={{ fontSize: '16px' }}>
                    {`${numeral(
                      displayedResource.total_ttc -
                        displayedResource.total_ht || 0
                    ).format('0,0.00')}€`}
                  </Typography.Text>
                </Col>
              </Row>
              <Row>
                <Col span={8} style={{ textAlign: 'left' }}>
                  <Typography.Text style={{ fontSize: '16px' }}>
                    {t('invoices.showInvoice.discount')}
                  </Typography.Text>
                </Col>
                <Col span={16} style={{ textAlign: 'right' }}>
                  <Typography.Text style={{ fontSize: '16px' }}>
                    {`${numeral(displayedResource.total_discount || 0).format(
                      '0,0.00'
                    )}€`}
                  </Typography.Text>
                </Col>
              </Row>
            </Skeleton>
          </Col>
        </Row>
        <Row style={{ margin: '30px 0' }}>
          <Col xs={{ offset: 8, span: 16 }} md={{ offset: 14, span: 10 }}>
            <Skeleton active loading={isLoading} paragraph={0}>
              <Row type="flex" style={{ height: '100%' }}>
                <Col span={8} style={{ textAlign: 'left' }}>
                  <Typography.Text strong style={{ fontSize: '18px' }}>
                    {t('invoices.showInvoice.totalDue')}
                  </Typography.Text>
                </Col>
                <Col
                  span={16}
                  style={{
                    textAlign: 'right',
                    display: 'flex',
                    alignItems: 'flex-end',
                    justifyContent: 'flex-end'
                  }}
                >
                  <Typography.Text strong style={{ fontSize: '18px' }}>
                    {`${numeral(displayedResource.total_ttc || 0).format(
                      '0,0.00'
                    )}€`}
                  </Typography.Text>
                </Col>
              </Row>
            </Skeleton>
          </Col>
        </Row>
        <Title level={3}>{t(`${resource}.show.credit_note_title`)}</Title>
        <Datatable
          resourceName="credit-notes"
          extraQuery={
            displayedResource._id ? `invoice=${displayedResource._id}` : null
          }
          columns={CreditNoteColumn({ isDownloading, DlFile })}
          populate="invoice"
          customActionColumn
        />
      </ContentCustom>
    </>
  );
};

ShowInvoice.propTypes = {
  resource: PropTypes.string.isRequired
};

export default ShowInvoice;
