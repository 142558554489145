import React, { useEffect, useState } from 'react';
import {
  Card,
  Button,
  Col,
  Collapse,
  Dropdown,
  List,
  Menu,
  message,
  Row,
  Divider,
  Tag,
  Alert
} from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import {
  CheckOutlined,
  DownloadOutlined,
  DownOutlined,
  LoadingOutlined,
  SendOutlined
} from '@ant-design/icons';
import useAuthContext from '../../../contexts/AuthContext';
import { routes } from '../../../utils/constants/routes';
import ModalGeneratingFile from '../Convocations/modalGeneratingFile';
import ModalSendMails from '../Convocations/modalSendMails';
import ErrorStatusCode from '../../../utils/ErrorStatusCode';
import { getPayments } from './utils/getPayments';
import { getTotalHT } from './utils/getTotalHT';
import { getInvoices } from './utils/getInvoices';
import { getCreditNotes } from './utils/getCreditNotes';
import { getModules } from './utils/getModules';
import { checkInvoiceCreated } from './utils/checkInvoiceCreated';

const { Panel } = Collapse;

const ProductList = ({
  customers,
  session,
  fetchData,
  contacts,
  forceRefresh,
  setForceRefresh,
  emails
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { notification } = ErrorStatusCode();
  const { dispatchAPI, user } = useAuthContext();
  const { id } = useParams();
  const [users, setUsers] = useState([]);
  const [file, setFile] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [payments, setPayments] = useState([]);
  const [creditNotes, setCreditNotes] = useState([]);
  const [modules, setModules] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [generateStatus, setGenerateStatus] = useState(false);
  const [totalHT, setTotalHT] = useState([]);
  const [isModalGenerateFileVisible, setIsModalGenerateFileVisible] = useState(
    false
  );
  const [disabled, setDisabled] = useState([]);

  useEffect(() => {
    if (customers.length && creditNotes.length) {
      getTotalHT({
        customers,
        creditNotes,
        invoices,
        session,
        setTotalHT
      });
    }
  }, [customers, creditNotes]);

  const fetchInvoiceData = async () => {
    try {
      await Promise.all([
        getModules({ dispatchAPI, setModules, id, message, t }),
        getPayments({ dispatchAPI, setPayments, notification }),
        getInvoices({ dispatchAPI, setInvoices, message, t }),
        getCreditNotes({ dispatchAPI, setCreditNotes, message, t })
      ]);
    } catch (error) {
      message.error(error);
    }
  };

  useEffect(() => {
    (async () => {
      await fetchInvoiceData();
    })();
  }, []);

  useEffect(() => {
    checkInvoiceCreated({
      customers,
      invoices,
      modules,
      setDisabled
    });
  }, [session?.files, customers]);

  const generateFile = async (temp, invoiceId, fileType, type) => {
    let tempType;
    switch (type) {
      case 'invoices':
        tempType = 'invoices';
        break;
      case 'credit_notes':
        tempType = 'credit-notes';
        break;
      default:
    }
    try {
      message.info(t('sessions.invoices.messages.loading_document'), 5);
      setGenerateStatus(true);
      await dispatchAPI('GET', {
        url: `/files/generate/${tempType}/${invoiceId}/${temp._id}?fileType=${fileType}`,
        responseType: 'blob'
      });

      await dispatchAPI('PATCH', {
        url: `/${tempType}/${invoiceId}`,
        body: { emission_date: new Date() }
      });

      setIsModalGenerateFileVisible(!isModalGenerateFileVisible);
      await fetchData();
      setGenerateStatus(false);
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  const downloadFile = async (itemId, type) => {
    let generatedFile;
    switch (type) {
      case 'invoices':
        generatedFile = session.files
          .filter((el) => el.invoice_id === itemId)
          .reduce((acc, el) => {
            if (!acc || new Date(el.date) > new Date(acc.date)) {
              return el;
            }
            return acc;
          }, null);
        break;
      case 'credit_notes':
        generatedFile = session.files
          .filter((el) => el.credit_note_id === itemId)
          .reduce((acc, el) => {
            if (!acc || new Date(el.date) > new Date(acc.date)) {
              return el;
            }
            return acc;
          }, null);
        break;
      default:
    }
    try {
      const response = await dispatchAPI('GET', {
        url: `/files/${generatedFile._id}`,
        responseType: 'blob'
      });
      const blob = new Blob([response.data], {
        type: response.data.type
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = generatedFile.filename;
      a.target = '_blank';
      a.click();
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  const showModal = (purpose, customerId, fileId, invoiceType) => {
    let generatedFile;
    switch (purpose) {
      case 'Trainees':
        setUsers(
          customers
            .filter((el) => el.customer._id === customerId)
            .map(({ customer }) => ({
              ...customer,
              ref: 'Trainees'
            }))
        );
        break;
      case 'Company':
        setUsers(
          contacts
            .filter(
              (el) =>
                el.my_company._id === customerId && el.company_representative
            )
            .map((el) => ({
              ...el,
              ref: 'Contact'
            }))
        );
        break;
      default:
    }

    switch (invoiceType) {
      case 'invoices':
        generatedFile = session.files
          .filter((el) => el.invoice_id === fileId)
          .reduce((acc, el) => {
            if (!acc || new Date(el.date) > new Date(acc.date)) {
              return el;
            }
            return acc;
          }, null);
        break;
      case 'credit_notes':
        generatedFile = session.files
          .filter((el) => el.credit_note_id === fileId)
          .reduce((acc, el) => {
            if (!acc || new Date(el.date) > new Date(acc.date)) {
              return el;
            }
            return acc;
          }, null);
        break;
      default:
    }

    const newGeneratedFile = {
      fileName: generatedFile.filename,
      id: generatedFile._id
    };

    setFile([newGeneratedFile]);
    setIsModalVisible(!isModalVisible);
  };

  const menuGenerateDropDown = (idInvoice, type) => {
    const templates = user.organization.templates.filter(
      (el) => el.type === type
    );

    const menu = (
      <Menu>
        {templates.map((temp) => (
          <>
            <Menu.Item key="1">
              {generateStatus ? (
                <Button disabled type="link">
                  {`${temp.name.split('.')[0]} docx`}
                </Button>
              ) : (
                <Button
                  type="link"
                  style={{ width: '100%', textAlign: 'left' }}
                  onClick={() => generateFile(temp, idInvoice, 'docx', type)}
                >
                  {`${temp.name.split('.')[0]} docx`}
                </Button>
              )}
            </Menu.Item>
            <Menu.Item key="2">
              {generateStatus ? (
                <Button disabled type="link">
                  {`${temp.name.split('.')[0]} pdf`}
                </Button>
              ) : (
                <Button
                  type="link"
                  style={{ width: '100%', textAlign: 'left' }}
                  onClick={() => generateFile(temp, idInvoice, 'pdf', type)}
                >
                  {`${temp.name.split('.')[0]} pdf`}
                </Button>
              )}
            </Menu.Item>
          </>
        ))}
      </Menu>
    );
    return menu;
  };

  const actionsMenu = (invoice, type) => {
    let amount = 0;
    let payment;

    switch (type) {
      case 'invoice':
        payment = payments
          .filter((el) => el?._id)
          .filter((el) => el.invoice?._id === invoice._id);
        payment.forEach((item) => {
          amount += item.amount;
        });
        break;
      case 'credit_note':
        payment = payments
          .filter((el) => el?._id)
          .filter((el) => el.credit_note?._id === invoice._id);
        payment.forEach((item) => {
          amount += item.amount;
        });
        break;
      default:
    }
    return amount;
  };

  const listActions = (userId, itemId, ref) => {
    let actions = [];
    session.files.forEach((el) => {
      if (
        el.invoice_id &&
        el.invoice_id.toString() === itemId.toString() &&
        el.type === 'invoices'
      ) {
        actions = [
          <>
            <Tag style={{ fontSize: 15 }} color="orange">
              <span>
                <CheckOutlined style={{ marginRight: 5 }} />
                {`${t('sessions.invoices.generate_the')} ${moment(
                  el.date
                ).format('DD-MM-YYYY')} `}
              </span>
              <span>
                {`${t('sessions.invoices.format')} ${
                  el.filename.split('.')[1]
                }`}
              </span>
            </Tag>
          </>
        ];
      }

      if (
        el.credit_note_id &&
        el.credit_note_id.toString() === itemId.toString() &&
        el.type === 'credit_notes'
      ) {
        actions = [
          <>
            <Tag style={{ fontSize: 15 }} color="orange">
              <span>
                <CheckOutlined style={{ marginRight: 5 }} />
                {`${t('sessions.invoices.generate_the')} ${moment(
                  el.date
                ).format('DD-MM-YYYY')} `}
              </span>
              <span>
                {`${t('sessions.invoices.format')} ${
                  el.filename.split('.')[1]
                }`}
              </span>
            </Tag>
          </>
        ];
      }
    });
    if (ref === 'Company') {
      const contactsList = contacts
        .filter((el) => el?._id)
        .filter(
          (el) =>
            el.my_company._id.toString() === itemId.toString() &&
            el.company_representative
        )
        .map((el) => el._id);
      const emailsList = emails
        .filter((el) => el.user?._id)
        .filter(
          (el) =>
            contactsList.includes(el.user._id.toString()) &&
            el.purpose === 'invoices'
        );
      if (emailsList.length) {
        actions.push(
          <Tag color="green">
            <span style={{ fontSize: 15 }}>
              <CheckOutlined style={{ marginRight: 5 }} />
              {t('sessions.invoices.sent')}
            </span>
          </Tag>
        );
      }
    } else {
      const email = emails
        .filter((el) => el.user?._id)
        .find(
          (el) =>
            el.user._id.toString() === itemId.toString() &&
            el.purpose === 'invoices'
        );
      if (email) {
        actions.push(
          <Tag color="green">
            <span style={{ fontSize: 15 }}>
              <CheckOutlined style={{ marginRight: 5 }} />
              {t('sessions.invoices.sent')}
            </span>
          </Tag>
        );
      }
    }
    return actions;
  };

  return (
    <>
      <ModalSendMails
        emailModel="invoice_model"
        file={file}
        users={users}
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        emailPurpose="invoices"
        refresh={forceRefresh}
        setRefresh={setForceRefresh}
      />
      <ModalGeneratingFile
        isVisible={isModalGenerateFileVisible}
        setVisible={setIsModalGenerateFileVisible}
      />
      <Collapse accordion>
        {customers?.map((customer) => (
          <Panel
            header={customer.customer.name || customer.customer.last_name}
            key={customer._id}
          >
            <Button
              disabled={disabled[customer.customer._id]}
              type="link"
              onClick={() =>
                history.push(
                  `/sessions/show/${session._id}/invoices/${customer.ref}/${customer.customer._id}`
                )
              }
            >
              {t('sessions.invoices.add_invoice')}
            </Button>
            {totalHT &&
              totalHT
                .filter((el) => el?.customer === customer.customer._id)
                .map((element) => {
                  if (
                    element?.credit_note_total_ht > element?.invoice_total_ht
                  ) {
                    return (
                      <Alert
                        message={`${t('sessions.invoices.total_ht_alert')} ${
                          element.invoice_number
                        }`}
                        type="warning"
                      />
                    );
                  }
                  return null;
                })}
            <Divider orientation="left">
              <span style={{ fontSize: 15 }}>
                {t('sessions.invoices.invoices')}
              </span>
            </Divider>
            <Card>
              <List
                dataSource={invoices
                  .filter((el) => el?._id)
                  .filter(
                    (el) =>
                      el.session === session._id &&
                      el.customer === customer.customer._id
                  )}
                renderItem={(item) => (
                  <List.Item style={{ position: 'relative' }}>
                    <List.Item.Meta
                      title={
                        <Row>
                          <Col span={4}>
                            <span>
                              {moment(item.emission_date).format('DD-MM-YYYY')}
                            </span>
                          </Col>
                          <Col span={6}>
                            <span>
                              {`${t('sessions.invoices.invoice')} ${
                                item.number
                              }`}
                            </span>
                          </Col>
                        </Row>
                      }
                      description={
                        <Row
                          style={{
                            width: 'calc(100vw - 370px)',
                            marginTop: 30
                          }}
                          align="middle"
                        >
                          <Col span={12}>
                            <Button
                              type="link"
                              onClick={() =>
                                history.push(
                                  `${routes.SESSIONS}/show/${session._id}/payment/${item._id}/invoice`,
                                  item
                                )
                              }
                              style={{ paddingLeft: 0 }}
                            >
                              {t('sessions.invoices.add_payment')}
                            </Button>

                            <Button
                              type="link"
                              onClick={() =>
                                history.push(
                                  `${routes.SESSIONS}/show/${session._id}/credit-note/${customer.customer._id}/${customer.ref}/${item._id}`,
                                  item
                                )
                              }
                              style={{ paddingLeft: 0 }}
                            >
                              {t('sessions.invoices.add_credit_note')}
                            </Button>
                            <h1>{customer.customer_id}</h1>
                          </Col>
                          <Col span={12}>
                            <span style={{ float: 'right' }}>
                              <span style={{ paddingRight: 50 }}>
                                {listActions(
                                  item.customer,
                                  item._id,
                                  customer.ref
                                )}
                              </span>
                              {generateStatus && <LoadingOutlined />}
                              <Dropdown
                                overlay={menuGenerateDropDown(
                                  item?._id,
                                  'invoices'
                                )}
                              >
                                <Button type="link">
                                  {t('sessions.invoices.generate_invoice')}
                                  <DownOutlined />
                                </Button>
                              </Dropdown>
                              {session.files.filter(
                                (element) => element.invoice_id === item._id
                              ).length ? (
                                <>
                                  <Divider type="vertical" />
                                  <Button
                                    type="link"
                                    icon={<DownloadOutlined />}
                                    onClick={() =>
                                      downloadFile(item._id, 'invoices')
                                    }
                                  />
                                  <Divider type="vertical" />
                                  <Button
                                    type="link"
                                    icon={<SendOutlined />}
                                    onClick={() => {
                                      showModal(
                                        customer.ref,
                                        item.customer,
                                        item._id,
                                        'invoices'
                                      );
                                    }}
                                  />
                                </>
                              ) : null}
                            </span>
                          </Col>
                        </Row>
                      }
                    />
                    <Row
                      style={{
                        position: 'absolute',
                        top: 10,
                        right: 0
                      }}
                      align="middle"
                    >
                      <span
                        style={{
                          display: 'inline-flex',
                          alignItems: 'center',
                          flexDirection: 'column',
                          float: 'right',
                          margin: '0 24px'
                        }}
                      >
                        <span
                          style={{ fontSize: 11, textTransform: 'uppercase' }}
                        >
                          {t('sessions.invoices.ht_amount')}
                        </span>
                        <span style={{ fontWeight: 700 }}>
                          {`${parseFloat(item.total_ht).toFixed(2)} €`}
                        </span>
                      </span>
                      <Divider type="vertical" />
                      <span
                        style={{
                          display: 'inline-flex',
                          alignItems: 'center',
                          flexDirection: 'column',
                          float: 'right',
                          margin: '0 24px'
                        }}
                      >
                        <span
                          style={{ fontSize: 11, textTransform: 'uppercase' }}
                        >
                          {t('sessions.invoices.ttc_amount')}
                        </span>
                        <span style={{ fontWeight: 700 }}>
                          {`${parseFloat(item.total_ttc).toFixed(2)} €`}
                        </span>
                      </span>
                      <Divider type="vertical" />
                      <span
                        style={{
                          display: 'inline-flex',
                          alignItems: 'center',
                          flexDirection: 'column',
                          float: 'right',
                          margin: '0 24px'
                        }}
                      >
                        <span
                          style={{ fontSize: 11, textTransform: 'uppercase' }}
                        >
                          {t('sessions.invoices.paid_amount')}
                        </span>
                        <span
                          style={{
                            fontWeight: 700,
                            color:
                              actionsMenu(item, 'invoice') < item.total_ttc
                                ? 'red'
                                : 'green'
                          }}
                        >
                          {`${parseFloat(
                            actionsMenu(item, 'invoice').toFixed(2)
                          )} €`}
                        </span>
                      </span>
                      {actionsMenu(item, 'invoice') > 0 < item.total_ttc && (
                        <>
                          <Divider type="vertical" />
                          <span
                            style={{
                              display: 'inline-flex',
                              alignItems: 'center',
                              flexDirection: 'column',
                              float: 'right',
                              marginLeft: 24
                            }}
                          >
                            <span
                              style={{
                                fontSize: 11,
                                textTransform: 'uppercase'
                              }}
                            >
                              {t('sessions.invoices.left_to_pay')}
                            </span>
                            <span
                              style={{
                                fontWeight: 700,
                                color:
                                  actionsMenu(item, 'invoice') >
                                  0 <
                                  item.total_ttc
                                    ? 'red'
                                    : 'green'
                              }}
                            >
                              {parseFloat(
                                item.total_ttc - actionsMenu(item, 'invoice')
                              ).toFixed(2)}
                              €
                            </span>
                          </span>
                        </>
                      )}
                    </Row>
                  </List.Item>
                )}
              />
            </Card>

            <Divider orientation="left">
              <span style={{ fontSize: 15 }}>
                {t('sessions.invoices.credit_notes')}
              </span>
            </Divider>

            <Card>
              <List
                dataSource={creditNotes
                  .filter((el) => el?._id)
                  .filter(
                    (el) =>
                      el.session === session._id &&
                      el.customer === customer.customer._id
                  )}
                renderItem={(item) => (
                  <List.Item style={{ position: 'relative' }}>
                    <List.Item.Meta
                      title={
                        <Row>
                          <Col span={2}>
                            <span>
                              {moment(item.emission_date).format('DD-MM-YYYY')}
                            </span>
                          </Col>
                          <Col span={4}>
                            <span>
                              {`${t('sessions.invoices.credit_note')} ${
                                item.number
                              }`}
                            </span>
                          </Col>
                          <Col span={4}>
                            <span>
                              {`${t('sessions.invoices.invoice_ref')} ${
                                item.invoice.number
                              }`}
                            </span>
                          </Col>
                        </Row>
                      }
                      description={
                        <Row
                          style={{
                            width: 'calc(100vw - 370px)',
                            marginTop: 30
                          }}
                          align="middle"
                        >
                          <Col span={12}>
                            <Button
                              type="link"
                              onClick={() =>
                                history.push(
                                  `${routes.SESSIONS}/show/${session._id}/payment/${item._id}/credit_note`,
                                  item
                                )
                              }
                              style={{ paddingLeft: 0 }}
                            >
                              {t('sessions.invoices.add_payment')}
                            </Button>
                          </Col>
                          <Col span={12}>
                            <span style={{ float: 'right' }}>
                              <span style={{ paddingRight: 50 }}>
                                {listActions(
                                  item.customer,
                                  item._id,
                                  customer.ref
                                )}
                              </span>
                              {generateStatus && <LoadingOutlined />}
                              <Dropdown
                                overlay={menuGenerateDropDown(
                                  item?._id,
                                  'credit_notes'
                                )}
                              >
                                <Button type="link">
                                  {t('sessions.invoices.generate_credit_note')}
                                  <DownOutlined />
                                </Button>
                              </Dropdown>
                              {session.files.filter(
                                (element) => element.credit_note_id === item._id
                              ).length ? (
                                <>
                                  <Divider type="vertical" />
                                  <Button
                                    type="link"
                                    icon={<DownloadOutlined />}
                                    onClick={() =>
                                      downloadFile(item._id, 'credit_notes')
                                    }
                                  />
                                  <Divider type="vertical" />
                                  <Button
                                    type="link"
                                    icon={<SendOutlined />}
                                    onClick={() => {
                                      showModal(
                                        customer.ref,
                                        item.customer,
                                        item._id,
                                        'credit_notes'
                                      );
                                    }}
                                  />
                                </>
                              ) : null}
                            </span>
                          </Col>
                        </Row>
                      }
                    />
                    <Row
                      style={{
                        position: 'absolute',
                        top: 10,
                        right: 0
                      }}
                      align="middle"
                    >
                      <span
                        style={{
                          display: 'inline-flex',
                          alignItems: 'center',
                          flexDirection: 'column',
                          float: 'right',
                          margin: '0 24px'
                        }}
                      >
                        <span
                          style={{ fontSize: 11, textTransform: 'uppercase' }}
                        >
                          {t('sessions.invoices.ht_amount')}
                        </span>
                        <span style={{ fontWeight: 700 }}>
                          {`${parseFloat(item.total_ht).toFixed(2)} €`}
                        </span>
                      </span>
                      <Divider type="vertical" />
                      <span
                        style={{
                          display: 'inline-flex',
                          alignItems: 'center',
                          flexDirection: 'column',
                          float: 'right',
                          margin: '0 24px'
                        }}
                      >
                        <span
                          style={{ fontSize: 11, textTransform: 'uppercase' }}
                        >
                          {t('sessions.invoices.ttc_amount')}
                        </span>
                        <span style={{ fontWeight: 700 }}>
                          {`${parseFloat(item.total_ttc).toFixed(2)} €`}
                        </span>
                      </span>
                      <Divider type="vertical" />
                      <span
                        style={{
                          display: 'inline-flex',
                          alignItems: 'center',
                          flexDirection: 'column',
                          float: 'right',
                          margin: '0 24px'
                        }}
                      >
                        <span
                          style={{ fontSize: 11, textTransform: 'uppercase' }}
                        >
                          {t('sessions.invoices.paid_amount')}
                        </span>
                        <span
                          style={{
                            fontWeight: 700,
                            color:
                              actionsMenu(item, 'credit_note') < item.total_ttc
                                ? 'red'
                                : 'green'
                          }}
                        >
                          {parseFloat(
                            actionsMenu(item, 'credit_note').toFixed(2)
                          )}
                          €
                        </span>
                      </span>
                      {actionsMenu(item, 'credit_note') >
                        0 <
                        item.total_ttc && (
                        <>
                          <Divider type="vertical" />
                          <span
                            style={{
                              display: 'inline-flex',
                              alignItems: 'center',
                              flexDirection: 'column',
                              float: 'right',
                              marginLeft: 24
                            }}
                          >
                            <span
                              style={{
                                fontSize: 11,
                                textTransform: 'uppercase'
                              }}
                            >
                              {t('sessions.invoices.due_amount')}
                            </span>
                            <span
                              style={{
                                fontWeight: 700,
                                color:
                                  actionsMenu(item, 'credit_note') >
                                  0 <
                                  item.total_ttc
                                    ? 'red'
                                    : 'green'
                              }}
                            >
                              {parseFloat(
                                item.total_ttc -
                                  actionsMenu(item, 'credit_note')
                              ).toFixed(2)}
                              €
                            </span>
                          </span>
                        </>
                      )}
                    </Row>
                  </List.Item>
                )}
              />
            </Card>

            <Divider orientation="left">
              <span style={{ fontSize: 15 }}>
                {t('sessions.invoices.payments')}
              </span>
            </Divider>

            <Card>
              <List
                dataSource={payments.filter(
                  (el) =>
                    (el.invoice?.session === session._id ||
                      el.credit_note?.session === session._id) &&
                    (el.invoice?.customer === customer.customer._id ||
                      el.credit_note?.customer === customer.customer._id)
                )}
                renderItem={(item) => (
                  <List.Item
                    actions={[
                      <span
                        style={{
                          display: 'inline-flex',
                          alignItems: 'center',
                          flexDirection: 'column',
                          float: 'right',
                          marginLeft: 50
                        }}
                      >
                        <span
                          style={{ fontSize: 11, textTransform: 'uppercase' }}
                        >
                          {t('sessions.invoices.amount')}
                        </span>
                        <span style={{ fontWeight: 700 }}>
                          {`${item.amount} €`}
                        </span>
                      </span>
                    ]}
                  >
                    <List.Item.Meta
                      title={
                        <Row>
                          <Col span={4}>
                            <span>
                              {moment(item.date).format('DD-MM-YYYY')}
                            </span>
                          </Col>
                          <Col span={6}>
                            <span>
                              {`${
                                item.invoice?.number
                                  ? t('sessions.invoices.invoice')
                                  : t('sessions.invoices.credit_note')
                              }${item.invoice?.number ||
                                item.credit_note?.number}`}
                            </span>
                          </Col>
                        </Row>
                      }
                    />
                  </List.Item>
                )}
              />
            </Card>
          </Panel>
        ))}
      </Collapse>
    </>
  );
};

export default ProductList;

ProductList.propTypes = {
  customers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  session: PropTypes.shape({
    files: PropTypes.arrayOf(PropTypes.shape({})),
    _id: PropTypes.string.isRequired
  }).isRequired,
  fetchData: PropTypes.func.isRequired,
  contacts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  forceRefresh: PropTypes.bool.isRequired,
  setForceRefresh: PropTypes.func.isRequired,
  emails: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};
