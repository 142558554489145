import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Col, Drawer, Form, message, Typography } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import useAuthContext from '../../../contexts/AuthContext';
import useFields from './fields';
import useGenerateFormItem from '../../../utils/GenerateFormItem';
import ContactFormList from '../Contacts/ContactFormList';

const { Title } = Typography;

const NewCompanyDrawer = ({
  isVisible,
  setVisibilityCompanyDrawer,
  getCompanies,
  refresh,
  setRefresh,
  form,
  setCompany
}) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [contacts, setContacts] = useState({});
  const [companyRepresentative, setCompanyRepresentative] = useState({});
  const { fields } = useFields();
  const generateFields = useGenerateFormItem();

  useEffect(() => {
    const list = {};
    list[0] = true;
    setCompanyRepresentative(list);
  }, []);

  const cancelButton = () => {
    setVisibilityCompanyDrawer();
  };

  const populateContact = () => {
    const list = [];
    contacts.forEach((contact, index) => {
      list.push({
        ...contact,
        company_representative: companyRepresentative[index]
      });
    });
    return list;
  };

  const onFinish = async (values) => {
    const randomPassword = Math.random()
      .toString(36)
      .slice(-8);
    try {
      const newValues = values;
      newValues.contacts = populateContact();
      const { data } = await dispatchAPI('POST', {
        url: '/companies',
        body: {
          ...newValues,
          role: 'guests:GUEST',
          verifiedEmail: false,
          password: randomPassword
        }
      });

      message.success(t('companies.message.success.new'));
      setRefresh(!refresh);
      await getCompanies();
      setVisibilityCompanyDrawer();
      if (setCompany) {
        setCompany(data);
      }
      form.setFieldsValue({ customer: data._id });
    } catch (e) {
      if (e?.response?.data?.message.includes('E11000')) {
        message.warning(t('companies.message.E11000'));
      } else if (e?.response?.data?.message.includes('required_contact')) {
        message.warning(t('companies.message.contact'));
      }
    }
  };

  const onFinishFailed = () => {
    message.error(t('companies.message.error'));
  };

  return (
    <Drawer
      visible={isVisible}
      onClose={() => {
        setVisibilityCompanyDrawer();
      }}
      width="30%"
      className="new-company-drawer"
    >
      <Col style={{ width: '100%' }}>
        <Title level={3}>{t(`companies.form.title.create`)}</Title>
      </Col>
      <Form
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        style={{ width: '100%', paddingBottom: 64, position: 'relative' }}
      >
        {fields.map((field) =>
          generateFields('companies' || 'companies', field)
        )}
        <ContactFormList
          contacts={contacts}
          setContacts={setContacts}
          companyRepresentative={companyRepresentative}
          setCompanyRepresentative={setCompanyRepresentative}
        />
        <Button
          type="add"
          htmlType="submit"
          style={{
            position: 'absolute',
            bottom: 12,
            right: 0
          }}
        >
          {t('buttons.save')}
          <CheckOutlined />
        </Button>
        <Button
          onClick={cancelButton}
          style={{
            position: 'absolute',
            bottom: 12,
            right: 140
          }}
          type="link"
          danger
        >
          {`${t('buttons.cancel')}`}
          <CloseOutlined />
        </Button>
      </Form>
    </Drawer>
  );
};

NewCompanyDrawer.propTypes = {
  isVisible: PropTypes.bool,
  setVisibilityCompanyDrawer: PropTypes.func.isRequired,
  getCompanies: PropTypes.func.isRequired,
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func.isRequired,
  form: PropTypes.shape({
    setFieldsValue: PropTypes.func
  }),
  setCompany: PropTypes.func
};

NewCompanyDrawer.defaultProps = {
  isVisible: false,
  refresh: false,
  form: {},
  setCompany: null
};

export default NewCompanyDrawer;
