import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import PropTypes from 'prop-types';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';
import ErrorStatusCode from '../../utils/ErrorStatusCode';

const AccountingContext = createContext({});

export const AccountingContextProvider = ({ children, collection }) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [range, setRange] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [template, setTemplate] = useState();
  const [organization, setOrganization] = useState();
  const { notification } = ErrorStatusCode();

  const getOrganizations = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url:
          '/organizations?fields=name,channel_number,address_details,postal_code,city'
      });
      setOrganization(data[0]);
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  const deleteDocument = async (id, callback) => {
    try {
      await dispatchAPI('DELETE', { url: `/${collection}/${id}` });
      callback();
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  const updateDocument = async (id, body, callback) => {
    try {
      await dispatchAPI('PATCH', { url: `/${collection}/${id}`, body });
      callback();
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  const getTemplateSetting = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/settings?label=template_${collection}`
      });
      setTemplate(data[0].value);
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  const downloadFile = async (id, name, callback) => {
    try {
      const response = await dispatchAPI('GET', {
        url: `/files/generate/${collection}/${id}/${template}?fileType=pdf`,
        responseType: 'blob'
      });
      const blob = new Blob([response.data], {
        contentType: 'application/pdf'
      });

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = name;
      a.click();
    } catch (e) {
      if (e.response) notification(e.response);
    }
    callback();
  };

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    await getOrganizations();
    await getTemplateSetting();
    setIsLoading(false);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <AccountingContext.Provider
      value={{
        collection,
        organization,
        template,
        range,
        setRange,
        updateDocument,
        deleteDocument,
        downloadFile
      }}
    >
      {children}
    </AccountingContext.Provider>
  );
};

AccountingContextProvider.propTypes = {
  children: PropTypes.element.isRequired,
  collection: PropTypes.string.isRequired
};

export default () => useContext(AccountingContext);
