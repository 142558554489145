import React from 'react';
import { Avatar } from 'antd';

export const listContentCompany = ({ name, logo, stamp, siret_number }) => [
  {
    label: 'Logo',
    content: logo && <Avatar shape="square" src={logo} size={120} />,
    span: 1
  },
  {
    label: 'Cachet',
    content: stamp && <Avatar shape="square" src={stamp} size={120} />,
    span: 2
  },
  {
    label: 'profile.company.name',
    content: name
  },
  {
    label: 'profile.company.siret',
    content: siret_number
  }
];

export const listContentContact = ({
  location_tell,
  channel_number,
  address_details,
  postal_code,
  city,
  country,
  phone_number,
  handicap_referent,
  handicap
}) => [
  {
    label: 'profile.phone_number',
    content: phone_number,
    span: 3
  },
  {
    label: 'profile.address',
    content: `${channel_number || ''} ${address_details || ''} ${postal_code ||
      ''} ${city || ''} ${country || ''} `,
    span: 3
  },
  {
    label: 'profile.company.location_tell',
    content: location_tell,
    span: 3
  },
  { label: 'profile.company.handicap_referent', content: handicap_referent },
  {
    label: 'profile.company.handicap',
    content: handicap,
    span: 3
  }
];

export const listContentPedagogic = ({ pedagogic_team }) => [
  {
    label: 'profile.pedagogic_team',
    content: pedagogic_team,
    span: 3
  }
];

export const listContentReglementation = (
  {
    juridic_form,
    activity_declaration,
    acquisition_state,
    qualiopi_number,
    ape_code,
    social_capital,
    form,
    small_company
  },
  t
) => [
  {
    label: 'profile.juridic_form',
    content: juridic_form
  },
  {
    label: 'profile.activity_declaration',
    content: activity_declaration
  },
  {
    label: 'profile.acquisition_state',
    content: acquisition_state
  },
  {
    label: 'profile.qualiopi_number',
    content: qualiopi_number
  },
  {
    label: 'profile.ape_code',
    content: ape_code
  },
  {
    label: 'profile.social_capital',
    content: social_capital
  },
  {
    label: 'profile.form.form',
    content: form ? t('profile.yes') : t('profile.no')
  },
  {
    label: 'profile.small_company',
    content: small_company ? t('profile.yes') : t('profile.no')
  }
];

export const listContentComptable = (
  { invoice_active, currency, sigle_tax, tva_number, tva_rate },
  t
) => [
  {
    label: 'profile.invoice_active',
    content: invoice_active ? t('profile.yes') : t('profile.no')
  },
  {
    label: 'profile.currency',
    content: currency
  },
  {
    label: 'profile.sigle_tax',
    content: sigle_tax
  },
  {
    label: 'profile.tva_number',
    content: tva_number
  },
  {
    label: 'profile.tva_rate',
    content: tva_rate
  }
];

export const listContentComptableCloture = ({ day_cloture, month_cloture }) => [
  {
    label: 'profile.day_cloture',
    content: day_cloture
  },
  {
    label: 'profile.month_cloture',
    content: month_cloture
  }
];

export const listContentComptableExport = ({
  journal_code,
  product_bpf,
  annex_bpf,
  pedagogic_bpf,
  customer_account,
  product,
  annex,
  pedagogic,
  tva_account,
  product_consulting,
  annex_consulting,
  pedagogic_consulting
}) => [
  {
    label: 'profile.journal_code',
    content: journal_code,
    span: 3
  },
  {
    label: 'profile.product_bpf',
    content: product_bpf,
    span: 3
  },
  {
    label: 'profile.annex_bpf',
    content: annex_bpf,
    span: 3
  },
  {
    label: 'profile.pedagogic_bpf',
    content: pedagogic_bpf,
    span: 3
  },
  {
    label: 'profile.customer_account',
    content: customer_account,
    span: 3
  },
  {
    label: 'profile.product',
    content: product,
    span: 3
  },
  {
    label: 'profile.annex',
    content: annex,
    span: 3
  },
  {
    label: 'profile.pedagogic',
    content: pedagogic,
    span: 3
  },
  {
    label: 'profile.tva_account',
    content: tva_account,
    span: 3
  },
  {
    label: 'profile.product_consulting',
    content: product_consulting,
    span: 3
  },
  {
    label: 'profile.annex_consulting',
    content: annex_consulting,
    span: 3
  },
  {
    label: 'profile.pedagogic_consulting',
    content: pedagogic_consulting,
    span: 3
  }
];

export const listContentComptableInvoice = ({
  bank_code,
  guichet_code,
  account_number,
  rib,
  iban,
  bic
}) => [
  {
    label: 'profile.bank_code',
    content: bank_code,
    span: 3
  },
  {
    label: 'profile.guichet_code',
    content: guichet_code,
    span: 3
  },
  {
    label: 'profile.account_number',
    content: account_number,
    span: 3
  },
  {
    label: 'profile.rib',
    content: rib,
    span: 3
  },
  {
    label: 'profile.iban',
    content: iban,
    span: 3
  },
  {
    label: 'profile.bic',
    content: bic,
    span: 3
  }
];
