import React, { useEffect, useState } from 'react';
import { Form, Checkbox, Input, Select, Button, Row, Col } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../../contexts/AuthContext';
import { fundingCheckbox } from '../../../utils/constants/sessionsCustomersCheckbox';
import NewCompanyDrawer from '../../Donnees/Companies/NewCompanyDrawer';
import NewTraineeDrawer from '../../Donnees/Trainees/NewTraineeDrawer';
import ErrorStatusCode from '../../../utils/ErrorStatusCode';

const { Option } = Select;

const useFields = (
  param,
  setId,
  id,
  idCustomer,
  purpose,
  isFieldsLoading,
  setIsFieldsLoading,
  form
) => {
  const { t } = useTranslation();
  const { notification } = ErrorStatusCode();
  const { dispatchAPI } = useAuthContext();
  const [companies, setCompanies] = useState([]);
  const [trainees, setTrainees] = useState([]);
  const [company, setCompany] = useState({});
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [visibilityCompanyDrawer, setVisibilityCompanyDrawer] = useState(false);
  const [visibilityTraineeDrawer, setVisibilityTraineeDrawer] = useState(false);

  useEffect(() => {
    if (idCustomer) {
      setCompany(companies.find((el) => el._id === idCustomer));
    }
  }, [companies]);

  const drawerCompanyVisibility = () => {
    setVisibilityCompanyDrawer(!visibilityCompanyDrawer);
  };

  const drawerTraineeVisibility = () => {
    setVisibilityTraineeDrawer(!visibilityTraineeDrawer);
  };

  const drawerVisibility = () => {
    if (param === 'private') {
      drawerTraineeVisibility();
    } else if (param === 'company') {
      drawerCompanyVisibility();
    }
  };

  const getTrainees = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/trainees' });
      setTrainees(data);
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  const getCompanies = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/companies' });
      setCompanies(data);
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  useEffect(() => {
    if (param === 'private') {
      setSelectedCustomers(trainees.filter((trainee) => !trainee.company));
    } else setSelectedCustomers(companies);
  }, [trainees, refresh]);

  useEffect(() => {
    (async () => {
      await setIsFieldsLoading(true);
      await Promise.all([getCompanies(), getTrainees()]);
      await setIsFieldsLoading(false);
    })();
  }, [refresh]);

  useEffect(() => {
    setRefresh(false);
  }, [refresh]);

  const basicFields = [
    {
      name: ['customer'],
      rules: [{ required: true }],
      input: (
        <Input.Group compact>
          <NewCompanyDrawer
            isVisible={visibilityCompanyDrawer}
            setVisibilityCompanyDrawer={drawerCompanyVisibility}
            getCompanies={getCompanies}
            refresh={refresh}
            setRefresh={setRefresh}
            form={form}
            setCompany={setCompany}
          />
          <NewTraineeDrawer
            isVisible={visibilityTraineeDrawer}
            setVisibilityTraineesDrawer={drawerTraineeVisibility}
            refresh={refresh}
            setRefresh={setRefresh}
            company={company}
            trainees={trainees}
            setId={setId}
            id={id}
          />
          <Row>
            <Col span={24}>
              <Form.Item name={['customer']}>
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  style={{ width: 370 }}
                  loading={isFieldsLoading}
                  onChange={(value, info) => {
                    setCompany({
                      _id: value,
                      name: info.children
                    });
                  }}
                >
                  {selectedCustomers.map(
                    ({ name, first_name, last_name, _id }) => (
                      <Option key={_id} value={_id}>
                        {name || `${first_name} ${last_name}`}
                      </Option>
                    )
                  )}
                </Select>
              </Form.Item>
            </Col>
            {purpose !== 'edit' && (
              <Col span={24}>
                <Button type="primary" onClick={drawerVisibility}>
                  <span style={{ fontSize: '15px' }}>
                    {t('buttons.create')}
                  </span>
                  <PlusOutlined />
                </Button>
              </Col>
            )}
          </Row>
        </Input.Group>
      )
    },
    ...(param !== 'private'
      ? [
          {
            name: ['trainees'],
            rules: [{ required: true }],
            input: (
              <Row>
                <Col span={24}>
                  <Form.Item name={['trainees']}>
                    <Select
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      style={{ width: 370 }}
                      loading={isFieldsLoading}
                      mode="multiple"
                    >
                      {trainees
                        .filter(
                          (el) =>
                            (company && el.company === company._id) ||
                            (!company && el.company === idCustomer)
                        )
                        .map(({ first_name, last_name, _id }) => (
                          <Option key={_id} value={_id}>
                            {`${first_name} ${last_name}`}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Button type="primary" onClick={drawerTraineeVisibility}>
                    <span style={{ fontSize: '15px' }}>
                      {t('buttons.create')}
                    </span>
                    <PlusOutlined />
                  </Button>
                </Col>
              </Row>
            )
          }
        ]
      : []),
    {
      name: ['funding'],
      input: (
        <Checkbox.Group>
          {fundingCheckbox.map((item) => (
            <Checkbox value={item}>{item}</Checkbox>
          ))}
        </Checkbox.Group>
      )
    }
  ];

  return {
    basicFields,
    isFieldsLoading
  };
};

export default useFields;
