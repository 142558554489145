import React from 'react';
import { Steps } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  StepsAgendaIcon,
  StepsCatalogIcon,
  ConventionIcon,
  CustomerIcon,
  ConvocationIcon,
  QuizzIcon,
  AttestationIcon,
  InvoiceIcon
} from '../../../utils/constants/customIcon';

const { Step } = Steps;

const SessionsSteps = ({ status }) => {
  const { t } = useTranslation();

  return (
    <Steps className="session-steps">
      <Step
        status={status ? status.traineeAndDate.status : 'wait'}
        icon={<StepsAgendaIcon />}
        title={t('Stagiaires/dates')}
      />
      <Step
        status={status ? status.programAndContributor.status : 'wait'}
        icon={<StepsCatalogIcon />}
        title={t('Programme/intervenant')}
      />
      <Step
        status={status ? status.convention.status : 'wait'}
        icon={<ConventionIcon />}
        title={t('Conventions')}
      />
      <Step
        status={status ? status.customers.status : 'wait'}
        icon={<CustomerIcon />}
        title={t('Clients')}
      />
      <Step
        status={status ? status.convocation.status : 'wait'}
        icon={<ConvocationIcon />}
        title={t('Convocations')}
      />
      <Step
        status={status ? status.quizzs.status : 'wait'}
        icon={<QuizzIcon />}
        title={t('Evaluations')}
      />
      <Step
        status={status ? status.attestation.status : 'wait'}
        icon={<AttestationIcon />}
        title={t('Attestations')}
      />
      <Step
        status={status ? status.invoice.status : 'wait'}
        icon={<InvoiceIcon />}
        title={t('Finance')}
      />
    </Steps>
  );
};

SessionsSteps.propTypes = {
  status: PropTypes.shape({
    attestation: PropTypes.shape({
      status: PropTypes.string,
      message: PropTypes.string
    }),
    convention: PropTypes.shape({
      status: PropTypes.string,
      message: PropTypes.string
    }),
    convocation: PropTypes.shape({
      status: PropTypes.string,
      message: PropTypes.string
    }),
    customers: PropTypes.shape({
      status: PropTypes.string,
      message: PropTypes.string
    }),
    invoice: PropTypes.shape({
      status: PropTypes.string,
      message: PropTypes.string
    }),
    programAndContributor: PropTypes.shape({
      status: PropTypes.string,
      message: PropTypes.string
    }),
    quizzs: PropTypes.shape({
      status: PropTypes.string,
      message: PropTypes.string
    }),
    traineeAndDate: PropTypes.shape({
      status: PropTypes.string,
      message: PropTypes.string
    })
  }).isRequired
};

export default SessionsSteps;
