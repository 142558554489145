import React, { useEffect, useState, useRef } from 'react';
import {
  Button,
  Checkbox,
  Divider,
  List,
  message,
  Popconfirm,
  Table,
} from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  CheckOutlined,
  LeftCircleOutlined,
  RightCircleOutlined,
} from '@ant-design/icons';
import useAuthContext from '../../../contexts/AuthContext';

const AttendanceTraineesRecordTable = ({
  trainees,
  modules,
  slots,
  setSlots,
  onSaveSign,
  onSelect,
  editSignings,
  setEditSignings,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    const list = [];
    modules.forEach(module => {
      module.slots.date.map(date => {
        date.sub_slots.map(subSlot => {
          list.push({
            id: subSlot._id,
            idModule: module._id,
            idDate: date._id,
            idSubSlot: subSlot._id,
            module: module.name,
            date: date.date,
            type: subSlot.type,
            signings: subSlot.signings,
          });
        });
      });
    });
    let newList = list.sort((a, b) => (moment(a.date).isAfter(b.date) ? 1 : -1));

    for (let i = 0; i < newList.length; i+=2) {
      const tab = newList[i];
      newList[i] = newList[i+1];
      newList[i+1] = tab;
    }
    newList = newList.filter((el)=> el !== undefined);
    setSlots(newList);

  }, [modules]);

  const columns = [
    {
      title: 'Nom',
      render: trainee => `${trainee.last_name} ${trainee.first_name}`,
      key: 'name',
    },
    ...slots.map((slot, indexSlot) => ({
      title: (
        <>
          <span>{moment(slot.date).format('DD-MM-YYYY')}</span>
          <br />
          <span>{slot.type}</span>
          <br />
          <span>{slot.module}</span>
        </>
      ),
      key: slot.id,
      width: '122px',
      render: trainee =>
        editSignings ? (
          <Checkbox
            checked={slot.signings?.map(el => el.user).includes(trainee._id)}
            onClick={checked =>
              onSelect(checked.target.checked, slot, indexSlot, trainee._id)
            }
          />
        ) : slot.signings &&
          slot.signings.find(el => el.user === trainee._id) &&
          slot.signings.find(el => el.user === trainee._id).signature ? (
          <img
              src={slot.signings.find(el => el.user === trainee._id).signature}
              style={{ maxWidth: 90, maxHeight: 40 }}
            />
        ) : (
          <>
            {slot.signings.find(el => el.user === trainee._id) && (
              <CheckOutlined />
            )}
          </>
        ),
    })),
  ];

  return (
    <>
      <Table
        columns={columns}
        scroll={{ x: 1500, y: 800 }}
        bordered
        dataSource={trainees}
        pagination={false}
        tableLayout="auto"
        className="session-signings-table"
      />
      {editSignings === false ? (
        <Button
          type="primary"
          onClick={() => setEditSignings(!editSignings)}
          style={{ margin: '16px 0', float: 'right' }}>
          Valider manuellement les présences
        </Button>
      ) : (
        <Button
          type="add"
          onClick={onSaveSign}
          style={{ margin: '16px 0', float: 'right' }}>
          Enregistrer
        </Button>
      )}
    </>
  );
};
AttendanceTraineesRecordTable.propTypes = {
  trainees: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  modules: PropTypes.arrayOf(PropTypes.shape({})),
};

export default AttendanceTraineesRecordTable;
