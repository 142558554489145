import { useTranslation } from 'react-i18next';

const useColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('skills.form.name'),
      dataIndex: 'name',
      sorter: true,
    },
    {
      title: t('skills.form.description'),
      dataIndex: 'description',
      sorter: true,
    },
  ];
};

export default useColumns;
