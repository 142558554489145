import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Calendar, Tag } from 'antd';
import moment from 'moment';

const SessionPlanning = ({ modules }) => {
  const [finalModules, setFinalModules] = useState([]);

  useEffect(() => {
    const list = [];
    modules.forEach((module) => {
      if (module) {
        module.slots.date.forEach((item) => {
          item.sub_slots.forEach((subSlot) => {
            list.push({
              id: module._id,
              module: module.name,
              date: item.date,
              type: subSlot.type,
              start_time: subSlot.start_time,
              end_time: subSlot.end_time
            });
          });
        });
      }
    });

    setFinalModules(list);
  }, [modules]);

  const dateCellRender = (value) => {
    const finalReturn = finalModules
      ? finalModules.filter(
          (module) =>
            value.format('YYYY-MM-DD') ===
            moment(module.date).format('YYYY-MM-DD')
        )
      : [];
    return finalReturn.map((module) => (
      <li style={{ fontSize: 10, paddingBottom: 5 }}>
        <Tag color="purple">{`${module.start_time} ${module.module}`}</Tag>
      </li>
    ));
  };

  return <Calendar dateCellRender={dateCellRender} />;
};

export default SessionPlanning;

SessionPlanning.propTypes = {
  modules: PropTypes.element.isRequired
};
