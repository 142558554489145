import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import QualityIncidents from './QualityIncidents';
import CreateUpdateQualityIncident from './CreateUpdateQualityIncident';
import { subRoutes } from '../../../utils/constants/routes';
import DetailsQualityIncident from './DetailQualityIncident';

const QualityIncidentRouter = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route
        path={`${path}${subRoutes.DASHBORD.QUALITY_INCIDENTS}/create`}
        render={() => <CreateUpdateQualityIncident purpose="create" />}
      />
      <Route
        path={`${path}${subRoutes.DASHBORD.QUALITY_INCIDENTS}/edit/:id`}
        render={() => <CreateUpdateQualityIncident purpose="edit" />}
      />
      <Route
        path={`${path}${subRoutes.DASHBORD.QUALITY_INCIDENTS}/show/:id`}
        render={() => <DetailsQualityIncident />}
      />
      <Route path={`${path}`} component={QualityIncidents} />
    </Switch>
  );
};

export default QualityIncidentRouter;
