import React, { useEffect, useState } from 'react';
import { Col, Divider, Row, Table, Input, DatePicker } from 'antd';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import useAuthContext from '../../../contexts/AuthContext';
import ErrorStatusCode from '../../../utils/ErrorStatusCode';
import useColumns from './affiliationsColumns';
import { SimplePieChart } from '../../DashBoard/SimplePieChart';
import { PageHeaderCustom } from '../../../components';

const { Search } = Input;
const { RangePicker } = DatePicker;
const StyledTable = styled.div`
  .rowStyle {
    cursor: pointer;
  }
`;

const Commissions = () => {
  const columns = useColumns(true);
  const { t } = useTranslation();
  const { id } = useParams();
  const { dispatchAPI, user } = useAuthContext();
  const { notification } = ErrorStatusCode();
  const [affiliates, setAffiliates] = useState([]);
  const [totalCommissions, setTotalCommissions] = useState(0);
  const [totalPaid, setTotalPaid] = useState(0);
  const [totalRest, setTotalRest] = useState(0);
  const [pieChartData, setPieChartData] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [dates, setDates] = useState([]);
  const [periodStatus, setPeriodStatus] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [filters, setFilters] = useState({
    LESS_MONTHS: 0,
    WAITING: 0,
    PAID: 0
  });

  const extra = [
    <>
      <Row gutter={8}>
        <Col span={10}>
          <Search
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder={t('prescriber.commissions.search_place_holder')}
          />
        </Col>
        <Col span={14}>
          <RangePicker
            onChange={(values) => setDates(values)}
            style={{ float: 'right' }}
          />
        </Col>
      </Row>
    </>
  ];

  const getAffiliates = async () => {
    try {
      const urlSearch = `/search/${searchValue}`;
      let dateSearch = '';
      if (dates && dates.length === 2) {
        dateSearch = `&date>=${moment(dates[0])
          .startOf('day')
          .toISOString()}&date<=${moment(dates[1])
          .endOf('day')
          .toISOString()}`;
      }
      const { data } = await dispatchAPI('GET', {
        url: `/commissions${searchValue ? urlSearch : ''}?sponsor=${id ||
          user._id}${dateSearch}&populate=sponsored._id,sponsored._id.organization&paymentStatus=${paymentStatus}&periodStatus=${periodStatus}`
      });

      setAffiliates(data);
      let amount = 0;
      let amountPaid = 0;
      let amountRest = 0;
      let amountLess = 0;
      const nbPaid = data.filter((el) => el.status === 'PAID').length || 0;
      const nbLessMonths =
        data.filter((el) => el.status === 'LESS_MONTHS').length || 0;
      const nbWaiting =
        data.filter((el) => el.status === 'WAITING').length || 0;
      data.forEach((commission) => {
        amount += commission.amount;
        if (commission.status === 'PAID') {
          amountPaid += commission.amount;
        } else {
          if (commission.status === 'LESS_MONTHS') {
            amountLess += commission.amount;
          }
          amountRest += commission.amount;
        }
      });

      const temp = {
        LESS_MONTHS: Number(amountLess.toFixed(2)),
        WAITING: Number((amountRest - amountLess).toFixed(2)),
        PAID: Number(amountPaid.toFixed(2))
      };
      setFilters({
        LESS_MONTHS: nbLessMonths,
        WAITING: nbWaiting,
        PAID: nbPaid
      });
      setPieChartData(
        Object.keys(temp).map((key) => ({
          type: key && t(`prescriber.affiliations.tags.${key}`),
          value: temp[key]
        }))
      );
      setTotalPaid(amountPaid);
      setTotalRest(amountRest);
      setTotalCommissions(amount);
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  useEffect(() => {
    (async () => {
      await getAffiliates();
    })();
  }, [searchValue, dates, periodStatus, paymentStatus]);

  const handlePageChange = async (_, filters) => {
    setPeriodStatus(filters.period_status);
    setPaymentStatus(filters.payment_status);
  };

  return (
    <>
      <Row>
        <Col xxl={17} xl={24} lg={24} sm={24} xs={24}>
          <PageHeaderCustom
            title={t('prescriber.commissions.title')}
            extra={extra}
          />
          <StyledTable
            as={Table}
            rowClassName="rowStyle"
            dataSource={affiliates}
            columns={columns}
            onChange={handlePageChange}
            style={{ margin: '0 8px 0 20px' }}
          />
        </Col>
        <Col
          xxl={7}
          xl={24}
          lg={24}
          sm={24}
          xs={24}
          className="dashboard-table-right-column"
        >
          <h1>{`${affiliates.length} commissions`}</h1>
          <Divider orientation="left">
            {t('prescriber.commissions.ttc_amount')}
          </Divider>
          <SimplePieChart data={pieChartData} />
          <Divider orientation="left">
            {t('prescriber.commissions.totals')}
          </Divider>
          <div className="dashboard-total-prices-wrapper">
            <p>
              <span>{t('prescriber.commissions.ttc_total')}</span>
              <span>{`${totalCommissions.toFixed(2)} €`}</span>
            </p>
            <p>
              <span>{t('prescriber.commissions.total_paid')}</span>
              <span>{`${totalPaid.toFixed(2)} €`}</span>
            </p>
            <p>
              <span>{t('prescriber.commissions.total_due')}</span>
              <span>{`${totalRest.toFixed(2)} €`}</span>
            </p>
          </div>
          <Divider orientation="left">
            {t('prescriber.commissions.filter')}
          </Divider>
          {Object.keys(filters).map((el) => {
            return (
              <p>
                {`${t(`prescriber.affiliations.tags.${el}`)} : ${filters[el]}`}
              </p>
            );
          })}
        </Col>
      </Row>
    </>
  );
};

export default Commissions;
