import React, { useEffect, useState } from 'react';
import { Button, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment/moment';
import { Link, useHistory } from 'react-router-dom';
import { EyeOutlined } from '@ant-design/icons';
import ErrorStatusCode from '../../utils/ErrorStatusCode';
import useAuthContext from '../../contexts/AuthContext';
import { ContentCustom, PageHeaderCustom } from '../../components';

const CommissionsList = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { dispatchAPI } = useAuthContext();
  const { notification } = ErrorStatusCode();
  const [commissions, setCommissions] = useState([]);

  const columns = [
    {
      title: t('commissions.columns.year'),
      key: 'year',
      dataIndex: '_id',
      sorter: true
    },
    {
      title: t('commissions.columns.amount'),
      key: 'amountYear',
      dataIndex: 'amountYear',
      render: (amountYear) =>
        amountYear ? `${(amountYear / 100).toFixed(2)} €` : '-',
      sorter: true
    }
  ];

  const generateXMLFile = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/commissions-files' });
      if (data) {
        const blob = new Blob([data[0].xmlContent], {
          type: 'text/xml;charset=UTF-8'
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `SEPA_file.xml`;
        a.click();
        window.location.reload();
      }
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  const getCommissions = async () => {
    const { data } = await dispatchAPI('GET', { url: '/commissions/report' });
    setCommissions(
      data.map((commission) => ({ ...commission, key: commission._id }))
    );
  };

  useEffect(() => {
    getCommissions();
  }, []);

  const onCustomersExpand = (month) => {
    const customers = {};
    if (month.commissions) {
      month.commissions.forEach((commission) => {
        customers[commission.sponsor._id] = {
          ...commission,
          amount:
            (customers[commission.sponsor._id]?.amount || 0) + commission.amount
        };
      });
    }
    return (
      <>
        {(month.commissions || []).length ? (
          <Table
            showHeader={false}
            dataSource={Object.values(customers)}
            onRow={({ sponsor }) => ({
              onDoubleClick: () => {
                history.push({
                  pathname: `/admin/admin-sponsorship/commissions-details/${sponsor._id}`
                });
              }
            })}
            columns={[
              {
                key: 'customer',
                dataIndex: 'sponsor',
                render: (sponsor) =>
                  `${sponsor.first_name} ${sponsor.last_name}`,
                sorter: true
              },
              {
                title: t('commissions.columns.amount'),
                key: 'amount',
                dataIndex: 'amount',
                render: (amount) =>
                  amount ? `${(amount / 100).toFixed(2)} €` : '-',
                sorter: true
              },
              {
                key: 'action',
                dataIndex: 'sponsor',
                render: (sponsor) => (
                  <Link
                    to={{
                      pathname: `/admin/admin-sponsorship/commissions-details/${sponsor._id}`
                    }}
                  >
                    <EyeOutlined style={{ fontSize: 18 }} />
                  </Link>
                )
              }
            ]}
          />
        ) : (
          'Aucune donnée pour ce mois ci'
        )}
      </>
    );
  };

  const onExpand = (record) => {
    const months = moment.months();
    const data = [];
    months.forEach((month, index) => {
      const commissionsList = record.data.find(
        (el) => moment(el._id.month, 'MM').format('MMMM') === month
      );
      data.push({ ...commissionsList, month, key: index });
    });

    return (
      <>
        {data.length ? (
          <Table
            showHeader={false}
            dataSource={data}
            columns={[
              {
                key: 'month',
                dataIndex: 'month',
                sorter: true
              },
              {
                title: t('commissions.columns.amount'),
                key: 'amountMonth',
                dataIndex: 'amountMonth',
                render: (amountMonth) =>
                  amountMonth ? `${(amountMonth / 100).toFixed(2)} €` : '-',
                sorter: true
              }
            ]}
            expandable={{
              expandedRowRender: (month) => onCustomersExpand(month)
            }}
          />
        ) : (
          'Aucune donnée pour ce mois ci'
        )}
      </>
    );
  };

  return (
    <>
      <PageHeaderCustom
        title="Gestion des commissions"
        extra={
          <Button onClick={generateXMLFile}>
            {' '}
            Télécharger le fichier XML du mois
          </Button>
        }
      />
      <ContentCustom>
        <Table
          dataSource={commissions}
          columns={columns}
          expandable={{
            expandedRowRender: (record) => onExpand(record)
          }}
        />
      </ContentCustom>
    </>
  );
};
export default CommissionsList;
