import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Row, Upload, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import {
  CameraOutlined,
  CheckOutlined,
  CloseOutlined
} from '@ant-design/icons';
import { outOfNavRoutes, routes } from '../../utils/constants/routes';
import useAuthContext from '../../contexts/AuthContext';
import useFields from './fields';
import useGenerateFormItem from '../../utils/GenerateFormItem';
import ErrorStatusCode from '../../utils/ErrorStatusCode';
import {
  formItemLayout,
  tailFormItemLayout
} from '../../utils/constants/formLayout';
import PageHeaderCustom from '../../components/PageHeader/PageHeader';
import ContentCustom from '../../components/ContentCustom/ContentCustom';
import ItemCompanyFields from './ItemCompanyFields';

const { Dragger } = Upload;

const UpdateCompany = ({ purpose }) => {
  const history = useHistory();
  const { id } = useParams();
  const { t } = useTranslation();
  const { dispatchAPI, setUser, user } = useAuthContext();
  const generateFields = useGenerateFormItem();
  const { companyFields } = useFields();
  const [form] = Form.useForm();
  const [companyLogo, setCompanyLogo] = useState();
  const [stamp, setStamp] = useState();
  const [imageUrl, setImageUrl] = useState('');
  const [fileList, setFileList] = useState([]);
  const [stampFileList, setStampFileList] = useState([]);
  const { notification } = ErrorStatusCode();

  const fetchUser = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/organizations/${id}`
      });
      form.setFieldsValue({
        ...data
      });
      setCompanyLogo(data.logo);
      setStamp(data.stamp);
      setUser({ ...user, organization: data });
    } catch (e) {
      if (e.response) {
        notification(e.response);
      }
    }
  }, []);

  useEffect(() => {
    if (purpose === 'edit') fetchUser();
  }, [fetchUser]);

  // This function convert the PDF to base64 format
  const fileToBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (e) => reject(e);
    });

  const updateProfile = async (body) => {
    try {
      const newBody = body;
      if (fileList.length) {
        const logo = await fileToBase64(
          fileList.find((f) => f.label === 'logo')
        );
        if (logo) newBody.logo = logo;
      }
      if (stampFileList.length) {
        const stampImg = await fileToBase64(
          stampFileList.find((f) => f.label === 'stamp')
        );
        if (stampImg) newBody.stamp = stampImg;
      }
      await dispatchAPI('PATCH', {
        url: `/organizations/${id}`,
        body: newBody
      });
      await fetchUser();
      history.push(outOfNavRoutes.PROFILE);
    } catch (e) {
      if (e.response) {
        notification(e.response);
      }
    }
  };

  const createCompany = async (body) => {
    try {
      const newBody = body;
      if (fileList.length > 0) {
        newBody.logo = await fileToBase64(fileList[0]);
      }

      const { data } = await dispatchAPI('POST', {
        url: `/organizations/new-prescriber`,
        newBody
      });
      setUser(data);
      history.push(`${routes.SPONSORSHIP}`);
    } catch (e) {
      if (e.response) {
        notification(e.response);
      }
    }
  };

  const handleSubmit = async (values) => {
    if (purpose === 'edit') {
      await updateProfile(values);
    } else {
      await createCompany(values);
    }
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const draggerProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      const newFile = file;
      newFile.label = 'logo';
      const fileExtension = newFile.name.split('.').pop();
      if (
        fileExtension === 'png' ||
        fileExtension === 'PNG' ||
        fileExtension === 'jpg' ||
        fileExtension === 'JPG'
      ) {
        setFileList([...fileList, newFile]);
        getBase64(newFile, (image) => {
          setImageUrl(image);
        });
      } else {
        message.error(t('profile.messages.format_image'));
        return true;
      }
      return false;
    },
    fileList
  };

  return (
    <>
      <PageHeaderCustom title={t('profile.title')} />
      <ContentCustom>
        <Form form={form} onFinish={handleSubmit}>
          <Form.Item {...formItemLayout} label="Logo">
            <Dragger {...draggerProps}>
              {companyLogo || imageUrl ? (
                <img
                  src={imageUrl || companyLogo}
                  alt="avatar"
                  style={{ width: '60%' }}
                />
              ) : (
                <p className="ant-upload-drag-icon">
                  <CameraOutlined style={{ color: 'var(--textColor)' }} />
                </p>
              )}
            </Dragger>
          </Form.Item>
          <div className="collapse-generate-fields">
            {companyFields.map((field) =>
              generateFields('company' || 'company', field)
            )}
          </div>
          <Form.Item>
            <ItemCompanyFields
              stampFileList={stampFileList}
              setStampFileList={setStampFileList}
              stamp={stamp}
              setStamp={setStamp}
            />
          </Form.Item>
          <Form.Item {...tailFormItemLayout}>
            <Row justify="end">
              <Button
                style={{ margin: '0 10px' }}
                type="link"
                danger
                onClick={() => history.goBack()}
              >
                {`${t('buttons.cancel')} `}
                <CloseOutlined />
              </Button>
              <Button type="add" htmlType="submit">
                {`${t('buttons.save')} `}
                <CheckOutlined />
              </Button>
            </Row>
          </Form.Item>
        </Form>
      </ContentCustom>
    </>
  );
};

UpdateCompany.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default UpdateCompany;
