import React, { useCallback, useEffect, useState } from 'react';
import { Checkbox, Select, Input, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../../contexts/AuthContext';
import ErrorStatusCode from '../../../utils/ErrorStatusCode';

const { Option } = Select;

const useFields = (companyRepresentative) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [enums, setEnums] = useState({});
  const [companies, setCompanies] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState('');
  const { notification } = ErrorStatusCode();

  const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

  const handlePhoneNumber = (value) => {
    return value.replace(phoneRegex, '($1) $2-$3');
  };

  const fields = [
    {
      label: 'gender',
      name: ['gender'],
      rules: [{ required: true }],
      input: (
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          loading={isFieldsLoading}
        >
          {(enums.genders || []).map((title) => (
            <Option key={title} value={title}>
              {t(`contacts.form.${title}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      label: 'company',
      name: ['my_company'],
      rules: [{ required: true }],
      input: (
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          loading={isFieldsLoading}
        >
          {companies.map((company) => (
            <Option key={company._id} value={company._id}>
              {company.name}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['last_name'],
      rules: [{ required: true }]
    },
    {
      name: ['first_name'],
      rules: [{ required: true }]
    },
    {
      name: ['company_representative'],
      valuePropName: 'checked',
      input: <Checkbox checked={companyRepresentative} />
    },
    {
      name: ['email'],
      rules: [{ required: true }, { type: 'email' }]
    },
    {
      name: ['phone_number'],
      label: 'phone_number.number',
      rules: [{ required: true }],
      input: (
        <Input.Group compact>
          <Form.Item
            noStyle
            name={['phone_number', 'country_code']}
            initialValue="+33"
          >
            <Select style={{ width: '25%' }}>
              <Option value="+33">+33</Option>
            </Select>
          </Form.Item>
          <Form.Item
            noStyle
            name={['phone_number', 'number']}
            rules={[
              {
                required: true,
                message: t('contacts.form.phone_number.place_holder')
              }
            ]}
          >
            <Input
              pattern="[0-9]{9}"
              value={handlePhoneNumber(phoneNumber)}
              onChange={(e) => setPhoneNumber(e.target.value)}
              style={{ width: '75%' }}
              title={t('errors_code.wrong_phone_number_format')}
            />
          </Form.Item>
        </Input.Group>
      )
    }
  ];

  const getCompanies = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/companies' });
      setCompanies(data);
    } catch (e) {
      if (e.response) {
        notification(e.response);
      }
    }
  };

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/contacts/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) {
        notification(e.response);
      }
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getEnums();
    await getCompanies();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return {
    fields,
    isFieldsLoading
  };
};

export default useFields;
