import React, { useState, useEffect } from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Button, message, Menu, Dropdown } from 'antd';
import { useTranslation } from 'react-i18next';
import SimpleResourceLandingLayout from '../../../components/layouts/SimpleResourceLandingLayout';
import useAuthContext from '../../../contexts/AuthContext';
import useColumns from './columns';
import ErrorStatusCode from '../../../utils/ErrorStatusCode';
import { useFormatter } from '../../../utils/formatter';
import ModalGeneratingFile from '../../Sessions/Convocations/modalGeneratingFile';

const ListPrograms = () => {
  const { columns, headers } = useColumns();
  const { t } = useTranslation();
  const { formattedData } = useFormatter();
  const [templates, setTemplates] = useState([]);
  const { dispatchAPI, user } = useAuthContext();
  const { notification } = ErrorStatusCode();
  const [generateStatus, setGenerateStatus] = useState(false);
  const [isFileGenerate, setIsFileGenerate] = useState(false);
  const [isModalGenerateFileVisible, setIsModalGenerateFileVisible] = useState(
    false
  );

  const getFile = () => {
    try {
      const list = user.organization.templates.filter(
        (el) => el.type === 'programs'
      );
      setTemplates(list);
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  const generateFile = async (temp, id, type) => {
    try {
      setIsFileGenerate(false);
      setGenerateStatus(true);
      message.info(t('programs.success_messages.generating_document'), 5);
      await dispatchAPI('GET', {
        url: `/files/generate/${id}/${temp._id}/${type}`,
        responseType: 'blob'
      });
      message.success(t('programs.success_messages.success_generating'));
      setGenerateStatus(false);
      setIsFileGenerate(true);
      setIsModalGenerateFileVisible(true);
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  const menuDropDown = (record) => {
    const menu = (
      <Menu>
        {templates.map((temp) => (
          <>
            <Menu.Item key="1">
              {generateStatus ? (
                <Button disabled type="link">
                  {`${temp.name.split('.')[0]} docx`}
                </Button>
              ) : (
                <Button
                  type="link"
                  onClick={() => generateFile(temp, record.key, 'docx')}
                >
                  {`${temp.name.split('.')[0]} docx`}
                </Button>
              )}
            </Menu.Item>
            <Menu.Item key="2">
              {generateStatus ? (
                <Button disabled type="link">
                  {`${temp.name.split('.')[0]} pdf`}
                </Button>
              ) : (
                <Button
                  type="link"
                  onClick={() => generateFile(temp, record.key, 'pdf')}
                >
                  {`${temp.name.split('.')[0]} pdf`}
                </Button>
              )}
            </Menu.Item>
          </>
        ))}
      </Menu>
    );
    return menu;
  };
  const extraActionColumn = (key) => {
    return (
      <Dropdown overlay={() => menuDropDown(key)} trigger={['click']}>
        <Button type="link">
          {t('programs.program')}
          <DownOutlined />
        </Button>
      </Dropdown>
    );
  };

  useEffect(() => {
    getFile();
  }, []);

  return (
    <>
      <ModalGeneratingFile
        isVisible={isModalGenerateFileVisible}
        setVisible={setIsModalGenerateFileVisible}
      />
      <SimpleResourceLandingLayout
        columns={columns}
        headers={headers}
        resourceName="programs"
        populate="files._id"
        withSubRoutes
        extraActionColumn={extraActionColumn}
        isFileGenerate={isFileGenerate}
        formatter={formattedData}
        resourceModelName="Program"
        templateType="programs_import_template"
        withoutImportButton
        withProjectionFields
      />
    </>
  );
};

export default ListPrograms;
