import React, { useCallback, useEffect, useState } from 'react';
import { Button, message } from 'antd';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../contexts/AuthContext';
import KanbanTable from './kanbanTable';
import ErrorStatusCode from '../../utils/ErrorStatusCode';

const KanbanSettings = ({ getKanbanCols, kanbanCols, type, length }) => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const { notification } = ErrorStatusCode();
  const [deals, setDeals] = useState([]);

  const fetchDeals = async () => {
    try {
      const data = await dispatchAPI('GET', { url: `/deals` });
      setDeals(data.data);
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  const addKanbanCol = async () => {
    const bodyKanbanCols = {
      label: 'Editer le nom...',
      type,
      icon: null,
      show: true,
      color: '#31b3fb',
      index: length
    };
    try {
      await dispatchAPI('POST', { url: '/kanbans', body: bodyKanbanCols });
      await getKanbanCols();
    } catch (e) {
      message.error(e.message);
    }
  };

  const deleteKanbanCol = async (kanbanCol) => {
    if (deals.filter((deal) => deal.status === kanbanCol.label).length > 0) {
      return message.error(t('settings.kanbantable.error-delete-column'));
    }
    try {
      await dispatchAPI('DELETE', {
        url: `/kanbans/${kanbanCol._id}`
      });
    } catch (e) {
      message.error(e.message);
    }
    await getKanbanCols();
  };

  const editKanbanCol = async (_id, row) => {
    const col = kanbanCols.find((col) => col._id === _id);
    const usecol = deals.filter((deal) => deal.status === col.label);
    if (usecol.length !== 0 && row.label !== col.label) {
      return message.error(t('settings.kanbantable.error-delete-column'));
    }
    try {
      await dispatchAPI('PATCH', {
        url: `/kanbans/${_id}`,
        body: row
      });
    } catch (e) {
      message.error(e.message);
    }
    await getKanbanCols();
  };

  const fetchData = useCallback(async () => {
    await fetchDeals();
  }, []);

  useEffect(() => {
    (async () => {
      await fetchData();
    })();
  }, []);

  return (
    <>
      <KanbanTable
        kanbanCols={kanbanCols}
        editKanbanCol={editKanbanCol}
        deleteKanbanCol={deleteKanbanCol}
      />
      <Button
        type="dashed"
        style={{ width: '100%', height: 50 }}
        onClick={addKanbanCol}
      >
        {t('settings.kanbantable.addkanbancol')}
      </Button>
    </>
  );
};

export default KanbanSettings;
