import React from 'react';
import { Button, Checkbox, Divider, List, Popconfirm, Space } from 'antd';
import PropTypes from 'prop-types';
import {
  DeleteOutlined,
  DownloadOutlined,
  FileOutlined,
  SendOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import * as moment from 'moment';

const ListFiles = ({
  files,
  downloadFile,
  showModal,
  marginTop,
  purpose,
  customer,
  onAccept,
  idUser,
  deleteFileFromSession,
  reference,
  type
}) => {
  const { t } = useTranslation();

  const getUniqueListBy = (arr) => {
    const uniqueMap = new Map();
    arr.forEach((item) => {
      const type = item.type;
      if (
        !uniqueMap.has(type) ||
        moment(item.date) > moment(uniqueMap.get(type).date)
      ) {
        uniqueMap.set(type, item);
      }
    });

    return Array.from(uniqueMap.values());
  };

  const LastFilesUpdate = getUniqueListBy(files);

  return (
    <>
      <List
        style={{ marginTop }}
        dataSource={LastFilesUpdate}
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta
              avatar={<FileOutlined />}
              title={
                <span>
                  {item.filename}
                  {t('sessions.customer.generate_the', {
                    date: moment(item.date).format('DD-MM-YYYY')
                  })}
                </span>
              }
              style={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                maxWidth: '100%',
                whitespace: 'nowrap',
                wordBreak: 'break-all',
                whiteSpace: 'normal'
              }}
            />
            {purpose === 'customers' ? (
              <div
                style={{
                  float: 'right',
                  display: 'flex',
                  alignItems: 'center',
                  minWidth: 190,
                  justifyContent: 'space-between'
                }}
              >
                <Space>
                  <span>{t('sessions.customer.accept')}</span>
                  <Checkbox
                    disabled={
                      item.status === 'waiting' &&
                      customer &&
                      customer.status === 'ACCEPTED'
                    }
                    checked={item.status && item.status === 'accepted'}
                    onClick={(event) =>
                      onAccept(idUser, event.target.checked, item)
                    }
                    className="accept-file-checkbox"
                  />
                </Space>
                <Space>
                  <Button
                    type="link"
                    onClick={() => {
                      downloadFile(item._id, item.filename);
                    }}
                    style={{ padding: 0 }}
                  >
                    <DownloadOutlined />
                  </Button>
                  <Divider type="vertical" />
                  <Button
                    type="link"
                    onClick={() => {
                      showModal(reference, item.user._id, item, type);
                    }}
                    style={{ padding: 0 }}
                  >
                    <SendOutlined />
                  </Button>
                  <Divider type="vertical" />
                  <Popconfirm
                    title={t('datatable.column.action.delete.title')}
                    okText={t('datatable.column.action.delete.ok')}
                    okButtonProps={{ type: 'danger' }}
                    cancelText={t('datatable.column.action.delete.cancel')}
                    onConfirm={() => {
                      deleteFileFromSession(item._id);
                    }}
                    icon={<WarningOutlined />}
                  >
                    <DeleteOutlined style={{ color: 'red' }} type="delete" />
                  </Popconfirm>
                </Space>
              </div>
            ) : (
              <span style={{ float: 'right' }}>
                <Button
                  type="link"
                  onClick={() => {
                    downloadFile(item._id, item.filename);
                  }}
                >
                  <DownloadOutlined />
                </Button>
                <Divider type="vertical" />
                <Button
                  type="link"
                  onClick={() => {
                    showModal(reference, item.user._id, item, type);
                  }}
                >
                  <SendOutlined />
                </Button>
              </span>
            )}
          </List.Item>
        )}
      />
    </>
  );
};

export default ListFiles;

ListFiles.propTypes = {
  files: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  downloadFile: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  marginTop: PropTypes.number,
  purpose: PropTypes.string.isRequired,
  customer: PropTypes.shape(PropTypes.any),
  onAccept: PropTypes.func.isRequired,
  idUser: PropTypes.string.isRequired,
  deleteFileFromSession: PropTypes.func.isRequired,
  reference: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
};

ListFiles.defaultProps = {
  customer: null,
  marginTop: null
};
