import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Alert,
  Button,
  Col,
  Dropdown,
  Input,
  Menu,
  Row,
  Select,
  Typography
} from 'antd';
import {
  MenuOutlined,
  PlusOutlined,
  ArrowRightOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import ExportButton from '../ExportButton/ExportButton';
import { ImportTemplateButton } from '../ImportTemplateButton';
import ImportButton from '../importButton';
import Datatable from '../DataTable/Datatable';
import PageHeaderCustom from '../PageHeader/PageHeader';
import ContentCustom from '../ContentCustom/ContentCustom';
import useAuthContext from '../../contexts/AuthContext';
import checkSubscriptionPermissions from '../../utils/checkSubscriptionPermissions';
import ErrorStatusCode from '../../utils/ErrorStatusCode';

const { Search } = Input;
const { Title } = Typography;

const SimpleResourceLandingLayout = ({
  resourceName,
  tradKey,
  dataToFetch,
  columns,
  customActionColumn,
  headers,
  children,
  populate,
  extraQuery,
  extraHeader,
  extraButtons,
  exportUrl,
  withCreateButton,
  searchWithFilter,
  searchFilterItems,
  withUploadButton,
  forceRefresh,
  withSubRoutes,
  extraActionColumn,
  withEditButton,
  resourceModelName,
  scroll,
  isFileGenerate,
  path,
  formatter,
  withoutImportButton,
  templateType,
  deleteAction,
  withProjectionFields
}) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [searchValue, setSearchValue] = useState(params.get('k'));
  const [searchTypeValue, setSearchTypeValue] = useState(
    params.get('searchType')
  );
  const [resourceData, setResourceData] = useState([]);
  const [resourceNumber, setResourceNumber] = useState([]);
  const [minSubscription, setMinSubscription] = useState({});
  const [searchType, setSearchType] = useState('');
  const { user, dispatchAPI } = useAuthContext();
  const [importTemplate, setImportTemplate] = useState(null);
  const { notification } = ErrorStatusCode();

  const { Option } = Select;

  const getSubscription = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/subscriptions?title=AISANCE`
      });
      setMinSubscription(data[0]);
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  const searchResource = (value) => {
    if (value) {
      history.push({
        pathname,
        search: `?k=${value}&searchType=${searchType}`
      });
    } else {
      history.push({
        pathname
      });
    }
  };

  const getImportTemplates = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `import-template?populate=file&template_type=${templateType}`
      });
      setImportTemplate(data);
    } catch (e) {
      notification(e);
    }
  };

  const handleChange = (value) => {
    setSearchType(value);
  };

  useEffect(() => {
    (async () => {
      await getSubscription();
    })();
  }, []);

  useEffect(() => {
    setSearchValue(params.get('k'));
    setSearchTypeValue(params.get('searchType'));
  }, [params]);

  useEffect(() => {
    (async () => {
      if (templateType) {
        await getImportTemplates();
      }
    })();
  }, []);

  const menu = (
    <Menu>
      {headers && (
        <Menu.Item>
          <ExportButton
            dataName={resourceName}
            headers={headers}
            url={`/${exportUrl || resourceName}`}
            fileName={`${resourceName}.csv`}
            populate={populate}
            extraQuery={extraQuery}
            formatter={formatter}
          />
        </Menu.Item>
      )}
      {!withoutImportButton && (
        <>
          {user &&
            user.organization &&
            user.role === 'users:SUPER-USER' &&
            checkSubscriptionPermissions(
              resourceName,
              resourceData.length,
              user.organization.current_subscription || minSubscription
            ) && (
              <Menu.Item key="import">
                <ImportButton
                  resourceName={resourceModelName}
                  headers={headers}
                />
              </Menu.Item>
            )}
          {importTemplate && (
            <Menu.Item key="template_import">
              <ImportTemplateButton importTemplate={importTemplate} />
            </Menu.Item>
          )}
        </>
      )}
    </Menu>
  );
  return (
    <>
      <PageHeaderCustom
        title={tradKey || t(`${resourceName}.title`)}
        withSubRoutes={withSubRoutes}
        extra={extraHeader}
      />
      <ContentCustom>
        <Row justify="space-between" gutter={[8, 16]}>
          <Col style={{ display: 'flex' }}>
            {searchWithFilter && (
              <Select
                defaultValue={searchTypeValue || 'customer'}
                style={{ width: '40%' }}
                onChange={handleChange}
              >
                {searchFilterItems.map((item) => {
                  return <Option value={item.value}>{item.name}</Option>;
                })}
              </Select>
            )}
            <Search
              allowClear
              placeholder={t('placeholder.search')}
              defaultValue={searchValue}
              onSearch={(value) => searchResource(value, searchType)}
            />
          </Col>
          <Col
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Title level={4}>
              {t('subscriptions.show.number')}
              {resourceNumber}
            </Title>
          </Col>
          {!withCreateButton && headers && (
            <Col>
              <Dropdown overlay={menu}>
                <Button type="link">
                  <MenuOutlined
                    style={{ fontSize: 16, color: 'var(--textColor)' }}
                  />
                </Button>
              </Dropdown>
            </Col>
          )}
          {withCreateButton && (
            <>
              <Col>
                <Row align="middle">
                  {extraButtons}
                  <Link to={`${pathname}/create`}>
                    <Button
                      disabled={
                        user &&
                        user.organization &&
                        user.role === 'users:SUPER-USER' &&
                        !checkSubscriptionPermissions(
                          resourceName,
                          resourceData.length,
                          user.organization.current_subscription ||
                            minSubscription
                        )
                      }
                      type="primary"
                    >
                      {`${t('buttons.create')}`}
                      &nbsp;
                      <PlusOutlined />
                    </Button>
                  </Link>
                  {withUploadButton && (
                    <Dropdown overlay={menu}>
                      <Button type="link">
                        <MenuOutlined
                          style={{ fontSize: 16, color: 'var(--textColor)' }}
                        />
                      </Button>
                    </Dropdown>
                  )}
                </Row>
              </Col>
            </>
          )}
        </Row>
        <Row style={{ justifyContent: 'flex-end' }}>
          <Col xxl={6} xl={10} lg={18} md={20} sm={22} xs={24}>
            {user &&
              user.organization &&
              user.role === 'users:SUPER-USER' &&
              !checkSubscriptionPermissions(
                resourceName,
                resourceData.length,
                user.organization.current_subscription || minSubscription
              ) && (
                <Alert
                  showIcon
                  message={
                    <span>
                      {user.organization.current_subscription
                        ? t(`buttons.infos.${resourceName}`)
                        : t('profile.minSubscription')}
                      <Button
                        onClick={() => {
                          history.push('/profile');
                          localStorage.setItem('subscriptionIndex', '2');
                        }}
                        type="link"
                        icon={<ArrowRightOutlined />}
                      />
                    </span>
                  }
                  type="warning"
                />
              )}
          </Col>
        </Row>
        <Row gutter={[8, 16]}>{children}</Row>
        <Datatable
          withEditButton={withEditButton}
          setResourceData={setResourceData}
          setResourceNumber={setResourceNumber}
          style={{ marginTop: 24 }}
          resourceName={dataToFetch || resourceName}
          withProjectionFields={withProjectionFields}
          searchValue={searchValue}
          searchTypeValue={searchTypeValue}
          columns={columns}
          deleteAction={deleteAction}
          customActionColumn={customActionColumn}
          extraQuery={extraQuery}
          extraActionColumn={extraActionColumn}
          populate={populate}
          forceRefresh={forceRefresh}
          scroll={scroll}
          isFileGenerate={isFileGenerate}
          path={path}
        />
      </ContentCustom>
    </>
  );
};

SimpleResourceLandingLayout.propTypes = {
  searchFilterItems: PropTypes.arrayOf(PropTypes.shape({})),
  searchWithFilter: PropTypes.bool,
  isFileGenerate: PropTypes.bool,
  resourceName: PropTypes.string.isRequired,
  tradKey: PropTypes.string,
  dataToFetch: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  customActionColumn: PropTypes.bool,
  headers: PropTypes.arrayOf(PropTypes.shape({})),
  extraQuery: PropTypes.string,
  extraHeader: PropTypes.element,
  extraButtons: PropTypes.element,
  extraActionColumn: PropTypes.func,
  exportUrl: PropTypes.string,
  populate: PropTypes.string,
  withCreateButton: PropTypes.bool,
  withUploadButton: PropTypes.bool,
  forceRefresh: PropTypes.bool,
  withSubRoutes: PropTypes.bool,
  withEditButton: PropTypes.bool,
  resourceModelName: PropTypes.string,
  scroll: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number
  }),
  path: PropTypes.string,
  formatter: PropTypes.func,
  withoutImportButton: PropTypes.bool,
  templateType: PropTypes.string,
  deleteAction: PropTypes.bool,
  withProjectionFields: PropTypes.bool
};

SimpleResourceLandingLayout.defaultProps = {
  deleteAction: true,
  searchWithFilter: false,
  searchFilterItems: null,
  isFileGenerate: false,
  scroll: {},
  withEditButton: true,
  tradKey: null,
  headers: null,
  extraQuery: null,
  extraHeader: null,
  extraButtons: null,
  exportUrl: null,
  populate: null,
  customActionColumn: false,
  withCreateButton: true,
  withUploadButton: true,
  dataToFetch: null,
  forceRefresh: null,
  withSubRoutes: false,
  resourceModelName: null,
  path: null,
  formatter: null,
  withoutImportButton: false,
  templateType: null,
  extraActionColumn: null,
  withProjectionFields: false
};

export default SimpleResourceLandingLayout;
