import React from 'react';
import { useTranslation } from 'react-i18next';
import { BellOutlined, EditOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { Badge, Button, Col, List, Switch, Popover, Row, Select } from 'antd';
import styled from 'styled-components';
import { PageHeaderCustom } from '../../../components';
import { useAgendaContext } from '../../../contexts/AgendaContext';

const { Option } = Select;

const SwitchEdition = styled.div`
  font-size: 16px;
  color: var(--primaryColor);
  .anticon {
    margin-right: 10px;
  }
  .ant-switch {
    margin-left: 10px;
    margin-bottom: 5px;
  }
  .ant-switch-checked {
    background-color: var(--primaryColor);
  }
`;

const HeaderRender = ({ value, onChange, setEditMode, editMode }) => {
  const {
    notificationsList,
    setSession,
    session,
    sessions,
    sessionsWithoutModule
  } = useAgendaContext();
  const { t } = useTranslation();

  const start = 0;
  const end = 12;
  const monthOptions = [];

  const current = value.clone();
  const localeData = value.localeData();
  const months = [];
  for (let i = 0; i < 12; i += 1) {
    current.month(i);
    months.push(localeData.monthsShort(current));
  }

  for (let index = start; index < end; index += 1) {
    monthOptions.push(
      <Select.Option className="month-item" key={`${index}`}>
        {months[index]}
      </Select.Option>
    );
  }
  const month = value.month();

  const year = value.year();
  const options = [];
  for (let i = year - 2; i < year + 4; i += 1) {
    options.push(
      <Select.Option key={i} value={i} className="year-item">
        {i}
      </Select.Option>
    );
  }

  const content = (
    <List
      dataSource={notificationsList}
      renderItem={(item) => (
        <List.Item>
          <List.Item.Meta title={item.title} description={item.description} />
        </List.Item>
      )}
    />
  );

  const onSessionChange = (val) => {
    setSession(val);
  };

  return (
    <div style={{ padding: 8 }}>
      <Row>
        <Col span={4}>
          <PageHeaderCustom title="Agenda" />
        </Col>
        {notificationsList.length !== 0 && (
          <Col
            offset={18}
            span={2}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              paddingRight: 16
            }}
          >
            <Popover
              placement="leftBottom"
              trigger="click"
              content={content}
              title={t('agenda.notification.title')}
            >
              <Badge count={notificationsList.length}>
                <Button
                  type="text"
                  danger
                  icon={
                    <BellOutlined
                      style={{
                        fontSize: 22,
                        color: 'var(--primaryColor)',
                        marginTop: 2
                      }}
                    />
                  }
                />
              </Badge>
            </Popover>
          </Col>
        )}
      </Row>
      <Row align="middle" style={{ marginBottom: 12 }}>
        <Col
          span={3}
          style={{ paddingLeft: 24, display: 'flex', alignItems: 'center' }}
        >
          <p>{t('agenda.filter_by_session')}</p>
        </Col>
        <Col span={4}>
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            style={{ width: 200 }}
            onChange={onSessionChange}
            value={session}
          >
            <Option value="sessions">{t('agenda.all_sessions')}</Option>
            {sessions
              .filter((ssn) => ssn.status?.label !== 'A PLANIFIER')
              .map((ssn) => (
                <Option value={ssn._id}>{ssn.name}</Option>
              ))}
          </Select>
        </Col>
        {sessionsWithoutModule.length !== 0 && (
          <Col
            offset={10}
            span={5}
            style={{
              display: 'flex',
              alignItems: 'center',
              minHeight: 32
            }}
          >
            <SwitchEdition>
              <EditOutlined />
              {t('agenda.planning')}
              <Switch
                onChange={() => setEditMode(!editMode)}
                checked={editMode}
              />
            </SwitchEdition>
          </Col>
        )}
        <Col
          offset={!sessionsWithoutModule.length && 12}
          span={2}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            paddingRight: 16
          }}
        >
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            dropdownMatchSelectWidth={false}
            className="my-year-select"
            onChange={(newYear) => {
              const now = value.clone().year(newYear);
              onChange(now);
            }}
            value={String(year)}
          >
            {options}
          </Select>
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            dropdownMatchSelectWidth={false}
            value={String(month)}
            onChange={(selectedMonth) => {
              const newValue = value.clone();
              newValue.month(parseInt(selectedMonth, 10));
              onChange(newValue);
            }}
            style={{ marginLeft: 8 }}
          >
            {monthOptions}
          </Select>
        </Col>
      </Row>
    </div>
  );
};

HeaderRender.propTypes = {
  value: PropTypes.shape({
    clone: PropTypes.func,
    localeData: PropTypes.func,
    month: PropTypes.func,
    year: PropTypes.func
  }),
  onChange: PropTypes.func,
  setEditMode: PropTypes.func,
  editMode: PropTypes.bool
};

HeaderRender.defaultProps = {
  value: {},
  onChange: () => {},
  setEditMode: () => {},
  editMode: false
};

export default HeaderRender;
