import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import useColumns from './Columns';
import ModalSendMails from '../Convocations/modalSendMails';

const EmailHistoryTable = ({ emails, session }) => {
  const [sendType, setSendType] = useState('');
  const [users, setUsers] = useState([]);
  const [emailPurpose, setEmailPurpose] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [emailContent, setEmailContent] = useState('');
  const [emailModel, setEmailModel] = useState('');
  const [emailObject, setEmailObject] = useState('');

  const columns = useColumns({
    setSendType,
    setUsers,
    setEmailPurpose,
    setIsModalVisible,
    setEmailContent,
    setEmailModel,
    setEmailObject
  });

  return (
    <>
      <Table dataSource={emails} columns={columns} />
      {isModalVisible && (
        <ModalSendMails
          file={[]}
          session={session}
          sendType={sendType}
          emailPurpose={emailPurpose}
          users={users}
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          emailContent={emailContent}
          emailModel={emailModel}
          emailObject={emailObject}
        />
      )}
    </>
  );
};

EmailHistoryTable.propTypes = {
  emails: PropTypes.arrayOf({}).isRequired
};

export default EmailHistoryTable;
