import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Card, Descriptions, Button, Row, Col } from 'antd';
import moment from 'moment';
import ChangeSubscriptionButton from '../../payment/ChangeSubscription';

const MySubcription = ({
  subscription,
  myAdvantages,
  advantagesLabels,
  user
}) => {
  const { t } = useTranslation();
  const nextDebitDate = () => {
    const filteredData = subscription.upcoming_payments.filter(
      (item) => new Date(item.charge_date) > new Date()
    );

    const smallestDateObject = filteredData.reduce((prev, curr) => {
      const prevDate = new Date(prev.charge_date);
      const currDate = new Date(curr.charge_date);
      return prevDate < currDate ? prev : curr;
    });
    return smallestDateObject;
  };

  return (
    <Card
      title={subscription.type}
      headStyle={{ fontWeight: 'bold' }}
      style={{ borderColor: 'orange' }}
      extra={
        <Button type="link" style={{ fontSize: 18 }}>
          <strong>{`${subscription.amount / 100}€`}</strong>
        </Button>
      }
    >
      <Descriptions>
        <Descriptions.Item label={t('subscriptions.date')}>
          {moment(subscription.start_date).format('L')}
        </Descriptions.Item>
        <Descriptions.Item label={t('subscriptions.next_debit_date')}>
          {nextDebitDate().charge_date &&
            moment(nextDebitDate().charge_date).format('L')}
        </Descriptions.Item>
        <br />
        <Descriptions.Item label={t('subscriptions.advantages_list')}>
          <Row justify="space-around" style={{ marginTop: 8 }}>
            {myAdvantages &&
              myAdvantages.options.map((option, idx) => (
                <Col xs={24} sm={24} md={12} lg={8}>
                  <p>
                    {`${advantagesLabels[idx]} :`}
                    <strong style={{ marginLeft: 8 }}>{option}</strong>
                  </p>
                </Col>
              ))}
          </Row>
        </Descriptions.Item>
      </Descriptions>
      <Row justify="space-between">
        <Link to="/profile/invoices">
          <Button type="primary">{t('subscriptions.invoices')}</Button>
        </Link>
        <ChangeSubscriptionButton organization={user.organization} />
      </Row>
    </Card>
  );
};

MySubcription.propTypes = {
  myAdvantages: PropTypes.shape({
    options: PropTypes.arrayOf
  }).isRequired,
  subscription: PropTypes.shape({
    amount: PropTypes.number,
    type: PropTypes.string,
    start_date: PropTypes.string,
    upcoming_payments: PropTypes.arrayOf
  }).isRequired,
  advantagesLabels: PropTypes.arrayOf().isRequired,
  user: PropTypes.shape({
    organization: PropTypes.shape({})
  }).isRequired
};

export default MySubcription;
