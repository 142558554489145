import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Layout, message, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import LoginForm from './LoginForm';
import RegisterForm from './RegisterForm';
import ForgotPwdForm from './ForgotPwdForm';
import useHandleResize from '../../utils/HandleResize';
import ChangePwdForm from './ChangePwdForm';
import Logo from '../../assets/images/demateriz-logo-gray.svg';
import useAuthContext from '../../contexts/AuthContext';
import ErrorStatusCode from '../../utils/ErrorStatusCode';

const Login = () => {
  const { dispatchAPI } = useAuthContext();
  const { token, sponsor } = useParams();
  const history = useHistory();
  const { t } = useTranslation();
  const { Title } = Typography;
  const { width, height } = useHandleResize();
  const [currentForm, setCurrentForm] = useState('login');
  const [googleUrl, setGoogleUrl] = useState('');
  const [code, setCode] = useState('');
  const { notification } = ErrorStatusCode();
  const { location } = history;
  const forms = {
    login: <LoginForm switchForm={(f) => setCurrentForm(f)} />,
    prescriber: (
      <RegisterForm switchForm={(f) => setCurrentForm(f)} prescriber />
    ),
    register: (
      <RegisterForm switchForm={(f) => setCurrentForm(f)} sponsor={sponsor} />
    ),
    forgotPwd: <ForgotPwdForm switchForm={(f) => setCurrentForm(f)} />,
    changePwd: (
      <ChangePwdForm switchForm={(f) => setCurrentForm(f)} token={token} />
    )
  };

  const postGoogleCode = async (googleCode) => {
    try {
      await dispatchAPI('GOOGLE_LOGIN', { googleCode });
      message.success(t('login.success'));
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  if (location.search) {
    const searchString = location.search.split('?')[1];
    const searchParts = searchString.split('&');

    searchParts.forEach((part) => {
      const partMap = part.split('=');

      if (partMap[0] === 'code') {
        const newCode = partMap[1];
        if (code === '') setCode(newCode);
      }
    });
  }

  useEffect(() => {
    if (code !== '') {
      (async () => {
        await postGoogleCode(decodeURIComponent(code));
      })();
    }
  }, [code]);

  const getGoogleUrl = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: `/auth2/google` });
      setGoogleUrl(data.url);
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  useEffect(() => {
    if (token && token !== 'register') {
      setCurrentForm('changePwd');
    } else if (token === 'register' || sponsor) {
      setCurrentForm('register');
    }
  }, [token]);

  useEffect(() => {
    (async () => {
      await getGoogleUrl();
    })();
  }, [googleUrl]);

  return (
    <div>
      <Layout
        style={{
          minHeight: '100vh',
          maxHeight: '100vh',
          display: 'inherit'
        }}
      >
        <Row style={{ maxHeight: '100vh' }}>
          <Col xs={{ span: 0 }} md={{ span: 12 }} lg={{ span: 16 }}>
            <Row justify="center" align="middle" style={{ height: '100vh' }}>
              <img style={{ width: '80%' }} alt="logo" src={Logo} />
            </Row>
          </Col>
          <Col
            xs={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 8 }}
            style={{
              backgroundColor: 'var(--componentBackground)',
              height: '100vh',
              maxHeight: '100vh',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'center',
              padding: '32px 5vw'
            }}
          >
            <img
              alt="logo"
              style={{ width: '60%', alignSelf: 'center', marginBottom: 80 }}
              src={Logo}
            />
            <Title style={{ textAlign: 'center', marginBottom: 40 }}>
              {t(`login.title.${currentForm}`)}
            </Title>
            {forms[currentForm]}
          </Col>
        </Row>
      </Layout>
    </div>
  );
};

Login.propTypes = {
  location: PropTypes.shape({}).isRequired
};

export default Login;
