import moment from 'moment';

const CheckTrialDays = (user) => {
  const dateCreationOrg = moment(user?.organization?.created_at)
    .local()
    .format('YYYY-MM-DD HH:mm:ss');
  const freedate = moment(dateCreationOrg).add(15, 'days');
  let check = false;
  if (user?.current_subscription) {
    check = true;
  } else if (
    freedate.local().format('YYYY-MM-DD HH:mm:ss') >
    moment()
      .local()
      .format('YYYY-MM-DD HH:mm:ss')
  ) {
    check = true;
  }

  return check;
};
export default CheckTrialDays;
