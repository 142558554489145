import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Checkbox, Divider, Form, Input, Select, message } from 'antd';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import {
  formItemLayout,
  noLabelLayout
} from '../../../utils/constants/formLayout';
import useAuthContext from '../../../contexts/AuthContext';
import ErrorStatusCode from '../../../utils/ErrorStatusCode';

const { Option } = Select;

const ContactFormList = ({
  setContacts,
  companyRepresentative,
  setCompanyRepresentative,
  checkIfCompanyRepresentative
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { dispatchAPI } = useAuthContext();
  const [enums, setEnums] = useState({});
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const { notification } = ErrorStatusCode();

  const onChange = (_, values) => {
    setContacts(values.contacts);
  };

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/contacts/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  useEffect(() => {
    (async () => {
      setIsFieldsLoading(true);
      await getEnums();
      setIsFieldsLoading(false);
    })();
  }, []);

  const companyRepresentativeState = (indexContact, checked) => {
    const list = { ...companyRepresentative };
    list[indexContact] = checked;
    setCompanyRepresentative(list);
    if (!checkIfCompanyRepresentative(list)) {
      message.info(t('companies.message.contact'));
    }
  };

  const onCustomRemove = (indexContact) => {
    const list = { ...companyRepresentative };
    delete list[indexContact];
    setCompanyRepresentative(list);
  };

  const onCustomAdd = () => {
    const list = { ...companyRepresentative };
    list[Object.keys(list).length] = false;
    setCompanyRepresentative(list);
  };

  return (
    <Form
      name="dynamic_form_nest_item"
      autoComplete="off"
      initialValues={{
        contacts: [{ fieldKey: 0, isListField: true, key: 0, name: 0 }]
      }}
      onValuesChange={(_, values) => onChange(_, values)}
      form={form}
      {...formItemLayout}
      {...noLabelLayout}
    >
      <Divider orientation="left" style={{ marginTop: 32 }}>
        <span>{t(`Contacts`)}</span>
      </Divider>
      <Form.List name="contacts">
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, index) => (
              <div
                key={field.key}
                style={{
                  background: 'var(--contrastBackground)',
                  padding: 16,
                  borderRadius: 4,
                  marginBottom: 20
                }}
                className="company-contacts-wrapper"
              >
                <CloseOutlined
                  onClick={() => {
                    if (index) {
                      onCustomRemove(index);
                      remove(field.name);
                    }
                  }}
                />
                <Form.Item
                  {...field}
                  label={t('contacts.form.gender')}
                  rules={[{ required: true }]}
                  name={[field.name, 'gender']}
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    loading={isFieldsLoading}
                  >
                    {(enums.genders || []).map((title) => (
                      <Option key={title} value={title}>
                        {t(`contacts.form.${title}`)}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  {...field}
                  label={t('contacts.form.last_name')}
                  rules={[
                    {
                      required: true
                    }
                  ]}
                  name={[field.name, 'last_name']}
                  fieldKey={[field.fieldKey, 'last_name']}
                >
                  <Input placeholder={t('contacts.form.last_name')} />
                </Form.Item>
                <Form.Item
                  {...field}
                  label={t('contacts.form.first_name')}
                  rules={[
                    {
                      required: true
                    }
                  ]}
                  name={[field.name, 'first_name']}
                  fieldKey={[field.fieldKey, 'first_name']}
                >
                  <Input placeholder={t('contacts.form.last_name')} />
                </Form.Item>
                <Form.Item
                  {...field}
                  label={t('contacts.form.email')}
                  rules={[
                    {
                      required: true
                    }
                  ]}
                  name={[field.name, 'email']}
                  fieldKey={[field.fieldKey, 'email']}
                >
                  <Input placeholder={t('contacts.form.email')} />
                </Form.Item>

                <Input.Group compact>
                  <Form.Item
                    noStyle
                    name={[field.name, 'phone_number', 'country_code']}
                    initialValue="+33"
                  >
                    <Select style={{ width: 70 }}>
                      <Option value="+33">+33</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    noStyle
                    name={[field.name, 'phone_number', 'number']}
                  >
                    <Input
                      type="number"
                      style={{ width: 'calc(100% - 70px)' }}
                    />
                  </Form.Item>
                </Input.Group>

                <Form.Item
                  className="company-contact-checkbox"
                  label={t('contacts.form.company_representative')}
                  {...field}
                  name={[field.name, 'company_representative']}
                  fieldKey={[field.fieldKey, 'company_representative']}
                >
                  <Checkbox
                    checked={companyRepresentative?.[index]}
                    onClick={(event) =>
                      companyRepresentativeState(index, event.target.checked)
                    }
                  />
                </Form.Item>
              </div>
            ))}
            <Form.Item className="company-contact-button">
              <Button
                type="dashed"
                onClick={() => {
                  onCustomAdd();
                  add();
                }}
                block
                icon={<PlusOutlined />}
              >
                {t('contacts.add_contact')}
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </Form>
  );
};

ContactFormList.propTypes = {
  setContacts: PropTypes.func.isRequired,
  companyRepresentative: PropTypes.shape({}).isRequired,
  setCompanyRepresentative: PropTypes.func.isRequired,
  checkIfCompanyRepresentative: PropTypes.func.isRequired
};

export default ContactFormList;
