import React from 'react';
import PropTypes from 'prop-types';
import { Button, Row } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useDownloadDocument } from '../utils/downloadDoc';

export const ImportTemplateButton = ({ importTemplate }) => {
  const { t } = useTranslation();
  const { downloadDocument } = useDownloadDocument();
  return (
    <Row justify="start">
      <Button
        style={{ padding: 0, margin: 0, color: 'black' }}
        type="link"
        onClick={() =>
          downloadDocument({
            _id: importTemplate.file._id,
            metadata: importTemplate.file.metadata,
            contenType: importTemplate.file.contentType
          })
        }
      >
        <DownloadOutlined style={{ fontSize: '14px' }} />
        {t('buttons.import_template')}
      </Button>
    </Row>
  );
};

ImportTemplateButton.propTypes = {
  importTemplate: PropTypes.shape({
    file: PropTypes.shape({
      _id: PropTypes.string,
      metadata: PropTypes.shape({}),
      contentType: PropTypes.string
    })
  }).isRequired
};
