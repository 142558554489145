import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card, Divider, List } from 'antd';
import useAuthContext from '../../../contexts/AuthContext';
import { PageHeaderCustom } from '../../../components';
import ListQuizzes from '../../Sessions/Quizzes/ListQuizzes';
import ModalUpdateNameQuiz from '../../Sessions/Quizzes/ModalUpdateNameQuiz';
import ErrorStatusCode from '../../../utils/ErrorStatusCode';

const QuizzesModels = () => {
  const { dispatchAPI, user } = useAuthContext();
  const { t } = useTranslation();
  const [quizzes, setQuizzes] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [type, setType] = useState('');
  const { notification } = ErrorStatusCode();
  const [isLoading, setIsLoading] = useState(false);

  const quizzesModel = [
    'training_quiz_model',
    'pre_training_model',
    'post_training_j_model',
    'post_training_month_model',
    'managers_quizzes_model',
    'contributors_quizzes_model',
    'funders_quizzes_model'
  ];

  const showModal = (typeModel) => {
    setType(typeModel);
    setIsVisible(!isVisible);
  };

  const getQuizzes = async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/quizzes?filter={"session":{"$exists":false}}'
      });
      setQuizzes(
        data
          .filter((el) => !el.session)
          .map((el) => ({
            ...el,
            key: el._id
          }))
      );
    } catch (e) {
      if (e.response) notification(e.response);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    (async () => {
      await getQuizzes();
    })();
  }, [refresh]);

  return (
    <>
      <ModalUpdateNameQuiz
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        quizzes={quizzes}
        setRefresh={setRefresh}
        refresh={refresh}
        purpose="models"
        type={type}
        isLoading={isLoading}
      />
      <PageHeaderCustom title={t('quizzesModels.title')} />
      <List
        dataSource={quizzesModel}
        renderItem={(item) => (
          <>
            <Divider orientation="left">
              <span>{t(`quizzesModels.models.${item}`)}</span>
            </Divider>
            <Card style={{ margin: '0 20px 32px 20px' }}>
              <ListQuizzes
                purpose="models"
                quizzes={quizzes.filter(
                  (el) =>
                    el.type === item &&
                    el.organization &&
                    el.organization === user.organization._id
                )}
                refresh={refresh}
                setRefresh={setRefresh}
                isVisible={isVisible}
                setIsVisible={setIsVisible}
                type={item}
              />
              <Button
                type="link"
                onClick={() => showModal(item)}
                style={{ marginTop: 10, paddingLeft: 0 }}
              >
                {t(`quizzesModels.models.${item}`)}
              </Button>
            </Card>
          </>
        )}
      />
    </>
  );
};

export default QuizzesModels;
