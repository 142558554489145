import React from 'react';
import { Tag } from 'antd';
import moment from 'moment';
import { userRoles } from '../../utils/constants/tagColors';

const userList = (dataUser, t) => {
  const {
    name,
    created_at,
    location_tell,
    activity_declaration,
    subscription,
    date_subcription_canceled,
    current_subscription
  } = dataUser.organization || {};
  const {
    first_name,
    last_name,
    email,
    phone_number,
    role,
    address,
    totalAlreadyBilled
  } = dataUser || {};

  const setPaymentDate = () => {
    const upcomingPayment = subscription.upcoming_payments.find((item) =>
      moment(item.charge_date).isAfter(moment())
    );

    if (upcomingPayment) {
      return moment(upcomingPayment.charge_date).format('DD/MM/YYYY');
    }

    return null;
  };

  return [
    {
      label: 'users.form.role',
      span: 1,
      content:
        (role && (
          <Tag color={userRoles[role.split(':')[1]]}>
            {t(`users.tags.${role.split(':')[1]}`)}
          </Tag>
        )) ||
        '-'
    },
    {
      label: t('company.form.name'),
      span: 1,
      content: name || '-'
    },

    {
      label: t('subscriptions.name_client'),
      span: 1,
      content: `${first_name}  ${last_name}`
    },
    {
      label: t('subscriptions.show.type_subscription'),
      span: 1,
      content: subscription && subscription.type
    },
    {
      label: t('subscriptions.show.amount_subscription'),
      span: 1,
      content: current_subscription && `${current_subscription.amount} € HT`
    },
    {
      label: t('subscriptions.show.subscription_date'),
      span: 1,
      content: subscription && setPaymentDate()
    },
    {
      label: t('subscriptions.show.creation_date'),
      span: 2,
      content:
        subscription && moment(subscription.created_at).format('DD/MM/YYYY')
    },
    {
      label: 'users.form.email',
      span: 1,
      content: email
    },
    {
      label: t('subscriptions.show.termination_date'),
      content: date_subcription_canceled
        ? moment(date_subcription_canceled).format('DD/MM/YYYY')
        : '-'
    },

    {
      label: 'profile.form.phone_number',
      content:
        phone_number && phone_number.number ? (
          <div>
            {`${phone_number.country_code} ${phone_number.number.slice(
              0,
              1
            )} ${phone_number.number.slice(1).replace(/(.{2})/g, '$1 ')}
                      `}
          </div>
        ) : (
          '-'
        )
    },
    {
      label: 'profile.form.address',
      content:
        `${address?.number || '-'} ${address?.street ||
          ''} ${address?.postal_code || ''} ${address?.city ||
          ''} ${address?.additional || ''}` || '-'
    },
    {
      label: 'subscriptions.show.totalAlreadyBilled',
      content: (totalAlreadyBilled && `${totalAlreadyBilled} € TTC`) || '-'
    }
  ];
};

export default userList;
