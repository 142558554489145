import React from 'react';
import { Alert, Col, Divider, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import SessionCustomersTable from '../sessionCustomers/SessionCustomersTable';

const Customers = ({
  customers,
  modules,
  session,
  fetchData,
  updateSession,
  contacts,
  refresh,
  setRefresh,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Row style={{ padding: '0 20px' }}>
        <Col span={24}>
          <Alert
            showIcon
            type="info"
            message={
              'Afin de passer cette étape, vous devez : accepter au moins un prospect en acceptant soit le devis, la convention ou le programme'
            }
          />
        </Col>
      </Row>
      <Divider orientation="left">
        <span>{t(`sessions.form.prospects`)}</span>
      </Divider>
      <SessionCustomersTable
        modules={modules}
        session={session}
        allCustomers={customers}
        customers={customers.filter(el => el.status === 'NEW')}
        fetchData={fetchData}
        updateSession={updateSession}
        purpose="customers"
        type="prospects"
        contacts={contacts}
        refresh={refresh}
        setRefresh={setRefresh}
      />

      <Divider orientation="left">
        <span>{t(`sessions.form.customers`)}</span>
      </Divider>
      <SessionCustomersTable
        modules={modules}
        session={session}
        allCustomers={customers}
        customers={customers.filter(el => el.status === 'ACCEPTED')}
        fetchData={fetchData}
        updateSession={updateSession}
        purpose="customers"
        type="accepted"
        contacts={contacts}
        refresh={refresh}
        setRefresh={setRefresh}
      />
    </>
  );
};

export default Customers;
