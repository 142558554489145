import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import Profile from './Profile';
import UpdateProfile from './UpdateProfile';
import UpdatePwd from './UpdatePwd';
import UpdateCompany from './UpdateCompany';
import { ListInvoices } from './Invoices/ListInvoices';

const ProfileRouter = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/edit/:id`} component={UpdateProfile} />
      <Route
        path={`${path}/company/create`}
        render={() => <UpdateCompany purpose="create" />}
      />
      <Route
        path={`${path}/company/edit/:id`}
        render={() => <UpdateCompany purpose="edit" />}
      />

      <Route path={`${path}/change-pwd`} component={UpdatePwd} />
      <Route path={`${path}/invoices`} component={ListInvoices} />
      <Route path={path} component={Profile} />
    </Switch>
  );
};

export default ProfileRouter;
