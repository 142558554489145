export const getModules = async ({
  dispatchAPI,
  setModules,
  id,
  message,
  t
}) => {
  try {
    const { data } = await dispatchAPI('GET', {
      url: `/module?session=${id}`
    });
    setModules(data[0]);
  } catch (e) {
    message.error(t('trainees.message.error'));
  }
};
