import React, { useEffect, useState } from 'react';
import { Alert, Col, Divider, Row, message } from 'antd';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../../contexts/AuthContext';
import ProductList from './productList';
import InvoiceIndicator from './InvoiceIndicator';
import ChargesTable from './ChargesTable';
import CalculRateModule from './CalculRateModule';

const Invoices = ({
  session,
  customers,
  fetchData,
  contacts,
  forceRefresh,
  setForceRefresh,
  emails,
  modules
}) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { dispatchAPI } = useAuthContext();
  const [totalProduct, setTotalProduct] = useState(0);
  const [totalCharge, setTotalCharge] = useState(0);
  const [creditNotes, setCreditNotes] = useState([]);
  const [totalCreditNotes, setTotalCreditNotes] = useState(0);
  const [totalMarges, setTotalMarges] = useState(0);

  const getCreditNotes = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/credit-notes?sessionId=${id}`
      });
      setCreditNotes(data);
    } catch (e) {
      message.error(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getCreditNotes();
    })();
  }, []);

  const calculTotalCharge = () => {
    let total = 0;
    let totalMarge = 0;
    let totalCreditNote = 0;
    modules.forEach((module) => {
      const [amount, margeAmount] = CalculRateModule(module, customers);
      total += amount;
      totalMarge += margeAmount;
    });

    if (creditNotes.length) {
      creditNotes.forEach((creditNote) => {
        totalCreditNote += creditNote.total_ttc;
      });
    }
    setTotalMarges(totalMarge);
    setTotalCreditNotes(totalCreditNote);
    setTotalProduct(total);
  };

  useEffect(() => {
    calculTotalCharge();
  }, [modules, customers, creditNotes]);

  return (
    <>
      <Row style={{ padding: '0 20px' }}>
        <Col span={24}>
          <Alert
            showIcon
            type="info"
            message={t('sessions.invoices.messages.next_step')}
          />
        </Col>
      </Row>
      <Divider orientation="left">
        <span>{t(`sessions.invoices.marge`)}</span>
      </Divider>
      <InvoiceIndicator
        visible={customers.length}
        totalProduct={totalProduct}
        totalCharge={totalCharge}
        totalCreditNotes={totalCreditNotes}
        totalMarges={totalMarges}
      />
      <Divider orientation="left">
        <span>{t(`sessions.invoices.products`)}</span>
      </Divider>
      <ProductList
        emails={emails}
        session={session}
        customers={customers}
        fetchData={fetchData}
        contacts={contacts}
        forceRefresh={forceRefresh}
        setForceRefresh={setForceRefresh}
      />
      <Divider orientation="left">
        <span>{t(`sessions.invoices.charges`)}</span>
      </Divider>
      <ChargesTable
        session={session}
        customers={customers}
        setTotalCharge={setTotalCharge}
      />
    </>
  );
};

export default Invoices;

Invoices.propTypes = {
  session: PropTypes.shape({}).isRequired,
  customers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fetchData: PropTypes.func.isRequired,
  contacts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  forceRefresh: PropTypes.bool.isRequired,
  setForceRefresh: PropTypes.func.isRequired,
  emails: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  modules: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};
