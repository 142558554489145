import React, { useEffect, useState } from 'react';
import {
  DeleteOutlined,
  EyeOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import PropTypes from 'prop-types';
import { Button, Col, Popconfirm, Popover, Row, Tag } from 'antd';
import { routes } from '../../../utils/constants/routes';
import { useAgendaContext } from '../../../contexts/AgendaContext';
import DetailsSession from '../DetailsSession';

const CellRenderWithPopOver = ({
  handleVisibleChange,
  value,
  condition,
  popoverVisible,
  module
}) => {
  const {
    modules,
    sessions,
    deleteSubSlot,
    updateModule,
    fetchModules
  } = useAgendaContext();
  const [editSlot, setEditSlot] = useState(false);
  const [moduleEdit, setModuleEdit] = useState({});
  const { t } = useTranslation();

  const CellRender = () => {
    return (
      <li style={{ fontSize: 10 }}>
        <Row
          style={{
            borderLeft: `3px solid ${module.color}`,
            padding: '0 2px 0 4px',
            marginTop: 6
          }}
        >
          <Col span={24}>
            <Tag>{module.module}</Tag>
            <Tag>{module.start_time}</Tag>
            <Link
              to={{
                pathname: `${routes.SESSIONS}/show/${module.idSession}`
              }}
            >
              <EyeOutlined style={{ fontSize: 16 }} />
            </Link>
            <h3>{module.session}</h3>
            {module.customers.map(({ customer }) => (
              <span>
                {`${customer?.name ||
                  `${customer?.last_name} ${customer?.first_name}`}, `}
              </span>
            ))}
          </Col>
        </Row>
      </li>
    );
  };

  const selectTime = (values, idModule, idSlot, idSubSlot) => {
    const selectedModule = modules.filter((el) => el._id === idModule)[0];
    const subSlot = selectedModule.slots.date
      .find((date) => date._id.toString() === idSlot.toString())
      .sub_slots.find((sub) => sub._id.toString() === idSubSlot.toString());
    subSlot.start_time = values[0];
    subSlot.end_time = values[1];
    setModuleEdit(selectedModule);
  };

  const updateSlot = async () => {
    setEditSlot(!editSlot);
    await updateModule(moduleEdit);
    await fetchModules();
  };

  useEffect(() => {
    if (!popoverVisible) {
      setEditSlot(false);
    }
  }, [popoverVisible]);

  return condition ? (
    <Popover
      style={{ width: '50%' }}
      placement="leftTop"
      onVisibleChange={handleVisibleChange}
      title={
        <Row>
          <Col span={12}>
            <h3>{module.session}</h3>
          </Col>
          <Col
            span={12}
            style={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ danger: true }}
              cancelText={t('datatable.column.action.delete.cancel')}
              icon={<WarningOutlined />}
              onConfirm={() => {
                deleteSubSlot(module.id, module.idSlot, module.idSubSlot);
              }}
            >
              <Button type="link" danger>
                {`${t('buttons.delete')} `}
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </Col>
        </Row>
      }
      content={() =>
        DetailsSession(
          sessions,
          modules,
          editSlot,
          setEditSlot,
          selectTime,
          updateSlot,
          module.idSession,
          module.id,
          module.idSlot,
          module.idSubSlot,
          module.type,
          module.date,
          module.start_time,
          module.end_time
        )
      }
      trigger="click"
    >
      <div>
        <CellRender value={value} module={module} />
      </div>
    </Popover>
  ) : (
    <CellRender value={value} module={module} />
  );
};

CellRenderWithPopOver.propTypes = {
  handleVisibleChange: PropTypes.func,
  value: PropTypes.shape({}),
  condition: PropTypes.bool,
  popoverVisible: PropTypes.bool,
  module: PropTypes.shape({
    id: PropTypes.string,
    idSession: PropTypes.string,
    color: PropTypes.string,
    module: PropTypes.string,
    start_time: PropTypes.string,
    session: PropTypes.string,
    customers: PropTypes.arrayOf(
      PropTypes.shape({
        customer: PropTypes.shape({
          name: PropTypes.string,
          last_name: PropTypes.string,
          first_name: PropTypes.string
        })
      })
    ),
    idSlot: PropTypes.string,
    idSubSlot: PropTypes.string,
    type: PropTypes.string,
    date: PropTypes.string,
    end_time: PropTypes.string,
    slots: PropTypes.shape({
      date: PropTypes.arrayOf(
        PropTypes.shape({
          _id: PropTypes.string,
          sub_slots: PropTypes.arrayOf(
            PropTypes.shape({
              _id: PropTypes.string,
              start_time: PropTypes.string,
              end_time: PropTypes.string
            })
          )
        })
      )
    })
  })
};

CellRenderWithPopOver.defaultProps = {
  handleVisibleChange: () => {},
  value: {},
  condition: false,
  popoverVisible: false,
  module: {
    id: '',
    idSession: '',
    color: '',
    module: '',
    start_time: '',
    session: '',
    customers: []
  }
};

export default CellRenderWithPopOver;
