import React from 'react';
import PropTypes from 'prop-types';
import { Divider, List } from 'antd';
import { useTranslation } from 'react-i18next';

const ListItemsBPF = ({ marginLeft, items, finance }) => {
  const { t } = useTranslation();

  return (
    <>
      <List
        size="small"
        style={{ ...marginLeft }}
        dataSource={items}
        renderItem={(item) => (
          <>
            <List.Item>
              <List.Item.Meta title={t(`bpf.${item.title}`)} />
              <span
                style={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                  float: 'right',
                  marginLeft: 50
                }}
              >
                {(item.quantity !== undefined || !finance) && (
                  <>
                    <span style={{ fontSize: 11, textTransform: 'uppercase' }}>
                      {finance ? '' : 'Nombre'}
                    </span>
                    <span style={{ fontWeight: 700 }}>{item.quantity}</span>
                  </>
                )}
              </span>
              <Divider type="vertical" />
              <span
                style={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                  float: 'right'
                }}
              >
                {(item.total_hours !== undefined || !finance) && (
                  <>
                    <span style={{ fontSize: 11, textTransform: 'uppercase' }}>
                      {t('dashboards.total_hours')}
                    </span>
                    <span style={{ fontWeight: 700 }}>{item.total_hours}</span>
                  </>
                )}
              </span>
            </List.Item>
            {item.levels && finance && (
              <ListItemsBPF
                marginLeft={{ marginLeft: marginLeft ? 100 : 50 }}
                items={item.levels}
                finance
              />
            )}
          </>
        )}
      />
    </>
  );
};

ListItemsBPF.propTypes = {
  marginLeft: PropTypes.shape({}).isRequired,
  items: PropTypes.arrayOf({}).isRequired,
  finance: PropTypes.bool
};

ListItemsBPF.defaultProps = {
  finance: undefined
};

export default ListItemsBPF;
