import React from 'react';
import { Card } from 'antd';
import { useTranslation } from 'react-i18next';
import { PageHeaderCustom } from '../../../components';
import RecapQualitySubscribers from './RecapQualitySubscribers';

const QualitySubscribers = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageHeaderCustom title={t('subscriptions.quality.title')} />
      <Card
        style={{ marginTop: 20, marginBottom: 20 }}
        bordered={false}
        className="card-no-border"
      >
        <RecapQualitySubscribers type="subscribers" />
      </Card>
    </>
  );
};

export default QualitySubscribers;
