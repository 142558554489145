import React from 'react';
import { Tooltip } from 'antd';

const columns = (t, type, purpose) => [
  ...(purpose === 'session'
    ? [
        {
          title: t(`quality.columns.${type}`),
          key: type,
          width: '15.05%',
          ellipsis: {
            showTitle: false
          },
          render: (data) => (
            <Tooltip placement="topLeft" title={data.name}>
              {data.name}
            </Tooltip>
          ),
          sorter: true
        },
        {
          title: t('quality.columns.post_training_j'),
          width: '31.12%',
          render: (session) =>
            session.notes.find((el) => el.type === 'post_training_j').note
              ? session.notes
                  .find((el) => el.type === 'post_training_j')
                  .note.toFixed(2)
              : 'N/A',
          sorter: true
        },
        {
          title: t('quality.columns.post_training_month'),
          width: '29.3%',
          render: (session) =>
            session.notes.find((el) => el.type === 'post_training_month').note
              ? session.notes
                  .find((el) => el.type === 'post_training_month')
                  .note.toFixed(2)
              : 'N/A',
          sorter: true
        },
        {
          title: t('quality.columns.total'),
          key: 'total',
          render: (session) =>
            session.notes.find((el) => el.type === 'global').note
              ? session.notes.find((el) => el.type === 'global').note.toFixed(2)
              : 'N/A'
        }
      ]
    : [
        {
          title: t(`quality.columns.${type}`),
          key: type,
          render: (data) => data.name,
          sorter: true
        },
        {
          title: t('quality.columns.post_training_j'),
          render: (year) =>
            year.notes.find((el) => el.type === 'post_training_j').note
              ? year.notes
                  .find((el) => el.type === 'post_training_j')
                  .note.toFixed(2)
              : 'N/A',
          sorter: true
        },
        {
          title: t('quality.columns.post_training_month'),
          render: (year) =>
            year.notes.find((el) => el.type === 'post_training_month').note
              ? year.notes
                  .find((el) => el.type === 'post_training_month')
                  .note.toFixed(2)
              : 'N/A'
        },
        {
          title: t('quality.columns.total'),
          key: 'total',
          render: (year) =>
            year.notes.find((el) => el.type === 'global').note
              ? year.notes.find((el) => el.type === 'global').note.toFixed(2)
              : 'N/A'
        }
      ])
];
export default columns;
