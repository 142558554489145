import React from 'react';
import PropTypes from 'prop-types';
import { Button, Menu } from 'antd';

const MenuDropDownCGV = ({ cgvTemplates, downloadCGVFile, t }) => {
  const menu = (
    <Menu>
      {cgvTemplates.map((temp) => (
        <>
          <Menu.Item key="1">
            <Button
              type="link"
              style={{ width: '100%', textAlign: 'left' }}
              onClick={() =>
                downloadCGVFile(
                  'docx',
                  temp._id,
                  temp.metadata.originalName.split('.')[0]
                )
              }
            >
              {`${t('sessions.conventions.button.download')} ${
                temp.metadata.originalName.split('.')[0]
              } docx`}
            </Button>
          </Menu.Item>
          <Menu.Item key="2">
            <Button
              type="link"
              style={{ width: '100%', textAlign: 'left' }}
              onClick={() =>
                downloadCGVFile(
                  'pdf',
                  temp._id,
                  temp.metadata.originalName.split('.')[0]
                )
              }
            >
              {`${t('sessions.conventions.button.download')} ${
                temp.metadata.originalName.split('.')[0]
              } pdf`}
            </Button>
          </Menu.Item>
        </>
      ))}
    </Menu>
  );
  return menu;
};

MenuDropDownCGV.propTypes = {
  cgvTemplates: PropTypes.arrayOf({}).isRequired,
  downloadCGVFile: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

export default MenuDropDownCGV;
