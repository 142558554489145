import React from 'react';
import { useTranslation } from 'react-i18next';
import { List } from 'antd';

const useListContent = ({
  gender,
  first_name,
  last_name,
  status,
  email,
  phone_number,
  number,
  street,
  additional,
  postal_code,
  city,
  rate,
  duration,
  tva,
  skills,
  notes,
  skills_notes,
  activity_declaration_number
}) => {
  const { t } = useTranslation();

  return [
    {
      label: 'contributors.form.gender',
      content: t(`contributors.form.${gender}`) || ''
    },
    {
      label: 'contributors.form.full_name',
      content: `${last_name} ${first_name}`
    },
    {
      label: 'contributors.form.email',
      content: email
    },
    {
      label: 'contributors.form.address',
      content: `${number || ''} ${street || ''} ${additional || ''}` || ''
    },
    {
      label: 'contributors.form.postcode',
      content: postal_code
    },
    {
      label: 'contributors.form.city',
      content: city
    },
    {
      label: 'contributors.form.status',
      content: status ? t(`contributors.form.${status}`) : '-'
    },
    {
      label: 'contributors.form.phone_number_full',
      span: 2,
      content:
        phone_number && phone_number.number ? (
          <div>
            {`${phone_number.country_code} ${phone_number.number.slice(
              0,
              1
            )} ${phone_number.number.slice(1).replace(/(.{2})/g, '$1 ')}
                    `}
          </div>
        ) : (
          '-'
        )
    },
    {
      label: 'contributors.form.rate',
      content: `${rate || 0} € ${
        duration ? t(`contributors.form.${duration}`).toLowerCase() : ''
      } `
    },
    {
      label: 'contributors.form.tva',
      content: `${tva || 0} %`
    },
    {
      label: 'contributors.form.manage_skills',
      content: skills && (
        <List
          dataSource={skills}
          renderItem={(item) => <List.Item>{`- ${item.name}`}</List.Item>}
        />
      )
    },
    {
      label: 'contributors.form.skills_notes',
      content: `${skills_notes || '-'}`
    },
    {
      label: 'contributors.form.notes',
      content: ` ${notes || '-'}`
    },
    {
      label: 'contributors.form.activity_declaration_number',
      content: `${activity_declaration_number || '-'}`
    }
  ];
};

export default useListContent;
