import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { AccountingContextProvider } from '../AccountingContext';
import ShowInvoice from './ShowInvoice';
import ListInvoices from './ListInvoices';
import CreateCreditNote from '../CreditNotes/CreateCreditNote';

const InvoiceRouter = () => {
  const { path } = useRouteMatch();

  return (
    <AccountingContextProvider collection="invoices">
      <Switch>
        <Route
          path={`${path}/show/:invoiceId/credit-note/:sessionId/:customerId/:ref`}
          render={() => <CreateCreditNote />}
        />
        <Route
          path={`${path}/show/:id`}
          render={() => <ShowInvoice resource="invoices" />}
        />
        <Route path={path} component={ListInvoices} />
      </Switch>
    </AccountingContextProvider>
  );
};

export default InvoiceRouter;
