import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { message, Tooltip, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { LeftOutlined, RightOutlined } from '@ant-design/icons/lib';
import useAuthContext from '../../../contexts/AuthContext';

const NextPrevButtons = ({ resource, emissionDate }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();

  const prev = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/${resource}?emission_date>${emissionDate}&sort=emission_date&limit=1&populate=customer,supplier`
      });
      if (data[0]) {
        const { _id } = data[0];
        history.push(`/${resource}/show/${_id}`);
      } else {
        message.error(t(`${resource}.show.noPrev`));
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  const next = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/${resource}?emission_date<${emissionDate}&sort=-emission_date&limit=1&populate=customer,supplier`
      });
      if (data[0]) {
        const { _id } = data[0];
        history.push(`/${resource}/show/${_id}`);
      } else {
        message.error(t(`${resource}.show.noNext`));
      }
    } catch (e) {
      message(e.response.status);
    }
  };

  return (
    <div style={{ float: 'right' }}>
      <Tooltip title={t(`${resource}.show.toolTip.prev`)} placement="topRight">
        <Button
          shape="circle"
          icon={<LeftOutlined />}
          onClick={prev}
          style={{ marginRight: 5, background: 'var(--contrastBackground)' }}
        />
      </Tooltip>
      <Tooltip title={t(`${resource}.show.toolTip.next`)} placement="topRight">
        <Button
          shape="circle"
          icon={<RightOutlined />}
          onClick={next}
          style={{ background: 'var(--contrastBackground)' }}
        />
      </Tooltip>
    </div>
  );
};

NextPrevButtons.propTypes = {
  resource: PropTypes.string.isRequired,
  emissionDate: PropTypes.string.isRequired
};

export default NextPrevButtons;
