import moment from 'moment';

const getNbDaysFromSlotsModule = slots => {
  return slots && slots.date.filter(el => el.sub_slots.length).length;
};

const getNbHoursFomSlotsModule = slots => {
  let nbHour = 0;
  slots &&
    slots.date.forEach(date => {
      date.sub_slots
        .filter(el => el)
        .forEach(subSlot => {
          const endTime = moment(subSlot.end_time, 'HH:mm:ss');
          const startTime = moment(subSlot.start_time, 'HH:mm:ss');
          nbHour += (endTime - startTime) / 3600000;
        });
    });
  return nbHour;
};

const QuantityRateItem = (module, customers, rate) => {
  let quantity;
  const nbDays = getNbDaysFromSlotsModule(module.slots);
  const nbHours = getNbHoursFomSlotsModule(module.slots);
  let nbTrainees = 1;
  const nbCustomers = 1;

  quantity = 0;
  if (customers.ref === 'Company') nbTrainees = customers.trainees_list.length;

  switch (rate.customer_type) {
    case 'TRAINEE_DAY':
      quantity = nbTrainees * nbDays;
      break;
    case 'TRAINEE_HOUR':
      quantity = nbTrainees * nbHours;
      break;
    case 'TRAINEE':
      quantity = nbTrainees;
      break;
    case 'CUSTOMER':
      quantity = nbCustomers;
      break;
    case 'DAY':
      quantity = nbCustomers * nbDays;
      break;
    case 'HOUR':
      quantity = nbCustomers * nbHours;
      break;
    default:
      break;
  }

  return parseInt(quantity, 10);
};

export default QuantityRateItem;
