import React from 'react';
import { useTranslation } from 'react-i18next';

const Columns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('sessions.form.customer_name'),
      dataIndex: ['customer'],
      render: customer =>
        (customer && customer.name) ||
        (customer && customer.first_name && customer.last_name),
      sorter: true,
    },
    {
      title: t('sessions.form.nb_trainees'),
      dataIndex: ['nb_trainees'],
      sorter: true,
    },
  ];
};

export default Columns;
