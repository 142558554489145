import React from 'react';
import CreateTemplate from '../Templates/CreateTemplate';

const CreateFile = () => (
  <CreateTemplate
    typeSelector
    callback={(history) => history.push('/documents/ged')}
  />
);

export default CreateFile;
