import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  message,
  PageHeader,
  Radio,
  Select,
  Space,
} from 'antd';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useAuthContext from '../../../contexts/AuthContext';
import {
  formItemLayout,
  noLabelLayout,
} from '../../../utils/constants/formLayout';
import ErrorStatusCode from '../../../utils/ErrorStatusCode';

const RatesCustomerList = ({ session, purpose, listRates, setListRates }) => {
  const { Option } = Select;
  const { t } = useTranslation();
  const { id } = useParams();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const { dispatchAPI } = useAuthContext();
  const [enums, setEnums] = useState([]);
  const [form] = Form.useForm();
  const [spec, setSpec] = useState([]);
  const { notification } = ErrorStatusCode();

  useEffect(() => {
    if (purpose === 'edit') {
      session.customers &&
        session.customers.forEach(customer => {
          if (customer.customer === id) {
            setSpec(customer.spec_rate);
            handleChange(null, { spec_rate: customer.spec_rate });
            form.setFieldsValue(customer);
          }
        });
    }
  }, [purpose, session]);

  useEffect(() => {
    if (purpose === 'edit') {
    }
  });
  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/module/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getEnums();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  const handleChange = (_, values) => {
    setListRates(values);
  };

  return (
    <>
      <Divider orientation="left">
        <span>{t(`Tarif spécial`)}</span>
      </Divider>
      <Form.List name={['spec_rate']}>
        {(fields, { add, remove }) => (
          <>
            {(fields.length
              ? fields
              : spec.map((el, index) => {
                  return {
                    fieldKey: index,
                    isListField: true,
                    key: index,
                    name: index,
                    ...el,
                  };
                })
            ).map(field => (
              <div
                style={{
                  background: 'var(--contrastBackground)',
                  padding: 16,
                  borderRadius: 4,
                  marginBottom: 20,
                }}>
                <CloseOutlined onClick={() => remove(field.name)} />
                <Form.Item
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  label={t('sessions.modules.rate_type')}
                  {...field}
                  name={[field.name, 'rate_type']}
                  fieldKey={[field.fieldKey, 'rate_type']}>
                  <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    loading={isFieldsLoading}
                    placeholder="Type du tarif">
                    {(enums.rate_type || []).map(title => (
                      <Option key={title} value={title}>
                        {t(`sessions.form.${title}`)}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  label={t('sessions.modules.customer_type')}
                  {...field}
                  name={[field.name, 'customer_type']}
                  fieldKey={[field.fieldKey, 'customer_type']}>
                  <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    loading={isFieldsLoading}
                    placeholder="Prix par">
                    {(enums.customer_type || []).map(title => (
                      <Option key={title} value={title}>
                        {t(`sessions.form.${title}`)}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  label="Paiement mensuel"
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                  {...field}
                  name={[field.name, 'monthly_payment']}
                  fieldKey={[field.fieldKey, 'monthly_payment']}>
                  <Checkbox />
                </Form.Item>
                <Form.Item
                  {...field}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  label={t('sessions.modules.name')}
                  name={[field.name, 'name']}
                  fieldKey={[field.fieldKey, 'name']}>
                  <Input placeholder="Formation" />
                </Form.Item>
                <Form.Item
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  label={t('sessions.modules.rate')}
                  {...field}
                  name={[field.name, 'rate']}
                  fieldKey={[field.fieldKey, 'rate']}>
                  <Input type="number" suffix="€" placeholder="Tarif" />
                </Form.Item>
                <Form.Item
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  label="TVA"
                  {...field}
                  name={[field.name, 'tva']}
                  fieldKey={[field.fieldKey, 'tva']}>
                  <Input type="number" suffix="%" placeholder="TVA" />
                </Form.Item>
              </div>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}>
                Ajouter un tarif spécial
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </>
  );
};

export default RatesCustomerList;
