import React, { useEffect, useState } from 'react';
import {
  DatePicker,
  Modal,
  Button,
  Col,
  Row,
  Select,
  message,
  PageHeader,
  Space,
  Checkbox,
  Popconfirm
} from 'antd';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import {
  CheckOutlined,
  DeleteOutlined,
  WarningOutlined
} from '@ant-design/icons';
import useAuthContext from '../../../contexts/AuthContext';
import SlotsFormList from './SlotsFormList';
import useHandleResize from '../../../utils/HandleResize';
import ErrorStatusCode from '../../../utils/ErrorStatusCode';

const moment = extendMoment(Moment);
const { Option } = Select;

const Modale = ({
  showModale,
  setShowModale,
  idModule,
  module,
  dateStart,
  setStartDate,
  reloadCalendar,
  setReloadCalendar,
  dateEnd,
  setEndDate,
  editSlots,
  sessions,
  editMode,
  setEditMode,
  updateSession,
  idKanbanCol
}) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [slots, setSlots] = useState([]);
  const [startMorning, setStartMorning] = useState('09:00');
  const [subSlot, setSubSlot] = useState(null);
  const [addSubSlot, setAddSubSlot] = useState('');
  const [endMorning, setEndMorning] = useState('12:00');
  const [startAfternoon, setStartAfternoon] = useState('14:00');
  const [endAfternoon, setEndAfternoon] = useState('17:00');
  const [fields, setFields] = useState([]);
  const [sessionValue, setSessionValue] = useState('');
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const range = moment().range(dateStart, dateEnd);
  const { pathname } = useLocation();
  const urlSplit = pathname.split('/');
  const loc = urlSplit[1];
  const { width } = useHandleResize();
  const { notification } = ErrorStatusCode();

  let listDates = [];
  const newlist = [];

  useEffect(() => {
    if (
      loc !== 'agenda' &&
      Object.keys(module).length &&
      dateStart !== null &&
      dateEnd !== null
    ) {
      const list = Array.from(range.by('days', { step: 1 }));
      const lt = [];

      listDates = list
        .filter((date) => {
          const d = date.format('dddd').toLowerCase();
          return (
            d !== 'samedi' &&
            d !== 'dimanche' &&
            d !== 'saturday' &&
            d !== 'sunday'
          );
        })
        .map(({ _d }) => ({ date: _d }));
      listDates.forEach((el, index) => {
        const dt = module.slots.date.find(
          (date) =>
            moment(date.date).format('YYYY-MM-DD') ===
            moment(el.date).format('YYYY-MM-DD')
        );
        if (dt) {
          if (dt.sub_slots.length === 2) {
            setAddSubSlot('day');
          } else {
            dt.sub_slots.map((sb) => {
              if (sb.type === 'Matin') {
                setAddSubSlot('après midi');
              } else {
                setAddSubSlot('matin');
              }
            });
          }
          lt.push({
            date: dt.date,
            sub_slots: dt.sub_slots.map((sb) => {
              return {
                ...sb,
                key: `${sb.type.toLowerCase()}${index}`
              };
            })
          });
        } else {
          lt.push({
            date: el.date,
            sub_slots: []
          });
        }
      });
      setSlots(lt);
    }
  }, [module, dateStart, dateEnd]);

  const onShowWeekend = (value) => {
    addDatesWithSubSLots(subSlot, value);
  };

  useEffect(() => {
    if (editSlots && editSlots.length) {
      editSlots.map((el) => {
        if (el.type === 'Matin') {
          setStartMorning(el.start_time);
          setEndMorning(el.end_time);
        } else if (el.type === 'Après midi') {
          setStartAfternoon(el.start_time);
          setEndAfternoon(el.end_time);
        }
      });
    }
  }, [editSlots]);

  const dates = [
    <div>
      Du
      <DatePicker
        onChange={(value) => setStartDate(value)}
        defaultValue={moment(dateStart)}
        format="DD/MM/YYYY"
      />
      Au
      <DatePicker
        onChange={(value) => setEndDate(value)}
        defaultValue={moment(dateEnd)}
        format="DD/MM/YYYY"
      />
    </div>
  ];

  useEffect(() => {
    if (loc === 'agenda') {
      if (sessions.length) {
        setIsFieldsLoading(false);
      }
    }
  }, [sessions]);

  const updateModule = async (body) => {
    try {
      await dispatchAPI('PATCH', { url: `/module/${idModule}`, body });
    } catch (e) {
      if (e.response) {
        notification(e.response);
      }
    }
  };

  const createModule = async (body) => {
    try {
      await dispatchAPI('POST', { url: `/module`, body });
      message.success(t('Session planifier avec succès'));
    } catch (e) {
      if (e.response) {
        notification(e.response);
      }
    }
  };

  const addDatesWithSubSLots = (type, showWeekend) => {
    setSubSlot(type);
    const list = Array.from(range.by('days', { step: 1 }));
    listDates = list
      .filter((date) => {
        const d = date.format('dddd').toLowerCase();
        return showWeekend
          ? date
          : d !== 'samedi' &&
              d !== 'dimanche' &&
              d !== 'saturday' &&
              d !== 'sunday';
      })
      .map(({ _d }) => ({ date: _d }));
    listDates.forEach((el, index) => {
      const slot = slots.find(
        (date) =>
          moment(date.date).format('DD-MM-YYYY') ===
          moment(el.date).format('DD-MM-YYYY')
      );

      if (type === 'morning') {
        newlist.push({
          date: el.date,
          sub_slots: [
            {
              key: `matin${index}`,
              type: 'Matin',
              start_time: startMorning,
              end_time: endMorning
            }
          ]
        });
      } else if (type === 'afternoon') {
        newlist.push({
          date: el.date,
          sub_slots: [
            {
              key: `soir${index}`,
              type: 'Après midi',
              start_time: startAfternoon,
              end_time: endAfternoon
            }
          ]
        });
      } else if (type === 'both') {
        if (!slot?.sub_slots.length) {
          newlist.push({
            date: el.date,
            sub_slots: [
              {
                key: `matin${index}`,
                type: 'Matin',
                start_time: startMorning,
                end_time: endMorning
              },
              {
                key: `soir${index}`,
                type: 'Après midi',
                start_time: startAfternoon,
                end_time: endAfternoon
              }
            ]
          });
        } else {
          newlist.push(slot);
        }
      }
    });
    setSlots(newlist);
  };

  const addSlotsToModule = async () => {
    let date = module.slots.date;
    if (!slots.length) {
      const list = Array.from(range.by('days', { step: 1 }));
      list.forEach((i) => {
        date = date.filter(
          (dt) =>
            moment(dt.date).format('DD-MM-YYYY') !== i.format('DD-MM-YYYY')
        );
      });
    } else {
      date = date.filter((dt) => {
        slots.forEach((slot) => {
          if (slot.date === dt.date) {
            return false;
          }
        });
        return true;
      });
    }
    const body = {
      slots: {
        date: [
          ...date.map((d) => ({
            ...d,
            date: moment(d.date)
              .local()
              .format('YYYY-MM-DD HH:mm:ss')
          })),
          ...slots
            .filter((el) => el.sub_slots.length)
            .map((d) => ({
              ...d,
              date: moment(d.date)
                .local()
                .format('YYYY-MM-DD HH:mm:ss')
            }))
        ]
      }
    };

    await updateModule(body);
  };

  const addModuleSession = async () => {
    const body = {
      session: sessionValue,
      slots: {
        start_date: moment(dateStart),
        end_date: moment(dateEnd),
        date: slots
      }
    };
    const sessionBody = {
      status: idKanbanCol
    };
    await createModule(body);
    await updateSession(sessionValue, sessionBody);
  };

  const handleSubmit = async () => {
    if (loc === 'agenda') {
      await addModuleSession();
      setEditMode(!editMode);
    } else {
      setStartDate(null);
      setEndDate(null);
      await addSlotsToModule();
    }
    setSubSlot(null);
    setAddSubSlot('');
    setReloadCalendar(!reloadCalendar);
    setShowModale(!showModale);
  };

  const deleteSubSlot = (indexSlot, indexSub) => {
    setSlots(
      slots.map((slot, index) => {
        if (index === indexSlot) {
          slots[index].sub_slots = slots[index].sub_slots.filter(
            (el, idx) => idx !== indexSub
          );
        }
        return slots[index];
      })
    );
  };

  const onSelect = (value) => {
    setSessionValue(value);
  };

  const handleCancel = () => {
    setStartDate(null);
    setEndDate(null);
    setShowModale(false);
    setAddSubSlot('');
    setSubSlot(null);
  };

  const deleteSlots = () => {
    setSlots([]);
    setAddSubSlot('');
    setSubSlot(null);
  };
  return (
    <Modal
      className="session-planning-modal"
      title={dates}
      visible={showModale}
      width={width < 1500 ? '85%' : '65%'}
      onCancel={handleCancel}
      destroyOnClose
      footer={null}
    >
      {loc === 'agenda' ? (
        <>
          <Row>
            <Col xl={6} sm={24}>
              <PageHeader
                title="Sélectionner une session"
                style={{ padding: 0, marginBottom: 10 }}
              />
              <Select
                onSelect={onSelect}
                style={{ width: 220 }}
                loading={isFieldsLoading}
              >
                {sessions.map((session) => (
                  <Option value={session._id} key={session._id}>
                    {session.name}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col xl={18} sm={24}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: '10px 0'
                }}
              >
                <Space>
                  <Button
                    onClick={() => addDatesWithSubSLots('morning')}
                    type="primary"
                    style={{ width: '8%', minWidth: 100 }}
                  >
                    Matin
                  </Button>
                  <Button
                    onClick={() => addDatesWithSubSLots('both')}
                    type="primary"
                    style={{
                      width: '15%',
                      minWidth: 180
                    }}
                  >
                    Matin et après-midi
                  </Button>
                  <Button
                    onClick={() => addDatesWithSubSLots('afternoon')}
                    type="primary"
                    style={{ width: '8%', minWidth: 100 }}
                  >
                    Après-midi
                  </Button>
                </Space>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <Button
                  type="add"
                  style={{ float: 'left' }}
                  onClick={handleSubmit}
                >
                  <CheckOutlined />
                  Enregistrer les dates
                </Button>
              </div>
              {subSlot ? (
                <SlotsFormList
                  setFields={setFields}
                  fields={fields}
                  slots={slots}
                  setSlots={setSlots}
                  newlist={newlist}
                  deleteSubSlot={deleteSubSlot}
                />
              ) : null}
            </Col>
          </Row>
        </>
      ) : (
        <>
          <Row align="middle" justify="center">
            <Col>
              <Checkbox
                onChange={(event) => onShowWeekend(event.target.checked)}
              />
              <span>{t('agenda.show_weekend_days')}</span>
            </Col>
            {!!slots.length && (
              <Col>
                <Popconfirm
                  title={t(
                    'Êtes-vous sûr(e) de vouloir supprimer ces éléments ?'
                  )}
                  okText={t('datatable.column.action.delete.ok')}
                  okButtonProps={{ type: 'danger' }}
                  cancelText={t('datatable.column.action.delete.cancel')}
                  onConfirm={deleteSlots}
                  icon={<WarningOutlined />}
                >
                  <Button
                    style={{ float: 'right', marginLeft: 10 }}
                    danger
                    type="link"
                  >
                    Supprimer tout
                    <DeleteOutlined style={{ color: 'red' }} type="delete" />
                  </Button>
                </Popconfirm>
              </Col>
            )}
          </Row>
          {(addSubSlot !== 'day' ||
            slots.map((el) => el.sub_slots.length).includes(0)) && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Button
                onClick={() => addDatesWithSubSLots('morning')}
                type="primary"
                style={{ width: '8%', minWidth: 100 }}
              >
                Matin
              </Button>
              <Button
                onClick={() => addDatesWithSubSLots('both')}
                type="primary"
                style={{
                  marginLeft: '10%',
                  marginRight: '10%',
                  width: '15%',
                  minWidth: 180
                }}
              >
                Matin et après-midi
              </Button>
              <Button
                onClick={() => addDatesWithSubSLots('afternoon')}
                type="primary"
                style={{ width: '8%', minWidth: 100 }}
              >
                Après-midi
              </Button>
            </div>
          )}
          <div
            style={{
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <Button
              type="add"
              style={{ width: '15%', minWidth: 180 }}
              onClick={handleSubmit}
            >
              <CheckOutlined />
              Enregistrer les dates
            </Button>
          </div>
          {subSlot ? (
            <SlotsFormList
              slots={slots}
              dates={module.slots.date}
              setSlots={setSlots}
              deleteSubSlot={deleteSubSlot}
            />
          ) : null}
        </>
      )}
    </Modal>
  );
};

Modale.propTypes = {
  showModale: PropTypes.bool,
  setShowModale: PropTypes.func,
  idModule: PropTypes.string,
  module: PropTypes.shape({
    slots: PropTypes.shape({
      date: PropTypes.array
    })
  }),
  dateStart: PropTypes.instanceOf(Date),
  setStartDate: PropTypes.func,
  dateEnd: PropTypes.instanceOf(Date),
  setEndDate: PropTypes.func,
  reloadCalendar: PropTypes.bool,
  setReloadCalendar: PropTypes.func,
  editSlots: PropTypes.arrayOf(PropTypes.object),
  sessions: PropTypes.arrayOf(PropTypes.object),
  editMode: PropTypes.bool,
  setEditMode: PropTypes.func,
  updateSession: PropTypes.func,
  idKanbanCol: PropTypes.string
};

Modale.defaultProps = {
  showModale: false,
  setShowModale: () => {},
  idModule: '',
  module: {
    slots: {
      date: []
    }
  },
  dateStart: null,
  setStartDate: () => {},
  dateEnd: null,
  setEndDate: () => {},
  reloadCalendar: false,
  setReloadCalendar: () => {},
  editSlots: [],
  sessions: [],
  editMode: false,
  setEditMode: () => {},
  updateSession: () => {},
  idKanbanCol: ''
};

export default Modale;
