export const headers = [
  {
    label: 'name',
    key: 'organization.name'
  },
  {
    label: 'current_subscription',
    key: 'current_subscription'
  },
  {
    label: 'subscription_status',
    key: 'subscription_status'
  },
  {
    label: 'subscription_creation_date',
    key: 'subscription_creation_date'
  },
  {
    label: 'subscription_termination_date',
    key: 'subscription_termination_date'
  }
];
