import React from 'react';
import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';

const colors = (item) => {
  switch (item.status) {
    case 'OPEN':
      return 'success';
    case 'CLOSED':
      return 'green';
    default:
      return 'var(--primaryColor)';
  }
};

const useColumns = ({ enums }) => {
  const { sources, subject, gravity } = enums;
  const { t } = useTranslation();

  const setFilter = (item) => {
    switch (item) {
      case 'source':
        return sources.map((element) => ({
          text: t(`dashboards.filter.${element}`),
          value: element
        }));
      case 'subject':
        return subject.map((element) => ({
          text: t(`dashboards.filter.${element}`),
          value: element
        }));
      case 'gravity':
        return gravity.map((element) => ({
          text: t(`dashboards.filter.${element}`),
          value: element
        }));
      default:
        return null;
    }
  };

  const columns = [
    {
      title: t('dashboards.quality_incidents.name'),
      dataIndex: 'name',
      sorter: true
    },
    {
      title: t('dashboards.quality_incidents.status'),
      toRequest: 'status',
      filters: [
        { text: t('dashboards.filter.closed'), value: 'CLOSED' },
        { text: t('dashboards.filter.to_do'), value: 'OPEN' }
      ],
      onFilter: (value, record) => record.status === value,
      render: (item) => (
        <Tag style={{ fontSize: 15 }} color={colors(item)}>
          {t(`quality_incidents.form.${item.status}`)}
        </Tag>
      ),
      sorter: true
    },
    {
      title: t('dashboards.quality_incidents.source'),
      toRequest: 'incident_source',
      filters: sources && setFilter('source'),
      onFilter: (value, record) => record.incident_source === value,
      render: ({ incident_source }) =>
        t(`quality_incidents.form.${incident_source}`),
      sorter: true
    },
    {
      title: t('dashboards.quality_incidents.subject'),
      toRequest: 'incident_subject',
      filters: subject && setFilter('subject'),
      onFilter: (value, record) => record.incident_subject === value,
      render: ({ incident_subject }) =>
        t(`quality_incidents.form.${incident_subject}`),
      sorter: true
    },
    {
      title: t('dashboards.quality_incidents.severity'),
      toRequest: 'incident_gravity',
      filters: gravity && setFilter('gravity'),
      onFilter: (value, record) => record.incident_gravity === value,
      render: ({ incident_gravity }) =>
        t(`quality_incidents.form.${incident_gravity}`),
      sorter: true
    }
  ];

  const headers = [
    {
      label: 'name',
      key: 'name'
    },
    {
      label: 'description',
      key: 'description'
    },
    {
      label: 'incident_date',
      key: 'incident_date'
    },
    {
      label: 'incident_source',
      key: 'incident_source'
    },
    {
      label: 'incident_subject',
      key: 'incident_subject'
    },
    {
      label: 'incident_gravity',
      key: 'incident_gravity'
    },
    {
      label: 'organization',
      key: 'organization.name',
      model_key: 'organization'
    }
  ];

  return { columns, headers };
};

export default useColumns;
