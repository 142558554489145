import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Form, Row, Spin, Switch, message } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';
import ContentCustom from '../ContentCustom/ContentCustom';
import PageHeaderCustom from '../PageHeader/PageHeader';
import useGenerateFormItem from '../../utils/GenerateFormItem';
import ErrorStatusCode from '../../utils/ErrorStatusCode';
import {
  formItemLayout,
  tailFormItemLayout
} from '../../utils/constants/formLayout';
import DocumentScan from '../../routes/Documents/Scan/DocumentScan';
import { ScanContextProvider } from '../../routes/Documents/Scan/ScanContext';

const modes = {
  FORM: 'FORM',
  SCAN: 'SCAN'
};

const CreateUpdateContainer = ({
  setForm,
  purpose,
  fields,
  loadingFields,
  resource,
  baseUrl,
  config,
  formExtra,
  tradKey,
  withSubRoutes,
  submitLabel,
  customSubmit,
  isParentLoading,
  urlGoBack,
  populate,
  withConfirmationButton
}) => {
  const history = useHistory();
  const { id } = useParams();
  const { t } = useTranslation();
  // const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [mode, setMode] = useState(modes.FORM);
  const generateFields = useGenerateFormItem();
  const [form] = Form.useForm();
  const { onGetResource, onCreateResource, onUpdateResource } = config;
  const { notification } = ErrorStatusCode();
  useEffect(() => {
    if (setForm) {
      setForm(form);
    }
  }, []);

  const updateResource = async (body) => {
    try {
      await dispatchAPI('PATCH', {
        url: `${baseUrl}/${id}`,
        body:
          onUpdateResource && onUpdateResource.setBody
            ? onUpdateResource.setBody(body)
            : body
      });
      if (urlGoBack !== undefined && urlGoBack !== null) {
        history.push(urlGoBack);
      } else {
        history.goBack();
      }
    } catch (e) {
      if (e.response) {
        ErrorStatusCode(e.response);
      }
    }
  };

  const createResource = async (body) => {
    try {
      const response = await dispatchAPI('POST', {
        url: `${baseUrl}`,
        body:
          onCreateResource && onCreateResource.setBody
            ? onCreateResource.setBody(body)
            : body
      });
      if (urlGoBack !== undefined && urlGoBack !== null) {
        history.push(`${urlGoBack}/${response.data._id}`);
      } else {
        history.goBack();
      }
    } catch (e) {
      if (e.response) {
        notification(e.response);
      }
    }
  };

  const getResource = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `${baseUrl}/${id}${populate ? `?populate=${populate}` : ''}`
      });
      form.setFieldsValue(
        onGetResource && onGetResource.setFields
          ? onGetResource.setFields(data)
          : data
      );
    } catch (e) {
      if (e.response) {
        notification(e.response);
      }
    }
    setIsLoading(false);
  }, [purpose, id, loadingFields]);

  useEffect(() => {
    if (purpose === 'edit' && id) {
      setIsLoading(true);
      if (!loadingFields)
        (async () => {
          await getResource();
        })();
    }
  }, [getResource]);

  const handleSubmit = async (values) => {
    if (customSubmit) return customSubmit(values);
    if (purpose === 'edit') await updateResource(values);
    if (purpose === 'create') await createResource(values);
  };

  return (
    <div style={{ overflow: 'hidden', height: 'calc(100vh - 102px)' }}>
      <PageHeaderCustom
        title={t(`${resource}.form.title.${purpose}`)}
        withSubRoutes={withSubRoutes}
      />
      <ContentCustom style={{ overflow: 'auto', height: 'calc(100% - 72px)' }}>
        <Spin spinning={isLoading || isParentLoading}>
          {mode === modes.SCAN && (
            <ScanContextProvider>
              <DocumentScan
                resource={resource === 'customers' ? 'companies' : resource}
              />
            </ScanContextProvider>
          )}
          {mode === modes.FORM && (
            <Form
              /* eslint-disable-next-line react/jsx-props-no-spreading */
              {...formItemLayout}
              onFinish={handleSubmit}
              form={form}
            >
              {fields.map((field) =>
                generateFields(tradKey || resource, field)
              )}
              {formExtra}
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <Form.Item {...tailFormItemLayout} className="submit-form-item">
                <Row justify="end">
                  <Button
                    style={{ margin: '16px 10px 0 10px' }}
                    type="link"
                    danger
                    onClick={() => {
                      history.goBack();
                    }}
                  >
                    {`${t('buttons.cancel')} `}
                    <CloseOutlined />
                  </Button>
                  {withConfirmationButton && (
                    <Button
                      type="add"
                      htmlType="submit"
                      style={{ marginTop: 16 }}
                    >
                      {`${t(submitLabel || 'buttons.save')} `}
                      <CheckOutlined />
                    </Button>
                  )}
                </Row>
              </Form.Item>
            </Form>
          )}
        </Spin>
      </ContentCustom>
    </div>
  );
};

CreateUpdateContainer.propTypes = {
  purpose: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  baseUrl: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired,
  loadingFields: PropTypes.bool,
  config: PropTypes.shape({
    onGetResource: PropTypes.shape({
      setFields: PropTypes.func
    }),
    onCreateResource: PropTypes.shape({
      setBody: PropTypes.func
    }),
    onUpdateResource: PropTypes.shape({
      setBody: PropTypes.func
    })
  }),
  formExtra: PropTypes.element,
  tradKey: PropTypes.string,
  withSubRoutes: PropTypes.bool,
  submitLabel: PropTypes.string,
  customSubmit: PropTypes.func,
  isParentLoading: PropTypes.bool,
  urlGoBack: PropTypes.string,
  withConfirmationButton: PropTypes.bool
};

CreateUpdateContainer.defaultProps = {
  config: {},
  urlGoBack: null,
  loadingFields: false,
  formExtra: null,
  tradKey: null,
  withSubRoutes: false,
  submitLabel: null,
  customSubmit: null,
  isParentLoading: false,
  withConfirmationButton: true
};

export default CreateUpdateContainer;
