import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Input, Table, TimePicker } from 'antd';
import moment from 'moment';
import { DeleteOutlined, WarningOutlined } from '@ant-design/icons';
import { red } from '@ant-design/colors';
import { useTranslation } from 'react-i18next';

const { RangePicker } = TimePicker;

const StyledTable = styled.table`
  tr:last-child > td {
    border-bottom: 0;
  }
`;

const range = (start, end) => {
  const result = [];
  for (let i = start; i < end; i += 1) {
    result.push(i);
  }
  return result;
};

const TasksTable = ({ tasks, delTask, updateTask, inOutTime, errors }) => {
  const { t } = useTranslation();
  const disableHours = () => {
    let disabledHours = [];
    if (inOutTime[0])
      disabledHours = [
        ...disabledHours,
        ...range(0, 24).splice(0, inOutTime[0].hours()),
      ];
    if (
      tasks[tasks.length - 2] &&
      tasks[tasks.length - 2].time &&
      tasks[tasks.length - 2].time.length
    ) {
      disabledHours = [
        ...disabledHours,
        ...range(0, 24).splice(0, tasks[tasks.length - 2].time[1].hours()),
      ];
    }
    if (inOutTime[1])
      disabledHours = [
        ...disabledHours,
        ...range(0, 24).splice(inOutTime[1].hours() + 1),
      ];
    return disabledHours;
  };

  return (
    <StyledTable
      as={Table}
      dataSource={tasks}
      columns={[
        ...(errors.length
          ? [
              {
                key: 'error',
                render: ({ key }) =>
                  errors.includes(key) && (
                    <WarningOutlined style={{ color: red.primary }} />
                  ),
              },
            ]
          : []),
        {
          title: t('times-and-attendances.show.tasks.name'),
          key: 'name',
          // eslint-disable-next-line react/prop-types
          render: ({ name, key }) => (
            <Input
              disabled={!inOutTime.length}
              placeholder="Nom de la tâche"
              value={name}
              onChange={e => updateTask(e.target.value, key, 'name')}
              style={{ width: 200 }}
            />
          ),
        },
        {
          title: t('times-and-attendances.show.tasks.time'),
          key: 'time',
          // eslint-disable-next-line react/prop-types
          render: ({ key, time }) => (
            <RangePicker
              disabled={!inOutTime.length}
              value={time}
              minuteStep={15}
              format="HH:mm"
              onChange={value => updateTask(value, key, 'time')}
              showTime={{ hideDisabledOptions: true }}
              disabledHours={disableHours}
            />
          ),
        },
        {
          title: t('times-and-attendances.show.tasks.duration'),
          key: 'duration',
          render: ({ time }) => {
            let duration;
            // eslint-disable-next-line react/prop-types
            if (time && time.length)
              // eslint-disable-next-line react/prop-types
              duration = moment.duration(time[1].diff(time[0]));
            // eslint-disable-next-line react/prop-types
            return time && time.length
              ? `${duration.hours()}h${
                  duration.minutes() !== 0 ? duration.minutes() : ''
                }`
              : '';
          },
        },
        {
          key: 'action',
          render: ({ key }) =>
            tasks.length > 1 && (
              <Button
                type="link"
                danger
                shape="circle"
                icon={<DeleteOutlined />}
                onClick={() => delTask(key)}
              />
            ),
        },
      ]}
      pagination={false}
    />
  );
};

TasksTable.propTypes = {
  tasks: PropTypes.arrayOf(
    PropTypes.shape({
      time: PropTypes.arrayOf(
        PropTypes.shape({
          hours: PropTypes.func,
        })
      ),
    })
  ).isRequired,
  delTask: PropTypes.func.isRequired,
  updateTask: PropTypes.func.isRequired,
  inOutTime: PropTypes.arrayOf(
    PropTypes.shape({
      hours: PropTypes.func,
    })
  ).isRequired,
  errors: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default TasksTable;
