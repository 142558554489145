import React, { useState } from 'react';
import { Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import columns from './listSubscriptions';
import { useFormatter } from '../../utils/formatter';
import SimpleResourceLandingLayout from '../../components/layouts/SimpleResourceLandingLayout';
import { headers } from './headers';

const Subscriptions = () => {
  const { formattedData } = useFormatter();
  const { t } = useTranslation();
  const [subIsActif, setSubIsActif] = useState(false);

  const actifSwitch = [
    <Switch
      checkedChildren="Actif"
      unCheckedChildren="Tout"
      onChange={() => {
        setSubIsActif(!subIsActif);
      }}
      checked={subIsActif}
      className="kanban-switch"
    />
  ];

  return (
    <SimpleResourceLandingLayout
      columns={[...columns(t)]}
      headers={headers}
      formatter={formattedData}
      extraQuery={`role=users:SUPER-USER&&subscription=${subIsActif}`}
      resourceName="users"
      tradKey="Organismes de formation"
      populate="organization.current_subscription"
      extraHeader={actifSwitch}
      path="subscriptions"
      withoutImportButton
    />
  );
};

export default Subscriptions;
