import PropTypes from 'prop-types';

const getFilesCompany = ({ setTemplatesCompany, user, notification }) => {
  try {
    const list = user.organization.templates.filter(
      (el) => el.type === 'company_convention'
    );
    setTemplatesCompany(list);
  } catch (e) {
    if (e.response) notification(e.response);
  }
};

getFilesCompany.propTypes = {
  setTemplatesPrivate: PropTypes.func.isRequired,
  user: PropTypes.arrayOf({}).isRequired,
  notification: PropTypes.func.isRequired
};

export default getFilesCompany;
