import React, { useEffect, useState } from 'react';
import {
  Chart,
  Geom,
  Axis,
  Tooltip,
  Legend,
  Coord,
  Guide,
  Label
} from 'bizcharts';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Col, Row } from 'antd';
import useAuthContext from '../../../contexts/AuthContext';

const AffiliationNumbersIndicator = ({ user }) => {
  const { dispatchAPI } = useAuthContext();
  const [data, setData] = useState([]);
  const [pieData, setPieData] = useState([]);
  const [total, setTotal] = useState(0);
  const months = moment.months();

  const getAffiliates = async () => {
    let nbLess = 0;
    let nbValidate = 0;
    const temp = [];

    await Promise.all(
      months.map(async (month) => {
        await Promise.all(
          user.sponsored.map(async (sponsoredId) => {
            const { data } = await dispatchAPI('GET', {
              url: `/users/${sponsoredId}?populate=organization`
            });

            const subscriptionStartDate =
              data.organization.subscription.created_at;

            if (
              moment(subscriptionStartDate).format('MMMM-YYYY') ===
              `${month}-${moment().format('YYYY')}`
            ) {
              if (
                moment(subscriptionStartDate)
                  .clone()
                  .add(3, 'months')
                  .isBefore(moment())
              ) {
                temp.push({
                  type: 'VALIDATE',
                  month: `${month}-${moment().format('YYYY')}`,
                  value: 1
                });
                nbValidate += 1;
              } else {
                temp.push({
                  type: 'LESS_MONTHS',
                  month: `${month}-${moment().format('YYYY')}`,
                  value: 1
                });
                nbLess += 1;
              }
            }
          })
        );
      })
    );

    setTotal(nbValidate + nbLess);
    setPieData([
      {
        type: 'LESS_MONTHS',
        value: nbLess
      },
      {
        type: 'VALIDATE',
        value: nbValidate
      }
    ]);

    const groupedData = temp.reduce((acc, obj) => {
      const key = obj.month + obj.type;
      if (acc[key]) {
        acc[key].value += obj.value;
      } else {
        acc[key] = { ...obj };
      }
      return acc;
    }, {});

    const finalData = Object.values(groupedData);

    setData(finalData);
  };

  useEffect(() => {
    getAffiliates();
  }, []);

  return (
    <Row justify="space-between">
      <Col span={15}>
        <Chart height={600} data={data} padding={[60, 60, 200, 50]} forceFit>
          <Axis name="month" />
          <Axis name="value" />
          <Legend
            position="bottom"
            itemFormatter={(type) => {
              return type === 'LESS_MONTHS' ? '> 3 mois' : '< 3 mois';
            }}
          />
          <Tooltip
            crosshairs={{
              type: 'y'
            }}
          />
          <Geom
            type="interval"
            position="month*value"
            color={['type', ['#3176A9', '#133751']]}
            tooltip={[
              'type*value',
              (type, value) => {
                return {
                  name: type === 'LESS_MONTHS' ? '> 3 mois' : '< 3 mois',
                  value
                };
              }
            ]}
            adjust={[
              {
                type: 'dodge',
                marginRatio: 1 / 32
              }
            ]}
          />
        </Chart>
      </Col>
      <Col>
        <Chart data={pieData} padding={100} forceFit>
          <Coord type="theta" radius={1} innerRadius={0.7} />
          <Legend
            position="bottom"
            itemFormatter={(type) => {
              return type === 'LESS_MONTHS' ? '> 3 mois' : '< 3 mois';
            }}
          />
          <Tooltip
            showTitle={false}
            itemTpl='<li><span style="background-color:{color};" class="g2-tooltip-marker"></span>{name}: {value}</li>'
          />
          <Guide>
            <Guide.Text
              position={['50%', '50%']}
              content={`${total}`}
              style={{
                fill: '#666',
                fontSize: '24'
              }}
            />
          </Guide>
          <Geom
            type="intervalStack"
            position="value"
            color={['type', ['#3176A9', '#133751']]}
            tooltip={[
              'type*value',
              (type, value) => {
                return {
                  name: type === 'LESS_MONTHS' ? '> 3 mois' : '< 3 mois',
                  value
                };
              }
            ]}
            style={{
              lineWidth: 1,
              stroke: '#fff'
            }}
          >
            <Label content="value" offset={-25} autoRotate={false} />
          </Geom>
        </Chart>
      </Col>
    </Row>
  );
};

AffiliationNumbersIndicator.propTypes = {
  user: PropTypes.shape({
    sponsored: PropTypes.arrayOf(PropTypes.string.isRequired)
  }).isRequired
};

export default AffiliationNumbersIndicator;
