import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Col, Collapse, Row, Spin, Tabs, Tag, Typography } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import useAuthContext from '../../../contexts/AuthContext';
import DataTable from '../../../components/DataTable/Datatable';
import AddActivityForm from './AddActivityForm';
import useContactColumns from './detailsColumns';
import SelectDealStatus from './SelectDealStatus';
import PageHeaderCustom from '../../../components/PageHeader/PageHeader';
import ContentCustom from '../../../components/ContentCustom/ContentCustom';
import ListDealActivity from './ListDealActivity';
import useErrorMessage from '../../../utils/ErrorMessage';
import { dealsPriorities } from '../../../utils/constants/tagColors';
import ErrorStatusCode from '../../../utils/ErrorStatusCode';
import { ButtonAdd } from '../../../utils/constants/customButton';
import NewContactDrawer from '../../Donnees/Contacts/NewContactDrawer';

const { TabPane } = Tabs;
const { Panel } = Collapse;

const ShowDeal = () => {
  const columns = useContactColumns();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const { id } = useParams();
  const [deal, setCurrentDeal] = useState({});
  const [formActivity, setFormActivity] = useState(false);
  const [, setListActivity] = useState(true);
  const [itemSelected, setItemSelected] = useState('activity');
  const [kanbanColumns, setKanbanCols] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [visibilityContactDrawer, setVisibilityContactDrawer] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const { message } = useErrorMessage();
  const { notification } = ErrorStatusCode();

  const menuItems = [
    { name: t('deals.tabs.activities'), key: 'activity' },
    { name: t('deals.tabs.notes'), key: 'note' },
    { name: t('deals.tabs.calls'), key: 'call' },
    { name: 'RDV', key: 'RDV' }
  ];

  const fetchData = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/deals/${id}?populate=lead,status,companies,activities.author`
      });
      setCurrentDeal(data);
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  const addActivity = async (values) => {
    await dispatchAPI('PATCH', {
      url: `/deals/${id}`,
      body: {
        activities: [...deal.activities, { ...values, _id: uuid() }]
      }
    });
    setFormActivity(false);
    setListActivity(true);
    await fetchData();
  };

  const editStatus = async (value) => {
    const newStatusId = kanbanColumns.filter((item) => item.index === value)[0]
      ._id;
    try {
      await dispatchAPI('PATCH', {
        url: `/deals/${id}`,
        body: {
          status: newStatusId
        }
      });
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    await fetchData();
  };

  const delActivity = async (values) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/deals/${id}`,
        body: {
          activities: deal.activities.filter(
            (activity) => activity._id !== values._id
          )
        }
      });
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    await fetchData();
  };

  const getEmployees = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/employees' });
      setEmployees(data);
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  const getKanbans = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/kanbans?type=CRM' });
      setKanbanCols(data);
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsLoading(true);
    await fetchData();
    await getKanbans();
    await getEmployees();
    setIsLoading(false);
  }, [refresh]);

  const drawerVisibility = () => {
    setVisibilityContactDrawer(!visibilityContactDrawer);
  };

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return (
    <>
      <PageHeaderCustom title={t('deals.show.title')} withSubRoutes />
      <Spin spinning={isLoading}>
        <ContentCustom>
          <Row style={{ marginTop: 24 }}>
            <Col span={24}>
              {deal && (
                <SelectDealStatus
                  editStatus={editStatus}
                  dealStatusIndex={deal.status?.index}
                  kanbanCols={kanbanColumns}
                />
              )}
            </Col>
          </Row>
          <Row style={{ marginTop: 48 }}>
            <Col span={13} offset={11}>
              <Tabs
                onChange={(key) => {
                  setItemSelected(key);
                }}
              >
                {menuItems.map((item) => (
                  <TabPane tab={item.name} key={item.key} />
                ))}
              </Tabs>
              <Button
                type="primary"
                style={{ width: 70, position: 'absolute', top: 10, right: 0 }}
                onClick={() => {
                  setListActivity(false);
                  setFormActivity(true);
                }}
              >
                <PlusCircleOutlined style={{ fontSize: 20 }} />
              </Button>
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <Collapse defaultActiveKey={['0']}>
                <Panel key="0" header={t('deals.form.global_information')}>
                  <Typography.Paragraph>
                    {`${t('deals.column.priority')} : `}
                    <Tag color={dealsPriorities[deal?.priority]}>
                      {deal?.priority}
                    </Tag>
                  </Typography.Paragraph>
                </Panel>
                {deal?.companies && (
                  <Panel key="1" header={t('deals.form.company_informations')}>
                    <Typography.Paragraph>
                      {`${t('deals.show.company')}:
                    ${deal && deal.companies && deal.companies.name}`}
                    </Typography.Paragraph>
                  </Panel>
                )}
                <Panel key="2" header={t('deals.form.contacts')}>
                  <NewContactDrawer
                    isVisible={visibilityContactDrawer}
                    setVisibilityContactDrawer={setVisibilityContactDrawer}
                    company={deal?.companies}
                    refresh={refresh}
                    setRefresh={setRefresh}
                  />
                  <Row style={{ marginBottom: 10, float: 'right' }}>
                    <ButtonAdd onClick={drawerVisibility} />
                  </Row>
                  <DataTable
                    resourceName="contacts"
                    columns={columns}
                    customActionColumn
                    forceRefresh={refresh}
                    extraQuery={`my_company=${deal?.companies?._id}`}
                  />
                </Panel>
              </Collapse>
            </Col>
            <Col
              span={13}
              offset={1}
              style={{ border: '2px solid var(--borderColor)' }}
            >
              {formActivity && (
                <AddActivityForm
                  addActivity={addActivity}
                  setFormActivity={setFormActivity}
                  setListActivity={setListActivity}
                  employeesList={employees}
                />
              )}
              {!formActivity && (
                <ListDealActivity
                  deal={deal}
                  selectedTab={itemSelected}
                  delActivity={delActivity}
                />
              )}
            </Col>
          </Row>
        </ContentCustom>
      </Spin>
    </>
  );
};

export default ShowDeal;
