import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Divider, Form, message, Option, Select, Spin } from 'antd';
import { CheckOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';
import moment from 'moment';
import TasksTable from './TasksTable';
import useAuthContext from '../../contexts/AuthContext';
import PageHeaderCustom from '../../components/PageHeader/PageHeader';
import DayRow from './DayRow';
import ContentCustom from '../../components/ContentCustom/ContentCustom';

const ButtonsContainer = styled.div`
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CreateUpdateTAndA = ({ purpose }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [tasks, setTasks] = useState([{ key: uuid() }]);
  const [totalDurations, setTotalDurations] = useState(moment.duration(0));
  const [totalTime, setTotalTime] = useState(moment.duration(0));
  const [day, setDay] = useState(null);
  const [inOutTime, setInOutTime] = useState([]);
  const [errors, setErrors] = useState([]);
  const [user, setUser] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const { Option } = Select;

  const createUpdateTAndA = async () => {
    try {
      await dispatchAPI(purpose === 'create' ? 'POST' : 'PATCH', {
        url: `/times-and-attendances${purpose === 'edit' ? `/${id}` : ''}`,
        body: {
          day,
          start_date: inOutTime[0],
          end_date: inOutTime[1],
          duration: inOutTime[1].diff(inOutTime[0]),
          tasks,
          user: selectedUser,
        },
      });
      history.goBack();
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
  };

  const getTAndA = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/times-and-attendances/${id}`,
      });
      setDay(moment(data.day));
      setInOutTime([moment(data.start_date), moment(data.end_date)]);
      setTasks(
        data.tasks.map(task => ({
          ...task,
          key: uuid(),
          time: [moment(task.time[0]), moment(task.time[1])],
        }))
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
    setIsLoading(false);
  }, [purpose, id]);

  const fetchEmployeeList = useCallback(async () => {
    const data = await dispatchAPI('GET', { url: `/users` });
    setUser(data.data);
  }, []);

  useEffect(() => {
    if (purpose === 'edit' && id) getTAndA();
  }, [purpose, id, getTAndA]);

  const handleSubmit = async () => {
    const tmp = [];
    tasks.forEach(task => {
      if (!task.name || !task.time || !task.time.length) tmp.push(task.key);
    });
    setErrors(tmp);
    if (!tmp.length) {
      await createUpdateTAndA();
    } else {
      message.error('Une ou plusieurs tâches sont invalides');
    }
  };

  const updateTask = (value, key, field) => {
    let time = [];
    if (field === 'time') {
      if (value) {
        time = [
          value[0].milliseconds(0).seconds(0),
          value[1].milliseconds(0).seconds(0),
        ];
        if (inOutTime[0] && time[0] < inOutTime[0])
          time = [inOutTime[0], time[1]];
        if (inOutTime[1] && time[1] > inOutTime[1])
          time = [time[0], inOutTime[1]];
        if (tasks.length > 1) {
          if (time[0] < tasks[tasks.length - 2].time[1])
            time = [tasks[tasks.length - 2].time[1], time[1]];
        }
      }
    }
    setTasks(
      tasks.map(task => {
        if (task.key === key)
          return {
            ...task,
            ...(field === 'time' ? { time } : { [field]: value }),
          };
        return task;
      })
    );
  };

  useEffect(() => {
    let duration = moment.duration(0);
    tasks.forEach(({ time }) => {
      if (time && time.length)
        duration = moment.duration(time[1].diff(time[0])).add(duration);
    });
    setTotalDurations(duration);
  }, [tasks]);

  useEffect(() => {
    fetchEmployeeList();
    if (inOutTime.length)
      setTotalTime(moment.duration(inOutTime[1].diff(inOutTime[0])));
  }, [inOutTime]);

  return (
    <>
      <PageHeaderCustom
        title={t(`times-and-attendances.form.title.${purpose}`)}
      />
      <ContentCustom style={{ border: 0 }}>
        <Spin spinning={isLoading}>
          <Form>
            <Form.Item
              label={t('times-and-attendances.employee')}
              name="employee"
              rules={[
                {
                  required: true,
                  message: 'Veuillez remplir ce champ',
                },
              ]}>
              <Select
                placeholder={t('absences.desc_employee')}
                allowClear
                onChange={value => setSelectedUser(value)}>
                {user.map(e => (
                  <Option value={e._id} key={e._id}>
                    {e.first_name} {e.last_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
          <DayRow
            day={day}
            setDay={setDay}
            inOutTime={inOutTime}
            setInOutTime={setInOutTime}
            totalDurations={totalDurations}
            totalTime={totalTime}
          />
          <Divider orientation="left">
            {t('times-and-attendances.show.tasks.title')}
          </Divider>
          <TasksTable
            tasks={tasks}
            delTask={key => setTasks(tasks.filter(task => task.key !== key))}
            updateTask={updateTask}
            inOutTime={inOutTime}
            errors={errors}
          />
          <Button
            type="dashed"
            style={{ width: '100%', borderRadius: 0 }}
            icon={<PlusOutlined />}
            onClick={() => setTasks([...tasks, { key: uuid() }])}
            disabled={
              !(
                tasks[tasks.length - 1].name &&
                tasks[tasks.length - 1].time &&
                tasks[tasks.length - 1].time.length
              ) ||
              totalDurations.asMilliseconds() === totalTime.asMilliseconds()
            }>
            {`${t('times-and-attendances.form.add_task')} `}
          </Button>
        </Spin>
        <ButtonsContainer>
          <Button
            type="add"
            disabled={
              !inOutTime.length ||
              totalTime.asMilliseconds() === 0 ||
              totalDurations.asMilliseconds() !== totalTime.asMilliseconds()
            }
            style={{ marginRight: 20 }}
            onClick={handleSubmit}>
            {`${t('buttons.save')} `}
            <CheckOutlined />
          </Button>
          <Button type="link" danger onClick={history.goBack}>
            {`${t('buttons.cancel')} `}
            <CloseOutlined />
          </Button>
        </ButtonsContainer>
      </ContentCustom>
    </>
  );
};

CreateUpdateTAndA.propTypes = {
  purpose: PropTypes.string.isRequired,
};

export default CreateUpdateTAndA;
