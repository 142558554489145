import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Row, Col, Alert } from 'antd';
import { PlusOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import ErrorStatusCode from '../../../utils/ErrorStatusCode';
import Datatable from '../../../components/DataTable/Datatable';
import useAuthContext from '../../../contexts/AuthContext';
import { Columns } from './Columns';
import { SearchBar } from '../../../utils/constants/customButton';
import checkSubscriptionPermissions from '../../../utils/checkSubscriptionPermissions';
import CheckTrialDays from '../../../utils/checkTrialDays';

const Container = styled.div`
  padding: 24px;
  background-color: white;
`;

const Account = ({ setActiveKey }) => {
  const columns = Columns();
  const { dispatchAPI, user } = useAuthContext();
  const { pathname } = useLocation();
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [searchValue, setSearchValue] = useState(params.get('k'));
  const { t } = useTranslation();
  const [resourceData, setResourceData] = useState([]);
  const [minSubscription, setMinSubscription] = useState({});
  const { notification } = ErrorStatusCode();

  const getSubscription = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/subscriptions?title=AISANCE`
      });
      setMinSubscription(data[0]);
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  const searchResource = (value) => {
    if (value) {
      history.push({
        pathname,
        search: `?k=${value}`
      });
    } else {
      history.push({
        pathname
      });
    }
  };

  useEffect(() => {
    (async () => {
      await getSubscription();
    })();
  }, []);

  useEffect(() => {
    setSearchValue(params.get('k'));
  }, [params]);

  return (
    <>
      <Container>
        <Row justify="space-between">
          <Col>
            <SearchBar
              defaultValue={searchValue}
              setSearchValue={searchResource}
            />
          </Col>
          <Col>
            <Link to={{ pathname: `users/create` }}>
              <Button
                style={{ marginTop: '29px' }}
                type="primary"
                disabled={
                  !CheckTrialDays(user) &&
                  !checkSubscriptionPermissions(
                    'users',
                    resourceData.length,
                    user.organization.current_subscription || minSubscription
                  )
                }
              >
                {`${t('buttons.create')}`}
                &nbsp;
                <PlusOutlined />
              </Button>
            </Link>
          </Col>
        </Row>
        <Row style={{ justifyContent: 'flex-end' }}>
          <Col style={{ marginBottom: '8px' }}>
            {CheckTrialDays(user) ? (
              <Alert
                showIcon
                message={t('profile.trial_period')}
                type="warning"
              />
            ) : (
              !checkSubscriptionPermissions(
                'users',
                resourceData.length,
                user.organization.current_subscription || minSubscription
              ) && (
                <Alert
                  showIcon
                  message={
                    <span>
                      {user.organization.current_subscription
                        ? t(`buttons.infos.users`)
                        : t('profile.minSubscription')}
                      <Button
                        onClick={() => {
                          history.push('/profile');
                          setActiveKey('2');
                        }}
                        type="link"
                        icon={<ArrowRightOutlined />}
                      />
                    </span>
                  }
                  type="warning"
                />
              )
            )}
          </Col>
        </Row>
        <Datatable
          columns={columns}
          resourceName="users"
          setResourceData={setResourceData}
          extraQuery={`organization=${user?.organization?._id}&_id!=${user?._id}`}
          path="users"
          searchValue={searchValue}
        />
      </Container>
    </>
  );
};

Account.propTypes = {
  setActiveKey: PropTypes.func.isRequired
};

export default Account;
