const subscriptions = [
  {
    label: 'AISANCE',
    id: 47,
    options: [1, 3, 'OUI', 150, 'OUI', 'OUI', '20 GO', 'OUI', 'OUI'],
    color: '#FFAC6F'
  },
  {
    label: 'CONFORT',
    id: 67,
    options: [2, 10, 'OUI', 300, 'OUI', 'OUI', '250 GO', 'OUI', 'OUI'],
    color: '#FF9E56'
  },
  {
    label: 'EUPHORIE',
    id: 117,
    options: [5, 30, 'OUI', 650, 'OUI', '1 TO', 'OUI', 'OUI'],
    color: '#FF903C'
  },
  {
    label: 'INFINI',
    id: 297,
    options: [
      'illimités',
      'illimités',
      'OUI',
      'illimités',
      'OUI',
      'OUI',
      '1 TO',
      'OUI',
      'OUI'
    ],
    color: '#FF6D00'
  }
];

export default subscriptions;
