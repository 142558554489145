import React, { useCallback, useEffect, useState } from 'react';
import { DatePicker, Select, Upload, Tag, Input, Form, Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  CameraOutlined,
  CheckOutlined,
  CloseOutlined,
} from '@ant-design/icons/lib';
import IBAN from 'iban';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';
import ErrorStatusCode from '../../utils/ErrorStatusCode';
import { userRoles } from '../../utils/constants/tagColors';

const { Option } = Select;
const { Dragger } = Upload;

const useFields = () => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [enums, setEnums] = useState({});
  const [role, setRole] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  const { notification } = ErrorStatusCode();
  const handleInput = value => {
    return value.replace(phoneRegex, '($1) $2-$3');
  };

  const fields = [
    {
      name: ['last_name'],
      rules: [{ required: false }],
    },
    {
      name: ['first_name'],
      rules: [{ required: false }],
    },
    {
      name: ['role'],
      required: true,
      input: (
        <Select loading={isLoading} onSelect={(value) => setRole(value)}>
          {(enums?.roles || []).filter(role => role ==="users:USER" || role ==="users:SUPER-USER").map((roleS) => (
            <Option key={roleS} value={roleS}>
              <Tag color={userRoles[roleS.split(':')[1]]}>
                {t(`users.tags.${roleS.split(':')[1]}`)}
              </Tag>
            </Option>
          ))}
        </Select>
      ),
    },
    {
      name: ['email'],
      rules: [{ required: false }],
    },
    {
      name: ['phone_number'],
      label: 'phone_number.number',
      input: (
        <Input.Group compact>
          <Form.Item
            noStyle
            name={['phone_number', 'country_code']}
            initialValue="+33">
            <Select style={{ width: '25%' }}>
              <Option value="+33">+33</Option>
            </Select>
          </Form.Item>
          <Form.Item noStyle name={['phone_number', 'number']}>
            <Input
              pattern="[0-9]{9}"
              value={handleInput(phoneNumber)}
              onChange={e => setPhoneNumber(e.target.value)}
              style={{ width: '75%' }}
              title={t('errors_code.wrong_phone_number_format')}
            />
          </Form.Item>
        </Input.Group>
      ),
    },
    // {
    //   name: ['date_of_birth'],
    //   input: <DatePicker />,
    // },
    {
      label: 'address.number',
      name: ['address', 'number'],
    },
    {
      label: 'address.street',
      name: ['address', 'street'],
    },
    {
      label: 'address.additional',
      name: ['address', 'additional'],
    },
    {
      label: 'address.postcode',
      name: ['address', 'postal_code'],
    },
    {
      label: 'address.city',
      name: ['address', 'city'],
    },
  ];

  const companyFields = [
    {
      name: ['name'],
      rules: [{ required: false }],
    },
    {
      name: ['siret_number'],
      rules: [{ required: false }],
      input: <Input type="number" />,
    },
  ];
  const contactFields = [
    {
      name: ['channel_number'],
      rules: [{ required: false }],
      input: <Input type="number" />,
    },
    {
      name: ['address_details'],
      rules: [{ required: false }],
    },
    {
      name: ['postal_code'],
      rules: [{ required: false }],
      input: <Input type="number" />,
    },
    {
      name: ['city'],
      rules: [{ required: false }],
    },
    {
      name: ['country'],
      rules: [{ required: false }],
    },
    {
      name: ['phone_number'],
      rules: [{ required: false }],
      input: (
        <Input
          pattern="[0][0-9]{9}"
          value={handleInput(phoneNumber)}
          onChange={e => setPhoneNumber(e.target.value)}
        />
      ),
    },
    {
      name: ['location_tell'],
      rules: [{ required: false }],
    },
    {
      name: ['handicap_referent'],
      rules: [{ required: false }],
      input: <Input placeholder="Nom du référent handicap " />,
    },
    {
      name: ['handicap'],
      rules: [{ required: false }],
      input: (
        <Switch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
        />
      ),
    },
  ];

  const teamFields = [
    {
      name: ['pedagogic_team'],
      rules: [{ required: false }],
    },
  ];

  const teamReglementation = [
    {
      name: ['juridic_form'],
      rules: [{ required: false }],
    },
    {
      name: ['activity_declaration'],
      rules: [{ required: false }],
    },
    {
      name: ['acquisition_state'],
      rules: [{ required: false }],
    },
    {
      name: ['qualiopi_number'],
      rules: [{ required: false }],
    },
    {
      name: ['ape_code'],
      rules: [{ required: false }],
    },
    {
      name: ['social_capital'],
      rules: [{ required: false }],
      input: <Input type="number" />,
    },
    {
      name: ['form'],
      rules: [{ required: false }],
      input: (
        <Switch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
        />
      ),
    },
    {
      name: ['small_company'],
      rules: [{ required: false }],
      input: (
        <Switch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
        />
      ),
    },
  ];

  const comptableClotureFields = [
    {
      name: ['day_cloture'],
      rules: [{ required: false }],
    },
    {
      name: ['month_cloture'],
      rules: [{ required: false }],
    },
  ];

  const comptableFields = [
    {
      name: ['bank_code'],
      rules: [{ required: false }],
    },
    {
      name: ['guichet_code'],
      rules: [{ required: false }],
    },
    {
      name: ['account_number'],
      rules: [{ required: false }],
    },
    {
      name: ['rib'],
      rules: [{ required: false }],
    },
    {
      name: ['iban'],
      rules: [
        { required: false },
        ({ getFieldValue }) => ({
          validator(_, value) {
            if (
              value === '' ||
              getFieldValue('iban') === '' ||
              IBAN.isValid(value || getFieldValue('iban'))
            ) {
              return Promise.resolve();
            }
            return Promise.reject(t('login.wrongIban'));
          },
        }),
      ],
    },
    {
      name: ['bic'],
      rules: [{ required: false }],
    },
  ];

  const teamComptable = [
    {
      name: ['invoice_active'],
      rules: [{ required: false }],
      input: (
        <Switch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
        />
      ),
    },
    {
      name: ['currency'],
      rules: [{ required: false }],
    },
    {
      name: ['sigle_tax'],
      rules: [{ required: false }],
    },
    {
      name: ['tva_number'],
      rules: [{ required: false }],
    },
    {
      name: ['tva_rate'],
      rules: [{ required: false }],
      input: <Input type="number" />,
    },
  ];

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/users/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsLoading(true);
    await getEnums();
    setIsLoading(false);
  }, []);

  useEffect(() => {
    getSelectOptions();
  }, [getSelectOptions]);

  return {
    fields,
    companyFields,
    contactFields,
    teamFields,
    teamReglementation,
    teamComptable,
    comptableClotureFields,
    comptableFields,
  };
};

export default useFields;
