import { useTranslation } from 'react-i18next';

const useColumns = () => {
  const { t } = useTranslation();

  const headers = [
    {
      label: 'name',
      key: 'name'
    },
    {
      label: 'subtitle',
      key: 'subtitle'
    },
    {
      label: 'organization',
      key: 'organization.name',
      model_key: 'organization'
    },
    {
      label: 'blocs',
      key: 'blocs.name',
      model_key: 'program',
      header_key: 'blocs',
      multiple_data_key: 'name'
    },
    {
      label: 'modality.training_type',
      key: 'modality.training_type',
      extra_label: 'training_type'
    },
    {
      label: 'modality.accessibility',
      key: 'modality.accessibility',
      extra_label: 'accessibility'
    },
    {
      label: 'modality.staff_limit',
      key: 'modality.staff_limit',
      extra_label: 'staff_limit'
    },
    {
      label: 'modality.training_place',
      key: 'modality.training_place.name',
      extra_label: 'training_places',
      model_key: 'TrainingPlaces'
    },
    {
      label: 'training_action_type.training_action_type',
      key: 'training_action_type.training_action_type',
      extra_label: 'training_action_type'
    },
    {
      label: 'training_action_type.training_domain',
      key: 'training_action_type.training_domain',
      extra_label: 'training_domain'
    },
    {
      label: 'training_action_type.diploma',
      key: 'training_action_type.diploma',
      extra_label: 'diploma'
    },
    {
      label: 'description',
      key: 'description'
    },
    {
      label: 'educational_goals',
      key: 'educational_goals'
    },
    {
      label: 'trainees_profile.customers',
      key: 'trainees_profile.customers',
      extra_label: 'potentiel_customers'
    },
    {
      label: 'trainees_profile.prerequisite',
      key: 'trainees_profile.prerequisite',
      extra_label: 'prerequisite'
    },
    {
      label: 'training_content',
      key: 'training_content.title',
      extra_label: 'training_content_title',
      key_type: 'String'
    },
    {
      label: 'training_content',
      key: 'training_content.list_items',
      extra_label: 'training_content_list_items',
      key_type: 'String'
    },
    {
      label: 'execution_track.team_description',
      key: 'execution_track.team_description',
      extra_label: 'team_description'
    },
    {
      label: 'execution_track.results_track',
      key: 'execution_track.results_track',
      extra_label: 'results_track'
    },
    {
      label: 'execution_track.educational_resources',
      key: 'execution_track.educational_resources',
      extra_label: 'educational_resources'
    },
    {
      label: 'certifications_modality.expected_results',
      key: 'certifications_modality.expected_results',
      extra_label: 'expected_results'
    },
    {
      label: 'certifications_modality.obtaining_modality',
      key: 'certifications_modality.obtaining_modality',
      extra_label: 'obtaining_modality'
    },
    {
      label: 'certifications_modality.certification_details',
      key: 'certifications_modality.certification_details',
      extra_label: 'certification_details'
    },
    {
      label: 'certifications_modality.validity_duration',
      key: 'certifications_modality.validity_duration',
      extra_label: 'validity_duration'
    },
    {
      label: 'quality',
      key: 'quality'
    },
    {
      label: 'skills',
      key: 'skills.name',
      extra_label: 'skills_domain'
    }
  ];

  const columns = [
    {
      title: t('programs.form.name'),
      key: 'name',
      dataIndex: 'name',
      sorter: true
    }
  ];

  return { columns, headers };
};

export default useColumns;
