import { message } from 'antd';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../contexts/AuthContext';

const useColumns = () => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [kanbanStatuses, setKanbanStatuses] = useState([]);

  const getKanbanStatuses = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/kanbans' });
      setKanbanStatuses(data.filter((el) => el.type === 'sessions'));
    } catch (e) {
      if (e.response) message.error(e.response.data.message);
    }
  };

  useEffect(() => {
    (async () => {
      await getKanbanStatuses();
    })();
  }, []);

  return [
    {
      title: t('sessions.form.name'),
      dataIndex: 'name',
      key: 'name',
      sorter: true
    },
    {
      title: t('sessions.form.session_type'),
      dataIndex: 'session_type',
      key: 'session_type',
      sorter: true,
      filters: ['PRO', 'OUT_BPF', 'CONSULTING'].map((type) => ({
        text: type,
        value: type
      }))
    },
    {
      title: t('tasks.form.status'),
      dataIndex: 'status',
      render: (status) => status.label,
      filters: kanbanStatuses.map((status) => ({
        text: status.label,
        value: status._id
      }))
    },
    {
      title: t('sessions.form.date'),
      render: (session) =>
        `${
          session?.min_start_date
            ? moment(session?.min_start_date).format('DD/MM/YYYY')
            : ''
        } - ${
          session?.max_end_date
            ? moment(session?.max_end_date).format('DD/MM/YYYY')
            : ''
        }`
    },
    {
      title: t('sessions.form.customers'),
      render: (session) =>
        (session?.customers || []).map(
          (el, index) =>
            `${el?.customer?.name || ''}
            ${index < session?.customers?.length - 1 ? ', ' : ''}`
        )
    },
    {
      title: t('sessions.form.total_rates'),
      key: 'total_rates',
      dataIndex: 'total_rates',
      render: (total_rates) =>
        `${total_rates ? `${total_rates.toFixed(2)} €` : ''} `
    }
  ];
};

export default useColumns;
