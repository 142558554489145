import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons';
import { Divider, Button } from 'antd';

const CreditNoteColumn = ({ isDownloading, DlFile }) => {
  const { t } = useTranslation();

  return [
    {
      title: t('invoices.list.column.title.credit_note_number'),
      dataIndex: 'number',
      key: 'number',
      sorter: true
    },
    {
      title: t('invoices.list.column.title.reference'),
      dataIndex: 'invoice',
      key: 'invoice',
      render: (invoice) => invoice && invoice.number,
      sorter: true
    },
    {
      title: t('invoices.list.column.title.emission_date'),
      dataIndex: 'emission_date',
      key: 'emission_date',
      render: (date) => date && moment(date).format('DD/MM/YYYY'),
      sorter: true
    },
    {
      title: t('invoices.list.column.title.total_ht'),
      dataIndex: 'total_ht',
      key: 'total_ht',
      render: (total) => `${parseFloat(total).toFixed(2)}€`,
      sorter: true
    },
    {
      title: t('invoices.list.column.title.total'),
      dataIndex: 'total_ttc',
      key: 'total_ttc',
      render: (total) => `${parseFloat(total).toFixed(2)}€`,
      sorter: true
    },
    {
      title: t('invoices.list.column.title.total_paid'),
      dataIndex: 'total_paid',
      key: 'total_paid',
      render: (total) => (total ? `${parseFloat(total).toFixed(2)}€` : '-'),
      sorter: true
    },
    {
      key: 'action',
      render: (item) => {
        return (
          <>
            <>
              <Divider type="vertical" />
              <Button
                style={{ padding: 0 }}
                type="link"
                onClick={() => {
                  DlFile({
                    fileId: item.key,
                    name: `${item.number}.pdf`,
                    fileType: 'pdf',
                    type: 'credit_notes'
                  });
                }}
              >
                {isDownloading ? <LoadingOutlined /> : <DownloadOutlined />}
              </Button>
            </>
          </>
        );
      }
    }
  ];
};

export default CreditNoteColumn;
