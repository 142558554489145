import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Calendar,
  Select,
  Row,
  Col,
  Tag,
  message,
  Button,
  Alert,
  Input,
} from 'antd';
import styled from 'styled-components';
import moment from 'moment';
import Modale from './modale';
import EditDeleteSlotsModal from './editDeleteSlotsModal';

const { Option } = Select;

const Cell = styled.div`
  list-style: none;
  user-select: none;
  width: 100%;
  height: 100%;
`;

const Planning = ({
  module,
  reloadCalendar,
  setReloadCalendar,
  contributors,
}) => {
  const [selectedContributor, setSelectedContributor] = useState(
    'contributors'
  );
  const [showModale, setShowModale] = useState(false);
  const [showModaleDates, setShowModaleDates] = useState(false);
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [newModule, setNewModule] = useState([]);
  const [finalSlots, setFinalSlots] = useState([]);
  const [endTemp, setEndTemp] = useState(null);

  useEffect(() => {
    let data = [];
    const list = [];
    for (const [key, value] of Object.entries(module)) {
      if (key === 'slots') {
        data = value.date;
      }
    }
    data.forEach(date => {
      date.sub_slots.map(subslot => {
        list.push({
          date: date.date,
          type: subslot.type,
          start_time: subslot.start_time,
          end_time: subslot.end_time,
          contributors: subslot.contributors,
        });
      });
    });
    setNewModule(list);
    setFinalSlots(list);
  }, [module]);

  const handleSelectContributor = value => {
    const list = [];

    setSelectedContributor(value);
    if (value === 'contributors') {
      list.push(...newModule);
    } else {
      newModule.forEach(slot => {
        if (slot.contributors.map(el => el._id).includes(value)) {
          list.push({
            ...slot,
            contributors: slot.contributors.filter(el => el._id === value),
          });
        }
      });
    }
    setFinalSlots(list);
  };

  useEffect(() => {
    if (end !== null) {
      setShowModale(true);
    }
  }, [end]);

  useEffect(() => {
    const list = [];
    const listCells = [];
    const listSelected = document.querySelectorAll(
      `td.ant-picker-cell-selected`
    );
    listSelected.forEach(cell => {
      cell.classList.remove('ant-picker-cell-selected');
    });
    for (
      let i = moment(start);
      i.isBefore(moment(endTemp).add(1, 'days'));
      i.add(1, 'days')
    ) {
      list.push(i.format('YYYY-MM-DD'));
    }
    list.forEach(el => {
      listCells.push(document.querySelector(`td[title="${el}"]`));
    });
    listCells.forEach(cell => {
      cell.classList.add('ant-picker-cell-selected');
    });
  }, [start, endTemp]);

  const resetAllSelector = () => {
    setEndTemp(null);
    const listSelected = document.querySelectorAll(
      `td.ant-picker-cell-selected`
    );
    listSelected.forEach(cell => {
      cell.classList.remove('ant-picker-cell-selected');
    });
  };

  const dateCellRender = value => {
    const date = new Date(value);

    return (
      <Cell
        onMouseDown={() => {
          resetAllSelector();
          setStart(date);
        }}
        onMouseEnter={() => start && setEndTemp(date)}
        onMouseUp={() => setEnd(date)}>
        {finalSlots.map(mod => {
          if (
            moment(date).format('YYYY-MM-DD') ===
            moment(mod.date).format('YYYY-MM-DD')
          ) {
            return (
              <li style={{ fontSize: 10, paddingBottom: 5 }}>
                <Tag color="purple">
                  {`${mod.start_time} à ${mod.end_time}`}
                </Tag>
                {mod.contributors &&
                  mod.contributors.map(contributor => (
                    <>
                      <span>{`${contributor.first_name.toUpperCase()} ${contributor.last_name.toUpperCase()}`}</span>
                      <br />
                    </>
                  ))}
              </li>
            );
          }
        })}
      </Cell>
    );
  };

  const headerRender = ({ value, type, onChange, onTypeChange }) => {
    const start = 0;
    const end = 12;
    const monthOptions = [];

    const current = moment(value).clone();
    const localeData = moment(value).localeData();
    const months = [];
    for (let i = 0; i < 12; i++) {
      current.month(i);
      months.push(localeData.monthsShort(current));
    }
    for (let index = start; index < end; index++) {
      monthOptions.push(
        <Select.Option className="month-item" key={`${index}`}>
          {months[index]}
        </Select.Option>
      );
    }
    const month = moment(value).month();

    const year = moment(value).year();
    const options = [];
    for (let i = year - 2; i < year + 4; i += 1) {
      options.push(
        <Select.Option key={i} value={i} className="year-item">
          {i}
        </Select.Option>
      );
    }
    return (
      <div style={{ padding: '8px 0' }}>
        <Row>
          <Col span={24}>
            <Alert
              showIcon
              message="Pour ajouter des créneaux de formations, vous pouvez sélectionner une date ou un intervalle de dates"
              type="info"
            />
          </Col>
        </Row>
        <Row style={{ marginTop: 5, marginBottom: 5 }}>
          <Col span={24}>
            <Alert
              showIcon
              message="Pour modifier des créneaux de formations, vous pouvez sélectionner une date  ou un intervalle de dates à modifier ou utiliser le bouton Modifier"
              type="info"
            />
          </Col>
        </Row>
        <Row>
          <Col span={2}>
            <Select
              dropdownMatchSelectWidth={false}
              className="my-year-select"
              onChange={newYear => {
                const now = moment(value)
                  .clone()
                  .year(newYear);
                onChange(now);
              }}
              value={year && String(year)}>
              {options}
            </Select>
          </Col>
          <Col span={2}>
            <Select
              dropdownMatchSelectWidth={false}
              onChange={selectedMonth => {
                const newValue = moment(value).clone();
                newValue.month(parseInt(selectedMonth, 10));
                onChange(newValue);
              }}
              value={String(month) && String(month)}>
              {monthOptions}
            </Select>
          </Col>
          <Col span={2}>
            <Select
              onSelect={handleSelectContributor}
              style={{ width: 120 }}
              value={selectedContributor}
            >
              <Option value="contributors">Intervenants</Option>
              {contributors.map(
                ({ contributor }) =>
                  contributor && (
                    <Option value={contributor._id}>
                      {contributor &&
                        `${contributor.first_name} ${contributor.last_name}`}
                    </Option>
                  )
              )}
            </Select>
          </Col>
          {/* {module.slots.date.length && (
            <Col
              span={2}
              offset={16}
              style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                onClick={() => setShowModaleDates(!showModaleDates)}
                type="primary">
                Modifier
              </Button>
            </Col>
          )} */}
        </Row>
      </div>
    );
  };

  return (
    <>
      <Calendar
        headerRender={headerRender}
        defaultValue={
          module.slots.date.filter(date => date.sub_slots.length).length > 0
            ? moment(module.slots.end_date, 'YYYY-MM-DD')
            : moment(new Date(), 'YYYY-MM-DD')
        }
        dateCellRender={dateCellRender}
        mode="month"
        style={{ marginRight: 25, padding: 10, userSelect: 'none' }}
      />
      <Modale
        resetAllSelector={resetAllSelector}
        showModale={showModale}
        setShowModale={setShowModale}
        idModule={module?._id}
        module={module}
        dateStart={start}
        dateEnd={end}
        setStartDate={setStart}
        setEndDate={setEnd}
        setReloadCalendar={setReloadCalendar}
        reloadCalendar={reloadCalendar}
        editSlots={newModule}
      />
      <EditDeleteSlotsModal
        showModale={showModaleDates}
        setShowModale={setShowModaleDates}
        module={module}
        setReloadCalendar={setReloadCalendar}
        reloadCalendar={reloadCalendar}
      />
    </>
  );
};

export default Planning;
