import React from 'react';
import useColumns from './columns';
import SimpleResourceLandingLayout from '../../../components/layouts/SimpleResourceLandingLayout';

const ExternalFounders = () => {
  const { columns, headers } = useColumns();

  return (
    <SimpleResourceLandingLayout
      resourceName="external-funders"
      columns={columns}
      headers={headers}
      withProjectionFields
      resourceModelName="External-Funders"
      templateType="external_founders_import_template"
    />
  );
};

export default ExternalFounders;
