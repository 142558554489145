import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import {
  Row,
  Col,
  Card,
  Collapse,
  Divider,
  List,
  message,
  PageHeader,
  Checkbox,
  Space,
  Button,
  Select,
  TreeSelect
} from 'antd';
import { useTranslation } from 'react-i18next';
import { ContentCustom } from '../../../components';
import useAuthContext from '../../../contexts/AuthContext';
import ModalUploadFiles from '../../Catalog/Program/modalUploadFiles';
import ListSessionFiles from '../conventions/ListSessionFiles';
import VisibilityOptions from './VisibilityOptions';
import ErrorStatusCode from '../../../utils/ErrorStatusCode';

const { Panel } = Collapse;
const { TreeNode } = TreeSelect;

const Extranet = ({
  customers,
  trainees,
  session,
  updateSession,
  fetchData
}) => {
  const { t } = useTranslation();
  const { dispatchAPI, user } = useAuthContext();
  const [dataList, setDataList] = useState([]);
  const [visibilities, setVisibilities] = useState({});
  const [customer, setCustomer] = useState('customers');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const { notification } = ErrorStatusCode();

  const [
    TraineesVisibilityOptions,
    ContributorsVisibiltyOptions,
    CompaniesVisibilityOptions
  ] = VisibilityOptions();

  useEffect(() => {
    setVisibilities(session.extranet_visibility_options);
  }, [session]);

  const redirect = (url) => {
    window.open(url, '_blank');
  };

  useEffect(() => {
    const tmp = [];
    session.contributors &&
      session.contributors.forEach((contributor) => {
        contributor?.contributor &&
          tmp.push({
            last_name: contributor.contributor.last_name,
            first_name: contributor.contributor.first_name,
            status: 'contributor',
            url: `${process.env.REACT_APP_EXTRANET_URL}/sessions/show/${session._id}?guestId=${contributor.contributor._id}`
          });
      });
    customers.forEach((customer) => {
      if (customer.ref === 'Trainees') {
        customer?.customer &&
          tmp.push({
            last_name: customer.customer.last_name,
            first_name: customer.customer.first_name,
            status: 'trainee',
            url: `${process.env.REACT_APP_EXTRANET_URL}/sessions/show/${session._id}?guestId=${customer.customer._id}`
          });
      } else {
        customer?.customer &&
          tmp.push({
            name: customer.customer.name,
            status: 'company',
            url: `${process.env.REACT_APP_EXTRANET_URL}/sessions/show/${session._id}?guestId=${customer.customer._id}`
          });
        customer.trainees_list.forEach((trainee) => {
          trainee &&
            tmp.push({
              last_name: trainee.last_name,
              first_name: trainee.first_name,
              status: 'trainee',
              url: `${process.env.REACT_APP_EXTRANET_URL}/sessions/show/${session._id}?guestId=${trainee._id}`
            });
        });
      }
    });
    setDataList(tmp);
  }, [session, customers, trainees]);

  const downloadFile = async (id, name) => {
    try {
      const response = await dispatchAPI('GET', {
        url: `/files/${id}`,
        responseType: 'blob'
      });
      const blob = new Blob([response.data], {
        type: response.data.type
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = name;
      a.target = '_blank';
      a.click();
    } catch (e) {
      if (e.response) notification(e.response);
    }
    setIsDownloading({ ...isDownloading, [id]: false });
  };

  const deleteFile = async (id) => {
    try {
      await dispatchAPI('DELETE', {
        url: `/files/${id}`
      });
    } catch (e) {
      message.error(t('settings.errors.category_delete'));
    }
  };

  const deleteFileFromSession = async (id) => {
    const filesList = session.files.filter((el) => el._id !== id);
    await updateSession({ files: filesList });
    await deleteFile(id);
    await fetchData();
  };

  const onChange = async (e, type) => {
    const visibles = visibilities;
    const name = e.target.name;
    const value = e.target.checked;
    visibles[type][name] = value;
    setVisibilities(visibles);
    await updateSession({ extranet_visibility_options: visibles });
  };
  const onCopyLinkExtrant = async (url) => {
    try {
      navigator.clipboard.writeText(url);
      message.success(t('sessions.extranet.button.success'));
    } catch (e) {
      message.success(t('sessions.extranet.button.error'));
    }
  };
  return (
    <>
      <Divider orientation="left">
        <span>{t(`sessions.extranet.title`)}</span>
      </Divider>
      <Card>
        <span> {t('sessions.extranet.link')}</span> <br />
        <Link
          onClick={() =>
            redirect(
              `${process.env.REACT_APP_API_URL}/redirect/${session._id}?userId=${user._id}`
            )
          }
        >
          {`${process.env.REACT_APP_API_URL}/redirect/${session._id}?user=${user._id}`}
        </Link>
        <Button
          type="primary"
          style={{ marginLeft: 100 }}
          onClick={() =>
            onCopyLinkExtrant(
              `${process.env.REACT_APP_API_URL}/redirect/${session._id}?userId=${user._id}`
            )
          }
        >
          {t('sessions.extranet.button.copy')}
        </Button>
        <Collapse accordion ghost>
          <Panel key="1" header={t('sessions.extranet.connect')}>
            <Divider orientation="left">
              <span>{t(`contributors.title`)}</span>
            </Divider>
            <List
              size="small"
              dataSource={dataList.filter((el) => el.status === 'contributor')}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    title={`${item.first_name} ${item.last_name} (${t(
                      `sessions.extranet.tags.${item.status}`
                    )})`}
                  />
                  <Link onClick={() => redirect(item.url)}>{item.url}</Link>
                </List.Item>
              )}
            />
            <Divider orientation="left">
              <span>{t(`company.title`)}</span>
            </Divider>
            <List
              size="small"
              dataSource={dataList.filter((el) => el.status === 'company')}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    title={`${item.name} (${t(
                      `sessions.extranet.tags.${item.status}`
                    )})`}
                  />
                  <Link onClick={() => redirect(item.url)}>{item.url}</Link>
                </List.Item>
              )}
            />
            <Divider orientation="left">
              <span>{t(`trainees.title`)}</span>
            </Divider>
            <List
              size="small"
              dataSource={dataList.filter((el) => el.status === 'trainee')}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    title={`${item.first_name} ${item.last_name} (${t(
                      `sessions.extranet.tags.${item.status}`
                    )})`}
                  />
                  <Link onClick={() => redirect(item.url)}>{item.url}</Link>
                </List.Item>
              )}
            />
          </Panel>
        </Collapse>
      </Card>
      <Divider orientation="left">
        <span>{t('sessions.extranet.configuration')}</span>
      </Divider>
      <Card
        headStyle={{ textAlign: 'center' }}
        style={{ marginTop: '10px' }}
        title={session.name}
      >
        <Collapse
          accordion
          ghost
          className="session-visibility-options-collapse"
        >
          <Panel key="1" header={t('sessions.extranet.visibilityOptions.title')}>
            <ContentCustom>
              <Row>
                <Col span={8}>
                  <PageHeader title="Stagiaires" />
                  {TraineesVisibilityOptions.map((el) => (
                    <>
                      <Checkbox
                        checked={
                          visibilities?.trainees_visibility_options &&
                          visibilities.trainees_visibility_options[el.name]
                        }
                        onClick={(e) =>
                          onChange(e, 'trainees_visibility_options')
                        }
                        name={el.name}
                      />
                      <span>{el.label}</span>
                      <br />
                    </>
                  ))}
                </Col>
                <Col span={8}>
                  <PageHeader title="Intervenants" />
                  {ContributorsVisibiltyOptions.map((el) => (
                    <>
                      <Checkbox
                        checked={
                          visibilities?.contributors_visibility_options &&
                          visibilities.contributors_visibility_options[el.name]
                        }
                        onClick={(e) =>
                          onChange(e, 'contributors_visibility_options')
                        }
                        name={el.name}
                      />
                      <span>{el.label}</span>
                      <br />
                    </>
                  ))}
                </Col>
                <Col span={8}>
                  <PageHeader title={t('companies.title')} />
                  {CompaniesVisibilityOptions.map((el) => (
                    <>
                      <Checkbox
                        checked={
                          visibilities?.companies_visibility_options &&
                          visibilities.companies_visibility_options[el.name]
                        }
                        onClick={(e) =>
                          onChange(e, 'companies_visibility_options')
                        }
                        name={el.name}
                      />
                      <span>{el.label}</span>
                      <br />
                    </>
                  ))}
                </Col>
              </Row>
            </ContentCustom>
          </Panel>
          <Panel key="2" header={t('sessions.extranet.documents')}>
            {session.files?.filter((el) => el.type === 'extranet').length !==
              0 && (
              <ListSessionFiles
                files={session.files?.filter((el) => el.type === 'extranet')}
                deleteFileFromSession={deleteFileFromSession}
                downloadFile={downloadFile}
              />
            )}
            <Space>
              <ModalUploadFiles
                fetchData={fetchData}
                id={session._id}
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
                fileType="extranet"
                uploadType="sessions"
              />
              <Button
                onClick={() => setIsModalVisible(!isModalVisible)}
                type="link"
                style={{ marginTop: 10 }}
              >
                {t('documents.create')}
              </Button>
            </Space>
          </Panel>
        </Collapse>
      </Card>
    </>
  );
};
export default Extranet;
