import React from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Drawer, Form, message, Typography } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../../contexts/AuthContext';
import useFields from './fields';
import useGenerateFormItem from '../../../utils/GenerateFormItem';

const { Title } = Typography;

const fieldsValues = {
  name: '',
  first_name: '',
  last_name: '',
  study_level: '',
  study_name: '',
  email: '',
  number: '',
  street: '',
  additional: '',
  postcode: '',
  city: '',
  phone_number: '',
  status: '',
  company: ''
};

const NewFunderDrawer = ({
  isVisible,
  setVisibilityDrawer,
  refresh,
  setRefresh
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { dispatchAPI } = useAuthContext();
  const { fields } = useFields();
  const generateFields = useGenerateFormItem();

  const cancelButton = () => {
    form.setFieldsValue(fieldsValues);
    setVisibilityDrawer();
  };

  const createFunder = async (body) => {
    try {
      await dispatchAPI('POST', {
        url: '/external-funders',
        body
      });
      message.success(t('ExternalFounders.message.success.new'));
    } catch (e) {
      message.error(t('ExternalFounders.message.error.new'));
    }
  };

  const onFinish = async (values) => {
    await createFunder(values);
    setRefresh(!refresh);
    form.setFieldsValue(fieldsValues);
    setVisibilityDrawer();
  };

  return (
    <>
      <Drawer
        visible={isVisible}
        onClose={() => {
          cancelButton();
        }}
        width="30%"
        className="new-trainee-drawer"
      >
        <Col style={{ width: '100%' }}>
          <Title level={3}>{t(`funders.form.title.create`)}</Title>
        </Col>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          style={{ width: '100%' }}
        >
          {fields.map((field) =>
            generateFields('ExternalFounders' || 'external-funders', field)
          )}
          <Button
            type="add"
            htmlType="submit"
            style={{
              margin: '16px 0 0 10px',
              float: 'right'
            }}
          >
            {t('buttons.save')}
            <CheckOutlined />
          </Button>
          <Button
            onClick={cancelButton}
            style={{
              float: 'right',
              marginTop: 16
            }}
            type="link"
            danger
          >
            {`${t('buttons.cancel')}`}
            <CloseOutlined />
          </Button>
        </Form>
      </Drawer>
    </>
  );
};

NewFunderDrawer.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  setVisibilityDrawer: PropTypes.func.isRequired,
  refresh: PropTypes.bool.isRequired,
  setRefresh: PropTypes.func.isRequired
};

export default NewFunderDrawer;
