import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import CreateUpdateContainer from '../../components/CreateUpdateContainer/CreateUpdateContainer';
import useAuthContext from '../../contexts/AuthContext';
import useFields from './fields';
import ErrorStatusCode from '../../utils/ErrorStatusCode';

const CreateUpdateUser = ({ purpose }) => {
  const [userPhoto, setUserPhoto] = useState();
  const { fields, isFieldsLoading, base64 } = useFields({ purpose, userPhoto });
  const { dispatchAPI } = useAuthContext();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { id } = useParams();
  const history = useHistory();
  const { t } = useTranslation();
  const [values, setValues] = useState();
  const { notification } = ErrorStatusCode();

  const config = {
    onGetResource: {
      setFields: (data) => {
        return {
          ...data,
          photo: data.photo && setUserPhoto(data.photo)
        };
      }
    }
  };
  const updateUser = async (body) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/users/${id}`,
        body: { ...body, ...(base64 ? { photo: base64 } : {}) }
      });
    } catch (e) {
      if (e.response) {
        if (
          e.response.data.status_code === 400 &&
          e.response.data.errors.email.kind === 'UNIQUE'
        ) {
          message.error(
            "Vous n'êtes pas autorisé (e) à utiliser un mail existant"
          );
        } else {
          notification(e.response);
        }
      }
    }
  };

  const createUser = async (body) => {
    try {
      await dispatchAPI('POST', {
        url: '/users',
        body: { ...body, photo: base64 }
      });
      history.goBack();
    } catch (e) {
      if (e.response) {
        if (e.response) {
          if (
            e.response.data.status_code === 400 &&
            e.response.data.errors.email.kind === 'UNIQUE'
          ) {
            message.error(
              "Vous n'êtes pas autorisé (e) à utiliser un mail existant"
            );
          } else {
            notification(e.response);
          }
        }
      }
    }
  };
  const handleSubmit = async (body) => {
    try {
      if (purpose === 'edit') {
        setIsModalVisible(true);

        setValues(body);
        setUserPhoto(userPhoto);
      } else {
        await createUser(body);
      }
    } catch (e) {
      if (e.response) {
        if (
          e.response.data.status_code === 400 &&
          e.response.data.errors.email.kind === 'UNIQUE'
        ) {
          message.error(
            "Vous n'êtes pas autorisé (e) à utiliser un mail existant"
          );
        } else {
          notification(e.response);
        }
      }
    }
  };

  const handleOk = async () => {
    try {
      setIsModalVisible(true);
      await updateUser(values);
      history.goBack();
    } catch (e) {
      if (e.response) {
        if (e.response) {
          if (
            e.response.data.status_code === 400 &&
            e.response.data.errors.email.kind === 'UNIQUE'
          ) {
            message.error(
              "Vous n'êtes pas autorisé (e) à utiliser un mail existant"
            );
          } else {
            notification(e.response);
          }
        }
      }
    }
  };
  const handleCancel = () => {
    setIsModalVisible(false);
    history.goBack();
  };
  return (
    <>
      <CreateUpdateContainer
        fields={fields}
        loadingFields={isFieldsLoading}
        purpose={purpose}
        resource="users"
        config={config}
        baseUrl="users"
        customSubmit={handleSubmit}
      />
      {purpose === 'edit' ? (
        <Modal
          title="Validation de modifications"
          visible={isModalVisible}
          onCancel={handleCancel}
          onOk={handleOk}
          width={800}
          okText={t('datatable.column.action.validate.ok')}
          cancelText={t('datatable.column.action.validate.cancel')}
        >
          <p>Etes-vous sûr de valider les changements apportés?</p>
        </Modal>
      ) : (
        ''
      )}
    </>
  );
};

CreateUpdateUser.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdateUser;
