export const fundersRadio = [
  "Plan de développement de l'entreprise",
  'Compte personnel de Formation',
  'CPF de transit',
  'Congé Individuel de Formation',
  'Contrat de professionnalisation',
  'Période de Professionnalisation',
];

export const fundingCheckbox = [
  'Situation de sous-traitance',
  "Dispositif spécifique pour les personnes en recherche d'emploi",
  'Ligne autres produits (13) du cadre C du BPF (clients étrangers)',
  "Ligne autres stagiaires (e) du cadre F1 du BPF (financement inconnu en situation de sous-traitance ou stagiaires non salariés d'un client entreprise, ...)",
];
