import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Checkbox,
  Collapse,
  Form,
  Input,
  message,
  Select,
  Upload
} from 'antd';
import {
  CaretRightOutlined,
  HomeOutlined,
  LockOutlined,
  MailOutlined,
  PhoneOutlined,
  UserOutlined,
  CameraOutlined
} from '@ant-design/icons';
import useAuthContext from '../../contexts/AuthContext';
import usePwdPattern from '../../utils/pwdPattern';

const { Dragger } = Upload;
const { Panel } = Collapse;
const { Option } = Select;

const RegisterForm = ({ switchForm, prescriber, sponsor }) => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [fileList, setFileList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [activeKey, setActiveKey] = useState('2');

  const fileToBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (e) => reject(e);
    });

  const registerUser = async (values) => {
    setLoading(true);
    try {
      if (fileList.length > 0) {
        values.logo = fileToBase64(fileList[0]);
      }
      await dispatchAPI('REGISTER', values);
      message.success(t('login.registrationSuccess'));
      switchForm('login');
    } catch (e) {
      if (e.response) {
        if (e.response.data && e.response.data.errors.email) {
          message.error(t('login.sameEmails'));
        } else {
          message.error(t('login.wrongDetails'));
        }
      } else if (e.request) {
        message.error(t('login.networkError'));
      } else {
        message.error(t('login.error'));
      }
    }
    setLoading(false);
  };

  const handleSubmit = async (values) => {
    await registerUser({
      ...values,
      email: values.username.toLowerCase(),
      role: prescriber && 'users:PRESCRIBER',
      sponsor: sponsor && sponsor
    });
  };

  const draggerProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      const fileExtension = file.name.split('.').pop();
      if (
        fileExtension === 'png' ||
        fileExtension === 'PNG' ||
        fileExtension === 'jpg' ||
        fileExtension === 'JPG'
      ) {
        setFileList([...fileList, file]);
      } else {
        message('Not a PNG or JPG file.');
        return true;
      }
      return false;
    },
    fileList
  };

  return (
    <div>
      <Form onFinish={handleSubmit}>
        <div style={{ padding: '16px' }}>
          <Form.Item
            name="first_name"
            rules={[{ required: true, message: t('errors.form.REQUIRED') }]}
          >
            <Input
              prefix={
                <UserOutlined style={{ color: 'var(--textColorSecondary)' }} />
              }
              placeholder={t('login.first_name')}
            />
          </Form.Item>
          <Form.Item
            name="last_name"
            rules={[{ required: true, message: t('errors.form.REQUIRED') }]}
          >
            <Input
              prefix={
                <UserOutlined style={{ color: 'var(--textColorSecondary)' }} />
              }
              placeholder={t('login.last_name')}
            />
          </Form.Item>
          <Form.Item
            name="username"
            rules={[
              { required: true, message: t('login.usernameMissing') },
              { type: 'email' }
            ]}
          >
            <Input
              prefix={
                <MailOutlined style={{ color: 'var(--textColorSecondary)' }} />
              }
              placeholder={t('login.username')}
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              { required: true, message: t('login.pwdMissing') },
              ...usePwdPattern()
            ]}
            hasFeedback
          >
            <Input.Password
              prefix={
                <LockOutlined style={{ color: 'var(--textColorSecondary)' }} />
              }
              placeholder={t('login.password')}
            />
          </Form.Item>
          <Form.Item
            dependencies={['password']}
            hasFeedback
            name="confirm"
            rules={[
              {
                required: true,
                message: t('login.pwdMissing')
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(t('login.pwdNotMatching'));
                }
              })
            ]}
          >
            <Input.Password
              prefix={
                <LockOutlined style={{ color: 'var(--textColorSecondary)' }} />
              }
              placeholder={t('login.confirmPassword')}
            />
          </Form.Item>
        </div>
        <Collapse
          bordered={false}
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}
          accordion
          activeKey={activeKey}
          ghost
          onChange={(key) => setActiveKey(key)}
        >
          {!prescriber && (
            <Panel
              header={t('login.company-information')}
              key="2"
              className="site-collapse-custom-panel"
            >
              <Form.Item name={['logo']}>
                <Dragger {...draggerProps}>
                  <p className="ant-upload-drag-icon">
                    <CameraOutlined style={{ color: 'var(--textColor)' }} />
                  </p>
                  {t('login.logo')}
                </Dragger>
              </Form.Item>
              <Form.Item
                name={['name']}
                rules={[
                  {
                    required: true,
                    message: t('errors.form.REQUIRED')
                  },
                  {
                    validator: (_, value) => {
                      if (value) {
                        return Promise.resolve();
                      }

                      setActiveKey('2');
                      return Promise.reject();
                    }
                  }
                ]}
              >
                <Input
                  style={{ width: '100%' }}
                  prefix={
                    <UserOutlined
                      style={{ color: 'var(--textColorSecondary)' }}
                    />
                  }
                  placeholder={t('login.name')}
                />
              </Form.Item>
              <Form.Item name={['siret_number']}>
                <Input
                  type="number"
                  style={{ width: '100%' }}
                  prefix={
                    <HomeOutlined
                      style={{ color: 'var(--textColorSecondary)' }}
                    />
                  }
                  placeholder={t('login.siret')}
                />
              </Form.Item>
            </Panel>
          )}
        </Collapse>
        <Collapse
          bordered={false}
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}
          accordion
          activeKey={activeKey}
          ghost
          onChange={(key) => setActiveKey(key)}
        >
          <Panel
            header={t('login.additional-information')}
            key="1"
            className="site-collapse-custom-panel"
          >
            <Form.Item name={['logo']}>
              <Dragger {...draggerProps}>
                <p className="ant-upload-drag-icon">
                  <CameraOutlined style={{ color: 'var(--textColor)' }} />
                </p>
                {t('login.logo')}
              </Dragger>
            </Form.Item>
            <Form.Item>
              <Input.Group compact>
                <Form.Item
                  noStyle
                  name={['phone_number', 'country_code']}
                  initialValue="+33"
                >
                  <Select style={{ width: '25%' }}>
                    <Option value="+33">+33</Option>
                  </Select>
                </Form.Item>
                <Form.Item noStyle name={['phone_number', 'number']}>
                  <Input
                    style={{ width: '75%' }}
                    prefix={
                      <PhoneOutlined
                        style={{ color: 'var(--textColorSecondary)' }}
                      />
                    }
                    placeholder={t('login.phone-number')}
                  />
                </Form.Item>
              </Input.Group>
            </Form.Item>
            <Form.Item name={['address', 'number']}>
              <Input
                type="number"
                style={{ width: '100%' }}
                prefix={
                  <HomeOutlined
                    style={{ color: 'var(--textColorSecondary)' }}
                  />
                }
                placeholder={t('login.street-number')}
              />
            </Form.Item>
            <Form.Item name={['address', 'street']}>
              <Input
                prefix={
                  <HomeOutlined
                    style={{ color: 'var(--textColorSecondary)' }}
                  />
                }
                placeholder={t('login.street-name')}
              />
            </Form.Item>
            <Form.Item name={['address', 'additional']}>
              <Input
                prefix={
                  <HomeOutlined
                    style={{ color: 'var(--textColorSecondary)' }}
                  />
                }
                placeholder={t('login.address-extra')}
              />
            </Form.Item>
            <Form.Item name={['address', 'postal_code']}>
              <Input
                prefix={
                  <HomeOutlined
                    style={{ color: 'var(--textColorSecondary)' }}
                  />
                }
                placeholder={t('login.postal-code')}
              />
            </Form.Item>
            <Form.Item name={['address', 'city']}>
              <Input
                prefix={
                  <HomeOutlined
                    style={{ color: 'var(--textColorSecondary)' }}
                  />
                }
                placeholder={t('login.city')}
              />
            </Form.Item>
          </Panel>
        </Collapse>
        <Form.Item
          style={{ textAlign: 'left' }}
          name="CGU"
          valuePropName="checked"
          rules={[
            {
              validator: (_, value) =>
                value
                  ? Promise.resolve()
                  : Promise.reject(t('login.CGUMissing'))
            }
          ]}
        >
          <Checkbox>
            {`${t('login.CGU1')} `}
            <a href="https://demateriz.com/cgu-demateriz/" _target="blank">
              <Button type="link" style={{ padding: 0, height: 'auto' }}>
                {`${t('login.CGU2')}`}
              </Button>
            </a>
          </Checkbox>
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
            style={{ width: '100%' }}
            loading={isLoading}
          >
            {t('login.registration')}
          </Button>
        </Form.Item>
        <Form.Item>
          <Button
            type="link"
            style={{ width: '100%' }}
            onClick={() => switchForm('login')}
          >
            {t('login.connection')}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

RegisterForm.propTypes = {
  switchForm: PropTypes.func.isRequired,
  prescriber: PropTypes.bool.isRequired,
  purpose: PropTypes.string,
  sponsor: PropTypes.string
};

RegisterForm.defaultProps = {
  purpose: null,
  sponsor: undefined
};
export default RegisterForm;
