import React from 'react';
import { useTranslation } from 'react-i18next';

const Columns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('Désignation'),
      dataIndex: ['name'],
      sorter: true,
    },
    {
      title: t('sessions.modules.rate_type'),
      key: 'rate_type',
      dataIndex: 'rate_type',
      render: rate_type => t(`sessions.modules.form.${rate_type}`),
      sorter: true,
    },
    {
      title: t('Quantité'),
      dataIndex: ['quantity'],
      sorter: true,
    },
    {
      title: t('Prix unitaire'),
      dataIndex: ['rate'],
      sorter: true,
    },
    {
      title: t('TVA'),
      dataIndex: ['tva'],
      sorter: true,
    },
    {
      title: t('Total HT'),
      dataIndex: ['total_ht'],
      sorter: true,
    },
  ];
};

export default Columns;
