import { useTranslation } from 'react-i18next';

const useColumns = () => {
  const { t } = useTranslation();

  const headers = [
    {
      label: 'name',
      key: 'name'
    },
    {
      label: 'email',
      key: 'email'
    },
    {
      label: 'type',
      key: 'type'
    },
    {
      label: 'address.number',
      key: 'address.number'
    },
    {
      label: 'address.street',
      key: 'address.street'
    },
    {
      label: 'address.additional',
      key: 'address.additional'
    },
    {
      label: 'address.postal_code',
      key: 'address.postal_code'
    },
    {
      label: 'address.city',
      key: 'address.city'
    },
    {
      label: 'email_contact',
      key: 'email_contact'
    },
    {
      label: 'free_notes',
      key: 'free_notes'
    },
    {
      label: 'organization',
      key: 'organization.name'
    }
  ];

  const columns = [
    {
      title: t('external-funders.columns.name'),
      dataIndex: 'name',
      sorter: true
    },
    {
      title: t('external-funders.columns.type'),
      dataIndex: 'type',
      sorter: true
    }
  ];

  return { columns, headers };
};

export default useColumns;
