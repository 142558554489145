import React, { useEffect } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Input, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import KanbanColumn from './KanbanColumn';
import EditDrawer from './EditDrawer';
import useBoxContext from './DealsContext';

const KanbanWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  position: relative;
  height: calc(100vh - 250px);
  margin: 0 0 -18px -4px;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 109, 0, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background-size: cover;
`;

const { Search } = Input;

const KanbanBoard = () => {
  const { t } = useTranslation();
  const {
    deal,
    searchResource,
    searchValue,
    visible,
    setVisible,
    fetchKanbanColumns,
    kanbanCols,
    fetchData
  } = useBoxContext();

  const sortedKanbanCols = kanbanCols
    .sort((a, b) => {
      return b.index - a.index;
    })
    .reverse();

  const onClose = () => {
    setVisible(false);
  };

  useEffect(() => {
    (async () => {
      await fetchData();
    })();
  }, [fetchData]);

  useEffect(() => {
    (async () => {
      await fetchKanbanColumns();
    })();
  }, []);

  return (
    <>
      <Row justify="space-between" gutter={[8, 16]}>
        <Col>
          <Search
            allowClear
            placeholder={t('placeholder.search')}
            defaultValue={searchValue}
            onSearch={(value) => searchResource(value)}
            style={{ marginLeft: '-4px' }}
          />
        </Col>
        <Col>
          <Button type="primary" onClick={() => setVisible(true)}>
            {t('buttons.create')}
            <PlusOutlined />
          </Button>
        </Col>
      </Row>
      <KanbanWrapper>
        <div
          style={{
            width: 'fit-content',
            display: 'flex',
            justifyContent: 'left',
            flexWrap: 'nowrap'
          }}
        >
          {sortedKanbanCols
            .filter((col) => col.show)
            .map((col) => (
              <KanbanColumn column={col} key={col.label} />
            ))}
        </div>
      </KanbanWrapper>
      <EditDrawer visible={visible} dealId={deal} close={onClose} />
    </>
  );
};

export default KanbanBoard;
