import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Card, Button, Divider, Form, message, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { formItemLayout } from '../../../utils/constants/formLayout';
import useAuthContext from '../../../contexts/AuthContext';
import ItemTable from './ItemTable';
import { getUpdatedModuleData } from './utils/getUpdatedModuleData';

const CreateCreditNote = () => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [editingKey, setEditingKey] = useState('');
  const { customerId, ref, sessionId, invoiceId } = useParams();
  const history = useHistory();
  const [moduleData, setModuleData] = useState([]);
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const isEditing = (record) => record.key === editingKey;

  const save = async (key) => {
    try {
      const row = await form.validateFields();

      const rowWithTotalHT = { ...row, total_ht: row.quantity * row.rate };

      const newData = [...moduleData];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...rowWithTotalHT
        });
        setModuleData(newData);
        setEditingKey('');
      } else {
        newData.push(rowWithTotalHT);
        setModuleData(newData);
        setEditingKey('');
      }
    } catch (e) {
      message(e);
    }
  };

  const edit = (record) => {
    form.setFieldsValue({ name: '', age: '', address: '', ...record });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const getCreditNotes = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/credit-notes`
      });
      return data;
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
    return null;
  };

  const getInvoice = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/invoices/${invoiceId}`
      });

      if (data) {
        await getUpdatedModuleData(
          data,
          getCreditNotes,
          setModuleData,
          message
        );
      }
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getInvoice();
      await getCreditNotes();
    })();
  }, []);

  const createCreditNote = async (body) => {
    try {
      await dispatchAPI('POST', { url: '/credit-notes', body });
      message.success(t('sessions.invoices.messages.add_credit_note_success'));
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
  };

  const onSubmit = async (values) => {
    if (selectedRowKeys.length) {
      const selectedItems = selectedRowKeys.map((key) =>
        moduleData.find((item) => item.key === key)
      );

      const total_ht = selectedItems.reduce((sum, item) => {
        return sum + item.total_ht;
      }, 0);

      const total_ttc = selectedItems.reduce((sum, item) => {
        const product = item.total_ht + (item.total_ht * item.tva) / 100;
        return sum + product;
      }, 0);

      const body = {
        invoice: invoiceId,
        session: sessionId,
        customer: customerId,
        items: selectedItems,
        total_ht,
        total_ttc,
        ref,
        emission_date: new Date(),
        ...values
      };

      await createCreditNote(body);
      history.goBack();
    } else {
      message.error(t('sessions.invoices.messages.select_items'));
    }
  };

  return (
    <>
      <Form {...formItemLayout} onFinish={onSubmit} form={form}>
        <Divider orientation="left">
          <span>{t('sessions.invoices.create_credit_note')}</span>
        </Divider>
        <Card bordered={false} className="card-no-border">
          <ItemTable
            setSelectedRowKeys={setSelectedRowKeys}
            selectedRowKeys={selectedRowKeys}
            moduleData={moduleData}
            isEditing={isEditing}
            save={save}
            cancel={cancel}
            edit={edit}
            editingKey={editingKey}
            form={form}
          />
        </Card>
        <Row justify="center">
          <Col>
            <Button
              style={{ float: 'right', marginRight: 9 }}
              type="link"
              danger
              onClick={() => history.goBack()}
            >
              {t('sessions.invoices.cancel')}
            </Button>
          </Col>
          <Col>
            <Button
              style={{ float: 'right', marginRight: 9 }}
              htmlType="submit"
              type="add"
            >
              {t('sessions.invoices.save')}
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default CreateCreditNote;
