import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Drawer, Form, message, Typography } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import useGenerateFormItem from '../../utils/GenerateFormItem';
import { formItemLayout } from '../../utils/constants/formLayout';
import useAuthContext from '../../contexts/AuthContext';
import useFields from './fields';

const { Title } = Typography;

const NewSkillDrawer = ({ isVisible, setVisibilitySkillDrawer, getSkills }) => {
  const [form] = Form.useForm();
  const { fields, isFieldsLoading } = useFields();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const generateFields = useGenerateFormItem();

  const cancelButton = () => {
    setVisibilitySkillDrawer();
  };

  const onFinish = async values => {
    try {
      // Add the type to the JSON body (companies or suppliers)
      // values.type = type ? type : 'customers';

      await dispatchAPI('POST', {
        url: '/skills',
        body: values,
      });
      message.success(t('skills.message.success.new'));
      form.setFieldsValue({ name: '', description: '' });
      await getSkills();
      setVisibilitySkillDrawer();
    } catch (e) {
      message.error(t('skills.message.error.new'));
    }
  };

  const onFinishFailed = error => {
    message.error(t('companies.message.error', error));
  };

  return (
    <Drawer
      visible={isVisible}
      onClose={() => {
        setVisibilitySkillDrawer();
      }}
      width="30%"
      className="new-skill-drawer">
      <Col style={{ width: '100%' }}>
        <Title level={3}>{t(`skills.form.title.create`)}</Title>
      </Col>
      <Form
        form={form}
        {...formItemLayout}
        name="test"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        style={{ width: '100%' }}>
        {fields.map(field => generateFields('skills' || 'skills', field))}
        <Button
          type="add"
          htmlType="submit"
          style={{ float: 'right', margin: '10px 0 0 10px' }}>
          {t('buttons.save')} <CheckOutlined />
        </Button>
        <Button
          onClick={cancelButton}
          type="link"
          danger
          style={{
            float: 'right',
            marginTop: 10,
          }}>
          {`${t('buttons.cancel')}`}
          <CloseOutlined />
        </Button>
      </Form>
    </Drawer>
  );
};

export default NewSkillDrawer;
