export const listItemBefore = [
  'Action de préformation et de préparation à la vie professionnelle',
  "Action d'adaptation et de développement des compétences des salariés",
  "Action permettant de faire valider les acquis de l'expérience",
  "Action de promotion de la mixité dans les entreprises, de sensibilisation à la lutte contre les stéréotypes sexistes et pour l'égalité professionnelle entre les femmes et les hommes",
  'Action de promotion professionnelle',
  'Action de prévention',
  'Action de conversion',
  "Action d'acquisition, d'entretien ou de perfectionnement des connaissances",
  "Action de formation continue relative à la radioprotection des personnes prévues à l'article L. 1333-11 du code de la santé publique",
  "Action de formation relative à l'économie et à la gestion de l'entreprise",
  "Action de formation relative à l'intéressement, à la participation et aux dispositifs d'épargne salariale et d'actionnariat salarié",
  'Action permettant de réaliser un bilan de compétences',
  'Action permettant aux travailleurs de faire valider les acquis de leur expérience',
  "Action d'accompagnement, d'information et de conseil dispensée aux créateurs ou repreneurs d'entreprises agricoles, artisanales, commerciales ou libérales, exerçant ou non une activité",
  "Action de lutte contre l'illettrisme et l'apprentissage de la langue française",
  'Action de formation continue relative au développement durable et à la transition énergétique',
];

export const listItemAfter = [
  'Action de formation',
  'Bilan de compétences',
  "Action permettant de faire valider les acquis de l'expérience",
  "Action de formation par l'apprentissage",
];

export const listTrainingDiploma = [
  'Aucun',
  'Certificat de qualification professionnelle (CQP) sans niveau de qualification enregisté au RNCP',
  'CQP non enregistré au RNCP ou au RS',
  'Niveau II',
  'Niveau III (BEP,CAP)',
  'Niveau IV (BAC professionnel,BT,BP,BM)',
  'Niveau V (BTS,DUT,écoles de formation sanitaires et sociale)',
  "Niveau VI à VIII (Licence,Master,diplôme d'ingénieur,Doctorat",
  'Certification (dont CQP) ou habilitation enregistrée au repertoire spécifique (RS)',
  "(Formations anciennes) Formations visant une certification et/ou une habilitation inscrite à l'inventaire de la CNCP",
];

export const listStudyLevel = [
  'BAC',
  'BT',
  'BP',
  'BM',
  'BAC professionnel',
  'Licence professionnelle',
  'Master',
  'DUT',
  'BAC +2',
  'BAC +3',
  'BAC +4',
  'BAC +5',
  'Doctorat',
  'BEP',
  'CAP',
  'BTS',
];
