const advantagesLabels = [
  "Nombre d'utilisateurs",
  'Nombre de formateurs',
  'Gestion administrative de A à Z',
  'Nombre de stagiaires / an',
  'CRM et catalogue en ligne',
  'Emargement numérique',
  'Stockage',
  'Sans engagement de durée',
  'Compte gratuit sur 15 jours'
];

export default advantagesLabels;
