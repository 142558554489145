import React from 'react';
import { Skeleton, Typography } from 'antd';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import useAccountingContext from '../../AccountingContext';

const Container = styled.div`
  background-color: var(--contrastBackground);
  padding: 10px;
  minheight: 150px;
`;
const { Title } = Typography;

const Organization = ({ isLoading, display }) => {
  const { t } = useTranslation();
  const { collection, organization } = useAccountingContext();

  return (
    <Container>
      <Skeleton loading={isLoading} paragraph={{ rows: 1, width: '50%' }}>
        {display && organization && (
          <>
            <Title level={5} style={{ marginBottom: '0', color: 'lightgrey' }}>
              {t(`${collection}.show.supplier`)}
            </Title>
            <Title level={3} style={{ marginTop: '0' }}>
              {organization.name}
            </Title>
            {organization.channel_number} {organization.address_details}
            <br />
            {organization.postal_code} {organization.city}
          </>
        )}
      </Skeleton>
    </Container>
  );
};

Organization.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  display: PropTypes.bool.isRequired,
};

export default Organization;
