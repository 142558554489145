import moment from 'moment';

const Columns = (t) => [
  {
    title: t('subscriptions.show.title'),
    key: 'organization',
    dataIndex: 'organization',
    render: (organization) => organization?.name,
    sorter: true
  },
  {
    title: t('subscriptions.show.type_subscription'),
    key: ' organization',
    dataIndex: 'organization',
    render: (organization) =>
      organization?.subscription
        ? organization?.subscription?.type ||
          t('subscriptions.datatable.columns.status.not_subscribed')
        : t('subscriptions.datatable.columns.status.none'),

    sorter: true
  },
  {
    title: t('subscriptions.show.status'),
    key: 'organization',
    dataIndex: 'organization',
    render: (organization) =>
      organization?.subscription
        ? t(
            `subscriptions.datatable.columns.status.${organization?.subscription
              ?.status || 'canceled'}`
          )
        : t('subscriptions.datatable.columns.status.not_subscribed')
  },
  {
    title: t('subscriptions.show.creation_date'),
    key: 'organization',
    dataIndex: 'organization',
    render: (organization) =>
      organization?.subscription &&
      moment(organization?.subscription?.created_at).format('DD/MM/YYYY')
  },
  {
    title: t('subscriptions.show.termination_date'),
    key: 'organization',
    dataIndex: 'organization',
    render: (organization) =>
      organization?.date_subcription_canceled
        ? moment(organization?.date_subcription_canceled).format('DD/MM/YYYY')
        : '-'
  }
];

export default Columns;
