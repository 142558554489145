import React from 'react';
import PropTypes from 'prop-types';
import { Card, Row, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import IndicatorCA from '../IndicatorCA';

const Marge = ({
  isLoading,
  indicatorsColors,
  purpose,
  dashboardData,
  monthTab
}) => {
  const { t } = useTranslation();
  return (
    <Card
      bordered={false}
      headStyle={{
        textAlign: 'left',
        color: indicatorsColors[purpose] || 'black'
      }}
      title={t(`dashboards.${purpose}.title`)}
      className="indicator-card indicator-card-large"
    >
      <Row>
        <Spin spinning={isLoading}>
          <IndicatorCA
            totalYear={
              dashboardData?.totalThisYear !== 0
                ? dashboardData?.totalThisYear -
                  dashboardData?.totalInterThisYear -
                  dashboardData?.totalCreditNoteThisYear
                : 0
            }
            totalLastYear={
              dashboardData?.totalLastYear !== 0
                ? dashboardData?.totalLastYear -
                  dashboardData?.totalInterLastYear -
                  dashboardData?.totalCreditNoteLastYear
                : 0
            }
            margin={
              (dashboardData?.totalThisYear -
                dashboardData?.totalInterThisYear -
                dashboardData?.totalCreditNoteThisYear) /
              dashboardData?.totalThisYear
            }
          />
        </Spin>
        <Spin spinning={isLoading}>
          <IndicatorCA
            totalYear={
              dashboardData?.totalThisMonth !== 0
                ? dashboardData?.totalThisMonth -
                  dashboardData?.totalInterThisMonth -
                  dashboardData?.totalCreditNoteThisMonth
                : 0
            }
            totalLastYear={
              dashboardData?.totalLastMonth !== 0
                ? dashboardData?.totalLastMonth -
                  dashboardData?.totalInterLastMonth -
                  dashboardData?.totalCreditNoteLastMonth
                : 0
            }
            month={monthTab}
            margin={
              (dashboardData?.totalThisMonth -
                dashboardData?.totalInterThisMonth -
                dashboardData?.totalCreditNoteThisMonth) /
              dashboardData?.totalThisMonth
            }
          />
        </Spin>
      </Row>
    </Card>
  );
};

Marge.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  indicatorsColors: PropTypes.shape({}).isRequired,
  purpose: PropTypes.string.isRequired,
  dashboardData: PropTypes.shape({
    totalThisYear: PropTypes.number,
    totalLastYear: PropTypes.number,
    totalThisMonth: PropTypes.number,
    totalLastMonth: PropTypes.number,
    totalInterLastYear: PropTypes.number,
    totalInterThisMonth: PropTypes.number,
    totalInterLastMonth: PropTypes.number,
    totalInterThisYear: PropTypes.number,
    totalCreditNoteLastYear: PropTypes.number,
    totalCreditNoteThisMonth: PropTypes.number,
    totalCreditNoteLastMonth: PropTypes.number,
    totalCreditNoteThisYear: PropTypes.number
  }).isRequired,
  monthTab: PropTypes.arrayOf.isRequired
};

export default Marge;
