import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Space,
  Collapse,
  Form,
  Input,
  Select,
  Row,
  message
} from 'antd';
import { Link } from 'react-router-dom';
import TextArea from 'antd/es/input/TextArea';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import useGenerateFormItem from '../../../../utils/GenerateFormItem';
import useAuthContext from '../../../../contexts/AuthContext';
import {
  formItemLayout,
  noLabelLayout
} from '../../../../utils/constants/formLayout';
import { ContentCustom } from '../../../../components';
import { ButtonAdd } from '../../../../utils/constants/customButton';
import ProgramBlocsTable from '../programBlocsTable';
import TrainingContent from '../trainingContent';
import TraineesProfils from './TraineesProfilsContentCustom';
import ExecutionTrack from './ExecutionTrackContentCustom';
import useFields from '../fields';

const { Panel } = Collapse;

const ProgramCollapse = ({
  trainingFieldValue,
  setTrainingFieldValue,
  setSelectedSkills,
  selectedSkills,
  form,
  id,
  trainingContent,
  setTrainingContent,
  drawerVisibility,
  isFieldsLoading,
  skills
}) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const generateFields = useGenerateFormItem();
  const [program, setProgram] = useState([]);
  const {
    modalityFields,
    trainingActionTypeFields,
    certificationsModalityFields
  } = useFields(trainingFieldValue, setTrainingFieldValue);

  const getProgram = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/programs/${id}?populate=blocs`
      });
      setProgram(data);
    } catch (e) {
      if (e.response) message(e.response);
    }
  };

  const onSelectSkill = (value) => {
    const list = [...selectedSkills];
    list.push(value);
    setSelectedSkills(list);
  };

  const onDeselectSkill = (value) => {
    setSelectedSkills(selectedSkills.filter((sk) => sk !== value));
  };

  const onChangePanel = () => {
    const formValues = form.getFieldsValue();
    if (formValues.educational_goals) {
      form.setFieldsValue({
        educational_goals: formValues.educational_goals.map((el) =>
          el === null || el === undefined ? '' : el
        )
      });
    }
    if (formValues.trainees_profile) {
      const trainees_profile = {
        customers: formValues.trainees_profile.customers.map((el) =>
          el === null || el === undefined ? '' : el
        ),
        prerequisite: formValues.trainees_profile.prerequisite.map((el) =>
          el === null || el === undefined ? '' : el
        )
      };
      form.setFieldsValue({ trainees_profile });
    }
    if (formValues.execution_track) {
      const execution_track = {
        results_track: formValues.execution_track.results_track?.map((el) =>
          el === null || el === undefined ? '' : el
        ),
        educational_resources: formValues.execution_track.educational_resources?.map(
          (el) => (el === null || el === undefined ? '' : el)
        )
      };
      form.setFieldsValue({
        ...formValues.execution_track,
        execution_track
      });
    }
  };

  useEffect(() => {
    (async () => {
      await getProgram();
    })();
  }, [id]);

  return (
    <Collapse
      accordion
      onChange={(value) => onChangePanel(value)}
      className="program-fields-collapse"
    >
      <Panel header={t('programs.form.modality')} key="1">
        <ContentCustom>
          {modalityFields.map((field) =>
            generateFields('programs' || 'programs', field)
          )}
        </ContentCustom>
      </Panel>
      <Panel header={t('programs.form.training_action_type')} key="2">
        <ContentCustom>
          <Form.Item name="training_action_type">
            {trainingActionTypeFields.map((field) =>
              generateFields('programs' || 'programs', field)
            )}
          </Form.Item>
        </ContentCustom>
      </Panel>
      <Panel key="12" header={t('programs.form.blocs')}>
        <ProgramBlocsTable program={program} getProgram={getProgram} />
      </Panel>
      <Panel key="3" header={t('programs.form.description')}>
        <Form.Item noStyle name={['description']}>
          <TextArea />
        </Form.Item>
      </Panel>
      <Panel key="4" header={t('programs.form.educational_goals')}>
        <Form.Item
          {...noLabelLayout}
          {...formItemLayout}
          name={['educational_goals']}
        >
          <Form.List name={['educational_goals']}>
            {(fields, { add, remove }) => (
              <>
                {(fields.length
                  ? fields
                  : [{ fieldKey: 0, isListField: true, key: 0, name: 0 }]
                ).map((field) => (
                  <Space
                    key={field.key}
                    style={{ display: 'flex', marginBottom: 8 }}
                    align="baseline"
                  >
                    <Form.Item
                      {...field}
                      fieldKey={[field.fieldKey, 'educational_goals']}
                    >
                      <Input />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(field.name)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    size="small"
                    type="primary"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  />
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form.Item>
      </Panel>
      <Panel header={t('programs.form.trainees_profile')} key="5">
        <TraineesProfils
          noLabelLayout={noLabelLayout}
          formItemLayout={formItemLayout}
        />
      </Panel>
      <Panel key="11" header={t('programs.form.team_description')}>
        <Form.Item
          label={t('programs.form.team_description')}
          name={['execution_track', 'team_description']}
        >
          <Input />
        </Form.Item>
      </Panel>
      <Panel key="6" header={t('programs.form.training_content')}>
        <TrainingContent
          trainingContent={trainingContent}
          setTrainingContent={setTrainingContent}
        />
      </Panel>
      <Panel key="7" header={t('programs.form.execution_track')}>
        <ExecutionTrack noLabelLayout={noLabelLayout} />
      </Panel>
      <Panel key="8" header={t('programs.form.certification_modality')}>
        <ContentCustom>
          {certificationsModalityFields.map((field) =>
            generateFields('programs' || 'programs', field)
          )}
        </ContentCustom>
      </Panel>
      <Panel key="9" header={t('programs.form.quality')}>
        <Form.Item
          label={t('programs.form.titles.results_indicators')}
          noStyle
          name={['quality']}
        >
          <TextArea />
        </Form.Item>
      </Panel>
      <Panel key="10" header={t('programs.form.skills_domain')}>
        <Form.Item name={['skills']} label={t('skills.title')}>
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            allowClear
            onSelect={onSelectSkill}
            onDeselect={onDeselectSkill}
            value={selectedSkills}
            mode="multiple"
            options={skills.map((skill) => ({
              label: skill.name,
              value: skill._id
            }))}
            loading={isFieldsLoading}
          />
          <Row style={{ marginTop: 12 }} align="middle" justify="space-between">
            <Link to={{ pathname: '/skills' }}>
              <span>{t('contributors.form.manage_skills')}</span>
            </Link>
            <ButtonAdd onClick={drawerVisibility} />
          </Row>
        </Form.Item>
      </Panel>
    </Collapse>
  );
};

ProgramCollapse.propTypes = {
  trainingFieldValue: PropTypes.arrayOf({}).isRequired,
  setTrainingFieldValue: PropTypes.func.isRequired,
  skills: PropTypes.arrayOf({}).isRequired,
  isFieldsLoading: PropTypes.bool.isRequired,
  drawerVisibility: PropTypes.func.isRequired,
  trainingContent: PropTypes.arrayOf({}).isRequired,
  setTrainingContent: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  selectedSkills: PropTypes.arrayOf({}).isRequired,
  setSelectedSkills: PropTypes.func.isRequired,
  form: PropTypes.shape({
    getFieldsValue: PropTypes.func,
    setFieldsValue: PropTypes.func
  }).isRequired
};

export default ProgramCollapse;
