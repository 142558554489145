import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';

import { Pie, measureTextWidth } from '@ant-design/charts';

const SimpleDonutChart = ({ data, totalDepa, totalVenir, totalRegle }) => {
  const renderStatistic = (containerWidth, text, style) => {
    const _measureTextWidth = (0, measureTextWidth)(text, style);
    const textWidth = _measureTextWidth.width;
    const textHeight = _measureTextWidth.height;
    const R = containerWidth / 2;
    let scale = 1;
    if (containerWidth < textWidth) {
      scale = Math.min(
        Math.sqrt(
          Math.abs(
            Math.pow(R, 2) /
              (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2))
          )
        ),
        1
      );
    }
    const textStyleStr = 'width:'.concat(containerWidth, 'px;');
    return '<div style="'
      .concat(textStyleStr, ';font-size:')
      .concat(scale, 'em;line-height:')
      .concat(scale < 1 ? 1 : 'inherit', ';">')
      .concat(text, '</div>');
  };

  const config = {
    appendPadding: 10,
    data,
    legend: false,
    angleField: 'value',
    colorField: 'type',
    radius: 1,
    innerRadius: 0.8,
    meta: {
      value: {
        formatter: function formatter(v) {
          return ''.concat(v, ' \xA5');
        }
      }
    },
    label: {
      type: 'inner',
      offset: '-50%',
      style: { textAlign: 'center' },
      autoRotate: false,

      content: ''
    },
    pieStyle: (_ref) => {
      switch (_ref.type) {
        case '1-8j':
          return { fill: '#FBC28A' };

        case '9-30j':
          return { fill: '#F76B00' };

        case '+30j':
          return { fill: '#AB622B' };

        case '0-8j':
          return { fill: '#ADC1E1' };

        case '9-30j ':
          return { fill: '#4F71A8' };

        case '+30j ':
          return { fill: '#2B3E5C' };
        default:
          return { fill: '#f5f5f5' };
      }
    },
    statistic: {
      title: {
        offsetY: -4,
        customHtml: function customHtml(container, view, datum) {
          const _container$getBoundin = container.getBoundingClientRect();
          const width = _container$getBoundin.width;
          const height = _container$getBoundin.height;
          const d = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2));
          const text = datum ? datum.type : 'Total';
          return renderStatistic(d, text, { fontSize: 28 });
        }
      },
      content: {
        offsetY: 4,
        style: { fontSize: '32px' },
        customHtml: function customHtml(container, view, datum, data) {
          const _container$getBoundin2 = container.getBoundingClientRect();
          const width = _container$getBoundin2.width;
          const text = datum
            ? ''.concat(datum.value).concat(' €')
            : ''
                .concat(
                  data.reduce((r, d) => {
                    return r + d.value;
                  }, 0)
                )
                .concat(' €');
          return renderStatistic(width, text, { fontSize: 32 });
        }
      }
    },
    interactions: [
      { type: 'element-selected' },
      { type: 'element-active' },
      { type: 'pie-statistic-active' }
    ]
  };
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center'
      }}
    >
      <Row>
        <Col span={7}>
          <Row style={{ height: '80%' }}>
            <div
              style={{
                marginTop: '50%',
                textAlign: 'end',
                width: '100%',
                marginRight: 16
              }}
            >
              <h3 style={{ marginBottom: 0 }}>Dépassés</h3>
              <h1 style={{ fontSize: 24, fontWeight: 600 }}>
                {`${totalDepa / 1000} K€`}
              </h1>
            </div>
          </Row>
          <Row style={{ height: '7%' }}>
            <Col
              span={8}
              style={{
                backgroundColor: '#FBC28A',
                color: 'white',
                textAlign: 'center',
                paddingTop: 3
              }}
            >
              1-8j
            </Col>
            <Col
              span={8}
              style={{
                backgroundColor: '#F76B00',
                color: 'white',
                textAlign: 'center',
                paddingTop: 3
              }}
            >
              9-30j
            </Col>
            <Col
              span={8}
              style={{
                backgroundColor: '#AB622B',
                color: 'white',
                textAlign: 'center',
                paddingTop: 3
              }}
            >
              +30j
            </Col>
          </Row>
          <Row style={{ height: '7%' }}>
            <Col span={8} style={{ textAlign: 'center' }}>
              {`${totalRegle?.total_1} €`}
            </Col>
            <Col span={8} style={{ textAlign: 'center' }}>
              {`${totalRegle?.total_8} €`}
            </Col>
            <Col span={8} style={{ textAlign: 'center' }}>
              {`${totalRegle?.total_30} €`}
            </Col>
          </Row>
        </Col>
        <Col span={10}>
          <Pie {...config} />
        </Col>
        <Col span={7}>
          <Row style={{ height: '80%' }}>
            <div
              style={{
                marginTop: '50%',
                textAlign: 'start',
                width: '100%',
                marginLeft: 16
              }}
            >
              <h3 style={{ marginBottom: 0 }}>A venir</h3>
              <h1 style={{ fontSize: 24, fontWeight: 600 }}>
                {`${totalVenir / 1000} K€`}
              </h1>
            </div>
          </Row>
          <Row style={{ height: '7%' }}>
            <Col
              span={8}
              style={{
                backgroundColor: '#ADC1E1',
                color: 'white',
                textAlign: 'center',
                paddingTop: 3
              }}
            >
              0-8j
            </Col>
            <Col
              span={8}
              style={{
                backgroundColor: '#4F71A8',
                color: 'white',
                textAlign: 'center',
                paddingTop: 3
              }}
            >
              9-30j
            </Col>
            <Col
              span={8}
              style={{
                backgroundColor: '#2B3E5C',
                color: 'white',
                textAlign: 'center',
                paddingTop: 3
              }}
            >
              +30j
            </Col>
          </Row>
          <Row style={{ height: '7%' }}>
            <Col span={8} style={{ textAlign: 'center' }}>
              {`${totalRegle?.total1} €`}
            </Col>
            <Col span={8} style={{ textAlign: 'center' }}>
              {`${totalRegle?.total8} €`}
            </Col>
            <Col span={8} style={{ textAlign: 'center' }}>
              {`${totalRegle?.total30} €`}
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

SimpleDonutChart.propTypes = {
  data: PropTypes.shape({
    reduce: PropTypes.func
  }).isRequired,
  totalDepa: PropTypes.number.isRequired,
  totalVenir: PropTypes.number.isRequired,
  totalRegle: PropTypes.shape({
    total_1: PropTypes.number,
    total_8: PropTypes.number,
    total_30: PropTypes.number,
    total1: PropTypes.number,
    total8: PropTypes.number,
    total30: PropTypes.number
  }).isRequired
};

export { SimpleDonutChart };
