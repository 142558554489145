import React from 'react';
import useColumns from './columns';
import SimpleResourceLandingLayout from '../../../components/layouts/SimpleResourceLandingLayout';

const ListCompanies = () => {
  const columns = useColumns();
  const headers = [
    {
      label: 'name',
      key: 'name'
    },
    {
      label: 'channel_number',
      key: 'channel_number'
    },
    {
      label: 'street',
      key: 'street'
    },
    {
      label: 'address_details',
      key: 'address_details'
    },
    {
      label: 'postal_code',
      key: 'postal_code'
    },
    {
      label: 'city',
      key: 'city'
    },
    {
      label: 'email',
      key: 'email'
    },
    {
      label: 'siret_number',
      key: 'siret_number'
    },
    {
      label: 'ape_number',
      key: 'ape_number' || ''
    },
    {
      label: 'representative',
      key: 'representative'
    },
    {
      label: 'tva_company',
      key: 'tva_company' || ''
    },
    {
      label: 'organization',
      key: 'organization.name'
    }
  ];

  return (
    <SimpleResourceLandingLayout
      columns={columns}
      resourceName="companies"
      withProjectionFields
      resourceModelName="Company"
      headers={headers}
      templateType="companies_import_template"
    />
  );
};

export default ListCompanies;
