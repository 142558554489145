import { createGlobalStyle } from 'styled-components';

const LightTheme = createGlobalStyle`
  body {
    --clientColor: #fff;
    --primaryColor: #FF6D00;
    --addColor: #01B775;
    --itemHoverColor: var(--primaryColor);
    --componentBackground: #fff;
    --secondaryHover: var(--componentBackground);
    --subMenuBackground: var(--componentBackground);
    --menuDropdownBackground: var(--componentBackground);
    --pickerDropdown: var(--componentBackground);
    --primaryHover:  rgba(255, 109, 0, 0.2);
    --secondaryColor: #ff4d4f;
    --disabledColor: #bfbfbf;
    --bodyBackground: #fff;
    --textColor: #000;
    --textColorSecondary: #2b2b2b;
    --itemActiveBackground: #e6f7ff;
    --itemHoverBackground: #f0f0f0;
    --borderColor: #e5e5e5;
    --contrastBackground: #fafafa;
    --opacitySVG: 1;
    --errorColor: #F12B00;
    --primaryColorA04
    --primaryColorA07
    --primaryContrast
    --tabsGray
    --textColorInvert
    --textColorHover
    --textColorA015
    --textColorA075
    --borderColorA06
    --disabledColorA04
    --disabledColorA06
    --itemActiveColor
    --logo
    --logoSmall
    --error100
    --errorA08
  }
`;

export default LightTheme;
