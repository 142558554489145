import PropTypes from 'prop-types';

const getCGVTemplates = async ({
  setCGVTemplates,
  notification,
  dispatchAPI,
  user
}) => {
  try {
    const { data } = await dispatchAPI('GET', {
      url: `/files?type=cgv&organization=${user.organization._id}`
    });
    setCGVTemplates(data);
  } catch (e) {
    if (e.response) notification(e.response);
  }
};

getCGVTemplates.propTypes = {
  setCGVTemplates: PropTypes.func.isRequired,
  notification: PropTypes.func.isRequired,
  dispatchAPI: PropTypes.func.isRequired,
  user: PropTypes.arrayOf({}).isRequired
};

export default getCGVTemplates;
