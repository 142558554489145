import React from 'react';
import PropTypes from 'prop-types';
import { Card, Divider, List } from 'antd';
import { useTranslation } from 'react-i18next';
import ListItemsBPF from './ListItemsBPF';

const PedagogicReport = ({ pedagogicData }) => {
  const { t } = useTranslation();

  return (
    <>
      <Divider orientation="left" className="bpf-sub-divider">
        <span>
          {`F-1. ${t(
            'dashboards.pedagogic_report.organization_trainees_type'
          )}`}
        </span>
      </Divider>
      <Card>
        <ListItemsBPF items={pedagogicData && pedagogicData.trainees_type} />
      </Card>

      <Divider orientation="left" className="bpf-sub-divider">
        <span>
          {`F-2. ${t('dashboards.pedagogic_report.subcontracted_activity')}`}
        </span>
      </Divider>
      <Card>
        <ListItemsBPF items={pedagogicData && pedagogicData.sub_contracting} />
      </Card>

      <Divider orientation="left" className="bpf-sub-divider">
        <span>{`F-3. ${t('dashboards.pedagogic_report.general_goal')}`}</span>
      </Divider>
      <Card>
        <List
          size="small"
          dataSource={pedagogicData && pedagogicData.general_goals}
          renderItem={(item) => (
            <>
              <List.Item>
                <List.Item.Meta title={t(`bpf.${item.title}`)} />
                <span
                  style={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    float: 'right'
                  }}
                >
                  <span style={{ fontSize: 11, textTransform: 'uppercase' }}>
                    {t('dashboards.number')}
                  </span>
                  <span style={{ fontWeight: 700 }}>{item.quantity}</span>
                </span>
                <Divider type="vertical" />
                <span
                  style={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    float: 'right'
                  }}
                >
                  <span style={{ fontSize: 11, textTransform: 'uppercase' }}>
                    {t('dashboards.total_hours')}
                  </span>
                  <span style={{ fontWeight: 700 }}>{item.total_hours}</span>
                </span>
              </List.Item>
              {item.title === 'RNCP_DIPLOMA' && (
                <ListItemsBPF marginLeft={30} items={item.levels} />
              )}
            </>
          )}
        />
      </Card>
    </>
  );
};

PedagogicReport.propTypes = {
  pedagogicData: PropTypes.shape({
    trainees_type: PropTypes.arrayOf({}),
    sub_contracting: PropTypes.arrayOf({}),
    general_goals: PropTypes.arrayOf({})
  }).isRequired
};

export default PedagogicReport;
