const checkSubscriptionPermissions = (type, typeNb, subscription) => {
  let check = true;
  switch (type) {
    case 'contributors':
      if (typeNb >= subscription.contributors_nb) {
        check = false;
      }
      break;
    case 'trainees':
      if (typeNb >= subscription.trainees_nb) {
        check = false;
      }
      break;
    case 'users':
      if (typeNb >= subscription.users_nb) {
        check = false;
      }
      break;
    case 'storage':
      if (typeNb === subscription.storage_capacity) {
        check = false;
      }
  }
  return check;
};

export default checkSubscriptionPermissions;
