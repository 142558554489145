export const setUniqueFiles = ({ files }) => {
  if (files) {
    files.sort((a, b) => new Date(b.date) - new Date(a.date));

    const uniqueFilesMap = new Map();

    files.forEach((file) => {
      let key;
      if (file.user) {
        key = `${file.user._id}-${file.type}`;
      } else if (file.type === 'programs') {
        key = `no-user-${file.type}`;
      }

      if (!uniqueFilesMap.has(key)) {
        uniqueFilesMap.set(key, file);
      }
    });

    const uniqueFiles = Array.from(uniqueFilesMap.values());

    return uniqueFiles;
  }
  return null;
};
