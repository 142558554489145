import React from 'react';
import PropTypes from 'prop-types';
import { Card, Row, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import IndicatorCA from '../IndicatorCA';

const CaHT = ({
  isLoading,
  indicatorsColors,
  purpose,
  dashboardData,
  monthTab
}) => {
  const { t } = useTranslation();
  return (
    <Card
      bordered={false}
      headStyle={{
        textAlign: 'left',
        color: indicatorsColors[purpose] || 'black'
      }}
      title={t(`dashboards.${purpose}.title`)}
      className="indicator-card indicator-card-large"
    >
      <Row>
        <Spin spinning={isLoading}>
          <IndicatorCA
            totalYear={dashboardData?.totalThisYear}
            totalLastYear={dashboardData?.totalLastYear}
          />
        </Spin>
        <Spin spinning={isLoading}>
          <IndicatorCA
            totalYear={dashboardData?.totalThisMonth}
            totalLastYear={dashboardData?.totalLastMonth}
            month={monthTab}
          />
        </Spin>
      </Row>
    </Card>
  );
};

CaHT.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  indicatorsColors: PropTypes.shape({}).isRequired,
  purpose: PropTypes.string.isRequired,
  dashboardData: PropTypes.shape({
    totalThisYear: PropTypes.number,
    totalLastYear: PropTypes.number,
    totalThisMonth: PropTypes.number,
    totalLastMonth: PropTypes.number
  }).isRequired,
  monthTab: PropTypes.arrayOf.isRequired
};

export default CaHT;
