import { useTranslation } from 'react-i18next';

const useColumns = () => {
  const { t } = useTranslation();

  const headers = [
    {
      label: 'first_name',
      key: 'first_name'
    },
    {
      label: 'last_name',
      key: 'last_name'
    },
    {
      label: 'email',
      key: 'email'
    },
    {
      label: 'gender',
      key: 'gender',
      header_key: 'contributor_gender'
    },
    {
      label: 'phone_number.number',
      key: 'phone_number.number'
    },
    {
      label: 'phone_number.country_code',
      key: 'phone_number.country_code'
    },
    {
      label: 'number',
      key: 'number'
    },
    {
      label: 'street',
      key: 'street'
    },
    {
      label: 'additional',
      key: 'additional'
    },
    {
      label: 'postal_code',
      key: 'postal_code',
      extra_label: 'postcode'
    },
    {
      label: 'city',
      key: 'city'
    },
    {
      label: 'company',
      key: 'company'
    },
    {
      label: 'status',
      key: 'status',
      header_key: 'contributor_status'
    },
    {
      label: 'rate',
      key: 'rate'
    },
    {
      label: 'duration',
      key: 'duration',
      header_key: 'contributor_duration'
    },
    {
      label: 'tva',
      key: 'tva'
    },
    {
      label: 'activity_declaration_number',
      key: 'activity_declaration_number'
    },
    {
      label: 'skills',
      key: 'skills',
      multiple_data_key: 'name'
    },
    {
      label: 'skills_notes',
      key: 'skills_notes'
    },
    {
      label: 'notes',
      key: 'notes'
    },
    {
      label: 'organization',
      key: 'organization.name'
    }
  ];

  const columns = [
    {
      title: t('contributors.form.last_name'),
      dataIndex: 'last_name',
      sorter: true
    },
    {
      title: t('contributors.form.first_name'),
      dataIndex: 'first_name',
      sorter: true
    },
    {
      title: t('contributors.form.status'),
      dataIndex: 'status',
      render: (status) => (status ? t(`contributors.form.${status}`) : '-'),
      sorter: true
    },
    {
      title: t('contributors.form.nb_sessions'),
      dataIndex: 'nb_sessions',
      sorter: true
    }
  ];

  return { columns, headers };
};

export default useColumns;
