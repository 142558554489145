export const getEmails = async ({
  dispatchAPI,
  emailModel,
  setEmails,
  notification,
  user
}) => {
  try {
    const { data } = await dispatchAPI('GET', {
      url: `/emails?type=${emailModel}`
    });
    setEmails(data.filter((el) => el.organization === user.organization._id));
  } catch (e) {
    if (e.response) notification(e.response);
  }
};
