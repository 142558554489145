import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Input, message, Select, PageHeader, Layout } from 'antd';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import {
  formItemLayout,
  tailFormItemLayout
} from '../../../../utils/constants/formLayout';
import useAuthContext from '../../../../contexts/AuthContext';
import fetchData from './utils/fecthData';
import setRoutes from './utils/setRoutes';
import onFinish from './utils/onFinish';

const { Content } = Layout;
const { Option } = Select;

const CreateAndUpdateCharge = ({ purpose }) => {
  const [form] = Form.useForm();
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const path = location.pathname.split('/');
  const [companies, setCompanies] = useState([]);

  const routes = setRoutes({ t, path, purpose });

  const handleSubmit = (values) => {
    onFinish({
      values,
      purpose,
      dispatchAPI,
      path,
      message,
      history,
      t
    });
  };

  const onFinishFailed = () => {
    message.error(t('charges.message.error'));
  };
  const cancelButton = () => {
    history.goBack();
  };

  useEffect(() => {
    (async () => {
      await fetchData({
        dispatchAPI,
        setCompanies,
        purpose,
        path,
        form
      });
    })();
  }, []);

  return (
    <>
      <PageHeader
        breadcrumb={{
          routes,
          itemRender: ({ url, breadcrumbName }) => (
            <Link to={url}>{breadcrumbName}</Link>
          )
        }}
        title={`${t(`charges.${purpose}`)}`}
        ghost={false}
        onBack={() => history.goBack()}
      />
      <Content
        style={{
          marginTop: 2,
          padding: 24,
          backgroundColor: '#fff'
        }}
      >
        <Form
          form={form}
          {...formItemLayout}
          onFinish={handleSubmit}
          onFinishFailed={onFinishFailed}
          initialValues={{ remember: true }}
        >
          <Form.Item
            label={`${t('charges.form.name')}`}
            name="name"
            initialValue={null}
            rules={[
              {
                required: true,
                message: t('companies.message.empty_field')
              }
            ]}
          >
            <Input style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item
            label={`${t('charges.form.company')}`}
            name="company"
            initialValue={null}
          >
            <Input style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item label={`${t('charges.form.rate')}`} name="rate">
            <Input type="number" suffix="€" style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item
            label={`${t('charges.form.tva')}`}
            name="tva"
            initialValue={null}
          >
            <Input type="number" suffix="%" style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item
            label={`${t('charges.form.note')}`}
            name="note"
            initialValue={null}
          >
            <Input.TextArea style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item
            {...tailFormItemLayout}
            style={{ display: 'flex', justifyContent: 'end' }}
          >
            <Button
              type="primary"
              htmlType="submit"
              style={{
                background: 'rgb(71, 210, 103)',
                color: '#fff',
                border: 'rgb(71, 210, 103)',
                borderRadius: 3,
                float: 'right',
                marginLeft: 10
              }}
            >
              {t('buttons.form.save')}
              <CheckOutlined />
            </Button>
            <Button
              onClick={cancelButton}
              style={{
                background: '#fff',
                color: 'red',
                borderColor: 'red',
                borderRadius: 3,
                float: 'right'
              }}
            >
              {t('buttons.form.cancel')}
              <CloseOutlined />
            </Button>
          </Form.Item>
        </Form>
      </Content>
    </>
  );
};

export default CreateAndUpdateCharge;

CreateAndUpdateCharge.propTypes = {
  url: PropTypes.string.isRequired,
  purpose: PropTypes.string.isRequired,
  breadcrumbName: PropTypes.string.isRequired
};
