import { useTranslation } from 'react-i18next';

const useListContent = ({
  name,
  address,
  email,
  type,
  free_notes,
  email_contact
}) => {
  const { t } = useTranslation();
  return [
    {
      label: t('ExternalFounders.form.name'),
      content: name
    },
    {
      label: t('ExternalFounders.form.address'),
      content:
        `${address?.number || ''} ${address?.street ||
          ''} ${address?.additional || ''}` || '-'
    },
    {
      label: t('ExternalFounders.form.city'),
      content: address?.city
    },
    {
      label: t('ExternalFounders.form.postal_code'),
      content: address?.postal_code
    },
    {
      label: t('ExternalFounders.form.email'),
      content: email
    },
    {
      label: t('ExternalFounders.form.type'),
      content: type
    },
    {
      label: t('ExternalFounders.form.free_notes'),
      content: free_notes
    },
    {
      label: t('ExternalFounders.form.contact'),
      content: email_contact
    }
  ];
};

export default useListContent;
