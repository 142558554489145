import React from 'react';
import PropTypes from 'prop-types';
import { Card, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import DevisTable from '../DevisTable';

const LastDoc = ({ isLoading, indicatorsColors, purpose }) => {
  const { t } = useTranslation();
  return (
    <Card
      bordered={false}
      headStyle={{
        textAlign: 'left',
        color: indicatorsColors[purpose] || 'black'
      }}
      title={t(`dashboards.${purpose}.title`)}
      className="indicator-card indicator-card-large indicator-card-contains-table"
    >
      <Spin spinning={isLoading}>
        <DevisTable indicator />
      </Spin>
    </Card>
  );
};

LastDoc.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  indicatorsColors: PropTypes.shape({}).isRequired,
  purpose: PropTypes.string.isRequired
};

export default LastDoc;
