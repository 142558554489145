import React from 'react';
import CreateUpdateContainer from '../../components/CreateUpdateContainer/CreateUpdateContainer';
import useFields from './fields';

const CreateUpdateSkill = ({ purpose }) => {
  const { fields, isFieldsLoading } = useFields();

  const config = {
    onCreateResource: {
      setBody: data => ({ ...data }),
    },
  };

  return (
    <div>
      <CreateUpdateContainer
        resource="skills"
        fields={fields}
        loadingFields={isFieldsLoading}
        config={config}
        purpose={purpose}
        baseUrl="skills"
        tradKey="skills"
        withSubRoutes
      />
    </div>
  );
};
export default CreateUpdateSkill;
