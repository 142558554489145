import React, { createContext, useContext } from 'react';
import LightTheme from '../assets/styles/Theme/LightTheme';
import DarkTheme from '../assets/styles/Theme/DarkTheme';
import LightThemeSuperAdmin from '../assets/styles/Theme/LightThemeSuperAdmin';
import { useStateWithLocalStorage } from '../utils';
import useAuthContext from '../contexts/AuthContext';

const ThemeContext = createContext({});

export const ThemeContextProvider = ({ children }) => {
  const [darkMode, setDarkMode] = useStateWithLocalStorage('darkTheme', false);
  const { dispatchAPI, user } = useAuthContext();

  return (
    <ThemeContext.Provider value={{ darkMode, setDarkMode }}>
      {darkMode ? <DarkTheme /> : <LightTheme />}
      {user && user.role === 'admins:SUPER-ADMIN' && <LightThemeSuperAdmin />}
      {children}
    </ThemeContext.Provider>
  );
};

export default () => useContext(ThemeContext);
