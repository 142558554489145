import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Divider, List, message, Popconfirm } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { WarningOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import Button from 'antd/es/button';
import useAuthContext from '../../../contexts/AuthContext';

const ChargesTable = ({ session, setTotalCharge }) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [dataContri, setDataContri] = useState([]);
  const [charges, setCharges] = useState([]);
  const [bool, setBool] = useState(false);
  const history = useHistory();

  useEffect(() => {
    (async () => {
      const contributors = [];
      const { data } = await dispatchAPI('GET', {
        url: `/sessions/${session?._id}?populate=contributors.contributor`
      });
      data.contributors.forEach((el) => {
        contributors.push(el);
      });
      setDataContri(contributors);
      const filter = { session: session?._id };
      const charge = await dispatchAPI('GET', {
        url: `/charges?filter=${JSON.stringify(filter)}`
      });
      setCharges(charge.data);
    })();
  }, [session, bool]);

  const deleteCharge = async (id) => {
    try {
      await dispatchAPI('DELETE', { url: `/charges/${id}` });
      setBool(!bool);
    } catch (e) {
      message.error(e);
    }
  };

  useEffect(() => {
    let total = 0;
    dataContri.forEach((item) => {
      if (item.duration === 'DAY') {
        total += (item.rate || 0) * (item.training_days || 0);
      } else {
        total += item.rate || 0;
      }
    });
    charges.forEach((item) => (total += item.rate || 0));
    setTotalCharge(total);
  }, [dataContri, charges]);

  return (
    <>
      <div className="site-card_border-less-wrapper">
        <Card>
          <Button
            type="link"
            onClick={() =>
              history.push(`/sessions/show/${session._id}/charges/create`)
            }
          >
            {t('charges.buttons.create')}
          </Button>
          <Divider orientation="left" style={{ marginBottom: 0 }}>
            <span style={{ fontSize: 15 }}>{t('charges.contributors')}</span>
          </Divider>
          <List
            size="small"
            itemLayout="horizontal"
            dataSource={dataContri}
            renderItem={(item) =>
              item &&
              item.contributor && (
                <List.Item>
                  <span style={{ float: 'left' }}>
                    <div>
                      {`${item.contributor?.last_name} ${item.contributor?.first_name}`}
                    </div>
                  </span>
                  <span style={{ float: 'right' }}>
                    {item.duration === 'DAY' ? (
                      <>
                        <p>
                          {t('charges.ht', {
                            total: (
                              (item.rate || 0) * (item.training_days || 0)
                            ).toFixed(2)
                          })}
                        </p>
                        <p>
                          {t('charges.ttc', {
                            total: (
                              (item.rate || 0) *
                              (item.training_days || 0) *
                              (1 + (item.tva / 100 || 0))
                            ).toFixed(2)
                          })}
                        </p>
                      </>
                    ) : (
                      <>
                        <p>{t('charges.ht', { total: item.rate || 0 })}</p>
                        <p>
                          {t('charges.ttc', {
                            total: (
                              (item.rate || 0) *
                              (1 + (item.tva / 100 || 0))
                            ).toFixed(2)
                          })}
                        </p>
                      </>
                    )}
                  </span>
                </List.Item>
              )
            }
          />
          <Divider orientation="left" style={{ marginBottom: 0 }}>
            <span style={{ fontSize: 15 }}>{t('charges.other_charges')}</span>
          </Divider>
          <List
            size="small"
            itemLayout="horizontal"
            dataSource={charges}
            renderItem={(item) => (
              <List.Item>
                <span
                  style={{
                    float: 'left',
                    display: 'inline-flex',
                    alignItems: 'center'
                  }}
                >
                  <div>{item.name}</div>
                  <div>
                    <Link
                      to={{
                        pathname: `/sessions/show/${session._id}/charges/edit/${item._id}`
                      }}
                    >
                      <Button type="link" style={{ padding: '3px 0 0 16px' }}>
                        <p>{t('charges.buttons.edit')}</p>
                      </Button>
                    </Link>
                    <Divider type="vertical" />
                    <Popconfirm
                      title={t('datatable.column.action.delete.title')}
                      okText={t('datatable.column.action.delete.ok')}
                      okButtonProps={{ type: 'danger' }}
                      cancelText={t('datatable.column.action.delete.cancel')}
                      onConfirm={() => deleteCharge(item._id)}
                      icon={<WarningOutlined />}
                    >
                      <Button
                        type="link"
                        danger
                        style={{ padding: '3px 0 0 0' }}
                      >
                        {t('charges.buttons.delete')}
                      </Button>
                    </Popconfirm>
                  </div>
                </span>
                <span style={{ float: 'right' }}>
                  <p>{t('charges.ht', { total: item.rate || 0 })}</p>
                  <p>
                    {t('charges.ttc', {
                      total: (
                        (item.rate || 0) *
                        (1 + (item.tva / 100 || 0))
                      ).toFixed(2)
                    })}
                  </p>
                </span>
              </List.Item>
            )}
          />
        </Card>
      </div>
    </>
  );
};

export default ChargesTable;

ChargesTable.propTypes = {
  session: PropTypes.shape(PropTypes.any).isRequired,
  setTotalCharge: PropTypes.func.isRequired
};
