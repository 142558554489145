import { useTranslation } from 'react-i18next';

const useListContent = ({
  name,
  description,
  address,
  compliant_disable_access,
  capacity,
  free_note
}) => {
  const { t } = useTranslation();
  return [
    {
      label: t('training-places.name'),
      content: name
    },
    {
      label: t('training-places.description'),
      content: description
    },
    {
      label: t('training-places.address'),
      content:
        `${address?.number || ''} ${address?.street ||
          ''} ${address?.additional || ''}` || '-'
    },
    {
      label: t('training-places.address'),
      content: address?.city
    },
    {
      label: t('training-places.postal_code'),
      content: address?.postal_code
    },
    {
      label: t('training-places.capacity'),
      content: capacity
    },
    {
      label: t('training-places.handicapped_access'),
      content: compliant_disable_access ? 'Oui' : 'Non'
    },
    {
      label: t('training-places.free_notes'),
      content: free_note
    }
  ];
};

export default useListContent;
