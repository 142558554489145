import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Popconfirm } from 'antd';

const useColumns = ({ isEditing, save, cancel, edit, editingKey }) => {
  const { t } = useTranslation();

  const columns = [
    {
      title: t('sessions.invoices.designation'),
      dataIndex: ['name'],
      sorter: true
    },
    {
      title: t('sessions.modules.rate_type'),
      key: 'rate_type',
      dataIndex: 'rate_type',
      render: (rate_type) => t(`sessions.modules.form.${rate_type}`),
      sorter: true
    },
    {
      title: t('sessions.invoices.quantity'),
      dataIndex: ['quantity'],
      sorter: true,
      render: (quantity) => `-  ${quantity}`,
      editable: true
    },
    {
      title: t('sessions.invoices.rate'),
      dataIndex: ['rate'],
      sorter: true,
      render: (rate) => `-  ${rate}`,
      editable: true
    },
    {
      title: t('sessions.invoices.tva'),
      dataIndex: ['tva'],
      sorter: true
    },
    {
      title: t('sessions.invoices.total_ht'),
      dataIndex: ['total_ht'],
      render: (_, record) => `-  ${record.quantity * record.rate}`,
      sorter: true
    },
    {
      title: t('sessions.invoices.operation'),
      dataIndex: 'operation',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Button
              type="link"
              onClick={() => save(record.key)}
              style={{ marginRight: 8 }}
            >
              {t('sessions.invoices.save')}
            </Button>
            <Popconfirm
              title={t('sessions.invoices.messages.cancel')}
              onConfirm={cancel}
            >
              <Button type="link">{t('sessions.invoices.cancel')}</Button>
            </Popconfirm>
          </span>
        ) : (
          <Button
            type="link"
            disabled={editingKey !== ''}
            onClick={() => edit(record)}
          >
            {t('sessions.invoices.edit')}
          </Button>
        );
      }
    }
  ];

  return { columns };
};

export default useColumns;
