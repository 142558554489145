import React, { useCallback, useEffect, useState } from 'react';
import { Checkbox, Form, Input, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { noLabelLayout } from '../../../utils/constants/formLayout';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';
import {
  listItemBefore,
  listItemAfter,
  listTrainingDiploma,
} from '../../../utils/constants/trainingActionTypes';
import ErrorStatusCode from '../../../utils/ErrorStatusCode';

const useFields = () => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { notification } = ErrorStatusCode();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [subcontracting, setSubContracting] = useState(false);
  const { dispatchAPI } = useAuthContext();
  const [enums, setEnums] = useState({});
  const [diplomSelect, setDiplomSelect] = useState(false);
  const [diploma, setDiploma] = useState('');
  const [priceByCustomer, setPriceByCustomer] = useState(false);

  const { Option, OptGroup } = Select;

  const handleChange = value => {
    setDiplomSelect(true);
    setDiploma(value);
  };

  const fields = [
    {
      name: ['rate_type'],
      input: (
        <Select loading={isFieldsLoading}>
          {(enums.rate_type || []).map(title => (
            <Option key={title} value={title}>
              {t(`sessions.modules.form.${title}`)}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      name: ['customer_type'],
      input: (
        <Select loading={isFieldsLoading}>
          {(enums.customer_type || []).map(title => (
            <Option key={title} value={title}>
              {t(`sessions.modules.form.${title}`)}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      name: ['monthly_payment'],
      input: <Checkbox />,
    },
    {
      name: ['name'],
      input: <Input placeholder="Formation" />,
    },
    {
      name: ['priceByCustomer'],
      input: (
        <Checkbox
          onClick={() => {
            setPriceByCustomer(!priceByCustomer);
          }}>
          {' '}
          Prix par type de client{' '}
        </Checkbox>
      ),
    },
    ...(priceByCustomer === true
      ? [
          {
            name: ['company_rate'],
            input: <Input type="number" />,
          },
          {
            name: ['independent_rate'],
            input: <Input type="number" />,
          },
          {
            name: ['private_rate'],
            input: <Input type="number" />,
          },
        ]
      : [
          {
            name: ['rate'],
            input: <Input type="number" />,
          },
        ]),
    {
      name: ['tva'],
      input: <Input type="number" />,
    },
  ];

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/module/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getEnums();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return {
    fields,
    isFieldsLoading,
  };
};

export default useFields;
