import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { subRoutes } from '../../utils/constants/routes';
import Companies from './Companies/Companies';
import DetailCompany from './Companies/DetailCompany';
import CreateUpdateCompany from './Companies/CreateUpdateCompany';
import CreateUpdateTrainee from './Trainees/CreateUpdateTrainee';
import ListTrainees from './Trainees/ListTrainees';
import Contributors from './Contributors/Contributors';
import CreateUpdateContributor from './Contributors/CreateUpdateContributor';
import DetailTrainee from './Trainees/DetailTrainee';
import DetailContributor from './Contributors/DetailContributor';
import CreateUpdateExternalFounders from './ExternalFounders/CreateUpdateExternalFounders';
import DetailsExternalFounders from './ExternalFounders/DetailsExternalFounders';
import ExternalFounders from './ExternalFounders/ExternalFounders';
import TrainingPlaces from './TrainingPlaces/TrainingPlaces';
import CreateUpdateTrainingPlaces from './TrainingPlaces/CreateUpdateTrainingPlaces';
import DetailsTrainingPlaces from './TrainingPlaces/DetailsTrainingPlaces';
import CreateUpdateContact from './Contacts/CreateUpdateContact';
import ShowContact from './Contacts/ShowContact';
import Contacts from './Contacts/ListContacts';

const DatasRouter = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route
        path={`${path}${subRoutes.DATAS.COMPANIES}/create`}
        render={() => <CreateUpdateCompany purpose="create" />}
      />
      <Route
        path={`${path}${subRoutes.DATAS.COMPANIES}/edit/:id`}
        render={() => <CreateUpdateCompany purpose="edit" />}
      />
      <Route
        path={`${path}${subRoutes.DATAS.COMPANIES}/show/:id`}
        component={DetailCompany}
      />
      <Route
        path={`${path}${subRoutes.DATAS.COMPANIES}`}
        render={() => <Companies companyType="CUSTOMERS" />}
      />
      <Route
        path={`${path}${subRoutes.DATAS.CONTACTS}/create`}
        render={() => <CreateUpdateContact purpose="create" />}
      />
      <Route
        path={`${path}${subRoutes.DATAS.CONTACTS}/edit/:id`}
        render={() => <CreateUpdateContact purpose="edit" />}
      />
      <Route
        path={`${path}${subRoutes.DATAS.CONTACTS}/show/:id`}
        component={ShowContact}
      />
      <Route path={`${path}${subRoutes.DATAS.CONTACTS}`} component={Contacts} />
      <Route
        path={`${path}${subRoutes.DATAS.TRAINEES}/create`}
        render={() => <CreateUpdateTrainee purpose="create" />}
      />
      <Route
        path={`${path}${subRoutes.DATAS.TRAINEES}/edit/:id`}
        render={() => <CreateUpdateTrainee purpose="edit" />}
      />
      <Route
        path={`${path}${subRoutes.DATAS.TRAINEES}/show/:id`}
        render={() => <DetailTrainee />}
      />
      <Route
        path={`${path}${subRoutes.DATAS.TRAINEES}`}
        component={ListTrainees}
      />
      <Route
        path={`${path}${subRoutes.DATAS.CONTRIBUTORS}/create`}
        render={() => <CreateUpdateContributor purpose="create" />}
      />
      <Route
        path={`${path}${subRoutes.DATAS.CONTRIBUTORS}/edit/:id`}
        render={() => <CreateUpdateContributor purpose="edit" />}
      />
      <Route
        path={`${path}${subRoutes.DATAS.CONTRIBUTORS}/show/:id`}
        render={() => <DetailContributor />}
      />
      <Route
        path={`${path}${subRoutes.DATAS.CONTRIBUTORS}`}
        component={Contributors}
      />
      <Route
        path={`${path}${subRoutes.DATAS['EXTERNAL-FUNDERS']}/create`}
        render={() => <CreateUpdateExternalFounders purpose="create" />}
      />
      <Route
        path={`${path}${subRoutes.DATAS['EXTERNAL-FUNDERS']}/edit/:id`}
        render={() => <CreateUpdateExternalFounders purpose="edit" />}
      />
      <Route
        path={`${path}${subRoutes.DATAS['EXTERNAL-FUNDERS']}/show/:id`}
        component={DetailsExternalFounders}
      />
      <Route
        path={`${path}${subRoutes.DATAS['EXTERNAL-FUNDERS']}`}
        component={ExternalFounders}
      />
      <Route
        path={`${path}${subRoutes.DATAS.TRAINING_PLACES}/create`}
        render={() => <CreateUpdateTrainingPlaces purpose="create" />}
      />
      <Route
        path={`${path}${subRoutes.DATAS.TRAINING_PLACES}/edit/:id`}
        render={() => <CreateUpdateTrainingPlaces purpose="edit" />}
      />
      <Route
        path={`${path}${subRoutes.DATAS.TRAINING_PLACES}/show/:id`}
        component={DetailsTrainingPlaces}
      />
      <Route
        path={`${path}${subRoutes.DATAS.TRAINING_PLACES}`}
        component={TrainingPlaces}
      />
    </Switch>
  );
};

export default DatasRouter;
