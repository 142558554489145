import React, { useCallback, useEffect, useState } from 'react';
import { Layout, PageHeader } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import KanbanSettings from './kanbanSettings';
import useAuthContext from '../../contexts/AuthContext';
import ErrorStatusCode from '../../utils/ErrorStatusCode';

const { Content } = Layout;
const LineSetting = styled.div`
  background-color: #f0f2f5;
  padding: 10px;
  margin-bottom: 30px;
  p {
    font-size: 16px;
    font-weight: bold;
    margin-top: 5px;
  }
`;

const Settings = () => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { notification } = ErrorStatusCode();
  const [length, setLength] = useState();
  const [kanbanCRMCols, setKanbanCRMCols] = useState([]);

  const getKanbanCols = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/kanbans' });
      setKanbanCRMCols(data.filter((el) => el.type === 'CRM'));

      setLength(data.length);
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  const fetchData = useCallback(async () => {
    await getKanbanCols();
  }, []);

  useEffect(() => {
    (async () => {
      await fetchData();
    })();
  }, []);

  const routes = [
    {
      path: '/',
      breadcrumbName: t('home.title')
    },
    {
      path: '/parameters',
      breadcrumbName: t('home.settings')
    }
  ];
  return (
    <>
      <PageHeader
        breadcrumb={{
          routes,
          itemRender: (item) => (
            <Link to={item.path}>{item.breadcrumbName}</Link>
          )
        }}
        title={t('settings.title')}
        onBack={() => window.history.back()}
        ghost={false}
      />
      <Content
        className="site-layout-background"
        style={{
          marginTop: 2,
          padding: 24,
          backgroundColor: '#fff'
        }}
      >
        <LineSetting>
          <p>{t('settings.kanbantable.title')}</p>
          <KanbanSettings
            getKanbanCols={getKanbanCols}
            kanbanCols={kanbanCRMCols}
            type="CRM"
            length={length}
          />
        </LineSetting>
      </Content>
    </>
  );
};

export default Settings;
