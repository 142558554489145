import React, { useEffect, useState } from 'react';
import { Col, Progress, Row } from 'antd';

const ChoiceGridResponses = ({
  globalNotes,
  setGlobalNotes,
  item,
  traineesList,
  responses,
  bool,
  setBool,
}) => {
  const [finalRes, setFinalRes] = useState();
  const [notes, setNotes] = useState([]);
  const [mark, setMark] = useState([]);

  useEffect(() => {
    const list = [];
    responses.map(response => {
      if (response.question === item._id) {
        list.push(response.grid_responses_list);
      }
    });
    setFinalRes(list);
  }, [responses]);

  useEffect(() => {
    const list = [];
    const note = 10 / item.answer_options.length;
    let mark = note;
    item.answer_options.map(el => {
      list[el._id] = mark;
      mark += note;
    });
    setNotes(list);
  }, [item.answer_options]);

  const noteCalculator = idQuestion => {
    let globalMark = 0;
    const questions = responses.filter(el => el.question === item._id);
    questions.forEach(el => {
      el.grid_responses_list.forEach(res => {
        if (res.question === idQuestion) {
          const mark = notes[res.response];
          globalMark += mark;
        }
      });
    });
    const final =
      questions.length && (globalMark / questions.length).toFixed(1);
    return final;
  };

  useEffect(() => {
    const list = [];
    let global = 0;
    item.questions_list.forEach(question => {
      const finalMark = noteCalculator(question._id);
      list[question._id] = finalMark;
      global += JSON.parse(finalMark);
    });
    const finalGlobalNote = global / item.questions_list.length;
    const test = globalNotes;
    test[item._id] = finalGlobalNote;
    setGlobalNotes(test);
    setBool(!bool);
    setMark(list);
  }, [item.questions_list, responses]);

  return (
    <>
      {item.questions_list &&
        item.questions_list.map(question => {
          return (
            <Row style={{ marginBottom: 24 }}>
              <Col span={4}>
                <span> {question.title}</span>
              </Col>
              <Col span={4}>
                <span
                  style={{
                    height: '50px',
                    width: '50px',
                    textAlign: 'center',
                    paddingTop: '13px',
                    backgroundColor: '#fff',
                    color: 'var(--primaryColor)',
                    borderRadius: '50%',
                    border: '1px solid var(--primaryColor)',
                    display: 'inline-block',
                    marginLeft: 20,
                  }}>
                  {mark[question._id]}
                  /10
                </span>
              </Col>
              <Col span={12}>
                {item.answer_options.map(opt => {
                  let percent = 0;
                  let nbr = 0;
                  const totalRes = finalRes && finalRes.length;
                  finalRes &&
                    finalRes.forEach(response => {
                      response.map(el => {
                        if (
                          el.response === opt._id &&
                          el.question === question._id
                        ) {
                          nbr += 1;
                        }
                      });
                    });
                  percent = (nbr / totalRes) * 100;
                  return (
                    <Row>
                      <Col span={6}>
                        <span>{opt.title}</span>
                      </Col>
                      <Col span={12}>
                        <Progress percent={percent} />
                      </Col>
                      <Col span={2}>
                        <span>{nbr > 1 && nbr}</span>
                      </Col>
                    </Row>
                  );
                })}
              </Col>
            </Row>
          );
        })}
    </>
  );
};

export default ChoiceGridResponses;
