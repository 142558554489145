import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Divider, message, Popconfirm, Table } from 'antd';
import { DeleteOutlined, WarningOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { routes, subRoutes } from '../../../utils/constants/routes';
import useColumns from './columns';
import { ContentCustom } from '../../../components';
import useAuthContext from '../../../contexts/AuthContext';

const ProgramBlocsTable = ({ program, getProgram }) => {
  const { columns } = useColumns();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [blocsPrograms, setBlocsPrograms] = useState([]);

  useEffect(() => {
    setBlocsPrograms(
      program.blocs.map(({ _id, ...el }) => ({
        ...el,
        key: _id,
        id: _id
      }))
    );
  }, [program]);

  const updateProgram = async (body) => {
    try {
      await dispatchAPI('PATCH', { url: `/programs/${program._id}`, body });
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
  };

  const deleteBlocFromProgram = async (key) => {
    const newBlocs = blocsPrograms.filter(
      (el) => el.id.toString() !== key.toString()
    );
    await updateProgram({ ...program, blocs: newBlocs });
    await getProgram();
  };

  const actionColumns = [
    {
      key: 'action',
      render: (item) => (
        <div>
          <span style={{ float: 'right' }}>
            <Divider type="vertical" />
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={async () => {
                await deleteBlocFromProgram(item.key);
              }}
              icon={<WarningOutlined />}
            >
              <DeleteOutlined style={{ color: 'red' }} type="delete" />
            </Popconfirm>
          </span>
        </div>
      )
    }
  ];

  return (
    <>
      <ContentCustom>
        <Link
          to={{
            pathname: `${routes.CATALOG}${subRoutes.CATALOG.PROGRAMS}/edit/${program._id}/add_blocs`
          }}
          style={{ marginBottom: 16, display: 'inline-block' }}
        >
          {t('programs.form.add_blocs')}
        </Link>
        <Table
          columns={[...columns, ...actionColumns]}
          dataSource={blocsPrograms}
          pagination={false}
        />
      </ContentCustom>
    </>
  );
};

ProgramBlocsTable.propTypes = {
  program: PropTypes.shape({
    blocs: PropTypes.array,
    _id: PropTypes.string
  }).isRequired,
  getProgram: PropTypes.func.isRequired
};

export default ProgramBlocsTable;
