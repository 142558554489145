import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

const ScanContext = createContext({});

export const ScanContextProvider = ({ children }) => {
  const [enums, setEnums] = useState([]);
  const [file, setFile] = useState();
  const [base64, setBase64] = useState();
  const [zone, setZone] = useState();
  const [zones, setZones] = useState([]);
  const [loadedZones, setLoadedZones] = useState([]);
  const [fields, setFields] = useState();
  const [loadingFields, setLoadingFields] = useState([]);
  const [output, setOutput] = useState({});
  const [zoningField, setZoningField] = useState(null);
  const [nextField, setNextField] = useState(null);

  return (
    <ScanContext.Provider
      value={{
        enums,
        file,
        base64,
        zone,
        zones,
        fields,
        loadingFields,
        output,
        zoningField,
        nextField,
        loadedZones,
        setEnums,
        setFile,
        setBase64,
        setZone,
        setZones,
        setFields,
        setLoadingFields,
        setOutput,
        setZoningField,
        setNextField,
        setLoadedZones,
      }}>
      {children}
    </ScanContext.Provider>
  );
};

ScanContextProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default () => useContext(ScanContext);
