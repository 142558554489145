import React, { useEffect, useState } from 'react';
import { Button, Form, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { ContentCustom } from '../../../components';
import useFields from './fields';
import useGenerateFormItem from '../../../utils/GenerateFormItem';
import PageHeaderCustom from '../../../components/PageHeader/PageHeader';
import { formItemLayout } from '../../../utils/constants/formLayout';
import ProgramsTable from '../../Sessions/sessionProgram/programsTable';
import useAuthContext from '../../../contexts/AuthContext';
import { routes, subRoutes } from '../../../utils/constants/routes';
import ErrorStatusCode from '../../../utils/ErrorStatusCode';

const CreateProgram = () => {
  const generateFields = useGenerateFormItem();
  const history = useHistory();
  const { state } = useLocation();
  const { t } = useTranslation();
  const { fields } = useFields();
  const { dispatchAPI } = useAuthContext();
  const [programs, setPrograms] = useState([]);
  const [blocs, setBlocs] = useState([]);
  const [name, setName] = useState('');
  const [idProgram, setIdProgram] = useState('');
  const [edit, setEdit] = useState(false);
  const [duplicateProgram, setDuplicateProgram] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const { notification } = ErrorStatusCode();

  const basicFields = {
    execution_track: {
      results_track: [
        'Feuilles de présence.',
        'Questions orales ou écrites (QCM).',
        'Mises en situation',
        "Formulaires d'évaluation de la formation.",
        'Certificat de réalisation de l’action de formation.'
      ],
      educational_resources: [
        'Accueil des stagiaires dans une salle dédiée à la formation.',
        'Documents supports de formation projetés.',
        'Exposés théoriques',
        'Etude de cas concrets',
        'Quiz en salle',
        'Mise à disposition en ligne de documents supports à la suite de la formation.'
      ]
    }
  };
  const tmp = {
    educational_goals: [],
    trainees_profile: {
      customers: [],
      prerequisite: []
    },
    execution_track: {
      team_description: '',
      results_track: [],
      educational_resources: []
    }
  };

  const createProgram = async (body) => {
    try {
      await dispatchAPI('POST', {
        url: '/programs',
        body
      });
      const { data } = await dispatchAPI('GET', {
        url: '/programs'
      });
      const program = data.find(
        (pro) => pro.name === body.name && pro.subtitle === body.subtitle
      );
      if (state && state.session) {
        await dispatchAPI('PATCH', {
          url: `/sessions/${state.session._id}`,
          body: { program: program._id }
        });
      }
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  const getPrograms = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/programs?populate=blocs`
      });
      setPrograms(
        data.map(({ _id, ...program }) => ({
          ...program,
          key: _id,
          _id
        }))
      );
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  const createProgramWithoutBlocs = async (values) => {
    const body = {
      ...values,
      ...basicFields
    };
    await createProgram(body);
    await getPrograms();
    setEdit(!edit);
  };

  const createProgramWithBlocs = async (values) => {
    let training_content = [];
    programs.forEach((program) => {
      blocs.forEach((bloc) => {
        if (program._id === bloc) {
          training_content = [...program.training_content];
          program.educational_goals.forEach((educ) => {
            tmp.educational_goals.push(educ);
          });
          if (program.trainees_profile.customers.length) {
            tmp.trainees_profile.customers.push(
              ...program.trainees_profile.customers
            );
          }
          if (program.trainees_profile.prerequisite.length) {
            tmp.trainees_profile.prerequisite.push(
              ...program.trainees_profile.prerequisite
            );
          }
          tmp.execution_track.team_description =
            program.execution_track?.team_description;
          if (program.execution_track.results_track.length) {
            tmp.execution_track.results_track.push(
              ...program.execution_track.results_track
            );
          }

          if (program.execution_track.educational_resources.length) {
            tmp.execution_track.educational_resources.push(
              ...program.execution_track.educational_resources
            );
          }
        }
      });
    });

    const body = {
      ...values,
      blocs,
      ...tmp,
      training_content
    };

    await createProgram(body);
    await getPrograms();
    setEdit(!edit);
  };

  useEffect(() => {
    if (edit === true) {
      programs.forEach((program) => {
        if (program.name === name) {
          setIdProgram(program._id);
        }
      });
    }
  }, [edit]);

  useEffect(() => {
    if (idProgram !== '') {
      history.push(
        `${routes.CATALOG}${subRoutes.CATALOG.PROGRAMS}/edit/${idProgram}`,
        state
      );
    }
  }, [idProgram]);

  const handleSubmit = async (values) => {
    setLoading(true);
    setName(values.name);
    if (duplicateProgram && !blocs.length) {
      message.error(t('program.error_messages.duplicate'));
    } else if (blocs.length) {
      await createProgramWithBlocs(values);
    } else {
      await createProgramWithoutBlocs(values);
    }
    setLoading(false);
  };

  useEffect(() => {
    (async () => {
      await getPrograms();
    })();
  }, []);

  return (
    <>
      <PageHeaderCustom title={t(`programs.form.title.create`)} />
      <ContentCustom>
        <Form {...formItemLayout} onFinish={handleSubmit}>
          {fields.map((field) =>
            generateFields('programs' || 'programs', field)
          )}
          {duplicateProgram && (
            <ProgramsTable programs={programs} setBlocs={setBlocs} />
          )}
          <Button
            loading={isLoading}
            type="primary"
            htmlType="submit"
            style={{
              marginBottom: 10,
              float: 'right'
            }}
          >
            {t('programs.create_program')}
          </Button>
          {programs.length !== 0 && (
            <Button
              onClick={() => {
                setBlocs([]);
                setDuplicateProgram(!duplicateProgram);
              }}
              type="link"
              style={{
                marginRight: 10,
                marginBottom: 10,
                float: 'right'
              }}
            >
              {duplicateProgram
                ? t('programs.cancel')
                : t('programs.duplicate')}
            </Button>
          )}
        </Form>
      </ContentCustom>
    </>
  );
};

export default CreateProgram;
