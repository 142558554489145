import React from 'react';
import PropTypes from 'prop-types';
import { Table, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import columns from './columns';

const RecapQualitySubscribers = ({ type, rowsData }) => {
  const { t } = useTranslation();
  const expandedRowUsersRender = (users) => {
    const column = [
      {
        title: 'Name',
        width: '11.7%',
        ellipsis: {
          showTitle: false
        },
        render: (user) => (
          <Tooltip
            placement="topLeft"
            title={
              user.last_name
                ? `${user.last_name} ${user.first_name}`
                : user.name
            }
          >
            {user.last_name
              ? `${user.last_name} ${user.first_name}`
              : user.name}
          </Tooltip>
        )
      },
      {
        title: t('subscriptions.quality.columns.status'),
        width: '32.3%',
        render: null,
        sorter: true
      },
      {
        title: t('subscriptions.quality.columns.global_total'),
        render: null,
        sorter: true
      }
    ];

    return (
      <Table
        bordered
        expandable={{
          rowExpandable: (record) => record.trainees_list.length
        }}
        showHeader={false}
        columns={column}
        dataSource={users}
        pagination={false}
      />
    );
  };
  const expandedRowRender = (users) => {
    return (
      <Table
        expandable={{
          expandedRowRender: (record) =>
            expandedRowUsersRender(
              record.customers.map((el) => ({ ...el, key: el._id }))
            )
        }}
        showHeader={false}
        columns={columns(t, type, 'users')}
        dataSource={users}
        bordered
        pagination={false}
      />
    );
  };
  return (
    <>
      <Table
        className="components-table-demo-nested"
        columns={columns(t, type, 'year')}
        expandable={{
          expandedRowRender: (record) => expandedRowRender(record.sessions)
        }}
        bordered
        dataSource={rowsData}
        pagination={false}
      />
    </>
  );
};

RecapQualitySubscribers.propTypes = {
  type: PropTypes.string.isRequired,
  rowsData: PropTypes.arrayOf({}).isRequired
};

export default RecapQualitySubscribers;
