import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Divider, Typography } from 'antd';
import moment from 'moment';
import { PageHeaderCustom } from '../../../components';
import DescriptionList from '../../../components/DescriptionList/DescriptionList';
import useAuthContext from '../../../contexts/AuthContext';
import listContent from './listContent';
import ListItemsBPF from './ListItemsBPF';
import PedagogicReport from './PedagogicReport';
import ErrorStatusCode from '../../../utils/ErrorStatusCode';

const BPF = () => {
  const { t } = useTranslation();
  const { dispatchAPI, user } = useAuthContext();
  const [bpf, setBpf] = useState({});
  const { notification } = ErrorStatusCode();
  const getBpf = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: `/bpf` });
      setBpf(data);
    } catch (e) {
      if (e.response) {
        notification(e.response);
      }
    }
  };

  useEffect(() => {
    (async () => {
      await getBpf();
    })();
  }, []);

  return (
    <>
      <PageHeaderCustom title={t('Bilan Pédagogique et Financier')} />
      <Typography.Title
        level={4}
        style={{
          margin: '10px 0 10px 20px',
          fontWeight: 400,
          borderBottom: '1px solid var(--primaryColor)',
          width: 'fit-content'
        }}
      >
        {t(`Année du BPF ${moment(new Date()).format('YYYY')}`)}
      </Typography.Title>
      <Card bordered={false} className="bpf-card">
        <Divider orientation="left">
          {`A. ${t('dashboards.balance_sheet.training_organization')}`}
        </Divider>
        <DescriptionList
          data={listContent({ ...user.organization, email: user.email })}
          translate
        />
        <Divider orientation="left">
          {`B. ${t('dashboards.balance_sheet.organization_features')}`}
        </Divider>
        <p style={{ marginLeft: 20 }}>
          {t('dashboards.balance_sheet.fiscal_year')}
          <b style={{ marginLeft: 5 }}>
            {bpf.fiscal_year &&
              moment(bpf.fiscal_year.start).format('DD-MM-YYYY')}
          </b>
          {t('dashboards.balance_sheet.to')}
          <b style={{ marginLeft: 5 }}>
            {bpf.fiscal_year &&
              moment(bpf.fiscal_year.end).format('DD-MM-YYYY')}
          </b>
        </p>
        <Divider orientation="left">
          {`C. ${t('dashboards.balance_sheet.products_origin')}`}
        </Divider>
        <Card>
          <ListItemsBPF items={[{ title: 'PRODUCT_FROM' }]} finance />
          <ListItemsBPF
            items={bpf.products_from?.filter(
              (el) =>
                el.title !== 'TOTAL_PRODUCT_MADE' && el.title !== 'CA_GLOBAL'
            )}
            marginLeft={{ marginLeft: 50 }}
            finance
          />
          <ListItemsBPF
            items={bpf.products_from?.filter(
              (el) =>
                el.title === 'TOTAL_PRODUCT_MADE' || el.title === 'CA_GLOBAL'
            )}
            finance
          />
        </Card>
        <Divider orientation="left">
          {`D. ${t('dashboards.balance_sheet.organization_charges')}`}
        </Divider>
        <Card>
          <ListItemsBPF items={bpf.charges} finance />
        </Card>
        <Divider orientation="left">
          {`E. ${t('dashboards.balance_sheet.trainers')}`}
        </Divider>
        <Card>
          <ListItemsBPF items={bpf.contributors} />
        </Card>
        <Divider orientation="left">
          {`F. ${t(
            'dashboards.balance_sheet.pedagogical_report_by_organization'
          )}`}
        </Divider>
        <PedagogicReport pedagogicData={bpf.pedagogic} />
        <Divider>
          {`G. ${t(
            'dashboards.balance_sheet.pedagogical_report_by_other_organization'
          )}`}
        </Divider>
      </Card>
    </>
  );
};

export default BPF;
