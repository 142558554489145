import React, { useCallback, useEffect, useState } from 'react';
import { Button, Checkbox, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { noLabelLayout } from '../../utils/constants/formLayout';
import useAuthContext from '../../contexts/AuthContext';
import {
  listItemBefore,
  listItemAfter,
  listTrainingDiploma
} from '../../utils/constants/trainingActionTypes';
import ModalTrainingFields from './ModalTrainingFields';
import ErrorStatusCode from '../../utils/ErrorStatusCode';

const useFields = (trainingFieldValue, setTrainingFieldValue) => {
  const { t } = useTranslation();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [subcontracting, setSubContracting] = useState(false);
  const { dispatchAPI } = useAuthContext();
  const [enums, setEnums] = useState({});
  const [diplomSelect, setDiplomSelect] = useState(false);
  const [diploma, setDiploma] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { notification } = ErrorStatusCode();
  const { Option } = Select;

  const filterOption = (input, option) => {
    return (
      option.children
        ?.toString()
        .toLowerCase()
        .indexOf(input?.toLowerCase()) > 0 ||
      option.key?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
    );
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleChange = (value) => {
    setDiplomSelect(true);
    setDiploma(value);
  };

  const fields = [
    {
      name: ['session_type'],
      rules: [{ required: true }],
      input: (
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          loading={isFieldsLoading}
        >
          {(enums.sessionType || []).map((title) => (
            <Option key={title} value={title}>
              {t(`sessions.form.${title}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['name'],
      rules: [{ required: true }]
    },
    {
      name: ['customer_type'],
      rules: [{ required: true }],
      input: (
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          loading={isFieldsLoading}
        >
          {(enums.customerType || []).map((title) => (
            <Option key={title} value={title}>
              {t(`sessions.form.${title}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      noLabel: true,
      layout: noLabelLayout,
      valuePropName: 'checked',
      name: ['subcontracting'],
      input: (
        <Checkbox
          onClick={() => {
            setSubContracting(!subcontracting);
          }}
        >
          {t('sessions.form.subcontracting')}
        </Checkbox>
      )
    },
    {
      name: ['training_type'],
      rules: [{ required: true }],
      input: (
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={filterOption}
        >
          <Option key={-1} style={{ fontWeight: 'bold' }} disabled>
            {' '}
            Depuis la réforme 2019
          </Option>
          {listItemAfter.map((item) => (
            <Option style={{ marginLeft: 30 }} key={item} value={item}>
              {item}
            </Option>
          ))}
          <Option style={{ fontWeight: 'bold' }} disabled>
            Avant 2019
          </Option>
          {listItemBefore.map((item) => (
            <Option style={{ marginLeft: 30 }} key={item} value={item}>
              {item}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['training_field'],
      input: (
        <>
          <Button type="link" block onClick={showModal}>
            {trainingFieldValue}
          </Button>
          <ModalTrainingFields
            setTrainingFieldValue={setTrainingFieldValue}
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
          />
        </>
      )
    },
    {
      name: ['diploma'],
      input: (
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.props.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          onChange={handleChange}
        >
          {listTrainingDiploma.map((item) => (
            <Option key={item} value={item}>
              {item}
            </Option>
          ))}
        </Select>
      )
    }
  ];

  if (diplomSelect === true && diploma !== 'Aucun') {
    fields.push({
      name: ['diploma_name']
    });
  }

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/sessions/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getEnums();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return {
    fields,
    isFieldsLoading
  };
};

export default useFields;
