import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../../contexts/AuthContext';
import ErrorStatusCode from '../../../utils/ErrorStatusCode';

const useColumns = () => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [companies, setCompanies] = useState();
  const { notification } = ErrorStatusCode();

  const getCompanies = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/companies' });
      setCompanies(data);
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  useEffect(() => {
    (async () => {
      await getCompanies();
    })();
  }, []);

  return [
    {
      title: t('contacts.form.last_name'),
      dataIndex: 'last_name',
      sorter: true
    },
    {
      title: t('contacts.form.first_name'),
      dataIndex: 'first_name',
      sorter: true
    },
    {
      title: t('contacts.form.company'),
      dataIndex: 'my_company',
      render: (my_company) => my_company && my_company.name,
      sorter: true,
      filters:
        companies &&
        companies.map((c) => ({
          text: c.name,
          value: c._id
        }))
    },
    {
      title: t('contacts.form.email'),
      dataIndex: 'email',
      sorter: true
    },
    {
      title: t('contacts.form.phone_number.number'),
      dataIndex: 'phone_number',
      render: (phone_number) =>
        phone_number && phone_number.number ? (
          <div>
            {`${phone_number.country_code} ${phone_number.number.slice(
              0,
              1
            )} ${phone_number.number.slice(1).replace(/(.{2})/g, '$1 ')}
                  `}
          </div>
        ) : (
          '-'
        ),
      sorter: true
    },
    {
      title: t('contacts.form.company_representative'),
      render: ({ company_representative }) =>
        company_representative ? 'Oui' : 'Non',
      sorter: true
    }
  ];
};

export default useColumns;
