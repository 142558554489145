export const headers = [
  {
    label: 'number',
    key: 'number'
  },
  {
    label: 'customer',
    key: 'customer.name'
  },
  {
    label: 'session',
    key: 'session.name'
  },
  {
    label: 'emission_date',
    key: 'emission_date'
  },
  {
    label: 'deadline_date',
    key: 'deadline_date'
  },
  {
    label: 'total_ht',
    key: 'total_ht'
  },
  {
    label: 'total_ttc',
    key: 'total_ttc'
  },
  {
    label: 'total_paid',
    key: 'total_paid'
  }
];
