import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card, Collapse, Divider, message } from 'antd';
import { useHistory } from 'react-router-dom';
import { PageHeaderCustom } from '../../../components';
import TemplatesList from './templateList';
import useAuthContext from '../../../contexts/AuthContext';
import { routes, subRoutes } from '../../../utils/constants/routes';
import ErrorStatusCode from '../../../utils/ErrorStatusCode';

const { Panel } = Collapse;

const EmailsTemplates = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { dispatchAPI, user } = useAuthContext();
  const [forceRefresh, setForceRefresh] = useState(false);
  const [createNew, setCreateNew] = useState(false);
  const [emails, setEmails] = useState([]);
  const { notification } = ErrorStatusCode();

  const getEmails = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/emails' });
      setEmails(data);
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  const createEmail = async body => {
    try {
      await dispatchAPI('POST', {
        url: '/emails',
        body,
      });
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
  };

  const modelName = type => {
    let name = '';
    switch (type) {
      case 'convention_model':
        name = 'Envoi de convention';
        break;
      case 'customers_email_model':
        name = 'Autres emails clients';
        break;
      case 'quotation_model':
        name = 'Envoi de devis';
        break;
      case 'invoice_model':
        name = 'Envoi de facture';
        break;
      case 'subcontracting_contributor_model':
        name = 'Envoi de contrat formateur';
        break;
      case 'contributors_email_model':
        name = 'Autre emails formateurs';
        break;
      case 'contributor_signings_model':
        name = 'Emargement numérique pour un intervenant';
        break;
      case 'convocation_model':
        name = 'Envoi de convocation';
        break;
      case 'certification_model':
        name = 'Envoi de certificat';
        break;
      case 'attendance_certificate_model':
        name = "Envoi d'attestation d'assiduité";
        break;
      case 'trainees_emails_model':
        name = 'Autres emails stagiaires';
        break;
      case 'pre_training_quiz_model':
        name = "Notification d'évaluation pré-formation";
        break;
      case 'post_training_j_model_quiz_model':
        name = "Notification d'évaluation de satisfaction";
        break;
      case 'post_training_month_model_quiz_model':
        name = "Notification d'évaluationa froid";
        break;
      case 'training_quiz_model':
        name = "Notification d'évaluation de fin de formation";
        break;
      case 'contributors_quizzes_model':
        name = "Notification d'évaluation pour formateur";
        break;
      case 'managers_quizzes_model':
        name = "Notification d'évaluation pour managers";
        break;
      case 'funders_quizzes_model':
        name = "Notification d'évaluation pour financeurs";
        break;
      case 'trainees_signings_model':
        name = 'Emargement numérique pour un stagiaire';
        break;
    }

    return name;
  };

  const addModelEmail = async typeModel => {
    const nameModel = modelName(typeModel);
    const modelEmails = emails.filter(
      el => el.type === typeModel && el.organization === user.organization._id
    );
    let body = {};
    if (modelEmails.length < 1) {
      body = {
        name: nameModel,
        type: typeModel,
        default: true,
      };
    } else {
      body = {
        name: nameModel,
        type: typeModel,
        default: false,
      };
    }

    await createEmail(body);
    setForceRefresh(!forceRefresh);
    setCreateNew(!createNew);
  };

  useEffect(() => {
    (async () => {
      await getEmails();
    })();
  }, [forceRefresh]);

  useEffect(() => {
    if (createNew && emails.filter(el => !el.session).length) {
      const email = emails[emails.length - 1];
      history.push(
        `${routes.DOCUMENTS}${subRoutes.DOCUMENTS.TEMPLATE_EMAILS}/edit/${email._id}`
      );
    }
  }, [emails]);

  return (
    <>
      <PageHeaderCustom title={t("Modèles d'emails")} />
      <Card bordered={false} className="card-no-border">
        <Collapse accordion ghost>
          <Panel header={t('Emails pour les clients')} key="1">
            <Divider orientation="left">
              <span style={{ fontSize: 15 }}>{t('Envoi de conventions')}</span>
            </Divider>
            <TemplatesList
              forceRefresh={forceRefresh}
              setForceRefresh={setForceRefresh}
              emails={emails.filter(
                el =>
                  el.type === 'convention_model' &&
                  el.organization &&
                  el.organization === user.organization._id
              )}
            />
            <Button
              type="link"
              onClick={() => addModelEmail('convention_model')}
              style={{ marginTop: 10 }}>
              Ajouter un modèle
            </Button>
            <Divider orientation="left">
              <span style={{ fontSize: 15 }}>{t('Autres emails clients')}</span>
            </Divider>
            <TemplatesList
              forceRefresh={forceRefresh}
              setForceRefresh={setForceRefresh}
              emails={emails.filter(
                el =>
                  el.type === 'customers_email_model' &&
                  el.organization &&
                  el.organization === user.organization._id
              )}
            />
            <Button
              type="link"
              onClick={() => addModelEmail('customers_email_model')}
              style={{ marginTop: 10 }}>
              Ajouter un modèle
            </Button>
          </Panel>
          <Panel key="2" header={t('Emails pour les factures et devis')}>
            <Divider orientation="left">
              <span style={{ fontSize: 15 }}>{t('Envoi de devis')}</span>
            </Divider>
            <TemplatesList
              forceRefresh={forceRefresh}
              setForceRefresh={setForceRefresh}
              emails={emails.filter(
                el =>
                  el.type === 'quotation_model' &&
                  el.organization &&
                  el.organization === user.organization._id
              )}
            />
            <Button
              type="link"
              onClick={() => addModelEmail('quotation_model')}
              style={{ marginTop: 10 }}>
              Ajouter un modèle
            </Button>
            <Divider orientation="left">
              <span style={{ fontSize: 15 }}>{t('Envoi de facture')}</span>
            </Divider>
            <TemplatesList
              forceRefresh={forceRefresh}
              setForceRefresh={setForceRefresh}
              emails={emails.filter(
                el =>
                  el.type === 'invoice_model' &&
                  el.organization &&
                  el.organization === user.organization._id
              )}
            />
            <Button
              type="link"
              onClick={() => addModelEmail('invoice_model')}
              style={{ marginTop: 10 }}>
              Ajouter un modèle
            </Button>
          </Panel>
          <Panel key="3" header={t('Emails pour les intervenants')}>
            <Divider orientation="left">
              <span style={{ fontSize: 15 }}>
                {t('Envoi de contrat formateur')}
              </span>
            </Divider>
            <TemplatesList
              forceRefresh={forceRefresh}
              setForceRefresh={setForceRefresh}
              emails={emails.filter(
                el =>
                  el.type === 'subcontracting_contributor_model' &&
                  el.organization &&
                  el.organization === user.organization._id
              )}
            />
            <Button
              type="link"
              onClick={() => addModelEmail('subcontracting_contributor_model')}
              style={{ marginTop: 10 }}>
              Ajouter un modèle
            </Button>
            <Divider orientation="left">
              <span style={{ fontSize: 15 }}>
                {t('Autre emails formateurs')}
              </span>
            </Divider>
            <TemplatesList
              forceRefresh={forceRefresh}
              setForceRefresh={setForceRefresh}
              emails={emails.filter(
                el =>
                  el.type === 'contributors_email_model' &&
                  el.organization &&
                  el.organization === user.organization._id
              )}
            />
            <Button
              type="link"
              onClick={() => addModelEmail('contributors_email_model')}
              style={{ marginTop: 10 }}>
              Ajouter un modèle
            </Button>
            <Divider orientation="left">
              <span style={{ fontSize: 15 }}>
                {t('Emargement numérique pour un intervenant ')}
              </span>
            </Divider>
            <TemplatesList
              forceRefresh={forceRefresh}
              setForceRefresh={setForceRefresh}
              emails={emails.filter(
                el =>
                  el.type === 'contributor_signings_model' &&
                  el.organization &&
                  el.organization === user.organization._id
              )}
            />
            <Button
              type="link"
              onClick={() => addModelEmail('contributor_signings_model')}
              style={{ marginTop: 10 }}>
              Ajouter un modèle
            </Button>
          </Panel>
          <Panel key="4" header={t('Emails pour les stagiaires')}>
            <Divider orientation="left">
              <span style={{ fontSize: 15 }}>{t('Envoi de convocations')}</span>
            </Divider>
            <TemplatesList
              forceRefresh={forceRefresh}
              setForceRefresh={setForceRefresh}
              emails={emails.filter(
                el =>
                  el.type === 'convocation_model' &&
                  el.organization &&
                  el.organization === user.organization._id
              )}
            />
            <Button
              type="link"
              onClick={() => addModelEmail('convocation_model')}
              style={{ marginTop: 10 }}>
              Ajouter un modèle
            </Button>
            <Divider orientation="left">
              <span style={{ fontSize: 15 }}>{t('Envoi de certificat')}</span>
            </Divider>
            <TemplatesList
              forceRefresh={forceRefresh}
              setForceRefresh={setForceRefresh}
              emails={emails.filter(
                el =>
                  el.type === 'certification_model' &&
                  el.organization &&
                  el.organization === user.organization._id
              )}
            />
            <Button
              type="link"
              onClick={() => addModelEmail('certification_model')}
              style={{ marginTop: 10 }}>
              Ajouter un modèle
            </Button>
            <Divider orientation="left">
              <span style={{ fontSize: 15 }}>
                {t("Envoi d'attestation d'assiduité")}
              </span>
            </Divider>
            <TemplatesList
              forceRefresh={forceRefresh}
              setForceRefresh={setForceRefresh}
              emails={emails.filter(
                el =>
                  el.type === 'attendance_certificate_model' &&
                  el.organization &&
                  el.organization === user.organization._id
              )}
            />
            <Button
              type="link"
              onClick={() => addModelEmail('attendance_certificate_model')}
              style={{ marginTop: 10 }}>
              Ajouter un modèle
            </Button>
            <Divider orientation="left">
              <span style={{ fontSize: 15 }}>
                {t('Autres emails stagiaires')}
              </span>
            </Divider>
            <TemplatesList
              forceRefresh={forceRefresh}
              setForceRefresh={setForceRefresh}
              emails={emails.filter(
                el =>
                  el.type === 'trainees_emails_model' &&
                  el.organization &&
                  el.organization === user.organization._id
              )}
            />
            <Button
              type="link"
              onClick={() => addModelEmail('trainees_emails_model')}
              style={{ marginTop: 10 }}>
              Ajouter un modèle
            </Button>
            <Divider orientation="left">
              <span style={{ fontSize: 15 }}>
                {t('Emargement numérique pour un stagiaire')}
              </span>
            </Divider>
            <TemplatesList
              forceRefresh={forceRefresh}
              setForceRefresh={setForceRefresh}
              emails={emails.filter(
                el =>
                  el.type === 'trainees_signings_model' &&
                  el.organization &&
                  el.organization === user.organization._id
              )}
            />
            <Button
              type="link"
              onClick={() => addModelEmail('trainees_signings_model')}
              style={{ marginTop: 10 }}>
              Ajouter un modèle
            </Button>
          </Panel>
          <Panel key="5" header={t('Evaluations')}>
            <Divider orientation="left">
              <span style={{ fontSize: 15 }}>
                {t("Notification d'évaluation pré-formation")}
              </span>
            </Divider>
            <TemplatesList
              forceRefresh={forceRefresh}
              setForceRefresh={setForceRefresh}
              emails={emails.filter(
                el =>
                  el.type === 'pre_training_quiz_model' &&
                  el.organization &&
                  el.organization === user.organization._id
              )}
            />
            <Button
              type="link"
              onClick={() => addModelEmail('pre_training_quiz_model')}
              style={{ marginTop: 10 }}>
              Ajouter un modèle
            </Button>
            <Divider orientation="left">
              <span style={{ fontSize: 15 }}>
                {t("Notification d'évaluation de satisfaction")}
              </span>
            </Divider>
            <TemplatesList
              forceRefresh={forceRefresh}
              setForceRefresh={setForceRefresh}
              emails={emails.filter(
                el =>
                  el.type === 'post_training_j_model_quiz_model' &&
                  el.organization &&
                  el.organization === user.organization._id
              )}
            />
            <Button
              type="link"
              onClick={() => addModelEmail('post_training_j_model_quiz_model')}
              style={{ marginTop: 10 }}>
              Ajouter un modèle
            </Button>
            <Divider orientation="left">
              <span style={{ fontSize: 15 }}>
                {t("Notification d'évaluation à froid")}
              </span>
            </Divider>
            <TemplatesList
              forceRefresh={forceRefresh}
              setForceRefresh={setForceRefresh}
              emails={emails.filter(
                el =>
                  el.type === 'post_training_month_model_quiz_model' &&
                  el.organization &&
                  el.organization === user.organization._id
              )}
            />
            <Button
              type="link"
              onClick={() =>
                addModelEmail('post_training_month_model_quiz_model')
              }
              style={{ marginTop: 10 }}>
              Ajouter un modèle
            </Button>
            <Divider orientation="left">
              <span style={{ fontSize: 15 }}>
                {t('Notification de quiz en présentiel')}
              </span>
            </Divider>
            <TemplatesList
              forceRefresh={forceRefresh}
              setForceRefresh={setForceRefresh}
              emails={emails.filter(
                el =>
                  el.type === 'training_quiz_model' &&
                  el.organization &&
                  el.organization === user.organization._id
              )}
            />
            <Button
              type="link"
              onClick={() => addModelEmail('training_quiz_model')}
              style={{ marginTop: 10 }}>
              Ajouter un modèle
            </Button>
            <Divider orientation="left">
              <span style={{ fontSize: 15 }}>
                {t("Notification d'évaluation pour formateur")}
              </span>
            </Divider>
            <TemplatesList
              forceRefresh={forceRefresh}
              setForceRefresh={setForceRefresh}
              emails={emails.filter(
                el =>
                  el.type === 'contributors_quizzes_model' &&
                  el.organization &&
                  el.organization === user.organization._id
              )}
            />
            <Button
              type="link"
              onClick={() => addModelEmail('contributors_quizzes_model')}
              style={{ marginTop: 10 }}>
              Ajouter un modèle
            </Button>
            <Divider orientation="left">
              <span style={{ fontSize: 15 }}>
                {t("Notification d'évaluation pour managers")}
              </span>
            </Divider>
            <TemplatesList
              forceRefresh={forceRefresh}
              setForceRefresh={setForceRefresh}
              emails={emails.filter(
                el =>
                  el.type === 'managers_quizzes_model' &&
                  el.organization &&
                  el.organization === user.organization._id
              )}
            />
            <Button
              type="link"
              onClick={() => addModelEmail('managers_quizzes_model')}
              style={{ marginTop: 10 }}>
              Ajouter un modèle
            </Button>
            <Divider orientation="left">
              <span style={{ fontSize: 15 }}>
                {t("Notification d'évaluation pour financeurs")}
              </span>
            </Divider>
            <TemplatesList
              forceRefresh={forceRefresh}
              setForceRefresh={setForceRefresh}
              emails={emails.filter(
                el =>
                  el.type === 'funders_quizzes_model' &&
                  el.organization &&
                  el.organization === user.organization._id
              )}
            />
            <Button
              type="link"
              onClick={() => addModelEmail('funders_quizzes_model')}
              style={{ marginTop: 10 }}>
              Ajouter un modèle
            </Button>
          </Panel>
        </Collapse>
      </Card>
    </>
  );
};

export default EmailsTemplates;
