import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { outOfNavRoutes, routes } from '../utils/constants/routes';
import Login from './login';
import Exception from '../components/Exceptions/Exception';
import useAuthContext from '../contexts/AuthContext';
import BasicLayout from '../components/layouts/BasicLayout';
import ProfileRouter from './profile/ProfileRouter';
import Confirmation from './emailConfirmation/Confirmation';
import Settings from './Settings/settings';
import Payment from './payment/Payment';
import UserRouter from './users/UserRouter';
import TAndARouter from './tAndA/TAndARouter';
import CommercialRouter from './Commercial/CommercialRouter';
import DocumentsRouter from './Documents/DocumentsRouter';
import AccountRouter from './Accounting/AccountRouter';
import DatasRouter from './Donnees/DatasRouter';
import SkillsRouter from './Skills/skillsRouter';
import SessionsRouter from './Sessions/SessionsRouter';
import CatalogRouter from './Catalog/CatalogRouter';
import AgendaRouter from './Agenda/AgendaRouter';
import Indicator from './DashBoard/Indicator';
import DashBoardRouter from './DashBoard/DashBoardRouter';
import SubscriptionsRouter from './Subscriptions/subscriptionsRouter';
import PrescriberRouter from './Prescriber/PrescriberRouter';
import PrescriberHome from './Prescriber/PrescriberHome';
import SuperAdminRouter from './SuperAdmin/SuperAdminRouter';

export const PrivateRoute = ({
  location,
  component: Component,
  ...restProps
}) => {
  const authContext = useAuthContext();
  const splitPath = location.pathname.split('/');
  const path = `/${
    splitPath[2] && !['show', 'edit', 'create'].includes(splitPath[2])
      ? splitPath[2]
      : splitPath[1]
  }`;

  return (
    <Route
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...restProps}
      render={(props) =>
        authContext.isValid ? (
          <BasicLayout path={path}>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <Component {...props} />
          </BasicLayout>
        ) : (
          <Redirect
            to={{
              pathname: outOfNavRoutes.LOGIN,
              state: { from: '/' }
            }}
          />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    state: PropTypes.shape({
      type: PropTypes.string,
      model: PropTypes.shape({
        name: PropTypes.string
      })
    })
  })
};

PrivateRoute.defaultProps = {
  location: { pathname: '/' }
};

const getComponentByUserRole = (role) => {
  switch (role) {
    case 'admins:SUPER-ADMIN':
      return SubscriptionsRouter;
    case 'users:PRESCRIBER':
      return PrescriberHome;
    default:
      return Indicator;
  }
};

const Router = () => {
  const { user } = useAuthContext();
  return (
    <BrowserRouter>
      <Switch>
        <Route path={`${outOfNavRoutes.LOGIN}/:token`} component={Login} />
        <Route path={outOfNavRoutes.LOGIN} component={Login} />
        <Route path={`${outOfNavRoutes.SPONSOR}/:sponsor`} component={Login} />
        <Route
          path={`${outOfNavRoutes.CONFIRMATION}/:token`}
          component={Confirmation}
        />
        <PrivateRoute path={outOfNavRoutes.PROFILE} component={ProfileRouter} />
        <PrivateRoute path={routes.DASHBORD} component={DashBoardRouter} />
        <PrivateRoute
          exact
          path="/"
          component={getComponentByUserRole(user?.role)}
        />
        <PrivateRoute exact path="/settings" component={Settings} />
        <PrivateRoute path="/admin" component={SuperAdminRouter} />
        <PrivateRoute
          exact
          path={routes.SPONSORSHIP}
          component={PrescriberRouter}
        />
        <PrivateRoute exact path="/payment-system/:id" component={Payment} />
        <PrivateRoute path="/payment-system/" component={Payment} />
        <PrivateRoute path={routes.ACCOUNTING} component={AccountRouter} />
        <PrivateRoute path={routes.COMMERCIAL} component={CommercialRouter} />
        <PrivateRoute path={routes.DATAS} component={DatasRouter} />
        <PrivateRoute path={routes.AGENDA} component={AgendaRouter} />
        <PrivateRoute path={routes.SESSIONS} component={SessionsRouter} />
        <PrivateRoute path="/skills" component={SkillsRouter} />
        <PrivateRoute path={routes.CATALOG} component={CatalogRouter} />
        <PrivateRoute path={routes.USERS} component={UserRouter} />
        <PrivateRoute path={routes.DOCUMENTS} component={DocumentsRouter} />
        <PrivateRoute
          path={routes.SUBSCRIPTIONS}
          component={SubscriptionsRouter}
        />
        <PrivateRoute
          path={routes['TIMES-AND-ATTENDANCES']}
          component={TAndARouter}
        />
        <PrivateRoute component={Exception} />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
