import React from 'react';
import {
  Button,
  Card,
  Checkbox,
  Space,
  Divider,
  List,
  Popconfirm,
  Dropdown
} from 'antd';
import {
  DeleteOutlined,
  DownOutlined,
  FileOutlined,
  WarningOutlined,
  DownloadOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const ListSessionFiles = ({
  purpose,
  files,
  deleteFileFromSession,
  downloadFile,
  onAccept,
  idUser,
  customer,
  menuDropDown,
  customerRef
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Card>
        <List
          className="program-files-list"
          dataSource={files}
          split={false}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                avatar={<FileOutlined />}
                title={
                  purpose === 'quotations'
                    ? `${item.filename} du ${moment(item.date).format(
                        'DD-MM-YYYY'
                      )}`
                    : item.filename
                }
              />
              <span style={{ float: 'right' }}>
                {purpose === 'quotations' ? (
                  <>
                    <Space>
                      <span> Accepté </span>
                      <Checkbox
                        disabled={
                          item.status === 'waiting' &&
                          customer &&
                          customer.status === 'ACCEPTED'
                        }
                        checked={item.status && item.status === 'accepted'}
                        onClick={(event) =>
                          onAccept(idUser, event.target.checked, item)
                        }
                      />
                    </Space>
                    <Dropdown
                      overlay={() =>
                        menuDropDown(
                          customer._id,
                          'download',
                          item._id,
                          item.filename,
                          customerRef
                        )
                      }
                      trigger={['click']}
                    >
                      <Button type="link">
                        <DownOutlined />
                      </Button>
                    </Dropdown>
                  </>
                ) : (
                  <Button
                    type="link"
                    onClick={() => {
                      downloadFile(item._id, item.filename);
                    }}
                  >
                    <DownloadOutlined />
                  </Button>
                )}

                <Divider type="vertical" />
                <Popconfirm
                  title={t('datatable.column.action.delete.title')}
                  okText={t('datatable.column.action.delete.ok')}
                  okButtonProps={{ type: 'danger' }}
                  cancelText={t('datatable.column.action.delete.cancel')}
                  onConfirm={async () => {
                    deleteFileFromSession(item._id);
                  }}
                  icon={<WarningOutlined />}
                >
                  <DeleteOutlined style={{ color: 'red' }} type="delete" />
                </Popconfirm>
              </span>
            </List.Item>
          )}
        />
      </Card>
    </>
  );
};

export default ListSessionFiles;
