import { useTranslation } from 'react-i18next';

const useListContent = (identification) => {
  const { t } = useTranslation();

  return [
    {
      label: t('dashboards.legal_form'),
      content: identification && identification.juridic_form
    },
    {
      label: t('dashboards.siret'),
      content: identification && identification.siret_number
    },
    {
      label: t('dashboards.naf'),
      content: identification && identification.ape_code
    },
    {
      label: t('dashboards.denomination'),
      content: identification && identification.name
    },
    {
      label: t('dashboards.address'),
      content:
        identification &&
        `${identification.channel_number ||
          ''} ${identification.address_details ||
          ''} ${identification.postal_code || ''} ${identification.city ||
          ''} ${identification.country || ''} `
    },
    {
      label: t('dashboards.phone'),
      content: identification && identification.phone_number
    },
    {
      label: t('dashboards.email'),
      content: identification && identification.email
    }
  ];
};

export default useListContent;
