import isEqual from 'lodash/isEqual';
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Card, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { SimpleDonutChart } from '../SimpleDonutChart';

const Donut = ({ isLoading, indicatorsColors, purpose, dashboardData }) => {
  const { t } = useTranslation();

  return (
    <Card
      bordered={false}
      headStyle={{
        textAlign: 'left',
        color: indicatorsColors[purpose] || 'black'
      }}
      title={t(`dashboards.${purpose}.title`)}
      className="indicator-card indicator-card-large"
    >
      <Spin spinning={isLoading}>
        <SimpleDonutChart
          title={t(`dashboards.${purpose}.${'item[0].title'}`)}
          total={0}
          data={dashboardData?.statsData || []}
          totalRegle={dashboardData?.totalRegle}
          totalDepa={dashboardData?.totalDepa}
          totalVenir={dashboardData?.totalVenir}
        />
      </Spin>
    </Card>
  );
};

const areEqual = (prevProps, nextProps) => {
  return (
    prevProps.isLoading === nextProps.isLoading &&
    isEqual(prevProps.indicatorsColors, nextProps.indicatorsColors) &&
    prevProps.purpose === nextProps.purpose &&
    isEqual(prevProps.dashboardData, nextProps.dashboardData)
  );
};

Donut.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  indicatorsColors: PropTypes.shape({}).isRequired,
  purpose: PropTypes.string.isRequired,
  dashboardData: PropTypes.shape({
    totalDepa: PropTypes.number,
    totalVenir: PropTypes.number,
    statsData: PropTypes.arrayOf(PropTypes.shape({})),
    totalRegle: PropTypes.shape({})
  }).isRequired
};

export default memo(Donut, areEqual);
