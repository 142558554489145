import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Checkbox,
  Col,
  Drawer,
  Form,
  Input,
  message,
  Select,
  Typography
} from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { formItemLayout } from '../../../utils/constants/formLayout';
import useAuthContext from '../../../contexts/AuthContext';
import ErrorStatusCode from '../../../utils/ErrorStatusCode';

const { Title } = Typography;
const { Option } = Select;

const NewContactDrawer = ({
  isVisible,
  setVisibilityContactDrawer,
  refresh,
  setRefresh,
  company
}) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [enums, setEnums] = useState({});
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState('');
  const { notification } = ErrorStatusCode();
  const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

  const handleInput = (value) => {
    return value.replace(phoneRegex, '($1) $2-$3');
  };
  const createContact = async (body) => {
    try {
      await dispatchAPI('POST', {
        url: '/contacts',
        body
      });
      message.success(t('contacts.message.success.new'));
    } catch (e) {
      message.error(t('companies.message.error.'));
    }
  };
  const onFinish = async (values) => {
    const body = {
      ...values,
      my_company: company._id
    };
    await createContact(body);
    setRefresh(!refresh);
    setVisibilityContactDrawer();
  };

  const onFinishFailed = (error) => {
    message.error(t(`contacts.message.error.${error}`));
  };

  const cancelButton = () => {
    setVisibilityContactDrawer();
  };

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/contacts/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getEnums();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return (
    <Drawer
      visible={isVisible}
      onClose={() => {
        setVisibilityContactDrawer();
      }}
      width="30%"
    >
      <Col style={{ width: '100%' }}>
        <Title level={3}>{t(`contacts.form.title.create`)}</Title>
      </Col>
      <Form
        {...formItemLayout}
        name="test"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        style={{ width: '100%' }}
      >
        <Form.Item
          style={{ width: '100%' }}
          label={t('contacts.form.company')}
          name="my_company"
        >
          <Input disabled defaultValue={company?.name} />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              message: t('contacts.message.empty_field')
            }
          ]}
          label={t('contacts.form.gender')}
          name="gender"
        >
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            loading={isFieldsLoading}
          >
            {(enums.genders || []).map((title) => (
              <Option key={title} value={title}>
                {t(`contacts.form.${title}`)}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={t('contacts.form.last_name')}
          name="last_name"
          rules={[
            {
              required: true,
              message: t('contacts.message.empty_field')
            }
          ]}
        >
          <Input style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          label={t('contacts.form.first_name')}
          name="first_name"
          rules={[
            {
              required: true,
              message: t('contacts.message.empty_field')
            }
          ]}
        >
          <Input style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item label={t('contacts.form.position')} name="position">
          <Input style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          label={t('contacts.form.company_representative')}
          name="company_representative"
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
        <Form.Item
          label={t('contacts.form.email')}
          name="email"
          rules={[
            {
              required: true,
              message: t('contacts.message.empty_field')
            }
          ]}
        >
          <Input style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          label={t('contacts.form.phone_number')}
          name="phone_number"
          rules={[
            {
              required: true,
              message: t('contacts.message.empty_field')
            }
          ]}
        >
          <Input
            pattern="[0][0-9]{9}"
            value={handleInput(phoneNumber)}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
        </Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          style={{
            background: 'rgb(71, 210, 103)',
            color: '#fff',
            border: 'rgb(71, 210, 103)',
            borderRadius: 3,
            marginLeft: 10,
            float: 'right'
          }}
        >
          {t('buttons.save')}
          <CheckOutlined />
        </Button>
        <Button
          onClick={cancelButton}
          style={{
            background: '#fff',
            color: 'red',
            borderColor: 'red',
            borderRadius: 3,
            float: 'right'
          }}
        >
          {`${t('buttons.cancel')}`}
          <CloseOutlined />
        </Button>
      </Form>
    </Drawer>
  );
};

NewContactDrawer.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  setVisibilityContactDrawer: PropTypes.func.isRequired,
  refresh: PropTypes.bool.isRequired,
  setRefresh: PropTypes.func.isRequired,
  company: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string
  }).isRequired
};

export default NewContactDrawer;
