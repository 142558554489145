import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DownloadOutlined,
  LoadingOutlined,
  EyeOutlined
} from '@ant-design/icons';
import { Divider, Button } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import moment from 'moment';
import { useFormatter } from '../../../utils/formatter';
import useAccountingContext from '../AccountingContext';
import columns from './columns';
import SimpleResourceLandingLayout from '../../../components/layouts/SimpleResourceLandingLayout';
import { headers } from './headers';

const ListInvoices = () => {
  const { t } = useTranslation();
  const { formattedData } = useFormatter();
  const location = useLocation();
  const { pathname } = location;
  const { template, range, downloadFile } = useAccountingContext();
  const [extraQuery, setExtraQuery] = useState(null);
  const [isDownloading, setIsDownloading] = useState({});

  const iconSize = 14;

  useEffect(() => {
    if (range && range.length) {
      setExtraQuery(
        `emission_date>=${moment(range[0])
          .startOf('day')
          .toISOString()}&emission_date<=${moment(range[1])
          .endOf('day')
          .toISOString()}&type=none`
      );
    } else setExtraQuery('type=none');
  }, [range]);

  const downloadInvoice = async (item) => {
    if (!isDownloading[item.key]) {
      setIsDownloading({ ...isDownloading, [item.key]: true });
      await downloadFile(item.key, `${item.number}.pdf`, () =>
        setIsDownloading({
          ...isDownloading,
          [item.key]: false
        })
      );
    }
  };

  const actionColumn = [
    {
      key: 'action',
      render: (item) => {
        return (
          <>
            {template && (
              <>
                <Divider type="vertical" />
                <Button
                  style={{ padding: 0 }}
                  type="link"
                  onClick={() => {
                    downloadInvoice(item);
                  }}
                >
                  {isDownloading[item.key] ? (
                    <LoadingOutlined />
                  ) : (
                    <DownloadOutlined />
                  )}
                </Button>
                <Divider type="vertical" />
                <Link
                  to={{
                    pathname: `${pathname}/show/${item.key}`
                  }}
                >
                  <EyeOutlined style={{ fontSize: iconSize }} />
                </Link>
              </>
            )}
          </>
        );
      }
    }
  ];

  const searchFilterItems = [
    {
      name: t('basiclayout.menu.customers'),
      value: 'customer'
    },
    {
      name: t('basiclayout.menu.sessions'),
      value: 'session'
    },
    {
      name: t('invoices.list.column.title.number'),
      value: 'number'
    },
    {
      name: t('invoices.list.column.title.total_ht'),
      value: 'total_ht'
    },
    {
      name: t('invoices.list.column.title.total'),
      value: 'total_ttc'
    }
  ];

  return (
    <SimpleResourceLandingLayout
      resourceName="invoices"
      columns={[...columns(), ...actionColumn]}
      extraQuery={extraQuery}
      populate="customer,session"
      customActionColumn
      withCreateButton={false}
      searchWithFilter
      searchFilterItems={searchFilterItems}
      headers={headers}
      formatter={formattedData}
      withoutImportButton
      withProjectionFields
      scroll={{ x: 1000 }}
    />
  );
};

export default ListInvoices;
