import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Pie, measureTextWidth } from '@ant-design/charts';

const SimplePieChart = ({ data }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    if (data.length) {
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    }
  }, [data]);

  const formatLegend = (value) => {
    switch (value) {
      case 'PAID':
        return 'Payé';
      case 'EXPIRED':
        return 'Expiré';
      case 'PARTIALLY':
        return 'Partiellement payé';
      case 'WAITING':
        return 'En attente';
      default:
        return value;
    }
  };

  const renderStatistic = (containerWidth, text, style) => {
    // eslint-disable-next-line no-underscore-dangle
    const _measureTextWidth = (0, measureTextWidth)(text, style);
    const textWidth = _measureTextWidth.width;
    const textHeight = _measureTextWidth.height;
    const R = containerWidth / 2;
    let scale = 1;
    if (containerWidth < textWidth) {
      scale = Math.min(
        Math.sqrt(
          Math.abs(
            // eslint-disable-next-line no-restricted-properties
            Math.pow(R, 2) /
              // eslint-disable-next-line no-restricted-properties
              (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2))
          )
        ),
        1
      );
    }
    const textStyleStr = 'width:'.concat(containerWidth, 'px;');
    return '<div style="'
      .concat(textStyleStr, ';font-size:')
      .concat(scale, 'em;line-height:')
      .concat(scale < 1 ? 1 : 'inherit', ';">')
      .concat(text, '</div>');
  };

  const config = {
    appendPadding: 10,
    data,
    angleField: 'value',
    colorField: 'type',
    color: ['#ADC1E1', '#6692DB', '#4F71A8', '#2B3E5C'],
    radius: 1,
    innerRadius: 0.7,
    label: {
      type: 'outer',
      labelHeight: 28,
      formatter: ({ value }) => `${value.toFixed(2)} €`
    },
    tooltip: {
      formatter: (values) => {
        return {
          name: values.type,
          value: `${values.value} €`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
        };
      }
    },
    statistic: {
      title: {
        offsetY: -4,
        customHtml: function customHtml(container, view, datum) {
          const { width, height } = container.getBoundingClientRect();
          // eslint-disable-next-line no-restricted-properties
          const d = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2));
          const text = datum ? formatLegend(datum.type) : 'Total';
          return renderStatistic(d, text, {
            fontSize: 28
          });
        }
      }
    },
    legend: {
      itemName: {
        formatter: (value) => formatLegend(value)
      }
    },
    interactions: [
      { type: 'element-selected' },
      { type: 'element-active' },
      { type: 'pie-statistic-active' }
    ]
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        height: '280px'
      }}
    >
      {!isLoading && <Pie style={{ height: '100%' }} {...config} />}
    </div>
  );
};

SimplePieChart.propTypes = {
  data: PropTypes.arrayOf({
    type: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired
  }).isRequired
};

export { SimplePieChart };
