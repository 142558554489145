import React, { useState } from 'react';
import { Row, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { CheckOutlined } from '@ant-design/icons';
import { Route } from 'react-router-dom';
import useAuthContext from '../../contexts/AuthContext';
import { getRedirectionFlow, pay } from './GCUtils';

const PaymentGocardless = (props) => {
  const { dispatchAPI, user } = useAuthContext();
  const { t } = useTranslation();
  const [redirectURI, setRedirectURI] = useState(null);

  const chooseGCAction = async () => {
    if (
      user.organization &&
      (user.organization.gcMandate === undefined ||
        user.organization.gcMandate === null)
    ) {
      const redirection = await getRedirectionFlow(
        dispatchAPI,
        user,
        user.organization,
        props.amount,
        props.subscriptionLabel,
        t
      );
      setRedirectURI(redirection);
      if (redirection !== undefined) window.location.href = redirection;
    } else {
      await pay(
        props.type,
        dispatchAPI,
        user.organization,
        props.amount,
        t,
        props.reference,
        props.subscriptionLabel
      );
    }
  };

  return redirectURI === null ? (
    <>
      <Row justify={'center'} className="payment-btn-row">
        <Button
          type="primary"
          style={props.btnStyle}
          onClick={() => chooseGCAction(dispatchAPI)}
        >
          {!props.title
            ? t('payment.buttons.gocardless')
            : 'Changer votre abonnement'}{' '}
          <CheckOutlined />
        </Button>
      </Row>
    </>
  ) : (
    <Route
      path="/payment-system"
      component={() => {
        window.location.href = redirectURI;
        return null;
      }}
    />
  );
};

export default PaymentGocardless;
