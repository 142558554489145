import React from 'react';
import { Layout } from 'antd';

const { Content } = Layout;

const ContentCustom = ({ children, style }) => {
  return (
    <Content
      style={{
        ...style,
        padding: '18px 24px',
        background: 'var(--componentBackground)',
      }}>
      {children}
    </Content>
  );
};

export default ContentCustom;
