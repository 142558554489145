import React, { useEffect, useState } from 'react';
import { Button, Col, Input, Radio, Row, Space, Table, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  DownOutlined,
  EditOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  UpOutlined
} from '@ant-design/icons';

const { TextArea } = Input;

const ChoiceGridTable = ({
  questionsOptions,
  options,
  questions,
  setQuestions,
  indexQuestion,
  addAnswerOption,
  deleteAnswerOption,
  updateAnswerOptions,
  updateQuiz,
  refresh,
  setRefresh,
  responses,
  purpose
}) => {
  const [questionsList, setQuestionList] = useState([]);
  const [checked, setChecked] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    setQuestionList(questionsOptions);
  }, [questionsOptions]);

  useEffect(() => {
    const element = [];
    if (purpose === 'show') {
      responses[0].responses[indexQuestion].grid_responses_list.map((res) => {
        element[res.question] = res.response;
      });
    } else {
      questionsList.map((el) => {
        element[el._id] = 0;
      });
    }
    setChecked(element);
  }, [questionsList]);

  const addQuestionOption = () => {
    const questionOptionsList = [...questionsOptions];
    const newQuestions = [...questions];
    newQuestions[indexQuestion].questions_list.push({
      title: JSON.stringify(
        newQuestions[indexQuestion].questions_list.length + 1
      )
    });
    questionOptionsList.push({
      title: JSON.stringify(questionOptionsList.length + 1)
    });
    setQuestionList(questionOptionsList);
    setQuestions(newQuestions);
  };

  const deleteQuestionOption = (title) => {
    const newquest = [...questions];
    newquest[indexQuestion].questions_list = questions[
      indexQuestion
    ].questions_list.filter((el) => el.title !== title);
    setQuestions(newquest);
  };

  const updateQuestionOptions = (title, value) => {
    const newquest = [...questions];
    const index = newquest[indexQuestion].questions_list.findIndex(
      (q) => q.title === title
    );
    newquest[indexQuestion].questions_list[index].title = value;
    setQuestionList(newquest[indexQuestion].questions_list);
    setQuestions(newquest);
  };

  const checkArrayLength = (type, title) => {
    let res = false;
    if (type === 'ascend') {
      questionsList.forEach((question, index) => {
        if (question.title === title) {
          if (index === 0) {
            res = true;
          }
        }
      });
    } else if (type === 'descend') {
      questionsList.forEach((question, index) => {
        if (question.title === title) {
          if (index === questionsList.length - 1) {
            res = true;
          }
        }
      });
    }
    return res;
  };

  const changeIndexQuestionOption = async (title, type) => {
    const newQuestions = [...questions];
    let indexAnswer = 0;
    const list = [...questionsOptions];
    questionsList.forEach((question, index) => {
      if (question.title === title) {
        indexAnswer = index;
      }
    });

    if (type === 'ascend') {
      const finalIndex = indexAnswer - 1;
      newQuestions[indexQuestion].questions_list.splice(
        finalIndex,
        0,
        newQuestions[indexQuestion].questions_list.splice(indexAnswer, 1)[0]
      );
      list.splice(finalIndex, 0, list.splice(indexAnswer, 1)[0]);
    } else if (type === 'descend') {
      const finalIndex = indexAnswer + 1;
      newQuestions[indexQuestion].questions_list.splice(
        finalIndex,
        0,
        newQuestions[indexQuestion].questions_list.splice(indexAnswer, 1)[0]
      );
      list.splice(finalIndex, 0, list.splice(indexAnswer, 1)[0]);
    }
    setQuestionList(list);
    setQuestions(newQuestions);
    await updateQuiz({ questions: newQuestions });
    setRefresh(!refresh);
  };

  const columns = [
    ...(purpose !== 'show'
      ? [
          {
            render: (question) => (
              <Button
                type="link"
                danger
                icon={<MinusCircleOutlined />}
                onClick={() => deleteQuestionOption(question.title)}
              />
            )
          }
        ]
      : []),
    {
      render: (question) => (
        <TextArea
          style={{ resize: 'both', overflow: 'auto' }}
          onChange={(e) =>
            updateQuestionOptions(question.title, e.target.value)
          }
          className="quiz-input"
          bordered={false}
          defaultValue={question.title}
          suffix={
            <Button type="link">
              <EditOutlined />
            </Button>
          }
        />
      ),
      width: 500,
      key: 'title'
    },
    ...options.map((opt, index) => ({
      title: (
        <>
          <Row>
            <Col span={22}>
              <TextArea
                style={{ resize: 'both', overflow: 'auto', width: '100%' }}
                onBlur={(event) =>
                  updateAnswerOptions(indexQuestion, index, event.target.value)
                }
                className="quiz-input"
                bordered={false}
                defaultValue={opt.title}
              />
            </Col>
            {purpose !== 'show' && (
              <Col>
                <Button
                  type="link"
                  danger
                  icon={<MinusCircleOutlined />}
                  className="delete-quiz-input-btn"
                  onClick={() => deleteAnswerOption(indexQuestion, index)}
                />
              </Col>
            )}
          </Row>
        </>
      ),
      width: 500,
      key: opt.title,
      render: (question) => (
        <Radio
          checked={purpose === 'show' && checked[question._id] === opt._id}
          disabled
        />
      )
    })),
    ...(purpose !== 'show'
      ? [
          {
            key: 'actions',
            render: (question) => (
              <>
                <Tooltip title="Changer de position">
                  <Button
                    type="link"
                    disabled={checkArrayLength('ascend', question.title)}
                    icon={<UpOutlined />}
                    onClick={() =>
                      changeIndexQuestionOption(question.title, 'ascend')
                    }
                  />
                  <Button
                    type="link"
                    disabled={checkArrayLength('descend', question.title)}
                    onClick={() =>
                      changeIndexQuestionOption(question.title, 'descend')
                    }
                    icon={<DownOutlined />}
                  />
                </Tooltip>
              </>
            )
          }
        ]
      : [])
  ];

  return (
    <>
      <Table
        scroll={{ x: true }}
        dataSource={questionsList}
        columns={columns}
        pagination={false}
      />
      {purpose !== 'show' && (
        <Space>
          <Button
            icon={<PlusOutlined />}
            onClick={() => addQuestionOption()}
            type="link"
          >
            {t('sessions.quizzes.add.line')}
          </Button>
          <Button
            icon={<PlusOutlined />}
            onClick={() => addAnswerOption(indexQuestion)}
            type="link"
          >
            {t('sessions.quizzes.add.column')}
          </Button>
        </Space>
      )}
    </>
  );
};

export default ChoiceGridTable;

ChoiceGridTable.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.object).isRequired,
  setQuestions: PropTypes.func.isRequired,
  indexQuestion: PropTypes.number.isRequired,
  updateQuiz: PropTypes.func.isRequired,
  purpose: PropTypes.string.isRequired,
  refresh: PropTypes.bool.isRequired,
  setRefresh: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  questionsOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  addAnswerOption: PropTypes.func.isRequired,
  deleteAnswerOption: PropTypes.func.isRequired,
  updateAnswerOptions: PropTypes.func.isRequired,
  responses: PropTypes.arrayOf(PropTypes.object).isRequired
};
