import React, { useEffect, useState, useRef } from 'react';
import {
  Button,
  Checkbox,
  Divider,
  List,
  message,
  Popconfirm,
  Table,
} from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  CheckOutlined,
  LeftCircleOutlined,
  RightCircleOutlined,
} from '@ant-design/icons';
import useAuthContext from '../../../contexts/AuthContext';

const AttendanceContributorsRecordTable = ({
  editSignings,
  setEditSignings,
  onSaveSign,
  onSelect,
  slots,
  setSlots,
  contributors,
  modules,
  session,
}) => {
  const { t } = useTranslation();

  const columns = [
    {
      title: 'Nom',
      render: ({ contributor }) =>
        contributor && `${contributor.last_name} ${contributor.first_name}`,
      key: 'name',
    },
    ...(slots &&
      slots.map((slot, index) => ({
        title: (
          <div>
            <span>{moment(slot.date).format('DD-MM-YYYY')}</span>
            <br />
            <span>{slot.type}</span>
            <br />
            <span>{slot.module}</span>
          </div>
        ),
        key: slot.id,
        width: '122px',
        render: ({ contributor }) =>
          editSignings ? (
            <Checkbox
              checked={slot.signings
                ?.map(el => el.user)
                .includes(contributor._id)}
              onClick={checked =>
                onSelect(checked.target.checked, slot, index, contributor._id)
              }
            />
          ) : (
            <>
              {slot.signings.find(el => el.user === contributor._id) &&
              slot.signings.find(el => el.user === contributor._id)
                .signature ? (
                <img
                    src={
                    slot.signings.find(el => el.user === contributor._id)
                      ?.signature
                  }
                  />
              ) : (
                <>
                  {slot.signings.find(el => el.user === contributor._id) && (
                    <CheckOutlined />
                  )}
                </>
              )}
            </>
          ),
      }))),
  ];

  return (
    <>
      <Table
        columns={columns}
        bordered
        dataSource={contributors}
        scroll={{ x: 1500, y: 800 }}
        pagination={false}
        tableLayout="auto"
        className="session-signings-table"
      />
      {editSignings === false ? (
        <Button
          type="primary"
          onClick={() => setEditSignings(!editSignings)}
          style={{ margin: '16px 0', float: 'right' }}>
          Valider manuellement les présences
        </Button>
      ) : (
        <Button
          type="add"
          onClick={onSaveSign}
          style={{ margin: '16px 0', float: 'right' }}>
          Enregistrer
        </Button>
      )}
    </>
  );
};
AttendanceContributorsRecordTable.propTypes = {
  contributors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  modules: PropTypes.arrayOf(PropTypes.shape({})),
};

export default AttendanceContributorsRecordTable;
