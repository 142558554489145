const useListContent = ({ name, description }) => {
  return [
    {
      label: 'skills.form.name',
      content: name,
      span: 3
    },
    {
      label: 'skills.form.description',
      content: description,
      span: 3
    }
  ];
};

export default useListContent;
