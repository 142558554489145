import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import ShowUser from '../users/ShowUser';
import AccessAcount from './AccessAccount';
import CommissionsList from './CommissionsList';
import CreateUpdateSubscribers from '../Subscriptions/CreateUpdateSubscriptions';
import Commissions from '../Prescriber/tabs/Commissions';

const SubscriptionsRouter = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route
        path={`${path}/access-account/create`}
        render={() => (
          <CreateUpdateSubscribers purpose="create" type="access_account" />
        )}
      />
      <Route
        path={`${path}/access-account/edit/:id`}
        render={() => (
          <CreateUpdateSubscribers purpose="edit" type="sponsorship" />
        )}
      />
      <Route
        path={`${path}/access-account/prescriber/edit/:id`}
        render={() => (
          <CreateUpdateSubscribers purpose="edit" type="prescriber" />
        )}
      />
      <Route path={`${path}/access-account/show/:id`} component={ShowUser} />
      <Route
        path={`${path}/admin-sponsorship/commissions-details/:id`}
        component={Commissions}
      />
      <Route path={`${path}/admin-sponsorship`} component={CommissionsList} />
      <Route path={`${path}/access-account`} component={AccessAcount} />
    </Switch>
  );
};

export default SubscriptionsRouter;
