import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { message } from 'antd';
import CreateUpdateContainer from '../../components/CreateUpdateContainer/CreateUpdateContainer';
import useFields from './fields';
import useAuthContext from '../../contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import ErrorStatusCode from '../../utils/ErrorStatusCode';
import { routes } from '../../utils/constants/routes';

const CreateUpdateSession = ({ purpose }) => {
  const [trainingFieldValue, setTrainingFieldValue] = useState(
    '100 - Formation générales'
  );
  const [kanbanCols, setKanbanCols] = useState([]);
  const { id } = useParams();
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const { notification } = ErrorStatusCode();
  const getKanbans = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/kanbans?type=sessions',
      });
      setKanbanCols(data);
    } catch (e) {
      if (e.response) {
        notification(e.response);
      }
    }
  };

  useEffect(() => {
    (async () => {
      await getKanbans();
    })();
  }, []);

  const { fields, isFieldsLoading } = useFields(
    trainingFieldValue,
    setTrainingFieldValue
  );

  const config = {
    onCreateResource: {
      setBody: data => ({
        ...data,
        training_field: trainingFieldValue,
        status: kanbanCols.filter(el => el.label === 'A PLANIFIER')[0]._id,
      }),
    },
  };

  return (
    <div>
      <CreateUpdateContainer
        resource="sessions"
        fields={fields}
        loadingFields={isFieldsLoading}
        config={config}
        purpose={purpose}
        urlGoBack={id
          ? `${routes.SESSIONS}/show/${id}`
          : `${routes.SESSIONS}/show`}
        baseUrl="sessions"
        tradKey="sessions"
        withSubRoutes
      />
    </div>
  );
};
export default CreateUpdateSession;
