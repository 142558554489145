import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, Select, Tag, Form, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';
import { tasksPriorities } from '../../../utils/constants/tagColors';
import NewCompanyDrawer from '../../Donnees/Companies/NewCompanyDrawer';
import useHandleResize from '../../../utils/HandleResize';
import ErrorStatusCode from '../../../utils/ErrorStatusCode';

const { Option } = Select;
const { TextArea } = Input;

const useFields = (visible) => {
  const { message } = useErrorMessage();
  const { notification } = ErrorStatusCode();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [enums, setEnums] = useState({});
  const [kanbanCols, setKanbanCols] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [visibilityCompanyDrawer, setVisibilityCompanyDrawer] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const { width } = useHandleResize();
  const drawerCompanyVisibility = () => {
    setVisibilityCompanyDrawer(!visibilityCompanyDrawer);
  };

  const drawerVisibility = () => {
    drawerCompanyVisibility();
  };

  const getCompanies = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/companies' });
      setCompanies(data);
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  const fields = [
    {
      name: ['name'],
      rules: [{ required: true }]
    },
    {
      name: ['priority'],
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading}>
          {(enums.priorities || []).map((priority) => (
            <Option value={priority} key={priority}>
              <Tag color={tasksPriorities[priority]}>
                {t(`deals.tags.${priority}`)}
              </Tag>
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['status'],
      rules: [{ required: true }],
      input: (
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input?.toLowerCase()) >= 0
          }
          loading={isFieldsLoading}
        >
          {kanbanCols.map(({ _id, label }) => (
            <Option value={_id} key={_id}>
              {label}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['companies'],
      input: (
        <Input.Group compact>
          <NewCompanyDrawer
            isVisible={visibilityCompanyDrawer}
            setVisibilityCompanyDrawer={drawerCompanyVisibility}
            getCompanies={getCompanies}
            refresh={refresh}
            setRefresh={setRefresh}
            setCompany={null}
          />
          <Form.Item name={['companies']}>
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input?.toLowerCase()) >= 0
              }
              loading={isFieldsLoading}
              style={{ width: 217 }}
            >
              {companies.map(({ _id, name }) => (
                <Option value={_id} key={_id}>
                  {name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Button
            type="primary"
            onClick={drawerVisibility}
            style={{
              float: width > 900 ? 'right' : 'none'
            }}
          >
            <span style={{ fontSize: '15px' }}>{t('buttons.create')}</span>
            <PlusOutlined />
          </Button>
        </Input.Group>
      )
    },
    {
      name: ['global_information'],
      rules: [{ required: false }],
      input: <TextArea rows={8} />
    }
  ];

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/deals/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getKanbans = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/kanbans?type=CRM' });
      setKanbanCols(data);
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getEnums();
    await getCompanies();
    await getKanbans();
    setIsFieldsLoading(false);
  }, [visible]);

  useEffect(() => {
    if (visible)
      (async () => {
        await getSelectOptions();
      })();
  }, [getSelectOptions]);

  return { fields, isFieldsLoading };
};

export default useFields;
