import React from 'react';
import { Alert, Button, Card, Col, List, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import PaymentGocardless from '../../payment/PaymentGocardless';
import useAuthContext from '../../../contexts/AuthContext';
import PageHeaderCustom from '../../../components/PageHeader/PageHeader';
import { outOfNavRoutes } from '../../../utils/constants/routes';

const Subscriptions = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { user } = useAuthContext();
  const subscription = user.organization && user.organization.subscription;
  const advantagesLabels = [
    "Nombre d'utilisateurs",
    'Nombre de formateurs',
    'Gestion administrative de A à Z',
    'Nombre de stagiaires / an',
    'CRM et catalogue en ligne',
    'Emargement numérique',
    'Stockage',
    'Sans engagement de durée',
    'Compte gratuit sur 15 jours',
  ];
  const subscriptions = [
    {
      label: 'AISANCE',
      id: 47,
      options: [1, 3, 'OUI', 150, 'OUI', 'OUI', '20 GO', 'OUI', 'OUI'],
      color: '#FFAC6F'
    },
    {
      label: 'CONFORT',
      id: 67,
      options: [2, 10, 'OUI', 300, 'OUI', 'OUI', '250 GO', 'OUI', 'OUI'],
      color: '#FF9E56'
    },
    {
      label: 'EUPHORIE',
      id: 117,
      options: [5, 30, 'OUI', 650, 'OUI', '1 TO', 'OUI', 'OUI'],
      color: '#FF903C'
    },
    {
      label: 'INFINI',
      id: 297,
      options: [
        'illimités',
        'illimités',
        'OUI',
        'illimités',
        'OUI',
        'OUI',
        '1 TO',
        'OUI',
        'OUI'
      ],
      color: '#FF6D00'
    }
  ];

  return (
    <>
      {!user.organization && (
        <Alert
          message={
            <span>
              Vous devez avoir un organisme de formation pour pouvoir vous
              abonner.
              <Button
                onClick={() =>
                  history.push(`${outOfNavRoutes.PROFILE}/company/create`)
                }
                type="link"
              >
                Je crée mon organisme de formation
              </Button>
            </span>
          }
          type="info"
        />
      )}

      <div className="subscription-tab">
        <PageHeaderCustom title={t('profile.panel.subscription')} />
        <Row
          justify="space-around"
          gutter={{
            xs: 8,
            sm: 10,
            md: 10,
            lg: 10,
            xl: 10,
            xxl: 10,
          }}>
          {subscriptions &&
            subscriptions.map((item, index) => (
              <Col className="subscription-wrapper">
                <Card
                  title={item.label}
                  headStyle={{
                    height: subscription ? 'auto' : 158,
                  }}
                  key={index}
                  className={
                    item.label === 'INFINI'
                      ? 'subscription-card pulse'
                      : 'subscription-card'
                  }
                  extra={
                    <span>
                      <span style={{ fontSize: 20 }}>{`${item.id} €`}</span>
                      <span style={{ fontsize: 14 }}> HT / mois</span>
                    </span>
                  }
                  style={{
                    background: item.color,
                    border: `1px solid ${item.color}`,
                  }}>
                  <List
                    dataSource={item.options}
                    renderItem={(option, idx) => (
                      <List.Item
                        style={{
                          padding: 0,
                          margin: 0,
                          border: 0,
                        }}>
                        <p>
                          {advantagesLabels[idx]} :
                          <strong style={{ marginLeft: 8 }}>{option}</strong>
                        </p>
                      </List.Item>
                    )}
                  />
                  {!subscription && user.organization ? (
                    <PaymentGocardless
                      title={subscription}
                      amount={item.id * 1.2}
                      type="subscription"
                      subscriptionLabel={item.label}
                    />
                  ) : (
                    !user.organization && (
                      <Row justify="center" className="payment-btn-row">
                        <Button
                          onClick={() =>
                            history.push(
                              `${outOfNavRoutes.PROFILE}/company/create`
                            )
                          }
                          type="primary">
                          Je crée mon organisme de formation
                        </Button>
                      </Row>
                    )
                  )}
                </Card>
              </Col>
            ))}
        </Row>
      </div>
    </>
  );
};

export default Subscriptions;
