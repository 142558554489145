import React, { useState } from 'react';
import {
  Space,
  Button,
  Card,
  List,
  Popconfirm,
  TimePicker,
  Tag,
  Checkbox,
  Row,
  Col,
} from 'antd';
import {
  DeleteOutlined,
  PlusOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const SlotsFormList = ({ slots, setSlots, dates, deleteSubSlot }) => {
  const { t } = useTranslation();
  const { RangePicker } = TimePicker;

  console.log('slots', slots);
  const selectTime = (values, subSlot, indexSlot) => {
    const tmpSlots = [...slots];
    slots.forEach(slot => {
      slot.sub_slots.map((sub, index) => {
        if (sub.key === subSlot.key) {
          tmpSlots[indexSlot].sub_slots[index].start_time = values[0];
          tmpSlots[indexSlot].sub_slots[index].end_time = values[1];
        }
      });
    });
    setSlots(tmpSlots);
  };

  const tagColor = type => {
    if (type === 'Matin') {
      return 'blue';
    }
    if (type === 'Après midi') {
      return 'gold';
    }
    return 'default';
  };

  const onChangeAllTimesSlots = subSlot => {
    const list = [];
    list[subSlot.key] = true;
    setSlots(
      slots.map(slot => ({
        ...slot,
        sub_slots: slot.sub_slots.map(sub => {
          if (sub.type === subSlot.type) {
            return {
              ...sub,
              start_time: subSlot.start_time,
              end_time: subSlot.end_time,
            };
          }
          return sub;
        }),
      }))
    );
    list[subSlot.key] = false;
  };

  const cardRender = (slot, indexSlot, subSlot, indexSubSlot) => (
    <Card
      style={{ width: '100%' }}
      key={indexSubSlot}
      title={
        <>
          <span style={{ marginRight: 4 }}>
            {moment(slot.date).format('dddd')}
          </span>
          <span>{moment(slot.date).format('LL')}</span>
          <Tag color={tagColor(subSlot.type)}>{subSlot.type}</Tag>
        </>
      }
      extra={
        <Popconfirm
          title={t('datatable.column.action.delete.title')}
          okText={t('datatable.column.action.delete.ok')}
          okButtonProps={{ danger: true }}
          cancelText={t('datatable.column.action.delete.cancel')}
          icon={<WarningOutlined />}
          onConfirm={() => {
            deleteSubSlot(indexSlot, indexSubSlot);
          }}>
          <Button type="link" danger>
            {`${t('buttons.delete')} `}
            <DeleteOutlined />
          </Button>
        </Popconfirm>
      }>
      <div>
        <Space>
          <RangePicker
            format="HH:mm"
            onChange={(_, values) => selectTime(values, subSlot, indexSlot)}
            value={[
              moment(subSlot.start_time, 'HH:mm'),
              moment(subSlot.end_time, 'HH:mm'),
            ]}
          />
          <Button type="link" onClick={() => onChangeAllTimesSlots(subSlot)}>
            Dupliquer l'horaire
          </Button>
        </Space>
      </div>
    </Card>
  );

  const addSlot = (indexSlot, typeSubSlot) => {
    const tmp = [...slots];
    tmp[indexSlot].sub_slots.push({
      key: typeSubSlot === 'Matin' ? `matin${indexSlot}` : `aprem${indexSlot}`,
      type: typeSubSlot,
      start_time: typeSubSlot === 'Matin' ? '09:00' : '14:00',
      end_time: typeSubSlot === 'Matin' ? '12:00' : '17:00',
    });
    setSlots(tmp);
  };

  return (
    <div>
      {slots &&
        slots.map((slot, indexSlot) => {
          return [1, 2].some(i =>
            slots.map(el => el.sub_slots.length).includes(i)
          ) && !slot.sub_slots.length ? (
            <div>
              <Card
                style={{ marginBottom: 10 }}
                title={
                  <>
                    <span style={{ marginRight: 4 }}>
                      {moment(slot.date).format('dddd')}
                    </span>
                    <span>{moment(slot.date).format('LL')}</span>
                  </>
                }>
                <Row justify="space-around">
                  <Button
                    type="link"
                    onClick={() => addSlot(indexSlot, 'Matin')}
                  >
                    <PlusOutlined />
                    Ajouter un créneau le matin
                  </Button>
                  <Button
                    type="link"
                    onClick={() => addSlot(indexSlot, 'Après midi')}
                  >
                    <PlusOutlined />
                    Ajouter un créneau l'après midi
                  </Button>
                </Row>
              </Card>
            </div>
          ) : (
            <>
              {slot.sub_slots.length === 1 ? (
                slot.sub_slots.map((subSlot, indexSubSlot) =>
                  subSlot.type === 'Après midi' ? (
                    <Row justify="space-around" align="middle" gutter={[0, 10]}>
                      {' '}
                      <Col span={12}>
                        <Button
                          type="link"
                          onClick={() => addSlot(indexSlot, 'Matin')}
                        >
                          <PlusOutlined />
                          Ajouter un créneau le matin
                        </Button>
                      </Col>
                      <Col span={12}>
                        {cardRender(slot, indexSlot, subSlot, indexSubSlot)}
                      </Col>
                    </Row>
                  ) : (
                    <Row justify="space-around" align="middle" gutter={[0, 10]}>
                      {' '}
                      <Col span={12}>
                        {cardRender(slot, indexSlot, subSlot, indexSubSlot)}
                      </Col>
                      <Col span={12}>
                        <Button
                          type="link"
                          onClick={() => addSlot(indexSlot, 'Après midi')}
                        >
                          <PlusOutlined />
                          Ajouter un créneau l'après midi
                        </Button>
                      </Col>
                    </Row>
                  )
                )
              ) : (
                <List
                  grid={{ gutter: 16, column: 2 }}
                  dataSource={slot.sub_slots}
                  renderItem={(subSlot, indexSubSlot) => (
                    <List.Item>
                      {cardRender(slot, indexSlot, subSlot, indexSubSlot)}
                    </List.Item>
                  )}
                />
              )}
            </>
          );
        })}
    </div>
  );
};

export default SlotsFormList;
