export const fileTypesSelectValues = ({ t }) => {
  return [
    {
      value: 'convocation',
      label: t('emails.files_type.convocation')
    },
    {
      value: 'subcontracting',
      label: t('emails.files_type.subcontracting')
    },
    {
      value: 'attendance_certificate',
      label: t('emails.files_type.attendance_certificate')
    },
    {
      value: 'training_action_completion',
      label: t('emails.files_type.training_action_completion')
    },
    {
      value: 'programs',
      label: t('emails.files_type.programs')
    }
  ];
};
