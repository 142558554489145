import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button, Divider, Row, Col, Tabs, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { EditOutlined } from '@ant-design/icons';
import moment from 'moment';
import useAuthContext from '../../contexts/AuthContext';
import listContent from './listContent';
import ProfileCompanyCollapse from './components/ProfileCompanyCollapse';
import SubscriptionCard from './components/SubscriptionCard';
import MySubcription from './components/MySubscription';
import { completeRedirectionFlow, pay } from '../payment/GCUtils';
import { outOfNavRoutes } from '../../utils/constants/routes';
import DescriptionList from '../../components/DescriptionList/DescriptionList';
import PageHeaderCustom from '../../components/PageHeader/PageHeader';
import ContentCustom from '../../components/ContentCustom/ContentCustom';
import Account from '../Accounting/Account/Account';
import subscriptions from './utils/subscriptions';
import advantagesLabels from './utils/advantagesLabels';

const { TabPane } = Tabs;

const Profile = () => {
  const { dispatchAPI, user, setUser } = useAuthContext();
  const { t } = useTranslation();
  const location = useLocation();
  const [activeKey, setActiveKey] = useState('1');
  const subscription = user.organization && user.organization.subscription;
  const params = new URLSearchParams(location.search);
  const amount = params.get('amount');
  const label = params.get('label');
  const redirectFlow = params.get('redirect_flow_id');
  const tabIndex = localStorage.getItem('subscriptionIndex') || 1;

  const myAdvantages = subscriptions.find(
    (element) => subscription && element.label === subscription.type
  );

  const handleTabClick = (value) => {
    localStorage.setItem('subscriptionIndex', value);
  };

  useEffect(() => {
    if (amount && label && redirectFlow) {
      (async () => {
        if (
          user.organization &&
          (user.organization.gcMandate === undefined ||
            user.organization.gcMandate === null)
        ) {
          const newUser = await completeRedirectionFlow(
            dispatchAPI,
            user,
            t,
            redirectFlow,
            setUser
          );
          pay(
            'subscription',
            dispatchAPI,
            newUser,
            amount,
            t,
            null,
            label,
            setUser,
            user
          );
        }
      })();
    }
  }, []);

  const handleChange = (value) => {
    setActiveKey(value);
  };

  return (
    <>
      <ContentCustom>
        <PageHeaderCustom
          title={t('profile.title')}
          extra={
            <>
              <Link
                to={{ pathname: `${outOfNavRoutes.PROFILE}/edit/${user._id}` }}
              >
                <Button type="primary">
                  {`${t('buttons.edit')} `}
                  <EditOutlined />
                </Button>
              </Link>
            </>
          }
        />
        <DescriptionList data={listContent(user, t)} translate />
        {user && user.organization && (
          <>
            {user.role === 'users:SUPER-USER' && (
              <Tabs
                activeKey={activeKey}
                defaultActiveKey={tabIndex}
                onTabClick={(value) => handleTabClick(value)}
                onChange={(e) => handleChange(e)}
                className="profile-tabs"
              >
                <TabPane tab={t('company.title')} key="1">
                  <Link
                    to={{
                      pathname: `${outOfNavRoutes.PROFILE}/company/edit/${user
                        .organization._id || user.organization}`
                    }}
                  >
                    <Button
                      type="primary"
                      style={{ float: 'right', marginTop: '-40px' }}
                    >
                      {`${t('buttons.edit')} `}
                      <EditOutlined />
                    </Button>
                  </Link>
                  <ProfileCompanyCollapse user={user} />
                </TabPane>
                <TabPane
                  tab={t('profile.panel.subscription')}
                  key="2"
                  className="subscription-tab"
                >
                  {user?.organization?.gcMandate && subscription && (
                    <Col className="subscription-wrapper">
                      <PageHeaderCustom
                        title={t('profile.panel.mySubscription')}
                      />
                      <MySubcription
                        subscription={subscription}
                        myAdvantages={myAdvantages}
                        advantagesLabels={advantagesLabels}
                        user={user}
                      />
                    </Col>
                  )}
                  <PageHeaderCustom title={t('profile.panel.subscription')} />
                  {user?.organization?.date_subcription_canceled && (
                    <Tag
                      color="orange"
                      style={{
                        marginBottom: '15px'
                      }}
                    >
                      {t('profile.cancelled')}
                      {moment(
                        user?.organization?.date_subcription_canceled
                      ).format('DD-MM-YYYY')}
                    </Tag>
                  )}
                  <Row
                    justify="space-around"
                    gutter={{
                      xs: 8,
                      sm: 10,
                      md: 10,
                      lg: 10,
                      xl: 10,
                      xxl: 10
                    }}
                  >
                    {subscriptions &&
                      subscriptions.map((item, index) => (
                        <Col className="subscription-wrapper">
                          <SubscriptionCard
                            item={item}
                            index={index}
                            subscription={subscription}
                            advantagesLabels={advantagesLabels}
                          />
                        </Col>
                      ))}
                  </Row>
                </TabPane>
                <TabPane tab={t('profile.panel.accessAccount')} key="3">
                  <Account setActiveKey={setActiveKey} />
                </TabPane>
              </Tabs>
            )}
          </>
        )}
        <Divider orientation="left" style={{ marginTop: 24 }}>
          {t('profile.settings.title')}
        </Divider>
        <div>
          <Link to={`${outOfNavRoutes.PROFILE}/change-pwd`}>
            <Button type="link">{t('profile.settings.changePwd')}</Button>
          </Link>
        </div>
        {user.role === 'users:PRESCRIBER' && user.mandateLink && (
          <div>
            <a href={user.mandateLink}>
              <Button type="link">
                {t('profile.settings.enter_bank_details')}
              </Button>
            </a>
          </div>
        )}
      </ContentCustom>
    </>
  );
};

export default Profile;
