import React, { useEffect, useState } from 'react';
import { Col, List, message, Row, Table } from 'antd';
import { useParams } from 'react-router-dom';
import Columns from './Columns';
import useAuthContext from '../../../contexts/AuthContext';
import QuantityRateItem from './QuantityRateItem';

const ItemsTable = ({
  session,
  selectedRowKeys,
  setSelectedRowKeys,
  selectedRowItems,
  setSelectedRowItems,
  listData,
  invoice,
  setListData,
}) => {
  const columns = Columns();
  const params = useParams();
  const { dispatchAPI } = useAuthContext();
  const [modules, setModules] = useState([]);
  const [finalItemsData, setFinalItemsData] = useState([]);
  const [invoices, setInvoices] = useState([]);

  const getModules = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/module?session=${session._id}`,
      });
      setModules(data);
    } catch (e) {
      if (e.response) {
        message(
          e.response.status === 400
            ? 'Requête invalide'
            : e.response.status === 401
            ? 'Vous devez vous authentifier pour accèder à la ressource'
            : e.response.status === 403
            ? "Vous n'avez pas les droits sur cette ressource"
            : e.response.status === 404
            ? 'Ressource non trouvée'
            : e.response.status === 498
            ? 'Session expirée'
            : e.response.status === 405
            ? "Cette requete n'est pas prise en charge par le serveur"
            : e.response.status === 409
            ? "Cette requête rentre en conflit avec l'état actuel du serveur"
            : e.response.status
        );
      }
    }
  };

  const getInvoices = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/invoices?session=${session._id}&customer=${params.id}`,
      });
      setInvoices(data);
    } catch (e) {}
  };

  useEffect(() => {
    (async () => {
      if (Object.keys(session).length) await getModules();
      await getInvoices();
    })();
  }, [session]);
  let TTC = 0;
  useEffect(() => {
    const list = [];
    const listHours = [];
    modules.forEach(module => {
      let nb_hours = 0;
      module.slots.date.forEach(date => {
        date.sub_slots.forEach(subSlot => {
          nb_hours += parseInt(subSlot.end_time) - parseInt(subSlot.start_time);
          listHours[module._id] = nb_hours;
        });
      });

      module.rates
        .filter(rate =>
          invoices.every(invoice =>
            invoice.items.every(item => item.key !== rate._id)
          )
        )
        .forEach(rate => {
          const nber = QuantityRateItem(
            module,
            session.customers.find(
              c =>
                c &&
                c.customer &&
                typeof params !== 'string' &&
                c.customer._id.toString() === params?.id.toString()
            ) || {},
            rate
          );
          if (
            rate &&
            rate.company_rate &&
            rate.private_rate &&
            rate.independent_rate
          ) {
            if (params?.ref === 'Company') {
              TTC +=
                parseFloat(rate.company_rate) * nber * (1 + rate.tva / 100);
              list.push({
                key: rate._id,
                name: rate.name,
                rate_type: rate.rate_type,
                quantity: parseInt(nber, 10),
                rate: rate.company_rate,
                tva: rate.tva,
                total_ht: parseFloat(
                  parseFloat(rate.company_rate, 10) * nber,
                  10
                ).toFixed(2),
                ttva: parseFloat(
                  parseFloat(rate.company_rate, 10) *
                    nber *
                    (1 + rate.tva / 100),
                  10
                ).toFixed(2),
                ttc: TTC,
              });
            } else if (params?.ref === 'Trainees') {
              TTC +=
                parseFloat(rate.private_rate) * nber * (1 + rate.tva / 100);

              list.push({
                key: rate._id,
                name: rate.name,
                rate_type: rate.rate_type,
                quantity: nber,
                rate: rate.private_rate,
                tva: rate.tva,
                total_ht: parseFloat(
                  parseFloat(rate.private_rate) * nber,
                  10
                ).toFixed(2),
                ttva: parseFloat(
                  parseFloat(rate.private_rate) * nber * (1 + rate.tva / 100),
                  10
                ).toFixed(2),
                ttc: TTC,
              });
            } else if (params?.ref === 'INDEPENDENT') {
              TTC +=
                parseFloat(rate.independent_rate) * nber * (1 + rate.tva / 100);

              list.push({
                key: rate._id,
                name: rate.name,
                quantity: parseInt(nber, 10),
                rate: rate.independent_rate,
                tva: rate.tva,
                total_ht: parseFloat(
                  parseFloat(rate.independent_rate) * nber,
                  10
                ).toFixed(2),
                ttva: parseFloat(
                  parseFloat(rate.independent_rate, 10) *
                    nber *
                    (1 + rate.tva / 100),
                  10
                ).toFixed(2),
                ttc: TTC,
              });
            }
          } else {
            TTC += parseFloat(rate.rate) * nber * (1 + rate.tva / 100);

            list.push({
              key: rate._id,
              name: rate.name,
              rate_type: rate.rate_type,
              quantity: parseInt(nber, 10),
              rate: rate.rate,
              tva: rate.tva,
              total_ht: parseFloat(parseFloat(rate.rate) * nber, 10).toFixed(2),
              ttva: parseFloat(
                parseFloat(rate.rate * nber, 10) * (1 + rate.tva / 100),
                10
              ).toFixed(2),
              ttc: TTC,
            });
          }
        });
    });
    setFinalItemsData(list);
  }, [modules]);

  useEffect(() => {
    const list = [];
    let total_ht = 0;
    let totalTVA = 0;
    list.push({
      key: 'total_ht',
      title: 'Total HT',
      rate: 0,
    });
    finalItemsData.forEach(rate => {
      total_ht += parseFloat(rate.total_ht);
      if (!list.find(el => el.key === rate.tva) && rate.tva !== 0) {
        list.push({
          key: rate.tva,
          title: `Total TVA ${rate.tva} %`,
          rate: 0,
        });
      }
    });

    list.forEach((el, index) => {
      if (el.key === 'total_ht') {
        list[index].rate = parseFloat(total_ht, 10).toFixed(2);
      }
    });

    finalItemsData.forEach(rate => {
      list.forEach((el, index) => {
        if (el.key === rate.tva) {
          list[index].rate += (rate.total_ht * rate.tva) / 100;
        }
      });

      if (rate.tva) {
        totalTVA += rate.total_ht * (1 + rate.tva / 100);
      } else totalTVA += rate.total_ht;
    });
    list.push({
      key: 'total_ttc',
      title: 'Total TTC',
      rate: parseFloat(totalTVA).toFixed(2),
    });
    setListData(list);
  }, [finalItemsData, modules]);

  const onSelectChange = (keys, items) => {
    setSelectedRowKeys(keys);
    setSelectedRowItems(items);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (keys, items) => onSelectChange(keys, items),
  };
  return (
    <>
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={finalItemsData}
        pagination={false}
      />
      <Row>
        <Col offset={16} span={8}>
          <List
            size="small"
            dataSource={listData}
            renderItem={item => (
              <List.Item key={item.key}>
                <List.Item.Meta title={item.title} />
                <div>{item.rate}</div>
              </List.Item>
            )}
          />
        </Col>
      </Row>
    </>
  );
};

export default ItemsTable;

// modules.forEach((module, index) => {
//   module.rates.forEach(rate => {
//     const number =
//       rate.customer_type === 'TRAINEE_DATE' || rate.customer_type === 'DATE'
//         ? modules[index].slots.date.length
//         : rate.customer_type === 'TRAINEE_HOUR' ||
//           rate.customer_type === 'HOUR'
//         ? listHours[module._id]
//         : 1;
//     if (typeof rate.company_rate === 'number') {
//       list.push({
//         key: rate._id,
//         name: rate.name,
//         rate_type: rate.rate_type,
//         quantity: number,
//         rate: rate.company_rate,
//         tva: rate.tva,
//         total_ht: rate.company_rate * number,
//       });
//     } else {
//       list.push({
//         key: rate._id,
//         name: rate.name,
//         rate_type: rate.rate_type,
//         quantity:
//           rate.customer_type === 'TRAINEE_DATE' ||
//           rate.customer_type === 'DATE'
//             ? modules[index].slots.date.length
//             : rate.customer_type === 'TRAINEE_HOUR' ||
//               rate.customer_type === 'HOUR'
//             ? listHours[module._id]
//             : 1,
//         rate: rate.rate,
//         tva: rate.tva,
//         total_ht: rate.rate * number,
//       });
//     }
//   });
// });
