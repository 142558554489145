import React, { useEffect, useState } from 'react';
import { Column, Pie, measureTextWidth } from '@ant-design/charts';
import { Col, Row, Select, PageHeader, Spin } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../contexts/AuthContext';

const { Option } = Select;

const Session = ({ tab }) => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [dataPaye, setDataPaye] = useState([]);
  const [dataCours, setDataCours] = useState([]);
  const [dataRefus, setDataRefus] = useState([]);
  const [devis, setDevis] = useState([]);
  const [modules, setModules] = useState([]);
  const [nbDevisEmis, setNbDevisEmis] = useState(0);
  const [nbSessionCours, setNbSessionCours] = useState(0);
  const [nbDevisPaye, setNbDevisPaye] = useState(0);
  const [nbDevisRefus, setNbDevisRefus] = useState(0);
  const { dispatchAPI } = useAuthContext();
  const [loading, setLoading] = useState(true);

  const getSessions = async () => {
    setLoading(true);
    const module = await dispatchAPI('GET', {
      url: '/module?fields=session,slots.start_date, slots.end_date'
    });
    setModules(module.data);

    const { data: sessionData } = await dispatchAPI('GET', {
      url: '/sessions?fields=created_at,customers'
    });
    let res = [];
    res = sessionData.map((el) => ({
      ...el,
      month: moment(el.created_at)
        .format('DD-MM-YYYY')
        .split('-')[1],
      type: moment(el.created_at)
        .format('DD-MM-YYYY')
        .split('-')[2]
    }));
    setDevis(res);
    setLoading(false);
  };
  const monthTab = [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Decembre'
  ];
  const getDefaultData = (year) => {
    const date = [];
    for (let i = 0; i < 12; i++) {
      date.push({
        month: `${i + 1}`,
        type: year,
        value: 0
      });
    }

    return date;
  };

  useEffect(() => {
    filterColumnChartByYear(moment().year());
  }, [devis]);

  useEffect(() => {
    if (tab !== 'Sessions') return;
    (async () => {
      await getSessions();
    })();
  }, [tab]);

  const renderStatistic = (containerWidth, text, style) => {
    const _measureTextWidth = (0, measureTextWidth)(text, style);
    const textWidth = _measureTextWidth.width;
    const textHeight = _measureTextWidth.height;
    const R = containerWidth / 2;
    let scale = 1;
    if (containerWidth < textWidth) {
      scale = Math.min(
        Math.sqrt(
          Math.abs(
            Math.pow(R, 2) /
              (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2))
          )
        ),
        1
      );
    }

    const textStyleStr = 'width:'.concat(containerWidth, 'px;');
    return '<div style="'
      .concat(textStyleStr, ';font-size:')
      .concat(scale, 'em;line-height:')
      .concat(scale < 1 ? 1 : 'inherit', ';">')
      .concat(text, '</div>');
  };

  const configPie = {
    appendPadding: 10,
    angleField: 'value',
    colorField: 'type',
    color: ({ type }) => {
      if (type === 'En cours') {
        return '#ADC1E1';
      }
      if (type === 'Terminé') {
        return '#2B3E5C';
      }
      return '#4F71A8';
    },
    legend: false,
    radius: 1,
    innerRadius: 0.8,
    meta: {
      value: {
        formatter: function formatter(v) {
          return ''.concat(v, ' \xA5');
        }
      }
    },
    label: {
      type: 'inner',
      offset: '-50%',
      style: { textAlign: 'center' },
      autoRotate: false,
      content: '{value}'
    },
    statistic: {
      title: {
        offsetY: -4,
        customHtml: function customHtml(container, view, datum) {
          const _container$getBoundin = container.getBoundingClientRect();
          const width = _container$getBoundin.width;
          const height = _container$getBoundin.height;
          const d = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2));
          const text = datum ? datum.type : 'Total';
          return renderStatistic(d, text, { fontSize: 28 });
        }
      },
      content: {
        offsetY: 4,
        style: { fontSize: '32px' },
        customHtml: function customHtml(container, view, datum, data) {
          const _container$getBoundin2 = container.getBoundingClientRect();
          const width = _container$getBoundin2.width;
          const text = datum
            ? ''.concat(datum.value).concat('')
            : ''
                .concat(
                  data.reduce(function(r, d) {
                    return r + d.value;
                  }, 0)
                )
                .concat('');
          return renderStatistic(width, text, { fontSize: 32 });
        }
      }
    },
    interactions: [
      { type: 'element-selected' },
      { type: 'element-active' },
      { type: 'pie-statistic-active' }
    ]
  };
  const config2 = {
    xField: 'month',
    yField: 'value',
    isGroup: true,
    seriesField: 'name',
    label: {
      position: 'middle',
      layout: [
        { type: 'interval-adjust-position' },
        { type: 'interval-hide-overlap' },
        { type: 'adjust-color' }
      ]
    },
    color: ({ name }) => {
      if (name === 'En cours') {
        return '#ADC1E1';
      }
      if (name === 'Terminé') {
        return '#2B3E5C';
      }
      return '#4F71A8';
    }
  };

  const filterColumnChartByYear = (yearSelected) => {
    const year = [];
    let newDataEmis = [];
    let newDataPas = [];
    let newDataTerm = [];
    let newDataCours = [];

    let nombreEmis = 0;
    let nombrePaye = 0;
    let nombreRefus = 0;
    let nombreCours = 0;

    devis.forEach((dev) => {
      if (!year.includes(dev.type)) {
        year.push(dev.type);
        newDataEmis = [...newDataEmis, ...getDefaultData(dev.type)];
        newDataPas = [...newDataPas, ...getDefaultData(dev.type)];
        newDataTerm = [...newDataTerm, ...getDefaultData(dev.type)];
        newDataCours = [...newDataCours, ...getDefaultData(dev.type)];
      }
    });
    devis.forEach(async (el) => {
      const moduleForsession = modules.filter((mod) => mod.session === el._id);

      newDataEmis.forEach((nd, index) => {
        if (
          nd.type === el.type &&
          el.type === yearSelected.toString() &&
          (nd.month === el.month || `0${nd.month}` === el.month)
        ) {
          nombreEmis++;
          newDataEmis[index].value = newDataEmis[index].value + 1;
        }
      });
      newDataPas.forEach((nd, index) => {
        if (
          nd.type === el.type &&
          el.type === yearSelected.toString() &&
          (nd.month === el.month || `0${nd.month}` === el.month)
        ) {
          let commence = false;
          let fini = true;
          moduleForsession.forEach((mod) => {
            if (moment(mod.slots.start_date).isBefore(moment())) {
              if (moment(mod.slots.end_date).isAfter(moment())) {
                fini = false;
              }
              commence = true;
            }
          });
          if (commence) {
            if (fini) {
              newDataTerm[index].value = newDataTerm[index].value + 1;
              nombreRefus++;
            } else {
              newDataCours[index].value = newDataCours[index].value + 1;
              nombreCours++;
            }
          } else {
            newDataPas[index].value = newDataPas[index].value + 1;
            nombrePaye++;
          }
        }
      });
    });

    newDataPas = newDataPas.map((el) => ({
      ...el,
      name: 'Non commencé'
    }));
    newDataCours = newDataCours.map((el) => ({
      ...el,

      name: 'En cours'
    }));
    newDataTerm = newDataTerm.map((el) => ({
      ...el,

      name: 'Terminé',
      value: el.value > 0 ? el.value : ''
    }));
    newDataTerm = [...newDataCours, ...newDataPas, ...newDataTerm];
    setNbDevisEmis(nombreEmis);
    setNbDevisPaye(nombrePaye);
    setNbDevisRefus(nombreRefus);
    setNbSessionCours(nombreCours);
    setDataPaye(newDataPas);
    setDataRefus(
      newDataTerm.filter((el) => parseInt(el.type, 10) === yearSelected)
    );
    setDataCours(newDataCours);
    setData(newDataEmis);
    setLoading(false);
  };

  const extra = [
    <Select
      style={{ width: '100px' }}
      defaultValue={moment().year()}
      onChange={(value) => filterColumnChartByYear(value)}
    >
      {[...new Set(data.map((el) => parseInt(el.type, 10)))].map((e) => (
        <Option value={e} key={e}>
          {`${e}`}
        </Option>
      ))}
    </Select>
  ];

  return (
    <>
      <PageHeader title={t('dashboards.sessions.number')} extra={extra} />
      <Row>
        {!loading ? (
          <>
            <Col
              xl={18}
              xs={24}
              sm={24}
              style={{ padding: '0 16px 20px 24px' }}
            >
              <Column
                {...{
                  ...config2,
                  data: dataRefus.map((el) => ({
                    ...el,
                    month: monthTab[el.month - 1]
                  }))
                }}
              />
            </Col>
            <Col xl={6} xs={24} sm={24} style={{ padding: '0 16px 20px 28px' }}>
              <Pie
                {...{
                  ...configPie,
                  data: [
                    {
                      type: 'En cours',
                      value: nbSessionCours
                    },
                    {
                      type: 'Terminé',
                      value: nbDevisRefus
                    },
                    {
                      type: 'Non commencé',
                      value: nbDevisPaye
                    }
                  ]
                }}
              />
            </Col>
          </>
        ) : (
          <>
            <Col
              xl={18}
              xs={24}
              sm={24}
              style={{ padding: '0 16px 20px 24px' }}
            >
              <Spin />
              <Column
                {...{
                  ...config2,
                  data: dataRefus.map((el) => ({
                    ...el,
                    month: monthTab[el.month - 1]
                  }))
                }}
              />
            </Col>
            <Col xl={6} xs={24} sm={24} style={{ padding: '0 16px 20px 28px' }}>
              <Spin />
            </Col>
          </>
        )}
      </Row>
    </>
  );
};

export default Session;

Session.propTypes = {
  refresh: PropTypes.bool,
  tab: PropTypes.string
};

Session.defaultProps = {
  refresh: false,
  tab: ''
};
