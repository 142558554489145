import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Form, message, Switch, Typography } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useHistory, useLocation } from 'react-router-dom';
import useGenerateFormItem from '../../../utils/GenerateFormItem';
import useFields from './RatesFields';
import { formItemLayout } from '../../../utils/constants/formLayout';
import useAuthContext from '../../../contexts/AuthContext';
import PageHeaderCustom from '../../../components/PageHeader/PageHeader';
import { ContentCustom } from '../../../components';
import ErrorStatusCode from '../../../utils/ErrorStatusCode';

const ModifyRatesForm = ({ purpose }) => {
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const { fields } = useFields();
  const { notification } = ErrorStatusCode();
  const history = useHistory();
  const loc = useLocation();
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const generateFields = useGenerateFormItem();
  const [isLoading, setIsLoading] = useState(false);
  const [module, setModule] = useState({});
  const splitURL = loc.pathname.split('/');
  const idSession = splitURL[3];
  const idModule = splitURL[5];
  const idRate = splitURL[7];
  const [form] = Form.useForm();
  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/module/${idModule}`,
      });
      setModule(data);
      form.setFieldsValue(data.rates.filter(el => el._id === idRate)[0]);
    } catch (e) {
      if (e.response) notification(e.response);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await fetchData();
    })();
  }, [fetchData]);

  const updateModule = async body => {
    try {
      await dispatchAPI('PATCH', { url: `/module/${idModule}`, body });
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
  };

  const updateRatesModule = async values => {
    const listRates = module.rates;
    const newList = listRates.filter(el => el._id !== idRate);
    const body = { rates: [...newList, values] };
    await updateModule(body);
    history.goBack();
  };
  return (
    <>
      <PageHeaderCustom title={t(`sessions.modules.form.modifyRates`)} />
      <ContentCustom>
        <>
          <Form {...formItemLayout} form={form} onFinish={updateRatesModule}>
            {fields.map(field => generateFields('module' || 'module', field))}
            <Button
              type="add"
              htmlType="submit"
              style={{ float: 'right', marginLeft: 10 }}>
              {t('buttons.save')} <CheckOutlined />
            </Button>
            <Button
              onClick={() => history.goBack()}
              style={{
                float: 'right',
              }}
              type="link"
              danger>
              {`${t('buttons.cancel')}`}
              <CloseOutlined />
            </Button>
          </Form>
        </>
      </ContentCustom>
    </>
  );
};

export default ModifyRatesForm;
