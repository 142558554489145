import React from 'react';
import PropTypes from 'prop-types';
import { List, Row, Col, Input, Button } from 'antd';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';

const TrainingContent = ({ trainingContent, setTrainingContent }) => {
  const addItemToSubTrainingFields = (indexTrainingField) => {
    const training = [...trainingContent];
    training[indexTrainingField].list_items.push({
      title: ''
    });
    setTrainingContent(training);
  };

  const addItemToTrainingFields = () => {
    const training = [...trainingContent];
    training.push({
      title: '',
      list_items: [
        {
          title: ''
        }
      ]
    });
    setTrainingContent(training);
  };

  const deleteItemFromTrainingFields = (indexTrainingField) => {
    setTrainingContent(
      trainingContent.filter((el, index) => index !== indexTrainingField)
    );
  };

  const deleteItemFromSubTrainingFields = (
    indexTrainingField,
    indexSubTrainingField
  ) => {
    const training = [...trainingContent];
    const newList = training[indexTrainingField].list_items.filter(
      (el, index) => index !== indexSubTrainingField
    );

    training[indexTrainingField].list_items = newList;
    setTrainingContent(training);
  };

  const updateTrainingField = (indexTrainingField, value) => {
    const training = [...trainingContent];
    training[indexTrainingField].title = value;
    setTrainingContent(training);
  };

  const updateSubTrainingField = (
    indexTrainingField,
    indexSubTrainingField,
    value
  ) => {
    const training = [...trainingContent];
    training[indexTrainingField].list_items[
      indexSubTrainingField
    ].title = value;
    setTrainingContent(training);
  };

  return (
    <List
      dataSource={trainingContent}
      renderItem={(item, index) => (
        <>
          <Row>
            <Col span={1}>{index + 1}</Col>
            <Col offset={1} span={20}>
              <Input
                onChange={(event) =>
                  updateTrainingField(index, event.target.value)
                }
                value={item.title}
              />
            </Col>
            <Col span={1}>
              <Button
                disabled={index === 0}
                type="link"
                danger
                icon={<MinusCircleOutlined />}
                onClick={() => deleteItemFromTrainingFields(index)}
              />
            </Col>
            <Col span={1}>
              <Button
                type="link"
                icon={<PlusCircleOutlined />}
                onClick={() => addItemToTrainingFields()}
              />
            </Col>
          </Row>
          {item.list_items.map((el, indexEl) => (
            <Row style={{ marginTop: 10, marginBottom: 10, marginLeft: 25 }}>
              <Col span={1}>{`${index + 1}. ${indexEl + 1}`}</Col>
              <Col offset={1} span={20}>
                <Input
                  onChange={(event) =>
                    updateSubTrainingField(index, indexEl, event.target.value)
                  }
                  value={el.title}
                />
              </Col>
              <Col span={1}>
                <Button
                  disabled={indexEl === 0}
                  type="link"
                  danger
                  icon={<MinusCircleOutlined />}
                  onClick={() =>
                    deleteItemFromSubTrainingFields(index, indexEl)
                  }
                />
              </Col>
              <Col span={1}>
                <Button
                  type="link"
                  icon={<PlusCircleOutlined />}
                  onClick={() => addItemToSubTrainingFields(index)}
                />
              </Col>
            </Row>
          ))}
        </>
      )}
    />
  );
};

TrainingContent.propTypes = {
  trainingContent: PropTypes.shape({
    filter: PropTypes.func,
    list_items: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired,
  setTrainingContent: PropTypes.func.isRequired
};

export default TrainingContent;
