import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useFields from './fields';
import CreateUpdateContainer from '../../../components/CreateUpdateContainer/CreateUpdateContainer';
import ContactFormList from '../Contacts/ContactFormList';

const CreateUpdateCompany = ({ purpose }) => {
  const { fields, isFieldsLoading } = useFields();
  const [contacts, setContacts] = useState([]);
  const [companyRepresentative, setCompanyRepresentative] = useState({});

  const populateContact = () => {
    const list = [];
    contacts.forEach((contact, index) => {
      list.push({
        ...contact,
        company_representative: companyRepresentative[index]
      });
    });
    return list;
  };

  useEffect(() => {
    const list = {};
    list[0] = true;
    setCompanyRepresentative(list);
  }, []);

  const checkIfCompanyRepresentative = (representativeList) => {
    let check = false;
    for (const [key, value] of Object.entries(representativeList)) {
      if (value) {
        check = true;
      }
    }
    return check;
  };

  const randomPassword = Math.random()
    .toString(36)
    .slice(-8);

  const config = {
    onCreateResource: {
      setBody: (data) => ({
        ...data,
        contacts: populateContact(),
        verifiedEmail: false,
        role: 'guests:GUEST',
        password: randomPassword
      })
    }
  };
  const extraFields = ContactFormList({
    setContacts,
    companyRepresentative,
    setCompanyRepresentative,
    checkIfCompanyRepresentative
  });

  return (
    <CreateUpdateContainer
      fields={fields}
      loadingFields={isFieldsLoading}
      config={config}
      purpose={purpose}
      formExtra={purpose === 'create' && extraFields}
      baseUrl="companies"
      resource="companies"
      tradKey="companies"
      withSubRoutes
    />
  );
};

CreateUpdateCompany.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdateCompany;
