import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Avatar,
  Badge,
  Button,
  Layout,
  Menu,
  message,
  Row,
  Col,
  Typography,
  Dropdown
} from 'antd';
import {
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
  BellFilled,
  CloseOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../../contexts/AuthContext';
import HeaderDropdown from '../../HeaderDropdown/HeaderDropdown';
import useLanguageContext from '../../../contexts/LanguageContext';
import { useSocketContext } from '../../../contexts/SocketContext';

const { Header } = Layout;
const { Text } = Typography;

const StyledHeader = styled.div`
  background: var(--menuDropdownBackground);
  box-shadow: 0 1px 8px rgba(43, 43, 43, 0.15);
  padding: 0;
  z-index: 8;
  width: 100%;
  position: fixed;
`;

const StyledContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledDropdownContainer = styled.span`
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
  transition: all 0.3s;
  :hover {
    background: var(--contrastBackground);
  }

  button {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 8px;

    p {
      margin-bottom: 0;
    }
  }
`;

const StyledFullNameSpan = styled.span`
  color: var(--textColor);
  font-weight: bold;
`;

const StyledRoleP = styled.p`
  font-size: 10px;
  color: var(--textColor);
`;

const TriggerCollapse = styled.div`
  color: var(--textColor);
  font-size: 20px;
  padding: 0 24px;
  cursor: pointer;
  margin-left: ${(props) => props.marginleft};
  z-index: 10000;
  :hover {
    color: var(--primaryColor);
  }
`;

const NotificationBadge = styled.span`
  background-color: orange;
  border-radius: 50%;
  display: inline-block;
  height: 8px;
  margin-left: 4px;
  width: 8px;
`;

const HeaderLayout = ({
  collapseSider,
  collapsed,
  collapseWidth,
  themeLogo
}) => {
  const history = useHistory();
  const { user, dispatchAPI } = useAuthContext();
  const { dispatch: dispatchLocale } = useLanguageContext();
  const { t } = useTranslation();
  const [marginTrigger, setMarginTrigger] = useState('0px');
  const [notificationMenu, setNotificationMenu] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const { forceRefresh } = useSocketContext();
  const [visible, setVisible] = useState(false);

  const handleVisibleChange = (flag) => {
    setVisible(flag);
  };

  const onProfileMenuClick = ({ key }) => {
    if (key === 'logout') {
      dispatchAPI('LOGOUT');
      message.success(t('login.logoutSuccess'));
    }
  };

  const updateStatus = async (notification, type) => {
    let body = {};
    switch (type) {
      case 'read':
        body = {
          read: {
            ...notification.read,
            [user._id]: true
          }
        };
        break;
      case 'delete':
        body = {
          show: {
            ...notification.show,
            [user._id]: false
          }
        };
        break;
      default:
        return null;
    }
    const { data } = await dispatchAPI('PATCH', {
      url: `notifications/${notification._id}?user=${user._id}`,
      body
    });

    if (data) {
      setNotifications(data);
      setNotificationCount(data.filter((el) => !el.read[user._id]).length);
    }
  };

  useEffect(() => {
    (async () => {
      const { data } = await dispatchAPI('GET', {
        url: `notifications?show.${user._id}=${true}&sort=-created_at`
      });

      if (data.length) {
        setNotifications(data);
        setNotificationCount(
          data.filter((notification) => !notification.read[user._id]).length
        );
      }
    })();
  }, [forceRefresh]);

  const sessionTabs = {
    SIGNING: '7',
    QUIZ: '8'
  };

  useEffect(() => {
    const menu = notifications.length ? (
      <Menu style={{ maxHeight: '400px', overflowY: 'auto' }}>
        {notifications.map((notification) => (
          <Menu.Item key={notification._id}>
            <Row
              gutter={[16, 16]}
              justify="end"
              align="middle"
              onMouseEnter={() => {
                if (!notification.read[user._id]) {
                  updateStatus(notification, 'read');
                }
              }}
            >
              {!notification.read[user._id] ? (
                <Col>
                  <NotificationBadge />
                </Col>
              ) : null}
              <Col
                onClick={() => {
                  localStorage.setItem(
                    'tabIndex',
                    sessionTabs[notification.type]
                  );
                  history.push(`/sessions/show/${notification.session}`);
                }}
              >
                <div
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: notification?.message }}
                />
              </Col>
              <Col>
                <Button
                  type="link"
                  onClick={() => updateStatus(notification, 'delete')}
                >
                  <CloseOutlined />
                </Button>
              </Col>
            </Row>
          </Menu.Item>
        ))}
      </Menu>
    ) : (
      <Menu>
        <Menu.Item key="no-notifications">
          {t('header.menu.notifications.no_notifications')}
        </Menu.Item>
      </Menu>
    );

    setNotificationMenu(menu);
  }, [notifications]);

  const profileMenu = (
    <Menu onClick={onProfileMenuClick}>
      {window.innerWidth <= 992 && user && (
        <Menu.Item key="user">
          <Button
            style={{
              width: '100%',
              height: 50
            }}
            type="link"
          >
            <StyledFullNameSpan>
              {`${user.first_name} ${user.last_name}`}
            </StyledFullNameSpan>
            <StyledRoleP>
              {t(`${user && user.organization && user.organization.name}`)}
            </StyledRoleP>
          </Button>
        </Menu.Item>
      )}
      <Menu.Item key="profile" onClick={() => history.push('/profile')}>
        <Badge
          dot={user.bank_info}
          size="default"
          offset={[15, 0]}
          title={t('profile.messages.bank_details')}
        >
          <UserOutlined
            style={{
              fontSize: 16
            }}
          />
          {t('header.menu.user.profile')}
        </Badge>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="logout">
        <LogoutOutlined
          style={{
            fontSize: 16
          }}
        />
        {t('header.menu.user.logout')}
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    if (collapsed) {
      if (collapseWidth === 0) {
        setMarginTrigger('0px');
      } else {
        setMarginTrigger('80px');
      }
    } else {
      setMarginTrigger('256px');
    }
  }, [collapsed, collapseWidth]);

  return (
    <StyledHeader as={Header}>
      {window.innerWidth < 992 && (
        <TriggerCollapse
          as={collapsed ? MenuUnfoldOutlined : MenuFoldOutlined}
          marginleft={marginTrigger}
          onClick={() => collapseSider(!collapsed)}
        />
      )}
      {themeLogo && (
        <img
          style={{
            width: 100,
            maxHeight: 50,
            position: 'absolute',
            top: 10,
            left: '50%',
            marginLeft: '-50px'
          }}
          src={themeLogo}
          alt="Company logo"
        />
      )}
      <StyledContainer>
        <Dropdown
          overlay={notificationMenu}
          visible={visible}
          onVisibleChange={handleVisibleChange}
          trigger={['click']}
        >
          <Row>
            <Button type="link">
              <Badge count={notificationCount}>
                <BellFilled style={{ fontSize: 22, color: 'black' }} />
              </Badge>
            </Button>
          </Row>
        </Dropdown>
        {/* <Divider /> */}
        <HeaderDropdown overlayMenu={profileMenu}>
          {window.innerWidth <= 992 ? (
            <StyledDropdownContainer>
              <UserOutlined />
            </StyledDropdownContainer>
          ) : (
            <StyledDropdownContainer>
              <Avatar
                size="medium"
                icon={
                  user && user.organization && user.organization.logo ? (
                    ''
                  ) : (
                    <UserOutlined />
                  )
                }
                src={
                  user && user.organization && user.organization.logo
                    ? user.organization.logo
                    : ''
                }
              >
                {user && `${user.first_name[0]}${user.last_name[0]}`}
              </Avatar>
              <Badge
                dot={user.bank_info}
                size="default"
                offset={[0, 0]}
                title={t('profile.messages.bank_details')}
              >
                <Button type="link">
                  <StyledFullNameSpan>
                    {`${user.first_name} ${user.last_name}`}
                  </StyledFullNameSpan>
                  <StyledRoleP>
                    {user && user.organization
                      ? user.organization.name
                      : t(`users.tags.${user.role.split(':')[1]}`)}
                  </StyledRoleP>
                </Button>
              </Badge>
            </StyledDropdownContainer>
          )}
        </HeaderDropdown>
        {/* <HeaderDropdown overlayMenu={test}>
          <StyledDropdownContainer style={{ marginRight: 16 }}>
            <GlobeIcon />
          </StyledDropdownContainer>
        </HeaderDropdown> */}
      </StyledContainer>
    </StyledHeader>
  );
};

HeaderLayout.propTypes = {
  collapseSider: PropTypes.func.isRequired,
  collapsed: PropTypes.bool.isRequired,
  collapseWidth: PropTypes.number.isRequired,
  themeLogo: PropTypes.string
};

HeaderLayout.defaultProps = {
  themeLogo: null
};

export default HeaderLayout;
