import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Datatable } from '../../../components';
import useColumns from './affiliationsColumns';
import { SearchBar } from '../../../utils/constants/customButton';
import useAuthContext from '../../../contexts/AuthContext';

const AffiliationsTable = ({ purpose }) => {
  const [refresh, setRefresh] = useState(false);
  const columns = useColumns(false, setRefresh, refresh);
  const { id } = useParams();
  const { user } = useAuthContext();
  const [searchValue, setSearchValue] = useState(null);

  return (
    <>
      <SearchBar setSearchValue={setSearchValue} defaultValue={searchValue} />
      <Datatable
        resourceName="users"
        populate="organization"
        forceRefresh={refresh}
        extraQuery={`sponsor=${purpose === 'access-account' ? id : user._id}`}
        searchValue={searchValue}
        columns={columns}
        customActionColumn
      />
    </>
  );
};

AffiliationsTable.propTypes = {
  purpose: PropTypes.string
};

AffiliationsTable.defaultProps = {
  purpose: null
};

export default AffiliationsTable;
