export const getTotalHT = ({ customers, creditNotes, session, setTotalHT }) => {
  const creditNote = [];
  customers.map((customer) => {
    creditNote.push(
      creditNotes
        .filter((el) => el?._id)
        .filter(
          (el) =>
            el.session === session._id && el.customer === customer.customer._id
        )
    );
  });

  const totalHT = creditNote.map(
    (element) =>
      element.length &&
      element.map((el) => ({
        credit_note_total_ht: el.total_ht,
        invoice_id: el.invoice._id,
        invoice_total_ht: el.invoice.total_ht,
        customer: el.customer,
        invoice_number: el.invoice?.number
      }))
  );

  const results = {};

  totalHT.forEach((element) => {
    if (!element) return;

    element.forEach((el) => {
      if (!results[el.invoice_id]) {
        // Si l'objet n'existe pas, l'ajouter avec les valeurs initiales
        results[el.invoice_id] = {
          invoice_id: el.invoice_id,
          invoice_total_ht: el.invoice_total_ht,
          customer: el.customer,
          credit_note_total_ht: el.credit_note_total_ht,
          invoice_number: el.invoice_number
        };
      } else {
        // Si l'objet existe déjà, ajouter la valeur de credit_note_total_ht
        results[el.invoice_id].credit_note_total_ht += el.credit_note_total_ht;
      }
    });
  });

  const mergedResults = Object.values(results);

  setTotalHT(mergedResults);
};
