import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Button, Card, List, Row, Col, TimePicker } from 'antd';
import { BankOutlined, UserOutlined } from '@ant-design/icons';
import { AgendaIcon } from '../../utils/constants/customIcon';

const DetailsSession = ({
  sessions,
  modules,
  editSlot,
  setEditSlot,
  selectTime,
  updateSlot,
  idSession,
  idModule,
  idSlot,
  idSubSlot,
  type,
  date,
  startTime,
  endTime
}) => {
  const { RangePicker } = TimePicker;
  const { t } = useTranslation();

  if (sessions?.length) {
    const session = sessions?.filter((el) => el._id === idSession)[0];
    const module = modules?.filter((el) => el._id === idModule)[0];
    return (
      <>
        <div style={{ marginBottom: 16, minWidth: 500 }}>
          <span>
            {`${t('agenda.from')} ${moment(module.slots.start_date).format(
              'DD-MM-YYYY'
            )} ${t('agenda.to')} ${moment(module.slots.end_date).format(
              'DD-MM-YYYY'
            )}`}
          </span>
          <br />
          <b>
            {session &&
              `${session.customers.length} ${t('agenda.customers')} : `}
          </b>
          {session &&
            session.customers &&
            session.customers.map(({ customer }) => (
              <b>
                {`${customer?.name ||
                  `${customer?.last_name} ${customer?.first_name}`}, `}
              </b>
            ))}
        </div>
        <Card
          style={{ border: 'red 1px solid' }}
          title={module.name}
          className="agenda-popover-card"
          extra={
            <>
              <Button type="primary" onClick={() => setEditSlot(!editSlot)}>
                {t('agenda.update')}
              </Button>
            </>
          }
        >
          <Row>
            <Col span={16}>
              <Row>
                <Col span={4}>
                  <AgendaIcon style={{ fontSize: 27 }} />
                </Col>
                <Col span={18}>
                  <span style={{ fontSize: 14 }}>
                    {moment(date).format('DD-MM-YYYY')}
                  </span>
                  {editSlot ? (
                    <>
                      <RangePicker
                        format="HH:mm"
                        onChange={(_, values) =>
                          selectTime(values, idModule, idSlot, idSubSlot, type)
                        }
                        defaultValue={[
                          moment(startTime, 'HH:mm'),
                          moment(endTime, 'HH:mm')
                        ]}
                      />
                      <Button type="primary" onClick={() => updateSlot()}>
                        {t('agenda.save')}
                      </Button>
                    </>
                  ) : (
                    <span style={{ fontSize: 14 }}>
                      {`${startTime} - ${endTime}`}
                    </span>
                  )}
                </Col>
              </Row>
            </Col>
            <Col span={8}>
              <span>{t('agenda.speaker')}</span>
              <List
                dataSource={session && session.contributors}
                split={false}
                size="small"
                renderItem={({ contributor }) => (
                  <List.Item>
                    <span>{`${contributor?.last_name} ${contributor?.first_name}`}</span>
                  </List.Item>
                )}
              />
            </Col>
          </Row>
        </Card>
        <Card title={t('customers.title')}>
          <List
            dataSource={session && session.customers}
            size="small"
            renderItem={(customer) => (
              <List.Item>
                <List.Item.Meta
                  avatar={
                    customer.ref === 'Company' ? (
                      <BankOutlined style={{ fontSize: 16 }} />
                    ) : (
                      <UserOutlined style={{ fontSize: 16 }} />
                    )
                  }
                  title={
                    customer?.customer?.name ||
                    `${customer.customer?.last_name} ${customer.customer?.first_name}`
                  }
                />
                {customer.ref === 'Company' && (
                  <List
                    style={{ width: '40%' }}
                    split={false}
                    dataSource={customer.trainees_list}
                    renderItem={(item) => (
                      <List.Item>{`${item.last_name} ${item.first_name}`}</List.Item>
                    )}
                  />
                )}
              </List.Item>
            )}
          />
        </Card>
      </>
    );
  }
  return null;
};

DetailsSession.propTypes = {
  sessions: PropTypes.element.isRequired,
  modules: PropTypes.element.isRequired,
  editSlot: PropTypes.string.isRequired,
  setEditSlot: PropTypes.func.isRequired,
  selectTime: PropTypes.string.isRequired,
  updateSlot: PropTypes.string.isRequired,
  idSession: PropTypes.string.isRequired,
  idModule: PropTypes.string.isRequired,
  idSlot: PropTypes.string.isRequired,
  idSubSlot: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  startTime: PropTypes.string.isRequired,
  endTime: PropTypes.string.isRequired
};

export default DetailsSession;
