import React from 'react';
import PropTypes from 'prop-types';
import { Button, message, Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../contexts/AuthContext';

const ChangeSubscriptionButton = ({ organization }) => {
  const { dispatchAPI, user, setUser } = useAuthContext();
  const { t } = useTranslation();

  const gcCancelSubscription = async () => {
    try {
      const { data } = await dispatchAPI('POST', {
        url: `/payment-system/gocardless/subscription/${organization.subscription.id}/actions/cancel`,
        body: { organization, user }
      });
      if (data) {
        message.success(t('profile.panel.rescinded_subscription'));
        const newUser = { ...user, organization: data };
        setUser(newUser);
      }
    } catch (e) {
      message(e.response.status);
    }
  };

  const confirm = () => {
    gcCancelSubscription();
  };

  return (
    <Popconfirm
      title={t('profile.rescind_alert')}
      onConfirm={confirm}
      okText={t('profile.yes')}
      cancelText={t('profile.no')}
    >
      <Button type="link">{t('profile.panel.rescind')}</Button>
    </Popconfirm>
  );
};

ChangeSubscriptionButton.propTypes = {
  organization: PropTypes.shape({
    subscription: PropTypes.shape({
      id: PropTypes.string
    })
  }).isRequired
};

export default ChangeSubscriptionButton;
