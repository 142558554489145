import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Input, Select, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import CreateUpdateContainer from '../../../components/CreateUpdateContainer/CreateUpdateContainer';
import useFields from './fields';
import useAuthContext from '../../../contexts/AuthContext';
import { listStudyLevel } from '../../../utils/constants/trainingActionTypes';
import ErrorStatusCode from '../../../utils/ErrorStatusCode';

const { Option } = Select;

const CreateUpdateTrainee = ({ purpose }) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [enums, setEnums] = useState({});
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [selectStatus, setSelectStatus] = useState('Employee');
  const { selectedFormField } = useFields(selectStatus);
  const [phoneNumber, setPhoneNumber] = useState('');
  const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  const { notification } = ErrorStatusCode();

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/trainees/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getEnums();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  const handleChange = (value) => {
    setSelectStatus(value);
  };

  const handleInput = (value) => {
    return value.replace(phoneRegex, '($1) $2-$3');
  };

  const basicContactField = [
    {
      label: 'gender',
      name: ['gender'],
      rules: [{ required: true }],
      input: (
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          loading={isFieldsLoading}
        >
          {(enums.genders || []).map((title) => (
            <Option key={title} value={title}>
              {t(`contacts.form.${title}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['last_name'],
      rules: [{ required: true }]
    },
    {
      name: ['first_name'],
      rules: [{ required: true }]
    },
    {
      name: ['email'],
      rules: [{ required: true }, { type: 'email' }]
    },
    {
      name: ['study_level'],
      input: (
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {listStudyLevel.map((item) => (
            <Option key={item} value={item}>
              {item}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['study_name']
    },
    {
      label: 'number',
      name: ['number'],
      input: <Input type="number" />
    },
    {
      label: 'street',
      name: ['street']
    },
    {
      label: 'additional',
      name: ['additional']
    },
    {
      label: 'postcode',
      name: ['postcode'],
      input: <Input type="number" />
    },
    {
      label: 'city',
      name: ['city']
    },
    {
      label: 'phone_number',
      name: ['phone_number'],
      input: (
        <Input.Group compact>
          <Form.Item noStyle name={['phone_number']}>
            <Input
              pattern="[0][0-9]{9}"
              value={handleInput(phoneNumber)}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </Form.Item>
        </Input.Group>
      )
    }
  ];
  basicContactField.splice(
    4,
    0,
    ...[
      {
        label: 'status',
        name: ['status'],
        input: (
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onChange={handleChange}
            loading={isFieldsLoading}
          >
            {(enums.types || []).map((title) => (
              <Option key={title} value={title}>
                {t(`trainees.form.${title}`)}
              </Option>
            ))}
          </Select>
        )
      },
      ...selectedFormField
    ]
  );

  const randomPassword = Math.random()
    .toString(36)
    .slice(-8);

  const config = {
    onCreateResource: {
      setBody: (data) => ({
        ...data,
        verifiedEmail: false,
        role: 'guests:GUEST',
        password: randomPassword
      })
    }
  };

  return (
    <CreateUpdateContainer
      resource="trainees"
      fields={basicContactField}
      loadingFields={isFieldsLoading}
      config={config}
      purpose={purpose}
      baseUrl="trainees"
      tradKey="trainees"
      withSubRoutes
    />
  );
};

CreateUpdateTrainee.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdateTrainee;
