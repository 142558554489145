import React from 'react';
import TextArea from 'antd/es/input/TextArea';

const useFields = () => {
  const fields = [
    {
      name: ['name'],
      rules: [{ required: true }],
    },
    {
      name: ['description'],
      input: <TextArea />,
    },
  ];

  return {
    fields,
  };
};

export default useFields;
