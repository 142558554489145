import React from 'react';
import PropTypes from 'prop-types';
import useFields from './fields';
import CreateUpdateContainer from '../../../components/CreateUpdateContainer/CreateUpdateContainer';

const CreateUpdateExternalFounders = ({ purpose }) => {
  const { fields, isFieldsLoading } = useFields();

  return (
    <CreateUpdateContainer
      resource="ExternalFounders"
      fields={fields}
      isParentLoading={isFieldsLoading}
      purpose={purpose}
      baseUrl="external-funders"
      withSubRoutes
    />
  );
};

CreateUpdateExternalFounders.propTypes = {
  purpose: PropTypes.string.isRequired,
};

export default CreateUpdateExternalFounders;
