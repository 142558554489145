import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Card, Col, Divider, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { CopyOutlined, SendOutlined } from '@ant-design/icons';
import moment from 'moment';
import numeral from 'numeral';
import AffiliationsTable from './AffiliationsTable';
import useAuthContext from '../../../contexts/AuthContext';
import ErrorStatusCode from '../../../utils/ErrorStatusCode';
import ModalEmail from './modalEmail';
import {
  getPrescriberRedirectionFlow,
  completePrescriberRedirectionFlow
} from '../../payment/GCUtils';

const GlobalView = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const redirectFlow = params.get('redirect_flow_id');
  const { user, setUser, setSession, dispatchAPI } = useAuthContext();
  const [affiliates, setAffiliates] = useState([]);
  const [commissions, setCommissions] = useState([]);
  const [totalMonth, setTotalMonth] = useState([]);
  const [totalYear, setTotalYear] = useState([]);
  const { notification } = ErrorStatusCode();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const getAffiliates = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/users?sponsor=${user._id}&populate=organization,organization.current_subscription`
      });

      setAffiliates(data);
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  const getCommissions = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/commissions?sponsor=${user._id}&populate=sponsored._id,sponsored._id.organization`
      });
      setCommissions(data);
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  useEffect(() => {
    getAffiliates();
    getCommissions();
    if (
      !user.gcMandate &&
      !user.mandateLink &&
      user.role === 'users:PRESCRIBER'
    ) {
      getPrescriberRedirectionFlow({ dispatchAPI, user, setUser });
    }
    if (redirectFlow) {
      (async () => {
        if (user.gcMandate === undefined) {
          await completePrescriberRedirectionFlow({
            dispatchAPI,
            user,
            redirectFlow,
            setRefresh,
            refresh,
            t,
            setUser
          });
        }
      })();
    }
  }, [refresh]);

  useEffect(() => {
    let totM = 0;
    let total = 0;
    commissions.forEach((commission) => {
      if (commission.status === 'PAID') {
        if (
          moment(commission.date).format('MM-YYYY') ===
          moment().format('MM-YYYY')
        ) {
          totM += commission.amount;
        }
        if (
          moment(commission.date).format('YYYY') === moment().format('YYYY')
        ) {
          total += commission.amount;
        }
      }
    });
    setTotalYear(total);
    setTotalMonth(totM);
  }, [commissions]);

  const openLink = () => {
    setSession(null);
    setUser(null);
    window.open(`${window.location.origin}/sponsor/${user._id}`, '_blank');
  };

  return (
    <>
      <ModalEmail
        setIsModalVisible={setIsModalVisible}
        isModalVisible={isModalVisible}
      />
      <Divider orientation="left">
        <span>{t('prescriber.global.link')}</span>
      </Divider>
      <Card>
        <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button onClick={() => openLink()} type="link">
            {`${window.location.origin}/sponsor/${user._id}`}
          </Button>
          <div>
            <Button
              type="link"
              onClick={() =>
                navigator.clipboard.writeText(
                  `${window.location.origin}/sponsor/${user._id}`
                )
              }
              icon={<CopyOutlined style={{ fontSize: 18 }} />}
            />
            <Button
              type="link"
              onClick={() => setIsModalVisible(!isModalVisible)}
              icon={<SendOutlined style={{ fontSize: 18 }} />}
            />
          </div>
        </Row>
        <Row>
          <span>
            {`${t('prescriber.global.affiliates')} : ${affiliates.length}`}
          </span>
        </Row>
      </Card>
      <Row>
        <Col span={14}>
          <Divider orientation="left">
            <span>{t('prescriber.global.affiliations')}</span>
          </Divider>
          <AffiliationsTable />
        </Col>
        <Col offset={1} span={9}>
          <Divider orientation="left">
            <span>{t('prescriber.global.commissions_indicators')}</span>
          </Divider>
          <Card>
            <Row justify="space-around">
              <div>
                <h3>
                  {moment()
                    .format('MMMM YYYY')
                    .toUpperCase()}
                </h3>
                <div
                  style={{
                    marginRight: 24,
                    display: 'flex',
                    background: '#f5f5f5',
                    padding: '8px 16px',
                    borderRadius: 4
                  }}
                >
                  {numeral(totalMonth).format('0[.]00 $')}
                </div>
              </div>
              <div>
                <h3>{`CUMULÉ ${moment().format('YYYY')}`}</h3>
                <div
                  style={{
                    marginRight: 24,
                    display: 'flex',
                    background: '#f5f5f5',
                    padding: '8px 16px',
                    borderRadius: 4
                  }}
                >
                  {numeral(totalYear).format('0[.]00 $')}
                </div>
              </div>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default GlobalView;
