import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Divider, Collapse, Button, List, Row, Col, Alert } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import useAuthContext from '../../../contexts/AuthContext';
import ListQuizzes from './ListQuizzes';
import ModalUpdateNameQuiz from './ModalUpdateNameQuiz';
import { routes } from '../../../utils/constants/routes';
import ErrorStatusCode from '../../../utils/ErrorStatusCode';

const { Panel } = Collapse;
const Quizzes = ({ trainees, session }) => {
  const [refresh, setRefresh] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [type, setType] = useState('');
  const [quizzesResponses, setQuizzesResponses] = useState([]);
  const history = useHistory();
  const [createNew, setCreateNew] = useState(false);
  const [quizzes, setQuizzes] = useState([]);
  const { dispatchAPI } = useAuthContext();
  const { id } = useParams();
  const { t } = useTranslation();
  const { notification } = ErrorStatusCode();
  const [isLoading, setIsLoading] = useState(false);
  const [quizzesModels, setQuizzesModels] = useState([]);

  const getQuizzesResponses = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/quizzes_responses`
      });
      setQuizzesResponses(data);
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  const getQuizzes = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/quizzes?session=${session._id}`
      });
      setQuizzes(
        data.map((el) => ({
          ...el,
          key: el._id
        }))
      );
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };
  const getQuizzesModels = async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/quizzes?filter={"session":{"$exists":false}}'
      });
      setQuizzesModels(
        data
          .filter((el) => !el.session)
          .map((el) => ({
            ...el,
            key: el._id
          }))
      );
    } catch (e) {
      if (e.response) notification(e.response);
    }
    setIsLoading(false);
  };

  const quizzesModel = [
    'pre_training',
    'training_quiz',
    'post_training_j',
    'post_training_month'
  ];

  const quizzesCollapse = [
    'managers_quizzes',
    'contributors_quizzes',
    'funders_quizzes'
  ];

  const showModal = (typeModel) => {
    setType(typeModel);
    setIsVisible(!isVisible);
  };

  useEffect(() => {
    if (!session._id) return;
    (async () => {
      await Promise.all([
        getQuizzes(),
        getQuizzesResponses(),
        getQuizzesModels()
      ]);
    })();
  }, [refresh, session]);

  useEffect(() => {
    if (createNew) {
      const quiz = quizzes[quizzes.length - 1];
      history.push(`${routes.SESSIONS}/show/${id}/quizzes/${quiz._id}`);
      setCreateNew(!createNew);
    }
  }, [createNew, quizzes]);

  return (
    <>
      <ModalUpdateNameQuiz
        idSession={id}
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        quizzes={quizzesModels}
        setRefresh={setRefresh}
        refresh={refresh}
        purpose="create"
        type={type}
        isLoading={isLoading}
      />
      <Row style={{ padding: '0 20px' }}>
        <Col span={24}>
          <Alert
            showIcon
            type="info"
            message="Afin de passer cette étape, vous devez : créer les quatre évaluations pour la session de formation."
          />
        </Col>
      </Row>
      <List
        dataSource={quizzesModel}
        renderItem={(item) => (
          <>
            <Divider orientation="left">
              <span>{t(`sessions.quizzes.models.${item}`)}</span>
            </Divider>
            <Card>
              <ListQuizzes
                trainees={trainees}
                quizzesResponse={quizzesResponses}
                purpose="create"
                quizzes={quizzes.filter(
                  (el) =>
                    el.session &&
                    el.session.toString() === id.toString() &&
                    el.type === item
                )}
                refresh={refresh}
                setRefresh={setRefresh}
                isVisible={isVisible}
                setIsVisible={setIsVisible}
                type={item}
              />
              <Button type="link" onClick={() => showModal(item)}>
                {t(`sessions.quizzes.add.${item}`)}
              </Button>
            </Card>
          </>
        )}
      />

      <Divider orientation="left">
        <span>{t(`Autres évaluations`)}</span>
      </Divider>
      <Card style={{ marginTop: 20 }}>
        <Collapse accordion ghost>
          <Panel
            key="1"
            header={
              <span style={{ fontSize: 15 }}>
                {t("Autres types d'évaluations")}
              </span>
            }
          >
            <List
              dataSource={quizzesCollapse}
              renderItem={(item) => (
                <>
                  <Divider orientation="left">
                    <span style={{ fontSize: 15 }}>
                      {t(`sessions.quizzes.models.${item}`)}
                    </span>
                  </Divider>
                  <Card>
                    <ListQuizzes
                      purpose="create"
                      quizzes={quizzes.filter(
                        (el) =>
                          el.session &&
                          el.session.toString() === id.toString() &&
                          el.type === item
                      )}
                      refresh={refresh}
                      setRefresh={setRefresh}
                      isVisible={isVisible}
                      setIsVisible={setIsVisible}
                      type={item}
                    />
                    <Button type="link" onClick={() => showModal(item)}>
                      {t(`sessions.quizzes.add.${item}`)}
                    </Button>
                  </Card>
                </>
              )}
            />
          </Panel>
        </Collapse>
      </Card>
    </>
  );
};

Quizzes.propTypes = {
  trainees: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  session: PropTypes.shape({
    _id: PropTypes.string.isRequired
  }).isRequired
};

export default Quizzes;
