import { useTranslation } from 'react-i18next';

const useColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('companies.form.name'),
      dataIndex: 'name',
      sorter: true
    },
    {
      title: t('companies.form.nb_sessions'),
      dataIndex: 'nb_sessions',
      sorter: true
    }
  ];
};

export default useColumns;
