import React from 'react';
import { useTranslation } from 'react-i18next';
import columns from './columns';
import SimpleResourceLandingLayout from '../../components/layouts/SimpleResourceLandingLayout';

const ListTAndAs = () => {
  const { t } = useTranslation();
  const headers = [
    {
      label: 'Day',
      key: 'day',
    },
    {
      label: 'Start date',
      key: 'start_date',
    },
    {
      label: 'End date',
      key: 'end_date',
    },
    {
      label: 'Duration',
      key: 'duration',
    },
    {
      label: 'First name',
      key: 'user.first_name',
    },
    {
      label: 'Last name',
      key: 'user.last_name',
    },
  ];

  return (
    <SimpleResourceLandingLayout
      resourceName="times-and-attendances"
      columns={columns(t)}
      headers={headers}
      populate="user"
    />
  );
};

export default ListTAndAs;
