import PropTypes from 'prop-types';

const checkIfConventionFiles = ({
  customersList,
  files,
  setIsConventionFiles,
  setGeneratedFile
}) => {
  const conventionFiles = {};
  const filesList = {};
  customersList.forEach(({ customer, ref, _id }) => {
    if (ref === 'Company') {
      const companyConventionFiles = files
        .filter(
          (f) =>
            f.user &&
            f.user._id.toString() === customer._id.toString() &&
            f.type === 'company_convention'
        )
        .sort((a, b) => new Date(b.date) - new Date(a.date))[0];
      if (companyConventionFiles) {
        conventionFiles[_id] = true;
        filesList[_id] = {
          fileName: companyConventionFiles.filename,
          id: companyConventionFiles._id
        };
      }
    } else {
      const privateConventionFiles = files
        .filter(
          (f) =>
            f.user &&
            f.user._id.toString() === customer._id.toString() &&
            f.type === 'private_convention'
        )
        .sort((a, b) => new Date(b.date) - new Date(a.date))[0];
      if (privateConventionFiles) {
        conventionFiles[_id] = true;
        filesList[_id] = {
          fileName: privateConventionFiles.filename,
          id: privateConventionFiles._id
        };
      }
    }
  });
  setIsConventionFiles(conventionFiles);
  setGeneratedFile(filesList);
};

checkIfConventionFiles.propTypes = {
  customersList: PropTypes.arrayOf({}).isRequired,
  files: PropTypes.arrayOf({}).isRequired,
  setIsConventionFiles: PropTypes.func.isRequired,
  setGeneratedFile: PropTypes.func.isRequired
};

export default checkIfConventionFiles;
