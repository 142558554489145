export const getUpdatedModuleData = async (
  data,
  getCreditNotes,
  setModuleData,
  message
) => {
  try {
    const creditNotes = await getCreditNotes();
    const filteredCreditNotes = creditNotes.filter(
      (item) => item.invoice === data._id
    );

    if (filteredCreditNotes.length) {
      const newDataModule = [];
      data.items.forEach((invoiceItem) => {
        let foundMatchingKey = false;

        const filteredItems = filteredCreditNotes.filter((obj) =>
          obj.items.some((item) => item.key === invoiceItem.key)
        );

        if (filteredItems.length > 1) {
          foundMatchingKey = true;
          const result = filteredItems.map((objet) => {
            const totalQuantity = objet.items.reduce(
              (acc, item) => acc + item.quantity,
              0
            );
            const totalRate = objet.items.reduce(
              (acc, item) => acc + item.quantity * item.rate,
              0
            );
            return {
              ...objet,
              quantity: totalQuantity,
              rate: totalRate
            };
          });

          const totalQuantity = result.reduce(
            (acc, objet) => acc + objet.quantity,
            0
          );
          if (invoiceItem.quantity - totalQuantity > 0) {
            newDataModule.push({
              ...invoiceItem,
              quantity: invoiceItem.quantity - totalQuantity
            });
          }
        } else {
          filteredCreditNotes.forEach((creditNote) => {
            creditNote.items.forEach((creditNoteItem) => {
              if (invoiceItem.key === creditNoteItem.key) {
                foundMatchingKey = true;

                if (invoiceItem.quantity - creditNoteItem.quantity > 0) {
                  if (invoiceItem.quantity !== creditNoteItem.quantity) {
                    newDataModule.push({
                      ...invoiceItem,
                      quantity: invoiceItem.quantity - creditNoteItem.quantity
                    });
                  }
                }
              }
            });
          });
        }

        if (!foundMatchingKey) {
          newDataModule.push(invoiceItem);
        }
      });
      setModuleData(newDataModule);
    } else {
      setModuleData(data.items);
    }
  } catch (e) {
    if (e.response) message.error(e.response);
  }
};
