import React, { useEffect, useState } from 'react';
import useColumns from './columns';
import useAuthContext from '../../../contexts/AuthContext';
import SimpleResourceLandingLayout from '../../../components/layouts/SimpleResourceLandingLayout';
import { useFormatter } from '../../../utils/formatter';
import useErrorMessage from '../../../utils/ErrorMessage';

const QualityIncidents = () => {
  const [enums, setEnums] = useState({});
  const { columns, headers } = useColumns({ enums });
  const { formattedData } = useFormatter();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/quality_incidents/enums'
      });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getEnums();
    })();
  }, []);

  return (
    <SimpleResourceLandingLayout
      resourceName="quality_incidents"
      columns={columns}
      headers={headers}
      formatter={formattedData}
      resourceModelName="QualityIncident"
      withProjectionFields
    />
  );
};

export default QualityIncidents;
