import React from 'react';
import useColumns from './columns';
import { useFormatter } from '../../../utils/formatter';
import SimpleResourceLandingLayout from '../../../components/layouts/SimpleResourceLandingLayout';

const TrainingPlaces = () => {
  const { columns, headers } = useColumns();
  const { formattedData } = useFormatter();

  return (
    <SimpleResourceLandingLayout
      resourceName="training-places"
      columns={columns}
      headers={headers}
      withProjectionFields
      formatter={formattedData}
      resourceModelName="Training-Places"
      templateType="training_places_import_template"
    />
  );
};

export default TrainingPlaces;
