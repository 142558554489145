import React, { useCallback, useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import { useHistory } from 'react-router-dom';
import ScanForm from './ScanForm';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';
import useScanContext from './ScanContext';
import ScanCanvas from './ScanCanvas';
import ScanOutput from './ScanOutput';
import ErrorStatusCode from '../../../utils/ErrorStatusCode'

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const DocumentScan = ({ resource }) => {
  const history = useHistory();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const {
    file,
    base64,
    zone,
    zones,
    output,
    setEnums,
    setOutput,
    setZones,
    setFields,
    loadingFields,
    setLoadingFields,
    setLoadedZones,
    loadedZones,
  } = useScanContext();
  const [isLoading, setLoading] = useState(false);
  const [model, setModel] = useState();
  const [type, setType] = useState();
  const [supplier, setSupplier] = useState();
  const { notification } = ErrorStatusCode();

  const modelParam = resource.endsWith('ies')
    ? `${resource.charAt(0).toUpperCase() + resource.slice(1, -3)}y`
    : resource.charAt(0).toUpperCase() + resource.slice(1, -1);

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/scan/enums' });
      setEnums(data);
      if (modelParam) {
        setModel(modelParam);
        await getModel(modelParam);
      }
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  const getModel = async model => {
    try {
      const { data } = await dispatchAPI('GET', { url: `/scan/${model}` });
      setFields(data);
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  const postScan = async body => {
    try {
      const { data } = await dispatchAPI('POST', { url: '/scan', body });
      setOutput(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const postElement = async (collection, body) => {
    try {
      await dispatchAPI('POST', { url: `/${collection}`, body });
      history.goBack();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const patchZones = async body => {
    try {
      await dispatchAPI('PATCH', { url: '/scan', body });
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getZones = async (model, type, supplier) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/scan/zones?collection_model=${model}&type=${type}&supplier=${supplier}`,
      });
      setLoadedZones(data.zones || []);
      setZones(data.zones || []);
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  const getOptions = useCallback(async () => {
    setLoading(true);
    await getEnums();
    setLoading(false);
  }, []);

  const handleZoneScan = async () => {
    await postScan({ file: base64, zone });
  };

  const handleFileUpload = async () => {
    await getZones(model, type, supplier);
  };

  const handleChange = values => {
    if (values.type) setType(values.type);
    if (values.supplier) setSupplier(values.supplier);
  };

  const handleSubmit = async values => {
    const collection = model.endsWith('y')
      ? `${model.slice(0, -1).toLowerCase()}s`
      : `${model.toLowerCase()}s`;

    Object.keys(values).forEach(k => {
      if (k.includes('.')) {
        const keyParts = k.split('.');
        if (!values[keyParts[0]]) {
          values[keyParts[0]] = {};
        }
        values[keyParts[0]][keyParts[1]] = values[k];
      }
    });

    if (!values.errorFields) {
      await postElement(collection, values);
    }

    if (type && supplier) {
      await patchZones({
        collection_model: model,
        type,
        supplier,
        zones,
      });
    }
  };

  useEffect(() => {
    getOptions();
  }, [getOptions]);

  useEffect(() => {
    if (zone) {
      handleZoneScan();
    }
  }, [zone]);

  useEffect(() => {
    if (loadedZones.length > 0) {
      const tmpLoadingFields = [];
      loadedZones.forEach(zone => {
        tmpLoadingFields.push(zone.key);
      });
      setLoadingFields(tmpLoadingFields);
      loadedZones.forEach(async zone => {
        await postScan({ file: base64, zone });
      });
    }
  }, [loadedZones]);

  useEffect(() => {
    if (output) {
      const tmpLoadingFields = [];
      loadingFields.forEach(field => {
        if (!Object.keys(output.values).includes(field)) {
          tmpLoadingFields.push(field);
        }
      });

      setLoadingFields(tmpLoadingFields);
    }
  }, [output]);

  return (
    <Row>
      <Col span={12}>
        <ScanForm
          onChange={handleChange}
          onFileUpload={handleFileUpload}
          loading={isLoading}
        />
        <ScanOutput onSubmit={handleSubmit} />
      </Col>
      <Col span={12}>
        {file && (
          <>
            <Document file={file} options={{ workerSrc: '/pdf.worker.js' }}>
              <Page pageNumber={1} />
            </Document>
            <ScanCanvas />
          </>
        )}
      </Col>
    </Row>
  );
};

export default DocumentScan;
