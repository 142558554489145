import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Divider, message, Popconfirm, Table, Button } from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  WarningOutlined,
  DownloadOutlined
} from '@ant-design/icons';
import useAuthContext from '../../contexts/AuthContext';
import ErrorStatusCode from '../../utils/ErrorStatusCode';
import deleteResource from './utils/deleteResource';

const iconSize = 14;

const StyledTable = styled.div`
  .rowStyle {
    cursor: pointer;
  }
`;

const Datatable = ({
  setResourceData,
  setResourceNumber,
  resourceName,
  path,
  columns,
  customActionColumn,
  searchValue,
  searchTypeValue,
  populate,
  style,
  extraQuery,
  forceRefresh,
  extraActionColumn,
  deleteAction,
  withEditButton,
  getURLParams,
  scroll,
  isFileGenerate,
  withProjectionFields
}) => {
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  const { t } = useTranslation();
  const { notification } = ErrorStatusCode();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [resources, setResources] = useState([]);
  const [generatedFile, setGeneratedFile] = useState({});
  const [isDownloading, setIsDownloading] = useState(false);
  const [exist, setExist] = useState({});
  const [pagination, setPagination] = useState({
    hideOnSinglePage: true,
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: true
  });

  const fetchData = useCallback(
    async (page = pagination, filters, sorters) => {
      setIsLoading(true);
      const { pageSize, current } = page;
      if (getURLParams) getURLParams(filters, sorters);

      const searchUrl = searchValue
        ? `filter={"$text": {"$search":"${searchValue}", "$searchType":"${searchTypeValue}"}}&`
        : null;

      let sortingParameter;
      if (sorters) {
        if (!sorters.order) {
          sortingParameter = null;
        } else if (sorters.order === 'descend') {
          sortingParameter = `sort=-${sorters.field}&`;
        } else {
          sortingParameter = `sort=${sorters.field}&`;
        }
      }

      let filterParameter = '';
      Object.entries(filters || {}).forEach((el) => {
        if (el[1]) filterParameter += `${el[0]}=${[...el[1]]}&`;
      });
      try {
        let projectionFields;
        if (withProjectionFields) {
          projectionFields = columns
            .map((column) => column.toRequest || column.dataIndex)
            .join(',');
        }

        const { data, headers } = await dispatchAPI('GET', {
          url: `/${resourceName}?${
            extraQuery ? `${extraQuery}&` : ''
          }${sortingParameter || ''}${filterParameter || ''}${
            populate ? `populate=${populate}&` : ''
          }${searchUrl || ''}limit=${pageSize}&skip=${(current - 1) *
            pageSize}${
            withProjectionFields ? `&fields=${projectionFields}` : ''
          }`
        });
        setPagination({
          ...page,
          total: parseInt(headers['x-total-count'], 10)
        });
        setResources(data.map(({ _id, ...d }) => ({ ...d, key: _id })));
        if (setResourceData) {
          setResourceNumber(parseInt(headers['x-total-count'], 10));
          setResourceData(data);
        }
      } catch (e) {
        if (e.response) {
          notification(e.response);
        }
      }
      setIsLoading(false);
    },
    [searchValue, forceRefresh, extraQuery]
  );

  const handlePageChange = async (page, filters, sorters) => {
    const { field, order } = sorters;
    await fetchData(page, filters, { field, order });
  };

  useEffect(() => {
    (async () => {
      await fetchData();
    })();
  }, [fetchData, isFileGenerate]);

  useEffect(() => {
    const exists = {};
    const filesList = {};
    resources.forEach((program, index) => {
      if (resources[index]?.files?.length) {
        const file = program.files.sort(
          (a, b) => new Date(b.date) - new Date(a.date)
        )[0];

        if (file) {
          exists[resources[index].key] = true;
          filesList[resources[index].key] = {
            fileName: file.filename,
            id: file._id._id
          };
        }
      }
    });
    setExist(exists);
    setGeneratedFile(filesList);
  }, [resources, isFileGenerate]);

  const downloadFile = async (id, name) => {
    try {
      const response = await dispatchAPI('GET', {
        url: `/files/${id}`,
        responseType: 'blob'
      });
      const blob = new Blob([response.data], {
        type: response.data.type
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = name;
      a.target = '_blank';
      a.click();
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
    setIsDownloading({ ...isDownloading, [id]: false });
  };

  const actionColumn = [
    {
      key: 'action',
      render: (record) => (
        <div style={{ float: 'right' }}>
          {extraActionColumn ? extraActionColumn(record) : <></>}
          {exist[record.key] && (
            <>
              <Divider type="vertical" />
              <Button
                type="link"
                icon={<DownloadOutlined />}
                onClick={() =>
                  downloadFile(
                    generatedFile[record.key].id,
                    generatedFile[record.key].fileName
                  )
                }
              />
              <Divider type="vertical" />
            </>
          )}
          <Link
            to={{
              pathname: `${path || pathname}/show/${record.key}`
            }}
          >
            <EyeOutlined style={{ fontSize: iconSize }} />
          </Link>
          {withEditButton && (
            <>
              <Divider type="vertical" />
              <Link
                to={{
                  pathname: `${path || pathname}/edit/${record.key}`
                }}
              >
                <EditOutlined style={{ fontSize: iconSize }} />
              </Link>
            </>
          )}
          {deleteAction && (
            <>
              <Divider type="vertical" />
              <Popconfirm
                title={t('datatable.column.action.delete.title')}
                okText={t('datatable.column.action.delete.ok')}
                okButtonProps={{ type: 'danger' }}
                cancelText={t('datatable.column.action.delete.cancel')}
                onConfirm={() =>
                  deleteResource({
                    id: record.key,
                    dispatchAPI,
                    fetchData,
                    resourceName,
                    message
                  })
                }
                icon={<WarningOutlined />}
              >
                <DeleteOutlined
                  style={{ color: 'red', fontSize: iconSize }}
                  type="delete"
                />
              </Popconfirm>
            </>
          )}
        </div>
      )
    }
  ];

  return (
    <>
      <StyledTable
        as={Table}
        style={style}
        rowClassName="rowStyle"
        onRow={({ key }) => ({
          onDoubleClick: () =>
            history.push({
              pathname: `${path || pathname}/show/${key}`
            })
        })}
        dataSource={resources}
        loading={isLoading}
        onChange={handlePageChange}
        pagination={pagination}
        columns={customActionColumn ? columns : [...columns, ...actionColumn]}
        scroll={scroll}
      />
    </>
  );
};

Datatable.propTypes = {
  isFileGenerate: PropTypes.bool,
  setResourceData: PropTypes.func.isRequired,
  setResourceNumber: PropTypes.func,
  resourceName: PropTypes.string.isRequired,
  path: PropTypes.string,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      key: PropTypes.string,
      dataIndex: PropTypes.string,
      render: PropTypes.func,
      sorter: PropTypes.bool,
      filters: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string,
          value: PropTypes.string
        })
      )
    })
  ),
  customActionColumn: PropTypes.bool,
  extraActionColumn: PropTypes.func,
  searchValue: PropTypes.string,
  populate: PropTypes.string,
  style: PropTypes.shape({}),
  extraQuery: PropTypes.string,
  forceRefresh: PropTypes.bool,
  getURLParams: PropTypes.func,
  withEditButton: PropTypes.bool,
  scroll: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number
  }),
  searchTypeValue: PropTypes.string,
  deleteAction: PropTypes.bool,
  withProjectionFields: PropTypes.bool
};

Datatable.defaultProps = {
  searchTypeValue: null,
  isFileGenerate: false,
  scroll: {},
  extraActionColumn: null,
  withEditButton: true,
  path: null,
  columns: [],
  customActionColumn: false,
  populate: null,
  searchValue: null,
  style: null,
  extraQuery: null,
  forceRefresh: null,
  getURLParams: null,
  setResourceNumber: null,
  deleteAction: true,
  withProjectionFields: false
};

export default Datatable;
