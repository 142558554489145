import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Select, Form, Row } from 'antd';
import { Link } from 'react-router-dom';
import TextArea from 'antd/es/input/TextArea';
import CreateUpdateContainer from '../../../components/CreateUpdateContainer/CreateUpdateContainer';
import useFields from './fields';
import useAuthContext from '../../../contexts/AuthContext';
import NewSkillDrawer from '../../Skills/NewSkillDrawer';
import { ButtonAdd } from '../../../utils/constants/customButton';
import ErrorStatusCode from '../../../utils/ErrorStatusCode';

const CreateUpdateContributor = ({ purpose }) => {
  const { notification } = ErrorStatusCode();
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const { fields } = useFields();
  const [skills, setSkills] = useState([]);
  const [visibilitySkillDrawer, setVisibilitySkillDrawer] = useState(false);
  const [selectedSkills, setSelectedSkills] = useState([]);

  const getSkills = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/skills' });
      setSkills(data);
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  const onSelectSkill = (value) => {
    setSelectedSkills([...selectedSkills, value]);
  };
  const onDeselect = (value) => {
    setSelectedSkills(selectedSkills.filter((sk) => sk !== value));
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getSkills();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  const randomPassword = Math.random()
    .toString(36)
    .slice(-8);

  const config = {
    onCreateResource: {
      setBody: (data) => ({
        ...data,
        skills: selectedSkills,
        verifiedEmail: false,
        role: 'guests:GUEST',
        password: randomPassword
      })
    },
    onUpdateResource: {
      setBody: (data) => {
        return {
          ...data,
          skills: selectedSkills
        };
      }
    },
    onGetResource: {
      setFields: (data) => {
        form.setFieldsValue({
          skills: selectedSkills,
          skills_notes: data.skills_notes,
          notes: data.notes
        });
        setSelectedSkills(data.skills);
        return data;
      }
    }
  };

  const drawerVisibility = () => {
    setVisibilitySkillDrawer(!visibilitySkillDrawer);
  };

  const advancedOptions = (
    <>
      <Form.Item name="skills" label={t('skills.title')}>
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          mode="multiple"
          onSelect={onSelectSkill}
          onDeselect={onDeselect}
          value={selectedSkills}
          allowClear
          options={skills.map((skill) => ({
            label: skill.name,
            value: skill._id
          }))}
          loading={isFieldsLoading}
        />

        <Row
          style={{ margin: '10px 0 8px 0' }}
          align="middle"
          justify="space-between"
        >
          <Link to={{ pathname: '/skills' }}>
            <span>{t('contributors.form.manage_skills')}</span>
          </Link>
          <ButtonAdd onClick={drawerVisibility} />
        </Row>
      </Form.Item>
      <Form.Item
        name="skills_notes"
        label={t('contributors.form.skills_notes')}
      >
        <TextArea />
      </Form.Item>
      <Form.Item name="notes" label={t('contributors.form.notes')}>
        <TextArea />
      </Form.Item>
    </>
  );

  return (
    <div>
      <NewSkillDrawer
        isVisible={visibilitySkillDrawer}
        setVisibilitySkillDrawer={drawerVisibility}
        getSkills={getSkills}
      />
      <CreateUpdateContainer
        resource="contributors"
        fields={fields}
        loadingFields={isFieldsLoading}
        formExtra={advancedOptions}
        config={config}
        purpose={purpose}
        baseUrl="contributors"
        tradKey="contributors"
        withSubRoutes
      />
    </div>
  );
};

CreateUpdateContributor.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdateContributor;
