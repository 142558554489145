const useListContent = ({
  name,
  channel_number,
  postal_code,
  siret_number,
  city,
  ape_number,
  street,
  address_details,
  email,
  tva_company,
  representative
}) => {
  return [
    {
      label: 'companies.form.name',
      content: name
    },
    {
      label: 'companies.form.email',
      content: email
    },
    {
      label: 'companies.form.address',
      content: channel_number && street ? `${channel_number} ${street}` : '-'
    },
    {
      label: 'companies.form.address_details',
      content: address_details
    },
    {
      label: 'companies.form.city',
      content: city
    },
    {
      label: 'companies.form.postal_code',
      content: postal_code
    },
    {
      label: 'companies.form.siret_number',
      content: siret_number
    },
    {
      label: 'companies.form.ape_number',
      content: ape_number
    },

    {
      label: 'companies.form.representative',
      content: representative
    },
    { label: 'companies.form.tva_company', content: tva_company }
  ];
};

export default useListContent;
