import React, { useState } from 'react';
import { Modal, Row, Col, Button, message, Input, Alert, Spin } from 'antd';
import {
  EditorState,
  convertToRaw,
  convertFromHTML,
  ContentState
} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { useTranslation } from 'react-i18next';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import PropTypes from 'prop-types';
import TokenInput from 'react-customize-token-input';
import useAuthContext from '../../../contexts/AuthContext';
import ErrorStatusCode from '../../../utils/ErrorStatusCode';
import 'react-customize-token-input/dist/react-customize-token-input.css';

const ModalEmail = ({ setIsModalVisible, isModalVisible }) => {
  const { dispatchAPI, user } = useAuthContext();
  const { t } = useTranslation();
  const { notification } = ErrorStatusCode();
  const [subject, setSubject] = useState('Lien de parainage DEMATERIZ');
  const [emailsAddress, setEmailsAdress] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const html = convertFromHTML(`
    <p>Bonjour,</p>
<p>{{params.sponsor}} veut vous parrainer. Pour bénéficier des avantages veuillez vous enregister via ce lien : {{params.url}}</p>
<p>Cordialement,</p>
<p>--</p>
<p>L'équipe DEMATERIZ<p>`);
  const state =
    html &&
    ContentState.createFromBlockArray(html.contentBlocks, html.entityMap);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(state)
  );

  const sendEmail = async () => {
    try {
      setIsLoading(true);
      await dispatchAPI('POST', {
        url: '/email_sender/sponsorship',
        body: {
          url: `${window.location.origin}/sponsor/${user._id}`,
          sponsor: `${user.last_name} ${user.first_name}`,
          receivers: emailsAddress,
          subject,
          htmlBody: draftToHtml(convertToRaw(editorState.getCurrentContent()))
        }
      });

      if (emailsAddress.length) {
        message.success(t('prescriber.mail.success'));
        setIsModalVisible(!isModalVisible);
      } else {
        message.error(t('prescriber.mail.address_error'));
      }
      setIsLoading(false);
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  const validateEmail = (mail) => {
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    }
    return false;
  };

  const handleGetTokenDisplayLabel = (tokenValue) => {
    const check = validateEmail(tokenValue);
    return (
      <div style={{ color: !check ? 'red' : '' }}>
        {check && (
          <span
            aria-label="user"
            role="img"
            style={{ color: '#ff9900', marginRight: '4px' }}
          >
            👤
          </span>
        )}

        {`${tokenValue}`}
      </div>
    );
  };

  const handleCancel = () => {
    setIsModalVisible(!isModalVisible);
  };

  const handleOk = () => {
    setIsModalVisible(!isModalVisible);
  };

  const handleTokenValuesChange = (values) => {
    setEmailsAdress(values);
  };

  const selectSubject = (value) => {
    setSubject(value);
  };

  return (
    <>
      <Modal
        maskClosable={false}
        visible={isModalVisible}
        onCancel={handleCancel}
        onOk={handleOk}
        width={800}
        footer={false}
      >
        <Row>
          <Col span={4}>
            <span>{t('prescriber.mail.recipients')}</span>
          </Col>
          <Col span={12}>
            <TokenInput
              style={{ height: 60, color: 'black' }}
              tokenValues={emailsAddress}
              onTokenValuesChange={handleTokenValuesChange}
              onGetTokenDisplayLabel={handleGetTokenDisplayLabel}
              separators={[' ']}
            />
          </Col>
        </Row>

        <Row style={{ marginTop: 5 }}>
          <Col span={4}>
            <span>{t('prescriber.mail.object')}</span>
          </Col>
          <Col span={12}>
            <Input
              value={subject}
              onChange={(e) => selectSubject(e.target.value)}
            />
          </Col>
        </Row>

        <Row style={{ marginTop: 5 }}>
          <Col span={24}>
            <Alert
              message={t('prescriber.mail.message')}
              type="info"
              showIcon
            />
          </Col>
        </Row>
        <Row style={{ marginTop: 5 }}>
          <div>
            <Editor
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              editorState={editorState}
              onEditorStateChange={setEditorState}
            />
          </div>
        </Row>
        <Row>
          <Col offset={20} span={4}>
            <Button type="primary" onClick={sendEmail}>
              <Spin style={{ marginRight: 10 }} spinning={isLoading} />
              {t('prescriber.mail.send')}
            </Button>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

ModalEmail.propTypes = {
  isModalVisible: PropTypes.bool.isRequired,
  setIsModalVisible: PropTypes.func.isRequired
};

export default ModalEmail;
