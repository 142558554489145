import React from 'react';
import useColumns from './columns';
import SimpleResourceLandingLayout from '../../../components/layouts/SimpleResourceLandingLayout';

const ListTrainees = () => {
  const { columns, headers } = useColumns();

  return (
    <SimpleResourceLandingLayout
      columns={columns}
      headers={headers}
      resourceName="trainees"
      withProjectionFields
      resourceModelName="Trainees"
      withSubRoutes
      templateType="trainees_import_template"
    />
  );
};

export default ListTrainees;
