import React, { useCallback, useEffect, useState } from 'react';
import { message, Button, Form, Row, Spin, Upload } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import {
  CheckOutlined,
  CloseOutlined,
  LoadingOutlined,
  PlusOutlined
} from '@ant-design/icons';
import { outOfNavRoutes } from '../../utils/constants/routes';
import useAuthContext from '../../contexts/AuthContext';
import useFields from './fields';
import useGenerateFormItem from '../../utils/GenerateFormItem';
import {
  formItemLayout,
  tailFormItemLayout
} from '../../utils/constants/formLayout';
import PageHeaderCustom from '../../components/PageHeader/PageHeader';
import ContentCustom from '../../components/ContentCustom/ContentCustom';
import ErrorStatusCode from '../../utils/ErrorStatusCode';

const { Dragger } = Upload;

const UpdateProfile = () => {
  const history = useHistory();
  const { id } = useParams();
  const { dispatchAPI, setUser, user } = useAuthContext();
  const generateFields = useGenerateFormItem();
  const { t } = useTranslation();
  const { notification } = ErrorStatusCode();
  const [userPhoto, setUserPhoto] = useState();
  const { fields, comptableFields } = useFields({ userPhoto });
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [imageUrl, setImageUrl] = useState('');
  const [base64, setBase64] = useState('');

  const fetchUser = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/users/${id}?populate=organization`
      });
      form.setFieldsValue({
        ...data
      });
      setUser(data);
      setUserPhoto(data.photo);
    } catch (e) {
      if (e.response) notification(e.response);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    (() => fetchUser())();
  }, [fetchUser]);

  // This function convert the PDF to base64 format
  const fileToBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (e) => reject(e);
    });

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const uploadButton = (
    <div>
      {isLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div>
        <p className="ant-upload-text">{t('files.create.action')}</p>
      </div>
    </div>
  );

  const updateProfile = async (values) => {
    try {
      const body = values;
      if (fileList.length > 0) {
        body.photo = await fileToBase64(fileList[0]);
      }
      await dispatchAPI('PATCH', { url: `/users/${id}`, body });
      message.success(t('profile.messages.profile_updated'));
      await fetchUser();
      history.push(outOfNavRoutes.PROFILE);
    } catch (e) {
      if (e.response) {
        if (
          e.response.data.status_code === 400 &&
          e.response.data.errors.email.kind === 'UNIQUE'
        ) {
          message.error(t('profile.messages.existing_email'));
        } else {
          notification(e.response);
        }
      }
    }
  };

  const handleSubmit = async (values) => {
    await updateProfile({ ...values, verified_email: true });
  };

  const draggerProps = {
    name: 'file',
    multiple: false,
    showUploadList: false,
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: async (file) => {
      const fileExtension = file.name.split('.').pop();
      if (
        fileExtension === 'png' ||
        fileExtension === 'PNG' ||
        fileExtension === 'jpg' ||
        fileExtension === 'JPG'
      ) {
        setFileList([...fileList, file]);
        const base = await fileToBase64(file);
        setBase64(base);
        getBase64(file, (image) => {
          setImageUrl(image);
          setIsLoading(false);
        });
        return false;
      }
      message.error(t('profile.messages.wrong_format_file'));
      return true;
    },
    fileList
  };

  return (
    <>
      <PageHeaderCustom title={t('profile.title')} />
      <ContentCustom>
        <Spin spinning={isLoading}>
          <Form form={form} onFinish={handleSubmit} {...formItemLayout}>
            <Form.Item {...formItemLayout} name="photo" label="Avatar">
              <Dragger {...draggerProps}>
                {userPhoto || imageUrl ? (
                  <img
                    src={imageUrl || userPhoto}
                    alt="avatar"
                    style={{ width: '60%' }}
                  />
                ) : (
                  uploadButton
                )}
              </Dragger>
            </Form.Item>
            {fields.map((field) => generateFields('users', field))}
            {user.role === 'users:PRESCRIBER' &&
              comptableFields.map((field) => generateFields('company', field))}
            <Form.Item {...tailFormItemLayout}>
              <Row justify="end">
                <Button
                  style={{ margin: '0 10px' }}
                  type="link"
                  danger
                  onClick={() => history.goBack()}
                >
                  {`${t('buttons.cancel')} `}
                  <CloseOutlined />
                </Button>
                <Button type="add" htmlType="submit">
                  {`${t('buttons.save')} `}
                  <CheckOutlined />
                </Button>
              </Row>
            </Form.Item>
          </Form>
        </Spin>
      </ContentCustom>
    </>
  );
};

export default UpdateProfile;
