import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { routes, subRoutes } from '../../../utils/constants/routes';

const useListContent = ({
  gender,
  first_name,
  last_name,
  email,
  phone_number,
  my_company,
  company_representative
}) => {
  const { t } = useTranslation();
  return [
    {
      label: 'contacts.form.gender',
      content: t(`contacts.form.${gender}`) || ''
    },
    {
      label: 'contacts.form.full_name',
      content: `${first_name} ${last_name}`
    },
    {
      label: 'contacts.form.email',
      content: email
    },
    {
      label: 'contacts.form.phone_number_full',
      span: 2,
      content:
        phone_number && phone_number.number ? (
          <div>
            {`${phone_number.country_code} ${phone_number.number.slice(
              0,
              1
            )} ${phone_number.number.slice(1).replace(/(.{2})/g, '$1 ')}
                    `}
          </div>
        ) : (
          '-'
        )
    },
    {
      label: 'contacts.form.company_representative',
      content: company_representative ? 'Oui' : 'Non'
    },
    {
      label: 'contacts.form.company',
      content: my_company && (
        <Link
          to={{
            pathname: `${routes.DATAS}${subRoutes.DATAS.COMPANIES}/show/${my_company._id}`
          }}
        >
          {my_company.name}
        </Link>
      )
    }
  ];
};

export default useListContent;
