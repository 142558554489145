import React, {
  useState,
  useEffect,
  createContext,
  useCallback,
  useContext
} from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useHistory, useLocation } from 'react-router-dom';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';
import ErrorStatusCode from '../../../utils/ErrorStatusCode';

export const DealsContext = createContext({});

export const DealsContextProvider = ({ children }) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [visible, setVisible] = useState(false);
  const [deals, setDeals] = useState([]);
  const [deal, setDeal] = useState(null);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [searchValue, setSearchValue] = useState(params.get('k'));
  const [kanbanCols, setKanbanCols] = useState([]);
  const [dataRefresh, setDataRefresh] = useState(false);
  const [employees, setEmployees] = useState([]);
  const { notification } = ErrorStatusCode();

  const searchResource = (value) => {
    if (value) {
      history.push({
        pathname,
        search: `?k=${value}`
      });
    } else {
      history.push({
        pathname
      });
    }
  };

  useEffect(() => {
    setSearchValue(params.get('k'));
  }, [params]);

  const fetchData = useCallback(async () => {
    const searchUrl = searchValue
      ? `filter={"$text": {"$search":"${searchValue}"}}&`
      : null;
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/deals?${searchUrl || ''}&populate=lead`
      });
      setDeals(data);
    } catch (e) {
      if (e.response) notification(e.response);
    }
  }, [searchValue, visible]);

  const delDeal = async (id) => {
    try {
      await dispatchAPI('DELETE', { url: `deals/${id}` });
      await fetchData();
      setDataRefresh(!dataRefresh);
    } catch (e) {
      if (e.response) notification(e.response.status);
    }
  };

  const updateDeal = async (id, body) => {
    try {
      await dispatchAPI('PATCH', { url: `/deals/${id}`, body });
      await fetchData();
      setDataRefresh(!dataRefresh);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const fetchKanbanColumns = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/kanbans?type=CRM' });
      setKanbanCols(data);
    } catch (e) {
      if (e.response) notification(e.response.status);
    }
  };

  const getEmployees = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/employees' });
      setEmployees(data);
    } catch (e) {
      if (e.response) notification(e.response.status);
    }
  };

  const fetch = useCallback(async () => {
    await fetchKanbanColumns();
    await getEmployees();
  }, []);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <DndProvider backend={HTML5Backend}>
      <DealsContext.Provider
        value={{
          fetchData,
          delDeal,
          updateDeal,
          deals,
          deal,
          setDeal,
          searchResource,
          searchValue,
          visible,
          setVisible,
          kanbanCols,
          fetchKanbanColumns,
          dataRefresh,
          setDataRefresh,
          employees
        }}
      >
        {children}
      </DealsContext.Provider>
    </DndProvider>
  );
};

export default () => useContext(DealsContext);
