import React, { useCallback, useEffect, useState } from 'react';
import { Button, Form, Divider, message, Popconfirm, Table, Tag } from 'antd';
import { Link } from 'react-router-dom';
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { routes, subRoutes } from '../../../utils/constants/routes';
import useAuthContext from '../../../contexts/AuthContext';
import RatesList from './ratesList';

const ModulesRatesTable = ({
  setForceRefresh,
  forceRefresh,
  mod,
  edit,
  setEdit,
  ratesDelete,
  setRatesDelete,
  refresh,
  setRefresh
}) => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [module, setModule] = useState({});
  const [rates, setRates] = useState([]);
  const [company, setCompany] = useState(false);
  useEffect(() => {
    setRates(
      mod.rates.map(({ _id, ...el }) => ({
        ...el,
        key: _id,
        id: _id
      }))
    );
  }, [mod]);

  useEffect(() => {
    rates.map((el) => (el.company_rate ? setCompany(true) : setCompany(false)));
  }, [rates]);

  const updateModule = async (body) => {
    try {
      await dispatchAPI('PATCH', { url: `/module/${mod._id}`, body });
      setRefresh(!refresh);
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
  };

  const ratesColumns = [
    {
      title: t('modules.form.name'),
      dataIndex: ['name'],
      sorter: true
    },
    ...(company === true
      ? [
          {
            title: t('modules.form.company_rate'),
            dataIndex: ['company_rate'],
            sorter: true
          },
          {
            title: t('modules.form.independent_rate'),
            dataIndex: ['independent_rate'],
            sorter: true
          },
          {
            title: t('modules.form.private_rate'),
            dataIndex: ['private_rate'],
            sorter: true
          }
        ]
      : [
          {
            title: t('modules.form.rate'),
            dataIndex: ['rate'],
            sorter: true
          },
          {
            title: t('company.form.tva_rate'),
            dataIndex: ['tva'],
            sorter: true
          },
          {
            title: t('sessions.modules.customer_type'),
            key: 'customer_type',
            dataIndex: 'customer_type',
            render: (customer_type) =>
              t(`sessions.modules.form.${customer_type}`),
            sorter: true
          }
        ]),
    {
      key: 'action',
      render: ({ key }) => (
        <div>
          <span style={{ float: 'right' }}>
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={async () => {
                await deleteRatesFromModules(key);
              }}
              icon={<WarningOutlined />}
            >
              <DeleteOutlined style={{ color: 'red' }} type="delete" />
            </Popconfirm>
          </span>
        </div>
      )
    }
  ];

  const deleteRatesFromModules = async (key) => {
    const ratesList = rates.filter((el) => el.id.toString() !== key.toString());
    await updateModule({ ...module, purpose: 'delete', rates: ratesList });
    setRatesDelete(!ratesDelete);
    setForceRefresh(!forceRefresh);
    setRefresh(!refresh);
  };
  return (
    <div>
      {edit === true ? (
        <RatesList
          forceRefresh={forceRefresh}
          setForceRefresh={setForceRefresh}
          mod={mod}
          edit={edit}
          setEdit={setEdit}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      ) : (
        <Table columns={ratesColumns} dataSource={rates} pagination={false} />
      )}
    </div>
  );
};

export default ModulesRatesTable;
