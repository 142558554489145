/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { Button, Form, Input, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import useAuthContext from '../../contexts/AuthContext';
import usePwdPattern from '../../utils/pwdPattern';
import {
  formItemLayout,
  tailFormItemLayout,
} from '../../utils/constants/formLayout';
import PageHeaderCustom from '../../components/PageHeader/PageHeader';
import ContentCustom from '../../components/ContentCustom/ContentCustom';
import { outOfNavRoutes } from '../../utils/constants/routes';

const UpdatePwd = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const updatePwd = async body => {
    setIsLoading(true);
    try {
      await dispatchAPI('PATCH', { url: `/users/change-password`, body });
      message.success(t('login.passwordChanged'));
      history.push(outOfNavRoutes.PROFILE);
    } catch (e) {
      if (e.response.status === 401)
        message.error(t('errors.message.wrongPwd'));
      // eslint-disable-next-line no-console
      console.error(e);
    }
    setIsLoading(false);
  };

  const handleSubmit = async values => {
    await updatePwd({
      password: values.password,
      new_password: values.new_password,
    });
  };

  return (
    <>
      <PageHeaderCustom title={t('profile.settings.modifyPwd')} />
      <ContentCustom>
        <Form {...formItemLayout} onFinish={handleSubmit}>
          <Form.Item
            name="password"
            label={t('profile.form.password')}
            rules={[{ required: true, message: t('profile.form.pwdMissing') }]}>
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="new_password"
            label={t('profile.form.new_password')}
            rules={[
              { required: true, message: t('profile.form.pwdMissing') },
              ...usePwdPattern(),
            ]}
            hasFeedback>
            <Input.Password />
          </Form.Item>
          <Form.Item
            dependencies={['new_password']}
            hasFeedback
            name="confirm"
            label={t('profile.form.confirm_password')}
            rules={[
              {
                required: true,
                message: t('profile.form.pwdMissing'),
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('new_password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(t('login.pwdNotMatching'));
                },
              }),
            ]}>
            <Input.Password />
          </Form.Item>
          <Form.Item {...tailFormItemLayout}>
            <Button type="link" danger onClick={() => history.goBack()}>
              {`${t('buttons.cancel')}`}
              <CloseOutlined />
            </Button>
            <Button
              style={{ margin: '0 10px' }}
              type="add"
              htmlType="submit"
              loading={isLoading}>
              {`${t('buttons.save')}`}
              <CheckOutlined />
            </Button>
          </Form.Item>
        </Form>
      </ContentCustom>
    </>
  );
};

export default UpdatePwd;
