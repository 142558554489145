import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Divider, Dropdown, List, Spin, Row, Col } from 'antd';
import {
  DownloadOutlined,
  DownOutlined,
  LoadingOutlined,
  SendOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const AttendanceCertificate = ({
  trainees,
  setSendType,
  showModal,
  loading,
  menuDropDown,
  session,
  listActions,
  downloadFile
}) => {
  const { t } = useTranslation();
  const [exist, setExist] = useState({});
  const [generatedFile, setGeneratedFile] = useState({});

  useEffect(() => {
    const exists = {};
    const filesList = {};
    trainees.forEach((trainee) => {
      const file = session.files
        .filter(
          (f) =>
            f.user &&
            f.user._id.toString() === trainee._id.toString() &&
            f.type === 'attendance_certificate'
        )
        .sort((a, b) => new Date(b.date) - new Date(a.date))[0];
      if (file) {
        exists[trainee._id] = true;
        filesList[trainee._id] = {
          fileName: file.filename,
          id: file._id
        };
      }
    });
    setExist(exists);
    setGeneratedFile(filesList);
  }, [session.files, trainees]);

  return (
    <>
      <Divider orientation="left" style={{ marginTop: 48 }}>
        <span>{t('sessions.attendance_certificate.title')}</span>
      </Divider>
      <Card>
        <Row justify="space-between">
          <Col>
            <Button
              disabled={Object.keys(generatedFile).length === 0}
              type="primary"
              style={{ marginBottom: 10 }}
              onClick={() => {
                setSendType('multi');
                showModal('all', '', '', 'attendance_certificate');
              }}
            >
              {t('sessions.attendance_certificate.messages.send')}
            </Button>
            <Spin
              style={{ marginLeft: 10 }}
              spinning={loading.attendance_certificate || false}
            />
            <Dropdown
              overlay={menuDropDown('attendance_certificate', '', true)}
            >
              <Button type="link" style={{ marginBottom: 10, marginLeft: 10 }}>
                {t('sessions.attendance_certificate.messages.generate')}
                <DownOutlined />
              </Button>
            </Dropdown>
          </Col>
        </Row>
        <List
          dataSource={trainees}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta title={`${item.first_name} ${item.last_name}`} />
              <span style={{ paddingRight: 50 }}>
                {listActions('attendance_certificate', item._id)}
              </span>
              <span style={{ float: 'right' }}>
                {loading[`${item._id}-attendance_certificate`] && (
                  <LoadingOutlined />
                )}
                <Dropdown
                  overlay={() =>
                    menuDropDown('attendance_certificate', item._id)
                  }
                  trigger={['click']}
                >
                  <Button type="link">
                    {t('sessions.attendance_certificate.title')}
                    <DownOutlined />
                  </Button>
                </Dropdown>
                {exist[item._id] && (
                  <>
                    <Divider type="vertical" />
                    <Button
                      type="link"
                      icon={<DownloadOutlined />}
                      onClick={() =>
                        downloadFile(
                          generatedFile[item._id].id,
                          generatedFile[item._id].fileName
                        )
                      }
                    />
                    <Divider type="vertical" />
                    <Button
                      type="link"
                      icon={<SendOutlined />}
                      onClick={() => {
                        setSendType('one');
                        showModal(
                          item.ref,
                          item._id,
                          generatedFile[item._id],
                          'attendance_certificate'
                        );
                      }}
                    />
                  </>
                )}
              </span>
            </List.Item>
          )}
        />
      </Card>
    </>
  );
};

AttendanceCertificate.propTypes = {
  trainees: PropTypes.arrayOf({}).isRequired,
  setSendType: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  loading: PropTypes.shape({
    attendance_certificate: PropTypes.shape({}),
    'Tous-attendance_certificate': PropTypes.shape({})
  }).isRequired,
  menuDropDown: PropTypes.shape({}).isRequired,
  session: PropTypes.shape({
    files: PropTypes.arrayOf({})
  }).isRequired,
  listActions: PropTypes.func.isRequired,
  downloadFile: PropTypes.func.isRequired
};

export default AttendanceCertificate;
