import React from 'react';
import { Route, Redirect, Switch, useRouteMatch } from 'react-router-dom';
import { subRoutes } from '../../utils/constants/routes';
import { ScanContextProvider } from './Scan/ScanContext';
import DocumentTemplates from './Templates/DocumentTemplates';
import { TemplatesContextProvider } from './Templates/TemplatesContext';
import CreateTemplate from './Templates/CreateTemplate';
import SignDocument from './Templates/SignDocument';
import DocumentsManager from './GED/DocumentsManager';
import CreateFile from './GED/CreateFile';
import EmailsTemplates from './TemplateEmails/EmailsTemplate';
import CreateTemplateEmail from './TemplateEmails/createTemplateEmail';

const DocumentsRouter = () => {
  const { path } = useRouteMatch();

  return (
    <TemplatesContextProvider>
      <ScanContextProvider>
        <Switch>
          <Route
            exact
            path={`${path}${subRoutes.DOCUMENTS.TEMPLATES}`}
            component={DocumentTemplates}
          />
          <Route
            exact
            path={`${path}${subRoutes.DOCUMENTS.GED}`}
            component={DocumentsManager}
          />
          <Route
            exact
            path={`${path}${subRoutes.DOCUMENTS.GED}/create`}
            component={CreateFile}
          />
          <Route
            exact
            path={`${path}${subRoutes.DOCUMENTS.TEMPLATES}/create`}
            component={CreateTemplate}
          />
          <Route
            exact
            path={`${path}${subRoutes.DOCUMENTS.TEMPLATES}/sign`}
            component={SignDocument}
          />
          <Route
            exact
            path={`${path}${subRoutes.DOCUMENTS.TEMPLATE_EMAILS}/create`}
            render={() => <CreateTemplateEmail purpose="create" />}
          />
          <Route
            exact
            path={`${path}${subRoutes.DOCUMENTS.TEMPLATE_EMAILS}/edit/:id`}
            render={() => <CreateTemplateEmail purpose="edit" />}
          />
          <Route
            exact
            path={`${path}${subRoutes.DOCUMENTS.TEMPLATE_EMAILS}`}
            component={EmailsTemplates}
          />
          <Redirect from="*" to={`${path}${subRoutes.DOCUMENTS.TEMPLATES}`} />
        </Switch>
      </ScanContextProvider>
    </TemplatesContextProvider>
  );
};

export default DocumentsRouter;
