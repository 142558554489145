import React from 'react';
import { Link } from 'react-router-dom';
import { EyeOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const useContactColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('deals.column.last_name'),
      key: 'last_name',
      dataIndex: 'last_name',
      sorter: true
    },
    {
      title: t('deals.column.first_name'),
      key: 'first_name',
      dataIndex: 'first_name',
      sorter: true
    },
    {
      title: t('deals.column.email'),
      key: 'email',
      dataIndex: 'email',
      sorter: true
    },
    {
      key: 'action',
      render: (item) => (
        <span>
          <Link
            to={{
              pathname: `/datas/contacts/show/${item.key}`
            }}
          >
            <EyeOutlined />
          </Link>
        </span>
      )
    }
  ];
};

export default useContactColumns;
