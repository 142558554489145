const onFinish = async ({
  values,
  purpose,
  dispatchAPI,
  path,
  message,
  history,
  t
}) => {
  if (purpose === 'edit') {
    await dispatchAPI('PATCH', { url: `/charges/${path[6]}`, body: values });
    message.success(t('charges.message.success.edit'));
  } else {
    await dispatchAPI('POST', {
      url: `/charges`,
      body: { ...values, session: path[3] }
    });
    message.success(t('charges.message.success.create'));
  }
  history.goBack();
};

export default onFinish;
