import moment from 'moment';
import React from 'react';
import { Tooltip } from 'antd';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  HourglassOutlined
} from '@ant-design/icons';

const checkAffiliateStatus = (affiliate, t) => {
  const organization = affiliate.organization;
  if (
    moment().diff(affiliate.created_at, 'days') < 15 &&
    !organization.subscription
  ) {
    return {
      value: 'TRIAL_PERIOD',
      icon: (
        <Tooltip title={t('prescriber.affiliations.filter.TRIAL_PERIOD')}>
          <HourglassOutlined style={{ fontSize: 18 }} />
        </Tooltip>
      )
    };
  }
  if (
    moment(affiliate?.date_subcription_canceled).diff(
      affiliate?.organization?.last_subscription_creation_date,
      'months'
    ) < 3 &&
    !organization.subscription
  ) {
    return {
      value: 'NO_SUBSCRIPTION',
      icon: (
        <Tooltip title={t('prescriber.affiliations.filter.NO_SUBSCRIPTION')}>
          <CloseCircleOutlined style={{ color: 'red', fontSize: 18 }} />
        </Tooltip>
      )
    };
  }
  if (!organization.subscription) {
    return {
      value: 'NO_SUBSCRIPTION',
      icon: (
        <Tooltip title={t('prescriber.affiliations.filter.NO_SUBSCRIPTION')}>
          <CloseCircleOutlined style={{ color: 'red', fontSize: 18 }} />
        </Tooltip>
      )
    };
  }
  if (
    organization.subscription &&
    moment().diff(organization.subscription.start_date, 'days') < 90
  ) {
    return {
      value: 'LESS_MONTHS',
      icon: (
        <Tooltip title={t('prescriber.affiliations.filter.LESS_MONTHS_OF_USE')}>
          <CheckCircleOutlined style={{ color: 'orange', fontSize: 18 }} />
        </Tooltip>
      )
    };
  }
  if (
    organization.subscription &&
    moment().diff(organization.subscription.start_date, 'days') > 90
  ) {
    return {
      value: 'MORE_MONTHS',
      icon: (
        <Tooltip title={t('prescriber.affiliations.filter.MORE_MONTHS')}>
          <CheckCircleOutlined style={{ color: 'green', fontSize: 18 }} />
        </Tooltip>
      )
    };
  }
  return {
    value: '',
    icon: ''
  };
};

export default checkAffiliateStatus;
