export const getPayments = async ({
  dispatchAPI,
  setPayments,
  notification
}) => {
  try {
    const { data } = await dispatchAPI('GET', {
      url: `/payments?populate=invoice,credit_note`
    });
    setPayments(data);
  } catch (e) {
    if (e.response) notification(e.response);
  }
};
