import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Collapse,
  DatePicker,
  Divider,
  Form,
  Input,
  message,
  Row,
  Select,
  Space,
} from 'antd';
import { useTranslation } from 'react-i18next';
import * as moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom';
import TextArea from 'antd/es/input/TextArea';
import useAuthContext from '../../../contexts/AuthContext';
import ItemsTable from './ItemsTable';
import useFields from './fields';
import useGenerateFormItem from '../../../utils/GenerateFormItem';
import { formItemLayout } from '../../../utils/constants/formLayout';
import { PageHeaderCustom } from '../../../components';

const { Option } = Select;
const { Panel } = Collapse;

const CreateInvoice = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const generateFields = useGenerateFormItem();
  const { dispatchAPI } = useAuthContext();
  const { fields } = useFields();
  const { pathname } = useLocation();
  const urlSplit = pathname.split('/');
  const idSession = urlSplit[3];
  const customerRef = urlSplit[5];
  const idCustomer = urlSplit[6];
  const [session, setSession] = useState({});
  const [selectCustomer, setSelectCustomer] = useState(idCustomer);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRowItems, setSelectedRowItems] = useState([]);
  const [ratesList, setRatesList] = useState([]);

  const getSession = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/sessions/${idSession}?customer=${JSON.stringify([
          { path: 'program' },
          { path: 'contributors', populate: { path: 'contributor' } },
          { path: 'funders', populate: { path: 'funder' } },
          { path: 'files', populate: { path: 'user' } },
          {
            path: 'customers',
            populate: [{ path: 'customer' }, { path: 'trainees_list' }],
          },
        ])}`,
      });
      setSession(data);
    } catch (e) {
      if (e.response) {
        message(
          e.response.status === 400
            ? 'Requête invalide'
            : e.response.status === 401
            ? 'Vous devez vous authentifier pour accèder à la ressource'
            : e.response.status === 403
            ? "Vous n'avez pas les droits sur cette ressource"
            : e.response.status === 404
            ? 'Ressource non trouvée'
            : e.response.status === 498
            ? 'Session expirée'
            : e.response.status === 405
            ? "Cette requete n'est pas prise en charge par le serveur"
            : e.response.status === 409
            ? "Cette requête rentre en conflit avec l'état actuel du serveur"
            : e.response.status
        );
      }
    }
  };
  useEffect(() => {
    (async () => {
      await getSession();
    })();
  }, [idSession]);

  const createInvoice = async body => {
    try {
      await dispatchAPI('POST', { url: '/invoices', body });
      message.success('Facture bien créée');
    } catch (e) {
      if (e.response) {
        message(
          e.response.status === 400
            ? 'Requête invalide'
            : e.response.status === 401
            ? 'Vous devez vous authentifier pour accèder à la ressource'
            : e.response.status === 403
            ? "Vous n'avez pas les droits sur cette ressource"
            : e.response.status === 404
            ? 'Ressource non trouvée'
            : e.response.status === 498
            ? 'Session expirée'
            : e.response.status === 405
            ? "Cette requete n'est pas prise en charge par le serveur"
            : e.response.status === 409
            ? "Cette requête rentre en conflit avec l'état actuel du serveur"
            : e.response.status
        );
      }
    }
  };
  const onSubmit = async values => {
    let total_ht = 0;
    let total_ttc = 0;
    const deadline = moment(new Date(), 'DD-MM-YYYY').add(
      values.payment_deadline,
      'days'
    );
    selectedRowItems.forEach(el => {
      total_ht += parseFloat(el.total_ht);
      total_ttc += parseFloat(el.total_ht) * (1 + el.tva / 100);
    });

    const body = {
      ...values,
      session: idSession,
      ref: customerRef,
      customer: idCustomer,
      items: selectedRowItems,
      deadline_date: moment(deadline),
      emission_date: new Date(),
      total_ht,
      total_ttc,
    };
    await createInvoice(body);
    history.goBack();
  };

  return (
    <>
      <PageHeaderCustom title={t('Facture')} />
      <Form {...formItemLayout} onFinish={onSubmit}>
        <Card style={{ margin: 20 }}>
          <Row align="middle" justify="space-between">
            <span>
              Session :<span style={{ marginLeft: 6 }}>{session.name}</span>
            </span>
            <DatePicker defaultValue={moment(new Date(), 'DD-MM-YYYY')} />
          </Row>
        </Card>
        {/* <Divider orientation="left">
          <span>{t('Affectation client pour le BPF')}</span>
        </Divider>
        <Card>
          <Row>
            <Col span={4}>
              <span>Client</span>
            </Col>
            <Col span={16}>
              <Select
                bordered={false}
                style={{ width: 200, background: 'white' }}
                onChange={v => setSelectCustomer(v)}
                value={selectCustomer}
              >
                {session.customers &&
                  session.customers
                    .filter(el => el.status === 'ACCEPTED')
                    .map(({ customer }) => (
                      <Option value={customer._id}>
                        {' '}
                        {customer.name ||
                          `${customer.last_name} ${customer.first_name}`}
                      </Option>
                    ))}
              </Select>
            </Col>
          </Row>
        </Card> */}

        <Divider orientation="left">
          <span>{t('Items')}</span>
        </Divider>
        <Card bordered={false} className="card-no-border">
          <ItemsTable
            session={session}
            selectedRowKeys={selectedRowKeys}
            setSelectedRowKeys={setSelectedRowKeys}
            selectedRowItems={selectedRowItems}
            setSelectedRowItems={setSelectedRowItems}
            listData={ratesList}
            setListData={setRatesList}
          />
        </Card>

        <Divider orientation="left">
          <span>{t('Informations supplémentaires')}</span>
        </Divider>
        <Card bordered={false} className="card-no-border">
          <Collapse accordion ghost>
            <Panel key="1" header="Mention libre">
              <Form.Item name="comments" noStyle>
                <TextArea />
              </Form.Item>
            </Panel>
            <Panel key="2" header="Modalités de réglement">
              {fields.map(field =>
                generateFields('invoices' || 'invoices', field)
              )}
            </Panel>
            <Panel key="3" header="Paramètres comptables">
              <Form.Item
                name="accounting_code"
                label="Code comptabilité analytique">
                <Input />
              </Form.Item>
            </Panel>
          </Collapse>
        </Card>
        <Button
          style={{ float: 'right', marginRight: 20 }}
          htmlType="submit"
          type="add">
          {' '}
          Enregistrer{' '}
        </Button>
      </Form>
    </>
  );
};

export default CreateInvoice;
