import moment from 'moment';

const checkIfIsPriceByCustomerType = (rateItem) => {
  let check = false;
  if (rateItem.company_rate) {
    check = true;
  }
  return check;
};

const getNbDaysFromSlotsModule = (slots) => {
  return slots.date.filter((el) => el.sub_slots.length).length;
};

const getNbHoursFomSlotsModule = (slots) => {
  let nbHour = 0;
  slots.date.forEach((date) => {
    date.sub_slots
      .filter((el) => el)
      .forEach((subSlot) => {
        const endTime = moment(subSlot.end_time, 'HH:mm');
        const startTime = moment(subSlot.start_time, 'HH:mm');
        nbHour += (endTime - startTime) / 3600000;
      });
  });
  return nbHour;
};

const getNbTrainees = (trainees) => {
  return trainees ? trainees.length : 0;
};

const calculateRateIfIsPriceByCustomerType = (
  rate,
  customerRef,
  customerStatus,
  nbDays,
  nbHours,
  nbTrainees
) => {
  let amount = 0;
  switch (rate.customer_type) {
    case 'CUSTOMER':
      if (customerRef === 'Company') {
        amount += rate.company_rate * (1 + rate.tva / 100);
      } else if (customerRef === 'Trainees' && customerStatus === 'PRIVATE') {
        amount += rate.private_rate * (1 + rate.tva / 100);
      } else {
        amount += rate.independent_rate * (1 + rate.tva / 100);
      }

      break;
    case 'DAY':
      if (customerRef === 'Company') {
        amount += rate.company_rate * nbDays * (1 + rate.tva / 100);
      } else if (customerRef === 'Trainees' && customerStatus === 'PRIVATE') {
        amount += rate.private_rate * nbDays * (1 + rate.tva / 100);
      } else {
        amount += rate.independent_rate * nbDays * (1 + rate.tva / 100);
      }

      break;
    case 'HOUR':
      if (customerRef === 'Company') {
        amount += rate.company_rate * nbHours * (1 + rate.tva / 100);
      } else if (customerRef === 'Trainees' && customerStatus === 'PRIVATE') {
        amount += rate.private_rate * nbHours * (1 + rate.tva / 100);
      } else {
        amount += rate.independent_rate * nbHours * (1 + rate.tva / 100);
      }

      break;
    case 'TRAINEE':
      if (customerRef === 'Company') {
        amount += rate.company_rate * nbTrainees * (1 + rate.tva / 100);
      } else if (customerRef === 'Trainees' && customerStatus === 'PRIVATE') {
        amount += rate.private_rate * (1 + rate.tva / 100);
      } else {
        amount += rate.independent_rate * (1 + rate.tva / 100);
      }

      break;
    case 'TRAINEE_DAY':
      if (customerRef === 'Company') {
        amount +=
          rate.company_rate * nbTrainees * nbDays * (1 + rate.tva / 100);
      } else if (customerRef === 'Trainees' && customerStatus === 'PRIVATE') {
        amount += rate.private_rate * nbDays * (1 + rate.tva / 100);
      } else {
        amount += rate.independent_rate * nbDays * (1 + rate.tva / 100);
      }

      break;
    case 'TRAINEE_HOUR':
      if (customerRef === 'Company') {
        amount +=
          rate.company_rate * nbTrainees * nbHours * (1 + rate.tva / 100);
      } else if (customerRef === 'Trainees' && customerStatus === 'PRIVATE') {
        amount += rate.private_rate * nbHours * (1 + rate.tva / 100);
      } else {
        amount += rate.independent_rate * nbHours * (1 + rate.tva / 100);
      }

      break;
    default:
      break;
  }

  return amount;
};

const switchRateType = (
  rates,
  nbDays,
  nbHours,
  customerTraineesList,
  customerRef,
  customerStatus
) => {
  let amount = 0;
  const nbTrainees = getNbTrainees(customerTraineesList);
  rates.forEach((rate) => {
    switch (rate.customer_type) {
      case 'CUSTOMER':
        if (!checkIfIsPriceByCustomerType(rate)) {
          amount += rate.rate * (1 + rate.tva / 100);
        } else {
          amount += calculateRateIfIsPriceByCustomerType(
            rate,
            customerRef,
            customerStatus,
            nbDays,
            nbHours,
            nbTrainees
          );
        }

        break;
      case 'DAY':
        if (!checkIfIsPriceByCustomerType(rate)) {
          amount += rate.rate * nbDays * (1 + rate.tva / 100);
        } else {
          amount += calculateRateIfIsPriceByCustomerType(
            rate,
            customerRef,
            customerStatus,
            nbDays,
            nbHours,
            nbTrainees
          );
        }

        break;
      case 'HOUR':
        if (!checkIfIsPriceByCustomerType(rate)) {
          amount += rate.rate * nbHours * (1 + rate.tva / 100);
        } else {
          amount += calculateRateIfIsPriceByCustomerType(
            rate,
            customerRef,
            customerStatus,
            nbDays,
            nbHours,
            nbTrainees
          );
        }

        break;
      case 'TRAINEE':
        if (!checkIfIsPriceByCustomerType(rate)) {
          amount += (nbTrainees || 1) * rate.rate * (1 + rate.tva / 100);
        } else {
          amount += calculateRateIfIsPriceByCustomerType(
            rate,
            customerRef,
            customerStatus,
            nbDays,
            nbHours,
            nbTrainees
          );
        }

        break;
      case 'TRAINEE_DAY':
        if (!checkIfIsPriceByCustomerType(rate)) {
          amount +=
            (nbTrainees || 1) * rate.rate * nbDays * (1 + rate.tva / 100);
        } else {
          amount += calculateRateIfIsPriceByCustomerType(
            rate,
            customerRef,
            customerStatus,
            nbDays,
            nbHours,
            nbTrainees
          );
        }

        break;
      case 'TRAINEE_HOUR':
        if (!checkIfIsPriceByCustomerType(rate)) {
          amount +=
            (nbTrainees || 1) * rate.rate * nbHours * (1 + rate.tva / 100);
        } else {
          amount += calculateRateIfIsPriceByCustomerType(
            rate,
            customerRef,
            customerStatus,
            nbDays,
            nbHours,
            nbTrainees
          );
        }

        break;
      default:
        break;
    }
  });
  return amount;
};

const CalculRateModule = (module, customers) => {
  let totalAmount = 0;
  const nbDays = getNbDaysFromSlotsModule(module.slots);
  const nbHours = getNbHoursFomSlotsModule(module.slots);

  (customers || []).forEach((customer) => {
    let amount = 0;
    if (customer.spec_rate && customer.spec_rate.length) {
      amount = switchRateType(
        customer.spec_rate,
        nbDays,
        nbHours,
        customer.trainees_list
      );
    } else {
      amount = switchRateType(
        module.rates,
        nbDays,
        nbHours,
        customer.trainees_list,
        customer.ref,
        customer.customer && customer.customer.status
      );
    }
    totalAmount += amount;
  });
  return totalAmount;
};

export default CalculRateModule;
