import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Card, Button, Divider, Form, message, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { formItemLayout } from '../../../../utils/constants/formLayout';
import { PageHeaderCustom } from '../../../../components';
import useGenerateFormItem from '../../../../utils/GenerateFormItem';
import useAuthContext from '../../../../contexts/AuthContext';
import useFields from './fields';

const CreatePayment = () => {
  const generateFields = useGenerateFormItem();
  const { dispatchAPI } = useAuthContext();
  const { fields } = useFields();
  const { id, invoiceType } = useParams();
  const history = useHistory();
  const { t } = useTranslation();
  const location = useLocation();
  const invoice = location.state;

  const createPayment = async (body) => {
    try {
      await dispatchAPI('POST', {
        url: `/payments?invoiceType=${invoiceType}`,
        body
      });
      message.success(t('sessions.invoices.messages.add_payment_success'));
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
  };

  const onSubmit = async (values) => {
    let body;

    switch (invoiceType) {
      case 'invoice':
        body = {
          invoice: id,
          ...values
        };
        break;
      case 'credit_note':
        body = {
          credit_note: id,
          ...values
        };
        break;
      default:
    }

    if (body.amount <= invoice.total_ttc && body.payment_type) {
      await createPayment(body);
      history.goBack();
    } else {
      message.error(
        `${t('sessions.invoices.messages.error_amount')} ${invoice.total_ttc} €`
      );
    }
  };

  return (
    <>
      <Form {...formItemLayout} onFinish={onSubmit}>
        <PageHeaderCustom
          title={t(`${t('sessions.invoices.invoice')} ${invoice.number}`)}
        />
        <Divider orientation="left">
          <span>{t('sessions.invoices.enter_payment')}</span>
        </Divider>
        <Card bordered={false} className="card-no-border">
          {fields.map((field) => generateFields('payments', field))}
        </Card>
        <Row>
          <Col span={5} offset={11}>
            <Button
              style={{ float: 'right', marginRight: 9 }}
              htmlType="submit"
              type="add"
            >
              {t('sessions.invoices.save')}
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default CreatePayment;
