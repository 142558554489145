import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Button, Popconfirm, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  DeleteOutlined,
  EditOutlined,
  WarningOutlined
} from '@ant-design/icons';
import moment from 'moment';
import useAuthContext from '../../contexts/AuthContext';
import ContentCustom from '../../components/ContentCustom/ContentCustom';
import listContent from './listContent';
import DescriptionList from '../../components/DescriptionList/DescriptionList';
import PageHeaderCustom from '../../components/PageHeader/PageHeader';
import ErrorStatusCode from '../../utils/ErrorStatusCode';

const DetailSubscriptions = () => {
  const { id } = useParams();
  const history = useHistory();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState({});
  const [upcomingPayments, setUpcomingPayments] = useState([]);
  const [totalAlreadyBilled, setTotalAlreadyBilled] = useState(null);
  const { notification } = ErrorStatusCode();

  const getUsers = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/users/${id}?populate=organization.current_subscription`
      });
      setUser(data);
      setUpcomingPayments(data.organization.subscription.upcoming_payments);
    } catch (e) {
      if (e.response) notification(e.response);
    }
    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  const deleteOrganizations = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/users/${id}` });
      history.goBack();
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  const today = moment().format('YYYY-MM-DD');

  useEffect(() => {
    setTotalAlreadyBilled(
      upcomingPayments.reduce((total, item) => {
        if (item.charge_date <= today) {
          const amount = item.amount / 100;
          return total + parseFloat(amount.toFixed(2));
        }
        return total;
      }, 0)
    );
  }, [upcomingPayments]);

  return (
    <>
      <PageHeaderCustom
        title={t('subscriptions.show.title')}
        withSubRoutes
        extra={
          <>
            <Link
              to={{
                pathname: `../edit/${id}`
              }}
            >
              <Button type="primary">
                {`${t('buttons.edit')} `}
                <EditOutlined />
              </Button>
            </Link>
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ danger: true }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={deleteOrganizations}
              icon={<WarningOutlined />}
            >
              <Button type="link" danger>
                {`${t('buttons.delete')} `}
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </>
        }
      />
      <ContentCustom>
        <Skeleton loading={isLoading}>
          <DescriptionList
            data={listContent({ ...user, totalAlreadyBilled }, t)}
            translate
          />
        </Skeleton>
      </ContentCustom>
    </>
  );
};

export default DetailSubscriptions;
