import React from 'react';

const Columns = t => [
  {
    title: t('files.list.columns.name'),
    key: 'name',
    sorter: true,
    render: value => value.metadata?.originalName || value.name,
  },
  {
    title: t('files.list.columns.content'),
    key: 'content',
    dataIndex: 'contentType',
    sorter: true,
  },
];

export default Columns;
