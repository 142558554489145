import React from 'react';
import PropTypes from 'prop-types';
import { Button, Menu } from 'antd';

const MenuDropDown = ({
  type,
  id,
  generateMultiFiles,
  templatesPrivate,
  generateStatus,
  templatesCompany,
  generateFile,
  t
}) => {
  let menu = {};
  if (type === 'multi') {
    menu = (
      <Menu>
        <Menu.Item key="1">
          <Button
            style={{ width: '100%', textAlign: 'left' }}
            type="link"
            onClick={() => generateMultiFiles('docx')}
          >
            {`${t('sessions.conventions.button.generate_to')} docx`}
          </Button>
        </Menu.Item>
        <Menu.Item key="1">
          <Button
            style={{ width: '100%', textAlign: 'left' }}
            type="link"
            onClick={() => generateMultiFiles('pdf')}
          >
            {`${t('sessions.conventions.button.generate_to')} pdf`}
          </Button>
        </Menu.Item>
      </Menu>
    );
  } else {
    menu = (
      <Menu>
        {type === 'private_convention'
          ? templatesPrivate.map((el, index) => (
              <>
                <Menu.Item key={`${el._id}-${index + 1}`}>
                  {generateStatus ? (
                    <Button type="link" disabled>
                      {`${el.name.split('.')[0]} docx`}
                    </Button>
                  ) : (
                    <Button
                      type="link"
                      style={{ width: '100%', textAlign: 'left' }}
                      onClick={() =>
                        generateFile({
                          id: el._id,
                          name: el.name,
                          customId: id,
                          fileType: 'docx'
                        })
                      }
                    >
                      {`${el.name.split('.')[0]} docx`}
                    </Button>
                  )}
                </Menu.Item>
                {/* eslint-disable-next-line react/no-array-index-key */}
                <Menu.Item key={`${el._id}-${index}`}>
                  {generateStatus ? (
                    <Button type="link" disabled>
                      {`${el.name.split('.')[0]} pdf`}
                    </Button>
                  ) : (
                    <Button
                      type="link"
                      style={{ width: '100%', textAlign: 'left' }}
                      onClick={() =>
                        generateFile({
                          id: el._id,
                          name: el.name,
                          customId: id,
                          fileType: 'pdf'
                        })
                      }
                    >
                      {`${el.name.split('.')[0]} pdf`}
                    </Button>
                  )}
                </Menu.Item>
              </>
            ))
          : templatesCompany.map((el, index) => (
              <>
                <Menu.Item key={`${el._id}-${index + 1}`}>
                  {generateStatus ? (
                    <Button type="link" disabled>
                      {`${el.name.split('.')[0]} docx`}
                    </Button>
                  ) : (
                    <Button
                      type="link"
                      style={{ width: '100%', textAlign: 'left' }}
                      onClick={() =>
                        generateFile({
                          id: el._id,
                          name: el.name,
                          customId: id,
                          fileType: 'docx'
                        })
                      }
                    >
                      {`${el.name.split('.')[0]} docx`}
                    </Button>
                  )}
                </Menu.Item>
                {/* eslint-disable-next-line react/no-array-index-key */}
                <Menu.Item key={`${el._id}-${index}`}>
                  {generateStatus ? (
                    <Button type="link" disabled>
                      {`${el.name.split('.')[0]} pdf`}
                    </Button>
                  ) : (
                    <Button
                      type="link"
                      style={{ width: '100%', textAlign: 'left' }}
                      onClick={() =>
                        generateFile({
                          id: el._id,
                          name: el.name,
                          customId: id,
                          fileType: 'pdf'
                        })
                      }
                    >
                      {`${el.name.split('.')[0]} pdf`}
                    </Button>
                  )}
                </Menu.Item>
              </>
            ))}
      </Menu>
    );
  }
  return menu;
};

MenuDropDown.propTypes = {
  type: PropTypes.string.isRequired,
  id: PropTypes.string,
  generateMultiFiles: PropTypes.func.isRequired,
  templatesPrivate: PropTypes.arrayOf({}).isRequired,
  generateStatus: PropTypes.func.isRequired,
  templatesCompany: PropTypes.arrayOf({}).isRequired,
  generateFile: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

export default MenuDropDown;
