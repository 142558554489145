import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Divider,
  PageHeader,
  Table,
  Tag,
  DatePicker,
  Input,
  Row,
  Col,
  Spin
} from 'antd';
import moment from 'moment';
import useAuthContext from '../../contexts/AuthContext';
import { SimplePieChart } from './SimplePieChart';
import ErrorStatusCode from '../../utils/ErrorStatusCode';

const StyledTable = styled.div`
  .rowStyle {
    cursor: pointer;
  }
`;

const { RangePicker } = DatePicker;
const { Search } = Input;
const DevisTable = ({ indicator, tab }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [total, setTotal] = useState(0);
  const [data, setData] = useState([]);
  const { dispatchAPI, user } = useAuthContext();
  const [dates, setDates] = useState(null);
  const [obj, setObj] = useState({});
  const [searchValue, setSearchValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [resources, setResources] = useState([]);
  const [graphData, setGraphData] = useState([{}]);
  const [status, setStatus] = useState(null);
  const { notification } = ErrorStatusCode();
  const [pagination, setPagination] = useState({
    hideOnSinglePage: false,
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: true
  });

  const fetchData = async (page = pagination) => {
    setIsLoading(true);
    try {
      const { data: devisData, headers } = await dispatchAPI('GET', {
        url: `/sessions?organization=${user.organization._id}&fields=contributors,_id,files.type,files.status,files.user,files.ref,files.amount,files.date&devistab=true`
      });
      let res = devisData;
      if (indicator) {
        res = devisData.sort((b, a) =>
          moment(a.date).isAfter(b.date) ? 1 : -1
        );
      }
      setPagination({
        ...page,
        total: parseInt(headers['x-total-count'], 10)
      });
      setResources(res);
    } catch (e) {
      if (e.response) notification(e.response);
    }
    setIsLoading(false);
  };

  const filterData = () => {
    setData(
      resources.filter(
        (r) =>
          (status ? status.includes(r.status) : true) &&
          (dates
            ? moment(r.date).isAfter(moment(dates[0])) &&
              moment(r.date).isBefore(moment(dates[1]))
            : true) &&
          r.user.toUpperCase().includes(searchValue.toUpperCase())
      )
    );
  };

  useEffect(() => {
    filterData();
  }, [resources, dates, status, searchValue]);
  const handlePageChange = async (page, filters, { columnKey, order } = {}) => {
    setStatus(filters.status);
    await fetchData(page);
  };

  useEffect(() => {
    (async () => {
      await fetchData();
    })();
  }, []);

  useEffect(() => {
    setTotal(
      data.length > 0 &&
        data.filter((el) => el.amount).map((el) => el.amount).length > 0
        ? data
            .filter((el) => el.amount)
            .map((el) => el.amount)
            .reduce((a, b) => a + b)
        : 0
    );
    const newObj = {};
    data.forEach((el) => {
      if (newObj[el.status]) {
        newObj[el.status].value = newObj[el.status].value + 1;
        newObj[el.status].amount = newObj[el.status].amount + (el.amount || 0);
      } else {
        newObj[el.status] = { value: 1, amount: el.amount || 0 };
      }
    });
    setObj(newObj);
  }, [data]);

  useEffect(() => {
    if (tab !== 'Devis') return;
    setGraphData(
      Object.keys(obj).map((k) => {
        return {
          type: k && t(`indicators.tags.${k.toUpperCase()}`),
          value: obj[k].amount
        };
      })
    );
  }, [obj, tab]);

  const column = [
    {
      title: 'Statut',
      dataIndex: 'status',
      sorter: true,
      filters: [
        { text: 'EN ATTENTE', value: 'waiting' },
        { text: 'ACCEPTÉ', value: 'accepted' }
      ],
      onFilter: (value, record) => record.status == value,
      render: (status) => {
        return (
          <Tag
            color={
              status && status.toLowerCase() === 'accepted'
                ? 'success'
                : status && status.toLowerCase() === 'waiting'
                ? 'gold'
                : 'default'
            }
          >
            {status && t(`indicators.tags.${status.toUpperCase()}`)}
          </Tag>
        );
      }
    },
    {
      title: 'Client',
      dataIndex: 'user'
    },
    {
      title: 'Date',
      dataIndex: 'date',
      render: (date) => moment(date).format('DD-MM-YYYY')
    },
    {
      title: 'Montant',
      dataIndex: 'amount',
      render: (amount) => `${parseFloat(amount).toFixed(2) || 0} €`
    }
  ];
  const extra = [
    <>
      <Row gutter={8}>
        <Col span={10}>
          <Search
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder={t('dashboards.quotations.search_client')}
          />
        </Col>
        <Col span={14}>
          <RangePicker
            onChange={(values) => setDates(values)}
            style={{ float: 'right' }}
          />
        </Col>
      </Row>
    </>
  ];

  if (indicator) {
    return (
      <StyledTable
        as={Table}
        rowClassName="rowStyle"
        dataSource={
          indicator ? resources.filter((_, index) => index < 5) : data
        }
        onRow={(record) => ({
          onDoubleClick: () => {
            history.push({
              pathname: `/sessions/show/${record.session}`,
              state: { key: '11' }
            });
            localStorage.setItem('tabIndex', '13');
          }
        })}
        loading={isLoading}
        onChange={handlePageChange}
        pagination={indicator ? false : pagination}
        columns={column}
      />
    );
  }
  return (
    <>
      <Row>
        <Col xl={17} sm={24} xs={24}>
          <PageHeader title={t('dashboards.quotations.title')} extra={extra} />
          <StyledTable
            as={Table}
            rowClassName="rowStyle"
            dataSource={
              indicator ? resources.filter((_, index) => index < 5) : data
            }
            loading={isLoading}
            onRow={(record) => ({
              onDoubleClick: () => {
                history.push({
                  pathname: `/sessions/show/${record.session}`,
                  state: { key: '11' }
                });
                localStorage.setItem('tabIndex', '13');
              }
            })}
            onChange={handlePageChange}
            pagination={indicator ? false : pagination}
            columns={column}
            style={{ margin: '0 8px 0 20px' }}
          />
        </Col>
        <Col xl={7} sm={24} xs={24} className="dashboard-table-right-column">
          <h1>{t('dashboards.quotations.number', { number: data.length })}</h1>
          <Divider orientation="left">
            {t('dashboards.quotations.ttc_amount')}
          </Divider>
          <Spin spinning={isLoading}>
            <SimplePieChart data={graphData} />
          </Spin>
          <Divider orientation="left">
            {t('dashboards.quotations.totals')}
          </Divider>
          <div className="dashboard-total-prices-wrapper">
            <p>
              <span>{t('dashboards.quotations.total')}</span>
              <span>{`${parseFloat(total).toFixed(2)} €`}</span>
            </p>
          </div>
          <Divider orientation="left">
            {t('dashboards.quotations.filters')}
          </Divider>
          {Object.keys(obj).map((el) => {
            return (
              <p>
                {`${t(`indicators.tags.${el.toUpperCase()}`)} : ${
                  obj[el].value
                }`}
              </p>
            );
          })}
        </Col>
      </Row>
    </>
  );
};

export default DevisTable;

DevisTable.propTypes = {
  indicator: PropTypes.bool,
  tab: PropTypes.string
};

DevisTable.defaultProps = {
  indicator: false,
  tab: ''
};
