import moment from 'moment';
import { useTranslation } from 'react-i18next';

export const useFormatter = () => {
  const { t } = useTranslation();

  const formattedData = (data) => ({
    ...data,
    country_code: data?.phone_number?.country_code,
    number: data?.phone_number?.number,
    incident_date: moment(data?.incident_date).format('DD/MM/YYYY'),
    incident_source:
      data?.incident_source && t(`dashboards.filter.${data?.incident_source}`),
    incident_subject:
      data?.incident_subject &&
      t(`dashboards.filter.${data?.incident_subject}`),
    incident_gravity:
      data?.incident_gravity &&
      t(`dashboards.filter.${data?.incident_gravity}`),
    blocs: data?.blocs?.map((bloc) => `${bloc.name}`).join(', '),
    modality: {
      training_type:
        data?.modality?.training_type &&
        t(`programs.form.${data?.modality?.training_type}`),
      accessibility: data?.modality?.accessibility,
      staff_limit: data?.modality?.staff_limit,
      training_place: { name: data?.modality?.training_place.name }
    },
    educational_goals: data?.educational_goals?.map((goal) => goal).join(', '),
    trainees_profile: {
      customers: data?.trainees_profile?.customers
        .map((customer) => customer)
        .join(', '),
      prerequisite: data?.trainees_profile?.prerequisite
        .map((pre) => pre)
        .join(', ')
    },
    training_content: data?.training_content?.reduce(
      (acc, content) => {
        const { title, list_items } = content;
        const maxItems = Math.max(acc.list_items.length, list_items.length);

        for (let i = 0; i < maxItems; i += 1) {
          acc.title += `${i === 0 ? title : ''}\n`;
          acc.list_items += `${
            list_items[i] ? `\t${list_items[i].title}` : ''
          }\n`;
        }

        return acc;
      },
      { title: '', list_items: '' }
    ),
    execution_track: {
      team_description: data?.execution_track?.team_description,
      results_track: data?.execution_track?.results_track
        .map((result) => `${result}\n`)
        .join(', '),
      educational_resources: data?.execution_track?.educational_resources
        .map((result) => `${result}\n`)
        .join(', ')
    },
    skills: data?.skills?.map((skill) => skill.name).join(', '),
    contributor_duration:
      data?.duration && t(`contributors.form.${data?.duration}`),
    contributor_status: data?.status && t(`contributors.form.${data?.status}`),
    contributor_gender: data?.gender && t(`contributors.form.${data?.gender}`),
    compliant_disable_access:
      data?.compliant_disable_access && data?.compliant_disable_access
        ? 'Oui'
        : 'Non',
    emission_date: moment(data?.emission_date).format('DD/MM/YYYY'),
    deadline_date: moment(data?.deadline_date).format('DD/MM/YYYY'),
    total_ht: Number(data?.total_ht?.toFixed(2)),
    total_ttc: Number(data?.total_ttc?.toFixed(2)),
    current_subscription: data?.organization?.current_subscription
      ? data?.organization?.current_subscription.title
      : 'Aucun',
    subscription_status: data?.organization?.subscription
      ? t(
          `subscriptions.datatable.columns.status.${data?.organization
            ?.subscription?.status || 'canceled'}`
        )
      : t('subscriptions.datatable.columns.status.not_subscribed'),
    subscription_creation_date:
      data?.organization?.subscription &&
      moment(data?.organization?.subscription?.created_at).format('DD/MM/YYYY'),
    subscription_termination_date:
      data?.organization?.subscription &&
      moment(data?.organization?.subscription?.termination_date).format(
        'DD/MM/YYYY'
      )
  });

  return { formattedData };
};
