import React, { useCallback, useEffect, useState } from 'react';
import { Button, Form, message, Row } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import useFields from './CustomersFields';
import useAuthContext from '../../../contexts/AuthContext';
import useGenerateFormItem from '../../../utils/GenerateFormItem';
import PageHeaderCustom from '../../../components/PageHeader/PageHeader';
import { formItemLayout } from '../../../utils/constants/formLayout';
import { ContentCustom } from '../../../components';
import FounderList from './FoundersList';
import RatesCustomerList from './ratesCustomerList';
import ErrorStatusCode from '../../../utils/ErrorStatusCode';

const SessionsAddCustomers = ({ purpose }) => {
  const history = useHistory();
  const loc = useLocation();
  const [form] = Form.useForm();
  const splitURL = loc.pathname.split('/');
  const idSession = splitURL[3];
  const idCustomer = splitURL[7];
  const [id, setId] = useState([]);
  const param = splitURL[5];
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const { basicFields } = useFields(
    param,
    setId,
    id,
    idCustomer,
    purpose,
    isFieldsLoading,
    setIsFieldsLoading,
    form
  );
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const generateFields = useGenerateFormItem();
  const [isLoading, setIsLoading] = useState(false);
  const [session, setSession] = useState({});
  const [listFunders, setListFunders] = useState([]);
  const [trainees, setTrainees] = useState([]);
  const [listRates, setListRates] = useState([]);
  const [ref, setRef] = useState('');
  const { notification } = ErrorStatusCode();

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/sessions/${idSession}`
      });
      setSession(data);
    } catch (e) {
      if (e.response) notification(e.response);
    }
    setIsLoading(false);
  }, []);

  const getTrainees = async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/trainees`
      });

      setTrainees(data);
    } catch (e) {
      if (e.response) notification(e.response);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    (async () => {
      await fetchData();
    })();
  }, [fetchData]);

  useEffect(() => {
    (async () => {
      await getTrainees();
    })();
  }, []);

  useEffect(() => {
    if (purpose === 'edit' && !isFieldsLoading) {
      session.customers &&
        session.customers.forEach((customer) => {
          if (customer.customer === idCustomer) {
            setId(customer.trainees_list);
            form.setFieldsValue({
              ...customer,
              trainees: customer.trainees_list
            });
          }
        });
    }
  }, [purpose, session, isFieldsLoading]);

  useEffect(() => {
    const values = {
      trainees: [...id]
    };
    form.setFieldsValue(values);
  }, [id]);

  const updateSession = async (newBody) => {
    const result = session.customers.map((el) => ({
      ...el,
      customer: el.customer
    }));

    try {
      if (purpose === 'create') {
        const body = { customers: [...result, newBody] };
        await dispatchAPI('PATCH', { url: `/sessions/${idSession}`, body });
        message.success(t('sessions.form.success.createCustomer'));
      }
      if (purpose === 'edit') {
        const body = newBody;
        await dispatchAPI('PATCH', { url: `/sessions/${idSession}`, body });
        message.success(t('sessions.form.success.editCustomer'));
      }
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
  };
  useEffect(() => {
    if (param === 'private') {
      setRef('Trainees');
    } else if (param === 'company') {
      setRef('Company');
    }
  }, [param]);

  const addCustomerToSession = async (values) => {
    const body = {
      customer: values.customer,
      ref,
      status: 'NEW',
      trainees_list: values.trainees,
      funding: values.funding,
      nb_trainees: param === 'private' ? 1 : values.trainees.length,
      funders: (listFunders.funders || []).filter((el) => el !== undefined),
      spec_rate: (listRates.spec_rate || []).filter((el) => el !== undefined)
    };
    await updateSession(body);
  };

  const updateCustomerSessions = async (values) => {
    const oldCustomersList = session.customers;
    const customersList = oldCustomersList.filter(
      (el) => el.customer !== idCustomer
    );
    const newBody = {
      customers: [
        ...customersList,
        {
          customer: values.customer,
          funding: values.funding,
          ref,
          status: 'NEW',
          trainees_list: values.trainees,
          nb_trainees: values.trainees?.length || 1,
          funders: (listFunders.funders || []).filter((el) => el !== undefined),
          spec_rate: (listRates.spec_rate || []).filter(
            (el) => el !== undefined
          )
        }
      ]
    };
    await updateSession(newBody);
  };

  const onValuesChange = (value) => {
    if (value.customer) {
      // const traineesList = {
      //   trainees: trainees
      //     .filter(el => el.company && el.company === value.customer)
      //     .map(el => el._id),
      // };
      form.setFieldsValue({
        ...value.customer,
        trainees: value.customer.trainees_list
      });
    }
    setTrainees([]);
  };

  const handleSubmit = async (values) => {
    if (purpose === 'create') await addCustomerToSession(values);
    if (purpose === 'edit') await updateCustomerSessions(values);
    history.goBack();
  };

  return (
    <>
      <PageHeaderCustom title={t(`Ajouter un client`)} />
      <ContentCustom>
        <>
          <Form
            onValuesChange={onValuesChange}
            {...formItemLayout}
            onFinish={handleSubmit}
            form={form}
          >
            {basicFields.map((field) =>
              generateFields('sessions' || 'sessions', field)
            )}
            <FounderList
              session={session}
              purpose={purpose}
              listFunders={listFunders}
              setListFunders={setListFunders}
            />
            <RatesCustomerList
              session={session}
              purpose={purpose}
              listRates={listRates}
              setListRates={setListRates}
            />
            <Row style={{ padding: '10px 0 24px 0', float: 'right' }}>
              <Button onClick={() => history.goBack()} type="link" danger>
                {`${t('buttons.cancel')}`}
                <CloseOutlined />
              </Button>
              <Button type="add" htmlType="submit">
                {t('buttons.save')}
                <CheckOutlined />
              </Button>
            </Row>
          </Form>
        </>
      </ContentCustom>
    </>
  );
};

export default SessionsAddCustomers;
