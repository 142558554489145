import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import Skills from './Skills';
import CreateUpdateSkill from './CreateUpdateSkill';
import DetailsSkill from './DetailsSkill';

const SkillsRouter = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route
        path={`${path}/create`}
        render={() => <CreateUpdateSkill purpose="create" />}
      />
      <Route
        path={`${path}/edit/:id`}
        render={() => <CreateUpdateSkill purpose="edit" />}
      />
      <Route path={`${path}/show/:id`} render={() => <DetailsSkill />} />
      <Route path={`${path}`} render={() => <Skills />} />
    </Switch>
  );
};

export default SkillsRouter;
