import React from 'react';
import useColumns from './columns';
import SimpleResourceLandingLayout from '../../components/layouts/SimpleResourceLandingLayout';

const Skills = () => {
  const columns = useColumns();

  return (
    <SimpleResourceLandingLayout
      columns={columns}
      resourceName="skills"
      withSubRoutes
    />
  );
};

export default Skills;
