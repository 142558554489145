import React, { useEffect, useState } from 'react';
import { Card, Col, Input, List, Progress, Rate, Row } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import ChoiceGridResponses from './ChoiceGridResponses';

const DetailResponses = ({
  traineesList,
  responses,
  quiz,
  notes,
  setNotes,
  secondBool,
  setSecondBool,
  setQuestionsNotes,
  questionsNotes,
  purpose,
}) => {
  const [finalResponses, setFinalresponses] = useState([]);
  const [globalNotes, setGlobalNotes] = useState([]);
  const [bool, setBool] = useState(false);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    const list = [];
    responses.map(response => {
      response.responses.map(res => {
        list.push({
          ...res,
          user: response.user,
        });
      });
    });
    setFinalresponses(list);
  }, [responses]);

  useEffect(() => {
    const list = [];
    quiz.questions.map(item => {
      if (item.type === 'CHOICE_GRID') {
        list[item._id] = 0;
      }
    });
    setGlobalNotes(list);
    setRefresh(!refresh);
  }, [quiz.questions]);

  useEffect(() => {
    if (purpose === 'quality') {
      let quizNote = 0;
      let listLength = 0;
      const qualityNotes = notes;
      for (const [key, note] of Object.entries(globalNotes)) {
        quizNote += note;
        listLength += 1;
      }
      qualityNotes[quiz._id] = quizNote / listLength;
      setNotes(qualityNotes);
      setSecondBool(!secondBool);
    }
  }, [refresh]);

  useEffect(() => {
    if (purpose === 'quality') {
      const list = [...questionsNotes];

      const index = list.findIndex(el => el.id === quiz._id);
      if (index !== -1) {
        list[index].notes = globalNotes;
      }
      setQuestionsNotes(list);
    }
  }, [secondBool, purpose]);

  const renderResponses = (item, indexQuestion) => {
    switch (item.type) {
      case 'TEXT_AREA':
        {
          const data = finalResponses.map(response => {
            if (response.question.toString() === item._id.toString()) {
              return (
                <Card
                  size="small"
                  title={
                    <>
                      <UserOutlined />
                      {response.user?.last_name} {response.user?.first_name}
                    </>
                  }>
                  {response.response[0]}
                </Card>
              );
            }
          });
          return data;
        }
        break;
      case 'SCORE':
        {
          const data = finalResponses.map(response => {
            if (response.question.toString() === item._id.toString()) {
              return (
                <Card
                  size="small"
                  title={`${response.user.last_name} ${response.user.first_name}`}>
                  <Rate value={response.response[0]} disabled />
                </Card>
              );
            }
          });
          return data;
        }
        break;
      case 'CHOICE_GRID':
        {
          return (
            <ChoiceGridResponses
              globalNotes={globalNotes}
              traineesList={traineesList}
              setGlobalNotes={setGlobalNotes}
              item={item}
              setBool={setBool}
              bool={bool}
              responses={finalResponses}
            />
          );
        }
        break;
      default:
        {
          return (
            <>
              {item.answer_options.map(opt => {
                let percent = 0;
                let nbr = 0;
                if (item.type === 'MULTI_CHOICE') {
                  finalResponses.forEach(response => {
                    if (response.response.includes(opt._id)) {
                      percent = 100;
                      nbr += 1;
                    }
                  });
                } else if (item.type === 'UNIQUE_CHOICE') {
                  finalResponses.forEach(response => {
                    if (
                      response.response.includes(opt._id) &&
                      responses.length > 1
                    ) {
                      percent = 100 / responses.length;
                    } else if (
                      response.response.includes(opt._id) &&
                      responses.length === 1
                    ) {
                      percent = 100;
                    }
                  });
                }
                return (
                  <Row>
                    <Col span={6}>
                      <span>{opt.title}</span>
                    </Col>
                    <Col span={16}>
                      <Progress
                        className="quiz-progress"
                        percent={percent.toFixed(2)}
                      />
                    </Col>
                    <Col span={2}>{nbr > 1 && <span>{nbr}</span>}</Col>
                  </Row>
                );
              })}
            </>
          );
        }
        break;
    }
  };

  return (
    <>
      <List
        dataSource={quiz.questions}
        grid={{ column: 1 }}
        renderItem={item => (
          <List.Item>
            <Card
              bordered={false}
              title={item.title}
              extra={
                item.type === 'CHOICE_GRID' && (
                  <span>
                    {globalNotes[item._id]}
                    /10
                  </span>
                )
              }>
              {renderResponses(item)}
            </Card>
          </List.Item>
        )}
      />
    </>
  );
};
DetailResponses.propTypes = {
  quiz: PropTypes.shape({}).isRequired,
  responses: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};
export default DetailResponses;
