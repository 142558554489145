import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Upload, Button, TreeSelect } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import useErrorMessage from '../../../utils/ErrorMessage';
import useAuthContext from '../../../contexts/AuthContext';
import ContentCustom from '../../../components/ContentCustom/ContentCustom';
import PageHeaderCustom from '../../../components/PageHeader/PageHeader';
import ErrorStatusCode from '../../../utils/ErrorStatusCode';

const { Dragger } = Upload;
const { TreeNode } = TreeSelect;

const CreateTemplate = ({ typeSelector, callback }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { token, dispatchAPI, user, setUser } = useAuthContext();
  const { message } = useErrorMessage();
  const [type, setType] = useState();
  const { notification } = ErrorStatusCode();

  const getUser = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/users/${user._id}?populate=organization`
      });
      setUser(data);
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  const props = {
    name: 'file',
    multiple: true,
    disabled: !type,
    action: `${process.env.REACT_APP_API_URL}/files?uploadType=${type}`,
    accept: '.docx',
    data: {
      type
    },
    beforeUpload(file) {
      if (
        !type?.includes('import_template') &&
        file.type !==
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      ) {
        message('notADocx');
        return false;
      }
      return true;
    },
    headers: { Authorization: `Bearer ${token}` },
    onChange(info) {
      const { status } = info.file;

      if (status === 'error') {
        message(`${info.file.name} file upload failed.`);
      }
      if (status === 'done') {
        (async () => {
          await getUser();
        })();
      }
    }
  };

  return (
    <>
      <PageHeaderCustom title={t('files.create.title')} />
      <ContentCustom>
        <Row>
          {typeSelector && (
            <>
              <Col span={8} style={{ textAlign: 'right' }}>
                <div style={{ marginTop: '4px' }}>
                  <span style={{ color: 'red' }}>*</span>
                  &nbsp;
                  {t(`files.form.type`)}
                  &nbsp;:&nbsp;
                </div>
              </Col>
              <Col span={8} style={{ marginBottom: 10 }}>
                <TreeSelect
                  style={{ width: '100%' }}
                  onChange={(v) => setType(v)}
                >
                  <TreeNode
                    value="internal_rules_cgv"
                    title={t('ged.internal_rules_cgv')}
                    selectable={false}
                  >
                    <TreeNode value="cgv" title={t('ged.cgv')} />
                    <TreeNode
                      value="internal_rules"
                      title={t('ged.internal_rules')}
                    />
                  </TreeNode>
                  <TreeNode value="programs" title={t('ged.programs')} />
                  <TreeNode
                    value="customers_files"
                    title={t('ged.customers_files.title')}
                    selectable={false}
                  >
                    <TreeNode
                      value="company_convention"
                      title={t('ged.customers_files.company_convention')}
                    />
                    <TreeNode
                      value="private_convention"
                      title={t('ged.customers_files.private_convention')}
                    />
                    <TreeNode
                      value="vae_contract"
                      title={t('ged.customers_files.vae_contract')}
                    />
                    <TreeNode
                      value="other_customers_files"
                      title={t('ged.customers_files.other_files')}
                    />
                  </TreeNode>
                  <TreeNode
                    value="invoices_quotations"
                    title={t('ged.invoices_quotations.title')}
                    selectable={false}
                  >
                    <TreeNode
                      value="quotations"
                      title={t('ged.invoices_quotations.quotations')}
                    />
                    <TreeNode
                      value="invoices"
                      title={t('ged.invoices_quotations.invoices')}
                    />
                    <TreeNode
                      value="credit_notes"
                      title={t('ged.invoices_quotations.credit_notes')}
                    />
                    <TreeNode
                      value="subscription"
                      title={t('ged.invoices_quotations.subscription')}
                    />
                  </TreeNode>
                  <TreeNode
                    value="contributors_files"
                    title={t('ged.contributors_files.title')}
                    selectable={false}
                  >
                    <TreeNode
                      value="subcontracting"
                      title={t('ged.contributors_files.subcontracting')}
                    />
                    <TreeNode
                      value="other_contributors_files"
                      title={t(
                        'ged.contributors_files.other_contributors_files'
                      )}
                    />
                  </TreeNode>
                  <TreeNode
                    value="trainees_files"
                    title={t('ged.trainees_files.title')}
                    selectable={false}
                  >
                    <TreeNode
                      value="convocation"
                      title={t('ged.trainees_files.convocation')}
                    />
                    <TreeNode
                      value="training_action_completion"
                      title={t('ged.trainees_files.training_action_completion')}
                    />
                    <TreeNode
                      value="attestation_dpc"
                      title={t('ged.trainees_files.attestation_dpc')}
                    />
                    <TreeNode
                      value="vae_eligibility"
                      title={t('ged.trainees_files.vae_eligibility')}
                    />
                    <TreeNode
                      value="attendance_certificate"
                      title={t('ged.trainees_files.attendance_certificate')}
                    />
                    <TreeNode
                      value="other_trainees_files"
                      title={t('ged.trainees_files.other_trainees_files')}
                    />
                  </TreeNode>
                  <TreeNode
                    value="signings"
                    title={t('ged.signings.title')}
                    selectable={false}
                  >
                    <TreeNode
                      value="signings_day"
                      title={t('ged.signings.signingsPerDay')}
                    />
                    <TreeNode
                      value="signings_slot"
                      title={t('ged.signings.signingsPerSlot')}
                    />
                    <TreeNode
                      value="signings_trainee"
                      title={t('ged.signings.signingsPerTrainee')}
                    />
                    <TreeNode
                      value="signings_week"
                      title={t('ged.signings.signingsPerWeek')}
                    />
                    <TreeNode
                      value="signings_weekend"
                      title={t('ged.signings.signingsPerWeekEnd')}
                    />
                    <TreeNode
                      value="digital_signing_report"
                      title={t('ged.signings.digitalSigningReport')}
                    />
                  </TreeNode>
                  <TreeNode value="evaluations" title={t('ged.evaluations')} />
                  <TreeNode
                    value="import_template"
                    title={t('ged.import_template.title')}
                    selectable={false}
                  >
                    <TreeNode
                      value="users_import_template"
                      title={t('ged.import_template.users_import_template')}
                    />
                    <TreeNode
                      value="programs_import_template"
                      title={t('ged.import_template.programs_import_template')}
                    />
                    <TreeNode
                      value="trainees_import_template"
                      title={t('ged.import_template.trainees_import_template')}
                    />
                    <TreeNode
                      value="companies_import_template"
                      title={t('ged.import_template.companies_import_template')}
                    />
                    <TreeNode
                      value="contacts_import_template"
                      title={t('ged.import_template.contacts_import_template')}
                    />
                    <TreeNode
                      value="contributors_import_template"
                      title={t(
                        'ged.import_template.contributors_import_template'
                      )}
                    />
                    <TreeNode
                      value="external_founders_import_template"
                      title={t(
                        'ged.import_template.external_founders_import_template'
                      )}
                    />
                    <TreeNode
                      value="training_places_import_template"
                      title={t(
                        'ged.import_template.trainings_place_import_template'
                      )}
                    />
                  </TreeNode>
                </TreeSelect>
              </Col>
            </>
          )}
          <Col span={8} offset={8}>
            <Dragger {...props}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">{t('files.create.action')}</p>
              <p className="ant-upload-hint">{t('files.create.formats')}</p>
            </Dragger>
          </Col>
          <Col span={8} offset={8}>
            <Button
              type="primary"
              style={{
                color: '#fff',
                float: 'right',
                backgroundColor: 'rgb(71, 210, 103)',
                border: 'rgb(71, 210, 103)',
                marginTop: 40
              }}
              onClick={() => callback(history)}
            >
              Terminé
            </Button>
          </Col>
        </Row>
      </ContentCustom>
    </>
  );
};

CreateTemplate.propTypes = {
  typeSelector: PropTypes.bool,
  callback: PropTypes.func
};

CreateTemplate.defaultProps = {
  typeSelector: false,
  callback: (history) => history.push('/documents/templates')
};

export default CreateTemplate;
