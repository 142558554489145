import React from 'react';
import PropTypes from 'prop-types';
import CreateUpdateContainer from '../../components/CreateUpdateContainer/CreateUpdateContainer';
import useFields from './fields';

const CreateUpdateSubscribers = ({ purpose, type }) => {
  const { fields } = useFields(purpose, type);

  const config = {
    onCreateResource: {
      setBody: (data) => {
        return {
          ...data,
          role: data.role || 'users:SUPER-USER'
        };
      }
    },
    onUpdateResource: {
      setBody: (data) => {
        return {
          ...data
        };
      }
    },

    onGetResource: {
      setFields: (data) => {
        return { ...data, organization_name: data?.organization?.name };
      }
    }
  };
  return (
    <CreateUpdateContainer
      fields={fields}
      purpose={purpose}
      config={config}
      baseUrl="users"
      resource="users"
      withSubRoutes
      populate="organization"
    />
  );
};
CreateUpdateSubscribers.propTypes = {
  purpose: PropTypes.string.isRequired,
  type: PropTypes.string
};

CreateUpdateSubscribers.defaultProps = {
  type: null
};

export default CreateUpdateSubscribers;
