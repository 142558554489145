import React, { useState } from 'react';
import { Select, Input, Form, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { LockOutlined } from '@ant-design/icons/lib';

const { Option } = Select;

const useFields = (purpose, type) => {
  const { t } = useTranslation();
  const [phoneNumber, setPhoneNumber] = useState('');
  const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

  const passwordPattern = [
    {
      pattern: new RegExp(/(?=.*[0-9])/),
      message: t('login.pwdDigit')
    },
    {
      pattern: new RegExp(/(?=.*[a-z])/),
      message: t('login.pwdLowerCaseChar')
    },
    {
      pattern: new RegExp(/(?=.*[A-Z])/),
      message: t('login.pwdUpperCaseChar')
    },
    {
      pattern: new RegExp(/(?=.*[!@#$%^&*])/),
      message: t('login.pwdSpecialChar')
    },
    {
      pattern: new RegExp(/.{8,32}/),
      message: t('login.pwdLength')
    }
  ];

  const handleInput = (value) => {
    return value.replace(phoneRegex, '($1) $2-$3');
  };

  const fields = [
    {
      name: ['last_name'],
      rules: [{ required: true }]
    },
    {
      name: ['first_name'],
      rules: [{ required: true }]
    },
    {
      name: ['email'],
      rules: [
        { required: true, message: t('login.usernameMissing') },
        { type: 'email' }
      ]
    },
    ...(purpose === 'create'
      ? [
          {
            name: ['password'],
            rules: [
              { required: true, message: t('login.pwdMissing') },
              ...passwordPattern
            ],
            input: (
              <Input.Password
                prefix={
                  <LockOutlined
                    style={{ color: 'var(--textColorSecondary)' }}
                  />
                }
                placeholder={t('login.password')}
              />
            )
          },
          {
            name: ['conf-password'],
            rules: [
              { required: true, message: t('login.pwdMissing') },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(t('login.pwdNotMatching'));
                }
              })
            ],
            input: (
              <Input.Password
                prefix={
                  <LockOutlined
                    style={{ color: 'var(--textColorSecondary)' }}
                  />
                }
                placeholder={t('login.confirmPassword')}
              />
            )
          }
        ]
      : []),
    ...(type === 'access_account'
      ? [
          {
            name: ['role'],
            rules: [{ required: true }],
            input: (
              <Select style={{ width: '100%' }}>
                <Option value="users:PRESCRIBER">
                  <Tag color="green">{t('login.prescriber')}</Tag>
                </Option>
                <Option value="users:SUPER-USER">
                  <Tag color="geekblue">{t('login.access_account')}</Tag>
                </Option>
              </Select>
            )
          }
        ]
      : []),
    {
      name: ['phone_number'],
      label: 'phone_number.number',
      input: (
        <Input.Group compact>
          <Form.Item
            noStyle
            name={['phone_number', 'country_code']}
            initialValue="+33"
          >
            <Select style={{ width: '25%' }}>
              <Option value="+33">+33</Option>
            </Select>
          </Form.Item>
          <Form.Item noStyle name={['phone_number', 'number']}>
            <Input
              pattern="[0-9]{9}"
              value={handleInput(phoneNumber)}
              onChange={(e) => setPhoneNumber(e.target.value)}
              style={{ width: '75%' }}
              title={t('errors_code.wrong_phone_number_format')}
            />
          </Form.Item>
        </Input.Group>
      )
    },
    ...(type !== 'prescriber'
      ? [
          {
            label: 'organization_name',
            name: ['organization_name'],
            rules: [{ required: true }],
            input: <Input />
          }
        ]
      : []),
    {
      label: 'address.number',
      name: ['address', 'number']
    },
    {
      label: 'address.street',
      name: ['address', 'street']
    },
    {
      label: 'address.additional',
      name: ['address', 'additional']
    },
    {
      label: 'address.postcode',
      name: ['address', 'postal_code']
    },
    {
      label: 'address.city',
      name: ['address', 'city']
    }
  ];

  const companyFields = [
    {
      name: ['name'],
      rules: [{ required: false }]
    },
    {
      name: ['siret_number'],
      rules: [{ required: false }],
      input: <Input type="number" />
    }
  ];

  return {
    fields,
    companyFields
  };
};

export default useFields;
