import React, { useCallback, useEffect, useState } from 'react';
import { Form, Input, Select, Tag, Upload, message } from 'antd';
import { LockOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { userRoles } from '../../utils/constants/tagColors';
import useAuthContext from '../../contexts/AuthContext';
import ErrorStatusCode from '../../utils/ErrorStatusCode';

const { Option } = Select;
const { Dragger } = Upload;

const useFields = ({ purpose, userPhoto }) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { notification } = ErrorStatusCode();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [enums, setEnums] = useState({});
  const [fileList, setFileList] = useState([]);
  const [base64, setBase64] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [imageUrl, setImageUrl] = useState('');

  // This function convert the PDF to base64 format
  const fileToBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (e) => reject(e);
    });
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const draggerProps = {
    name: 'file',
    multiple: false,
    showUploadList: false,
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: async (file) => {
      const fileExtension = file.name.split('.').pop();
      if (
        fileExtension === 'png' ||
        fileExtension === 'PNG' ||
        fileExtension === 'jpg' ||
        fileExtension === 'JPG'
      ) {
        setFileList([...fileList, file]);
        const base = await fileToBase64(file);
        setBase64(base);
        getBase64(file, (image) => {
          setImageUrl(image);
          setIsFieldsLoading(false);
        });
        return false;
      }
      message('Not a PNG or JPG file.');
      return true;
    },
    fileList
  };
  const uploadButton = (
    <div>
      {isFieldsLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div>
        <p className="ant-upload-text">{t('files.create.action')}</p>
      </div>
    </div>
  );
  const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

  const handlePhoneNumber = (value) => {
    return value.replace(phoneRegex, '($1) $2-$3');
  };
  const passwordPattern = [
    {
      pattern: new RegExp(/(?=.*[0-9])/),
      message: t('login.pwdDigit')
    },
    {
      pattern: new RegExp(/(?=.*[a-z])/),
      message: t('login.pwdLowerCaseChar')
    },
    {
      pattern: new RegExp(/(?=.*[A-Z])/),
      message: t('login.pwdUpperCaseChar')
    },
    {
      pattern: new RegExp(/(?=.*[!@#$%^&*])/),
      message: t('login.pwdSpecialChar')
    },
    {
      pattern: new RegExp(/.{8,32}/),
      message: t('login.pwdLength')
    }
  ];
  const fields = [
    {
      name: ['photo'],
      input: (
        <Dragger {...draggerProps}>
          {userPhoto || imageUrl ? (
            <img
              src={imageUrl || userPhoto}
              alt="avatar"
              style={{ width: '60%' }}
            />
          ) : (
            uploadButton
          )}
        </Dragger>
      )
    },
    {
      name: ['_id'],
      noLabel: true,
      input: <Input style={{ display: 'none' }} />
    },
    {
      name: ['last_name'],
      rules: [{ required: true }]
    },
    {
      name: ['first_name'],
      rules: [{ required: true }]
    },
    {
      name: ['role'],
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading}>
          {(enums.roles || []).map((role) => (
            <Option key={role} value={role}>
              <Tag color={userRoles[role.split(':')[1]]}>
                {t(`users.tags.${role.split(':')[1]}`)}
              </Tag>
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['email'],
      rules: [
        { required: true, message: t('login.usernameMissing') },
        { type: 'email' }
      ]
    },
    ...(purpose === 'create'
      ? [
          {
            name: ['password'],
            rules: [
              { required: true, message: t('login.pwdMissing') },
              ...passwordPattern
            ],
            input: (
              <Input.Password
                prefix={
                  <LockOutlined
                    style={{ color: 'var(--textColorSecondary)' }}
                  />
                }
                placeholder={t('login.password')}
              />
            )
          },
          {
            name: ['conf-password'],
            rules: [
              { required: true, message: t('login.pwdMissing') },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(t('login.pwdNotMatching'));
                }
              })
            ],
            input: (
              <Input.Password
                prefix={
                  <LockOutlined
                    style={{ color: 'var(--textColorSecondary)' }}
                  />
                }
                placeholder={t('login.confirmPassword')}
              />
            )
          }
        ]
      : []),
    {
      name: ['phone_number'],
      label: 'phone_number.number',
      rules: [{ required: true }],
      input: (
        <Input.Group compact>
          <Form.Item
            noStyle
            name={['phone_number', 'country_code']}
            initialValue="+33"
          >
            <Select style={{ width: '25%' }}>
              <Option value="+33">+33</Option>
            </Select>
          </Form.Item>
          <Form.Item
            noStyle
            name={['phone_number', 'number']}
            rules={[
              {
                required: true,
                message: t('users.form.phone_number.place_holder')
              }
            ]}
          >
            <Input
              pattern="[0-9]{9}"
              value={handlePhoneNumber(phoneNumber)}
              onChange={(e) => setPhoneNumber(e.target.value)}
              style={{ width: '75%' }}
              title={t('errors_code.wrong_phone_number_format')}
            />
          </Form.Item>
        </Input.Group>
      )
    }
  ];

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/users/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getEnums();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    getSelectOptions();
  }, [getSelectOptions]);

  return {
    fields,
    isFieldsLoading,
    base64
  };
};

export default useFields;
