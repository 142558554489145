import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { message, Button, Drawer, Form, Spin, Row } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import useAuthContext from '../../../contexts/AuthContext';
import useFields from './fields';
import useGenerateFormItem from '../../../utils/GenerateFormItem';
import useDealContext from './DealsContext';
import ErrorStatusCode from '../../../utils/ErrorStatusCode';

const EditDrawer = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { dispatchAPI } = useAuthContext();
  const { notification } = ErrorStatusCode();
  const [isLoading, setIsLoading] = useState(false);
  const generateFormItem = useGenerateFormItem();
  const {
    visible,
    setVisible,
    deal,
    setDeal,
    dataRefresh,
    setDataRefresh
  } = useDealContext();
  const { fields, isFieldsLoading } = useFields(visible);

  const onClose = () => {
    form.resetFields();
    setVisible(false);
    setDeal(null);
    setDataRefresh(!dataRefresh);
  };

  const onFinishForm = async (values) => {
    try {
      await dispatchAPI(deal ? 'PATCH' : 'POST', {
        url: `/deals${deal ? `/${deal}` : ''}`,
        body: values
      });
      message.success(t('deals.messages.success.opportunity_saved'));
      onClose();
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
  };

  const fetchDeal = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/deals/${deal}`
      });
      form.setFieldsValue(data);
    } catch (e) {
      if (e.response) notification(e.response);
    }
    setIsLoading(false);
  }, [deal, isFieldsLoading]);

  useEffect(() => {
    if (deal && !isFieldsLoading)
      (async () => {
        await fetchDeal();
      })();
  }, [fetchDeal]);

  return (
    <Drawer
      title={t(`deals.show.${deal ? 'edit' : 'add'}`)}
      width={450}
      onClose={onClose}
      visible={visible}
      className="new-deal-drawer"
    >
      <Spin spinning={isLoading}>
        <Form
          wrapperCol={{ span: 24 }}
          labelCol={{ span: 24 }}
          form={form}
          onFinish={onFinishForm}
        >
          {fields.map((field) => generateFormItem('deals', field))}
          <Form.Item style={{ marginTop: 16, float: 'right' }}>
            <Row style={{ flexFlow: 'row nowrap' }}>
              <Button
                style={{ margin: '0 10px', float: 'right' }}
                type="link"
                danger
                onClick={onClose}
              >
                {`${t('buttons.cancel')} `}
                <CloseOutlined />
              </Button>
              <Button type="add" htmlType="submit" style={{ float: 'right' }}>
                {`${t('buttons.save')} `}
                <CheckOutlined />
              </Button>
            </Row>
          </Form.Item>
        </Form>
      </Spin>
    </Drawer>
  );
};

export default EditDrawer;
