import { useTranslation } from 'react-i18next';

const useColumns = () => {
  const { t } = useTranslation();

  const headers = [
    {
      label: 'name',
      key: 'name'
    },
    {
      label: 'description',
      key: 'description'
    },
    {
      label: 'address.number',
      key: 'address.number'
    },
    {
      label: 'address.street',
      key: 'address.street'
    },
    {
      label: 'address.additional',
      key: 'address.additional'
    },
    {
      label: 'address.postal_code',
      key: 'address.postal_code'
    },
    {
      label: 'address.city',
      key: 'address.city'
    },
    {
      label: 'capacity',
      key: 'capacity'
    },
    {
      label: 'compliant_disable_access',
      key: 'compliant_disable_access'
    },
    {
      label: 'free_note',
      key: 'free_note'
    },
    {
      label: 'organization',
      key: 'organization.name'
    }
  ];

  const columns = [
    {
      title: t('training-places.column.name'),
      dataIndex: 'name',
      sorter: true
    },
    {
      title: t('training-places.column.capacity'),
      dataIndex: 'capacity',
      sorter: true
    },
    {
      title: t('training-places.column.price_day'),
      dataIndex: 'price_day',
      sorter: true
    }
  ];

  return { columns, headers };
};

export default useColumns;
