import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import PrescriberHome from './PrescriberHome';

const PrescribersRouter = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}`} render={() => <PrescriberHome />} />
    </Switch>
  );
};

export default PrescribersRouter;
