import React from 'react';
import { Button, Space, Form, Input, PageHeader } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { ContentCustom } from '../../../../components';

const ExecutionTrack = (noLabelLayout) => {
  const { t } = useTranslation();
  return (
    <ContentCustom>
      <Form.Item {...noLabelLayout} noStyle name={['program']}>
        <Form.List name={['execution_track', 'results_track']}>
          {(fields, { add, remove }) => (
            <>
              <PageHeader title={t('programs.form.titles.results_track')} />
              {(fields.length
                ? fields
                : [{ fieldKey: 0, isListField: true, key: 0, name: 0 }]
              ).map((field) => (
                <Space
                  key={field.key}
                  style={{ display: 'flex', marginBottom: 8 }}
                  align="baseline"
                >
                  <Form.Item
                    noStyle
                    {...field}
                    fieldKey={[field.fieldKey, 'results_track']}
                  >
                    <Input />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(field.name)} />
                </Space>
              ))}
              <Form.Item>
                <Button
                  size="small"
                  type="primary"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                />
              </Form.Item>
            </>
          )}
        </Form.List>
        <Form.List name={['execution_track', 'educational_resources']}>
          {(fields, { add, remove }) => (
            <>
              <PageHeader
                title={t('programs.form.titles.pedagogicals_ressources')}
              />
              {(fields.length
                ? fields
                : [{ fieldKey: 0, isListField: true, key: 0, name: 0 }]
              ).map((field) => (
                <Space
                  key={field.key}
                  style={{ display: 'flex', marginBottom: 8 }}
                  align="baseline"
                >
                  <Form.Item
                    noStyle
                    {...field}
                    fieldKey={[field.fieldKey, 'educational_resources']}
                  >
                    <Input />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(field.name)} />
                </Space>
              ))}
              <Form.Item>
                <Button
                  size="small"
                  type="primary"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                />
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form.Item>
    </ContentCustom>
  );
};

export default ExecutionTrack;
