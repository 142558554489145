import React, { useCallback, useEffect, useState } from 'react';
import { Button, Checkbox, Col, Form, Input, message, Row, Select } from 'antd';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../../contexts/AuthContext';
import { ContentCustom } from '../../../components';
import ErrorStatusCode from '../../../utils/ErrorStatusCode';

const RatesList = ({
  setForceRefresh,
  forceRefresh,
  mod,
  edit,
  setEdit,
  refresh,
  setRefresh
}) => {
  const { Option } = Select;
  const { t } = useTranslation();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const { dispatchAPI } = useAuthContext();
  const { notification } = ErrorStatusCode();
  const [enums, setEnums] = useState([]);
  const [priceByCustomer, setPriceByCustomer] = useState(false);
  const [submitButton, setSubmitButton] = useState(false);
  const [module, setModule] = useState({});
  const [form] = Form.useForm();

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/module/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  const updateModule = async (body) => {
    try {
      await dispatchAPI('PATCH', { url: `/module/${mod._id}`, body });
      setRefresh(!refresh);
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
  };

  useEffect(() => {
    mod.rates.forEach((rate) => {
      if (rate.company_rate) {
        setPriceByCustomer(true);
      }
    });
  }, [mod]);
  useEffect(() => {
    if (edit === true) {
      form.setFieldsValue(mod);
    }
  }, [edit]);

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getEnums();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  const onChange = () => {
    setPriceByCustomer(!priceByCustomer);
  };

  const addPriceToModule = async (values) => {
    if (!priceByCustomer) {
      delete values.rates[0].company_rate;
      delete values.rates[0].independent_rate;
      delete values.rates[0].private_rate;
    } else {
      delete values.rates[0].rate;
    }

    const body = {
      ...module,
      purpose: 'create',
      rates: [...values.rates]
    };

    await updateModule(body);
    setForceRefresh(!forceRefresh);
    setEdit(false);
    form.resetFields();
  };

  return (
    <div>
      <Form onFinish={addPriceToModule} form={form} autoComplete="off">
        <Form.List name={['rates']} company_rate={['company_rate']}>
          {(fields, { add, remove }) => (
            <>
              {(fields.length
                ? fields
                : [{ fieldKey: 0, isListField: true, key: 0, name: 0 }]
              ).map((field) => (
                <ContentCustom>
                  <div
                    className="session-rates-wrapper"
                    style={{
                      background: 'var(--contrastBackground)',
                      padding: 16,
                      borderRadius: 4,
                      margin: '-6px -20px'
                    }}
                  >
                    <CloseOutlined onClick={() => remove(field.name)} />
                    <Row justify="end">
                      {priceByCustomer === false ? (
                        <>
                          <Col xxl={4} xl={5} lg={6} md={7} sm={10} xs={20}>
                            <Form.Item
                              {...field}
                              label={t('sessions.modules.priceByCustomer')}
                            >
                              <Checkbox
                                checked={priceByCustomer}
                                onChange={onChange}
                              />
                            </Form.Item>
                          </Col>
                          <Col xxl={4}>
                            <Form.Item
                              label={t('sessions.modules.monthly_payment')}
                              {...field}
                              name={[field.name, 'monthly_payment']}
                              fieldKey={[field.fieldKey, 'monthly_payment']}
                              valuePropName="checked"
                            >
                              <Checkbox />
                            </Form.Item>
                          </Col>
                        </>
                      ) : (
                        <>
                          <Col xxl={4} xl={5} lg={6} md={7} sm={10} xs={20}>
                            <Form.Item
                              {...field}
                              label={t('sessions.modules.priceByCustomer')}
                            >
                              <Checkbox
                                checked={priceByCustomer}
                                onChange={onChange}
                              />
                            </Form.Item>
                          </Col>
                          <Col xxl={4}>
                            <Form.Item
                              label={t('sessions.modules.monthly_payment')}
                              {...field}
                              name={[field.name, 'monthly_payment']}
                              fieldKey={[field.fieldKey, 'monthly_payment']}
                              valuePropName="checked"
                            >
                              <Checkbox />
                            </Form.Item>
                          </Col>
                        </>
                      )}
                    </Row>
                    <Row>
                      <Col span={10}>
                        <Form.Item
                          rules={[
                            {
                              required: true
                            }
                          ]}
                          label={t('sessions.modules.rate_type')}
                          {...field}
                          name={[field.name, 'rate_type']}
                          fieldKey={[field.fieldKey, 'rate_type']}
                        >
                          <Select
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            loading={isFieldsLoading}
                          >
                            {(enums.rate_type || []).map((title) => (
                              <Option key={title} value={title}>
                                {t(`sessions.modules.form.${title}`)}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={8} offset={6}>
                        <Form.Item
                          rules={[
                            {
                              required: true
                            }
                          ]}
                          label={t('sessions.modules.customer_type')}
                          {...field}
                          name={[field.name, 'customer_type']}
                          fieldKey={[field.fieldKey, 'customer_type']}
                        >
                          <Select
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            loading={isFieldsLoading}
                          >
                            {(enums.customer_type || []).map((title) => (
                              <Option key={title} value={title}>
                                {t(`sessions.modules.form.${title}`)}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row>
                      <Col span={12} style={{ paddingRight: 12 }}>
                        <Form.Item
                          rules={[
                            {
                              required: priceByCustomer
                            }
                          ]}
                          {...field}
                          hidden={priceByCustomer}
                          label={t('sessions.modules.name')}
                          name={[field.name, 'name']}
                          fieldKey={[field.fieldKey, 'name']}
                        >
                          <Input placeholder="Formation" />
                        </Form.Item>
                      </Col>
                      <Col span={4} style={{ paddingRight: 12 }}>
                        <Form.Item
                          {...field}
                          rules={[
                            {
                              required: priceByCustomer
                            }
                          ]}
                          hidden={!priceByCustomer}
                          className="rates-by-customer"
                          label={t('sessions.modules.company_rate')}
                          name={[field.name, 'company_rate']}
                          fieldKey={[field.fieldKey, 'company_rate']}
                        >
                          <Input type="number" suffix="€" />
                        </Form.Item>
                      </Col>
                      <Col span={4} style={{ paddingRight: 12 }}>
                        <Form.Item
                          {...field}
                          rules={[
                            {
                              required: priceByCustomer
                            }
                          ]}
                          hidden={!priceByCustomer}
                          className="rates-by-customer"
                          label={t('sessions.modules.independent_rate')}
                          name={[field.name, 'independent_rate']}
                          fieldKey={[field.fieldKey, 'independent_rate']}
                        >
                          <Input type="number" suffix="€" />
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Form.Item
                          rules={[
                            {
                              required: priceByCustomer
                            }
                          ]}
                          {...field}
                          hidden={!priceByCustomer}
                          className="rates-by-customer"
                          label={t('sessions.modules.private_rate')}
                          name={[field.name, 'private_rate']}
                          fieldKey={[field.fieldKey, 'private_rate']}
                        >
                          <Input type="number" suffix="€" />
                        </Form.Item>
                      </Col>

                      <Col span={20} style={{ paddingRight: 12 }}>
                        <Form.Item
                          {...field}
                          rules={[
                            {
                              required: priceByCustomer
                            }
                          ]}
                          hidden={!priceByCustomer}
                          label={t('sessions.modules.name')}
                          name={[field.name, 'name']}
                          fieldKey={[field.fieldKey, 'name']}
                        >
                          <Input placeholder="Formation" />
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Form.Item
                          {...field}
                          rules={[
                            {
                              required: !priceByCustomer
                            }
                          ]}
                          hidden={priceByCustomer}
                          label={t('sessions.modules.rate')}
                          name={[field.name, 'rate']}
                          fieldKey={[field.fieldKey, 'rate']}
                        >
                          <Input type="number" suffix="€" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={4} offset={20}>
                        <Form.Item
                          {...field}
                          rules={[
                            {
                              required: true
                            }
                          ]}
                          label="TVA"
                          name={[field.name, 'tva']}
                          fieldKey={[field.fieldKey, 'tva']}
                        >
                          <Input type="number" suffix="%" />
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                </ContentCustom>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => {
                    add();
                    setSubmitButton(true);
                  }}
                  block
                  icon={<PlusOutlined />}
                  style={{ marginTop: 16 }}
                >
                  {t('sessions.form.button.addRate')}
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        <Form.Item>
          <div style={{ float: 'right' }}>
            {edit === true && (
              <Button
                style={{ marginTop: 16 }}
                type="link"
                danger
                onClick={() => {
                  setEdit(!edit);
                }}
              >
                Annuler
              </Button>
            )}
            <Button type="add" htmlType="submit" style={{ marginTop: 16 }}>
              {t('sessions.form.button.submitRates')}
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default RatesList;
