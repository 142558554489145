import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'antd';

const HeaderDropdown = ({ children, overlayMenu }) => {
  return (
    <Dropdown trigger={['click']} overlay={overlayMenu}>
      {children}
    </Dropdown>
  );
};

HeaderDropdown.propTypes = {
  overlayMenu: PropTypes.shape({}).isRequired,
};

export default HeaderDropdown;
