import React, { useCallback, useEffect, useState } from 'react';
import { Button, Form, Input, Select } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';
import NewCompanyDrawer from '../Companies/NewCompanyDrawer';
import useHandleResize from '../../../utils/HandleResize';

const { Option } = Select;

const useFields = (selectStatus) => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const [refresh, setRefresh] = useState(false);
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [visibilityCompanyDrawer, setVisibilityCompanyDrawer] = useState(false);
  const [companies, setCompanies] = useState([]);
  let selectedFormField;

  const { width } = useHandleResize();

  const getCompanies = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/companies' });
      setCompanies(data);
    } catch (e) {
      if (e.response) message(e.response.message);
    }
  };

  const drawerCompanyVisibility = () => {
    setVisibilityCompanyDrawer(!visibilityCompanyDrawer);
  };

  const drawerVisibility = () => {
    drawerCompanyVisibility();
  };

  const isEmployeeFields = [
    {
      label: 'company',
      name: ['company'],
      rules: [
        selectStatus === 'EMPLOYEE' ? { required: true } : { required: false }
      ],
      input: (
        <Input.Group compact>
          <NewCompanyDrawer
            isVisible={visibilityCompanyDrawer}
            setVisibilityCompanyDrawer={drawerCompanyVisibility}
            getCompanies={getCompanies}
            refresh={refresh}
            setCompany={null}
            setRefresh={setRefresh}
          />
          <Form.Item name={['company']}>
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              style={{ width: 340 }}
              loading={isFieldsLoading}
            >
              {companies.map((company) => (
                <Option key={company._id} value={company._id}>
                  {company.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Button
            type="add"
            onClick={drawerVisibility}
            style={{
              float: width > 1700 ? 'right' : 'none'
            }}
          >
            <span style={{ fontSize: '15px' }}>{t('buttons.create')}</span>
            <PlusOutlined />
          </Button>
        </Input.Group>
      )
    },
    {
      label: 'function',
      name: ['function']
    }
  ];

  const isIndependentFields = [
    {
      label: 'company',
      name: ['company'],
      input: (
        <Input.Group compact>
          <NewCompanyDrawer
            isVisible={visibilityCompanyDrawer}
            setVisibilityCompanyDrawer={drawerCompanyVisibility}
            getCompanies={getCompanies}
            refresh={refresh}
            setCompany={null}
            setRefresh={setRefresh}
          />
          <Form.Item name={['company']}>
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              style={{ width: 340 }}
              loading={isFieldsLoading}
            >
              {companies.map((company) => (
                <Option key={company._id} value={company._id}>
                  {company.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Button
            type="add"
            onClick={drawerVisibility}
            style={{
              float: width > 1700 ? 'right' : 'none'
            }}
          >
            <span style={{ fontSize: '15px' }}>{t('buttons.create')}</span>
            <PlusOutlined />
          </Button>
        </Input.Group>
      )
    }
  ];

  const isPrivateFields = [
    {
      label: 'function',
      name: ['function']
    }
  ];

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getCompanies();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  if (selectStatus === 'INDEPENDENT') {
    selectedFormField = isIndependentFields;
  } else if (selectStatus === 'PRIVATE') {
    selectedFormField = isPrivateFields;
  } else {
    selectedFormField = isEmployeeFields;
  }

  return {
    selectedFormField,
    isFieldsLoading
  };
};

export default useFields;
