import React from 'react';
import PropTypes from 'prop-types';
import { Statistic } from 'antd';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';

const InvoiceIndicator = ({
  visible,
  totalProduct,
  totalCharge,
  totalCreditNotes,
  totalMarges
}) => {
  return (
    <>
      <div style={{ display: 'flex' }}>
        <Statistic
          className="session-invoice-statistic"
          title="Chiffre d'affaires"
          value={visible ? totalProduct : '-'}
          precision={2}
          suffix="€"
          style={{ marginRight: '100px', marginLeft: '40px' }}
        />
        <Statistic
          className="session-invoice-statistic"
          title="Charges HT"
          value={
            visible
              ? totalCharge < 1000
                ? totalCharge
                : totalCharge / 1000
              : '-'
          }
          precision={2}
          suffix={totalCharge < 1000 ? '€' : 'K €'}
          style={{ marginRight: '100px' }}
        />
        <Statistic
          className="session-invoice-statistic"
          title="Marge HT"
          value={visible ? totalMarges - totalCharge - totalCreditNotes : '-'}
          precision={2}
          valueStyle={{
            color:
              (totalMarges - totalCharge - totalCreditNotes) / 1000 > 0
                ? '#01b775'
                : '#f12b00'
          }}
          prefix={
            (totalMarges - totalCharge - totalCreditNotes) / 1000 > 0 ? (
              <ArrowUpOutlined />
            ) : (
              <ArrowDownOutlined />
            )
          }
          suffix="€"
        />
      </div>
    </>
  );
};

export default InvoiceIndicator;

InvoiceIndicator.propTypes = {
  visible: PropTypes.number.isRequired,
  totalProduct: PropTypes.number.isRequired,
  totalCharge: PropTypes.number.isRequired,
  totalCreditNotes: PropTypes.number.isRequired,
  totalMarges: PropTypes.number.isRequired
};
