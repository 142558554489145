const deleteResource = async ({
  id,
  dispatchAPI,
  fetchData,
  resourceName,
  message
}) => {
  try {
    await dispatchAPI('DELETE', { url: `/${resourceName}/${id}` });
    await fetchData();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    if (e.response) message.error(e.response.data.message);
  }
};

export default deleteResource;
