import PropTypes from 'prop-types';

const getFilesPrivate = ({ setTemplatesPrivate, user, notification }) => {
  try {
    const list = user.organization.templates.filter(
      (el) => el.type === 'private_convention'
    );
    setTemplatesPrivate(list);
  } catch (e) {
    if (e.response) notification(e.response);
  }
};

getFilesPrivate.propTypes = {
  setTemplatesPrivate: PropTypes.func.isRequired,
  user: PropTypes.arrayOf({}).isRequired,
  notification: PropTypes.func.isRequired
};

export default getFilesPrivate;
