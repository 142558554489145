import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Modal, message } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import SlotsToContributorsSession from '../sessionContributors/SlotsToContributorsSession';
import SlotsFormList from './SlotsFormList';
import useAuthContext from '../../../contexts/AuthContext';

const EditDeleteSlotsModal = ({
  reloadCalendar,
  setReloadCalendar,
  showModale,
  setShowModale,
  module
}) => {
  const [expandedKeys, setExpandedKeys] = useState([module._id]);
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [slotsData, setSlotsData] = useState([]);
  const [modifiedList, setModifiedList] = useState([]);
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();

  const handleCancel = () => {
    setShowModale(!showModale);
  };

  const deleteSubSlot = (indexSlot, indexSub) => {
    setModifiedList(
      modifiedList.map((_, index) => {
        if (index === indexSlot) {
          modifiedList[index].sub_slots = modifiedList[index].sub_slots.filter(
            (el, idx) => idx !== indexSub
          );
        }
        return modifiedList[index];
      })
    );
  };

  useEffect(() => {
    const treeData = [
      {
        title: module.name,
        id: module._id,
        key: module._id,
        children: []
      }
    ];
    module.slots.date.forEach((date) => {
      if (treeData) {
        treeData[0].children.push({
          title: `${moment(date.date).format('dddd')} ${moment(
            date.date
          ).format('LL')}`,
          key: date._id,
          children: []
        });
      }
    });
    module.slots.date.forEach((date) => {
      if (treeData) {
        treeData[0].children.forEach((item, indexChild) => {
          if (item.key === date._id) {
            date.sub_slots.forEach((subSlot) => {
              treeData[0].children[indexChild].children.push({
                title: `${subSlot.start_time} - ${subSlot.end_time}`,
                key: subSlot._id,
                disableCheckbox: true
              });
            });
          }
        });
      }
    });
    setSlotsData(treeData);
  }, [module]);

  const updateModule = async (body) => {
    try {
      await dispatchAPI('PATCH', { url: `/module/${module._id}`, body });
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
  };

  const updateSlotsModule = async () => {
    const listDates = module.slots.date;
    const listId = [];
    let newList = [];
    listDates.forEach((date) => {
      modifiedList.forEach((modif) => {
        if (date._id.toString() === modif._id.toString()) {
          listId.push(date._id);
        }
      });
    });

    newList = listDates.filter((item) => !listId.includes(item._id));

    const datesList = [...newList, ...modifiedList];

    const dates = datesList.filter((el) => el.sub_slots.length !== 0);
    const body = {
      slots: {
        date: dates
      }
    };

    await updateModule(body);
    setReloadCalendar(!reloadCalendar);
    setShowModale(!showModale);
  };

  useEffect(() => {
    const tmp = [];
    if (checkedKeys.length) {
      checkedKeys.forEach((key) => {
        module.slots.date.forEach((date) => {
          if (date._id.toString() === key.toString()) {
            tmp.push(date);
          }
        });
      });
    }
    setModifiedList(tmp);
  }, [checkedKeys]);

  return (
    <Modal
      title="Dates"
      visible={showModale}
      width="80%"
      onCancel={handleCancel}
      destroyOnClose
      footer={null}
    >
      <Row>
        <Col span={12}>
          <SlotsToContributorsSession
            module={module}
            slots={slotsData}
            expandedKeys={expandedKeys}
            selectedKeys={selectedKeys}
            setExpandedKeys={setExpandedKeys}
            checkedKeys={checkedKeys}
            setCheckedKeys={setCheckedKeys}
            setSelectedKeys={setSelectedKeys}
          />
        </Col>
        <Col span={12}>
          <SlotsFormList
            slots={modifiedList}
            setSlots={setModifiedList}
            deleteSubSlot={deleteSubSlot}
          />
        </Col>
      </Row>
      <Row justify="end">
        {checkedKeys.length !== 0 && (
          <>
            <Button
              style={{ margin: '0 10px' }}
              type="link"
              danger
              onClick={() => setShowModale(!showModale)}
            >
              {t('buttons.cancel')}
              <CloseOutlined />
            </Button>
            <Button type="add" onClick={() => updateSlotsModule()}>
              {`${t('buttons.save')} `}
              <CheckOutlined />
            </Button>
          </>
        )}
      </Row>
    </Modal>
  );
};

export default EditDeleteSlotsModal;

EditDeleteSlotsModal.propTypes = {
  reloadCalendar: PropTypes.bool.isRequired,
  setReloadCalendar: PropTypes.func.isRequired,
  showModale: PropTypes.bool.isRequired,
  setShowModale: PropTypes.func.isRequired,
  module: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    slots: PropTypes.shape({
      date: PropTypes.arrayOf(PropTypes.instanceOf(Date))
    })
  }).isRequired
};
