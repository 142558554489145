import React, { useState } from 'react';
import { Input, Select } from 'antd';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

const useFields = () => {
  const { t } = useTranslation();
  const [deadline, setDeadline] = useState([]);

  const deadlinePayments = [
    '15 jours',
    '30 jours',
    '45 jours',
    '60 jours',
    '90 jours',
    'Comptant',
  ];

  const fields = [
    {
      label: 'payment_deadline',
      name: ['payment_deadline'],
      rules: [{ required: true }],
      input: <Input type="number" />,
    },
    {
      name: ['recovery_fees'],
    },
    {
      name: ['penalty_rate'],
    },
  ];

  return {
    fields,
  };
};

export default useFields;
