const columns = (t, type, purpose) => [
  {
    title: t(`subscriptions.quality.columns.${type}`),
    key: type,
    render: null,
    sorter: true,
  },
  {
    title: t('subscriptions.quality.columns.status'),
    render: null,

    sorter: true,
  },

  {
    title: t('subscriptions.quality.columns.global_total'),
    key: 'total',
    render: null,
  },
];
export default columns;
