import React, { useState } from 'react';
import { Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import ContentCustom from '../../components/ContentCustom/ContentCustom';
import Datatable from '../../components/DataTable/Datatable';
import PageHeaderCustom from '../../components/PageHeader/PageHeader';
import ErrorStatusCode from '../../utils/ErrorStatusCode';
import useAuthContext from '../../contexts/AuthContext';
import { SearchAddBar } from '../../utils/constants/customButton';
import useColumns from './columns';

const { TabPane } = Tabs;

const AccessAcount = () => {
  const { notification } = ErrorStatusCode();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [searchValue, setSearchValue] = useState(params.get('k'));
  const [refresh, setRefresh] = useState(false);
  const columns = useColumns({
    dispatchAPI,
    setRefresh,
    refresh,
    notification
  });

  const tabs = [
    { value: 'all', key: 1 },
    { value: 'sponsorship', key: 2 },
    { value: 'prescriber', key: 3 }
  ];

  return (
    <>
      <PageHeaderCustom title="Comptes d'accès" />
      <ContentCustom>
        <Tabs>
          {tabs.map(({ value, key }) => (
            <TabPane tab={t(`users.tabs.${value}`)} key={key}>
              <SearchAddBar
                addPath="access-account/create"
                setSearchValue={setSearchValue}
              />
              <Datatable
                resourceName="users/admin-display"
                extraQuery={`role!=guests:GUEST,users:USER,admins:ADMIN,admins:SUPER-ADMIN${
                  value === 'sponsorship' ? ',users:PRESCRIBER' : ''
                }${
                  value === 'prescriber' ? ',users:SUPER-USER' : ''
                }&fields=-photo`}
                columns={columns}
                forceRefresh={refresh}
                customActionColumn
                searchValue={searchValue}
              />
            </TabPane>
          ))}
        </Tabs>
      </ContentCustom>
    </>
  );
};

export default AccessAcount;
