import React from 'react';
import { Skeleton, Typography } from 'antd';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import useAccountingContext from '../../AccountingContext';

const Container = styled.div`
  background-color: var(--contrastBackground);
  padding: 10px;
  minheight: 150px;
`;
const { Title } = Typography;

const Customer = ({ isLoading, customer }) => {
  const { t } = useTranslation();
  const { collection } = useAccountingContext();

  return (
    <Container>
      <Skeleton loading={isLoading} paragraph={{ rows: 1, width: '50%' }}>
        {customer && (
          <>
            <Title level={5} style={{ marginBottom: '0', color: 'lightgrey' }}>
              {t(`${collection}.show.customer`)}
            </Title>
            <Title level={3} style={{ marginTop: '0' }}>
              <Link to={`/companies/show/${customer._id}`}>
                {customer.first_name} {customer.last_name}
              </Link>
            </Title>
            {customer.number} {customer.street}
            <br />
            {customer.postcode} {customer.city}
          </>
        )}
      </Skeleton>
    </Container>
  );
};

Customer.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  customer: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    channel_number: PropTypes.number,
    address_details: PropTypes.string,
    postal_code: PropTypes.number,
    city: PropTypes.string,
  }).isRequired,
};

export default Customer;
