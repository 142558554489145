import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import Deals from './Deals/Deals';
import ShowDeal from './Deals/ShowDeal';

const CommercialRouter = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/deals/show/:id`} component={ShowDeal} />
      <Route path={`${path}/deals`} component={Deals} />
      <Route path={path} render={() => <Redirect to={`${path}/deals`} />} />
    </Switch>
  );
};

export default CommercialRouter;
