import { useTranslation } from 'react-i18next';
import { notification } from 'antd';

const ErrorStatusCode = () => {
  const { t } = useTranslation();

  return {
    notification: response => {
      notification.warn({
        message: `${t(
          `errors_code.notification.status_${response?.data?.status_code}`
        )} ${
          response?.data?.description
            ? `, ${t(
                `errors_code.notification.${response?.data?.description}`
              )}`
            : ''
        }`,

        key: 'response',
      });
    },
  };
};

export default ErrorStatusCode;
