import React from 'react';
import { Button, List, Card, message, Popconfirm } from 'antd';
import { useHistory } from 'react-router-dom';
import { EditOutlined, WarningOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../../contexts/AuthContext';
import { routes, subRoutes } from '../../../utils/constants/routes';

const TemplatesList = ({ emails, forceRefresh, setForceRefresh }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { dispatchAPI } = useAuthContext();

  const deleteEmail = async id => {
    try {
      await dispatchAPI('DELETE', { url: `/emails/${id}` });
      setForceRefresh(!forceRefresh);
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
  };

  const updateModelEmail = async (id, body) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/emails/${id}`,
        body,
      });
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
  };

  const changeDefaultModel = async id => {
    const oldDefaultModel = emails.filter(el => el && el.default)[0];
    await updateModelEmail(oldDefaultModel._id, { default: false });
    await updateModelEmail(id, { default: true });
    setForceRefresh(!forceRefresh);
  };

  return (
    <Card>
      {emails.length !== 0 && (
        <List
          dataSource={emails}
          renderItem={item => (
            <List.Item
              onDoubleClick={() =>
                history.push(
                  `${routes.DOCUMENTS}${subRoutes.DOCUMENTS.TEMPLATE_EMAILS}/edit/${item._id}`
                )
              }
              actions={
                item.default
                  ? [
                      <Button
                        type="link"
                        icon={<EditOutlined />}
                        onClick={() =>
                          history.push(
                            `${routes.DOCUMENTS}${subRoutes.DOCUMENTS.TEMPLATE_EMAILS}/edit/${item._id}`
                          )
                        }
                      />,
                      <span>Par défaut</span>,
                    ]
                  : [
                      <Button
                        type="link"
                        icon={<EditOutlined />}
                        onClick={() =>
                          history.push(
                            `${routes.DOCUMENTS}${subRoutes.DOCUMENTS.TEMPLATE_EMAILS}/edit/${item._id}`
                          )
                        }
                      />,
                      <Popconfirm
                        title={t('datatable.column.action.delete.title')}
                        okText={t('datatable.column.action.delete.ok')}
                        okButtonProps={{ danger: true }}
                        cancelText={t('datatable.column.action.delete.cancel')}
                        onConfirm={() => deleteEmail(item._id)}
                        icon={<WarningOutlined />}>
                        <Button type="link" danger>
                          {`${t('buttons.delete')} `}
                        </Button>
                      </Popconfirm>,
                      <Button
                        type="link"
                        onClick={() => changeDefaultModel(item?._id)}>
                        Par défaut
                      </Button>,
                    ]
              }>
              <List.Item.Meta title={item.name} />
            </List.Item>
          )}
        />
      )}
    </Card>
  );
};

export default TemplatesList;
