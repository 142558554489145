import React from 'react';
import { Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { ContentCustom, PageHeaderCustom } from '../../components';
import GlobalView from './tabs/GlobalView';
import Affiliations from './tabs/Affiliations';
import Commissions from './tabs/Commissions';
import Subscriptions from './tabs/Subscriptions';
import Indicators from './tabs/Indicators';

const { TabPane } = Tabs;

const PrescriberHome = () => {
  const { t } = useTranslation();
  const tabsList = [
    'global',
    'indicators',
    'affiliate',
    'commission',
    'subscribe'
  ];

  const checkRender = (type) => {
    switch (type) {
      case 'global':
        return <GlobalView />;
      case 'indicators':
        return <Indicators />;
      case 'affiliate':
        return <Affiliations />;
      case 'commission':
        return <Commissions />;
      case 'subscribe':
        return <Subscriptions />;
      default:
        return <GlobalView />;
    }
  };

  return (
    <>
      <PageHeaderCustom title="Parrainage" />
      <ContentCustom>
        <Tabs>
          {tabsList.map((el) => (
            <TabPane tab={t(`prescriber.tabs.${el}`)} key={el}>
              {checkRender(el)}
            </TabPane>
          ))}
        </Tabs>
      </ContentCustom>
    </>
  );
};

export default PrescriberHome;
