import React, { useCallback, useEffect, useState } from 'react';
import { Input, Select } from 'antd';
import useAuthContext from '../../../contexts/AuthContext';
import ErrorStatusCode from '../../../utils/ErrorStatusCode';

const { Option } = Select;
const { TextArea } = Input;

const useField = () => {
  const [enums, setEnums] = useState({});
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const { dispatchAPI } = useAuthContext();
  const { notification } = ErrorStatusCode();

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/external-funders/enums'
      });
      setEnums(data);
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getEnums();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  const fields = [
    {
      label: 'name',
      name: ['name'],
      rules: [{ required: true }],
      input: <Input />
    },
    {
      label: 'email',
      name: ['email'],
      rules: [{ required: true }, { type: 'email' }]
    },
    {
      label: 'type',
      name: ['type'],
      rules: [{ required: true }],
      input: (
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          loading={isFieldsLoading}
        >
          {(enums.external_funder_types || []).map((title) => (
            <Option key={title} value={title}>
              {title}
            </Option>
          ))}
        </Select>
      )
    },
    {
      label: 'channel_number',
      name: ['address', 'number'],
      input: <Input type="number" />
    },
    {
      label: 'street',
      name: ['address', 'street']
    },
    {
      label: 'address_details',
      name: ['address', 'additional']
    },
    {
      label: 'postal_code',
      name: ['address', 'postal_code'],
      input: <Input type="number" />
    },
    {
      label: 'city',
      name: ['address', 'city']
    },
    {
      label: 'contact',
      name: 'email_contact'
    },
    {
      label: 'free_notes',
      name: ['free_notes'],
      rules: [{ required: false }],
      input: <TextArea />
    }
  ];

  return {
    fields,
    isFieldsLoading
  };
};

export default useField;
