import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { message, Upload, Modal } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../../contexts/AuthContext';
import ErrorStatusCode from '../../../utils/ErrorStatusCode';

const { Dragger } = Upload;

const ModalUploadFiles = ({
  isModalVisible,
  setIsModalVisible,
  id,
  fetchData,
  fileType,
  uploadType,
  redirect,
  sessionId
}) => {
  const { t } = useTranslation();
  const { dispatchAPI, token, user } = useAuthContext();
  const [minSubscription, setMinSubscription] = useState({});
  const [upload, setUpload] = useState(false);
  const { notification } = ErrorStatusCode();
  const history = useHistory();

  const errorMessage = `${t(
    'programs.error_messages.limited_storage_capacity'
  )} ${
    user.organization.current_subscription
      ? t('programs.error_messages.subscription')
      : t('programs.error_messages.trial_version')
  }.`;

  const onChangeMessages = (info) => {
    const { status } = info.file;
    if (status === 'done') {
      message.success(
        `${info.file.name} ${t('programs.success_messages.upload_success')}.`
      );
    } else if (status === 'error') {
      message.error(
        `${info.file.name} ${t('programs.error_messages.upload_failed')}.`
      );
    }
  };

  const getSubscription = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/subscriptions?title=AISANCE`
      });
      setMinSubscription(data[0]);
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  useEffect(() => {
    (async () => {
      await getSubscription();
    })();
  }, []);

  let props;
  if (uploadType === 'programs') {
    props = {
      name: 'file',
      multiple: true,
      headers: { Authorization: `Bearer ${token}` },
      action:
        upload &&
        `${process.env.REACT_APP_API_URL}/programs/files/${id}?sessionId=${sessionId}`,
      beforeUpload(file) {
        const sizeFile = file.size / 1000000000;
        if (
          user.organization.current_storage + sizeFile <=
          (user.organization.current_subscription?.storage_capacity ||
            minSubscription.storage_capacity)
        ) {
          setUpload(!upload);
        } else {
          message.error(errorMessage);
        }
      },
      onChange(info) {
        onChangeMessages(info);
      }
    };
  } else {
    props = {
      name: 'file',
      multiple: true,
      headers: { Authorization: `Bearer ${token}` },
      action: `${process.env.REACT_APP_API_URL}/sessions/files/${id}?type=${fileType}`,
      beforeUpload(file) {
        const sizeFile = file.size / 1000000000;
        if (
          user.organization.current_storage + sizeFile <=
          (user.organization.current_subscription?.storage_capacity ||
            minSubscription.storage_capacity)
        ) {
          setUpload(!upload);
        } else {
          message.error(errorMessage);
        }
      },
      onChange(info) {
        onChangeMessages(info);
      }
    };
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleOk = async () => {
    setIsModalVisible(false);
    await fetchData();
    if (redirect) {
      if (sessionId) {
        history.push(`/sessions/show/${sessionId}`);
      } else {
        history.push(`/catalog/programs`);
      }
    }
  };

  return (
    <Modal
      visible={isModalVisible}
      onCancel={handleCancel}
      onOk={handleOk}
      width="100%"
    >
      <Dragger {...props}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">{t('programs.dragger_message')}</p>
      </Dragger>
    </Modal>
  );
};

ModalUploadFiles.propTypes = {
  isModalVisible: PropTypes.bool.isRequired,
  setIsModalVisible: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  fetchData: PropTypes.func.isRequired,
  fileType: PropTypes.string.isRequired,
  uploadType: PropTypes.string.isRequired,
  redirect: PropTypes.bool,
  sessionId: PropTypes.string
};

ModalUploadFiles.defaultProps = {
  redirect: false,
  sessionId: ''
};

export default ModalUploadFiles;
