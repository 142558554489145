import React from 'react';
import { useTranslation } from 'react-i18next';

const VisibilityOptions = () => {
  const { t } = useTranslation();

  const TraineesVisibilityOptions = [
    {
      name: 'individual_pedagogic_track',
      label: t(
        'sessions.extranet.visibilityOptions.trainees.individual_pedagogic_track'
      )
    },
    {
      name: 'program',
      label: t('sessions.extranet.visibilityOptions.trainees.program')
    },
    {
      name: 'agenda',
      label: t('sessions.extranet.visibilityOptions.trainees.agenda')
    },
    {
      name: 'internal_rules',
      label: t('sessions.extranet.visibilityOptions.trainees.internal_rules')
    },
    {
      name: 'online_signings_button',
      label: t(
        'sessions.extranet.visibilityOptions.trainees.online_signings_button'
      )
    }
  ];

  const ContributorsVisibiltyOptions = [
    {
      name: 'subcontracting_contract',
      label: t(
        'sessions.extranet.visibilityOptions.contributors.subcontracting_contract'
      )
    },
    {
      name: 'program',
      label: t('sessions.extranet.visibilityOptions.contributors.program')
    },
    {
      name: 'agenda',
      label: t('sessions.extranet.visibilityOptions.contributors.agenda')
    },
    {
      name: 'trainees_list',
      label: t('sessions.extranet.visibilityOptions.contributors.trainees_list')
    },
    {
      name: 'internal_rules',
      label: t(
        'sessions.extranet.visibilityOptions.contributors.internal_rules'
      )
    },
    {
      name: 'online_signings_button',
      label: t(
        'sessions.extranet.visibilityOptions.contributors.online_signings_button'
      )
    }
  ];

  const CompaniesVisibilityOptions = [
    {
      name: 'conventions',
      label: t('sessions.extranet.visibilityOptions.companies.conventions')
    },
    {
      name: 'program',
      label: t('sessions.extranet.visibilityOptions.companies.program')
    },
    {
      name: 'cgv',
      label: t('sessions.extranet.visibilityOptions.companies.cgv')
    },
    {
      name: 'quotations_invoices',
      label: t(
        'sessions.extranet.visibilityOptions.companies.quotations_invoices'
      )
    },
    {
      name: 'notes_comments',
      label: t('sessions.extranet.visibilityOptions.companies.notes_comments')
    },
    {
      name: 'agenda',
      label: t('sessions.extranet.visibilityOptions.companies.agenda')
    },
    {
      name: 'trainees_list',
      label: t('sessions.extranet.visibilityOptions.companies.trainees_list')
    },
    {
      name: 'internal_rules',
      label: t('sessions.extranet.visibilityOptions.companies.internal_rules')
    }
  ];

  return [
    TraineesVisibilityOptions,
    ContributorsVisibiltyOptions,
    CompaniesVisibilityOptions
  ];
};

export default VisibilityOptions;
