import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, message, Popconfirm, Skeleton } from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import useAuthContext from '../../../contexts/AuthContext';
import listContent from './listContent';
import ContentCustom from '../../../components/ContentCustom/ContentCustom';
import DescriptionList from '../../../components/DescriptionList/DescriptionList';
import PageHeaderCustom from '../../../components/PageHeader/PageHeader';
import { routes, subRoutes } from '../../../utils/constants/routes';
import ErrorStatusCode from '../../../utils/ErrorStatusCode';

const DetailsTrainingPlaces = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const history = useHistory();
  const { dispatchAPI } = useAuthContext();
  const [trainingPlaces, setTrainingPlaces] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { notification } = ErrorStatusCode();

  const fetchData = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/training-places/${id}`,
      });
      setTrainingPlaces(data);
    } catch (e) {
      if (e.response) notification(e.response);
    }
  }, [id]);

  const deleteTrainingPlace = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/training-places/${id}` });
      history.goBack();
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await fetchData();
      setIsLoading(false);
    })();
  }, [fetchData]);

  return (
    <div>
      <PageHeaderCustom
        title={trainingPlaces.name}
        extra={
          <>
            <Link
              to={{
                pathname: `${routes.DATAS}${subRoutes.DATAS.TRAINING_PLACES}/edit/${id}`,
              }}>
              <Button type="primary">
                {`${t('buttons.edit')} `}
                <EditOutlined />
              </Button>
            </Link>
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ danger: true }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={deleteTrainingPlace}
              icon={<WarningOutlined />}>
              <Button type="link" danger>
                {`${t('buttons.delete')} `}
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </>
        }
      />
      <ContentCustom>
        <Skeleton loading={isLoading}>
          <DescriptionList data={listContent(trainingPlaces)} translate />
        </Skeleton>
      </ContentCustom>
    </div>
  );
};

export default DetailsTrainingPlaces;
