import React from 'react';
import PropTypes from 'prop-types';
import { Collapse, Badge } from 'antd';
import { useTranslation } from 'react-i18next';
import { CaretRightOutlined } from '@ant-design/icons';
import DescriptionList from '../../../components/DescriptionList/DescriptionList';
import {
  listContentCompany,
  listContentContact,
  listContentPedagogic,
  listContentReglementation,
  listContentComptable,
  listContentComptableCloture,
  listContentComptableInvoice
} from '../listContentCompany';

const { Panel } = Collapse;

const ProfileCompanyCollapse = ({ user }) => {
  const { t } = useTranslation();

  return (
    <Collapse
      bordered={false}
      expandIcon={({ isActive }) => (
        <CaretRightOutlined rotate={isActive ? 90 : 0} />
      )}
      accordion
      ghost
      className="collapse-with-border"
    >
      <Panel
        header={t('profile.panel.company')}
        key="1"
        className="site-collapse-custom-panel"
      >
        <DescriptionList
          data={listContentCompany(user.organization, t)}
          translate
        />
      </Panel>
      <Panel
        header={t('profile.panel.contact')}
        key="2"
        className="site-collapse-custom-panel"
      >
        <DescriptionList
          data={listContentContact(user.organization, t)}
          translate
        />
      </Panel>
      <Panel
        header={t('profile.panel.team')}
        key="3"
        className="site-collapse-custom-panel"
      >
        <DescriptionList
          data={listContentPedagogic(user.organization, t)}
          translate
        />
      </Panel>
      <Panel
        header={t('profile.panel.regelmentation')}
        key="6"
        className="site-collapse-custom-panel"
      >
        <DescriptionList
          data={listContentReglementation(user.organization, t)}
          translate
        />
      </Panel>
      <Panel
        header={
          <Badge
            dot={user.bank_info}
            size="default"
            offset={[15, 0]}
            title={t('profile.messages.bank_details')}
          >
            {t('profile.panel.comptable')}
          </Badge>
        }
        key="7"
        className="site-collapse-custom-panel"
      >
        <DescriptionList
          data={listContentComptable(user.organization, t)}
          translate
        />
        <Collapse
          bordered={false}
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}
          accordion
          ghost
        >
          <Panel
            header={t('profile.panel.comptable-cloture')}
            key="1"
            className="site-collapse-custom-panel"
          >
            <DescriptionList
              data={listContentComptableCloture(user.organization, t)}
              translate
            />
          </Panel>
          <Panel
            header={
              <Badge
                dot={user.bank_info}
                size="default"
                offset={[10, 0]}
                title={t('profile.messages.bank_details')}
              >
                {t('profile.panel.comptable-identity')}
              </Badge>
            }
            key="2"
            className="site-collapse-custom-panel"
          >
            <DescriptionList
              data={listContentComptableInvoice(user.organization, t)}
              translate
            />
          </Panel>
        </Collapse>
      </Panel>
    </Collapse>
  );
};

ProfileCompanyCollapse.propTypes = {
  user: PropTypes.shape({
    organization: PropTypes.shape({}),
    bank_info: PropTypes.bool
  }).isRequired
};

export default ProfileCompanyCollapse;
