import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import ListTAndAs from './ListTAndAs';
import CreateUpdateTAndA from './CreateUpdateTAndA';
import ShowTAndA from './ShowTAndA';

const TAndARouter = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route
        path={`${match.path}/create`}
        render={() => <CreateUpdateTAndA purpose="create" />}
      />
      <Route
        path={`${match.path}/edit/:id`}
        render={() => <CreateUpdateTAndA purpose="edit" />}
      />
      <Route path={`${match.path}/show/:id`} component={ShowTAndA} />
      <Route path={match.path} component={ListTAndAs} />
    </Switch>
  );
};

export default TAndARouter;
