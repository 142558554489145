import React, { useEffect, useState } from 'react';
import { Col } from 'antd';
import PropTypes from 'prop-types';
import { useLocation, useHistory } from 'react-router-dom';
import { Columns } from '../../Accounting/Account/Columns';
import Datatable from '../../../components/DataTable/Datatable';
import { SearchBar } from '../../../utils/constants/customButton';

const CrewTable = ({ user }) => {
  const history = useHistory();
  const columns = Columns();
  const { pathname } = useLocation();
  const { location } = history;
  const params = new URLSearchParams(location.search);
  const [searchValue, setSearchValue] = useState(params.get('k'));

  const searchResource = (value) => {
    if (value) {
      history.push({
        pathname,
        search: `?k=${value}`
      });
    } else {
      history.push({
        pathname
      });
    }
  };

  useEffect(() => {
    setSearchValue(params.get('k'));
  }, [params]);

  return (
    <>
      <Col>
        <SearchBar defaultValue={searchValue} setSearchValue={searchResource} />
      </Col>
      <Datatable
        columns={columns}
        resourceName="users"
        extraQuery={`organization=${user?.organization}&_id!=${user?._id}`}
        path="users"
        searchValue={searchValue}
        customActionColumn
      />
    </>
  );
};

export default CrewTable;

CrewTable.propTypes = {
  user: PropTypes.shape({
    role: PropTypes.string,
    organization: PropTypes.shape({}),
    _id: PropTypes.string
  }).isRequired
};
