import moment from 'moment';

const listContent = ({ start_date, end_date, duration, user }) => [
  {
    label: 'times-and-attendances.column.employee',
    content: user && `${user.first_name} ${user.last_name}`,
    span: 3,
  },
  {
    label: 'times-and-attendances.column.duration',
    content:
      duration &&
      `${moment.duration(duration).hours()}h${
        moment.duration(duration).minutes()
          ? moment.duration(duration).minutes()
          : ''
      }`,
    span: 3,
  },
  {
    label: 'times-and-attendances.column.start_time',
    content: start_date && moment(start_date).format('HH:mm'),
    span: 1,
  },
  {
    label: 'times-and-attendances.column.end_time',
    content: end_date && moment(end_date).format('HH:mm'),
    span: 2,
  },
];

export default listContent;
