export const userRoles = {
  GUEST: 'green',
  PRESCRIBER: '',
  USER: 'blue',
  'SUPER-USER': 'geekblue',
  ADMIN: 'red',
  'SUPER-ADMIN': 'magenta'
};

export const invoiceTypes = {
  PAY: 'blue',
  DEPOSIT: 'green',
  CREDIT: 'magenta'
};

export const ticketsPriorities = ['NORMAL', 'HIGH', 'URGENT'];

export const ticketsPrioritiesColor = {
  LOW: 'blue',
  NORMAL: 'green',
  HIGH: 'orange',
  URGENT: 'red'
};

export const ticketsTypes = ['PICKUP', 'THEFT', 'LOSS', 'DAMAGED'];

export const ticketsTypesColor = {
  PICKUP: 'green',
  THEFT: 'volcano',
  LOSS: 'cyan',
  DAMAGED: 'red'
};

export const tasksStatuses = {
  OPEN: 'blue',
  PENDING: 'green',
  'IN PROGRESS': 'orange',
  CLOSED: 'red'
};

export const tasksPriorities = {
  URGENT: 'red',
  HIGH: 'yellow',
  NORMAL: 'cyan',
  LOW: 'grey'
};

export const dealsPriorities = {
  URGENT: 'red',
  HIGH: 'yellow',
  NORMAL: 'cyan',
  LOW: 'grey'
};

export const projectsStatuses = {
  OPEN: 'blue',
  PENDING: 'green',
  'IN PROGRESS': 'orange',
  AUDITED: 'cyan',
  CLOSED: 'red',
  CANCELED: 'red'
};

export const companiesTypes = {
  CUSTOMERS: 'blue',
  SUPPLIERS: 'green'
};
export const movementsTags = {
  EXPERTISE: 'cyan',
  DEMONSTRATION: 'gold',
  INTERVENTION: 'blue',
  'LEVER DE RESERVE': 'purple',
  SAV: 'red',
  'ENTRETIEN CAMION': 'orange'
};
