import React from 'react';
import { Col } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import { RiseOutlined, FallOutlined } from '@ant-design/icons';

const IndicatorCA = ({ totalYear, totalLastYear, month }) => {
  return (
    <Col style={{ display: 'flex', flexDirection: 'column' }}>
      {month ? (
        <p style={{ fontWeight: 500, marginBottom: 4 }}>
          {`${month[moment().month()]}  ${moment().year()}`}
        </p>
      ) : (
        <p style={{ fontWeight: 500, marginBottom: 4 }}>Cumulé</p>
      )}
      <div
        style={{
          marginRight: 24,
          display: 'flex',
          background: '#f5f5f5',
          padding: '8px 16px 0',
          borderRadius: 4,
          minHeight: 114
        }}
      >
        <div
          style={{
            marginRight: 24
          }}
        >
          {totalYear > totalLastYear ? (
            <>
              <RiseOutlined
                style={{
                  color: 'var(--addColor)',
                  fontSize: '5em',
                  position: 'relative',
                  top: 16
                }}
              />
              <p
                style={{
                  color: 'var(--addColor)',
                  fontWeight: 500,
                  width: 70,
                  position: 'relative',
                  left: 95
                }}
              >
                {totalLastYear === 0
                  ? '+ 100 %'
                  : `+ ${(
                      ((totalYear - totalLastYear) / Math.abs(totalLastYear)) *
                      100
                    ).toFixed(2)} %`}
              </p>
            </>
          ) : (
            <>
              {totalYear < totalLastYear ? (
                <>
                  <FallOutlined
                    style={{
                      color: 'var(--errorColor)',
                      fontSize: '5em',
                      position: 'relative',
                      top: 16
                    }}
                  />
                  <p
                    style={{
                      color: 'var(--errorColor)',
                      fontWeight: 500,
                      width: 70,
                      position: 'relative',
                      left: 95
                    }}
                  >
                    {totalYear <= 0 || totalLastYear < 0
                      ? `- 100 %`
                      : `${(
                          ((totalYear - totalLastYear) /
                            Math.abs(totalLastYear)) *
                          100
                        ).toFixed(2)} %`}
                  </p>
                </>
              ) : (
                <>
                  <p
                    style={{
                      color: 'gray',
                      fontWeight: 500,
                      fontSize: 24
                    }}
                  >
                    0 %
                  </p>
                </>
              )}
            </>
          )}
        </div>
        <div>
          <h2 style={{ fontWeight: 600, marginBottom: '.4em' }}>
            {`${totalYear?.toFixed(2) || 0} €`}
          </h2>
          {`N-1: ${totalLastYear?.toFixed(2) || 0} €`}
        </div>
      </div>
    </Col>
  );
};

IndicatorCA.propTypes = {
  totalYear: PropTypes.number.isRequired,
  totalLastYear: PropTypes.number.isRequired,
  month: PropTypes.arrayOf({}).isRequired
};

export default IndicatorCA;
