import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Chart,
  Geom,
  Axis,
  Tooltip,
  Coord,
  Label,
  Legend,
  Guide
} from 'bizcharts';
import { Typography, Row } from 'antd';
import DataSet from '@antv/data-set';
import moment from 'moment';
import numeral from 'numeral';

const { DataView } = DataSet;
const { Text } = Typography;

const CommissionsSubscriptionsAmountIndicator = ({ commissions }) => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const subscriptions = ['AISANCE', 'CONFORT', 'EUPHORIE', 'INFINI'];

  useEffect(() => {
    const temp = [];
    let amount = 0;
    if (commissions.length) {
      subscriptions.forEach((subscription) => {
        let value = 0;
        const listCommissions = commissions.filter(
          (commission) =>
            commission.sponsored._id.organization.subscription.type ===
              subscription &&
            moment(commission.date).format('MM-YYYY') ===
              moment().format('MM-YYYY') &&
            commission.status === 'PAID'
        );
        if (listCommissions.length) {
          listCommissions.forEach((comm) => {
            value += comm.amount;
            amount += comm.amount;
          });
        }
        temp.push({
          type: subscription,
          value
        });
      });
      setTotal(amount);
      setData(temp);
    }
  }, [commissions]);

  const dv = new DataView();
  dv.source(data).transform({
    type: 'percent',
    field: 'value',
    dimension: 'type',
    as: 'percent'
  });

  const cols = {
    percent: {
      formatter: (val) => {
        val = `${val * 100}%`;
        return val;
      }
    }
  };
  return (
    <>
      {total !== 0 ? (
        <Chart data={dv} scale={cols} padding={40} forceFit>
          <Coord type="theta" radius={0.75} innerRadius={0.6} />
          <Axis name="percent" />
          <Legend position="bottom" />
          <Tooltip
            showTitle={false}
            itemTpl='<li><span style="background-color:{color};" class="g2-tooltip-marker"></span>{name}: {value}</li>'
          />
          <Guide>
            <Row justify="center">
              <Text strong>
                {t('prescriber.indicators.month', {
                  month: moment().format('MMMM')
                })}
              </Text>
            </Row>
            <Guide.Text
              position={['42%', '50%']}
              content={`${numeral(total).format('0[.]00 $')}`}
              style={{
                fill: '#666',
                fontSize: '24'
              }}
            />
          </Guide>
          <Geom
            type="intervalStack"
            position="value"
            color="type"
            tooltip={[
              'type*value',
              (type, value) => {
                value = `${value.toFixed(2)} €`;
                return {
                  name: type,
                  value
                };
              }
            ]}
            style={{
              lineWidth: 1,
              stroke: '#fff'
            }}
          >
            <Label
              content="percent"
              formatter={(val, item) => {
                return `${item.point.type}: ${Number(
                  val.replace('%', '')
                ).toFixed(2)} %`;
              }}
            />
          </Geom>
        </Chart>
      ) : (
        <span>{t('prescriber.indicators.no_subscription')}</span>
      )}
    </>
  );
};

CommissionsSubscriptionsAmountIndicator.propTypes = {
  commissions: PropTypes.arrayOf({}).isRequired
};

export default CommissionsSubscriptionsAmountIndicator;
