import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { message } from 'antd';
import useAuthContext from './AuthContext';
import ErrorStatusCode from '../utils/ErrorStatusCode';

const AgendaContext = createContext({});

export const AgendaContextProvider = ({ children }) => {
  const [session, setSession] = useState('sessions');
  const [modules, setModules] = useState([]);
  const [kanbanCols, setKanbanCols] = useState([]);
  const [sessions, setSessions] = useState([]);
  const [reloadCalendar, setReloadCalendar] = useState(false);
  const { notification } = ErrorStatusCode();
  const { dispatchAPI } = useAuthContext();
  const [notificationsList, setNotificationsList] = useState([]);
  const [finalModules, setFinalModules] = useState([]);
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [filtredModules, setFiltredModules] = useState([]);
  const { t } = useTranslation();
  const [sessionsWithoutModule, setSessionsWithoutModule] = useState([]);

  const fetchKanbanColumns = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/kanbans?type=sessions'
      });
      setKanbanCols(data);
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  const fetchSessions = useCallback(async () => {
    try {
      const fieldsArray = [
        '_id',
        'name',
        'customers.name',
        'customers.first_name',
        'customers.last_name',
        'status.color'
      ];
      const fieldsString = fieldsArray.join(',');
      const { data } = await dispatchAPI('GET', {
        url: `/sessions/?customer=${JSON.stringify([
          { path: 'status' },
          { path: 'contributors', populate: { path: 'contributor' } },
          {
            path: 'customers',
            populate: [{ path: 'customer' }, { path: 'trainees_list' }]
          }
        ])}&fields=${fieldsString}`
      });
      setSessions(data);
    } catch (e) {
      if (e.response) notification(e.response);
    }
  }, []);

  const fetchModules = useCallback(async () => {
    try {
      const fieldsArray = [
        'slots.date._id',
        'slots.date.date',
        'slots.date.sub_slots._id',
        'slots.date.sub_slots.type',
        'slots.date.sub_slots.start_time',
        'slots.date.sub_slots.end_time',
        'name',
        'session._id',
        'session.name'
      ];
      const fieldsString = fieldsArray.join(',');
      const { data } = await dispatchAPI('GET', {
        url: `/module?populate=session&fields=${fieldsString}`
      });
      setModules(data);
    } catch (e) {
      if (e.response) notification(e.response);
    }
  }, []);

  useEffect(() => {
    (async () => {
      try {
        await setIsLoading(true);
        await Promise.all([
          fetchModules(),
          fetchSessions(),
          fetchKanbanColumns()
        ]);
        await setIsLoading(false);
      } catch (e) {
        if (e.response) notification(e.response);
      } finally {
        await setIsLoading(false);
      }
    })();
  }, [reloadCalendar]);

  const updateSession = async (id, body) => {
    try {
      await dispatchAPI('PATCH', { url: `/sessions/${id}`, body });
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  const updateModule = async (body) => {
    try {
      await dispatchAPI('PATCH', { url: `/module/${body._id}`, body });
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
  };

  const deleteSubSlot = async (idModule, idSlot, idSubSlot) => {
    let module = {};
    const subSlotList = modules
      .filter((el) => el._id === idModule)[0]
      .slots.date.find((date) => date._id.toString() === idSlot.toString())
      .sub_slots.filter(
        (subSlot) => subSlot._id.toString() !== idSubSlot.toString()
      );

    module = modules.filter((el) => el._id === idModule)[0];

    if (subSlotList.length) {
      module.slots.date.forEach((date, indexDate) => {
        if (date._id.toString() === idSlot) {
          module.slots.date[indexDate].sub_slots = subSlotList;
        }
      });
    } else {
      const dateList = module.slots.date.filter(
        (date) => date._id.toString() !== idSlot.toString()
      );
      module.slots.date = dateList;
    }

    await updateModule(module);
    await fetchModules();
  };

  useEffect(() => {
    if (filtredModules.length > 0 && sessions.length > 0) {
      const list = [];
      filtredModules.forEach((module) => {
        if (module.slots) {
          module.slots.date.forEach((item) => {
            item.sub_slots.forEach((subSlot) => {
              list.push({
                id: module._id,
                idSlot: item._id,
                idSubSlot: subSlot._id,
                idSession: module.session?._id,
                module: module.name,
                date: item.date,
                type: subSlot.type,
                session: module.session?.name,
                customers: [],
                start_time: subSlot.start_time,
                end_time: subSlot.end_time,
                color: ''
              });
            });
          });
        }
      });
      sessions.forEach((ssn) => {
        filtredModules.forEach((module) => {
          if (module.session?._id.toString() === ssn._id.toString()) {
            list.forEach((item, index) => {
              if (module._id === item.id) {
                list[index].customers = ssn.customers;
                list[index].color = ssn.status.color;
              }
            });
          }
        });
      });
      setFinalModules(list);
    }
  }, [filtredModules, sessions]);

  const checkIfExist = (ssn) => {
    let check = false;
    modules.forEach((module) => {
      if (module && module.session?._id.toString() === ssn?._id) {
        check = true;
      }
    });
    return check;
  };

  useEffect(() => {
    if (session === 'sessions') {
      setFiltredModules(modules.filter((el) => el.session));
    } else {
      setFiltredModules(modules.filter((mod) => mod.session?._id === session));
    }
  }, [modules, session]);

  useEffect(() => {
    if (sessionsWithoutModule) {
      sessionsWithoutModule.forEach((ssn) => {
        if (checkIfExist(ssn)) {
          (async () => {
            await updateSession(ssn._id, {
              status: kanbanCols.filter((el) => el.label === 'A VENIR')[0]._id
            });
          })();
        }
        setSessionsWithoutModule(
          kanbanCols &&
            sessions.filter(
              (ssnn) =>
                ssnn.status._id ===
                kanbanCols.filter((el) => el.label === 'A PLANIFIER')[0]?._id
            )
        );
      });
    }
  }, [reloadCalendar]);

  useEffect(() => {
    if (sessions.length > 0 && kanbanCols.length > 0) {
      setSessionsWithoutModule(
        kanbanCols &&
          sessions.filter(
            (ssnn) =>
              ssnn.status._id ===
              kanbanCols.filter((el) => el.label === 'A PLANIFIER')[0]?._id
          )
      );
    }
  }, [sessions, kanbanCols]);

  useEffect(() => {
    const list = [];
    if (sessionsWithoutModule.length) {
      list.push({
        title: `${t('agenda.notification.you_have')} ${
          sessionsWithoutModule.length
        } ${t('agenda.notification.unplanned_sessions')}`,
        description: t('agenda.notification.description')
      });
    }
    setNotificationsList(list);
  }, [sessionsWithoutModule]);

  return (
    <AgendaContext.Provider
      value={{
        notificationsList,
        setSession,
        session,
        sessions,
        sessionsWithoutModule,
        modules,
        deleteSubSlot,
        updateModule,
        fetchModules,
        finalModules,
        kanbanCols,
        updateSession,
        reloadCalendar,
        setReloadCalendar,
        start,
        setStart,
        end,
        setEnd,
        isLoading,
        setIsLoading,
        editMode,
        setEditMode
      }}
    >
      {children}
    </AgendaContext.Provider>
  );
};

export const useAgendaContext = () => {
  return useContext(AgendaContext);
};
