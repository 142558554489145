import React from 'react';
import {
  BellOutlined,
  HourglassOutlined,
  QuestionOutlined,
  CheckOutlined,
  LoginOutlined,
  CloseOutlined,
  DiffOutlined,
  UnorderedListOutlined,
  HeatMapOutlined,
  WechatOutlined,
  AlertOutlined,
  BulbOutlined,
} from '@ant-design/icons';

export const kanbanIcon = [
  {
    label: 'bell',
    value: <BellOutlined />,
  },
  {
    label: 'check',
    value: <CheckOutlined />,
  },
  {
    label: 'plus',
    value: <HourglassOutlined />,
  },
  {
    label: 'question',
    value: <QuestionOutlined />,
  },
  {
    label: 'login',
    value: <LoginOutlined />,
  },
  {
    label: 'close',
    value: <CloseOutlined />,
  },
  {
    label: 'diff',
    value: <DiffOutlined />,
  },
  {
    label: 'list',
    value: <UnorderedListOutlined />,
  },
  {
    label: 'heatmap',
    value: <HeatMapOutlined />,
  },
  {
    label: 'wechat',
    value: <WechatOutlined />,
  },
  {
    label: 'alert',
    value: <AlertOutlined />,
  },
  {
    label: 'bulb',
    value: <BulbOutlined />,
  },
];

export const kanbanColor = [
  '#fffff',
  '#e6f7ff',
  '#bae7ff',
  '#91d5ff',
  '#69c0ff',
  '#40a9ff',
  '#1890ff',
  '#096dd9',
  '#0050b3',
  '#003a8c',
  '#002766',
];
