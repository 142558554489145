import React, { useEffect, useState } from 'react';
import { DatePicker, Input, message, Radio, Select } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../../contexts/AuthContext';
import ErrorStatusCode from '../../../utils/ErrorStatusCode';

const { Option } = Select;

const useField = () => {
  const [status, setStatus] = useState('OPEN');
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(false);
  const [enums, setEnums] = useState({});
  const { notification } = ErrorStatusCode();

  const getEnums = async () => {
    setIsFieldsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/quality_incidents/enums`,
      });
      setEnums(data);
    } catch (e) {
      if (e.response) notification(e.response);
    }
    setIsFieldsLoading(false);
  };

  useEffect(() => {
    (async () => {
      await getEnums();
    })();
  }, []);

  const fields = [
    {
      label: 'name',
      name: ['name'],
      rules: [{ required: true }],
      input: <Input />,
    },
    {
      label: 'description',
      name: ['description'],
      rules: [{ required: false }],
      input: <TextArea />,
    },
    {
      label: 'status',
      name: ['status'],
      initialValue: 'OPEN',
      rules: [{ required: true }],
      input: (
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          onSelect={value => setStatus(value)}
          loading={isFieldsLoading}>
          {(enums.status || []).map(title => (
            <Option key={title} value={title}>
              {t(`quality_incidents.form.${title}`)}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      label: 'incident_date',
      name: ['incident_date'],
      rules: [{ required: true }],
      input: <DatePicker style={{ width: 350 }} format="DD-MM-YYYY" />,
    },
    {
      label: 'incident_source',
      name: ['incident_source'],
      rules: [{ required: true }],
      input: (
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          loading={isFieldsLoading}>
          {(enums.sources || []).map(title => (
            <Option key={title} value={title}>
              {t(`quality_incidents.form.${title}`)}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      label: 'incident_subject',
      name: ['incident_subject'],
      rules: [{ required: true }],
      input: (
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          loading={isFieldsLoading}>
          {(enums.subject || []).map(title => (
            <Option key={title} value={title}>
              {t(`quality_incidents.form.${title}`)}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      label: 'incident_gravity',
      name: ['incident_gravity'],
      rules: [{ required: true }],
      input: (
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          loading={isFieldsLoading}>
          {(enums.gravity || []).map(title => (
            <Option key={title} value={title}>
              {t(`quality_incidents.form.${title}`)}
            </Option>
          ))}
        </Select>
      ),
    },
    ...(status === 'CLOSED'
      ? [
          {
            label: 'incident_resolution',
            name: ['incident_resolution'],
            rules: [{ required: true }],
            input: <TextArea />,
          },
          {
            label: 'incident_resolution_date',
            name: ['incident_resolution_date'],
            rules: [{ required: true }],
            input: <DatePicker style={{ width: 350 }} format="DD-MM-YYYY" />,
          },
        ]
      : []),
  ];

  return {
    fields,
    isFieldsLoading,
  };
};

export default useField;
