import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { routes, subRoutes } from '../../../utils/constants/routes';

const useListContent = ({
  first_name,
  last_name,
  status,
  email,
  phone_number,
  birth_name,
  number,
  street,
  additional,
  postcode,
  city,
  country,
  company,
  profession,
  ...trainee
}) => {
  const { t } = useTranslation();

  return [
    {
      label: 'trainees.form.full_name',
      content: `${last_name} ${first_name}`
    },
    {
      label: 'trainees.form.email',
      content: email
    },
    {
      label: 'trainees.form.company',
      content: company && (
        <Link
          to={{
            pathname: `${routes.DATAS}${subRoutes.DATAS.COMPANIES}/show/${company._id}`
          }}
        >
          {company.name}
        </Link>
      )
    },
    { label: 'trainees.form.study_level', content: trainee.study_level },

    {
      label: 'trainees.form.address',
      content: `${number || ''} ${street || ''} ${additional || ''}` || '-'
    },
    {
      label: 'trainees.form.postcode',
      content: postcode
    },
    {
      label: 'trainees.form.city',
      content: city
    },

    {
      label: 'trainees.form.status',
      content: status ? t(`trainees.form.${status}`) : '-'
    },
    {
      label: 'trainees.form.phone_number',
      content: phone_number
    },

    {
      label: 'trainees.form.function',
      content: trainee.function ? `${trainee.function}` : '-'
    },
    { label: 'trainees.form.study_name', content: trainee.study_name }
  ];
};

export default useListContent;
