export const getCreditNotes = async ({
  dispatchAPI,
  setCreditNotes,
  message,
  t
}) => {
  try {
    const { data } = await dispatchAPI('GET', {
      url: '/credit-notes?populate=invoice'
    });
    setCreditNotes(data);
  } catch (e) {
    message.error(t('trainees.message.error'));
  }
};
