import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Button } from 'antd'; // or the correct library you're using
import styled from 'styled-components';
import { useAgendaContext } from '../../../contexts/AgendaContext';
import SideDrawer from './SideDrawer';
import CellRenderWithPopOver from './CellRender';

const Cell = styled.div`
  list-style: none;
  width: 100%;
  height: 100%;
`;

const DateCellRender = ({ value }) => {
  const { finalModules, setStart, setEnd, editMode } = useAgendaContext();
  const [visible, setVisible] = useState({});
  const [popoverVisible, setPopoverVisible] = useState(false);

  const handleVisibleChange = (isVisible) => {
    setPopoverVisible(isVisible);
  };

  const onClose = () => {
    setVisible(!visible);
  };

  const finalReturn = finalModules
    ? finalModules.filter(
        (module) =>
          value.format('YYYY-MM-DD') ===
          moment(module.date).format('YYYY-MM-DD')
      )
    : [];

  return (
    <>
      <Cell
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onMouseDown={() => {
          if (!editMode) return;
          setStart(value);
        }}
        onMouseUp={() => {
          if (!editMode) return;
          setEnd(value);
        }}
      >
        {finalReturn.length <= 1 ? (
          finalReturn.map((module) => {
            return (
              <CellRenderWithPopOver
                handleVisibleChange={handleVisibleChange}
                value={value}
                condition={!editMode}
                popoverVisible={popoverVisible}
                module={module}
              />
            );
          })
        ) : (
          <>
            {finalReturn.slice(0, 1).map((module) => {
              return (
                <CellRenderWithPopOver
                  handleVisibleChange={handleVisibleChange}
                  value={value}
                  condition={!editMode}
                  popoverVisible={popoverVisible}
                  module={module}
                />
              );
            })}
            <Button
              onClick={() => {
                setVisible({ [moment(value).format('YYYY-MM-DD')]: true });
              }}
              type="link"
              className="agenda-modules-btn"
            >
              {`${finalReturn.length} créneaux`}
            </Button>
            <SideDrawer
              visible={visible}
              value={value}
              onClose={onClose}
              finalReturn={finalReturn}
              handleVisibleChange={handleVisibleChange}
            />
          </>
        )}
      </Cell>
    </>
  );
};

DateCellRender.propTypes = {
  value: PropTypes.shape({
    format: PropTypes.func
  })
};

DateCellRender.defaultProps = {
  value: {
    format: () => {}
  }
};

export default DateCellRender;
