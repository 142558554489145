import React from 'react';
import { Row, List, Tree, Modal, Col } from 'antd';
import {
  listCurrentsFields,
  listGeneralFields,
} from '../../utils/constants/sessionsTrainningFields';

const ModalTrainingFields = ({
  setTrainingFieldValue,
  isModalVisible,
  setIsModalVisible,
}) => {
  const onSelect = (selectedKeys, info) => {
    setTrainingFieldValue(info.node.title.props.children);
    setIsModalVisible(!isModalVisible);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleClick = value => {
    setTrainingFieldValue(value);
    setIsModalVisible(!isModalVisible);
  };
  return (
    <Modal
      footer={null}
      closable
      visible={isModalVisible}
      onCancel={handleCancel}
      width="100%">
      <Row>
        <Col span={12}>
          <List
            size="small"
            header={<h2>Domaines courants</h2>}
            dataSource={listCurrentsFields}
            renderItem={item => (
              <List.Item onClick={() => handleClick(item)} key={item}>
                <a href="#">
                  <span>{item}</span>
                </a>
              </List.Item>
            )}
          />
        </Col>
        <Col span={12}>
          <h2>Tous les domaines</h2>
          <Tree
            defaultExpandAll
            onSelect={onSelect}
            treeData={listGeneralFields}
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default ModalTrainingFields;
