import React from 'react';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import { EditableCell } from './EditableCell';
import useColumns from './columns';

const ItemTable = ({
  setSelectedRowKeys,
  selectedRowKeys,
  moduleData,
  isEditing,
  save,
  cancel,
  edit,
  editingKey
}) => {
  const { columns } = useColumns({
    isEditing,
    save,
    cancel,
    edit,
    editingKey
  });

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === 'age' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record)
      })
    };
  });

  const onSelectChange = (keys) => {
    setSelectedRowKeys(keys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (keys, items) => onSelectChange(keys, items)
  };

  return (
    <Table
      components={{
        body: {
          cell: EditableCell
        }
      }}
      dataSource={moduleData}
      columns={mergedColumns}
      rowSelection={rowSelection}
    />
  );
};

export default ItemTable;

ItemTable.propTypes = {
  setSelectedRowKeys: PropTypes.func.isRequired,
  selectedRowKeys: PropTypes.arrayOf(PropTypes.string),
  moduleData: PropTypes.arrayOf(PropTypes.shape({})),
  isEditing: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  edit: PropTypes.func.isRequired,
  editingKey: PropTypes.string
};

ItemTable.defaultProps = {
  moduleData: [],
  selectedRowKeys: [],
  editingKey: ''
};
