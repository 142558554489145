import React, { useCallback, useEffect, useState } from 'react';
import { DatePicker, Input, Select, message, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import useAuthContext from '../../../../contexts/AuthContext';
import { projectsStatuses } from '../../../../utils/constants/tagColors';
import ErrorStatusCode from '../../../../utils/ErrorStatusCode';

const { Option } = Select;

const useFields = () => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [enums, setEnums] = useState({});
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const { notification } = ErrorStatusCode();
  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/payments/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getEnums();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  const fields = [
    {
      label: 'date',
      name: ['date'],
      initialValue: moment(new Date()),
      rules: [{ required: true }],
      input: <DatePicker style={{ width: 350 }} format="DD-MM-YYYY" />,
    },
    {
      name: ['amount'],
      rules: [{ required: true }],
      input: <Input type="number" suffix="€" />,
    },
    {
      name: ['payment_type'],
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading}>
          {(enums.payment_type || []).map(item => (
            <Option key={item} value={item}>
              {t(`payments.tags.${item}`)}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      name: ['comments'],
    },
  ];

  return {
    fields,
  };
};

export default useFields;
