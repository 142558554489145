import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import GlobalPlanning from './GlobalPlanning';
import { AgendaContextProvider } from '../../contexts/AgendaContext';

const AgendaRouter = () => {
  const { path } = useRouteMatch();
  return (
    <AgendaContextProvider>
      <Switch>
        <Route path={`${path}`} component={GlobalPlanning} />
      </Switch>
    </AgendaContextProvider>
  );
};

export default AgendaRouter;
