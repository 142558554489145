import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { Divider, Popconfirm } from 'antd';
import useAuthContext from '../../../contexts/AuthContext';
import ErrorStatusCode from '../../../utils/ErrorStatusCode';

const ColumnsViews = () => {
  const { t } = useTranslation();
  return [
    {
      title: t('accounts.form.wording'),
      key: 'wording',
      dataIndex: 'wording',
      sorter: true
    },
    {
      title: t('accounts.form.num'),
      key: 'num',
      dataIndex: 'num',
      sorter: true
    }
  ];
};

const Columns = () => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { notification } = ErrorStatusCode();
  const [refresh, setRefresh] = useState(false);

  const deleteResource = async (id) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/users/${id}`,
        body: { isArchived: true }
      });
      setRefresh(!refresh);
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  return [
    {
      title: t('users.form.last_name'),
      key: 'last_name',
      dataIndex: 'last_name',
      render: null,
      sorter: true
    },
    {
      title: t('users.form.first_name'),
      key: 'first_name',
      dataIndex: 'first_name',
      sorter: true
    },

    {
      title: t('users.form.email'),
      key: 'email',
      dataIndex: 'email',
      render: null,
      sorter: true
    },
    {
      key: 'action',
      render: (record) => (
        <div style={{ float: 'right' }}>
          <Link
            to={{
              pathname: record.key
            }}
          >
            <EyeOutlined style={{ fontSize: 18 }} />
          </Link>
          <Divider type="vertical" />
          <Link
            to={{
              pathname: `/admin/access-account/edit/${record.key}`
            }}
          >
            <EditOutlined style={{ fontSize: 18 }} />
          </Link>
          <Divider type="vertical" />
          <Popconfirm
            title={t('datatable.column.action.delete.title')}
            okText={t('datatable.column.action.delete.ok')}
            okButtonProps={{ type: 'danger' }}
            cancelText={t('datatable.column.action.delete.cancel')}
            onConfirm={() => deleteResource(record.key)}
            icon={<WarningOutlined />}
          >
            <DeleteOutlined
              style={{ color: 'red', fontSize: 18 }}
              type="delete"
            />
          </Popconfirm>
        </div>
      )
    }
  ];
};

export { ColumnsViews, Columns };
