import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { PageHeader, Space, Popconfirm } from 'antd';
import { routes } from '../../utils/constants/routes';

const PageHeaderCustom = ({ title, extra, withSubRoutes }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { pathname } = location;
  const splitPathname = pathname.split('/');
  const history = useHistory();
  const pathnameSplited = pathname.split('/');
  const [visible, setVisible] = useState(false);
  const [leaveConfirm, setleaveConfirm] = useState(true);

  const handleClick = () => {
    setVisible(true);
  };

  const isThisPageIsForm = () => {
    for (let i = 0; i < pathnameSplited.length; i += 1) {
      if (pathnameSplited[i] === 'edit' || pathnameSplited[i] === 'create') {
        return true;
      }
    }
    return false;
  };

  const isForm = isThisPageIsForm();

  useEffect(() => {
    if (isForm) setleaveConfirm(false);
  });

  const confirm = () => {
    setleaveConfirm(true);
    setVisible(false);
    history.goBack();
  };

  const dynamicRoutes = [
    {
      path: routes.HOME,
      breadcrumbName: t('home.title'),
    },
    ...(splitPathname[1] && splitPathname[1] !== ''
      ? [
          {
            path: `/${splitPathname[1]}`,
            breadcrumbName: withSubRoutes
              ? t(`basiclayout.menu.${splitPathname[1]}`)
              : t(`${splitPathname[1]}.title`),
          },
        ]
      : []),
    ...(splitPathname[2] && splitPathname[2] !== ''
      ? [
          {
            ...(withSubRoutes
              ? {
                  path: `/${splitPathname[1]}/${splitPathname[2]}`,
                }
              : {}),
            breadcrumbName: withSubRoutes
              ? t(`${splitPathname[2]}.title`)
              : t(`breadcrumbs.${splitPathname[2]}`),
          },
        ]
      : []),
    ...(withSubRoutes && splitPathname[3] && splitPathname[3] !== ''
      ? [
          {
            breadcrumbName: t(`breadcrumbs.${splitPathname[3]}`),
          },
        ]
      : []),
  ];

  const itemRender = (
    // eslint-disable-next-line react/prop-types
    { path, breadcrumbName, state, search, ...route },
    params,
    routesList
  ) => {
    const last = routesList.indexOf(route) === routesList.length - 1;
    return last ? (
      <span key={path}>{breadcrumbName}</span>
    ) : (
      <Link key={path} to={{ pathname: path, state, search }}>
        {breadcrumbName}
      </Link>
    );
  };

  return leaveConfirm ? (
    <>
      {dynamicRoutes.length > 2 ? (
        <PageHeader
          breadcrumb={{ routes: dynamicRoutes, itemRender }}
          title={title}
          onBack={() => history.goBack()}
          ghost={false}
          extra={extra}
        />
      ) : (
        <PageHeader
          breadcrumb={{ routes: dynamicRoutes, itemRender }}
          title={title}
          ghost={false}
          extra={extra}
        />
      )}
    </>
  ) : (
    <>
      <Space onClick={handleClick}>
        <PageHeader
          breadcrumb={{ routes: dynamicRoutes, itemRender }}
          title={title}
          ghost={false}
          extra={extra}
        />
      </Space>
      <Popconfirm
        title="Les informations renseigné ne seront pas sauvegardé.
        Êtes vous sur de vouloir quitter? "
        visible={visible}
        onConfirm={confirm}
        onCancel={() => setVisible(!visible)}
        okText="Oui"
        cancelText="Non"
      />
    </>
  );
};

PageHeaderCustom.propTypes = {
  title: PropTypes.string.isRequired,
  extra: PropTypes.element,
  withSubRoutes: PropTypes.bool,
};

PageHeaderCustom.defaultProps = {
  extra: null,
  withSubRoutes: false,
};

export default PageHeaderCustom;
