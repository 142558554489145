import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';
import CreateUpdateContainer from '../../../components/CreateUpdateContainer/CreateUpdateContainer';
import useFields from './Fields';
import ErrorStatusCode from '../../../utils/ErrorStatusCode';

const SignDocument = () => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { notification } = ErrorStatusCode();
  const { fields } = useFields();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const sendSignRequest = async (fileId, contactId) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/sign/${fileId}/${contactId}`,
      });

      return data;
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  const handleSubmit = async ({ file, contact }) => {
    setIsLoading(true);
    await sendSignRequest(file, contact);
    setIsLoading(false);
    history.goBack();
  };

  return (
    <CreateUpdateContainer
      fields={fields}
      purpose="edit"
      baseUrl="files"
      resource="files"
      submitLabel="files.form.submit"
      customSubmit={handleSubmit}
      isParentLoading={isLoading}
    />
  );
};

export default SignDocument;
