export const setFiles = ({
  globalEmail,
  setFiltredFiles,
  setSelectedFiles,
  file,
  setUniqueFiles,
  files,
  isSelectedDocument
}) => {
  if (!globalEmail) {
    setSelectedFiles([...file]);
  }
  if (globalEmail && isSelectedDocument) {
    if (files) {
      setFiltredFiles(setUniqueFiles({ files }));
    }
  }
  if (globalEmail && !isSelectedDocument) {
    if (files) {
      setSelectedFiles([...files]);
    }
  }
};
