import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Collapse, Row, Divider, Popconfirm, Table } from 'antd';
import {
  DeleteOutlined,
  DownloadOutlined,
  HighlightOutlined,
  LoadingOutlined,
  PlusOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import useAuthContext from '../../../contexts/AuthContext';
import PageHeaderCustom from '../../../components/PageHeader/PageHeader';
import ContentCustom from '../../../components/ContentCustom/ContentCustom';
import Datatable from '../../../components/DataTable/Datatable';
import Columns from '../Templates/Columns';
import useErrorMessage from '../../../utils/ErrorMessage';
import ErrorStatusCode from '../../../utils/ErrorStatusCode';

const { Panel } = Collapse;

const iconSize = 14;

const DocumentsManager = () => {
  const { t } = useTranslation();
  const { dispatchAPI, user, setUser } = useAuthContext();
  const { message } = useErrorMessage();
  const { notification } = ErrorStatusCode();
  const { pathname } = useLocation();
  const [forceRefresh, setForceRefresh] = useState(false);
  const [usedTemplates, setUsedTemplates] = useState([]);
  const [isDownloading, setIsDownloading] = useState({});
  const [isSigning, setIsSigning] = useState({});

  const deleteFile = async (file) => {
    try {
      if (
        usedTemplates.length > 0 &&
        usedTemplates.find((template) => template.value === file.key)
      ) {
        return message('used_file');
      }

      if (file.metadata && file.metadata.type === 'cgv') {
        await dispatchAPI('DELETE', {
          url: `/files/${file.key}`
        });
      } else {
        const { data } = await dispatchAPI('DELETE', {
          url: `/files/delete_template/${file.key}`
        });
        setUser({ ...user, organization: data });
      }
      setForceRefresh(true);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    return null;
  };

  const downloadFile = async (id, name, contentType) => {
    try {
      const response = await dispatchAPI('GET', {
        url: `/files/${id}`,
        responseType: 'blob'
      });
      const blob = new Blob([response.data], {
        type: contentType
      });

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = name;
      a.click();
    } catch (e) {
      if (e.response) notification(e.response);
    }
    setIsDownloading({ ...isDownloading, [id]: false });
  };

  const getSignURL = async (fileId) => {
    try {
      const { data } = await dispatchAPI('GET', { url: `/sign/${fileId}` });

      return data;
    } catch (e) {
      if (e.response) notification(e.response);
    }
    setIsSigning({ ...isSigning, [fileId]: false });
    return null;
  };

  const handleSignFile = async (fileId) => {
    const { redirectUrl } = await getSignURL(fileId);

    if (redirectUrl) {
      window.location.replace(redirectUrl);
    } else {
      message(500);
    }
  };

  const getTemplateSetting = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/settings?unitType=TEMPLATE'
      });
      setUsedTemplates(data);
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  const fetchData = useCallback(async () => {
    await getTemplateSetting();
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const actionColumn = [
    {
      key: 'action',
      render: (item) => {
        return (
          <span>
            <Button
              type="link"
              style={{ padding: 0 }}
              onClick={async () => {
                if (!isDownloading[item.key]) {
                  setIsDownloading({ ...isDownloading, [item.key]: true });
                  await downloadFile(
                    item.key,
                    item.metadata?.originalName || item.name,
                    item.contentType
                  );
                }
              }}
            >
              {isDownloading[item.key] ? (
                <LoadingOutlined />
              ) : (
                <DownloadOutlined />
              )}
            </Button>
            {item.contentType === 'application/pdf' && user.docusign_token && (
              <>
                <Divider type="vertical" />
                <Button
                  type="link"
                  style={{ padding: 0 }}
                  onClick={async () => {
                    if (!isSigning[item.key]) {
                      setIsSigning({ ...isSigning, [item.key]: true });
                      await handleSignFile(item.key);
                    }
                  }}
                >
                  {isSigning[item.key] ? (
                    <LoadingOutlined />
                  ) : (
                    <HighlightOutlined style={{ fontSize: iconSize }} />
                  )}
                </Button>
              </>
            )}
            <Divider type="vertical" />
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.delete.cancel')}
              icon={<WarningOutlined />}
              onConfirm={async () => {
                setForceRefresh(false);
                await deleteFile(item);
              }}
            >
              <DeleteOutlined
                style={{ color: 'red', fontSize: iconSize }}
                type="delete"
              />
            </Popconfirm>
          </span>
        );
      }
    }
  ];

  return (
    <>
      <PageHeaderCustom title={t('ged.title')} />
      <ContentCustom>
        <Row justify="end" gutter={[8, 16]}>
          <Col>
            <Row align="middle">
              <Link to={`${pathname}/create`}>
                <Button type="primary">
                  {`${t('buttons.create')}`}
                  &nbsp;
                  <PlusOutlined />
                </Button>
              </Link>
            </Row>
          </Col>
        </Row>
        <Collapse
          accordion
          style={{ marginTop: 24 }}
          className="documents-manager-collapse"
        >
          <Panel header={t('ged.internal_rules_cgv')} key="1">
            <Collapse accordion>
              <Panel header={t('ged.cgv')} key="1">
                <Datatable
                  resourceName="files"
                  columns={[...Columns(t), ...actionColumn]}
                  extraQuery={`type=cgv&organization=${user.organization._id}`}
                  customActionColumn
                  forceRefresh={forceRefresh}
                />
              </Panel>
              <Panel header={t('ged.internal_rules')} key="2">
                <Datatable
                  resourceName="files"
                  columns={[...Columns(t), ...actionColumn]}
                  extraQuery={`type=internal_rules&organization=${user.organization._id}`}
                  customActionColumn
                  forceRefresh={forceRefresh}
                />
              </Panel>
            </Collapse>
          </Panel>
          <Panel header={t('ged.programs')} key="2">
            <Table
              dataSource={user.organization.templates
                .filter((el) => el.type === 'programs')
                .map((el) => ({ ...el, key: el._id }))}
              columns={[...Columns(t), ...actionColumn]}
              pagination={false}
            />
          </Panel>
          <Panel header={t('ged.customers_files.title')} key="3">
            <Collapse accordion>
              <Panel
                key="1"
                header={t('ged.customers_files.company_convention')}
              >
                <Table
                  dataSource={user.organization.templates
                    .filter((el) => el.type === 'company_convention')
                    .map((el) => ({ ...el, key: el._id }))}
                  columns={[...Columns(t), ...actionColumn]}
                  pagination={false}
                />
              </Panel>
              <Panel
                key="2"
                header={t('ged.customers_files.private_convention')}
              >
                <Table
                  dataSource={user.organization.templates
                    .filter((el) => el.type === 'private_convention')
                    .map((el) => ({ ...el, key: el._id }))}
                  columns={[...Columns(t), ...actionColumn]}
                  pagination={false}
                />
              </Panel>
              <Panel key="3" header={t('ged.customers_files.vae_contract')}>
                <Table
                  dataSource={user.organization.templates
                    .filter((el) => el.type === 'vae_contract')
                    .map((el) => ({ ...el, key: el._id }))}
                  columns={[...Columns(t), ...actionColumn]}
                  pagination={false}
                />
              </Panel>
              <Panel key="4" header={t('ged.customers_files.other_files')}>
                <Table
                  dataSource={user.organization.templates
                    .filter((el) => el.type === 'other_customers_files')
                    .map((el) => ({ ...el, key: el._id }))}
                  columns={[...Columns(t), ...actionColumn]}
                  pagination={false}
                />
              </Panel>
            </Collapse>
          </Panel>
          <Panel header={t('ged.invoices_quotations.title')} key="4">
            <Collapse accordion>
              <Panel key="1" header={t('ged.invoices_quotations.quotations')}>
                <Table
                  dataSource={user.organization.templates
                    .filter((el) => el.type === 'quotations')
                    .map((el) => ({ ...el, key: el._id }))}
                  columns={[...Columns(t), ...actionColumn]}
                  pagination={false}
                />
              </Panel>
              <Panel key="2" header={t('ged.invoices_quotations.invoices')}>
                <Table
                  dataSource={user.organization.templates
                    .filter((el) => el.type === 'invoices')
                    .map((el) => ({ ...el, key: el._id }))}
                  columns={[...Columns(t), ...actionColumn]}
                  pagination={false}
                />
              </Panel>
              <Panel key="3" header={t('ged.invoices_quotations.credit_notes')}>
                <Table
                  dataSource={user.organization.templates
                    .filter((el) => el.type === 'credit_notes')
                    .map((el) => ({ ...el, key: el._id }))}
                  columns={[...Columns(t), ...actionColumn]}
                  pagination={false}
                />
              </Panel>
              <Panel key="4" header={t('ged.invoices_quotations.subscription')}>
                <Table
                  dataSource={user.organization.templates
                    .filter((el) => el.type === 'subscription')
                    .map((el) => ({ ...el, key: el._id }))}
                  columns={[...Columns(t), ...actionColumn]}
                  pagination={false}
                />
              </Panel>
            </Collapse>
          </Panel>
          <Panel header={t('ged.contributors_files.title')} key="5">
            <Collapse accordion>
              <Panel
                key="1"
                header={t('ged.contributors_files.subcontracting')}
              >
                <Table
                  dataSource={user.organization.templates
                    .filter((el) => el.type === 'subcontracting')
                    .map((el) => ({ ...el, key: el._id }))}
                  columns={[...Columns(t), ...actionColumn]}
                  pagination={false}
                />
              </Panel>
              <Panel
                key="2"
                header={t('ged.contributors_files.other_contributors_files')}
              >
                <Table
                  dataSource={user.organization.templates
                    .filter((el) => el.type === 'other_contributors_files')
                    .map((el) => ({ ...el, key: el._id }))}
                  columns={[...Columns(t), ...actionColumn]}
                  pagination={false}
                />
              </Panel>
            </Collapse>
          </Panel>
          <Panel header={t('ged.trainees_files.title')} key="6">
            <Collapse accordion>
              <Panel key="1" header={t('ged.trainees_files.convocation')}>
                <Table
                  dataSource={user.organization.templates
                    .filter((el) => el.type === 'convocation')
                    .map((el) => ({ ...el, key: el._id }))}
                  columns={[...Columns(t), ...actionColumn]}
                  pagination={false}
                />
              </Panel>
              <Panel
                key="2"
                header={t('ged.trainees_files.training_action_completion')}
              >
                <Table
                  dataSource={user.organization.templates
                    .filter((el) => el.type === 'training_action_completion')
                    .map((el) => ({ ...el, key: el._id }))}
                  columns={[...Columns(t), ...actionColumn]}
                  pagination={false}
                />
              </Panel>
              <Panel key="3" header={t('ged.trainees_files.attestation_dpc')}>
                <Table
                  dataSource={user.organization.templates
                    .filter((el) => el.type === 'attestation_dpc')
                    .map((el) => ({ ...el, key: el._id }))}
                  columns={[...Columns(t), ...actionColumn]}
                  pagination={false}
                />
              </Panel>
              <Panel key="4" header={t('ged.trainees_files.vae_eligibility')}>
                <Table
                  dataSource={user.organization.templates
                    .filter((el) => el.type === 'vae_eligibility')
                    .map((el) => ({ ...el, key: el._id }))}
                  columns={[...Columns(t), ...actionColumn]}
                  pagination={false}
                />
              </Panel>
              <Panel
                key="5"
                header={t('ged.trainees_files.attendance_certificate')}
              >
                <Table
                  dataSource={user.organization.templates
                    .filter((el) => el.type === 'attendance_certificate')
                    .map((el) => ({ ...el, key: el._id }))}
                  columns={[...Columns(t), ...actionColumn]}
                  pagination={false}
                />
              </Panel>
              <Panel
                key="6"
                header={t('ged.trainees_files.other_trainees_files')}
              >
                <Table
                  dataSource={user.organization.templates
                    .filter((el) => el.type === 'other_trainees_files')
                    .map((el) => ({ ...el, key: el._id }))}
                  columns={[...Columns(t), ...actionColumn]}
                  pagination={false}
                />
              </Panel>
            </Collapse>
          </Panel>
          <Panel header={t('ged.signings.title')} key="7">
            <Collapse accordion>
              <Panel header={t('ged.signings.signingsPerDay')} key="1">
                <Table
                  dataSource={user.organization.templates
                    .filter((el) => el.type === 'signings_day')
                    .map((el) => ({ ...el, key: el._id }))}
                  columns={[...Columns(t), ...actionColumn]}
                  pagination={false}
                />
              </Panel>
              <Panel header={t('ged.signings.signingsPerSlot')} key="2">
                <Table
                  dataSource={user.organization.templates
                    .filter((el) => el.type === 'signings_slot')
                    .map((el) => ({ ...el, key: el._id }))}
                  columns={[...Columns(t), ...actionColumn]}
                  pagination={false}
                />
              </Panel>
              <Panel header={t('ged.signings.signingsPerTrainee')} key="3">
                <Table
                  dataSource={user.organization.templates
                    .filter((el) => el.type === 'signings_trainee')
                    .map((el) => ({ ...el, key: el._id }))}
                  columns={[...Columns(t), ...actionColumn]}
                  pagination={false}
                />
              </Panel>
              <Panel header={t('ged.signings.signingsPerWeek')} key="4">
                <Table
                  dataSource={user.organization.templates
                    .filter((el) => el.type === 'signings_week')
                    .map((el) => ({ ...el, key: el._id }))}
                  columns={[...Columns(t), ...actionColumn]}
                  pagination={false}
                />
              </Panel>
              <Panel header={t('ged.signings.signingsPerWeekEnd')} key="5">
                <Table
                  dataSource={user.organization.templates
                    .filter((el) => el.type === 'signings_weekend')
                    .map((el) => ({ ...el, key: el._id }))}
                  columns={[...Columns(t), ...actionColumn]}
                  pagination={false}
                />
              </Panel>
              <Panel header={t('ged.signings.digitalSigningReport')} key="6">
                <Table
                  dataSource={user.organization.templates
                    .filter((el) => el.type === 'digital_signing_report')
                    .map((el) => ({ ...el, key: el._id }))}
                  columns={[...Columns(t), ...actionColumn]}
                  pagination={false}
                />
              </Panel>
            </Collapse>
          </Panel>
          <Panel header={t('ged.evaluations')} key="8">
            <Table
              dataSource={user.organization.templates
                .filter((el) => el.type === 'evaluations')
                .map((el) => ({ ...el, key: el._id }))}
              columns={[...Columns(t), ...actionColumn]}
              pagination={false}
            />
          </Panel>
          <Panel header={t('ged.import_template.title')} key="9">
            <Collapse accordion>
              <Panel
                header={t('ged.import_template.users_import_template')}
                key="1"
              >
                <Table
                  dataSource={user.organization.templates
                    .filter((el) => el.type === 'users_import_template')
                    .map((el) => ({ ...el, key: el._id }))}
                  columns={[...Columns(t), ...actionColumn]}
                  pagination={false}
                />
              </Panel>
              <Panel
                header={t('ged.import_template.programs_import_template')}
                key="2"
              >
                <Table
                  dataSource={user.organization.templates
                    .filter((el) => el.type === 'programs_import_template')
                    .map((el) => ({ ...el, key: el._id }))}
                  columns={[...Columns(t), ...actionColumn]}
                  pagination={false}
                />
              </Panel>
              <Panel
                header={t('ged.import_template.trainees_import_template')}
                key="3"
              >
                <Table
                  dataSource={user.organization.templates
                    .filter((el) => el.type === 'trainees_import_template')
                    .map((el) => ({ ...el, key: el._id }))}
                  columns={[...Columns(t), ...actionColumn]}
                  pagination={false}
                />
              </Panel>
              <Panel
                header={t('ged.import_template.companies_import_template')}
                key="4"
              >
                <Table
                  dataSource={user.organization.templates
                    .filter((el) => el.type === 'companies_import_template')
                    .map((el) => ({ ...el, key: el._id }))}
                  columns={[...Columns(t), ...actionColumn]}
                  pagination={false}
                />
              </Panel>
              <Panel
                header={t('ged.import_template.contacts_import_template')}
                key="5"
              >
                <Table
                  dataSource={user.organization.templates
                    .filter((el) => el.type === 'contacts_import_template')
                    .map((el) => ({ ...el, key: el._id }))}
                  columns={[...Columns(t), ...actionColumn]}
                  pagination={false}
                />
              </Panel>
              <Panel
                header={t('ged.import_template.contributors_import_template')}
                key="6"
              >
                <Table
                  dataSource={user.organization.templates
                    .filter((el) => el.type === 'contributors_import_template')
                    .map((el) => ({ ...el, key: el._id }))}
                  columns={[...Columns(t), ...actionColumn]}
                  pagination={false}
                />
              </Panel>
              <Panel
                header={t(
                  'ged.import_template.external_founders_import_template'
                )}
                key="7"
              >
                <Table
                  dataSource={user.organization.templates
                    .filter(
                      (el) => el.type === 'external_founders_import_template'
                    )
                    .map((el) => ({ ...el, key: el._id }))}
                  columns={[...Columns(t), ...actionColumn]}
                  pagination={false}
                />
              </Panel>
              <Panel
                header={t(
                  'ged.import_template.trainings_place_import_template'
                )}
                key="8"
              >
                <Table
                  dataSource={user.organization.templates
                    .filter(
                      (el) => el.type === 'training_places_import_template'
                    )
                    .map((el) => ({ ...el, key: el._id }))}
                  columns={[...Columns(t), ...actionColumn]}
                  pagination={false}
                />
              </Panel>
            </Collapse>
          </Panel>
        </Collapse>
      </ContentCustom>
    </>
  );
};

export default DocumentsManager;
