const setRoutes = ({ t, path, purpose }) => {
  const routes = [
    {
      path: '/',
      breadcrumbName: t('home.title')
    },
    {
      path: `/session`,
      breadcrumbName: `${t('breadcrumb.charges')}`
    },
    {
      path: `/session/show/${path[3]}`,
      breadcrumbName: `${t('breadcrumb.charges')}`
    },
    {
      breadcrumbName: t(`charges.${purpose}`)
    }
  ];

  return routes;
};

export default setRoutes;
