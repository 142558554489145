import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import {
  DeleteOutlined,
  WarningOutlined,
  DownOutlined,
  CheckOutlined,
  DownloadOutlined,
  LoadingOutlined
} from '@ant-design/icons';
import {
  Button,
  Divider,
  message,
  Popconfirm,
  Space,
  Checkbox,
  Menu,
  Dropdown,
  Card,
  Row,
  Tag
} from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import useAuthContext from '../../../contexts/AuthContext';
import ModalGeneratingFile from '../Convocations/modalGeneratingFile';
import ErrorStatusCode from '../../../utils/ErrorStatusCode';

const AssociatedProgramToSession = ({
  session,
  updateSession,
  fetchData,
  purpose,
  customer,
  onAccept,
  idUser
}) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [exist, setExist] = useState(false);
  const [generatedFile, setGeneratedFile] = useState({});
  const [generateStatus, setGenerateStatus] = useState(false);
  const { notification } = ErrorStatusCode();

  const deleteProgramFromSession = async () => {
    await updateSession({ program: null });
    await fetchData();
  };
  const [templates, setTemplates] = useState([]);
  const { dispatchAPI, user } = useAuthContext();
  const [isModalGenerateFileVisible, setIsModalGenerateFileVisible] = useState(
    false
  );

  const getFile = () => {
    try {
      const list = user.organization.templates.filter(
        (el) => el.type === 'programs'
      );
      setTemplates(list);
    } catch (e) {
      if (e.response) {
        notification(e.response);
      }
    }
  };

  const downloadFile = async (id, name) => {
    try {
      const response = await dispatchAPI('GET', {
        url: `/files/${id}`,
        responseType: 'blob'
      });
      const blob = new Blob([response.data], {
        type: response.data.type
      });

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = name;
      a.target = '_blank';
      a.click();
    } catch (e) {
      if (e.response) {
        notification(e.response);
      }
    }
  };

  const generateFile = async (temp, programId, type) => {
    try {
      setGenerateStatus(true);
      message.info(t('sessions.messages.is_generating'), 5);
      await dispatchAPI('GET', {
        url: `/files/generate/${programId}/${temp._id}/${type}?sessionId=${id}`,
        responseType: 'blob'
      });
      message.success(t('programs.success_messages.success_generating'));
      setGenerateStatus(false);
      setIsModalGenerateFileVisible(true);
      await fetchData();
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  const menuDropDown = (record) => {
    const menu = (
      <Menu>
        {templates.map((temp) => (
          <>
            <Menu.Item key="1">
              {generateStatus ? (
                <Button disabled type="link">
                  {`${temp.name.split('.')[0]} docx`}
                </Button>
              ) : (
                <Button
                  type="link"
                  onClick={() => generateFile(temp, record._id, 'docx')}
                >
                  {`${temp.name.split('.')[0]} docx`}
                </Button>
              )}
            </Menu.Item>
            <Menu.Item key="2">
              {generateStatus ? (
                <Button disabled type="link">
                  {`${temp.name.split('.')[0]} pdf`}
                </Button>
              ) : (
                <Button
                  type="link"
                  onClick={() => generateFile(temp, record._id, 'pdf')}
                >
                  {`${temp.name.split('.')[0]} pdf`}
                </Button>
              )}
            </Menu.Item>
          </>
        ))}
      </Menu>
    );
    return menu;
  };

  const setFile = () => {
    if (session.program.files.length) {
      const newestProgram = session.program.files.reduce((acc, obj) => {
        if (!acc || obj.date > acc.date) {
          return obj;
        }
        return acc;
      }, null);

      setGeneratedFile({
        id: newestProgram._id,
        fileName: newestProgram.filename,
        extern: true
      });
      setExist(true);
    } else if (session.files.length) {
      const fileProgram = session.files.filter(
        (file) => file.program === session.program._id
      );

      if (fileProgram.length) {
        setGeneratedFile({
          id: fileProgram[0]._id,
          fileName: fileProgram[0].filename
        });
        setExist(true);
      }
    }
  };

  useEffect(() => {
    setFile();
  }, [session.program.files, session.files]);

  const listActions = (files) => {
    let actions = [];
    const fileProgram = files.filter(
      (file) => file.program === session.program._id
    );

    fileProgram.forEach((file) => {
      actions = [
        <>
          <Tag style={{ fontSize: 15 }} color="orange">
            <span>
              <CheckOutlined style={{ margin: '4px 2px 0 0', fontSize: 14 }} />
              {`${t('sessions.conventions.button.generate_the')} ${moment(
                file.date
              ).format('DD-MM-YYYY')} `}
            </span>
            <span>
              {`${t('sessions.conventions.button.format')} ${
                file.filename.split('.')[1]
              }`}
            </span>
          </Tag>
        </>
      ];
    });

    return actions;
  };

  useEffect(() => {
    (async () => await getFile())();
  }, []);

  return (
    <>
      <ModalGeneratingFile
        isVisible={isModalGenerateFileVisible}
        setVisible={setIsModalGenerateFileVisible}
      />
      <div className="site-card_border-less-wrapper">
        <Card>
          <Row justify="space-between" align="middle">
            <span>{session.program.name}</span>
            <div
              style={{
                float: 'right',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                minWidth: 160
              }}
            >
              {!generatedFile.extern ? (
                listActions(session.files)
              ) : (
                <div>{t('sessions.programs.extern')}</div>
              )}
              {generateStatus && <LoadingOutlined />}
              {purpose !== 'customers' && !generatedFile.extern && (
                <Dropdown
                  overlay={() => menuDropDown(session.program)}
                  trigger={['click']}
                >
                  <Button type="link">
                    {t('sessions.programs.program')}
                    <DownOutlined />
                  </Button>
                </Dropdown>
              )}
              {exist && (
                <>
                  <Divider type="vertical" />
                  <Button
                    type="link"
                    icon={<DownloadOutlined />}
                    onClick={() =>
                      downloadFile(generatedFile.id, generatedFile.fileName)
                    }
                  />
                </>
              )}
              {purpose === 'customers' && (
                <Space>
                  <span>{t('sessions.programs.accept')}</span>
                  <Checkbox
                    checked={customer.status === 'ACCEPTED'}
                    onClick={(event) => onAccept(idUser, event.target.checked)}
                    className="accept-file-checkbox"
                  />
                </Space>
              )}
              <Space>
                <Divider type="vertical" />
                <Popconfirm
                  title={t('datatable.column.action.delete.title')}
                  okText={t('datatable.column.action.delete.ok')}
                  okButtonProps={{ type: 'danger' }}
                  cancelText={t('datatable.column.action.delete.cancel')}
                  onConfirm={async () => {
                    await deleteProgramFromSession();
                  }}
                  icon={<WarningOutlined />}
                >
                  <DeleteOutlined style={{ color: 'red' }} type="delete" />
                </Popconfirm>
              </Space>
            </div>
          </Row>
        </Card>
      </div>
    </>
  );
};

AssociatedProgramToSession.propTypes = {
  session: PropTypes.shape({
    files: PropTypes.arrayOf(PropTypes.shape({})),
    program: PropTypes.shape({
      files: PropTypes.arrayOf(PropTypes.shape({})),
      name: PropTypes.string
    })
  }).isRequired,
  customer: PropTypes.shape({
    status: PropTypes.string
  }),
  updateSession: PropTypes.func.isRequired,
  fetchData: PropTypes.func.isRequired,
  purpose: PropTypes.string,
  onAccept: PropTypes.func.isRequired,
  idUser: PropTypes.string
};

AssociatedProgramToSession.defaultProps = {
  customer: null,
  purpose: null,
  idUser: null
};

export default AssociatedProgramToSession;
