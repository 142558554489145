import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import { userRoles } from '../../utils/constants/tagColors';

export const useColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('users.form.last_name'),
      key: 'last_name',
      dataIndex: 'last_name',
      sorter: true
    },
    {
      title: t('users.form.first_name'),
      key: 'first_name',
      dataIndex: 'first_name',
      sorter: true
    },
    {
      title: t('users.form.role'),
      key: 'role',
      dataIndex: 'role',
      render: (role) => (
        <Tag color={userRoles[role.split(':')[1]]}>
          {t(`users.tags.${role.split(':')[1]}`)}
        </Tag>
      ),
      sorter: true,
      filters: Object.keys(userRoles).map((role) => ({
        text: t(`users.tags.${role}`),
        value:
          role.split('-').length > 1
            ? `${role.split('-')[1].toLowerCase()}s:${role}`
            : `${role.toLowerCase()}s:${role}`
      }))
    },
    {
      title: t('users.form.email'),
      key: 'email',
      dataIndex: 'email',
      sorter: true
    }
  ];
};
