import { useTranslation } from 'react-i18next';
import moment from 'moment';

export const useColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('invoices.list.column.title.number'),
      dataIndex: 'number',
      key: 'number',
      sorter: true
    },
    {
      title: t('invoices.list.column.title.emission_date'),
      dataIndex: 'emission_date',
      key: 'emission_date',
      sorter: true,
      render: (emission_date) =>
        emission_date ? moment(emission_date).format('DD/MM/YYYY') : '-'
    },
    {
      title: t('invoices.list.column.title.total'),
      dataIndex: 'total_ttc',
      key: 'total_ttc',
      sorter: true,
      render: (total_ttc) =>
        total_ttc ? `${(total_ttc / 100).toFixed(2)} €` : '-'
    }
  ];
};
