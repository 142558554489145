const fetchData = async ({
  dispatchAPI,
  setCompanies,
  purpose,
  path,
  form
}) => {
  const { data } = await dispatchAPI('GET', { url: '/companies' });
  setCompanies(data);
  if (purpose === 'edit') {
    const charge = await dispatchAPI('GET', { url: `/charges/${path[6]}` });
    form.setFieldsValue(charge.data);
  }
};
export default fetchData;
