import React, { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Card,
  Col,
  Collapse,
  Divider,
  Dropdown,
  List,
  Menu,
  message,
  Row,
  Spin,
  Tag
} from 'antd';
import { useTranslation } from 'react-i18next';
import {
  CheckOutlined,
  DownloadOutlined,
  DownOutlined,
  LoadingOutlined,
  SendOutlined
} from '@ant-design/icons';
import moment from 'moment';
import useAuthContext from '../../../contexts/AuthContext';
import ModalSendMails from './modalSendMails';
import ModalGeneratingFile from './modalGeneratingFile';
import ErrorStatusCode from '../../../utils/ErrorStatusCode';

const { Panel } = Collapse;

const GenerateConvocations = ({
  session,
  emails,
  trainees,
  fetchData,
  updateSession,
  forceRefresh,
  setForceRefresh
}) => {
  const { t } = useTranslation();
  const { dispatchAPI, user } = useAuthContext();
  const [users, setUsers] = useState([]);
  const [exist, setExist] = useState({});
  const [isModalMailVisible, setIsModalMailVisible] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [generatedFile, setGeneratedFile] = useState({});
  const [file, setFile] = useState([]);
  const [loading, setLoading] = useState({});
  const [template, setTemplate] = useState([]);
  const [isModalGenerateFileVisible, setIsModalGenerateFileVisible] = useState(
    false
  );

  const [sendType, setSendType] = useState('one');
  const [generateStatus, setGenerateStatus] = useState(false);
  const [emailPurpose, setEmailPurpose] = useState('');
  const [globalEmail, setGlobalEmail] = useState(true);
  const { notification } = ErrorStatusCode();

  const showModal = (purpose, id, file, fileType) => {
    if (purpose === 'all') {
      const list = [];
      setUsers(
        trainees.map((el) => ({
          ...el,
          ref: 'Trainees'
        }))
      );

      trainees.forEach((trainee) => {
        const listFiles = session.files.filter(
          (item) =>
            item?.user &&
            item?.user._id === trainee?._id &&
            item?.type === fileType
        );
        list.push({
          ...listFiles[listFiles.length - 1],
          user: trainee?._id
        });
      });
      setFile(
        list
          .filter((el) => el?._id !== undefined)
          .map((el) => ({
            id: el?._id,
            fileName: el?.filename,
            user: el?.user
          }))
      );
    } else {
      setUsers(
        trainees
          .filter((el) => el?._id.toString() === id.toString())
          .map((el) => ({
            ...el,
            ref: 'Trainees'
          }))
      );
      setFile([file]);
    }
    setEmailPurpose(fileType);
    setIsModalMailVisible(!isModalMailVisible);
  };

  const getFilesConvocations = async () => {
    try {
      const list = user.organization.templates.filter(
        (el) => el.type === 'convocation'
      );
      setTemplate(list);
    } catch (e) {
      if (e.response) {
        notification(e.response);
      }
    }
  };

  useEffect(() => {
    (async () => {
      await getFilesConvocations();
    })();
  }, []);

  const getFile = async (temp, customId, fileType) => {
    try {
      setLoading({ [`${customId}-${temp.type}`]: true });
      await generateFile(temp._id, temp.name, customId, fileType);
    } catch (e) {
      if (e.response) {
        notification(e.response);
      }
    }
    setLoading({ [`${customId}-${temp.type}`]: false });
  };

  const generateMultiFiles = async (templateId, typeFile, type) => {
    try {
      message.info(
        'Veuillez patienter, le document est en cours de génération',
        5
      );
      setLoading({ [type]: true });
      setGenerateStatus(true);
      await dispatchAPI('GET', {
        url: `/multi-generate/${
          session._id
        }/${templateId}?trainees=${JSON.stringify(
          trainees.map((el) => el._id)
        )}&fileType=${typeFile}`
      });
      setIsModalGenerateFileVisible(!isModalGenerateFileVisible);
      await fetchData();
      setLoading({ [type]: false });
      setGenerateStatus(false);
    } catch (e) {
      message.error(t('settings.errors.category_delete'));
    }
  };

  const menuDropDown = (type, id, isMulti) => {
    const menu = (
      <Menu>
        {template.map((el, index) => (
          <>
            <Menu.Item key={`${el._id}-${index + 1}`}>
              {generateStatus ? (
                <Button type="link" disabled>
                  {`${el.name.split('.')[0]} docx`}
                </Button>
              ) : (
                <Button
                  type="link"
                  style={{ width: '100%', textAlign: 'left' }}
                  onClick={() =>
                    isMulti
                      ? generateMultiFiles(el._id, 'docx', 'convocation')
                      : getFile(el, id, 'docx')
                  }
                >
                  {`${el.name.split('.')[0]} docx`}
                </Button>
              )}
            </Menu.Item>
            <Menu.Item key={`${el._id}-${index}`}>
              {generateStatus ? (
                <Button type="link" disabled>
                  {`${el.name.split('.')[0]} pdf`}
                </Button>
              ) : (
                <Button
                  type="link"
                  style={{ width: '100%', textAlign: 'left' }}
                  onClick={() =>
                    isMulti
                      ? generateMultiFiles(el._id, 'pdf', 'convocation')
                      : getFile(el, id, 'pdf')
                  }
                >
                  {`${el.name.split('.')[0]} pdf`}
                </Button>
              )}
            </Menu.Item>
          </>
        ))}
      </Menu>
    );
    return menu;
  };

  const generateFile = async (id, name, customId, fileType) => {
    try {
      message.info(
        'Veuillez patienter, le document est en cours de génération',
        5
      );
      setGenerateStatus(true);
      await dispatchAPI('GET', {
        url: `/files/generate/convention/${session._id}/${customId}/${id}?fileType=${fileType}`,
        responseType: 'blob'
      });
      setIsModalGenerateFileVisible(!isModalGenerateFileVisible);
      await fetchData();
      setLoading(false);
      setGenerateStatus(false);
    } catch (e) {
      if (e.response) notification(e.response);
    }
    setIsDownloading({ ...isDownloading, [id]: false });
  };

  const downloadFile = async (id, name) => {
    try {
      const response = await dispatchAPI('GET', {
        url: `/files/${id}`,
        responseType: 'blob'
      });
      const blob = new Blob([response.data], {
        type: response.data.type
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = name;
      a.target = '_blank';
      a.click();
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
    setIsDownloading({ ...isDownloading, [id]: false });
  };

  useEffect(() => {
    const exists = {};
    const filesList = {};
    trainees.forEach((trainee) => {
      const file = session.files
        .filter(
          (file) =>
            file.user &&
            file.user._id.toString() === trainee._id.toString() &&
            file.type === 'convocation'
        )
        .sort((a, b) => new Date(b.date) - new Date(a.date))[0];
      if (file) {
        exists[trainee._id] = true;
        filesList[trainee._id] = {
          fileName: file.filename,
          id: file._id
        };
      }
    });
    setExist(exists);
    setGeneratedFile(filesList);
  }, [session.files, trainees]);

  const listActions = (type, id) => {
    let actions = [];
    session.files.forEach((file) => {
      if (
        file.user &&
        file.user._id.toString() === id.toString() &&
        file.type === type
      ) {
        actions = [
          <>
            <Tag style={{ fontSize: 15 }} color="orange">
              <span>
                <CheckOutlined
                  style={{ margin: '4px 2px 0 0', fontSize: 14 }}
                />{' '}
                {`Générée le ${moment(file.date).format('DD-MM-YYYY')} `}
              </span>
              <span>{`au format ${file.filename.split('.')[1]}`}</span>
            </Tag>
          </>
        ];
      }
    });

    const email = emails.find(
      (el) => el.user && el.user._id === id && el.purpose === type
    );
    if (email) {
      actions.push(
        <Tag color="green">
          <span style={{ fontSize: 15 }}>
            <CheckOutlined style={{ margin: '4px 2px 0 0', fontSize: 14 }} />
            Envoyée
          </span>
        </Tag>
      );
    }

    return actions;
  };

  return (
    <>
      <Row style={{ padding: '0 20px' }}>
        <Col span={24}>
          <Alert
            showIcon
            type="info"
            message="Afin de passer cette étape, vous devez : générer les convocations pour tous les stagiaires et les envoyer."
          />
        </Col>
      </Row>
      <Divider orientation="left">
        <span>{t('Générer les convocations')}</span>
      </Divider>
      <ModalGeneratingFile
        isVisible={isModalGenerateFileVisible}
        setVisible={setIsModalGenerateFileVisible}
      />
      <ModalSendMails
        emailModel="convocation_model"
        files={file}
        file={file}
        sendType={sendType}
        isSelectedDocument={false}
        globalEmail={globalEmail}
        setGlobalEmail={setGlobalEmail}
        users={users}
        isModalVisible={isModalMailVisible}
        setIsModalVisible={setIsModalMailVisible}
        emailPurpose={emailPurpose}
        refresh={forceRefresh}
        setRefresh={setForceRefresh}
        session={session}
      />
      <Card>
        {trainees.length === 0 && (
          <Col style={{ marginBottom: '20px' }}>
            <Alert
              showIcon
              type="info"
              message={
                "Vous ne pouvez pas ni générer, ni envoyer des convocations s'il n'y a aucun client réel préalablement renseigné"
              }
            />
          </Col>
        )}
        <Button
          disabled={Object.keys(generatedFile).length === 0}
          type="primary"
          style={{ marginBottom: 10 }}
          onClick={() => {
            setSendType('multi');
            setGlobalEmail(true);
            showModal('all', '', '', 'convocation');
          }}
        >
          Envoyer les convocations
        </Button>
        <Spin
          style={{ marginLeft: 10 }}
          spinning={loading.convocation || false}
        />
        <Dropdown
          disabled={trainees.length === 0}
          overlay={menuDropDown('convocation', '', true)}
        >
          <Button type="link" style={{ marginBottom: 10, marginLeft: 10 }}>
            Générer les convocations
            <DownOutlined />
          </Button>
        </Dropdown>

        <List
          dataSource={trainees}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta title={`${item.first_name} ${item.last_name}`} />
              <span style={{ paddingRight: 50 }}>
                {listActions('convocation', item._id)}
              </span>
              <span style={{ float: 'right' }}>
                {loading[`${item._id}-convocation`] && <LoadingOutlined />}
                <Dropdown
                  overlay={() => menuDropDown('convocation', item._id)}
                  trigger={['click']}
                >
                  <Button type="link">
                    Convocation <DownOutlined />
                  </Button>
                </Dropdown>
                {exist[item._id] && (
                  <>
                    <Divider type="vertical" />
                    <Button
                      type="link"
                      icon={<DownloadOutlined />}
                      onClick={() =>
                        downloadFile(
                          generatedFile[item._id].id,
                          generatedFile[item._id].fileName
                        )
                      }
                    />
                    <Divider type="vertical" />
                    <Button
                      type="link"
                      icon={<SendOutlined />}
                      onClick={() => {
                        setSendType('one');
                        setGlobalEmail(false);
                        showModal(
                          item.ref,
                          item._id,
                          generatedFile[item._id],
                          'convocation'
                        );
                      }}
                    />
                  </>
                )}
              </span>
            </List.Item>
          )}
        />
      </Card>
    </>
  );
};

export default GenerateConvocations;
