import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import QualityReport from './QualityReport';

const QualityRouter = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}`} component={QualityReport} />
    </Switch>
  );
};

export default QualityRouter;
