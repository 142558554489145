export const checkInvoiceCreated = ({
  customers,
  invoices,
  modules,
  setDisabled
}) => {
  customers.forEach(({ customer }) => {
    const invoiceForeachOne = invoices.filter(
      (invoice) => invoice.customer === customer._id
    );
    const oldInvoices = (modules || []).rates?.filter((rate) =>
      invoiceForeachOne.every((invoice) =>
        invoice.items.every((item) => item.key !== rate._id)
      )
    );
    if (oldInvoices?.length > 0) {
      setDisabled((prev) => {
        return { ...prev, [customer._id]: false };
      });
    } else {
      setDisabled((prev) => {
        return { ...prev, [customer._id]: true };
      });
    }
  });
};
