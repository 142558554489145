import React, { useCallback, useEffect, useState } from 'react';
import {
  Divider,
  PageHeader,
  Form,
  Input,
  Button,
  Space,
  Select,
  message,
  Checkbox,
  Radio,
} from 'antd';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  formItemLayout,
  noLabelLayout,
} from '../../../utils/constants/formLayout';
import useAuthContext from '../../../contexts/AuthContext';
import { fundersRadio } from '../../../utils/constants/sessionsCustomersCheckbox';
import NewFunderDrawer from '../../Donnees/ExternalFounders/NewFunderDrawer';
import useHandleResize from '../../../utils/HandleResize';
import ErrorStatusCode from '../../../utils/ErrorStatusCode';

const FounderList = ({ session, purpose, listFunders, setListFunders }) => {
  const { Option } = Select;
  const { notification } = ErrorStatusCode();
  const { id } = useParams();
  const { t } = useTranslation();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const { dispatchAPI } = useAuthContext();
  const [funders, setFunders] = useState([]);
  const [funderOption, setFunderOption] = useState('');
  const [visibilityDrawer, setVisibilityDrawer] = useState(false);
  const [form] = Form.useForm();
  const [funder, setFunder] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const { width } = useHandleResize();

  useEffect(() => {
    if (purpose === 'edit') {
      session.customers &&
        session.customers.forEach(customer => {
          if (customer.customer === id) {
            form.setFieldsValue(customer);
            handleChange(null, { funders: customer.funders });
            setFunder(customer.funders);
          }
        });
    }
  }, [purpose, session]);

  const getFunders = async () => {
    try {
      setIsFieldsLoading(true);
      const { data } = await dispatchAPI('GET', { url: '/external-funders' });
      setFunders(data);
      setIsFieldsLoading(false);
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };
  const onChange = e => {
    console.log('radio checked', e.target.value);
    setFunderOption(e.target.value);
  };

  useEffect(() => {
    (async () => {
      await getFunders();
    })();
  }, [refresh]);

  const handleChange = (_, values) => {
    setListFunders(values);
  };

  const drawerVisibility = () => {
    setVisibilityDrawer(!visibilityDrawer);
  };

  return (
    <Form
      onValuesChange={handleChange}
      name="dynamic_form_nest_item"
      autoComplete="off"
      form={form}
      {...formItemLayout}>
      <Divider orientation="left">
        <span>{t(`Financeurs externes`)}</span>
      </Divider>{' '}
      <Form.List name={['funders']}>
        {(fields, { add, remove }) => (
          <>
            {(fields.length
              ? fields
              : funder.map((_, index) => ({
                  fieldKey: index,
                  isListField: true,
                  key: index,
                  name: index,
                }))
            ).map(field => (
              <div
                key={field.key}
                style={{
                  background: 'var(--contrastBackground)',
                  padding: 16,
                  borderRadius: 4,
                  marginBottom: 20,
                }}>
                <CloseOutlined onClick={() => remove(field.name)} />
                <Input.Group>
                  <NewFunderDrawer
                    refresh={refresh}
                    setRefresh={setRefresh}
                    isVisible={visibilityDrawer}
                    setVisibilityDrawer={drawerVisibility}
                  />
                  <Form.Item
                    {...field}
                    label={'Financeur'}
                    name={[field.name, 'funder']}
                    fieldKey={[field.fieldKey, 'funder']}
                    rules={[
                      { required: true, message: 'Ce champs est requis' },
                    ]}>
                    <Select
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      loading={isFieldsLoading}
                      placeholder="Nom du financeur">
                      {funders.map(funder => (
                        <Option key={funder._id} value={funder._id}>
                          {funder.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Button
                    type="primary"
                    onClick={drawerVisibility}
                    style={{
                      float: 'right',
                    }}>
                    <span style={{ fontSize: '15px' }}>
                      {t('buttons.create')}
                    </span>
                    <PlusOutlined />
                  </Button>
                </Input.Group>

                <Form.Item
                  {...field}
                  label={'Montant'}
                  rules={[{ required: true, message: 'Ce champs est requis' }]}
                  name={[field.name, 'amount']}
                  fieldKey={[field.fieldKey, 'amount']}>
                  <Input
                    type="number"
                    suffix="€"
                    placeholder="Montant du financement"
                  />
                </Form.Item>
                <Form.Item
                  {...field}
                  label={"Référence d'accord de prise en charge"}
                  name={[field.name, 'agreement_reference']}
                  fieldKey={[field.fieldKey, 'agreement_reference']}>
                  <Input placeholder="Référence d'accord de prise en charge" />
                </Form.Item>
                <Form.Item
                  {...field}
                  label="Subrogation de paiement"
                  valuePropName="checked"
                  name={[field.name, 'payment_subrogation']}
                  fieldKey={[field.fieldKey, 'payment_subrogation']}>
                  <Checkbox />
                </Form.Item>
                <Form.Item
                  {...field}
                  label="Options"
                  name={[field.name, 'funder_option']}
                  fieldKey={[field.fieldKey, 'funder_option']}>
                  <Radio.Group value={funderOption}>
                    {fundersRadio.map(item => (
                      <Radio value={item}>{item}</Radio>
                    ))}
                  </Radio.Group>
                </Form.Item>
              </div>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}>
                Ajouter un financeur externe
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </Form>
  );
};

export default FounderList;
