import React, { useEffect, useState } from 'react';
import { Button, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import PageHeaderCustom from '../../../components/PageHeader/PageHeader';
import { ContentCustom } from '../../../components';
import ProgramsTable from '../../Sessions/sessionProgram/programsTable';
import useAuthContext from '../../../contexts/AuthContext';
import ErrorStatusCode from '../../../utils/ErrorStatusCode';

const AddProgramBlocToProgram = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { dispatchAPI } = useAuthContext();
  const [programs, setPrograms] = useState([]);
  const [program, setProgram] = useState({});
  const [blocs, setBlocs] = useState([]);
  const splitURL = pathname.split('/');
  const idProgram = splitURL[4];
  const history = useHistory();
  const { notification } = ErrorStatusCode();

  const tmp = {
    educational_goals: [],
    trainees_profile: {
      customers: [],
      prerequisite: []
    },
    execution_track: {
      team_description: '',
      results_track: [],
      educational_resources: []
    }
  };

  const getPrograms = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/programs?populate=blocs`
      });
      setPrograms(
        data.map(({ _id, ...dataProgram }) => ({
          ...dataProgram,
          key: _id,
          _id
        }))
      );
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  const getProgram = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/programs/${idProgram}?populate=blocs`
      });
      setProgram(data);
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  const updateProgram = async (body) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/programs/${idProgram}`,
        body
      });
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
  };

  const addBlocsToProgram = async () => {
    let training_content = [];
    programs.forEach((eachProgram) => {
      blocs.forEach((bloc) => {
        if (eachProgram._id === bloc) {
          training_content = [...eachProgram.training_content];
          eachProgram.educational_goals.forEach((educ) => {
            tmp.educational_goals.push(educ);
          });

          if (eachProgram.trainees_profile) {
            eachProgram.trainees_profile.customers.forEach((el) => {
              tmp.trainees_profile.customers.push(el);
            });
          }

          if (eachProgram.trainees_profile) {
            eachProgram.trainees_profile.prerequisite.forEach((el) => {
              tmp.trainees_profile.prerequisite.push(el);
            });
          }

          tmp.execution_track.team_description =
            program.execution_track?.team_description;

          if (eachProgram.execution_track) {
            eachProgram.execution_track.results_track.forEach((el) => {
              tmp.execution_track.results_track.push(el);
            });
          }

          if (eachProgram.execution_track) {
            eachProgram.execution_track.educational_resources.forEach((el) => {
              tmp.execution_track.educational_resources.push(el);
            });
          }
        }
      });
    });

    const body = {
      ...program,
      blocs: [...program.blocs, ...blocs],
      ...tmp,
      training_content
    };

    await updateProgram(body);
    history.goBack();
  };

  useEffect(() => {
    (async () => {
      await getPrograms();
      await getProgram();
    })();
  }, []);

  return (
    <>
      <PageHeaderCustom title={t(`programs.form.title.create`)} />
      <ContentCustom>
        <ProgramsTable programs={programs} setBlocs={setBlocs} />
        <Button
          onClick={() => {
            addBlocsToProgram();
          }}
          type="add"
          htmlType="submit"
          style={{
            color: '#fff',
            float: 'right',
            marginBottom: 20,
            backgroundColor: 'rgb(71, 210, 103)',
            border: 'rgb(71, 210, 103)'
          }}
        >
          {t('programs.continue_with_blocs')}
        </Button>
      </ContentCustom>
    </>
  );
};

export default AddProgramBlocToProgram;
