import React, { useCallback, useEffect, useState } from 'react';
import { Input } from 'antd';

const useFields = () => {
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);

  const fields = [
    {
      name: ['name'],
      rules: [{ required: true }]
    },
    {
      name: ['channel_number'],
      input: <Input type="number" style={{ width: '100%' }} />,
      rules: [{ required: true }]
    },
    {
      label: 'street',
      name: ['street'],
      rules: [{ required: true }]
    },
    {
      name: ['address_details'],
      rules: [{ required: false }]
    },
    {
      name: ['postal_code'],
      input: <Input style={{ width: '100%' }} />,
      rules: [{ required: true }]
    },
    {
      name: ['city'],
      rules: [{ required: true }]
    },
    {
      name: ['email'],
      rules: [{ required: true }, { type: 'email' }]
    },
    {
      name: ['siret_number'],
      input: <Input type="number" style={{ width: '100%' }} />,
      rules: [{ required: true }]
    },
    {
      name: ['ape_number'],
      label: 'ape_number',
      rules: [{ required: true, message: 'Veuillez remplir ce champ' }],
      input: <Input style={{ width: '100%' }} />
    },

    {
      name: ['representative'],
      rules: [{ required: true }],
      input: <Input placeholder=" Nom et Prénom du représentant" />
    },
    {
      name: ['tva_company'],
      rules: [{ required: false }],
      input: <Input placeholder=" Numéro de TVA" />
    }
  ];

  const getSelectOptions = useCallback(() => {
    setIsFieldsLoading(true);
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return {
    fields,
    isFieldsLoading
  };
};

export default useFields;
