import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useFields from './fields';
import CreateUpdateContainer from '../../../components/CreateUpdateContainer/CreateUpdateContainer';

const CreateUpdateContact = ({ purpose }) => {
  const [companyRepresentative, setCompanyRepresentative] = useState(false);
  const { fields, isFieldsLoading } = useFields(
    companyRepresentative,
    setCompanyRepresentative
  );

  const config = {
    onGetResource: {
      setFields: (data) => {
        setCompanyRepresentative(data.company_representative);
        return data;
      }
    }
  };

  return (
    <CreateUpdateContainer
      fields={fields}
      loadingFields={isFieldsLoading}
      purpose={purpose}
      config={config}
      baseUrl="contacts"
      resource="contacts"
      withSubRoutes
    />
  );
};

CreateUpdateContact.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdateContact;
