import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Collapse, Form, Upload, message } from 'antd';
import { CameraOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import useFields from './fields';
import useGenerateFormItem from '../../utils/GenerateFormItem';
import ContentCustom from '../../components/ContentCustom/ContentCustom';
import { formItemLayout } from '../../utils/constants/formLayout';

const { Panel } = Collapse;
const { Dragger } = Upload;

const ItemCompanyFields = ({ stampFileList, setStampFileList, stamp }) => {
  const {
    contactFields,
    teamFields,
    teamReglementation,
    comptableClotureFields,
    comptableFields,
    teamComptable
  } = useFields();
  const { t } = useTranslation();
  const generateFields = useGenerateFormItem();
  const [imageUrl, setImageUrl] = useState('');

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const draggerProps = {
    onRemove: (file) => {
      const index = stampFileList.indexOf(file);
      const newFileList = stampFileList.slice();
      newFileList.splice(index, 1);
      setStampFileList(newFileList);
    },
    beforeUpload: (file) => {
      const newFile = file;
      newFile.label = 'stamp';
      const fileExtension = newFile.name.split('.').pop();
      if (
        fileExtension === 'png' ||
        fileExtension === 'PNG' ||
        fileExtension === 'jpg' ||
        fileExtension === 'JPG'
      ) {
        setStampFileList([...stampFileList, newFile]);
        getBase64(newFile, (image) => {
          setImageUrl(image);
        });
      } else {
        message.error(t('profile.messages.format_image'));
        return true;
      }
      return false;
    },
    stampFileList
  };

  return (
    <>
      <Collapse accordion className="collapse-generate-fields">
        <Panel header={t('company.form.stamp')} key="1">
          <Form.Item {...formItemLayout} label={t('company.form.stamp')}>
            <Dragger {...draggerProps}>
              {stamp || imageUrl ? (
                <img
                  src={imageUrl || stamp}
                  alt="stamp"
                  style={{ width: '60%' }}
                />
              ) : (
                <p className="ant-upload-drag-icon">
                  <CameraOutlined style={{ color: 'var(--textColor)' }} />
                </p>
              )}
            </Dragger>
          </Form.Item>
        </Panel>
        <Panel header={t('company.form.contact')} key="2">
          <ContentCustom>
            {contactFields.map((field) =>
              generateFields('company' || 'company', field)
            )}
          </ContentCustom>
        </Panel>
        <Panel header={t('company.form.team')} key="3">
          <ContentCustom>
            {teamFields.map((field) =>
              generateFields('company' || 'company', field)
            )}
          </ContentCustom>
        </Panel>
        <Panel header={t('company.form.reglementation')} key="4">
          <ContentCustom>
            {teamReglementation.map((field) =>
              generateFields('company' || 'company', field)
            )}
          </ContentCustom>
        </Panel>
        <Panel header={t('company.form.comptable')} key="5">
          <Collapse accordion className="collapse-generate-fields">
            <Panel header={t('company.form.comptable1')} key="1">
              <ContentCustom>
                {teamComptable.map((field) =>
                  generateFields('company' || 'company', field)
                )}
              </ContentCustom>
            </Panel>
            <Panel header={t('company.form.comptable-cloture')} key="2">
              <ContentCustom>
                {comptableClotureFields.map((field) =>
                  generateFields('company' || 'company', field)
                )}
              </ContentCustom>
            </Panel>
            <Panel header={t('company.form.comptable-identity')} key="3">
              <ContentCustom>
                {comptableFields.map((field) =>
                  generateFields('company' || 'company', field)
                )}
              </ContentCustom>
            </Panel>
          </Collapse>
        </Panel>
      </Collapse>
    </>
  );
};

export default ItemCompanyFields;

ItemCompanyFields.propTypes = {
  stampFileList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setStampFileList: PropTypes.func.isRequired,
  stamp: PropTypes.string.isRequired
};
