import React from 'react';

export const listCurrentsFields = [
  '100 - Formations générales',
  '111 - Physique-chimie',
  '310 - Spécialités plurivalentes des échanges et de la gestion (y compris administration générale des entreprises et des collectivités)',
  '344 - Sécurité des biens et des personnes, police, surveillance (y compris hygiène et sécurité)',
  '331 - Santé',
  "326 - Informatique, traitement de l'information, réseaux de transmission des données",
  '311 - Transports, manutention, magasinage',
  "415 - Développement des capacités d'orientation, d'insertion ou de réinsertion sociales",
  '413 - Développement des capacités comportementales et relationnelles',
  '312 - Commerce, vente',
];

export const listGeneralFields = [
  {
    title: (
      <span
        style={{
          color: '#1890ff',
          'text-decoration': 'underline',
          'font-weight': 'bold',
        }}>
        100 - Formations générales
      </span>
    ),
    key: '0-0',
    children: [
      {
        title: (
          <span style={{ color: '#1890ff', 'font-weight': 'bold' }}>
            110 - Spécialités pluriscientifiques
          </span>
        ),
        key: '0-0-0',
        children: [
          {
            title: (
              <span style={{ color: '#1890ff' }}>111 - Physique-chimie</span>
            ),
            key: '0-0-0-0',
          },
          {
            title: (
              <span style={{ color: '#1890ff' }}>
                112 - Chimie-biologie, biochimie
              </span>
            ),
            key: '0-0-0-1',
          },
          {
            title: (
              <span style={{ color: '#1890ff' }}>
                113 - Sciences naturelles (biologie-géologie)
              </span>
            ),
            key: '0-0-0-2',
          },
          {
            title: <span style={{ color: '#1890ff' }}>114 - Mathématique</span>,
            key: '0-0-0-3',
          },
          {
            title: <span style={{ color: '#1890ff' }}>115 - Physique</span>,
            key: '0-0-0-4',
          },
          {
            title: <span style={{ color: '#1890ff' }}>116 - Chimie</span>,
            key: '0-0-0-5',
          },
          {
            title: (
              <span style={{ color: '#1890ff' }}>
                117 - Sciences de la terre
              </span>
            ),
            key: '0-0-0-6',
          },
          {
            title: (
              <span style={{ color: '#1890ff' }}>118 - Sciences de la vie</span>
            ),
            key: '0-0-0-7',
          },
        ],
      },
      {
        title: (
          <span style={{ color: '#1890ff', 'font-weight': 'bold' }}>
            120 - Spécialités pluridisciplinaires, sciences humaines et droit
          </span>
        ),
        key: '0-0-1',
        children: [
          {
            title: <span style={{ color: '#1890ff' }}>121 - Géographie</span>,
            key: '0-0-1-0',
          },
          {
            title: <span style={{ color: '#1890ff' }}>122 - Economie</span>,
            key: '0-0-1-1',
          },
          {
            title: (
              <span style={{ color: '#1890ff' }}>
                123 - Sciences sociales (y compris démographie, anthropologie)
              </span>
            ),
            key: '0-0-1-2',
          },
          {
            title: <span style={{ color: '#1890ff' }}>124 - Psychologie</span>,
            key: '0-0-1-3',
          },
          {
            title: (
              <span style={{ color: '#1890ff' }}>125 - Linguistiques</span>
            ),
            key: '0-0-1-4',
          },
          {
            title: <span style={{ color: '#1890ff' }}>126 - Histoire</span>,
            key: '0-0-1-5',
          },
          {
            title: (
              <span style={{ color: '#1890ff' }}>
                127 - Philosophie, éthique et théologie
              </span>
            ),
            key: '0-0-1-6',
          },
          {
            title: (
              <span style={{ color: '#1890ff' }}>
                128 - Droit, sciences politiques
              </span>
            ),
            key: '0-0-1-7',
          },
        ],
      },
      {
        title: (
          <span style={{ color: '#1890ff', 'font-weight': 'bold' }}>
            130 - Spécialités littéraires et artistiques plurivalentes
          </span>
        ),
        key: '0-0-2',
        children: [
          {
            title: (
              <span style={{ color: '#1890ff' }}>
                131 - Français, littérature et civilisation française
              </span>
            ),
            key: '0-0-2-0',
          },
          {
            title: (
              <span style={{ color: '#1890ff' }}>132 - Arts plastiques</span>
            ),
            key: '0-0-2-1',
          },
          {
            title: (
              <span style={{ color: '#1890ff' }}>
                133 - Musique, arts du spectacle
              </span>
            ),
            key: '0-0-2-2',
          },
          {
            title: (
              <span style={{ color: '#1890ff' }}>
                134 - Autres disciplines artistiques et spécialités artistiques
              </span>
            ),
            key: '0-0-2-3',
          },
          {
            title: (
              <span style={{ color: '#1890ff' }}>
                135 - Langues et civilisations anciennes
              </span>
            ),
            key: '0-0-2-4',
          },
          {
            title: (
              <span style={{ color: '#1890ff' }}>
                136 - Langues vivantes, civilisations étrangères et régionales
              </span>
            ),
            key: '0-0-2-5',
          },
        ],
      },
    ],
  },
  {
    title: (
      <span
        style={{
          color: '#1890ff',
          'text-decoration': 'underline',
          'font-weight': 'bold',
        }}>
        200 - Technologies industrielles fondamentales (génie industriel,
        procédés de Transformation, spécialités à dominante fonctionnelle)
      </span>
    ),
    key: '0-1',
    children: [
      {
        title: (
          <span style={{ color: '#1890ff', 'font-weight': 'bold' }}>
            201 - Technologies de commandes des transformations industriels
            (automatismes et robotique industriels, informatique industrielle)
          </span>
        ),
        key: '0-1-0',
      },
      {
        title: (
          <span style={{ color: '#1890ff', 'font-weight': 'bold' }}>
            210 - Spécialités plurivalentes de l'agronomie et de l'agriculture
          </span>
        ),
        key: '0-1-1',
        children: [
          {
            title: (
              <span style={{ color: '#1890ff' }}>
                211 - Productions végétales, cultures spécialisées
                (horticulture, viticulture, arboriculture fruitière...)
              </span>
            ),
            key: '0-1-1-0',
          },
          {
            title: (
              <span style={{ color: '#1890ff' }}>
                212 - Productions animales, élevage spécialisé, aquaculture,
                soins aux animaux, y compris vétérinaire
              </span>
            ),
            key: '0-1-1-1',
          },
          {
            title: (
              <span style={{ color: '#1890ff' }}>
                213 - Forêts, espaces naturels, faune sauvage, pêche
              </span>
            ),
            key: '0-1-1-2',
          },
          {
            title: (
              <span style={{ color: '#1890ff' }}>
                214 - Aménagement paysager (parcs, jardins, espaces verts ...)
              </span>
            ),
            key: '0-1-1-3',
          },
        ],
      },
      {
        title: (
          <span style={{ color: '#1890ff', 'font-weight': 'bold' }}>
            220 - Spécialités pluritechnologiques des transformations
          </span>
        ),
        key: '0-1-2',
        children: [
          {
            title: (
              <span style={{ color: '#1890ff' }}>
                221 - Agro-alimentaire, alimentation, cuisine
              </span>
            ),
            key: '0-1-2-0',
          },
          {
            title: (
              <span style={{ color: '#1890ff' }}>
                222 - Transformations chimiques et apparentées (y compris
                industrie pharmaceutique)
              </span>
            ),
            key: '0-1-2-1',
          },
          {
            title: (
              <span style={{ color: '#1890ff' }}>
                223 - Métallurgie (y compris sidérurgie, fonderie, non
                ferreux...)
              </span>
            ),
            key: '0-1-2-2',
          },
          {
            title: (
              <span style={{ color: '#1890ff' }}>
                224 - Matériaux de construction, verre, céramique
              </span>
            ),
            key: '0-1-2-3',
          },
          {
            title: (
              <span style={{ color: '#1890ff' }}>
                225 - Plasturgie, matériaux composites
              </span>
            ),
            key: '0-1-2-4',
          },
          {
            title: (
              <span style={{ color: '#1890ff' }}>226 - Papier, carton</span>
            ),
            key: '0-1-2-5',
          },
          {
            title: (
              <span style={{ color: '#1890ff' }}>
                227 - Energie, génie climatique (y compris énergie nucléaire,
                thermique, hydraulique
              </span>
            ),
            key: '0-1-2-6',
          },
        ],
      },
      {
        title: (
          <span style={{ color: '#1890ff', 'font-weight': 'bold' }}>
            230 - Spécialités pluritechnologiques, génie civil, construction,
            bois
          </span>
        ),
        key: '0-1-3',
        children: [
          {
            title: (
              <span style={{ color: '#1890ff' }}>
                231 - Mines et carrières, génie civil, topographie
              </span>
            ),
            key: '0-1-3-0',
          },
          {
            title: (
              <span style={{ color: '#1890ff' }}>
                232 - Bâtiment : construction et couverture
              </span>
            ),
            key: '0-1-3-1',
          },
          {
            title: (
              <span style={{ color: '#1890ff' }}>
                233 - Bâtiment : finitions
              </span>
            ),
            key: '0-1-3-2',
          },
          {
            title: (
              <span style={{ color: '#1890ff' }}>
                234 - Travail du bois et de l'ameublement
              </span>
            ),
            key: '0-1-3-3',
          },
        ],
      },
      {
        title: (
          <span style={{ color: '#1890ff', 'font-weight': 'bold' }}>
            240 - Spécialités pluritechnologiques matériaux souples
          </span>
        ),
        key: '0-1-4',
        children: [
          {
            title: <span style={{ color: '#1890ff' }}>241 - Textile</span>,
            key: '0-1-4-0',
          },
          {
            title: (
              <span style={{ color: '#1890ff' }}>
                242 - Habillement (y compris mode, couture)
              </span>
            ),
            key: '0-1-4-1',
          },
          {
            title: (
              <span style={{ color: '#1890ff' }}>243 - Cuirs et peaux</span>
            ),
            key: '0-1-4-2',
          },
        ],
      },
      {
        title: (
          <span style={{ color: '#1890ff', 'font-weight': 'bold' }}>
            250 - Spécialités pluritechnologiques mécanique-électricité (y
            compris maintenance mécano-électrique)
          </span>
        ),
        key: '0-1-5',
        children: [
          {
            title: (
              <span style={{ color: '#1890ff' }}>
                251 - Mécanique générale et de précision, usinage
              </span>
            ),
            key: '0-1-5-0',
          },
          {
            title: (
              <span style={{ color: '#1890ff' }}>
                252 - Moteurs et mécanique auto
              </span>
            ),
            key: '0-1-5-1',
          },
          {
            title: (
              <span style={{ color: '#1890ff' }}>
                253 - Mécanique aéronautique et spatiale
              </span>
            ),
            key: '0-1-5-2',
          },
          {
            title: (
              <span style={{ color: '#1890ff' }}>
                254 - Structures métalliques (y compris soudure, carrosserie,
                coque bateau, cellule avion)
              </span>
            ),
            key: '0-1-5-3',
          },
          {
            title: (
              <span style={{ color: '#1890ff' }}>
                255 - Electricité, électronique (non compris automatismes,
                productique)
              </span>
            ),
            key: '0-1-5-4',
          },
        ],
      },
    ],
  },
  {
    title: (
      <span
        style={{
          color: '#1890ff',
          'text-decoration': 'underline',
          'font-weight': 'bold',
        }}>
        300 - Spécialités plurivalentes des services
      </span>
    ),
    key: '0-2',
    children: [
      {
        title: (
          <span style={{ color: '#1890ff', 'font-weight': 'bold' }}>
            310 - Spécialités plurivalentes des échanges et de la gestion (y
            compris administration générale des entreprises et des
            collectivités)
          </span>
        ),
        key: '0-2-0',
        children: [
          {
            title: (
              <span style={{ color: '#1890ff' }}>
                311 - Transports, manutention, magasinage
              </span>
            ),
            key: '0-2-0-0',
          },
          {
            title: (
              <span style={{ color: '#1890ff' }}>312 - Commerce, vente</span>
            ),
            key: '0-2-0-1',
          },
          {
            title: (
              <span style={{ color: '#1890ff' }}>
                313 - Finances, banque, assurances
              </span>
            ),
            key: '0-2-0-2',
          },
          {
            title: (
              <span style={{ color: '#1890ff' }}>
                314 - Comptabilité, gestion
              </span>
            ),
            key: '0-2-0-3',
          },
          {
            title: (
              <span style={{ color: '#1890ff' }}>
                315 - Ressources humaines, gestion du personnel, gestion de
                l'emploi
              </span>
            ),
            key: '0-2-0-4',
          },
        ],
      },
      {
        title: (
          <span style={{ color: '#1890ff', 'font-weight': 'bold' }}>
            320 - Spécialités plurivalentes de la communication
          </span>
        ),
        key: '0-2-1',
        children: [
          {
            title: (
              <span style={{ color: '#1890ff' }}>
                321 - Journalisme, communication (y compris communication
                graphique et publicité)
              </span>
            ),
            key: '0-2-1-0',
          },
          {
            title: (
              <span style={{ color: '#1890ff' }}>
                322 - Techniques de l'imprimerie et de l'édition
              </span>
            ),
            key: '0-2-1-1',
          },
          {
            title: (
              <span style={{ color: '#1890ff' }}>
                323 - Techniques de l'image et du son, métiers connexes du
                spectacle
              </span>
            ),
            key: '0-2-1-2',
          },
          {
            title: (
              <span style={{ color: '#1890ff' }}>
                324 - Secrétariat, bureautique
              </span>
            ),
            key: '0-2-1-3',
          },
          {
            title: (
              <span style={{ color: '#1890ff' }}>
                325 - Documentation, bibliothèques, administration des données
              </span>
            ),
            key: '0-2-1-4',
          },
          {
            title: (
              <span style={{ color: '#1890ff' }}>
                326 - Informatique, traitement de l'information, réseaux de
                transmission des données
              </span>
            ),
            key: '0-2-1-5',
          },
        ],
      },
      {
        title: (
          <span style={{ color: '#1890ff', 'font-weight': 'bold' }}>
            330 - Spécialités plurivalentes sanitaires et sociales
          </span>
        ),
        key: '0-2-2',
        children: [
          {
            title: <span style={{ color: '#1890ff' }}>331 - Santé</span>,
            key: '0-2-2-0',
          },
          {
            title: (
              <span style={{ color: '#1890ff' }}>332 - Travail social</span>
            ),
            key: '0-2-2-1',
          },
          {
            title: (
              <span style={{ color: '#1890ff' }}>
                333 - Enseignement, formation
              </span>
            ),
            key: '0-2-2-2',
          },
          {
            title: (
              <span style={{ color: '#1890ff' }}>
                334 - Accueil, hôtellerie, tourisme
              </span>
            ),
            key: '0-2-2-3',
          },
          {
            title: (
              <span style={{ color: '#1890ff' }}>
                335 - Animation culturelle, sportive et de loisirs
              </span>
            ),
            key: '0-2-2-4',
          },
          {
            title: (
              <span style={{ color: '#1890ff' }}>
                336 - Coiffure, esthétique et autres spécialités des services
                aux personnes
              </span>
            ),
            key: '0-2-2-5',
          },
          {
            title: (
              <span style={{ color: '#1890ff' }}>
                341 - Aménagement du territoire, développement, urbanisme
              </span>
            ),
            key: '0-2-2-6',
          },
          {
            title: (
              <span style={{ color: '#1890ff' }}>
                343 - Protection et développement du patrimoine
              </span>
            ),
            key: '0-2-2-7',
          },
          {
            title: (
              <span style={{ color: '#1890ff' }}>
                344 - Sécurité des biens et des personnes, police, surveillance
                (y compris hygiène et sécurité)
              </span>
            ),
            key: '0-2-2-8',
          },
          {
            title: (
              <span style={{ color: '#1890ff' }}>
                345 - Application des droits et statut des personnes
              </span>
            ),
            key: '0-2-2-9',
          },
          {
            title: (
              <span style={{ color: '#1890ff' }}>
                346 - Spécialités militaires
              </span>
            ),
            key: '0-2-2-10',
          },
        ],
      },
      {
        title: (
          <span style={{ color: '#1890ff', 'font-weight': 'bold' }}>
            410 - Spécialités concernant plusieurs capacités
          </span>
        ),
        key: '0-2-3',
        children: [
          {
            title: (
              <span style={{ color: '#1890ff' }}>
                411 - Pratiques sportives (y compris : arts martiaux)
              </span>
            ),
            key: '0-2-3-0',
          },
          {
            title: (
              <span style={{ color: '#1890ff' }}>
                412 - Développement des capacités mentales et apprentissages de
                base
              </span>
            ),
            key: '0-2-3-1',
          },
          {
            title: (
              <span style={{ color: '#1890ff' }}>
                413 - Développement des capacités comportementales et
                relationnelles
              </span>
            ),
            key: '0-2-3-2',
          },
          {
            title: (
              <span style={{ color: '#1890ff' }}>
                414 - Développement des capacités individuelles d'organisation
              </span>
            ),
            key: '0-2-3-3',
          },
          {
            title: (
              <span style={{ color: '#1890ff' }}>
                415 - Développement des capacités d'orientation, d'insertion ou
                de réinsertion sociales
              </span>
            ),
            key: '0-2-3-4',
          },
          {
            title: (
              <span style={{ color: '#1890ff' }}>
                421 - Jeux et activités spécifiques de loisirs
              </span>
            ),
            key: '0-2-3-5',
          },
          {
            title: (
              <span style={{ color: '#1890ff' }}>
                422 - Economie et activités domestiques
              </span>
            ),
            key: '0-2-3-6',
          },
          {
            title: (
              <span style={{ color: '#1890ff' }}>
                423 - Vie familiale, vie sociale et autres formations au
                développement personnel
              </span>
            ),
            key: '0-2-3-7',
          },
        ],
      },
    ],
  },
  {
    title: (
      <span
        style={{
          color: '#1890ff',
          'text-decoration': 'underline',
          'font-weight': 'bold',
        }}>
        999 - Autres
      </span>
    ),
    key: '0-3',
  },
];
