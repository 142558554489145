import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import Subscriptions from './subscriptions';
import DetailSubscription from './detailSubscriptions';
import CreateUpdateSubscribers from './CreateUpdateSubscriptions';
import QualitySubscribers from './QualitySusbcribers/qualitySubscribers';

const SubscriptionsRouter = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/show/:id`} component={DetailSubscription} />

      <Route
        path={`${path}/quality_subscribers`}
        component={QualitySubscribers}
      />

      <Route
        path={`${path}/edit/:id`}
        render={() => <CreateUpdateSubscribers purpose="edit" />}
      />
      <Route
        path={`${path}/create`}
        render={() => <CreateUpdateSubscribers purpose="create" />}
      />
      <Route path={path} component={Subscriptions} />
    </Switch>
  );
};

export default SubscriptionsRouter;
