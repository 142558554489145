import PropTypes from 'prop-types';

const getCInternalRulesTemplates = async ({
  dispatchAPI,
  setInternalRulesList,
  user,
  notification
}) => {
  try {
    const { data } = await dispatchAPI('GET', {
      url: `/files?type=internal_rules&organization=${user.organization._id}`
    });
    setInternalRulesList(data);
  } catch (e) {
    if (e.response) notification(e.response);
  }
};

getCInternalRulesTemplates.propTypes = {
  dispatchAPI: PropTypes.func.isRequired,
  setInternalRulesList: PropTypes.func.isRequired,
  user: PropTypes.arrayOf({}).isRequired,
  notification: PropTypes.func.isRequired
};

export default getCInternalRulesTemplates;
