import React from 'react';
import { Tag } from 'antd';
import moment from 'moment';

const colors = (status) => {
  switch (status) {
    case 'OPEN':
      return '';
    case 'CLOSED':
      return 'green';
    default:
      return 'var(--primaryColor)';
  }
};

const useListContent = (
  {
    name,
    description,
    status,
    incident_date,
    incident_source,
    incident_subject
  },
  t
) => {
  return [
    {
      label: t('dashboards.quality_incidents.name'),
      content: name
    },
    {
      label: t('dashboards.quality_incidents.description'),
      content: description
    },
    {
      label: t('dashboards.quality_incidents.incident_date'),
      content: moment(incident_date).format('dddd DD MMMM YYYY')
    },
    {
      label: t('dashboards.quality_incidents.status'),
      content: (
        <Tag style={{ fontSize: 15 }} color={colors(status)}>
          {t(`quality_incidents.form.${status}`)}
        </Tag>
      )
    },
    {
      label: t('dashboards.quality_incidents.incident_source'),
      content: t(`quality_incidents.form.${incident_source}`)
    },
    {
      label: t('dashboards.quality_incidents.incident_subject'),
      content: t(`quality_incidents.form.${incident_subject}`)
    },
    {
      label: t('dashboards.quality_incidents.incident_severity'),
      content: t(`quality_incidents.form.${incident_subject}`)
    }
  ];
};

export default useListContent;
