import React, { useEffect, useState } from 'react';
import { Tabs, PageHeader } from 'antd';
import ContentCustom from '../../components/ContentCustom/ContentCustom';
import Session from './Session';
import DevisTable from './DevisTable';
import DueTable from './DueTable';
import IndicatorWrapper from './IndicatorWrapper';

const { TabPane } = Tabs;
const tabBreadcrumb = ['Indicateurs', 'Sessions', 'Devis', 'Factures'];

const Indicator = () => {
  const [activKey, setActiveKey] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const routes = [
    {
      path: '/',
      breadcrumbName: 'Dashboard'
    },
    {
      path: '/',
      breadcrumbName: tabBreadcrumb[activKey]
    }
  ];

  useEffect(() => {
    setRefresh(!refresh);
  }, [activKey]);

  return (
    <>
      <ContentCustom>
        <PageHeader breadcrumb={{ routes }} title="Tableau de bord" />
        <Tabs
          defaultActiveKey="1"
          tabsPosition="top"
          onChange={(value) => setActiveKey(value - 1)}
          className="dashboard-tabs"
        >
          <TabPane tab="État" key="1">
            <IndicatorWrapper tab="État" refresh={refresh} />
          </TabPane>
          <TabPane tab="Sessions" key="2">
            <Session tab="Sessions" refresh={refresh} />
          </TabPane>
          <TabPane tab="Devis" key="3">
            <DevisTable tab="Devis" refresh={refresh} />
          </TabPane>
          <TabPane tab="Factures" key="4">
            <DueTable tab="Factures" refresh={refresh} />
          </TabPane>
        </Tabs>
      </ContentCustom>
    </>
  );
};

export default Indicator;
