import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Button, Divider, Popconfirm, Skeleton, Table } from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';
import DescriptionList from '../../components/DescriptionList/DescriptionList';
import listContent from './listContent';
import PageHeaderCustom from '../../components/PageHeader/PageHeader';
import { routes } from '../../utils/constants/routes';
import ContentCustom from '../../components/ContentCustom/ContentCustom';
import ErrorStatusCode from '../../utils/ErrorStatusCode';

const ShowTAndA = () => {
  const { id } = useParams();
  const history = useHistory();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { notification } = ErrorStatusCode();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [tAndA, setTAndA] = useState({});

  const getTAndA = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/times-and-attendances/${id}?populate=user`,
      });
      setTAndA(data);
    } catch (e) {
      if (e.response) notification(e.response);
    }
    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    getTAndA();
  }, [getTAndA]);

  const deleteTAndA = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/times-and-attendances/${id}` });
      history.goBack();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <PageHeaderCustom
        title={t('times-and-attendances.show.title', {
          date: moment(tAndA.day).format('L'),
        })}
        extra={
          <>
            <Link
              to={{
                pathname: `${routes['TIMES-AND-ATTENDANCES']}/edit/${id}`,
              }}>
              <Button type="primary">
                {`${t('buttons.edit')} `}
                <EditOutlined />
              </Button>
            </Link>
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={deleteTAndA}
              icon={<WarningOutlined />}>
              <Button type="link" danger>
                {`${t('buttons.delete')} `}
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </>
        }
      />
      <ContentCustom>
        <Skeleton loading={isLoading} active>
          <DescriptionList data={listContent(tAndA)} translate />
        </Skeleton>
        <Divider orientation="left">
          {t('times-and-attendances.show.tasks.title')}
        </Divider>
        <Table
          pagination={false}
          dataSource={(tAndA.tasks || []).map(({ _id, ...task }) => ({
            ...task,
            key: _id,
          }))}
          columns={[
            {
              title: t('times-and-attendances.show.tasks.name'),
              dataIndex: 'name',
            },
            {
              title: t('times-and-attendances.show.tasks.time'),
              dataIndex: 'time',
              render: ([start_time, end_time]) =>
                `${moment(start_time).format('HH:mm')} - ${moment(
                  end_time
                ).format('HH:mm')}`,
            },
            {
              title: t('times-and-attendances.show.tasks.duration'),
              key: 'duration',
              render: ({ time }) =>
                `${moment
                  .duration(moment(time[1]).diff(moment(time[0])))
                  .hours()}h${
                  moment
                    .duration(moment(time[1]).diff(moment(time[0])))
                    .minutes() !== 0
                    ? moment
                        .duration(moment(time[1]).diff(moment(time[0])))
                        .minutes()
                    : ''
                }`,
            },
          ]}
        />
      </ContentCustom>
    </>
  );
};

export default ShowTAndA;
