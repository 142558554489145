import React, { useCallback, useState, useEffect } from 'react';
import { Layout, Modal, Row, Typography } from 'antd';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';

import './stripe.css';
import { useTranslation } from 'react-i18next';

const promise = loadStripe('pk_test_yH3fJCX0cb6ZWDX2IskdCr65');
const { Text } = Typography;

const ModaleCard = props => {
  const { t } = useTranslation();

  return (
    <Modal
      title={null}
      footer={null}
      mask={false}
      closable={false}
      visible={props.visible}>
      <Row justify={'center'}>
        <Text className={'price-title'}>
          {t('payment.stripe.title')} {props.amount} €
        </Text>
      </Row>
      <Elements stripe={promise}>
        <CheckoutForm amount={props.amount} setmodalOpen={props.setmodalOpen} />
      </Elements>
    </Modal>
  );
};

export default ModaleCard;
