import React from 'react';
import PropTypes from 'prop-types';
import { Col, DatePicker, Progress, Row, TimePicker, Typography } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const { RangePicker } = TimePicker;

const DayRow = ({
  day,
  setDay,
  inOutTime,
  setInOutTime,
  totalDurations,
  totalTime,
}) => {
  const { t } = useTranslation();
  const disableDate = current => {
    return current && current > moment().endOf('day');
  };

  return (
    <>
      <Row gutter={[0, 8]} align="middle">
        <Col xs={{ span: 24 }} sm={{ span: 3 }}>
          <Typography.Text>
            {t('times-and-attendances.form.time_recording_date')}
          </Typography.Text>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 6 }}>
          <DatePicker
            value={day}
            disabledDate={disableDate}
            format="DD/MM/YYYY"
            onChange={value => value && setDay(value.startOf('day'))}
          />
        </Col>
      </Row>
      <Row gutter={[0, 8]} justify="space-between">
        <Col span={12}>
          <Row align="middle">
            <Col xs={{ span: 24 }} sm={{ span: 6 }}>
              <Typography.Text>
                {t('times-and-attendances.form.start_end_date')}
              </Typography.Text>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 12 }}>
              <RangePicker
                size="small"
                disabled={!day}
                showTime
                format="HH:mm"
                minuteStep={15}
                value={inOutTime}
                onChange={value =>
                  value
                    ? setInOutTime([
                        value[0].milliseconds(0).seconds(0),
                        value[1].milliseconds(0).seconds(0),
                      ])
                    : setInOutTime([])
                }
              />
            </Col>
          </Row>
        </Col>
        <Col span={6}>
          <Progress
            percent={((totalDurations / totalTime) * 100 || 0).toFixed()}
          />
        </Col>
      </Row>
    </>
  );
};

DayRow.propTypes = {
  day: PropTypes.shape({}),
  setDay: PropTypes.func.isRequired,
  inOutTime: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setInOutTime: PropTypes.func.isRequired,
  totalDurations: PropTypes.shape({}).isRequired,
  totalTime: PropTypes.shape({}).isRequired,
};

DayRow.defaultProps = {
  day: null,
};

export default DayRow;
