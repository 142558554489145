import React from 'react';
import moment from 'moment';
import { Tooltip } from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import { red } from '@ant-design/colors';

const columns = t => [
  {
    title: t('times-and-attendances.column.employee'),
    dataIndex: 'user',
    sorter: true,
    render: user => user && `${user.last_name} ${user.first_name}`,
  },
  {
    title: t('times-and-attendances.column.date'),
    key: 'day',
    // eslint-disable-next-line react/prop-types
    render: ({ day, tasks, start_date, end_date }) => {
      const totalTime = moment.duration(
        moment(end_date).diff(moment(start_date))
      );
      let duration = moment.duration(0);
      // eslint-disable-next-line react/prop-types
      tasks.forEach(({ time }) => {
        if (time && time.length)
          duration = moment
            .duration(moment(time[1]).diff(moment(time[0])))
            .add(duration);
      });
      return duration.asMilliseconds() === totalTime.asMilliseconds() ? (
        moment(day).format('l')
      ) : (
        <>
          {moment(day).format('l')}
          <Tooltip title={t('times-and-attendances.invalid')} placement="right">
            <WarningOutlined
              style={{ color: red.primary, fontSize: 16, margin: '0 8px' }}
            />
          </Tooltip>
        </>
      );
    },
  },
  {
    title: t('times-and-attendances.column.duration'),
    key: 'duration',
    render: ({ duration }) => {
      if (duration) {
        const d = moment.duration(duration);
        return `${d.hours()}h${d.minutes() ? d.minutes() : ''}`;
      }
      return '';
    },
  },
];

export default columns;
