import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { subRoutes } from '../../utils/constants/routes';
import Indicator from './Indicator';
import BPF from './BPF/BPF';
import QualityRouter from './QualityReport/QualityRouter';
import QualityIncidentRouter from './QualityIncidents/QualityIncidentRouter';

const DashboardRouter = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path="/dashboard/indicator" component={Indicator} />
      <Route
        path={`${path}${subRoutes.DASHBORD.PEDAGOGIC_FINANCIAL_REPORT}`}
        component={BPF}
      />
      <Route
        path={`${path}${subRoutes.DASHBORD.QUALITY_MONITOR}`}
        component={QualityRouter}
      />
      <Route path={`${path}`} component={QualityIncidentRouter} />
    </Switch>
  );
};

export default DashboardRouter;
