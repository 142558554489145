import React from 'react';
import PropTypes from 'prop-types';
import { Button, DatePicker, Form, Input, Select } from 'antd';
import { useTranslation } from 'react-i18next';

const { Option } = Select;
const { TextArea } = Input;

const layout = {
  labelCol: { span: 4, offset: 1 },
  wrapperCol: { span: 16 }
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 }
};

const AddActivityForm = ({ addActivity, setFormActivity, setListActivity }) => {
  const { t } = useTranslation();

  return (
    <div style={{ marginTop: 100 }}>
      <Form {...layout} onFinish={addActivity}>
        <Form.Item
          name="type"
          label={t('commercial.form.category')}
          rules={[{ required: true }]}
        >
          <Select>
            <Option value="note">{t('commercial.note')}</Option>
            <Option value="call">{t('commercial.call')}</Option>
            <Option value="RDV">{t('commercial.appointment')}</Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="desc"
          label={t('commercial.form.description')}
          rules={[{ required: true }]}
        >
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item
          name="dateTime"
          label={t('commercial.form.date')}
          rules={[{ required: true }]}
        >
          <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button
            danger
            style={{ marginRight: 20 }}
            onClick={() => {
              setListActivity(true);
              setFormActivity(false);
            }}
          >
            {t('buttons.cancel')}
          </Button>
          <Button type="primary" htmlType="submit">
            {t('buttons.add')}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

AddActivityForm.propTypes = {
  addActivity: PropTypes.func.isRequired,
  setFormActivity: PropTypes.func.isRequired,
  setListActivity: PropTypes.func.isRequired
};

export default AddActivityForm;
