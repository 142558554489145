import React from 'react';
import { Input, InputNumber, Radio } from 'antd';
import { useTranslation } from 'react-i18next';

const { TextArea } = Input;

const useField = () => {
  const { t } = useTranslation();
  const fields = [
    {
      label: 'name',
      name: ['name'],
      rules: [{ required: true }],
      input: <Input />
    },
    {
      label: 'description',
      name: ['description'],
      rules: [{ required: false }],
      input: <TextArea style={{ resize: 'none' }} />
    },
    {
      label: 'channel_number',
      name: ['address', 'number'],
      rules: [{ required: true }],
      input: <Input type="number" />
    },
    {
      label: 'street',
      rules: [{ required: true }],
      name: ['address', 'street']
    },
    {
      label: 'address_details',
      name: ['address', 'additional']
    },
    {
      label: 'postal_code',
      rules: [{ required: true }],
      name: ['address', 'postal_code'],
      input: <Input type="number" />
    },
    {
      label: 'city',
      name: ['address', 'city']
    },
    {
      label: 'capacity',
      name: ['capacity'],
      rules: [{ required: false }],
      input: <InputNumber style={{ width: '100%' }} />
    },
    {
      label: 'compliant_disable_access',
      name: ['compliant_disable_access'],
      rules: [{ required: true }],
      input: (
        <Radio.Group style={{ marginLeft: 10, paddingTop: 0 }}>
          <Radio value>{t('radio.yes')}</Radio>
          <Radio value={false}>{t('radio.no')}</Radio>
        </Radio.Group>
      )
    },

    {
      label: 'free_note',
      name: ['free_note'],
      rules: [{ required: false }],
      input: <TextArea style={{ resize: 'none' }} />
    }
  ];

  return {
    fields
  };
};

export default useField;
