import React from 'react';
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { Divider, Popconfirm, Tag } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const useColumns = ({ dispatchAPI, setRefresh, refresh, notification }) => {
  const { t } = useTranslation();
  const colorTypes = {
    Simple: 'blue',
    Parrain: 'warning',
    Prescripteur: 'green'
  };

  const deleteResource = async (id) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/users/${id}`,
        body: { isArchived: true }
      });
      setRefresh(!refresh);
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  const columns = [
    {
      title: t('users.form.last_name'),
      key: 'last_name',
      dataIndex: 'last_name',
      render: null,
      sorter: true
    },
    {
      title: t('users.form.first_name'),
      key: 'first_name',
      dataIndex: 'first_name',
      sorter: true
    },
    {
      title: t('users.form.email'),
      key: 'email',
      dataIndex: 'email',
      render: null,
      sorter: true
    },
    {
      title: t('users.form.type'),
      key: 'type',
      dataIndex: 'type',
      render: (type) => type && <Tag color={colorTypes[type]}>{type}</Tag>,
      sorter: true
    },
    {
      key: 'action',
      render: (record) => (
        <div style={{ float: 'right' }}>
          <Link
            to={{
              pathname: `access-account/show/${record.key}`
            }}
          >
            <EyeOutlined style={{ fontSize: 18 }} />
          </Link>
          <Divider type="vertical" />
          <Link
            to={{
              pathname:
                record.role === 'users:PRESCRIBER'
                  ? `access-account/prescriber/edit/${record.key}`
                  : `access-account/edit/${record.key}`
            }}
          >
            <EditOutlined style={{ fontSize: 18 }} />
          </Link>
          <Divider type="vertical" />
          <Popconfirm
            title={t('datatable.column.action.delete.title')}
            okText={t('datatable.column.action.delete.ok')}
            okButtonProps={{ type: 'danger' }}
            cancelText={t('datatable.column.action.delete.cancel')}
            onConfirm={() => deleteResource(record.key)}
            icon={<WarningOutlined />}
          >
            <DeleteOutlined
              style={{ color: 'red', fontSize: 18 }}
              type="delete"
            />
          </Popconfirm>
        </div>
      )
    }
  ];
  return columns;
};

export default useColumns;
