import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Divider, Card, List, Popconfirm, message } from 'antd';
import {
  DeleteOutlined,
  FileOutlined,
  WarningOutlined
} from '@ant-design/icons';
import useAuthContext from '../../../../contexts/AuthContext';
import ModalUploadFiles from '../modalUploadFiles';

const ImportFileContainer = ({ program, setProgram, id, state }) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  const fetchData = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/programs/${id}?populate=skills`
      });
      setProgram({
        ...data,
        educational_goals: data.educational_goals,
        trainees_profile: {
          customers: data.trainees_profile.customers,
          prerequisite: data.trainees_profile.prerequisite
        },
        execution_track: {
          ...data.execution_track,
          results_track: data.execution_track.results_track,
          educational_resources: data.execution_track.educational_resources
        }
      });
    } catch (e) {
      if (e.response) message(e.response);
    }
  };

  useEffect(() => {
    (async () => {
      await fetchData();
    })();
  }, []);

  const downloadFile = async (itemId, name) => {
    try {
      const response = await dispatchAPI('GET', {
        url: `/files/${itemId}`,
        responseType: 'blob'
      });
      const blob = new Blob([response.data], {
        type: response.data.type
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = name;
      a.target = '_blank';
      a.click();
    } catch (e) {
      if (e.response) message(e.response);
    }
    setIsDownloading({ ...isDownloading, [itemId]: false });
  };

  const deleteFile = async (itemId) => {
    try {
      await dispatchAPI('DELETE', {
        url: `/files/${itemId}`
      });
    } catch (e) {
      message.error(t('settings.errors.category_delete'));
    }
  };

  const updateProgram = async (body) => {
    try {
      await dispatchAPI('PATCH', { url: `/programs/${id}`, body });
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
  };

  const deleteFileFromProgram = async (itemId) => {
    const files = program.files.filter((el) => el._id !== itemId);
    await updateProgram({ files });
    await deleteFile(itemId);
    await fetchData();
  };

  return (
    <>
      <Divider orientation="left">
        <span>{t('programs.form.externFiles')}</span>
      </Divider>
      <Button
        type="link"
        onClick={() => {
          setIsModalVisible(!isModalVisible);
        }}
        style={{ marginLeft: 10 }}
      >
        {t('programs.form.title.addFiles')}
      </Button>
      <ModalUploadFiles
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        id={program._id}
        fetchData={fetchData}
        uploadType="programs"
        redirect
        sessionId={state?.session?._id}
      />
      <Card style={{ margin: 20 }}>
        <List
          className="program-files-list"
          dataSource={program.files}
          split={false}
          renderItem={(item) =>
            item && (
              <List.Item>
                <List.Item.Meta
                  onClick={() => {
                    downloadFile(item._id, item.filename);
                  }}
                  avatar={<FileOutlined />}
                  title={item.filename}
                />
                <span style={{ float: 'right' }}>
                  <Popconfirm
                    title={t('datatable.column.action.delete.title')}
                    okText={t('datatable.column.action.delete.ok')}
                    okButtonProps={{ type: 'danger' }}
                    cancelText={t('datatable.column.action.delete.cancel')}
                    onConfirm={async () => {
                      await deleteFileFromProgram(item._id);
                    }}
                    icon={<WarningOutlined />}
                  >
                    <DeleteOutlined style={{ color: 'red' }} type="delete" />
                  </Popconfirm>
                </span>
              </List.Item>
            )
          }
        />
      </Card>
    </>
  );
};

ImportFileContainer.propTypes = {
  program: PropTypes.shape({
    files: PropTypes.array,
    _id: PropTypes.string
  }).isRequired,
  setProgram: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  state: PropTypes.shape({
    session: PropTypes.shape({ _id: PropTypes.string })
  })
};

ImportFileContainer.defaultProps = {
  state: null
};

export default ImportFileContainer;
