import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { subRoutes } from '../../utils/constants/routes';
import UpdateProgram from './Program/UpdateProgram';
import ListPrograms from './Program/ListPrograms';
import DetailProgram from './Program/DetailProgram';
import CreateProgram from './Program/CreateProgram';
import AddProgramBlocToProgram from './Program/addProgramBlocToProgram';
import QuizzesModels from './QuizzesModels/QuizzesModels';
import UpdateQuiz from '../Sessions/Quizzes/UpdateQuiz';
import DetailQuiz from '../Sessions/Quizzes/DetailQuiz';

const CatalogRouter = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route
        path={`${path}${subRoutes.CATALOG.PROGRAMS}/edit/:id/add_blocs`}
        render={() => <AddProgramBlocToProgram />}
      />
      <Route
        path={`${path}${subRoutes.CATALOG.PROGRAMS}/create`}
        render={() => <CreateProgram purpose="create" />}
      />
      <Route
        path={`${path}${subRoutes.CATALOG.PROGRAMS}/edit/:id`}
        render={() => <UpdateProgram purpose="edit" />}
      />
      <Route
        path={`${path}${subRoutes.CATALOG.QUIZZES_MODELS}/edit/:id`}
        component={UpdateQuiz}
      />
      <Route
        path={`${path}${subRoutes.CATALOG.QUIZZES_MODELS}/show/:id`}
        render={() => <DetailQuiz purpose="catalog" />}
      />
      <Route
        path={`${path}${subRoutes.CATALOG.PROGRAMS}/show/:id`}
        component={DetailProgram}
      />
      <Route
        path={`${path}${subRoutes.CATALOG.PROGRAMS}`}
        component={ListPrograms}
      />
      <Route
        path={`${path}${subRoutes.CATALOG.QUIZZES_MODELS}`}
        component={QuizzesModels}
      />
    </Switch>
  );
};

export default CatalogRouter;
