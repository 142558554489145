import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Col, Divider, Row } from 'antd';
import CommissionsAmountIndicator from './CommissionsAmountIndicator';
import useAuthContext from '../../../contexts/AuthContext';
import ErrorStatusCode from '../../../utils/ErrorStatusCode';
import CommissionsSubscriptionsAmountIndicator from './CommissionsSubscriptionsAmountIndicator';
import AffiliationNumbersIndicator from './AffiliationNumbersIndicator';

const Indicators = () => {
  const { dispatchAPI, user } = useAuthContext();
  const { t } = useTranslation();
  const { notification } = ErrorStatusCode();
  const [commissions, setCommissions] = useState([]);

  const getCommissions = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/commissions?sponsor=${user._id}&populate=sponsored._id,sponsored._id.organization`
      });
      setCommissions(data);
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  useEffect(() => {
    getCommissions();
  }, []);

  return (
    <>
      <Row>
        <Col span={14}>
          <Divider orientation="left">
            <span>{t('prescriber.indicators.commissions_indicator')}</span>
          </Divider>
          <Card>
            <CommissionsAmountIndicator commissions={commissions} />
          </Card>
        </Col>
        <Col offset={1} span={9}>
          <Divider orientation="left">
            <span style={{ whiteSpace: 'break-spaces' }}>
              {t('prescriber.indicators.commissions_by_subscription')}
            </span>
          </Divider>
          <Card>
            <CommissionsSubscriptionsAmountIndicator
              commissions={commissions}
            />
          </Card>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Divider orientation="left">
            <span>{t('prescriber.indicators.affiliation_indicator')}</span>
          </Divider>
          <Card>
            <AffiliationNumbersIndicator user={user} />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Indicators;
