import React, { useCallback, useEffect, useState } from 'react';
import { Form, Switch, Row, Col, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../../contexts/AuthContext';
import NewSkillDrawer from '../../Skills/NewSkillDrawer';
import ErrorStatusCode from '../../../utils/ErrorStatusCode';
import ImportFileContainer from './components/ImportFileContainer';
import ProgramCollapse from './components/ProgramCollapse';

const { Text } = Typography;

const ItemProgramFieldsCollapse = (
  id,
  form,
  trainingFieldValue,
  setTrainingFieldValue,
  trainingContent,
  setTrainingContent,
  setSelectedSkills,
  selectedSkills,
  importDocs,
  setImportDocs,
  state
) => {
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [skills, setSkills] = useState([]);
  const [program, setProgram] = useState([]);
  const [visibilitySkillDrawer, setVisibilitySkillDrawer] = useState(false);
  const { notification } = ErrorStatusCode();

  useEffect(() => {
    if (program.training_content && program.training_content.length) {
      setTrainingContent(program.training_content);
    }
  }, [program]);

  const getSkills = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/skills' });
      setSkills(data);
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  const drawerVisibility = () => {
    setVisibilitySkillDrawer(!visibilitySkillDrawer);
  };
  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getSkills();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  const onChange = (checked) => {
    setImportDocs(checked);
  };

  return (
    <Row gutter={[10, 10]}>
      <Col span={24}>
        <Row gutter={[12, 12]}>
          <Col>
            <Text>{t('programs.form.blocs')}</Text>
          </Col>
          <Col>
            <Switch onChange={onChange} />
          </Col>
          <Col>
            <Text>{t('programs.form.externFiles')}</Text>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <NewSkillDrawer
          isVisible={visibilitySkillDrawer}
          setVisibilitySkillDrawer={drawerVisibility}
          getSkills={getSkills}
        />
      </Col>
      <Col span={24}>
        <Form.Item noStyle>
          {!importDocs ? (
            <ProgramCollapse
              trainingFieldValue={trainingFieldValue}
              setTrainingFieldValue={setTrainingFieldValue}
              setSelectedSkills={setSelectedSkills}
              selectedSkills={selectedSkills}
              form={form}
              id={id}
              trainingContent={trainingContent}
              setTrainingContent={setTrainingContent}
              drawerVisibility={drawerVisibility}
              isFieldsLoading={isFieldsLoading}
              skills={skills}
            />
          ) : (
            <ImportFileContainer
              program={program}
              setProgram={setProgram}
              id={id}
              state={state}
            />
          )}
        </Form.Item>
      </Col>
    </Row>
  );
};

export default ItemProgramFieldsCollapse;
