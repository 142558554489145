import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons';
import { Divider, Button } from 'antd';
import useAuthContext from '../../../contexts/AuthContext';
import SimpleResourceLandingLayout from '../../../components/layouts/SimpleResourceLandingLayout';
import { useColumns } from './columns';
import ErrorStatusCode from '../../../utils/ErrorStatusCode';
import { useDownloadDocument } from '../../../utils/downloadDoc';

export const ListInvoices = () => {
  const [isDownloading, setIsDownloading] = useState({});
  const columns = useColumns();
  const { notification } = ErrorStatusCode();
  const { user, dispatchAPI, setUser } = useAuthContext();
  const { t } = useTranslation();
  const [templateId, setTemplateId] = useState(null);
  const { downloadDocument } = useDownloadDocument();

  const getTemplateId = () => {
    const template = user.organization.templates.filter(
      (el) => el.type === 'subscription'
    );
    setTemplateId(template[0]._id);
  };

  useEffect(() => {
    getTemplateId();
  }, []);

  const downloadFile = async (id, name, callback) => {
    try {
      const response = await dispatchAPI('GET', {
        url: `/files/generate/subscription-invoices/${id}/${templateId}?fileType=pdf`,
        responseType: 'blob'
      });
      const blob = new Blob([response.data], {
        contentType: 'application/pdf'
      });

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = name;
      a.click();
    } catch (e) {
      if (e.response) notification(e.response);
    }
    const { data } = await dispatchAPI('GET', {
      url: `/organizations/${user.organization._id}`
    });

    setUser({ ...user, organization: data });
    callback();
  };

  const generateInvoice = async (item) => {
    if (!isDownloading[item.key]) {
      setIsDownloading({ ...isDownloading, [item.key]: true });
      await downloadFile(item.key, `${item.number}.pdf`, () =>
        setIsDownloading({
          ...isDownloading,
          [item.key]: false
        })
      );
    }
  };

  const actionColumn = [
    {
      key: 'action',
      render: (item) => {
        return (
          <>
            {templateId && (
              <>
                <Divider type="vertical" />
                <Button
                  style={{ padding: 0 }}
                  type="link"
                  onClick={() => {
                    let subscription = [];

                    if (user?.organization?.subscription_invoices) {
                      subscription = user?.organization?.subscription_invoices.filter(
                        (el) => el.invoice_id === item.key
                      );
                    }

                    if (subscription.length) {
                      downloadDocument({
                        _id: subscription[0]._id,
                        metadata: subscription[0].metadata,
                        contentType: subscription[0].contentType
                      });
                    } else {
                      generateInvoice(item);
                    }
                  }}
                >
                  {isDownloading[item.key] ? (
                    <LoadingOutlined />
                  ) : (
                    <DownloadOutlined />
                  )}
                </Button>
                <Divider type="vertical" />
              </>
            )}
          </>
        );
      }
    }
  ];

  return (
    <SimpleResourceLandingLayout
      title={t('invoices.title')}
      columns={[...columns, ...actionColumn]}
      resourceName="invoices"
      extraQuery={`companyId=${user.organization._id}&type=SUBSCRIPTION`}
      customActionColumn
      withCreateButton={false}
    />
  );
};
