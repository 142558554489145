import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Button } from 'antd';
import { EyeOutlined } from '@ant-design/icons';

const useColumns = ({
  setSendType,
  setUsers,
  setEmailPurpose,
  setIsModalVisible,
  setEmailContent,
  setEmailModel,
  setEmailObject
}) => {
  const { t } = useTranslation();

  return [
    {
      title: t('emails.table.date'),
      dataIndex: 'created_at',
      render: (created_at) =>
        created_at && moment(created_at).format('DD-MM-YYYY'),
      sorter: true
    },
    {
      title: t('emails.table.name'),
      dataIndex: 'name',
      sorter: true
    },
    {
      title: t('emails.table.user'),
      render: ({ user }) => user && `${user.first_name} ${user.last_name}`,
      sorter: true
    },
    {
      title: t('emails.table.email'),
      render: ({ user }) => user && user.email,
      sorter: true
    },
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      width: 120,
      render: (record) => (
        <Button
          onClick={() => {
            setSendType(record.type);
            setUsers([record.user]);
            setEmailPurpose(record.purpose);
            setIsModalVisible(true);
            setEmailContent(record.data);
            setEmailModel(record.type);
            setEmailObject(record.subject);
          }}
        >
          <EyeOutlined style={{ fontSize: 18 }} />
        </Button>
      )
    }
  ];
};

export default useColumns;
