import React from 'react';
import useColumns from './columns';
import { useFormatter } from '../../../utils/formatter';
import SimpleResourceLandingLayout from '../../../components/layouts/SimpleResourceLandingLayout';

const ListContacts = () => {
  const columns = useColumns();
  const { formattedData } = useFormatter();

  const headers = [
    {
      label: 'last_name',
      key: 'last_name'
    },
    {
      label: 'first_name',
      key: 'first_name'
    },
    {
      label: 'gender',
      key: 'gender'
    },
    {
      label: 'my_company',
      key: 'my_company.name',
      model_key: 'company'
    },
    {
      label: 'email',
      key: 'email'
    },
    {
      label: 'phone_number.country_code',
      key: 'phone_number.country_code'
    },
    {
      label: 'phone_number.number',
      key: 'phone_number.number'
    },
    {
      label: 'organization',
      key: 'organization.name'
    }
  ];

  return (
    <SimpleResourceLandingLayout
      columns={columns}
      resourceName="contacts"
      withSubRoutes
      withProjectionFields
      headers={headers}
      formatter={formattedData}
      resourceModelName="Contact"
      templateType="contacts_import_template"
    />
  );
};

export default ListContacts;
