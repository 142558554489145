import { useTranslation } from 'react-i18next';

const useListContent = ({
  name,
  subtitle,
  description,
  training_action_type,
  modality
}) => {
  const { t } = useTranslation();
  return [
    {
      label: 'programs.form.name',
      content: name
    },
    {
      label: 'programs.form.subtitle',
      content: subtitle
    },
    {
      label: 'programs.form.training_action_type',
      content: training_action_type && training_action_type.training_action_type
    },
    {
      label: 'programs.form.training_type',
      content: modality && t(`programs.form.${modality.training_type}`)
    },
    {
      label: 'programs.form.accessibility',
      content: modality && modality.accessibility
    },
    {
      label: 'programs.form.training_domain',
      content: training_action_type && training_action_type.training_domain,
      span: 3
    },
    {
      label: 'programs.form.diploma',
      content: training_action_type && training_action_type.diploma,
      span: 3
    },
    {
      label: 'programs.form.description',
      content: description
    }
  ];
};

export default useListContent;
