import PropTypes from 'prop-types';

const showModal = ({
  ref,
  id,
  document,
  setUsers,
  customersList,
  contacts,
  setConventionFile,
  files,
  setIsModalMailVisible,
  isModalMailVisible
}) => {
  if (ref === 'Company') {
    setUsers(
      contacts
        .filter(
          (el) =>
            el.my_company._id.toString() === id.toString() &&
            el.company_representative
        )
        .map((el) => ({
          ...el,
          ref: 'Contact'
        }))
    );
  } else if (ref === 'all') {
    const list = [];

    customersList.forEach(({ customer, ref }) => {
      if (ref === 'Company') {
        list.push(
          ...contacts
            .filter(
              (el) =>
                el.my_company._id.toString() === customer._id.toString() &&
                el.company_representative
            )
            .map((el) => ({
              ...el,
              ref: 'Contact'
            }))
        );
      } else {
        list.push(customer);
      }
    });
    setUsers(list);
  } else {
    setUsers(
      customersList
        .filter((el) => el.customer._id.toString() === id.toString())
        .map(({ customer, reference }) => ({
          ...customer,
          reference
        }))
    );
  }
  if (ref !== 'all') {
    setConventionFile([document]);
  } else {
    const list = [];
    customersList.forEach(({ customer, ref }) => {
      if (ref === 'Company') {
        const listFiles = files.filter(
          (item) =>
            item.user &&
            item.user._id === customer._id &&
            item.type === 'company_convention'
        );
        const filteredUsers = contacts.filter(
          (el) =>
            el.my_company._id.toString() === customer._id.toString() &&
            el.company_representative
        );
        filteredUsers.forEach((filteredUser) => {
          list.push({
            ...listFiles[listFiles.length - 1],
            name: filteredUser.first_name,
            user: filteredUser._id
          });
        });
      } else {
        const listFiles = files.filter(
          (item) =>
            item.user &&
            item.user._id === customer._id &&
            item.type === 'private_convention'
        );
        list.push({
          ...listFiles[listFiles.length - 1],
          user: customer._id
        });
      }
    });

    setConventionFile(
      list
        .filter((el) => el.user !== undefined)
        .map((el) => ({
          id: el._id,
          fileName: el.filename,
          user: el.user
        }))
    );
  }
  setIsModalMailVisible(!isModalMailVisible);
};

showModal.propTypes = {
  ref: PropTypes.string.isRequired,
  id: PropTypes.string,
  document: PropTypes.shape({
    fileName: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired
  }),
  setUsers: PropTypes.func.isRequired,
  customersList: PropTypes.arrayOf({}).isRequired,
  contacts: PropTypes.arrayOf({}).isRequired,
  setConventionFile: PropTypes.func.isRequired,
  files: PropTypes.arrayOf({}).isRequired,
  setIsModalMailVisible: PropTypes.func.isRequired,
  isModalMailVisible: PropTypes.bool
};

export default showModal;
