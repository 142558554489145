import React, { useCallback, useEffect, useState } from 'react';
import { Input, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { listStudyLevel } from '../../../utils/constants/trainingActionTypes';
import useAuthContext from '../../../contexts/AuthContext';
import ErrorStatusCode from '../../../utils/ErrorStatusCode';

const { Option } = Select;

const useFields = (companies, purpose) => {
  const { t } = useTranslation();
  const [enums, setEnums] = useState({});
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const { dispatchAPI } = useAuthContext();
  const { notification } = ErrorStatusCode();

  const labelValueArray = companies.map((obj) => ({
    label: obj.name,
    value: obj._id
  }));

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/trainees/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getEnums();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);
  const fields = [
    purpose === 'drawerCompany' && {
      label: 'company',
      name: 'company',
      input: <Select disabled options={labelValueArray} />
    },
    {
      label: 'gender',
      name: ['gender'],
      rules: [{ required: true }],
      input: (
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          loading={isFieldsLoading}
        >
          {(enums.genders || []).map((title) => (
            <Option key={title} value={title}>
              {t(`contacts.form.${title}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['last_name'],
      rules: [{ required: true }]
    },
    {
      name: ['first_name'],
      rules: [{ required: true }]
    },
    {
      name: ['email'],
      rules: [{ required: true }, { type: 'email' }]
    },
    {
      name: ['study_level'],
      input: (
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {listStudyLevel.map((item) => (
            <Option key={item} value={item}>
              {item}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['study_name']
    },
    {
      label: 'number',
      name: ['number'],
      input: <Input type="number" />
    },
    {
      label: 'street',
      name: ['street']
    },
    {
      label: 'additional',
      name: ['additional']
    },
    {
      label: 'postcode',
      name: ['postcode'],
      input: <Input type="number" />
    },
    {
      label: 'city',
      name: ['city']
    },
    {
      name: ['phone_number']
    }
  ];
  return {
    fields,
    isFieldsLoading
  };
};

export default useFields;
