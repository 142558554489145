import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Menu } from 'antd';
import { NavLink } from 'react-router-dom';
import { GiftOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import navMenuLogos from '../../utils/constants/navMenuLogos';
import useHandleResize from '../../utils/HandleResize';
import { routes, subRoutes } from '../../utils/constants/routes';
import useAuthContext from '../../contexts/AuthContext';
import CheckTrialDays from '../../utils/checkTrialDays';

const { SubMenu } = Menu;

const NavMenu = ({ path, setCollapsed }) => {
  const { t } = useTranslation();
  const { width } = useHandleResize();
  const { user } = useAuthContext();
  const [openKeys, setOpenKeys] = useState([]);

  // This function makes sure you can only have one subMenu open at a time
  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (
      Object.entries(routes)
        .map((entry) => entry[1])
        .indexOf(latestOpenKey) === -1
    ) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  return user.role === 'users:SUPER-USER' ? (
    <Menu
      style={{
        padding: '16px 0',
        background: 'var(--clientColor)',
        overflow: 'auto',
        height: '100vh'
      }}
      selectedKeys={[path]}
      openKeys={openKeys}
      defaultOpenKeys={[path.split('/')[1]]}
      onOpenChange={onOpenChange}
      mode="inline"
      onSelect={width < 992 ? () => setCollapsed(true) : () => {}}
    >
      {Object.entries(routes).map(([menuItem, p]) =>
        subRoutes[menuItem] ? (
          <SubMenu
            disabled={
              !CheckTrialDays(user) &&
              user?.organization?.current_subscription === undefined
            }
            key={p}
            icon={navMenuLogos[menuItem]}
            title={t(`basiclayout.menu.${menuItem.toLowerCase()}`)}
          >
            {Object.entries(subRoutes[menuItem]).map(
              ([subMenuItem, subPath]) => (
                <Menu.Item key={subPath} disabled={subPath === ''}>
                  <NavLink to={`${p}${subPath}`}>
                    <span>
                      {t(`basiclayout.submenu.${subMenuItem.toLowerCase()}`)}
                    </span>
                  </NavLink>
                </Menu.Item>
              )
            )}
          </SubMenu>
        ) : (
          menuItem !== 'ACCOUNTING' &&
          menuItem !== 'SUBSCRIPTIONS' &&
          menuItem !== 'ADMIN_SPONSORSHIP' &&
          (user.role === 'users:SUPER-USER' ? (
            <Menu.Item
              key={p}
              disabled={
                !CheckTrialDays(user) &&
                user?.organization?.current_subscription === undefined
              }
            >
              <NavLink to={p}>
                {navMenuLogos[menuItem]}
                <span>{t(`basiclayout.menu.${menuItem.toLowerCase()}`)}</span>
              </NavLink>
            </Menu.Item>
          ) : (
            menuItem !== 'USERS' && (
              <Menu.Item
                key={p}
                disabled={
                  !CheckTrialDays(user) &&
                  user?.organization?.current_subscription === undefined
                }
              >
                <NavLink to={p}>
                  {navMenuLogos[menuItem]}
                  <span>{t(`basiclayout.menu.${menuItem.toLowerCase()}`)}</span>
                </NavLink>
              </Menu.Item>
            )
          ))
        )
      )}
    </Menu>
  ) : (
    user.role === 'admins:SUPER-ADMIN' && (
      <Menu
        className="menu_super_admin"
        style={{
          padding: '16px 0',
          overflow: 'auto',
          height: '100vh'
        }}
        selectedKeys={[path]}
        defaultSelectedKeys={['1']}
        defaultOpenKeys={[path.split('/')[1]]}
        onOpenChange={onOpenChange}
        openKeys={openKeys}
        mode="inline"
        onSelect={width < 992 ? () => setCollapsed(true) : () => {}}
      >
        <SubMenu
          key="sub1"
          icon={<GiftOutlined />}
          title={<span>{t('basiclayout.menu.sponsorship')}</span>}
        >
          <Menu.Item>
            <NavLink to="/admin/access-account">
              <span>{t(`basiclayout.menu.access_account`)}</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item>
            <NavLink to="/admin/admin-sponsorship">
              <span>{t(`basiclayout.menu.commissions_monitor`)}</span>
            </NavLink>
          </Menu.Item>
        </SubMenu>
        <Menu.Item key="sub2">
          <NavLink to="/subscriptions">
            <UsergroupAddOutlined />
            <span>{t('basiclayout.menu.subscriptions')}</span>
          </NavLink>
        </Menu.Item>
      </Menu>
    )
  );
};

NavMenu.propTypes = {
  path: PropTypes.string.isRequired,
  setCollapsed: PropTypes.func.isRequired
};

export default NavMenu;
