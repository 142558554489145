import React from 'react';
import {
  CarOutlined,
  ContactsOutlined,
  FieldTimeOutlined,
  FileDoneOutlined,
  FileTextOutlined,
  GiftOutlined,
  ProjectOutlined,
  SettingOutlined,
  StockOutlined,
  TagsOutlined,
  UserOutlined,
} from '@ant-design/icons';
import {
  DashboardIcon,
  AgendaIcon,
  CommercialIcon,
  SessionIcon,
  CatalogIcon,
  DataIcon,
  AccountingIcon,
  DocumentIcon,
} from './customIcon';

const navMenuLogos = {
  DASHBORD: <DashboardIcon />,
  USERS: <UserOutlined style={{ fontSize: 22, marginRight: 14 }} />,
  ACCOUNTING: <AccountingIcon />,
  COMMERCIAL: <CommercialIcon />,
  CATALOG: <CatalogIcon />,
  DATAS: <DataIcon />,
  AGENDA: <AgendaIcon />,
  SESSIONS: <SessionIcon />,
  DOCUMENTS: <DocumentIcon />,
  CONTRACTS: <FileDoneOutlined />,
  QUOTES: <FileTextOutlined />,
  CUSTOMERS: <ContactsOutlined />,
  HUMANRESOURCE: <UserOutlined />,
  DELIVERIES: <CarOutlined />,
  PROJECTS: <ProjectOutlined />,
  THEME: <SettingOutlined />,
  'TIMES-AND-ATTENDANCES': <FieldTimeOutlined />,
  PRODUCTION: <StockOutlined />,
  TICKETS: <TagsOutlined />,
  SPONSORSHIP: <GiftOutlined style={{ fontSize: 24, marginRight: 14 }} />,
};

export default navMenuLogos;
