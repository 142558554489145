import React, { useState } from 'react';
import { Switch } from 'antd';
import { useTranslation } from 'react-i18next';

import PageHeaderCustom from '../../components/PageHeader/PageHeader';
import ContentCustom from '../../components/ContentCustom/ContentCustom';
import ListSessions from './ListSessions';
import { SessionsContextProvider } from './SessionKanban/SessionsContext';
import SessionsKanbanBoard from './SessionKanban/SessionsKanbanBoard';

const Sessions = () => {
  const { t } = useTranslation();
  const [kanbanView, setKanbanView] = useState(true);
  const [fencedView, setFencedView] = useState(false);

  localStorage.setItem('tabIndex', '1');

  return (
    <>
      <PageHeaderCustom
        title={t('sessions.title')}
        withSubRoutes
        extra={
          <Switch
            checkedChildren="Kanban"
            unCheckedChildren="Liste"
            onChange={() => {
              setKanbanView(!kanbanView);
            }}
            checked={kanbanView}
            className="kanban-switch"
          />
        }
      />

      <ContentCustom>
        <SessionsContextProvider>
          {kanbanView ? (
            <SessionsKanbanBoard fencedView={fencedView} />
          ) : (
            <ListSessions fencedView={fencedView} />
          )}
        </SessionsContextProvider>
      </ContentCustom>
    </>
  );
};

export default Sessions;
