import React from 'react';
import { Skeleton, Typography } from 'antd';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import useAccountingContext from '../../AccountingContext';

const Container = styled.div`
  background-color: var(--contrastBackground);
  padding: 10px;
  minheight: 150px;
`;
const { Title } = Typography;

const Contact = ({ isLoading, contact }) => {
  const { t } = useTranslation();
  const { collection } = useAccountingContext();

  return (
    <Container>
      <Skeleton loading={isLoading} paragraph={{ rows: 1, width: '50%' }}>
        {contact && (
          <>
            <Title level={5} style={{ marginBottom: '0', color: 'lightgrey' }}>
              {t(`${collection}.show.contact`)}
            </Title>
            <Title level={3} style={{ marginTop: '0' }}>
              <Link to={`/contacts/show/${contact._id}`}>
                {`${contact.first_name} ${contact.last_name}`}
              </Link>
            </Title>
            {contact.email}
            <br />
            {contact.phone_number1}
          </>
        )}
      </Skeleton>
    </Container>
  );
};

Contact.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  contact: PropTypes.shape({
    _id: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
    phone_number1: PropTypes.string,
  }).isRequired,
};

export default Contact;
