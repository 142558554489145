import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Card, List } from 'antd';
import PaymentGocardless from '../../payment/PaymentGocardless';

const SubscriptionCard = ({ item, index, subscription, advantagesLabels }) => {
  const { t } = useTranslation();
  return (
    <Card
      title={item.label}
      headStyle={{
        height: subscription ? 'auto' : 158
      }}
      key={index}
      className={
        item.label === 'INFINI'
          ? 'subscription-card pulse'
          : 'subscription-card'
      }
      extra={
        <span>
          <span style={{ fontSize: 20 }}>{`${item.id} € `}</span>
          <span style={{ fontsize: 14 }}>{t('profile.cards.ht_by_month')}</span>
        </span>
      }
      style={{
        background: item.color,
        border: `1px solid ${item.color}`
      }}
    >
      <List
        dataSource={item.options}
        renderItem={(option, idx) => (
          <List.Item
            style={{
              padding: 0,
              margin: 0,
              border: 0
            }}
          >
            <p>
              {`${advantagesLabels[idx]} :`}
              <strong style={{ marginLeft: 8 }}>{option}</strong>
            </p>
          </List.Item>
        )}
      />
      {!subscription && (
        <PaymentGocardless
          title={subscription}
          amount={item.id * 1.2}
          type="subscription"
          subscriptionLabel={item.label}
        />
      )}
    </Card>
  );
};

SubscriptionCard.propTypes = {
  item: PropTypes.shape({
    label: PropTypes.string,
    id: PropTypes.string,
    color: PropTypes.string,
    options: PropTypes.arrayOf()
  }).isRequired,
  index: PropTypes.number.isRequired,
  subscription: PropTypes.shape({}).isRequired,
  advantagesLabels: PropTypes.arrayOf().isRequired
};

export default SubscriptionCard;
