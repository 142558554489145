import React from 'react';
import SimpleResourceLandingLayout from '../../components/layouts/SimpleResourceLandingLayout';
import useAuthContext from '../../contexts/AuthContext';
import { useColumns } from './columns';

const ListUsers = () => {
  const { user } = useAuthContext();
  const columns = useColumns();

  const organizationId = user?.organization?._id;
  const userId = user?._id;

  const headers = [
    {
      label: 'last_name',
      key: 'last_name'
    },
    {
      label: 'first_name',
      key: 'first_name'
    },
    {
      label: 'email',
      key: 'email'
    },
    {
      label: 'phone_number.country_code',
      key: 'phone_number.country_code'
    },
    {
      label: 'phone_number.number',
      key: 'phone_number.number'
    },
    {
      label: 'role',
      key: 'role'
    },
    {
      label: 'address.number',
      key: 'address.number'
    },
    {
      label: 'address.street',
      key: 'address.street'
    },
    {
      label: 'address.additional',
      key: 'address.additional'
    },
    {
      label: 'address.postcode',
      key: 'address.postal_code'
    },
    {
      label: 'address.city',
      key: 'address.city'
    },
    {
      label: 'organization',
      key: 'ornanization.name',
      model_key: 'organization'
    }
  ];

  return (
    <SimpleResourceLandingLayout
      resourceName="users"
      columns={columns}
      headers={headers}
      resourceModelName="User"
      extraQuery={`organization=${organizationId}&_id!=${userId}`}
      templateType="users_import_template"
    />
  );
};

export default ListUsers;
