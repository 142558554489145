import React from 'react';
import PropTypes from 'prop-types';
import { DeleteOutlined } from '@ant-design/icons';
import moment from 'moment';
import { Col, Row, Timeline } from 'antd';
import styled from 'styled-components';

const Line = styled.div`
  position: relative;
  background-color: #f0f0f0;
  margin-right: 40px;
  padding: 15px;
  .type {
    font-weight: bold;
    font-size: 20px;
    text-transform: capitalize;
  }
`;

const DisplayActivity = ({ elem, delActivity }) => {
  const dateTime = elem.dateTime ? new Date(elem.dateTime) : null;

  return (
    <Timeline.Item>
      {elem.dateTime && (
        <div className="date">{moment(dateTime).format('LLLL')}</div>
      )}
      <Line>
        <DeleteOutlined
          onClick={() => delActivity(elem)}
          style={{
            position: 'absolute',
            top: 10,
            right: 10,
            cursor: 'pointer'
          }}
        />
        <div className="type">{elem.type}</div>
        <Row>
          <Col span={14}>
            <div className="desc">{elem.desc}</div>
          </Col>
        </Row>
      </Line>
    </Timeline.Item>
  );
};

const ListDealActivity = ({ deal, selectedTab, delActivity }) => {
  const listActivitiesTimeline = () => {
    const sortedArray = deal?.activities.sort((a, b) => {
      return new Date(b.dateTime) - new Date(a.dateTime);
    });

    return sortedArray.map((elem) => (
      <DisplayActivity delActivity={delActivity} elem={elem} key={elem._id} />
    ));
  };

  if (deal.activities) {
    return (
      <div style={{ paddingLeft: '14px', paddingTop: '30px' }}>
        {selectedTab === 'call' &&
          deal.activities
            .filter((activity) => activity.type === 'call')
            .map((elem) => (
              <DisplayActivity
                delActivity={delActivity}
                elem={elem}
                key={elem._id}
              />
            ))}
        {selectedTab === 'note' &&
          deal?.activities
            .filter((activity) => activity.type === 'note')
            .map((elem) => (
              <DisplayActivity
                delActivity={delActivity}
                elem={elem}
                key={elem._id}
              />
            ))}
        {selectedTab === 'RDV' &&
          deal?.activities
            .filter((activity) => activity.type === 'RDV')
            .map((elem) => (
              <DisplayActivity
                delActivity={delActivity}
                elem={elem}
                key={elem._id}
              />
            ))}
        {selectedTab === 'activity' && (
          <Timeline>{listActivitiesTimeline()}</Timeline>
        )}
      </div>
    );
  }
  return null;
};

DisplayActivity.propTypes = {
  elem: PropTypes.shape({
    dateTime: PropTypes.string,
    type: PropTypes.string,
    desc: PropTypes.string
  }).isRequired,
  delActivity: PropTypes.func.isRequired
};

ListDealActivity.propTypes = {
  deal: PropTypes.shape({
    activities: PropTypes.elem
  }).isRequired,
  selectedTab: PropTypes.string.isRequired,
  delActivity: PropTypes.func.isRequired
};

export default ListDealActivity;
