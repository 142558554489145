import React, { useEffect, useState } from 'react';
import { Card, Col, Divider, List, Progress, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import DetailResponses from '../Quizzes/detailsResponses';

const Quality = ({ quizzes, quizzesResponses, trainees }) => {
  const { t } = useTranslation();
  const [finalQuizzes, setFinalQuizzes] = useState([]);
  const [notes, setNotes] = useState([]);
  const [questionsNotes, setQuestionsNotes] = useState([]);
  const [bool, setBool] = useState(false);
  const [qualityNote, setQualityNote] = useState(0);

  useEffect(() => {
    const list = [];
    quizzesResponses.forEach(response => {
      quizzes.forEach(quiz => {
        if (
          response.quiz === quiz._id &&
          !list.find(el => el._id === quiz._id)
        ) {
          list.push(quiz);
        }
      });
    });
    setFinalQuizzes(list);
  }, [quizzes, quizzesResponses]);

  useEffect(() => {
    const list = [];
    finalQuizzes.forEach(quiz => {
      list.push({
        id: quiz._id,
        notes: [],
      });
    });

    setQuestionsNotes(list);
    setBool(!bool);
  }, [finalQuizzes]);

  useEffect(() => {
    let noteQuality = 0;
    for (const [key, note] of Object.entries(notes)) {
      noteQuality += note;
    }
    setQualityNote(noteQuality / finalQuizzes.length);
  }, [bool]);

  return (
    <>
      <Divider orientation="left">
        <span>{t(`Rapport qualité de la formation`)}</span>
      </Divider>
      <Card>
        <Row>
          <Col
            span={4}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <span style={{ fontSize: 30, fontWeight: 'bold' }}>
              {qualityNote ? `${qualityNote.toFixed(2)} / 10` : 'Aucune note'}
            </span>
          </Col>
          <Col span={20} className="session-quality-global-grade-col">
            {finalQuizzes.map((quiz, index) => {
              if (quiz.type === 'post_training_j') {
                return questionsNotes.map(note => {
                  if (note.id === quiz._id) {
                    return (
                      <Card bordered={false} title="Satisfaction">
                        <List
                          dataSource={quiz.questions.filter(
                            el => el.type === 'CHOICE_GRID'
                          )}
                          grid={{ column: 1 }}
                          renderItem={item => (
                            <List.Item>
                              <Row>
                                <Col span={8}>{item.title}</Col>
                                <Col span={16}>
                                  <Progress
                                    percent={(
                                      (note.notes[item._id] / 10) *
                                      100
                                    ).toFixed()}
                                  />
                                </Col>
                              </Row>
                            </List.Item>
                          )}
                        />
                      </Card>
                    );
                  }
                });
              }
              return questionsNotes.map(note => {
                if (note.id === quiz._id) {
                  return (
                    <Card bordered={false} title="Transfert des acquis">
                      <List
                        dataSource={quiz.questions.filter(
                          el => el.type === 'CHOICE_GRID'
                        )}
                        grid={{ column: 1 }}
                        renderItem={item => {
                          return (
                            <List.Item>
                              <Row>
                                <Col span={8}>{item.title}</Col>
                                <Col span={16}>
                                  <Progress
                                    percent={(
                                      (note.notes[item._id] / 10) *
                                      100
                                    ).toFixed()}
                                  />
                                </Col>
                              </Row>
                            </List.Item>
                          );
                        }}
                      />
                    </Card>
                  );
                }
              });
            })}
          </Col>
        </Row>
      </Card>
      {finalQuizzes.map(quiz => (
        <>
          <Card
            extra={
              <span>
                {notes[quiz._id] && notes[quiz._id].toFixed(2)}
                /10
              </span>
            }
            style={{ margin: 16 }}
            title={quiz.name}
            className="session-quality-quiz-card">
            <DetailResponses
              purpose="quality"
              traineesList={trainees}
              responses={quizzesResponses.filter(el => el.quiz === quiz._id)}
              quiz={quiz}
              notes={notes}
              setNotes={setNotes}
              secondBool={bool}
              questionsNotes={questionsNotes}
              setQuestionsNotes={setQuestionsNotes}
              setSecondBool={setBool}
            />
          </Card>
        </>
      ))}
    </>
  );
};

export default Quality;
