import React from 'react';
import PropTypes from 'prop-types';
import { Card, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import Quote from '../Quote';

const CaPoten = ({ isLoading, indicatorsColors, purpose, sessionData }) => {
  const { t } = useTranslation();
  return (
    <Card
      bordered={false}
      headStyle={{
        textAlign: 'left',
        color: indicatorsColors[purpose] || 'black'
      }}
      title={t(`dashboards.${purpose}.title`)}
      className="indicator-card indicator-card-large"
    >
      <Spin spinning={isLoading || !sessionData}>
        <Quote sessionData={sessionData} />
      </Spin>
    </Card>
  );
};

CaPoten.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  indicatorsColors: PropTypes.shape({}).isRequired,
  purpose: PropTypes.string.isRequired,
  sessionData: PropTypes.shape({}).isRequired
};

export default CaPoten;
